package online.interactiver.common.autogeneration.taskLineContent

class TextKeyPointsContent(
    val keyPoints: List<String>,
    val text: String,
    var soundSrc: String? = null
): TaskLineContent {

    override fun toRawTaskContent(): String {
        return "${keyPoints.joinToString(";")}; $text"
    }
}