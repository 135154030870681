package entities.interactivePicture.advancedSettings

import app.StoreState
import app.UndoableAction
import entities.interactivePicture.selectInteractivePicture
import online.interactiver.common.interactivepicture.AdvancedSettings

open class AdvancedSettingsAction(
    override val preventHistoryUpdate: Boolean = false,
    override val preventFutureUpdate: Boolean = false,
) : UndoableAction(preventHistoryUpdate, preventFutureUpdate)

data class SetAdvancedSettings(val advancedSettings: AdvancedSettings) : AdvancedSettingsAction()

val AdvancedSettingsReducer = { state: AdvancedSettings, action: AdvancedSettingsAction ->
    when (action) {
        is SetAdvancedSettings -> action.advancedSettings
        is TaskTypeSettingsAction ->
            state.copy(taskType = TaskTypeSettingsReducer(state.taskType, action))
        else -> state
    }
}

val selectAdvancedSettings = { state: StoreState -> selectInteractivePicture(state).advancedSettings }

val selectedHintColorSettings = { state: StoreState -> selectAdvancedSettings(state).hintsSettings.hintColor }
