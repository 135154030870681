package pages.constructor.ui.components.elements

import antd.Button
import antd.Tooltip
import antd.TooltipPropsWithTitle
import app.appState.OptionEditState
import app.appState.selectAppState
import app.useAppSelector
import emotion.css.ClassName
import emotion.react.css
import entities.interactivePicture.elements.controls.ui.tools.ControlTools
import entities.interactivePicture.elements.figuresAndLines.ui.tools.StaticTools
import entities.interactivePicture.elements.gapPuzzles.ui.tools.DragTools
import entities.interactivePicture.elements.other.ui.tools.OtherTools
import enums.EButtonTypes
import react.*
import react.dom.html.ReactHTML.div
import shared.components.Icon
import utils.types.jsObject

enum class ElementsTabs {
    Static, Control, Drag, Other
}

external interface TooltipConditionalProps : TooltipPropsWithTitle, PropsWithChildren {
    var disabled: Boolean?
}

private val TooltipConditional = FC<TooltipConditionalProps> { props ->
    if (props.disabled == true) {
        +props.children
    } else {
        Tooltip {
            title = props.title
            placement = props.placement
            +props.children
        }
    }
}

val Elements = FC<Props> {
    val (activeTab, setActiveTab) = useState(ElementsTabs.Static)

    val appState = useAppSelector(selectAppState)

    useEffect(appState::class) {
        setActiveTab(appState.getDefaultTab())
    }

    val tooltipDisabled = appState is OptionEditState

    ElementsHeader {}
    div {
        css(tabsAndContent)
        div {
            css(tabsContainer)
            div {
                className = if (activeTab == ElementsTabs.Static) ClassName(darkBlackFilter) else ClassName(grayFilter)
                Tooltip {
                    title = "Basic, non-interactive elements"
                    placement = "topLeft"
                    Button {
                        css(tab)
                        id = EButtonTypes.STATIC_TAB.value
                        disabled = appState.getStaticIsDisabled()
                        onClick = { setActiveTab(ElementsTabs.Static) }
                        Icon {
                            src = "ic_static_24x24.svg"
                        }
                        div {
                            css(tabText)
                            +"Static"
                        }
                    }
                }
            }
            div {
                className = if (activeTab == ElementsTabs.Control) ClassName(darkBlackFilter) else ClassName(grayFilter)
                TooltipConditional {
                    title = "Interactive elements with answer checks"
                    placement = "top"
                    overlayStyle = jsObject { maxWidth = "500px"; paddingLeft = "2px" }
                    disabled = tooltipDisabled
                    Button {
                        css(tab)
                        id = EButtonTypes.CONTROL_TAB.value
                        disabled = appState.getControlIsDisabled()
                        onClick = { setActiveTab(ElementsTabs.Control) }
                        Icon {
                            src = "ic_controls_24x24.svg"
                        }
                        div {
                            css(tabText)
                            +"Control"
                        }
                    }
                }
            }
            div {
                className = if (activeTab == ElementsTabs.Drag) ClassName(darkBlackFilter) else ClassName(grayFilter)
                TooltipConditional {
                    title = "Drag-and-drop puzzles with checks"
                    placement = "top"
                    disabled = tooltipDisabled
                    Button {
                        css(tab)
                        id = EButtonTypes.PUZZLE_TAB.value
                        disabled = appState.getPuzzleIsDisabled()
                        onClick = { setActiveTab(ElementsTabs.Drag) }
                        Icon {
                            src = "ic_drag_24x24.svg"
                        }
                        div {
                            css(tabText)
                            +"Puzzle"
                        }
                    }
                }
            }
            div {
                className = if (activeTab == ElementsTabs.Other) ClassName(darkBlackFilter) else ClassName(grayFilter)
                TooltipConditional {
                    title = "Advanced combinations of simple elements"
                    placement = "topRight"
                    overlayStyle = jsObject { maxWidth = "500px" }
                    disabled = tooltipDisabled
                    Button {
                        css(tab)
                        id = EButtonTypes.OTHER_TAB.value
                        disabled = appState.getOtherIsDisabled()
                        onClick = { setActiveTab(ElementsTabs.Other) }
                        Icon {
                            src = "ic_other_24x24.svg"
                        }
                        div {
                            css(tabText)
                            +"Other"
                        }
                    }
                }
            }
        }
        when (activeTab) {
            ElementsTabs.Other -> OtherTools { }
            ElementsTabs.Control -> ControlTools {}
            ElementsTabs.Drag -> DragTools { }
            ElementsTabs.Static -> StaticTools { }
        }
    }
}

val ElementsHeader = VFC {
    div {
        css(headerContainer)
        div {
            css(header)
            +"Elements"
        }
    }
}