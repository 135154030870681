package entities.interactivePicture.elements.editors.activeStateEditor


import antd.Option
import antd.Select
import app.appState.selectAppState
import app.useAppDispatch
import app.useAppSelector
import builders.enums.EElementType
import emotion.react.css
import entities.interactivePicture.elements.SetElementActiveStateForElements
import entities.interactivePicture.elements.SetHintActiveStateForElements
import entities.interactivePicture.selectElementsUnderSelectionRectangle
import entities.interactivePicture.selectFocusedElement
import enums.EActiveStateType
import online.interactiver.common.interactivepicture.InteractiveElement
import pages.constructor.ui.components.editors.interactivityEditor.activeStateHeader
import pages.constructor.ui.components.editors.interactivityEditor.blockHeader
import pages.constructor.ui.components.editors.interactivityEditor.textContainer
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.canvas.interfaces.getValueForEditor
import shared.canvas.interfaces.isAfterCheckingState
import shared.canvas.interfaces.isAllTimeState
import shared.canvas.interfaces.shouldDrawEditor

external interface ActiveStateEditorProps : Props {
    var isHint: Boolean
    var id: String?
}

val ActiveStateEditor = FC<ActiveStateEditorProps> f@{ props ->
    val appState = useAppSelector(selectAppState)
    val selected = useAppSelector(selectFocusedElement)
    val elementsUnderSelectionRectangle = useAppSelector(selectElementsUnderSelectionRectangle)
    val dispatch = useAppDispatch()

    val typesThatAlwaysShownAllTime = EElementType.values().filter { it.shownAllTime }.map { it.text }
    val typesWithHint = EElementType.values().filter { it.usesHintEditor }.map { it.text }
    val elementDisabled = !shouldDrawEditor(selected, elementsUnderSelectionRectangle) { element -> element.visibleElement.type !in typesThatAlwaysShownAllTime }
    val hintDisabled = !shouldDrawEditor(selected, elementsUnderSelectionRectangle) { element -> typesWithHint.contains(element.visibleElement.type) }
    if (!props.isHint && elementDisabled) {
        return@f
    }

    fun getActiveStateString(element: InteractiveElement): String {
        val solvingStageCondition = if (props.isHint && element.hint.showCondition != null) {
            element.hint.showCondition!!.solvingStageCondition
        } else if (!props.isHint && element.visibleElement.showCondition != null) {
            element.visibleElement.showCondition!!.solvingStageCondition
        } else {
            return "All time"
        }

        if (solvingStageCondition.isAllTimeState()) {
            return EActiveStateType.ALL_TIME.text
        }

        if (solvingStageCondition.isAfterCheckingState()) {
            return EActiveStateType.AFTER_CHECKING.text
        }

        return EActiveStateType.BEFORE_CHECKING.text
    }

    if (props.isHint) {
        div {
            css(activeStateHeader)
            +"Active"
        }
    } else {
        div {
            css(textContainer)
            div {
                css(blockHeader)
                +"Element showing"
            }
        }
    }
    val activeState = if (!hintDisabled || !props.isHint ) {
        getValueForEditor(selected, elementsUnderSelectionRectangle) { element -> getActiveStateString(element) }
    } else {
        "All time"
    }

    div {
        css(container)
        Select {
            css(activeStateEditorContainer)
            id = props.id
            value = activeState
            this.disabled = props.isHint && hintDisabled
            showArrow = true
            onSelect = s@{ value, _ ->
                if (props.isHint) {
                    if (selected != null) {
                        dispatch(appState.getSetHintActiveState(selected.identifier.id!!, value))
                    } else {
                        dispatch(SetHintActiveStateForElements(elementsUnderSelectionRectangle.map { it.identifier.id!! }.toTypedArray(), value))
                    }
                    return@s
                }

                if (selected != null) {
                    dispatch(appState.getSetElementActiveState(selected.identifier.id!!, value))
                } else {
                    dispatch(SetElementActiveStateForElements(elementsUnderSelectionRectangle.map { it.identifier.id!! }.toTypedArray(), value))
                }
            }
            EActiveStateType.values().map {
                Option {
                    value = it.text
                }
            }
        }
    }
}