package shared.components.textArea

import app.appState.selectAppState
import app.useAppDispatch
import app.useAppSelector
import builders.enums.EAlign
import builders.enums.EElementType
import builders.enums.EInteractiveType
import entities.interactivePicture.elements.SetSyncingPhrases
import entities.interactivePicture.elements.SetTextForElements
import entities.interactivePicture.elements.controls.inputs.SetCorrectValue
import entities.interactivePicture.elements.controls.inputs.SetCorrectValueForInputs
import entities.interactivePicture.selectElementsUnderSelectionRectangle
import kotlinx.browser.document
import online.interactiver.common.interactivepicture.InteractiveElement
import online.interactiver.common.interactivepicture.TextStyle
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTextAreaElement
import org.w3c.dom.events.Event
import org.w3c.dom.events.InputEvent
import org.w3c.dom.events.MouseEvent
import react.useEffect
import utils.structures.Position

fun getDisabled(selector: (EElementType) -> Boolean, selected: InteractiveElement?): Boolean {
    return !EElementType.values().filter { selector(it) }.map { it.text }.contains(selected?.visibleElement?.type)
}

fun updateTextArea(
    stage: dynamic,
    text: dynamic,
    isSelected: Boolean,
    textArea: HTMLTextAreaElement,
    bounds: Position,
    textStyle: TextStyle,
    isUnderSelectionRectangle: Boolean,
    element: InteractiveElement) {
    val dispatch = useAppDispatch()
    val appState = useAppSelector(selectAppState)
    val elementsUnderSelectionRectangle = useAppSelector(selectElementsUnderSelectionRectangle)
    val isSynced = element.isSynced

    useEffect(bounds, isSelected, isUnderSelectionRectangle) {
        if (stage == null || !text || !isSelected && !isUnderSelectionRectangle || getDisabled({ type -> type.usesTextEditor }, element)) {
            return@useEffect
        }

        textArea.style.left = "${text.absolutePosition().x}px"

        textArea.style.top =
            when(textStyle.verticalAlign) {
                EAlign.Vertical.MIDDLE.value ->
                    "${text.absolutePosition().y + text.height() / 2 - textArea.clientHeight / 2}px"
                EAlign.Vertical.TOP.value ->
                    "${text.absolutePosition().y + textStyle.padding}px"
                EAlign.Vertical.BOTTOM.value ->
                    "${text.absolutePosition().y + text.height() - textArea.clientHeight - textStyle.padding}px"
                else ->
                    "${text.absolutePosition().y + text.height() / 2 - textArea.clientHeight / 2}px"
            }

        val onInput = f@{ _: InputEvent ->
            if (text.isVisible()) {
                if (text.text().length == textArea.value.length + 1) {
                    return@f
                }
                text.hide()
                textArea.style.opacity = "1"
            }

            textArea.style.width = "${text.width()}px"
            textArea.style.height = "auto"
            textArea.style.height = "${textArea.scrollHeight}px"
            val inputs = elementsUnderSelectionRectangle.filter { it.type == EInteractiveType.INPUT_INTERACTIVE.text }
            if (element.type == EInteractiveType.INPUT_INTERACTIVE.text || inputs.isNotEmpty()) {
                if (isSelected) {
                    dispatch(SetCorrectValue(element.identifier.id!!, textArea.value))
                } else {
                    dispatch(SetCorrectValueForInputs(inputs.map { it.identifier.id!! }.toTypedArray(), textArea.value))
                }
            }

            if (!isSelected) {
                val ids = elementsUnderSelectionRectangle.map { it.identifier.id!! }.toTypedArray()
                dispatch(SetSyncingPhrases(ids, false))
                dispatch(SetTextForElements(ids, textArea.value))
                return@f
            }

            dispatch(appState.getSetVisibleElementText(element.identifier.id!!, textArea.value))
            if (isSynced != null) {
                dispatch(appState.getSetSyncingPhrase(element.identifier.id!!, false))
            }
        }

        textArea.oninput = onInput

        val onTextAreaClick = f@{ evt: Event ->
            val event = evt as MouseEvent
            val textAreaX = textArea.getBoundingClientRect().x
            val textAreaY = textArea.getBoundingClientRect().y
            val isClickInTextarea = textAreaX <= event.clientX && event.clientX <= textAreaX + bounds.width
                    && textAreaY <= event.clientY && event.clientY <= textAreaY + textArea.getBoundingClientRect().height
            if (!isClickInTextarea) {
                textArea.style.opacity = "0"
                textArea.style.width = "0px"
                if (document.activeElement !is HTMLTextAreaElement && document.activeElement !is HTMLInputElement) {
                    textArea.focus()
                }
                text.show()
                return@f
            }
            if (text.isVisible()) {
                text.hide()
                textArea.style.opacity = "1"
                textArea.style.width = "${text.width()}px"
                textArea.style.height = "auto"
                textArea.style.height = "${textArea.scrollHeight}px"
                textArea.style.top = when(textStyle.verticalAlign) {
                    EAlign.Vertical.MIDDLE.value ->
                        "${text.absolutePosition().y + text.height() / 2 - textArea.clientHeight / 2}px"
                    EAlign.Vertical.TOP.value ->
                        "${text.absolutePosition().y + textStyle.padding}px"
                    EAlign.Vertical.BOTTOM.value ->
                        "${text.absolutePosition().y + text.height() - textArea.clientHeight - textStyle.padding}px"
                    else ->
                        "${text.absolutePosition().y + text.height() / 2 - textArea.clientHeight / 2}px"
                }
                textArea.focus()
            }
        }
        document.addEventListener("click", onTextAreaClick)

        cleanup {
            document.removeEventListener("click", onTextAreaClick)
        }
    }
}

fun setTextArea(textStyle: TextStyle,
                text: dynamic,
                isSelected: Boolean,
                textArea: HTMLTextAreaElement,
                isUnderSelectionRectangle: Boolean,
                element: InteractiveElement) {
    useEffect(element.visibleElement.text?.style?.textColor,
        element.visibleElement.text?.style?.align,
        element.visibleElement.text?.style?.verticalAlign,
        element.visibleElement.text?.style?.fontSize, isSelected, isUnderSelectionRectangle) {
        if (!isSelected && !isUnderSelectionRectangle || !text || getDisabled({ type -> type.usesTextEditor }, element)) {
            return@useEffect
        }

        textArea.value = element.visibleElement.text?.simpleText ?: ""
        val canvasDiv = document.getElementById("canvas")
        canvasDiv?.append(textArea)

        if (document.activeElement !is HTMLTextAreaElement && document.activeElement !is HTMLInputElement) {
            textArea.focus()
        }
        val fontWeight = if (textStyle.fontStyle?.contains("bold") == true || textStyle.fontStyle?.contains("700") == true){ "700" } else { "400" }
        val fontStyle = if (textStyle.fontStyle?.contains("italic") == true) { "italic" } else { "normal"}

        textArea.style.position = "absolute"
        textArea.style.border = "none"
        textArea.style.width = "0px"
        textArea.style.opacity = "0"
        textArea.rows = 1
        textArea.style.display = "inline-block"
        textArea.style.resize = "none"
        textArea.style.margin = "0px"
        textArea.style.padding = "0px"
        textArea.style.textOverflow = "hidden"
        textArea.style.overflowX = "hidden"
        textArea.style.overflowY = "hidden"
        textArea.style.background = "none"
        textArea.style.outline = "none"
        textArea.style.color = element.visibleElement.text?.style?.textColor ?: "white"
        textArea.style.fontFamily = "${textStyle.fontFamily}"
        textArea.style.fontSize = "${textStyle.fontSize}px"
        textArea.style.textAlign = "${textStyle.align}"
        textArea.style.verticalAlign = "${textStyle.verticalAlign}"
        textArea.style.fontWeight = fontWeight
        textArea.style.fontStyle = fontStyle
        textArea.style.textDecoration =  "${textStyle.textDecoration}"
        textArea.style.lineHeight = "${textStyle.lineHeight}em"

        textArea.setAttribute("dir", "auto")

        cleanup {
            textArea.removeAttribute("dir")
            textArea.parentNode?.removeChild(textArea)
            text.show()
        }
    }
    useEffect(element.visibleElement.text?.simpleText) {
        if (!isUnderSelectionRectangle) {
            return@useEffect
        }

        textArea.value = element.visibleElement.text?.simpleText ?: ""
    }
}

fun hideTextArea(text: dynamic, textArea: HTMLTextAreaElement) {
    if (!text || text.isVisible()) {
        return
    }
    text.show()
    textArea.style.display = "none"
    textArea.style.opacity = "0"
    textArea.style.width = "0px"
}

fun focusTextArea(textArea: HTMLTextAreaElement) {
    textArea.style.display = "inline-block"
    textArea.focus()
}