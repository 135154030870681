package pages.constructor.ui.components.advancedElementsSettings.advancedInputSettings

import Modal
import antd.Button
import antd.Input
import app.useAppDispatch
import app.useAppSelector
import builders.enums.EElementType
import entities.advancedElementSettings.selectAdvancedElementSettingsModalState
import entities.interactivePicture.selectFocusedElement
import emotion.react.css
import entities.advancedElementSettings.CloseAdvancedElementSettings
import entities.interactivePicture.elements.SetElementCode
import entities.interactivePicture.elements.controls.inputs.SetCorrectValue
import online.interactiver.common.utils.clone
import entities.interactivePicture.elements.controls.inputs.SetInputOptions
import entities.interactivePicture.elements.controls.inputs.SetMaxLength
import entities.interactivePicture.elements.controls.inputs.buildInputOption
import entities.interactivePicture.elements.controls.inputs.ui.editor.MaxLengthEditor
import entities.interactivePicture.elements.editors.elementCodeEditor.ElementCodeEditor
import entities.interactivePicture.elements.editors.elementCodeEditor.useFocusedElementCode
import org.w3c.dom.HTMLInputElement
import react.*
import react.dom.html.ReactHTML.div
import shared.components.Icon
import utils.types.jsObject

val AdvancedInputSettings = FC<Props> {
    val modalState = useAppSelector(selectAdvancedElementSettingsModalState)
    val dispatch = useAppDispatch()
    val inputRefs = useRef<MutableMap<String, dynamic>>(mutableMapOf())

    val focusedElement = useAppSelector(selectFocusedElement)

    val focusedElementType = EElementType.values().find{ focusedElement?.visibleElement?.type == it.text }

    if (focusedElement == null || focusedElementType != EElementType.INPUT_CONTROL) {
        throw RuntimeException("Rendered advanced input settings with no input focused!")
    }
    val (answerOptions, setAnswerOptions) = useState(focusedElement.input?.answerOptions?.clone() ?: mutableListOf())
    val (okDisabled, setOkDisabled) = useState(false)
    val (correctValue, setCorrectValue) = useState(focusedElement.input?.correctValue ?: "")
    val (maxLength, setMaxLength) = useState(focusedElement.input?.maxLength ?: 255)
    val (elementCode, setElementCode) = useFocusedElementCode()

    useEffect(focusedElement) {
        setAnswerOptions(focusedElement.input?.answerOptions?.clone() ?: mutableListOf())
        setCorrectValue(focusedElement.input?.correctValue ?: "")
        setMaxLength(focusedElement.input?.maxLength ?: 255)
    }

    Modal {
        width = 800
        open = modalState.isOpen
        onCancel = {
            setAnswerOptions(focusedElement.input?.answerOptions?.clone() ?: mutableListOf())
            setCorrectValue(focusedElement.input?.correctValue ?: "")
            setMaxLength(focusedElement.input?.maxLength ?: 255)
            setElementCode(focusedElement.identifier.code ?: "")
            dispatch(CloseAdvancedElementSettings())
        }
        onOk = {
            dispatch(SetInputOptions(focusedElement.identifier.id!!,answerOptions.map {
                it.answer= inputRefs.current?.get(it.identifier!!.id!!).current.input.value
                it
            }.toMutableList() ))
            dispatch(SetCorrectValue(focusedElement.identifier.id!!, correctValue))
            dispatch(SetMaxLength(focusedElement.identifier.id!!, maxLength.toString()))
            dispatch(SetElementCode(focusedElement.identifier.id!!, elementCode))
            dispatch(CloseAdvancedElementSettings())
        }
        okText = "Save changes"
        okButtonProps = jsObject {
            disabled = okDisabled
        }
        div {
            css(header)
            +"Advanced element settings"
        }
        div {
            css(container)
            div {
                css(elementTypeHeader)
                +"Input"
            }
            ElementCodeEditor {
                value = elementCode
                onChange = {
                    setElementCode(it)
                }
            }
            MaxLengthEditor {
                value = maxLength
                onChange = {
                    val newMaxLength = if (it == ""){
                        0
                    } else {
                        it.toIntOrNull() ?: maxLength
                    }
                    setMaxLength(newMaxLength)
                }
            }
            div {
                css(inputContainer)
                Input {
                    css(input)
                    dir = "auto"
                    placeholder = "Enter answer"
                    value = correctValue
                    onChange = {e ->
                        setCorrectValue(e.target.value)
                    }
                }
            }
            answerOptions.map { option ->
                if ( !inputRefs.current?.containsKey(option.identifier!!.id)!!){
                    inputRefs.current?.put(option.identifier!!.id!!, createRef<HTMLInputElement>())
                }
                val text = if ( option.answer?.isEmpty() == true ) {
                    inputRefs.current?.get(option.identifier!!.id!!).current?.input?.value ?: ""
                } else {
                    option.answer
                }
                div {
                    css(inputContainer)
                    key = "container_${option.identifier!!.id}"
                    Input {
                        css(input)
                        dir = "auto"
                        key = option.identifier!!.id
                        placeholder = "Enter answer options"
                        defaultValue = text
                        ref = inputRefs.current?.get(option.identifier!!.id)
                    }
                    Button {
                        css(deleteButton)
                        Icon {
                            src = "ic_cross_24x24.svg"
                        }
                        onClick = {
                            val newState = answerOptions.clone()
                            newState.removeAt(newState.indexOfFirst { it.identifier?.id == option.identifier!!.id })
                            setAnswerOptions(newState)
                        }
                    }
                }
            }
            Button {
                css(addButton)
                onClick = {
                    val newState = answerOptions.clone()
                    newState.add(buildInputOption(""))
                    setAnswerOptions(newState)
                }
                Icon {
                    src = "ic_plus_24x24.svg"
                }
                + "Add answer option"
            }
        }
    }
}