package entities.interactivePicture.elements.controls.inputs

import online.interactiver.common.interactivepicture.GeometryStyle
import online.interactiver.common.interactivepicture.TextStyle

val inputStyle = GeometryStyle(
    fillColor = "#ffffff",
    strokeColor = "#D0D5DD",
    cornerRadius = 5,
    strokeWidth = 1,
    opacity = 1.0,
)

val inputHoverStyle = GeometryStyle(
    fillColor = "#ffffff",
    strokeColor = "#597EF7",
    strokeWidth = 1,
    cornerRadius = 5,
)

val inputFocusStyle = GeometryStyle(
    fillColor = "#ffffff",
    strokeColor = "#597EF7",
    strokeWidth = 1,
    cornerRadius = 5,
)

val inputTextStyle = TextStyle(
    textColor = "#101828",
    fontFamily = "Arial",
    fontStyle = "400",
    fontSize = 14,
    align = "left",
    padding = 5,
)