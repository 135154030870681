package widgets.ExerciseSettings.components.SettingLabel

import csstype.*
import utils.types.CssStyle

val labelContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 2.px
}

val label: CssStyle = {
    fontSize = 14.px
    lineHeight = 18.9.px
    fontWeight = integer(500)
    margin = 0.px
    color = Color("#5D6676")
}
