package app.appState

import builders.enums.EElementGroup
import builders.enums.EFrameType
import builders.enums.ELineType
import entities.interactivePicture.elements.*
import entities.interactivePicture.elements.editors.ScoreEditor.*
import entities.interactivePicture.elements.figuresAndLines.lines.AddLine
import entities.interactivePicture.elements.figuresAndLines.marker.AddMarker
import entities.interactivePicture.elements.figuresAndLines.static.AddRectWithImage
import entities.interactivePicture.elements.figuresAndLines.static.AddRectWithText
import entities.interactivePicture.elements.figuresAndLines.static.AddSound
import entities.interactivePicture.elements.frames.AddFrame
import online.interactiver.common.interactivepicture.*
import pages.constructor.ui.components.elements.ElementsTabs
import pages.constructor.ui.components.viewers.ViewersTabs
import react.FC
import react.Props
import redux.RAction
import utils.structures.Position

object CommonState : IAppState {
    override fun getAddRectWithText(id: String, position: Position?): RAction {
        return AddRectWithText(id, position)
    }

    override fun getAddRectWithImage(id: String, position: Position?, base64: String, fileName: String, ratio: Double): RAction {
        return AddRectWithImage(id, position, base64, fileName, ratio)
    }

    override fun getAddMarker(id: String, position: Position): RAction {
        return AddMarker(id, position)
    }

    override fun setScoreData(elementID: String, index: Int, score: ScoreData): ScoreAction {
        return SetCommonScoreData(elementID, index, score)
    }

    override fun setScoreCondition(elementID: String, index: Int, condition: ScoreCondition): ScoreAction {
        return SetCommonScoreCondition(elementID, index, condition)
    }

    override fun addScoreData(elementID: String): ScoreAction {
        return AddCommonScoreData(elementID)
    }

    override fun addScoreCondition(elementID: String): ScoreAction {
        return AddCommonScoreCondition(elementID)
    }

    override fun deleteScoreData(elementID: String, index: Int): RAction {
        return DeleteCommonScoreData(elementID, index)
    }

    override fun deleteScoreCondition(elementID: String, index: Int): RAction {
        return DeleteCommonScoreCondition(elementID, index)
    }

    override fun getAddLine(id: String, start: Point, end: Point): RAction {
        return AddLine(id, start, end, ELineType.LINE)
    }

    override fun getAddArrow(id: String, start: Point, end: Point): RAction {
        return AddLine(id, start, end, ELineType.ARROW_LINE)
    }

    override fun getAddSound(id: String, position: Position?, src: String?, filename: String?): RAction {
        return AddSound(id, position, src, filename)
    }

    override fun getAddFrameRect(id: String, position: Position?): RAction {
        return AddFrame(id, position, EFrameType.RECT)
    }

    override fun getAddFrameArea(id: String, position: Position?): RAction {
        return AddFrame(id, position, EFrameType.AREA)
    }

    override fun getTransformElement(id: String, geometry: Geometry): RAction {
        return TransformElement(id, geometry)
    }

    override fun getTransformElementCurve(id: String, curve: Curve): RAction {
        return TransformElementCurve(id, curve)
    }

    override fun getSetVisibleElementText(id: String, text: String): RAction {
        return SetVisibleElementText(id, text)
    }

    override fun getSetVisibleElementRenderView(id: String, base64: String): RAction {
        return SetVisibleElementRenderView(id, base64)
    }
    override fun getSetElementsHorizontalAlignForElements(ids: Array<String>, position: Int, flag: String ): RAction {
        return SetElementsHorizontalAlignForElements(ids, position, flag)
    }
    override fun getSetElementsVerticalAlignForElements(ids: Array<String>, position: Int, flag: String ): RAction {
        return SetElementsVerticalAlignForElements(ids, position, flag)
    }
    override fun getSetElementsSpaceBetweenHorizontal(id: String, offset: Int): RAction {
        return SetElementsSpaceBetweenHorizontal(id, offset)
    }
    override fun getSetElementsSpaceBetweenVertical(id: String, offset: Int): RAction {
        return SetElementsSpaceBetweenVertical(id, offset)
    }

    override fun getSetHintText(id: String, text: String): RAction {
        return SetHintText(id, text)
    }

    override fun getSetHintRect(id: String, rect: Rect): RAction {
        return SetHintRect(id, rect)
    }

    override fun getSetHintVerticalAlign(id: String, align: String?): RAction {
        return SetHintVerticalAlign(id, align)
    }

    override fun getRemoveElement(id: String): RAction {
        return RemoveElement(id)
    }

    override fun getCopyElement(id: String, newId: String): RAction {
        return CopyElement(id, newId)
    }

    override fun getSetElementActiveState(id: String, activeState: String): RAction {
        return SetElementActiveState(id, activeState)
    }

    override fun getSetHintActiveState(id: String, activeState: String): RAction {
        return SetHintActiveState(id, activeState)
    }

    override fun getMoveSelectedElements(ids: Array<String>, direction: String): RAction {
        return MoveSelectedElements(ids, direction)
    }

    override fun getSetFillColor(id: String, color: String): RAction {
        return SetFillColor(id, color)
    }

    override fun getSetFillColorForElements(ids: Array<String>, color: String): RAction {
        return SetFillColorForElements(ids, color)
    }

    override fun getSetFillOpacity(id: String, opacity: Double, preventHistoryUpdate: Boolean): RAction {
        return SetFillOpacity(id, opacity, preventHistoryUpdate)
    }
    override fun getSetFillOpacityForElements(ids: Array<String>, opacity: Double, preventHistoryUpdate: Boolean): RAction {
        return SetFillOpacityForElements(ids, opacity, preventHistoryUpdate)
    }

    override fun getSetBorderColor(id: String, color: String): RAction {
        return SetBorderColor(id, color)
    }
    override fun getSetBorderColorForElements(ids: Array<String>, color: String): RAction {
        return SetBorderColorForElements(ids, color)
    }

    override fun getSetBorderWidth(id: String, width: Int): RAction {
        return SetBorderWidth(id, width)
    }

    override fun getSetBorderWidthForElements(ids: Array<String>, width: Int): RAction {
        return SetBorderWidthForElements(ids, width)
    }

    override fun getSetBorderDash(id: String, dash: Array<Double>?): RAction {
        return SetBorderDash(id, dash)
    }
    override fun getSetBorderDashForElements(ids: Array<String>, dash: Array<Double>?): RAction {
        return SetBorderDashForElements(ids, dash)
    }

    override fun getSetTextColor(id: String, color: String): RAction {
        return SetTextColor(id, color)
    }
    override fun getSetTextColorForElements(ids: Array<String>, color: String): RAction {
        return SetTextColorForElements(ids, color)
    }

    override fun getSetTextHorizontalAlign(id: String, align: String): RAction {
        return SetTextHorizontalAlign(id, align)
    }
    override fun getSetTextHorizontalAlignForElements(ids: Array<String>, align: String): RAction {
        return SetTextHorizontalAlignForElements(ids, align)
    }

    override fun getSetTextVerticalAlign(id: String, align: String): RAction {
        return SetTextVerticalAlign(id, align)
    }
    override fun getSetTextVerticalAlignForElements(ids: Array<String>, align: String): RAction {
        return SetTextVerticalAlignForElements(ids, align)
    }

    override fun getSetTextFontSize(id: String, size: Int, preventHistoryUpdate: Boolean): RAction {
        return SetTextFontSize(id, size, preventHistoryUpdate)
    }

    override fun getSetTextFontSizeForElements(ids: Array<String>, size: Int, preventHistoryUpdate: Boolean): RAction {
        return SetTextFontSizeForElements(ids, size, preventHistoryUpdate)
    }
    override fun getSetTextPadding(id: String, size: Int, preventHistoryUpdate: Boolean): RAction {
        return SetTextPadding(id, size, preventHistoryUpdate)
    }

    override fun getSetTextPaddingForElements(ids: Array<String>, size: Int, preventHistoryUpdate: Boolean): RAction {
        return SetTextPaddingForElements(ids, size, preventHistoryUpdate)
    }
    override fun getSetTextStyle(id: String, style: String): RAction {
        return SetTextStyle(id, style)
    }
    override fun getSetTextStyleForElements(ids: Array<String>, style: String): RAction {
        return SetTextStyleForElements(ids, style)
    }
    override fun getSetTextDecoration(id: String, style: String): RAction {
        return SetTextDecoration(id, style)
    }
    override fun getSetTextDecorationForElements(ids: Array<String>, style: String): RAction {
        return SetTextDecorationForElements(ids, style)
    }
    override fun getSetTextLineHeight(id: String, lineHeight: Double, preventHistoryUpdate: Boolean): RAction {
        return SetTextLineHeight(id, lineHeight, preventHistoryUpdate)
    }
    override fun getSetTextLineHeightForElements(ids: Array<String>, lineHeight: Double, preventHistoryUpdate: Boolean): RAction {
        return SetTextLineHeightForElements(ids, lineHeight, preventHistoryUpdate)
    }
    override fun getSetHintTextFontSize(id: String, size: Int, preventHistoryUpdate: Boolean): RAction {
        return SetHintTextFontSize(id, size, preventHistoryUpdate)
    }
    override fun getSetHintTextFontSizeForElements(ids: Array<String>, size: Int, preventHistoryUpdate: Boolean): RAction {
        return SetHintTextFontSizeForElements(ids, size, preventHistoryUpdate)
    }
    override fun getSetHintFontStyle(id: String, style: String): RAction {
        return SetHintFontStyle(id, style)
    }
    override fun getSetHintFontStyleForElements(ids: Array<String>, style: String): RAction {
        return SetHintFontStyleForElements(ids, style)
    }
    override fun getSetHintDecoration(id: String, style: String): RAction {
        return SetHintDecoration(id, style)
    }
    override fun getSetHintDecorationForElements(ids: Array<String>, style: String): RAction {
        return SetHintDecorationForElements(ids, style)
    }
    override fun getSetHintHorizontalAlign(id: String, align: String): RAction {
        return SetHintHorizontalAlign( id, align)
    }
    override fun getSetHintHorizontalAlignForElements(ids: Array<String>, align: String): RAction {
        return SetHintHorizontalAlignForElements( ids, align)
    }
    override fun getSetHintLineHeight(id: String, lineHeight: Double, preventHistoryUpdate: Boolean): RAction {
        return SetHintLineHeight( id, lineHeight, preventHistoryUpdate)
    }
    override fun getSetHintLineHeightForElements(ids: Array<String>, lineHeight: Double, preventHistoryUpdate: Boolean): RAction {
        return SetHintLineHeightForElements( ids, lineHeight, preventHistoryUpdate)
    }
    override fun getSetHintTextPadding(id: String, size: Int, preventHistoryUpdate: Boolean): RAction {
        return SetHintTextPadding(id, size, preventHistoryUpdate)
    }

    override fun getSetHintTextPaddingForElements(ids: Array<String>, size: Int, preventHistoryUpdate: Boolean): RAction {
        return SetHintTextPaddingForElements(ids, size, preventHistoryUpdate)
    }

    override fun getSetElementCode(id: String, code: String): RAction {
        return SetElementCode(id, code)
    }

    override fun getDefaultTab(): ElementsTabs {
        return ElementsTabs.Static
    }

    override fun getStaticIsDisabled(): Boolean {
        return false
    }

    override fun getControlIsDisabled(): Boolean {
        return false
    }

    override fun getPuzzleIsDisabled(): Boolean {
        return false
    }

    override fun getOtherIsDisabled(): Boolean {
        return false
    }

    override fun getStaticGroup(): EElementGroup {
        return EElementGroup.STATIC
    }

    override fun getDefaultViewerTab(): ViewersTabs {
        return ViewersTabs.Static
    }

    override fun getStaticViewerIsDisabled(): Boolean {
        return false
    }

    override fun getControlViewerIsDisabled(): Boolean {
        return false
    }

    override fun getPuzzleViewerIsDisabled(): Boolean {
        return false
    }

    override fun getOtherViewerIsDisabled(): Boolean {
        return false
    }

    override fun getExtraUnderCanvasDOM(): FC<Props> {
        return FC { }
    }

    override fun getMessage(): String {
        return "Common app state"
    }

    override fun getSelectedOptionId(): String? {
        return null
    }

    override fun clone(): IAppState {
        return CommonState
    }

    override fun getSetSyncingPhrase(id: String, isSynced: Boolean): RAction {
        return SetSyncingPhrase(id, isSynced)
    }
}
