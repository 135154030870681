package widgets.StudentDuoLayout

import emotion.react.css
import entities.solvedTasks.ui.SolvedTasks
import org.w3c.dom.HTMLDivElement
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.section

external interface StudentDuoLayoutProps : PropsWithChildren {
    var buttons: FC<Props>?
}

val StudentDuoLayout = FC<StudentDuoLayoutProps> { props ->
    section {
        css(page)
        SolvedTasks {}
        div {
            css(content)
            +props.children
        }
        props.buttons?.let {
            div {
                css(footer)
                it {}
            }
        }
    }
}