package shared.components.cropImage

import Modal
import antd.Button
import antd.Spin
import csstype.px
import emotion.react.css
import entities.interactivePicture.elements.editors.soundEditor.inputHidden
import kotlinx.browser.document
import kotlinx.js.jso
import org.w3c.dom.*
import pages.constructor.ui.components.elements.grayFilter
import react.*
import react.dom.html.InputType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.p
import reactcrop.Crop
import reactcrop.ReactCrop
import shared.components.Icon
import shared.components.header.Header
import utils.fileToBase64
import utils.types.impl
import widgets.ExerciseSettings.components.CustomLogoSettings.iconButton
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoTask.ui.components.SlideEditModal.cancelButton

external interface CropImageProps : Props {
    var onSaveClick: (String) -> Unit
    var isOpen: Boolean
    var crop: Crop?
    var closeModal: () -> Unit
    var openModal: () -> Unit
    var cropOnChange: (Crop) -> Unit
    var onDeleteClick: () -> Unit
    var savedImage: String?
}

data class CropImageHook(
    val resetState: () -> Unit,
    val component: FC<CropImageProps>,
    val props: CropImageProps,
)

fun useCropImage(): CropImageHook {
    val (crop, setCrop) = useState<Crop>()
    val (isOpen, setIsOpen) = useState(false)

    return CropImageHook(
        resetState = {
            setCrop(null)
            setIsOpen(false)
        },
        component = CropImage,
        props = impl {
            this.isOpen = isOpen
            this.crop = crop
            this.closeModal = { setIsOpen(false) }
            this.openModal = { setIsOpen(true) }
            this.cropOnChange = { setCrop(it) }
        }
    )
}

val CropImage = FC<CropImageProps> { props ->
    val inputRef = useRef(null) as MutableRefObject<HTMLInputElement>?
    val imageRef = useRef(null) as MutableRefObject<HTMLImageElement>?

    val (image, setImage) = useState<String>()
    val (sizeOfImage, setSizeOfImage) = useState<Pair<Int, Int>>()

    useEffect(image) {
        if (image == null) return@useEffect

        val imageObj = Image()
        imageObj.src = image
        imageObj.onload = {
            setSizeOfImage(Pair(imageObj.width, imageObj.height))
        }
    }

    val crop = props.crop

    val inputImageOnChange = fileToBase64 { src, _ ->
        setImage(src)
        props.openModal()
    }

    val handleOnSaveClick = f@{
        if (crop == null) {
            image?.let { props.onSaveClick(it) }
            return@f
        }

        val cropWidth = crop.width
        val cropHeight = crop.height
        val cropX = crop.x
        val cropY = crop.y
        if (cropWidth == null || cropHeight == null || cropX == null || cropY == null) {
            return@f
        }

        val canvas = document.createElement("canvas") as HTMLCanvasElement
        val img = imageRef?.current ?: return@f

        val scaleX = img.naturalWidth.toDouble() / img.width
        val scaleY = img.naturalHeight.toDouble() / img.height

        canvas.width = cropWidth
        canvas.height = cropHeight

        val ctx = canvas.getContext("2d") as CanvasRenderingContext2D? ?: return@f

        ctx.drawImage(
            img,
            cropX * scaleX,
            cropY * scaleY,
            cropWidth * scaleX,
            cropHeight * scaleY,
            0.0,
            0.0,
            cropWidth.toDouble(),
            cropHeight.toDouble()
        )

        val croppedBase64 = canvas.toDataURL("image/jpeg")

        props.onSaveClick(croppedBase64)
    }

    div {
        css(uploadContainer)
        Button {
            css(uploadButton)
            onClick = {
                inputRef?.current?.click()
            }
            Icon {
                src = "ic_upload_sound_24x24.svg"
            }
            +"Upload image"
        }
        input {
            css(inputHidden)
            ref = inputRef
            type = InputType.file
            accept = "image/*"
            size = 1
            onChange = inputImageOnChange
        }
        val savedImage = props.savedImage
        if (!savedImage.isNullOrBlank()) {
            div {
                css(savedImageCss)
                p {
                    css(savedImageName)
                    +savedImage.takeLast(10)
                }
                Button {
                    css(iconButton)
                    Icon {
                        css(grayFilter)
                        src = "ic_delete_16x18.svg"
                    }
                    onClick = {
                        props.onDeleteClick()
                    }
                }
            }
        }
    }


    Modal {
        open = props.isOpen
        onCancel = {
            props.closeModal()
        }
        zIndex = 101
        footer = null
        width = 900.px
        bodyStyle = jso(modal)
        Header {
            text = "Continue with full image or select part to crop"
        }
        div {
            css(cropContainer)
            if (sizeOfImage == null) {
                Spin {
                    size = "large"
                }
            } else {
                ReactCrop {
                    this.crop = crop
                    onChange = { props.cropOnChange(it) }
                    img {
                        css(if (sizeOfImage.first > sizeOfImage.second) imageWithMaxWidth else imageWithMaxHeight)
                        ref = imageRef
                        src = image
                    }
                }
            }
        }
        div {
            css(footerContainer)
            Button {
                css(generateButton)
                onClick = handleOnSaveClick
                +"Save"
            }
            Button {
                css(cancelButton)
                onClick = {
                    props.closeModal()
                }
                +"Cancel"
            }
        }
    }
}
