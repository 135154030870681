package online.interactiver.common.interactivepicture

import kotlinx.serialization.Serializable
import online.interactiver.common.enums.EWordParticle
import online.interactiver.common.math.LinearTransformation
import online.interactiver.common.utils.Copyable
import online.interactiver.common.utils.clone

@Serializable
data class GapPuzzle(
    var type: String = "DesktopByDragTouchByClick", // "DesktopByDragTouchByClick" (default for draggable), "ByDrag", "ByClick" // ignore in constructor
    var gap: SimpleElement?, // place where puzzle element should be placed by default (static hovered on background)
    var checkmarkPosition: String = "Hide",
    // no check criteria because element will be automatically placed in gap if it's center fills in the gap
    // maybe add later
    var returnToPlaceIfThrown: String? = null, // if "1" - use return animation
    // по механике отдельный вопрос: какой эффект оверлапинга; возвращается ли на исходное место если брошен и тд
    var customPuzzleInitialPosition: String? = null, // if "1" then visibleElement position is custom puzzle init position
    var isExtraPuzzle: Boolean? = null
) {
    fun clone() = copy(
        gap = gap?.clone(),
    )

    fun clone(linearTransformation: LinearTransformation) = clone().apply {
        applyTransformation(linearTransformation)
    }

    fun applyTransformation(linearTransformation: LinearTransformation) {
        gap?.applyTransformation(linearTransformation)
    }
}

@Serializable
data class InputOption(
    var identifier: Identifier? = null,
    var answer: String? = null,

    var scores: MutableList<ScoreData>? = null,
) : Copyable<InputOption> {
    override fun clone() = copy(
        identifier = identifier?.clone(),
        scores = scores?.map { it.clone() }?.toMutableList(),
    )

}

@Serializable
data class Input(
    var type: String = "AnyPlainText", // "Digits", "Latin", "UsualMath", ... // define import forms; for example mathQuill may be used for math
    var maxLength: Int = 255,
    var correctValue: String? = null, // if null - complete criteria used
    var correctValueFunction: String? = null, // if null - complete criteria used
    var style: TextStyle? = null,
    var placeholderText: String = "Type text",
    var correctAnswerPosition: String = "Above input",
    var checkmarkPosition: String = "Hide",
    var ignoreWhiteSpaces: String? = null,
    var ignoreCaseSensitivity: String? = null,
    var answerOptions: MutableList<InputOption>? = null,
) {
    fun clone() = copy()
}

@Serializable
data class Button(
    var correctState: String? = null, // "selected", "unselected"; if null - complete criteria used
    var checkmarkPosition: String = "Hide",
    var scoresBySelected: MutableList<ScoreData>? = null,
    var scoresByUnselected: MutableList<ScoreData>? = null,
    // alternative text for pressed (selected) buttons - maybe later
) {
    fun clone() = copy()
}

@Serializable
data class SelectorOption(
    var identifier: Identifier? = null,
    var text: TextFrame? = null,
    var isCorrect: String? = null, // "1" if correct

    // later:
    var frames: MutableList<InteractiveElement>? = null, // hints allowed
    var figuresAndLines: MutableList<InteractiveElement>? = null, // hints allowed
    // any other ideas with selector actions besides showing and hiding of elements

    var scores: MutableList<ScoreData>? = null,

    var style: ElementStyle? = null,
) : Copyable<SelectorOption> {
    override fun clone() = copy(
        identifier = identifier?.clone(),
        text = text?.clone(),
        frames = frames?.map { it.clone() }?.toMutableList(),
        figuresAndLines = figuresAndLines?.map { it.clone() }?.toMutableList(),
        style = style?.clone(),
        scores = scores?.map { it.clone() }?.toMutableList(),
    )

    fun clone(linearTransformation: LinearTransformation) = clone().apply {
        applyTransformation(linearTransformation)
    }

    fun applyTransformation(linearTransformation: LinearTransformation) {
        frames?.forEach { it.applyTransformation(linearTransformation) }
        figuresAndLines?.forEach { it.applyTransformation(linearTransformation) }
    }
}

@Serializable
data class Selector(
    var options: MutableList<SelectorOption>? = null,
    var defaultText: String? = null, // if null - first option selected
) {
    fun clone(): Selector = copy(
        options = options?.map { it.clone() }?.toMutableList(),
    )

    fun clone(linearTransformation: LinearTransformation) = clone().apply {
        applyTransformation(linearTransformation)
    }

    fun applyTransformation(linearTransformation: LinearTransformation) {
        options?.forEach { it.applyTransformation(linearTransformation) }
    }
}

@Serializable
data class Sound(
    val src: String? = null,
    val altSrc: String?,
    val filename: String? = null,
    val badgePosition: String? = null, // Left, Right, LeftLine, RightLine
    val activation: String? = null, // ByClick, AfterDisplay
    val showCondition: ShowCondition? = null,
    val iconSize: Int = 32,
    val triggeredEventsByElement: MutableList<TriggeredEventByElement> = mutableListOf()
)

@Serializable
data class PhraseWord(
    var word: String,
    val hint: SimpleElement = SimpleElement("Hint"),
    val sound: Sound? = null
)

@Serializable
data class PhraseSettings(
    var isPartiallyInteractive: Boolean = false, // if "false" then all words as puzzles, if "true" then some words will be replaced with puzzle, selector or input
    var isRandom: Boolean = false,
    var selectors: MutableList<InteractiveElement> = mutableListOf(),
    var inputs: MutableList<InteractiveElement> = mutableListOf(),
    var puzzles: MutableList<InteractiveElement> = mutableListOf(),
) {
    fun clone(): PhraseSettings {
        return copy(
            selectors = selectors.clone(),
            inputs = inputs.clone(),
            puzzles = puzzles.clone()
        )
    }
}

@Serializable
data class Phrase(
    var type: String = "SentenceBuilder", // or ShuffledSentence
    var style: PhraseStyle = PhraseStyle(),
    var leftTop: Point = Point(0, 0),
    var width: Int = Int.MAX_VALUE,
    var words: MutableList<PhraseWord> = mutableListOf(),
    var extraWords: MutableList<PhraseWord> = mutableListOf(),
    val settings: PhraseSettings = PhraseSettings(),
) {
    fun clone(): Phrase {
        return copy(
            style = style.copy(),
            leftTop = leftTop.clone(),
            words = words.map { it.copy() }.toMutableList(),
            settings = settings.clone()
        )
    }
    fun getPhraseText(): String {
        return words.joinToString(" ") { it.word }
    }
}

@Serializable
data class InteractiveElement(
    var type: String = "Figure", // "Frame", "Figure", "Connection", "Button", "Input", "Selector", "GapPuzzle", "Phrase"
    var identifier: Identifier = Identifier(),

    var visibleElement: SimpleElement = SimpleElement("MainElement"),

    //TODO: may be hint should be null by default. Depends on do we want to render hint window by default or only after user add text in right panel
    var hint: SimpleElement = SimpleElement("Hint"), // if multiple hints needed -> create separate element for each hint

    var gapPuzzle: GapPuzzle? = null, // drag?

    var scores: MutableList<ScoreData>? = null,

    var input: Input? = null,
    var button: Button? = null,
    var selector: Selector? = null,
    var sound: Sound? = null,
    var phrase: Phrase? = null,
    var isSynced: Boolean? = null,
    var partOfWord: EWordParticle? = null
) : Copyable<InteractiveElement> {
    override fun clone() = copy(
        identifier = identifier.clone(),
        visibleElement = visibleElement.clone(),
        hint = hint.clone(),
        gapPuzzle = gapPuzzle?.clone(),
        input = input?.clone(),
        button = button?.clone(),
        selector = selector?.clone(),
        scores = scores?.map { it.clone() }?.toMutableList(),
        phrase = phrase?.clone()
    )


    fun clone(linearTransformation: LinearTransformation) = clone().apply {
        applyTransformation(linearTransformation)
    }

    fun applyTransformation(linearTransformation: LinearTransformation) {
        visibleElement.applyTransformation(linearTransformation)
        hint.applyTransformation(linearTransformation)
        gapPuzzle?.applyTransformation(linearTransformation)
        selector?.applyTransformation(linearTransformation)
    }
}