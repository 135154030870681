package widgets.AdminPanel.ui.components.CreateNewButton

import csstype.*
import utils.types.CssStyle
import utils.types.FitContent

val createNewButton: (String, String) -> CssStyle = { bgColor, bgColorHovered ->
    {
        backgroundColor = Color(bgColor)
        color = Color("white")
        borderRadius = 10.px
        gap = 2.px
        fontSize = 14.px
        lineHeight = 18.2.px
        padding = Padding(5.px, 20.px, 5.px, 10.px)
        height = FitContent.fitContent
        display = Display.flex
        alignItems = AlignItems.center
        hover {
            backgroundColor = Color(bgColorHovered)
            color = important(Color("white"))
        }
    }
}