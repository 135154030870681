package entities.interactivePicture.elements.editors.canvasSizeEditor

import csstype.*
import utils.types.CssStyle

val setupContainer: CssStyle = {
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.center
    marginLeft = 20.px
    border = Border(0.5.px, LineStyle.solid, Color("#D0D5DD"))
    borderRadius = 5.px
    width = 210.px
    height = 36.px
}

val setup: CssStyle = {
    borderWidth = 0.px
    width = 55.px
    height = 28.px
    fontFamily = string("\"Inter\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 14.px
    lineHeight = 17.px
    color = Color("#101828")
    flex = None.none
    order = integer(0)
    flexGrow = number(0.0)
    borderRadius = 5.px
}

val activeSetup: CssStyle = {
    backgroundColor = Color("#F4F7FF")
    width = 55.px
    height = 28.px
    fontFamily = string("\"Inter\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 14.px
    lineHeight = 17.px
    color = Color("#101828")
    flex = None.none
    order = integer(0)
    flexGrow = number(0.0)
    borderRadius = 4.px
    border = None.none
}

val container: CssStyle = {
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.center
    paddingLeft = 4.5.px
    paddingRight = 4.5.px
    width = 100.pct
    height = 100.pct
}
