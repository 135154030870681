package entities.interactivePicture.elements.editors.soundEditor

import csstype.*
import utils.types.CssStyle
import utils.types.extend

val inputHidden: CssStyle = {
    display = Display.block
    width = 0.px
    height = 0.px
}

val container: CssStyle = {
    padding = 20.px
    width = 210.px
}

val header: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    fontFamily = string("\"Arial\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 12.px
    lineHeight = 130.pct
    color = Color("#101828")
    flex = None.none
    order = integer(0)
    flexGrow = number(0.0)
    marginBottom = 10.px
}

val iconTextContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    gap = 4.px
}

val uploadButton: CssStyle = {
    border = None.none
    borderRadius = 5.px
    backgroundColor = Color("#F2F4F7")
    width = 45.pct
    color = Color("#101828")
    display = Display.flex
    justifyContent = JustifyContent.center
    alignItems = AlignItems.center
    padding = 0.px
    height = 35.px
    hover {
        color = important(Color("#101828"))
    }
}

val buttonsContainer: CssStyle = {
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
    width = 100.pct
    height = 35.px
    marginBottom = 10.px
}

val uploadFileText: CssStyle = {
    fontFamily = string("\"Arial\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 12.px
    lineHeight = 130.pct
    color = Color("#101828")
}

val filenameDeleteContainer = iconTextContainer.extend {
    gap = 16.px
}

val filenameText = uploadFileText.extend {
    overflow = Overflow.hidden
}

val filenameWrapContainer: CssStyle = {
    width = 100.pct
    backgroundColor = Color("#FFF")
    padding = 0.px
    paddingTop = 8.px
    marginBottom = 10.px
}

val deleteButton: CssStyle = {
    display = Display.flex
    border = None.none
    borderRadius = 5.px
    width = Auto.auto
    height = Auto.auto
    padding = 0.px
}

val verticalContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    width = 100.pct
    gap = 10.px
}

val selectContainer: CssStyle = {
    width = 100.pct
}

val selectHeader: CssStyle = {
    fontFamily = string("\"Arial\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 12.px
    lineHeight = 130.pct
    color = Color("#101828")
    marginBottom = 4.px
    width = 100.pct
}

val selector: CssStyle = {
    width = 100.pct
}
