package widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.FourShortPairsTaskLineEditor.components.WordMatchPairEditor

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.center
    gap = 12.px
}

val input: CssStyle = {
    width = 100.pct
}

val delimeter: CssStyle = {
    height = 1.px
    width = 15.px
    backgroundColor = Color("#000")

    position = Position.relative
    top = 12.px
}
