package widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.ThreeLongPairsTaskLineEditor.components.WordMatchLongPairEditor

import emotion.react.css
import online.interactiver.common.autogeneration.taskLineContent.WordMatchingContent
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.components.inputLabelledLimited.InputCounterPosition
import shared.components.inputLabelledLimited.InputLabelledLimited

external interface WordMatchLongPairEditorProps : Props {
    var value: WordMatchingContent.WordMatch?
    var onChange: ((WordMatchingContent.WordMatch) -> Unit)?
    var label: String?
    var maxWordLength: Int?
    var maxMatchLength: Int?
    var onEditSoundClick: ((String) -> Unit)?
}

val WordMatchLongPairEditor = FC<WordMatchLongPairEditorProps> { props ->
    div {
        css(container)
        InputLabelledLimited {
            css(input)
            placeholder = "Word"
            value = props.value?.word
            label = props.label
            onChange = {
                props.onChange?.invoke(
                    WordMatchingContent.WordMatch(
                        it,
                        props.value?.match ?: "undefined",
                    )
                )
            }
            counterPosition = InputCounterPosition.RIGHT
            limit = props.maxWordLength
            soundSrc = props.value?.soundSrc
            onEditSoundClick =  {
                props.value?.word?.let { props.onEditSoundClick?.invoke(it) }
            }
        }
        InputLabelledLimited {
            css(input)
            placeholder = "Match"
            value = props.value?.match
            onChange = {
                props.onChange?.invoke(
                    WordMatchingContent.WordMatch(
                        props.value?.word ?: "undefined",
                        it
                    )
                )
            }
            counterPosition = InputCounterPosition.RIGHT
            limit = props.maxMatchLength
        }
    }
}
