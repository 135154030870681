package builders.enums

enum class ESoundActivation(val textUi: String, val textValue: String) {
    // textUi and textValue separated make it possible to change UI names of positions
    // Keep textValue unchanged to make generated JSONs work forever!
    BY_CLICK("By click", "ByClick");
    // Uncomment when implemented
    //AFTER_DISPLAY("After display", "AfterDisplay");

    companion object {
        val DEFAULT = BY_CLICK
        fun fromString(string: String?): ESoundActivation {
            return ESoundActivation.values().find { it.textValue == string } ?: DEFAULT
        }
    }
}
