package widgets.languageAutoTask.ui.components.AddCustomSlideButton

import csstype.*
import utils.types.CssStyle
import utils.types.FitContent

val image: CssStyle = {
    width = 100.pct
}

val globContainer: CssStyle = {
    padding = Padding(8.px, 20.px, 30.px, 30.px)
}

val card: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    gap = 4.px

    width = 230.px

    transition = (0.1).s
    transitionTimingFunction = TransitionTimingFunction.easeOut

    cursor = Cursor.pointer

    hover {
        transform = scale(1.05,1.05)
    }
}

val cardLabel: CssStyle = {
    fontFamily = string("Inter, system-ui")
    fontWeight = FontWeight.bold
    fontSize = 16.px
    alignSelf = AlignSelf.start
}

val grid: CssStyle = {
    display = Display.grid
    gridTemplateColumns = repeat(4, Auto.auto)
    columnGap = 40.px
    rowGap = 40.px
}

val modalTitle: CssStyle = {
    fontFamily = string("Montserrat")
    fontWeight = FontWeight.bolder
    fontSize = 24.px
    color = Color("#282F3E")
    marginBottom = 40.px
}
