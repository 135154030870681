package shared.canvas.implementations

import Vector2d
import app.useAppImage
import kotlinx.browser.document
import online.interactiver.common.interactivepicture.Sound
import org.w3c.dom.HTMLAudioElement
import org.w3c.dom.HTMLSourceElement
import org.w3c.dom.events.Event
import react.*
import reactkonva.Image
import reactkonva.Rect
import shared.canvas.KonvaElementProps
import shared.components.iconsPath
import utils.types.impl

val TransformableSound = FC<KonvaElementProps> { props ->
    val style = props.style

    val imageRef = useRef<dynamic>()
    val (playImage, pauseImage) = useSoundImages()
    val (isPlaying, setIsPlaying) = useState(false)
    val audio = useAudio(props.data.sound) { setIsPlaying(it) }

    Rect {
        this.ref = props.elementRef
        this.width = props.bounds.width
        this.height = props.bounds.height
        this.onTransform =
            { event ->
                props.onTransform(event)
                val node = (props.elementRef as MutableRefObject<dynamic>).current
                val image = imageRef.current

                image.width(node.width())
                image.height(node.height())
                image.scaleX(1)
                image.scaleY(1)
            }
        this.onTransformStart = props.onTransformStart
        this.onTransformEnd = props.onTransformEnd
        this.stroke = style.strokeColor
        this.fill = style.fillColor
    }
    Image {
        this.ref = imageRef
        this.width = props.bounds.width
        this.height = props.bounds.height
        this.image = if (isPlaying) pauseImage else playImage
        this.strokeWidth = style.strokeWidth
        this.lineCap = style.lineCap
        this.lineJoin = style.lineJoin
        this.dash = style.dash?.toTypedArray()
        this.opacity = style.opacity
        this.shadowColor = style.shadowColor
        this.shadowBlur = style.shadowBlur
        this.shadowOffset = style.shadowOffset?.let { impl<Vector2d> { x = it.x; y = it.y } }
        this.shadowOpacity = style.shadowOpacity
        onClick = {
            onPlayPauseAudio(audio, isPlaying) { setIsPlaying(it) }
        }
    }
}

fun useSoundImages(): Pair<dynamic, dynamic> {
    val playPath = kotlinext.js.require("./$iconsPath/${"ic_play_44x44.svg"}").default
    val pausePath = kotlinext.js.require("./$iconsPath/${"ic_pause_44x44.svg"}").default
    val playImage = useAppImage(playPath as String)[0]
    val pauseImage = useAppImage(pausePath as String)[0]
    return Pair(playImage, pauseImage)
}

fun onPlayPauseAudio(audio: HTMLAudioElement, isPlaying: Boolean, onPlay: (Boolean) -> Unit) {
    if (isPlaying && audio.currentTime > 0) {
        audio.pause()
    } else {
        audio.play()
    }
    onPlay(!isPlaying)
}

fun useAudio(sound: Sound?, onPlay: (Boolean) -> Unit): HTMLAudioElement {
    val (audio) = useState(document.createElement("audio") as HTMLAudioElement)
    val (source) = useState(document.createElement("source") as HTMLSourceElement)
    val (altSource) = useState(document.createElement("source") as HTMLSourceElement)
    val src = sound?.src
    val altSrc = sound?.altSrc
    useEffect(src) {
        if (src != null) {
            source.setAttribute("src", src)
            cleanup {
                source.removeAttribute("src")
            }
        }
    }

    useEffect(altSrc) {
        if (altSrc != null) {
            altSource.setAttribute("src", altSrc)
            cleanup {
                altSource.removeAttribute("src")
            }
        }
    }

    useEffectOnce {
        audio.appendChild(source)
        audio.appendChild(altSource)
        val onEnded = { _: Event ->
            onPlay(false)
        }
        audio.addEventListener("ended", onEnded)

        cleanup {
            audio.removeChild(source)
            audio.removeChild(altSource)
            audio.removeEventListener("ended", onEnded)
        }
    }

    return audio
}
