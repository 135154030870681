package widgets.StudentDuoSlider.ui

import csstype.*
import kotlinx.browser.window
import utils.types.CssStyle
import utils.types.shadowArray
import widgets.StudentDuoLayout.headerFooterAndPaddingHeight

val studentDuoSlider: CssStyle = {
    width = 100.pct
    maxWidth = 511.px
    boxSizing = BoxSizing.borderBox
    backgroundColor = Color("white")
    borderRadius = 12.px
    boxShadow = shadowArray(
        BoxShadow(0.px, 3.px, 18.px, (-1).px, Color("#32324717")), BoxShadow(0.px, 0.px, 1.px, 0.px, Color("#0C1A4B3D"))
    )
    height = 100.pct
    display = Display.flex
    flexDirection = FlexDirection.column
    justifyContent = JustifyContent.spaceBetween
}

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    padding = 10.px
    gap = 28.px
    boxSizing = BoxSizing.borderBox
}

val iFrameContainer: (Double, Double) -> CssStyle = { width, height ->
    {
        this.height = height.px
        this.width = width.px
        display = Display.flex
        gap = 10.px
        justifyContent = JustifyContent.center
        alignSelf = AlignSelf.center
    }
}

val buttonsContainer: CssStyle = {
    display = Display.flex
    width = 100.pct
    alignItems = AlignItems.stretch
    justifyContent = JustifyContent.spaceBetween
}
