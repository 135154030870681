package entities.interactivePicture.elements.figuresAndLines.marker

import online.interactiver.common.interactivepicture.GeometryStyle
import online.interactiver.common.interactivepicture.TextStyle

val markerStyle = GeometryStyle(
    fillColor = "#1C274C",
    opacity = 1.0,
    cornerRadius = 5,
    strokeWidth = 0,
)

val markerTextStyle = TextStyle(
    textColor = "#FDFDFD",
    fontFamily = "Arial",
    fontStyle = "700",
    fontSize = 20,
    align = "center",
    padding = 1,
)
