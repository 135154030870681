package entities.interactivePicture.viewer.filter

import app.useAppDispatch
import builders.enums.EInteractiveType
import emotion.react.css
import entities.interactivePicture.viewer.SetViewerFilter
import entities.interactivePicture.viewer.filterButton
import react.FC
import react.Props
import shared.components.Icon

external interface FilterButtonProps : Props {
    var type: EInteractiveType
    var checked: Boolean
}

val FilterButton = FC<FilterButtonProps> { props ->
    val dispatch = useAppDispatch()

    fun changeFilter() {
        dispatch(SetViewerFilter(props.type, !props.checked))
    }

    antd.Button {
        css(filterButton)
        +props.type.filterButtonText
        onClick = { changeFilter() }


        if (props.checked) {
            Icon {
                src = "ic_check_24x24.svg"
            }
        }
    }
}