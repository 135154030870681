package entities.interactivePicture.drag.ui.tools

import app.useAppSelector
import builders.enums.EElementType
import emotion.react.css
import entities.interactivePicture.elements.gapPuzzles.drags.ui.editor.header
import entities.interactivePicture.elements.gapPuzzles.drags.ui.editor.wrapContainer
import entities.interactivePicture.selectFocusedElement
import react.FC
import react.MutableRefObject
import react.Props
import react.dom.html.ReactHTML
import reactkonva.Group
import reactkonva.Layer
import reactkonva.Rect
import reactkonva.Stage
import shared.canvas.implementations.BackgroundCutComponent
import shared.canvas.interfaces.resizeSavingRatio
import utils.structures.Position
import entities.interactivePicture.elements.editors.soundEditor.container

external interface CutFromBackgroundProps : Props {
    var stageRef: MutableRefObject<dynamic>
}


val WIDTH = 210;
val HEIGHT = 210;

val CutFromBackgroundEditor = FC<CutFromBackgroundProps> { props ->
    val selected = useAppSelector(selectFocusedElement)
    if (!selected?.visibleElement?.type.equals(EElementType.BACKGROUND_DRAG.text)) {
        return@FC;
    }


    val bounds = selected?.resizeSavingRatio(Position(0, 0, WIDTH, HEIGHT))

    ReactHTML.div {
        css(container)
        ReactHTML.div {
            css(header)
            +"Background Cut"
        }

        ReactHTML.div {
            css(wrapContainer)
            Stage {
                height = HEIGHT
                width = WIDTH
                Layer {
                    Rect {
                        x = 0
                        y = 0
                        height = HEIGHT
                        width = WIDTH
                        cornerRadius = 3
                        fill = "#F9F5F2"
                        opacity = 1
                        cornerRadius = 10;
                    }
                    Group {
                        x = bounds?.x
                        y = bounds?.y
                        BackgroundCutComponent {
                            this.stageRef = props.stageRef
                            this.elementRef = elementRef
                            this.bounds = bounds!!
                            this.data = selected
                            this.style = style
                            this.textStyle = textStyle
                        }
                    }
                }
            }
        }
    }
}