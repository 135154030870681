package shared.canvas

import app.useAppDispatch
import app.useAppSelector
import builders.enums.EStyleType
import entities.interactivePicture.guides.LineGuide
import entities.interactivePicture.guides.ObjectSnappingEdges
import entities.interactivePicture.guides.ui.canvas.updateGuidesOnDrag
import entities.lastCreatedType.SetLastCreatedType
import entities.lastCreatedType.selectLastCreatedType
import enums.EOrientation
import enums.ESnapType
import kotlinx.browser.document
import online.interactiver.common.interactivepicture.*
import react.*
import reactkonva.Arrow
import reactkonva.Circle
import reactkonva.Group
import shared.canvas.interfaces.getElementBounds
import shared.canvas.interfaces.getElementStyle
import shared.canvas.interfaces.norm
import shared.canvas.utils.getGuideLines
import shared.canvas.utils.setTargetPositionByGuide
import kotlin.math.abs

external interface LineTransformableProps : Props {
    var curve: Curve
    var style: ElementStyle?
    var onChange: ((Curve) -> Unit)?
    var onSelect: dynamic /* (evt: KonvaEventObject<MouseEvent>) -> Unit | (evt: KonvaEventObject<Event>) -> Unit */
    var isSelected: Boolean
    var data: InteractiveElement
    var horizontalRef: MutableRefObject<dynamic>
    var verticalRef: MutableRefObject<dynamic>
    var parentTrRef: MutableRefObject<dynamic>
    var isUnderSelectionRectangle: Boolean
    var guidesLines: MutableList<ObjectSnappingEdges>
}

val LineTransformable = FC<LineTransformableProps> { props ->

    val arrowRef = useRef<dynamic>()
    val startRef = useRef<dynamic>()
    val middleRef = useRef<dynamic>()
    val endRef = useRef<dynamic>()

    val lastCreatedType = useAppSelector(selectLastCreatedType)
    val dispatch = useAppDispatch()

    val (start, setStart) = useState(props.curve.start!!.absolutePosition!!)
    val (end, setEnd) = useState(props.curve.end!!.absolutePosition!!)
    val (middle, setMiddle) = useState(props.curve.middle!!.absolutePosition!!)
    val (isMiddleDragged, setIsMiddleDragged) = useState(false)

    val (style, setStyle) = useState(props.style?.usual)

    useEffect(props.curve) {
        val propStart = props.curve.start!!.absolutePosition!!
        val propMid = props.curve.middle!!.absolutePosition!!
        val propEnd = props.curve.end!!.absolutePosition!!
        setStart(propStart)
        setEnd(propEnd)
        setMiddle(propMid)
        setIsMiddleDragged(
            abs(propMid.x - (propStart.x + propEnd.x) / 2) > 1 ||
                    abs(propMid.y - (propStart.y + propEnd.y) / 2) > 1
        )
    }

    useEffect(props.isSelected, props.style) {
        setStyle(if (props.isSelected == true) props.style?.onFocus else props.style?.usual)
    }

    useEffect(style) {
        document.body?.style?.cursor = style?.mouseCursor ?: "default"
    }

    useEffect(props.isUnderSelectionRectangle) {
        if (props.parentTrRef == undefined) {
            return@useEffect
        }

        val tr = props.parentTrRef.current
        val oldNodes = (tr.nodes() as Array<dynamic>).toMutableList()
        if (props.isUnderSelectionRectangle) {
            oldNodes.add(arrowRef.current)
            val newNodes = oldNodes.toTypedArray()
            tr.nodes(newNodes)
            tr.getLayer()?.batchDraw()
            return@useEffect
        }

        if (!oldNodes.remove(arrowRef.current)) {
            return@useEffect
        }

        tr.nodes(oldNodes.toTypedArray())
        tr.getLayer()?.batchDraw()
    }

    var endPointBaseOnWidth = end

    if (props.curve.endType != null) {
        endPointBaseOnWidth = calculateEndPosition(style?.strokeWidth ?: 0, middle, end, 1)
    }

    Group {
        Arrow {
            ref = arrowRef
            points = arrayOf(start.x, start.y, middle.x, middle.y, endPointBaseOnWidth.x, endPointBaseOnWidth.y)
            pointerLength = 8
            pointerWidth = 7
            fill = style?.fillColor
            stroke = style?.fillColor
            strokeWidth = style?.strokeWidth
            opacity = style?.opacity
            dash = style?.dash?.toTypedArray()
            tension = 0.5
            pointerAtBeginning = props.curve.startType != null
            pointerAtEnding = props.curve.endType != null
            onClick = {
                props.onSelect(it.evt.shiftKey)
            }
            hitStrokeWidth =
                style?.strokeWidth?.plus(20) //конва создает 2 элемента. И вот hitStroke это ширина второго элемента, с которым происходит взаимодейтсвие
            draggable = true
            onDragStart = {
                if (!props.isSelected && !props.isUnderSelectionRectangle) {
                    props.onSelect(false)
                }
                if (lastCreatedType.type != null) {
                    dispatch(SetLastCreatedType(null))
                }
            }
            onDragMove = { e ->
                if (!props.isUnderSelectionRectangle) {
                    val arrow = e.target
                    val startMarker = startRef.current
                    val middleMarker = middleRef.current
                    val endMarker = endRef.current

                    val newStart = Point(arrow.x() + arrow.points()[0], arrow.y() + arrow.points()[1])
                    val newMiddle = Point(arrow.x() + arrow.points()[2], arrow.y() + arrow.points()[3])
                    var newEnd = Point(arrow.x() + arrow.points()[4], arrow.y() + arrow.points()[5])

                    if (props.curve.endType != null) {
                        newEnd = calculateEndPosition(style?.strokeWidth ?: 0, newMiddle, newEnd, -1)
                    }


                    startMarker.x(newStart.x)
                    startMarker.y(newStart.y)
                    middleMarker.x(newMiddle.x)
                    middleMarker.y(newMiddle.y)
                    endMarker.x(newEnd.x)
                    endMarker.y(newEnd.y)
                }
            }
            onDragEnd = { e ->
                val arrow = e.target

                val newStart = Point(arrow.x() + arrow.points()[0], arrow.y() + arrow.points()[1])
                val newMiddle = Point(arrow.x() + arrow.points()[2], arrow.y() + arrow.points()[3])
                var newEnd = Point(arrow.x() + arrow.points()[4], arrow.y() + arrow.points()[5])

                if (props.curve.endType != null) {
                    newEnd = calculateEndPosition(style?.strokeWidth ?: 0, newMiddle, newEnd, -1)
                }

                arrow?.x(0)
                arrow?.y(0)

                setStart(newStart)
                setMiddle(newMiddle)
                setEnd(newEnd)

                props.onChange?.invoke(
                    props.curve.copy(
                        start = Position(absolutePosition = newStart),
                        middle = Position(absolutePosition = newMiddle),
                        end = Position(absolutePosition = newEnd),
                    )
                )
            }

            onMouseEnter = {
                setStyle(props.data.getElementStyle(props.isSelected, EStyleType.HOVER))
            }
            onMouseLeave = {
                setStyle(props.data.getElementStyle(props.isSelected, EStyleType.USUAL))
            }
        }
        if (props.isSelected) {
            val lineBounds = props.data.getElementBounds()
            for (anchor in arrayOf("start", "middle", "end")) {
                val point = when (anchor) {
                    "start" -> start; "middle" -> middle; "end" -> end; else -> start
                }
                val setter = when (anchor) {
                    "start" -> setStart; "middle" -> setMiddle; "end" -> setEnd; else -> setStart
                }
                val ref = when (anchor) {
                    "start" -> startRef; "middle" -> middleRef; "end" -> endRef; else -> startRef
                }

                val getGuidesWithOppositeAnchor = a@{
                    if (anchor == "middle") {
                        return@a props.guidesLines
                    }
                    val oppositeDragPointForGuide = when (anchor) {
                        "start" -> props.curve.end!!.absolutePosition!!
                        else -> props.curve.start!!.absolutePosition!!
                    }
                    val oppositeGuides = ObjectSnappingEdges(
                        horizontal = listOf(LineGuide(
                            ownerUID = "",
                            position = oppositeDragPointForGuide.y,
                            snap = ESnapType.CENTER,
                            diff = 0,
                            offset = oppositeDragPointForGuide.y - lineBounds.y,
                            orientation = EOrientation.HORIZONTAL
                        )),
                        vertical = listOf(LineGuide(
                            ownerUID = "",
                            position = oppositeDragPointForGuide.x,
                            snap = ESnapType.CENTER,
                            diff = 0,
                            offset = oppositeDragPointForGuide.x - lineBounds.x,
                            orientation = EOrientation.VERTICAL
                        ))
                    )
                    props.guidesLines + oppositeGuides
                }

                val guidesWithOppositeAnchor = getGuidesWithOppositeAnchor()
                Circle {
                    this.ref = ref
                    key = anchor
                    x = point.x
                    y = point.y
                    radius = 6
                    fill = "#FFFFFF"
                    stroke = "#4096ff"
                    strokeWidth = 2
                    opacity = 0.5
                    draggable = true
                    onDragStart = {
                        if (lastCreatedType.type != null) {
                            dispatch(SetLastCreatedType(null))
                        }
                    }
                    onDragMove = f@{ e ->
                        val x = e.target.x()
                        val y = e.target.y()


                        val bounds = utils.structures.Position(x, y, 0, 0)
                        val guides = getGuideLines(
                            guidesWithOppositeAnchor,
                            props.data.identifier.id!!,
                            utils.structures.Position(x, y, bounds.width, bounds.height),
                            ESnapType.ALL, ESnapType.ALL
                        )

                        updateGuidesOnDrag(props.horizontalRef, props.verticalRef, guides)

                        if (!props.isUnderSelectionRectangle) {
                            setTargetPositionByGuide(guides, e.target)
                        }

                        setter(Point(e.target.x(), e.target.y()))

                        if (isMiddleDragged) {
                            return@f
                        }

                        if (point == middle) {
                            setIsMiddleDragged(true)
                            return@f
                        }

                        val newMiddle = if (point == start) {
                            Point((e.target.x() + end.x) / 2, (e.target.y() + end.y) / 2)
                        } else {
                            Point((e.target.x() + start.x) / 2, (e.target.y() + start.y) / 2)
                        }
                        setMiddle(newMiddle)
                    }
                    onDragEnd = { e ->
                        props.horizontalRef.current.opacity(0)
                        props.verticalRef.current.opacity(0)
                        setter(Point(e.target.x(), e.target.y()))
                        props.onChange?.invoke(
                            props.curve.copy(
                                start = Position(absolutePosition = start),
                                middle = Position(absolutePosition = middle),
                                end = Position(absolutePosition = end),
                            )
                        )
                    }
                    onMouseEnter = {
                        document.body?.style?.cursor = "move"
                    }
                    onMouseMove = onMouseEnter
                    onMouseLeave = {
                        document.body?.style?.cursor = "default"
                    }
                }
            }
        }
    }
}

fun calculateEndPosition(strokeWidth: Int, middlePoint: Point, endPoint: Point, scale: Int): Point {
    val direction = Point(endPoint.x - middlePoint.x, endPoint.y - middlePoint.y)
    val diff = Point(
        (direction.x / direction.norm() * strokeWidth).toInt(),
        (direction.y / direction.norm() * strokeWidth).toInt()
    )
    return Point(endPoint.x - diff.x * scale, endPoint.y - diff.y * scale)
}

