package widgets.Exercises.ui.components.PublicExerciseView

import Modal
import antd.Button
import app.useAppDispatch
import emotion.react.css
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import features.ShareButton.ui.ShareButton
import io.ktor.http.*
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.js.jso
import online.interactiver.common.autogeneration.LanguageAutoState
import pages.constructor.ui.components.elements.darkGreyFilter
import pages.languageAuto.SetHiddenIframeIsShown
import pages.languageAuto.UpdateCurrentLanguageAutoRequest
import pages.languageAuto.ui.ERoute
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.iframe
import react.router.dom.Link
import react.router.useLocation
import react.router.useNavigate
import shared.components.Icon
import shared.components.description.Description
import shared.components.header.Header
import utils.types.jsObject
import widgets.Exercises.createLanguageAutoRequestBasedOn
import widgets.LanguageAutoSlider.ui.iFrame
import widgets.LanguageAutoTask.ui.components.HiddenIframe.getIframeWithCookieScript
import widgets.LanguageAutoTask.ui.divider

external interface PublicExerciseViewProps : Props {
    var isOpen: Boolean
    var close: () -> Unit
    var request: LanguageAutoState?
    var isUserOwns: Boolean
    var userName: String?
}

val PublicExerciseView = FC<PublicExerciseViewProps> { props ->
    val dispatch = useAppDispatch()

    val navigate = useNavigate()
    val location = useLocation()

    val slider = props.request?.slider?.let { getIframeWithCookieScript(it, "") } ?: return@FC
    val link = props.request?.link ?: return@FC
    val embedCode = props.request?.embedCode ?: return@FC
    val requestId = props.request?.requestId ?: return@FC
    val userId = props.request?.userId ?: return@FC

    Modal {
        open = props.isOpen
        onCancel =  { props.close() }
        footer = null
        width = 900
        zIndex = 100
        bodyStyle = jso(modalStyle)

        div {
            css(iFrameContainer)
            iframe {
                css(iFrame)
                srcDoc = slider
                onLoad = {
                    dispatch(EndModalLoading())
                }
            }
        }

        div {
            css(divider)
        }

        Header {
            text = "Exercises: \"${props.request?.name}\""
        }

        Link {
            css(linkToAuthor)
            to = "/profile${if (props.isUserOwns) "" else "/$userId" }"
            Description {
                text = "Author: ${props.userName}"
                fontSize = 16.0
                lineHeight = 21.6
            }
        }

        div {
            css(infoContainer)
        }

        div {
            css(buttonsContainer)
            ShareButton {
                this.slider = slider
                this.link = link
                this.embedCode = embedCode
                this.requestId = requestId
                this.isUserOwns = props.isUserOwns
            }

            Button {
                css(copyAndChangeButton)
                Icon {
                    css(darkGreyFilter)
                    src = "ic_plus_32x32.svg"
                }
                onClick = {
                    dispatch(StartModalLoading("Getting content"))
                    GlobalScope.launch {
                        val response = createLanguageAutoRequestBasedOn(requestId)

                        dispatch(EndModalLoading())
                        if (response.code == HttpStatusCode.Unauthorized.value) {
                            navigate(ERoute.GOOGLE_AUTH.path, jso { state = jsObject { from = location.pathname } })
                            return@launch
                        }
                        if (response.data == null) {
                            return@launch
                        }

                        props.close()
                        dispatch(SetHiddenIframeIsShown(true))
                        dispatch(UpdateCurrentLanguageAutoRequest(response.data))
                        navigate("${ERoute.EXERCISE.path}/${response.data.requestId}")
                    }
                }
                +"Copy and change"
            }
        }
    }
}