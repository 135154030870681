package shared.canvas.implementations


import app.useAppImage
import react.FC
import react.MutableRefObject
import react.ref
import react.useRef
import reactkonva.Image
import shared.canvas.KonvaElementProps
import shared.components.iconsPath


val TransformableSelector = FC<KonvaElementProps> { props ->

    val imageRef = useRef<dynamic>()
    val path = kotlinext.js.require("./${iconsPath}/${"ic_arrow_down_24x24.svg"}").default
    val image = useAppImage(path)[0]

    val arrowPadding = 15
    val arrowWidth = 13

    TransformableRect {
        data = props.data
        style = props.style
        bounds = props.bounds
        elementRef = props.elementRef
        textRef = props.textRef

        this.onTransform =
            { event ->
                props.onTransform(event)
                val node = (props.elementRef as MutableRefObject<dynamic>).current
                val image = imageRef.current

                image.x(node.width() - arrowWidth - arrowPadding)
                image.y((node.height() - arrowWidth) / 2.0)
            }
        this.onTransformStart = props.onTransformStart
        this.onTransformEnd = props.onTransformEnd
    }


    Image {
        this.ref = imageRef
        this.y = (props.bounds.height - arrowWidth) / 2.0
        this.x = props.bounds.width - arrowWidth - arrowPadding
        this.width = arrowWidth
        this.height = arrowWidth
        this.image = image
    }

}