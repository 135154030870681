package shared.components.editableSound

import org.w3c.dom.HTMLAudioElement
import org.w3c.dom.events.Event
import react.*

data class AudioListenerHook(
    val play: () -> Unit,
    val pause: () -> Unit,
    val ref: RefObject<HTMLAudioElement>?,
    val isAudioPaused: Boolean
)

fun useAudioListener(): AudioListenerHook {
    val audioRef = useRef(null) as RefObject<HTMLAudioElement>?
    val (isAudioPaused, setIsAudioPaused) = useState(true)

    useEffect(audioRef?.current) {
        val onEnded = { _: Event ->
            setIsAudioPaused(true)
        }
        audioRef?.current?.addEventListener("ended", onEnded)

        cleanup {
            audioRef?.current?.removeEventListener("ended", onEnded)
        }
    }

    return AudioListenerHook(
        play = {
            audioRef?.current?.play()
            setIsAudioPaused(false)
        },
        pause = {
            audioRef?.current?.pause()
            setIsAudioPaused(true)
        },
        ref = audioRef,
        isAudioPaused = isAudioPaused
    )
}