package shared.components.header

import csstype.AlignItems
import csstype.Display
import csstype.FlexDirection
import csstype.px
import utils.types.CssStyle

val tooltipWrapper: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    gap = 8.px
    alignItems = AlignItems.center
}
