package widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.FourShortPairsTaskLineEditor.components.WordMatchPairEditor

import emotion.react.css
import online.interactiver.common.autogeneration.taskLineContent.WordMatchingContent
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.components.inputLabelledLimited.InputLabelledLimited

external interface WordMatchPairEditorProps : Props {
    var value: WordMatchingContent.WordMatch?
    var onChange: ((WordMatchingContent.WordMatch) -> Unit)?
    var wordLabel: String?
    var matchLabel: String?
    var maxWordLength: Int?
    var maxMatchLength: Int?
    var onEditSoundClick: ((String) -> Unit)?
}

val WordMatchPairEditor = FC<WordMatchPairEditorProps> { props ->
    div {
        css(container)
        InputLabelledLimited {
            css(input)
            placeholder = "Word"
            label = props.wordLabel
            value = props.value?.word
            onChange = {
                props.onChange?.invoke(
                    WordMatchingContent.WordMatch(
                        it,
                        props.value?.match ?: "undefined",
                    )
                )
            }
            limit = props.maxWordLength
            soundSrc = props.value?.soundSrc
            onEditSoundClick =  {
                props.value?.word?.let { props.onEditSoundClick?.invoke(it) }
            }
        }
        div {
            css(delimeter)
        }
        InputLabelledLimited {
            css(input)
            placeholder = "Match"
            label = props.matchLabel
            value = props.value?.match
            onChange = {
                props.onChange?.invoke(
                    WordMatchingContent.WordMatch(
                        props.value?.word ?: "undefined",
                        it
                    )
                )
            }
            limit = props.maxMatchLength
        }
    }
}
