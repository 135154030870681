package online.interactiver.common.duo.courseprogress

import kotlinx.serialization.Serializable

@Serializable
data class CourseProgress(
    val id: Int,
    val name: String,
    val numberOfSolvedExercises: Int,
    var languageToLearn: String? = null,
    val numberOfExercises: Int
)
