package widgets.SliderStatistics.ui.components.SliderStudentsTable

import antd.IColumn
import antd.Input
import antd.Spin
import antd.Table
import app.useDebouncedState
import csstype.*
import emotion.react.css
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.ETimePeriod
import online.interactiver.common.statistics.UserDetailedStatisticsOfSliderResponse
import pages.constructor.ui.components.elements.greenFilter
import pages.constructor.ui.components.elements.lightBlueFilter
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.section
import shared.components.Icon
import utils.types.extend
import utils.types.impl
import utils.types.jsObject
import widgets.SliderStatistics.getStatisticsWithDetailsAboutUsersRequest

external interface SliderStudentsTableProps : Props {
    var requestId: Int
    var timePeriod: ETimePeriod
}

val SliderStudentsTable = FC<SliderStudentsTableProps> { props ->
    val (isLoading, setIsLoading) = useState(true)
    val (studentsStatistics, setStudentsStatistics) = useState(listOf<UserDetailedStatisticsOfSliderResponse>())
    val (studentName, setStudentName) = useDebouncedState("", 300)

    val slidesCount = useMemo(studentsStatistics) {
        if (studentsStatistics.isEmpty()) 0 else studentsStatistics.first().slides.size
    }

    val tableColumns = useMemo(slidesCount) {
        val res = mutableListOf<IColumn>(
            impl {
                title = "Name"
                dataIndex = "name"
                align = "center"
                fixed = "left"
                width = "80px"
            },
            impl {
                title = "Total"
                dataIndex = "total"
                align = "center"
                fixed = "left"
                width = "80px"
            }
        )

        for (i in 0 until slidesCount) {
            res.add(impl {
                title = (i + 1).toString()
                dataIndex = "slide${i}"
                align = "center"
                render = render@{ slideStatus: Boolean? ->
                    if (slideStatus == null) {
                        return@render createElement(div)
                    }

                    return@render createElement(Icon, impl {
                        if (slideStatus == true) {
                            css(greenFilter)
                            src = "ic_checked_box_24x24.svg"
                        } else if (slideStatus == false) {
                            css(lightBlueFilter)
                            src = "ic_eye_24x24.svg"
                        }
                    })
                }
            })
        }
        return@useMemo res.toTypedArray()
    }

    fun UserDetailedStatisticsOfSliderResponse.toTableDataSource(): dynamic {
        val res = jsObject {}
        res["name"] = name
        res["total"] = slides.count { it == true }
        for (i in slides.indices) {
            res["slide${i}"] = slides[i]
        }
        return res
    }

    val tableDataSource = useMemo(studentsStatistics) {
        studentsStatistics.map { it.toTableDataSource() }.toTypedArray()
    }

    fun getStatistics(studentName: String) {
        setIsLoading(true)
        GlobalScope.launch {
            val response = getStatisticsWithDetailsAboutUsersRequest(
                props.requestId, props.timePeriod.value, studentName
            )
            setIsLoading(false)
            if (response == null) {
                return@launch
            }

            setStudentsStatistics(response)
        }
    }

    useEffect(props.requestId, props.timePeriod) {
        getStatistics(studentName)
    }

    section {
        css(widget)
        div {
            css(inputContainer)
            p {
                css(label)
                +"Student name"
            }
            Input {
                value = studentName
                placeholder = "Type name"
                onInput = {
                    val newName = it.currentTarget.value
                    setStudentName(newName) { getStatistics(newName) }
                }
            }
        }
        if (isLoading) {
            div {
                css {
                    display = Display.flex
                    alignItems = AlignItems.center
                    justifyContent = JustifyContent.center
                    width = 100.pct
                    height = 100.px
                }
                Spin {
                    size = "large"
                }
            }
        } else if (studentsStatistics.isNotEmpty()) {
            Table {
                pagination = false
                dataSource = tableDataSource
                columns = tableColumns
                scroll = impl {
                    // this makes slides columns min-width of 56px, which fits 32px of padding (by antd) and 24px icons width
                    this?.x = "${80 + 80 + slidesCount * 56}px"
                    if (tableDataSource.size > 10) {
                        this?.y = "670px" // it fits 10 rows in the viewport
                    }
                }
            }
        }
    }
}
