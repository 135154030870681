package shared.components.inputLabelledLimitedWithRadio

import antd.Input
import antd.TextArea
import builders.enums.EAlign
import emotion.react.css
import react.FC
import react.dom.html.ReactHTML.div
import react.useEffect
import react.useMemo
import react.useState
import shared.components.editableSound.EditableSound
import shared.components.inputLabelledLimited.Counter
import shared.components.inputLabelledLimited.DEFAULT_BUILD_PHRASE_RAW_LIMIT
import shared.components.inputLabelledLimited.InputLabelledLimitedProps
import shared.components.inputLabelledLimited.InputType
import shared.components.inputLabelledLimitedWithCheckbox.InputCounterPosition
import shared.components.radio.Radio

external interface InputLabelledLimitedWithRadioProps : InputLabelledLimitedProps {
    var onRadioChange: (String) -> Unit
    var radioCurrent: String
    var radioValue: String
}

val InputLabelledLimitedWithRadio = FC<InputLabelledLimitedWithRadioProps> { props ->
    val limit = useMemo(props.limit, callback = {
        props.limit ?: DEFAULT_BUILD_PHRASE_RAW_LIMIT
    })

    val counterPosition = useMemo(props.counterPosition, callback = {
        props.counterPosition ?: InputCounterPosition.TOP_RIGHT
    })

    val counterAlign = useMemo(props.counterAlign, callback = {
        props.counterAlign ?: EAlign.Horizontal.RIGHT
    })

    val inputType = useMemo(props.inputType, callback = {
        props.inputType ?: InputType.INPUT
    })

    val inputComponent = useMemo(inputType, callback = {
        when(inputType) {
            InputType.INPUT -> Input
            InputType.TEXTAREA -> TextArea
        }
    })

    val (value, setValue) = useState(props.value)

    useEffect(props.value) {
        setValue(props.value)
    }

    div {
        css(container)
        div {
            css(labelContainer)
            Radio {
                css(labelStyle)
                label = props.label
                current = props.radioCurrent
                onChange = props.onRadioChange
                this.value = props.radioValue
            }
            EditableSound {
                soundSrc = props.soundSrc
                onEditClick = props.onEditSoundClick

                this.patternMetaTags = props.patternMetaTags
                onPatternMetaTagsChange = props.onPatternMetaTagsChange
            }
            if (counterPosition == InputCounterPosition.TOP_RIGHT) {
                Counter {
                    this.value = value?.length
                    this.limit = limit
                    align = counterAlign
                }
            }
        }
        div {
            css(labelContainer)
            inputComponent {
                className = props.className
                this.value = value
                placeholder = props.placeholder
                onBlur = {
                    val value = it.target.value.take(limit)
                    props.onChange?.invoke(value)
                }
                onInput = {
                    setValue(it.currentTarget.value.take(limit))
                }
                disabled = props.disabled == true
                dir = "auto"
            }
            if (counterPosition == InputCounterPosition.RIGHT) {
                Counter {
                    this.value = value?.length
                    this.limit = limit
                    align = counterAlign
                }
            }
        }
    }
}
