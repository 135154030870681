package pages.constructor.ui.components.header.templates.preview

import csstype.*
import utils.inject
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column

    height = 100.vh.minus(90.px)
    marginBottom = (-20).px
}

val verticalContainer: CssStyle = {
    width = 100.pct
    gap = 10.px;
    display = Display.flex
    flexDirection = FlexDirection.column
}

val tagContainer: CssStyle = {
    width = 100.pct
    height = 20.px
    gap = 12.px
    paddingLeft = 8.px
    display = Display.flex
    flexDirection = FlexDirection.row
}

val window: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    height = 100.pct
}


val table: CssStyle = {
    marginTop = 32.px
    display = Display.flex
    flexDirection = FlexDirection.row
    gap = 36.px
    height = 100.pct
}

val leftColumn: CssStyle = {
    gap = 18.px

    flexGrow = number(1.0)

    display = Display.flex
    flexDirection = FlexDirection.column
}

val rightColumn: CssStyle = {
    width = 240.px

    gap = 24.px

    display = Display.flex
    flexDirection = FlexDirection.column
}

val header: CssStyle = {
    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)

    marginLeft = 10.px

    fontSize = 18.px
    lineHeight = 26.px
    color = Color("#282F3E")
}

val descText: CssStyle = {
    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)

    width = 200.px

    marginLeft = 10.px

    fontSize = 14.px
    lineHeight = 17.px
    color = Color("#5D6676")
}

val windowHeader: CssStyle = {
    height = 40.px
    borderTopLeftRadius = 16.px
    borderTopRightRadius = 16.px
    background = Color("#EDEFFD")
}

val windowBody: CssStyle = {
    height = 100.pct
    background = Color("#D9D9D9")
}

val htmlFrame: CssStyle = {
    height = 100.pct
    width = 100.pct
    border = Border(0.px, LineStyle.hidden)
    overflowX = Overflow.hidden
    margin = 0.px
    padding = 0.px
    overflow = Overflow.hidden
}


val backButton: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 10.px

    width = 150.px

    marginLeft = 10.px

    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 14.px
    lineHeight = 130.pct
    color = Color("#282F3E")

    border = None.none
    padding = 0.px

    transitionDuration = 0.s // because filter's transition is weird

    hover {
        color = important(Color("#FFFFFF"))
        filter = ("invert(52%) sepia(29%) saturate(5446%) " +
                "hue-rotate(207deg) brightness(96%) contrast(102%)").unsafeCast<FilterFunction>()
        backgroundColor = important(rgba(0, 0, 0, 0.0))
        transitionDuration = 0.s
    }
}


val buttonUse: CssStyle = {
    inject(backButton)

    color = Color("#FFFFFF")
    fontWeight = integer(700)
    justifyContent = JustifyContent.center
    width = 210.px
    marginLeft = 0.px
    padding = Padding(6.px, 12.px)
    backgroundColor = Color("#597EF7")
    height = 35.px

    hover {
        color = important(Color("#FFFFFF"))
        backgroundColor = important(Color("#597EF7"))
    }
}