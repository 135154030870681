package pages.studentDuoRoadMap.ui.StudentDuoRoadMap

import antd.Button
import csstype.*
import emotion.react.css
import online.interactiver.common.duo.DuoCourse
import online.interactiver.common.duo.EExerciseState
import pages.studentDuo.selectRequiredStudentDuoNativeLanguage
import pages.studentDuoRoadMap.ui.ExerciseButton.ExerciseButton
import react.*
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.section
import react.redux.useSelector
import react.router.useNavigate
import shared.components.loader.Loader
import utils.localization.Localization
import utils.types.extend
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton
import widgets.StudentDuoLayout.StudentDuoLayout

val StudentDuoRoadMap = FC<Props> {
    val nativeLanguage = useSelector(selectRequiredStudentDuoNativeLanguage)
    val navigate = useNavigate()
    val (course, setCourse) = useState<DuoCourse>()

    useDuoCourse {
        val indexOfLastSuccessfullySolvedExercise = it.states.indexOfLast {
            exercise -> exercise.state == EExerciseState.SOLVED.value
        }
        it.states.getOrNull(indexOfLastSuccessfullySolvedExercise + 1)?.state = EExerciseState.CURRENT.value
        setCourse(it)
    }

    StudentDuoLayout {
        buttons = FC {
            Button {
                css((if (course?.states?.any { it.state != EExerciseState.SOLVED.value } == true) {
                    newTaskButton
                } else {
                    generateButton
                }).extend {
                    flex = number(1.0)
                    borderRadius = 0.px
                    borderBottomRightRadius = 12.px
                    borderBottomLeftRadius = 12.px
                })
                onClick = {
                    navigate("/duo/content")
                }
                +Localization.getOtherTopicButtonTextWithLanguages(nativeLanguage)
            }
        }
        p {
            css(courseName)
            +(course?.name ?: "")
        }
        section {
            css(classNames = arrayOf(ClassName("scroll")), roadmap)
            if (course == null ) {
                Loader {}
                return@section
            }
            val indexOfCurrentExercise = course.states.indexOfFirst { it.state == EExerciseState.CURRENT.value }
            val indexOfExerciseToScroll = if (indexOfCurrentExercise == - 1) {
                course.states.size - 1
            } else {
                indexOfCurrentExercise - 2
            }
            course.states.forEachIndexed { index, duoExercise ->
                ExerciseButton {
                    scroll = indexOfExerciseToScroll == index
                    exercise = duoExercise
                    number = index + 1
                }
            }
        }
    }
}