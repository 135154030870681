package pages.constructor.ui.components.header.styleSettings.stylePicker

import utils.types.CssStyle
import csstype.*
import utils.inject

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.flexStart
    gap = 10.px
    alignSelf = AlignSelf.stretch
}
val header: CssStyle = {
    color = Color("#101828")
    fontFamily= string("\"Arial\", system-ui")
    fontSize = 12.px
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    lineHeight = 130.pct
}
val buttonContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.flexStart
    gap = 10.px
}