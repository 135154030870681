package features.GenerateAudiosForCourseButton.ui

import antd.Button
import app.useAppDispatch
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import features.GenerateAudiosForCourseButton.generateAudiosRequest
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.course.GenerateAudiosRequest
import pages.course.selectCourseState
import react.FC
import react.Props
import react.redux.useSelector
import shared.components.Icon
import widgets.CourseMaterials.SetCourseSentencesToGenerateExercises
import widgets.CourseMaterials.SetCourseWordsToGenerateExercises
import widgets.CourseMaterials.selectSentencesToGenerateExercises
import widgets.CourseMaterials.selectWordsToGenerateExercises
import widgets.CourseSettings.selectCourseInterfaceLanguage
import widgets.CourseSettings.selectCourseLanguageToLearn
import widgets.LanguageAutoContent.ui.generateButton

val GenerateAudiosForCourseButton = FC<Props> {
    val languageToLearn = useSelector(selectCourseLanguageToLearn)
    val sentences = useSelector(selectSentencesToGenerateExercises)
    val words = useSelector(selectWordsToGenerateExercises)

    val courseState = useSelector(selectCourseState)

    val dispatch = useAppDispatch()

    Button {
        css(generateButton)
        onClick = {
            dispatch(StartModalLoading("Generating audios..."))
            GlobalScope.launch {
                val response = generateAudiosRequest(courseState)

                dispatch(EndModalLoading())
                if (response.data == null) {
                    dispatch(OpenErrorModal("Error generating audios"))
                    return@launch
                }

                dispatch(SetCourseSentencesToGenerateExercises(response.data.sentences))
                dispatch(SetCourseWordsToGenerateExercises(response.data.words))
            }
        }
        Icon {
            src = "ic_magic_wand_24x24.svg"
        }
        +"Generate audios"
    }
}