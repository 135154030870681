package widgets.Exercises.ui.components.SharePlatformLinkButton

import antd.Button
import emotion.react.css
import kotlinx.browser.window
import kotlinx.js.timers.setTimeout
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useState
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoSlider.ui.components.ShareModal.folderPlatformLink
import kotlin.time.Duration.Companion.seconds

external interface SharePlatformLinkButtonProps : Props {
    var parentFolderId: Int?
}

val SharePlatformLinkButton = FC<SharePlatformLinkButtonProps> { props ->
    val (linkIsCopied, setLinkIsCopied) = useState(false)

    if (props.parentFolderId == null) {
        div {}
        return@FC
    }

    Button {
        css(generateButton)
        onClick = onClick@{
            if (linkIsCopied) {
                return@onClick
            }

            window.navigator.clipboard.writeText("$folderPlatformLink${props.parentFolderId}")
            setLinkIsCopied(true)
            setTimeout(1.seconds) {
                setLinkIsCopied(false)
            }
        }
        +if (linkIsCopied) "Link copied" else "Share with students"
    }
}