package pages.constructor.ui.components.canvas

import csstype.*
import utils.types.CssStyle

val canvasContainer: CssStyle = {
    this.width = 100.pct
    this.height = 100.pct

    display = Display.flex
    justifyContent = JustifyContent.center
    alignItems = AlignItems.center
    position = Position.relative

}

val container: CssStyle = {
    width = 100.pct
    height = 100.pct
    display = Display.flex
    justifyContent = JustifyContent.center
    alignItems = AlignItems.center
    position = Position.relative
}
