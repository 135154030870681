package widgets.CourseSettings.ui

import csstype.*
import utils.types.CssStyle

val courseSettings: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    width = 100.pct
    gap = 22.px
}
