package pages.constructor.ui.components.advancedElementsSettings.advancedSelectorSettings

import online.interactiver.common.utils.clone
import online.interactiver.common.interactivepicture.ScoreData
import online.interactiver.common.interactivepicture.SelectorOption
import react.useCallback

// AdvancedSelectorSettings uses internal options clone to make "Cancel" possible
// and that's why we need some logic based on native React hooks instead of redux

fun useAddScoreToOption(options: MutableList<SelectorOption>?): (Int) -> MutableList<SelectorOption>? {
    return useCallback(options) { optionIdx ->
        val newOptions = options?.clone()

        newOptions?.let {
            val newScore = ScoreData("", 0)
            if (it[optionIdx].scores?.add(newScore) == null) {
                it[optionIdx].scores = mutableListOf(newScore)
            }
        }

        return@useCallback newOptions
    }
}

fun useSetScoreValue(options: MutableList<SelectorOption>?): (Int, Int, Int) -> MutableList<SelectorOption>? {
    return useCallback(options) { optionIdx, scoreIdx, value ->
        val newOptions = options?.clone()

        newOptions?.let {
            it[optionIdx].scores?.get(scoreIdx)?.value = value
        }

        return@useCallback newOptions
    }
}

fun useSetScoreName(options: MutableList<SelectorOption>?): (Int, Int, String) -> MutableList<SelectorOption>? {
    return useCallback(options) { optionIdx, scoreIdx, name ->
        val newOptions = options?.clone()

        newOptions?.let {
            it[optionIdx].scores?.get(scoreIdx)?.name = name
        }

        return@useCallback newOptions
    }
}

fun useRemoveScore(options: MutableList<SelectorOption>?): (Int, Int) -> MutableList<SelectorOption>? {
    return useCallback(options) { optionIdx, scoreIdx ->
        val newOptions = options?.clone()

        newOptions?.let {
            it[optionIdx].scores?.removeAt(scoreIdx)
        }

        return@useCallback newOptions
    }
}
