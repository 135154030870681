package entities.dictionary.ui.phrase

import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.audio
import react.dom.html.ReactHTML.div
import shared.components.radio.Radio

external interface PhraseProps: Props {
    var phrase: String
    var textDescription: String
    var soundDescription: String?
    var phraseToSave: String
    var onChange: (String) -> Unit
    var id: String
    var radioValue: String
    var header: String
}

val Phrase = FC<PhraseProps> { props ->
    div {
        css(container)
        div {
            css(radioContainer)
            Radio {
                id = props.id
                value = props.radioValue
                current = props.phraseToSave
                onChange = {
                    props.onChange(it)
                }
            }
            div {
                css(phraseHeader)
                +props.header
            }
        }
        div {
            css(phraseContainer)
            div {
                div {
                    css(phraseStyle)
                    +"Phrase"
                }
                div {
                    css(phraseLabel)
                    +props.phrase
                }
            }
            div {
                div {
                    css(phraseStyle)
                    +"Hint"
                }
                div {
                    css(phraseLabel)
                    +props.textDescription
                }
            }
            div {
                div {
                    css(phraseStyle)
                    +"Sound"
                }
                div {
                    css(audioContainer)
                    val soundDescription = props.soundDescription
                    if (soundDescription != null) {
                        audio {
                            css(audioStyle)
                            controls = true
                            src = soundDescription
                        }
                    }
                }
            }
        }
    }
}