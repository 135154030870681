package widgets.Exercises.ui.components.UserActions

import Modal
import antd.Button
import app.useAppDispatch
import csstype.px
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.modalLoader.EndModalLoading
import entities.modalLoader.ShowSuccessfulLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.js.jso
import online.interactiver.common.accessgroupuser.Role
import online.interactiver.common.autogeneration.LanguageAutoGeneration
import online.interactiver.common.autogeneration.LanguageAutoState
import online.interactiver.common.enums.EAccessType
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import pages.constructor.ui.components.elements.grayFilter
import pages.languageAuto.*
import pages.languageAuto.ui.ERoute
import react.FC
import react.Props
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.router.dom.useSearchParams
import react.router.useNavigate
import react.useState
import shared.components.Icon
import shared.components.header.Header
import utils.types.extend
import widgets.Exercises.*
import widgets.Exercises.ui.components.UserActions.ExerciseMovingModal.ExerciseMovingModal
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoTask.ui.components.SlideEditModal.cancelButton

data class ModalWithCopiedContentMeta(val isOpen: Boolean = false, val text: String = "")

data class ActionMeta(
    val label: String,
    val icon: String,
    val onClick: (MouseEvent<HTMLDivElement, *>) -> Unit,
    val include: Boolean = true
)

external interface UserActionsProps : Props {
    var request: LanguageAutoGeneration?
    var closePopover: () -> Unit
    var openStatistics: () -> Unit
    var openShareModal: () -> Unit
    var setSelectedRequest: (LanguageAutoState) -> Unit
    var isPublic: Boolean
    var updateExercises: (() -> Unit)?
    var accessType: EAccessType?
}

val UserActions = FC<UserActionsProps> { props ->
    val dispatch = useAppDispatch()
    val navigate = useNavigate()

    val (modalWithCopiedContent, setModalWithCopiedContent) = useState(ModalWithCopiedContentMeta())
    val (isDeleteModalOpened, setIsDeleteModalOpened) = useState(false)
    val (movingModalIsOpened, setMovingModalIsOpened) = useState(false)

    val (searchParams, setSearchParams) = useSearchParams()

    if (props.request == null) {
        return@FC
    }

    Modal {
        open = modalWithCopiedContent.isOpen
        onCancel = {
            it.stopPropagation()
            setModalWithCopiedContent(ModalWithCopiedContentMeta())
        }
        footer = null
        p {
            css(header)
            +"The ${modalWithCopiedContent.text} has been successfully copied"
        }
    }

    Modal {
        open = isDeleteModalOpened
        onCancel = {
            it.stopPropagation()
            setIsDeleteModalOpened(false)
        }
        footer = null
        width = 445
        bodyStyle = jso(modalContainer)
        div {
            css(modalTextContainer)
            Header {
                text = "Are you sure you want to delete \n'${props.request!!.name}' exercise?"
            }
            p {
                css(deleteText)
                +"This action cannot be undone."
            }
        }
        div {
            css(modalButtonsContainer)
            Button {
                css(generateButton.extend {
                    width = 186.px
                })
                onClick = { evt: MouseEvent<HTMLButtonElement, *> ->
                    evt.stopPropagation()
                    setIsDeleteModalOpened(false)
                    props.closePopover()
                    dispatch(StartModalLoading("Deleting..."))
                    GlobalScope.launch {
                        val deleted = deleteRequest(props.request!!.requestId)
                        dispatch(EndModalLoading())
                        if (!deleted) {
                            OpenErrorModal(text = "Couldn't delete request")
                            return@launch
                        }

                        props.updateExercises?.invoke()
                    }
                }
                +"Delete"
            }
            Button {
                css(cancelButton)
                onClick = { evt: MouseEvent<HTMLButtonElement, *> ->
                    evt.stopPropagation()
                    setIsDeleteModalOpened(false)
                }
                +"Cancel"
            }
        }
    }

    ExerciseMovingModal {
        open = movingModalIsOpened
        onClose = {
            setMovingModalIsOpened(false)
        }
        generation = props.request
        onMoved = {
            if (it == null) {
                searchParams.delete("parent_folder_id")
            } else {
                searchParams.set("parent_folder_id", it.toString())
            }
            setSearchParams(searchParams)

            props.updateExercises?.invoke()
        }
    }

    div {
       css(container)
        listOf(
            ActionMeta(
                label = "Edit",
                icon = "ic_pencil_simple_24x24.svg",
                onClick = {
                    props.closePopover()
                    navigate("${ERoute.EXERCISE.path}/${props.request!!.requestId}")
                }
            ),
            ActionMeta(
                label = "Copy and edit",
                icon = "ic_plus_32x32.svg",
                onClick = {
                    dispatch(StartModalLoading("Getting content"))
                    GlobalScope.launch {
                        val response = createLanguageAutoRequestBasedOn(props.request!!.requestId, props.accessType)

                        dispatch(EndModalLoading())
                        if (response.data == null) {
                            return@launch
                        }

                        dispatch(SetHiddenIframeIsShown(true))
                        dispatch(UpdateCurrentLanguageAutoRequest(response.data))
                        navigate("${ERoute.EXERCISE.path}/${response.data.requestId}")
                    }
                }
            ),
            ActionMeta(
                label = "Statistics",
                icon = "ic_chart_32x22.svg",
                onClick = {
                    props.closePopover()
                    props.openStatistics()
                }
            ),
            ActionMeta(
                label = if (props.isPublic) "Make private" else "Add to community",
                icon = if (props.isPublic) "ic_hashtag_24x24.svg" else "ic_4_dots_16x16.svg",
                onClick = {
                    props.closePopover()
                    dispatch(StartModalLoading("Updating access type of slider..."))
                    GlobalScope.launch {
                        val isSaved = updateSliderAccessType(props.request!!.requestId, !props.isPublic)
                        if (props.isPublic) {
                            dispatch(ShowSuccessfulLoading("Removed from community!", "The exercise has been removed from the public community"))
                        } else {
                            dispatch(ShowSuccessfulLoading("Added to community!", "Other users can now use this exercise. Thank you for contribution!"))
                        }
                        if (!isSaved) {
                            OpenErrorModal(text = "Couldn't update access type")
                            return@launch
                        }

                        props.updateExercises?.invoke()
                    }
                }
            ),
            ActionMeta(
                label = "Share",
                icon = "ic_link_45deg_24x24.svg",
                onClick = {
                    props.closePopover()
                    dispatch(StartModalLoading("Getting content"))
                    GlobalScope.launch {
                        val request = getLanguageAutoRequest(props.request!!.requestId, false)

                        dispatch(EndModalLoading())
                        if (request == null) {
                            return@launch
                        }

                        props.setSelectedRequest(request)
                        props.openShareModal()
                    }
                }
            ),
            ActionMeta(
                label = "Delete",
                icon = "ic_delete_16x18.svg",
                onClick = {
                    setIsDeleteModalOpened(true)
                },
                include = props.accessType != EAccessType.SHARED || props.request?.role == Role.ADMIN.get
            ),
            ActionMeta(
                label = "Move",
                icon = "ic_move_24x24.svg",
                onClick = {
                    props.closePopover()
                    setMovingModalIsOpened(true)
                },
                include = props.accessType != EAccessType.SHARED
            )
        ).forEach { actionMeta ->
            if (!actionMeta.include) {
                return@forEach
            }

            div {
                css(action)
                onClick = actionMeta.onClick
                div {
                    css(iconContainer)
                    Icon {
                        css(grayFilter)
                        src = actionMeta.icon
                    }
                }
                +actionMeta.label
            }
        }
    }
}