package shared.canvas.implementations

import NodeConfig
import ShapeConfig
import entities.interactivePicture.elements.figuresAndLines.static.PHRASE_ELEMENT_HEIGHT
import entities.interactivePicture.elements.figuresAndLines.static.rectWithTextStyle
import entities.interactivePicture.elements.figuresAndLines.static.rectWithTextTextStyleOfPhrase
import entities.interactivePicture.elements.other.ui.tools.phrase.EPhraseElementsAlign
import entities.interactivePicture.elements.toOnHover
import entities.interactivePicture.guides.ObjectSnappingEdges
import entities.interactivePicture.guides.ui.canvas.updateGuidesOnDrag
import enums.ESnapType
import kotlinx.browser.document
import online.interactiver.common.interactivepicture.GeometryStyle
import online.interactiver.common.interactivepicture.InteractiveElement
import online.interactiver.common.interactivepicture.Point
import online.interactiver.common.interactivepicture.TextStyle
import react.FC
import react.MutableRefObject
import react.Props
import reactkonva.Group
import reactkonva.Rect
import reactkonva.Text
import shared.canvas.interfaces.getElementBounds
import shared.canvas.utils.getGuideLines
import shared.canvas.utils.hideGuideLines
import shared.canvas.utils.setTargetPositionByGuide
import utils.measureAsKonvaText
import utils.measurePuzzlesBlockHeight
import utils.structures.Position
import utils.types.style

external interface DraggablePhraseProps : Props {
    var element: InteractiveElement
    var isSelected: Boolean?
    var isUnderSelectionRectangle: Boolean?
    var onSelect: ((Boolean) -> Unit)?
    var onDragEnd: ((Point) -> Unit)?
    var guidesLines: List<ObjectSnappingEdges>
    var horizontalRef: MutableRefObject<dynamic>
    var verticalRef: MutableRefObject<dynamic>
}

const val PHRASE_LINE_ELEMENT_GAP = 6
const val PHRASE_LINE_WIDTH = 2
const val PHRASE_BOUNDS_PADDING = 5
const val PHRASE_LINE_SPACING_FOR_UNPLACED_PUZZLES = 10
const val PHRASE_PUZZLES_GROUP_PADDING = 12

private fun NodeConfig.injectParentPadding() {
    x?.let { x = it.toDouble() - PHRASE_BOUNDS_PADDING }
    y?.let { y = it.toDouble() - PHRASE_BOUNDS_PADDING }
}

private fun NodeConfig.injectChildPadding() {
    x?.let { x = it.toDouble() + PHRASE_BOUNDS_PADDING }
    y?.let { y = it.toDouble() + PHRASE_BOUNDS_PADDING }
}

val DraggablePhrase = FC<DraggablePhraseProps> { props ->
    if (props.element.phrase == null) {
        return@FC
    }
    val phrase = props.element.phrase!!
    val textStyle = rectWithTextTextStyleOfPhrase(phrase.style.elementPadding, phrase.style.fontSize)


    Group {
        x = phrase.leftTop.x
        y = phrase.leftTop.y

        injectParentPadding()

        onMouseEnter = {
            document.body?.style?.cursor = "pointer"
        }
        onMouseLeave = {
            document.body?.style?.cursor = "default"
        }
        draggable = true
        onClick = {
            props.onSelect?.invoke(it.evt.shiftKey)
        }
        onDragStart = {
            props.onSelect?.invoke(it.evt.shiftKey)
        }
        onDragMove = { event ->
            val x = event.target.x()
            val y = event.target.y()

            val bounds = props.element.getElementBounds()

            if (props.isUnderSelectionRectangle != true) {
                val guides = getGuideLines(
                    props.guidesLines,
                    props.element.identifier.id!!,
                    Position(x, y, bounds.width, bounds.height),
                    ESnapType.ALL,
                    ESnapType.ALL,
                )
                updateGuidesOnDrag(props.horizontalRef, props.verticalRef, guides)
                setTargetPositionByGuide(guides, event.target)
            }
        }
        onDragEnd = {
            props.onDragEnd?.invoke(
                Point(it.target.x() + PHRASE_BOUNDS_PADDING, it.target.y() + PHRASE_BOUNDS_PADDING)
            )
            hideGuideLines(props.horizontalRef, props.verticalRef)
        }

        val bounds = props.element.getElementBounds()
        Rect {
            if (props.isSelected == true || props.isUnderSelectionRectangle == true) {
                style(GeometryStyle().toOnHover())
            }
            x = 0
            y = 0
            width = bounds.width
            height = bounds.height
        }

        // The first line
        Rect {
            opacity = 1.0
            fill = "#333"

            x = 0
            y = PHRASE_ELEMENT_HEIGHT + PHRASE_LINE_ELEMENT_GAP
            width = phrase.width
            height = PHRASE_LINE_WIDTH

            injectChildPadding()
        }

        val align = EPhraseElementsAlign.fromString(phrase.style.elementsAlign)

        inline fun getStartX() = when(align) {
            EPhraseElementsAlign.LEFT -> 0
            EPhraseElementsAlign.RIGHT -> phrase.width
        }

        var curX = getStartX(); var curWidth = 0; var curY = 0
        phrase.words.forEach { word ->
            val (wordWidth, _) = word.word.measureAsKonvaText(textStyle)

            if (curWidth + wordWidth > phrase.width && curX > 0) {
                // New line
                Rect {
                    opacity = 1.0
                    fill = "#333"

                    x = 0
                    y = curY + 2 * PHRASE_ELEMENT_HEIGHT + 2 * PHRASE_LINE_ELEMENT_GAP + phrase.style.lineSpacing
                    width = phrase.width
                    height = PHRASE_LINE_WIDTH

                    injectChildPadding()
                }

                curX = getStartX(); curWidth = 0;
                curY += PHRASE_ELEMENT_HEIGHT + PHRASE_LINE_ELEMENT_GAP + phrase.style.lineSpacing
            }

            inline fun NodeConfig.position() {
                x = when(align) {
                    EPhraseElementsAlign.LEFT -> curX
                    EPhraseElementsAlign.RIGHT -> curX - wordWidth
                }
                y = curY
                width = wordWidth
                height = PHRASE_ELEMENT_HEIGHT

                injectChildPadding()
            }

            Rect {
                style(rectWithTextStyle)
                position()
            }
            Text {
                style(textStyle)
                position()
                text = word.word
            }

            when(align) {
                EPhraseElementsAlign.LEFT -> curX += wordWidth + phrase.style.elementSpacing
                EPhraseElementsAlign.RIGHT -> curX -= wordWidth + phrase.style.elementSpacing
            }
            curWidth += wordWidth + phrase.style.elementSpacing
        }

        UnplacedPuzzlesSpace {
            this.x = 0
            this.y = curY + PHRASE_ELEMENT_HEIGHT + PHRASE_LINE_ELEMENT_GAP + PHRASE_PUZZLES_GROUP_PADDING

            this.width = phrase.width
            this.height = phrase.measurePuzzlesBlockHeight()
            this.text = "Phrase's puzzles will be placed here"

            this.injectChildPadding = true
        }
    }
}

external interface UnplacedPuzzlesSpaceProps : Props {
    var x: Number?
    var y: Number?

    var width: Number?
    var height: Number?

    var text: String?

    var injectChildPadding: Boolean
}

val UnplacedPuzzlesSpace = FC<UnplacedPuzzlesSpaceProps> { props ->
    Group {
        x = props.x
        y = props.y

        if (props.injectChildPadding) {
            injectChildPadding()
        }

        fun ShapeConfig.size() {
            width = props.width
            height = props.height
        }
        val puzzlesBlockRectStyle = GeometryStyle(
            dash = mutableListOf(33.0, 10.0),
            opacity = 1.0,
            fillColor = "#fff",
            strokeColor = "#101828",
            strokeWidth = 1,
            cornerRadius = 3,
        )
        Rect {
            size()
            style(puzzlesBlockRectStyle)
        }
        val puzzlesBlockTextStyle = TextStyle(
            fontFamily = "Arial",
            fontSize = 14,
            fontStyle = "400",
            textColor = "#5D6676",
            align = "center",
            verticalAlign = "middle",
            padding = (props.width ?: 0).toInt() / 10,
        )
        Text {
            size()
            style(puzzlesBlockTextStyle)
            text = props.text
        }
    }
}
