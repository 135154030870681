package widgets.languageAutoTask.ui.components.SlideEditModal.patternRadioPicker

import emotion.react.css
import online.interactiver.common.interactiveexercise.EExerciseUiPattern
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.components.radio.Radio

external interface PatternRadioPickerProps : Props {
    var value: EExerciseUiPattern?
    var onChange: ((EExerciseUiPattern) -> Unit)?
    var patterns: List<EExerciseUiPattern>?
    var taskLineContent: String
}

val PatternRadioPicker = FC<PatternRadioPickerProps> { props ->
    div {
        div {
            css(header)
            +"Switch template"
        }
        div {
            css(grid)
            props.patterns?.map {
                Radio {
                    value = it.shortName
                    onChange = { shortName ->
                        props.onChange?.let { onChange ->
                            val pattern = EExerciseUiPattern.fromShortName(shortName)
                            pattern?.let { onChange(it) }
                        }
                    }
                    label = it.uiLabel
                    current = props.value?.shortName ?: ""

                    tooltip = it.uiTooltip
                    //disabled = !it.canBeBuiltFrom(props.taskLineContent, isOpenAiFormat = false)
                }
            }
        }
    }
}
