package online.interactiver.common.autogeneration

import kotlinx.serialization.Serializable
import online.interactiver.common.interactivepicture.SliderGreetingScreenTitleWithScoreSettings

@Serializable
data class UpdateSliderSettingsRequest(
    val task: String,
    val continueButtonLink: String,
    val continueButtonText: String,
    val shareButtonLink: String,
    val shareButtonText: String,
    val titlesWithScoreSettings: List<SliderGreetingScreenTitleWithScoreSettings>,
    val enableGamifiedPopups: Boolean,
    val enableThirdPassage: Boolean,
    val askStudentName: String
)
