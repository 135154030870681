package shared.canvas

import Box
import app.useAppDispatch
import app.useAppSelector
import builders.enums.EElementType
import builders.enums.EStyleType
import entities.interactivePicture.guides.ObjectSnappingEdges
import entities.interactivePicture.guides.getEmptyLineGuide
import entities.interactivePicture.guides.ui.canvas.updateGuidesOnDrag
import entities.lastCreatedType.SetLastCreatedType
import entities.lastCreatedType.selectLastCreatedType
import enums.EOrientation
import enums.ESnapType
import kotlinx.browser.document
import online.interactiver.common.interactivepicture.*
import org.w3c.dom.HTMLTextAreaElement
import react.*
import reactkonva.Group
import reactkonva.Transformer
import shared.canvas.interfaces.*
import shared.canvas.utils.*
import shared.components.textArea.focusTextArea
import shared.components.textArea.hideTextArea
import shared.components.textArea.setTextArea
import shared.components.textArea.updateTextArea

const val HINT_BADGE_RADIUS = 15
const val HINT_BADGE_OFFSET = 5

external interface TransformableProps : Props {
    var data: InteractiveElement
    var isSelected: Boolean
    var onSelect: (Boolean) -> Unit /* (evt: KonvaEventObject<MouseEvent>) -> Unit | (evt: KonvaEventObject<Event>) -> Unit */
    var onChange: (Geometry) -> Unit
    var drawable: FC<KonvaElementProps>
    var horizontalRef: MutableRefObject<dynamic>
    var verticalRef: MutableRefObject<dynamic>
    var parentTrRef: MutableRefObject<dynamic>
    var isUnderSelectionRectangle: Boolean
    var guidesLines: MutableList<ObjectSnappingEdges>
}

external interface KonvaElementProps : Props {
    var data: InteractiveElement
    var style: GeometryStyle
    var textStyle: TextStyle?
    var bounds: utils.structures.Position
    var elementRef: Ref<dynamic>
    var stageRef: MutableRefObject<dynamic>
    var textRef: Ref<dynamic>
    var onTransformStart: (dynamic) -> Unit
    var onTransform: (dynamic) -> Unit
    var onTransformEnd: (dynamic) -> Unit
    var isUnderSelectionRectangle: Boolean
}

val Transformable = FC<TransformableProps> { props ->
    val groupRef = useRef<dynamic>()
    val textRef = useRef<dynamic>()
    val trRef = useRef<dynamic>()
    val elementRef = useRef<dynamic>()
    val soundRef = useRef<dynamic>()

    val lastCreatedType = useAppSelector(selectLastCreatedType)
    val dispatch = useAppDispatch()

    val (startAnchor, setStartAnchor) = useState("")
    val (anchorVerticalLine, setAnchorVerticalLine) = useState(getEmptyLineGuide(EOrientation.VERTICAL))
    val (anchorHorizontalLine, setAnchorHorizontalLine) = useState(getEmptyLineGuide(EOrientation.HORIZONTAL))
    val bounds = props.data.getElementBounds()


    val (hintPreviewRect, setHintPreviewRect) = useState(
        Rect(
            leftTopPosition = Position(
                absolutePosition = Point(0, 0)
            ), width = bounds.width, height = bounds.height
        )
    )

    useEffect(bounds.width, bounds.height) {
        setHintPreviewRect(
            Rect(
                leftTopPosition = Position(
                    absolutePosition = Point(0, 0)
                ), width = bounds.width, height = bounds.height
            )
        )
    }

    val (style, setStyle) = useState(props.data.getElementStyle(props.isSelected, EStyleType.USUAL))
    useEffect(props.isSelected, props.data.sound) {
        if (!props.isUnderSelectionRectangle) {
            setStyle(props.data.getElementStyle(props.isSelected, EStyleType.USUAL))
        }

        if (props.isSelected && elementRef.current) {
            var nodes = mutableListOf(elementRef.current)
            if (soundRef.current != null) {
                nodes.add(soundRef.current)
            }
            trRef.current?.nodes(nodes.toTypedArray())
            trRef.current?.getLayer()?.batchDraw()
        }
    }
    val textStyle = props.data.visibleElement.text?.style ?: TextStyle()

    val (textArea, _) = useState(document.createElement("textarea") as HTMLTextAreaElement)
    setTextArea(textStyle, textRef.current, props.isSelected, textArea, props.isUnderSelectionRectangle, props.data)


    useEffect(style) {
        document.body?.style?.cursor = style.mouseCursor ?: "default"
    }

    useEffect(props.data.visibleElement.style) {
        if (!props.isUnderSelectionRectangle) {
            setStyle(props.data.getElementStyle(props.isSelected, EStyleType.USUAL))
        } else {
            setStyle(props.data.getElementStyle(props.isSelected, EStyleType.FOCUS))
        }
    }

    useEffect(props.isUnderSelectionRectangle) {
        if (props.parentTrRef == undefined) {
            return@useEffect
        }

        val tr = props.parentTrRef.current
        val oldNodes = (tr.nodes() as Array<dynamic>).toMutableList()
        if (props.isUnderSelectionRectangle) {
            setStyle(props.data.getElementStyle(false, EStyleType.FOCUS))
            oldNodes.add(groupRef.current)
            val newNodes = oldNodes.toTypedArray()
            tr.nodes(newNodes)
            tr.getLayer()?.batchDraw()
            return@useEffect
        }
        setStyle(props.data.getElementStyle(false, EStyleType.USUAL))

        if (!oldNodes.remove(groupRef.current)) {
            return@useEffect
        }

        tr.nodes(oldNodes.toTypedArray())
        tr.getLayer()?.batchDraw()
    }

    // TODO: Вынести в отдельный метод

    val stage = elementRef.current?.getStage()
    var hintBadgeHorizontalAnchor = HintBadgeHorizontalAnchor.Right
    var hintBadgeVerticalAnchor = HintBadgeVerticalAnchor.Top
    if (stage != null) {
        if (bounds.x + bounds.width + HINT_BADGE_OFFSET + 2 * HINT_BADGE_RADIUS > stage.width() as Int) {
            hintBadgeHorizontalAnchor = HintBadgeHorizontalAnchor.Left
        }
        if (bounds.y - HINT_BADGE_OFFSET - 2 * HINT_BADGE_RADIUS < 0) {
            hintBadgeVerticalAnchor = HintBadgeVerticalAnchor.Bottom
        }
    }

    updateTextArea(stage, textRef.current, props.isSelected, textArea, bounds, textStyle, props.isUnderSelectionRectangle, props.data)

    val onTransformStart = a@{ event: dynamic ->
        val text = textRef.current
        hideTextArea(text, textArea)
        if (lastCreatedType.type != null) {
            dispatch(SetLastCreatedType(null))
        }
    }

    val onTransform = a@{ event: dynamic ->
        val group = groupRef.current
        val node = elementRef.current
        val text = textRef.current

        // Здесь у нас может быть изменение якоря ( Например если взять за правый конец и перенести его левее левого)
        // Необходимо пересчитать текущие якори
        val selectedAnchor = trRef.current._movingAnchorName
        calculateAnchorLine(trRef, anchorVerticalLine, bounds, setAnchorVerticalLine, startAnchor)
        calculateAnchorLine(trRef, anchorHorizontalLine, bounds, setAnchorHorizontalLine, startAnchor)
        setStartAnchor(selectedAnchor.toString())
        
        val transformedBounds =
            onTransformElement(
                node,
                group,
                text,
                props.data,
                anchorVerticalLine,
                anchorHorizontalLine,
                EOrientation.NONE
            )
        onTransformSetHint(node, setHintPreviewRect)

        val guides = getGuideLines(
            props.guidesLines,
            props.data.identifier.id!!,
            transformedBounds,
            anchorToPreferredLine(selectedAnchor, EOrientation.VERTICAL),
            anchorToPreferredLine(selectedAnchor, EOrientation.HORIZONTAL)
        )

        updateGuidesOnDrag(props.horizontalRef, props.verticalRef, guides)
        val leader = setTargetShapeByGuide(guides, node, anchorVerticalLine, anchorHorizontalLine)
        onTransformElement(node, group, text, props.data, anchorVerticalLine, anchorHorizontalLine, leader)
        return@a
    }


    val onTransformEnd = { event: dynamic ->
        val group = groupRef.current
        val target = elementRef.current
        onTransform(event)

        if (props.data.isMarker()) {
            target.x(target.width() / 2)
            target.y(target.height() / 2)
        }

        focusTextArea(textArea)

        setStartAnchor("")
        setAnchorVerticalLine(getEmptyLineGuide(EOrientation.VERTICAL))
        setAnchorHorizontalLine(getEmptyLineGuide(EOrientation.HORIZONTAL))

        val newBounds = utils.structures.Position(group.x(), group.y(), target.width(), target.height())

        props.onChange(
            props.data.getGeometryClone(
                newBounds
            )
        )

        hideGuideLines(props.horizontalRef, props.verticalRef)
    }



    Fragment {
        Group {
            ref = groupRef
            onClick = {
                props.onSelect(it.evt.shiftKey)
            }
            onTap = {
                props.onSelect(false)
            }
            draggable = true

            //val x = if (!props.data.isMarker()) bounds.x else bounds.x + bounds.width / 2
            //val y = if (!props.data.isMarker()) bounds.y else bounds.y + bounds.height / 2

            this.x = bounds.x
            this.y = bounds.y


            onMouseEnter = {
                if (!props.isUnderSelectionRectangle) {
                    setStyle(props.data.getElementStyle(props.isSelected, EStyleType.HOVER))
                }
            }
            onMouseLeave = {
                if (!props.isUnderSelectionRectangle) {
                    setStyle(props.data.getElementStyle(props.isSelected, EStyleType.USUAL))
                }
            }
            onDragStart = {
                if (!props.isSelected && !props.isUnderSelectionRectangle) {
                    props.onSelect(false)
                }
                hideTextArea(textRef.current, textArea)
                if (lastCreatedType.type != null) {
                    dispatch(SetLastCreatedType(null))
                }
            }

            onDragEnd = { event ->
                val x = event.target.x()
                val y = event.target.y()

                focusTextArea(textArea)

                val newBounds = utils.structures.Position(x, y, bounds.width, bounds.height)

                props.onChange(
                    props.data.getGeometryClone(
                        newBounds
                    )
                )
                hideGuideLines(props.horizontalRef, props.verticalRef)
            }

            onDragMove = a@{ event ->
                val x = event.target.x()
                val y = event.target.y()

                if (!props.isUnderSelectionRectangle) {
                    val guides = getGuideLines(
                        props.guidesLines,
                        props.data.identifier.id!!,
                        utils.structures.Position(x, y, bounds.width, bounds.height),
                        ESnapType.ALL,
                        ESnapType.ALL,
                    )
                    updateGuidesOnDrag(props.horizontalRef, props.verticalRef, guides)
                    setTargetPositionByGuide(guides, event.target)
                }
            }

            props.drawable {
                this.elementRef = elementRef
                this.onTransform = onTransform
                this.onTransformStart = onTransformStart
                this.onTransformEnd = onTransformEnd
                this.textRef = textRef
                this.data = props.data
                this.style = style
                this.bounds = bounds
                this.isUnderSelectionRectangle = props.isUnderSelectionRectangle
            }

            val hint = props.data.getHintText()
            if (!hint.trim().equals("")) {

                if (props.isSelected || props.isUnderSelectionRectangle) {
                    HintPreview {
                        this.x = 0
                        this.y = 0
                        this.text = hint
                        visible = true
                        rect = hintPreviewRect
                        this.textStyle = props.data.getHintTextStyle()
                        geometryStyle = props.data.getHintGeometryStyle()
                        element = props.data
                        isUnderSelectionRectangle = props.isUnderSelectionRectangle
                    }
                } else {
                    HintBadge {
                        this.x = 0
                        this.y = 0
                        rect = hintPreviewRect
                        horizontalAnchor = hintBadgeHorizontalAnchor
                        verticalAnchor = hintBadgeVerticalAnchor
                        radius = HINT_BADGE_RADIUS
                        offset = HINT_BADGE_OFFSET
                    }
                }
            }
            val elementType = EElementType.values().find { props.data.visibleElement.type == it.text }
            val sound = props.data.sound
            if (sound != null && elementType?.usesSoundBadge == true) {
                Sound {
                    ref = soundRef
                    this.sound = sound
                    parentRef = elementRef
                    parentBounds = bounds
                    iconSize = props.data.sound?.iconSize
                }
            }
        }

        if (props.isSelected) {
            Transformer {
                ref = trRef
                boundBoxFunc = lambda@{ oldBox: Box, newBox: Box ->
                    if (newBox.width.toInt() < 5 || newBox.height.toInt() < 5) {
                        return@lambda oldBox
                    }
                    return@lambda newBox
                }
                val anchors = arrayListOf("top-left", "top-right", "bottom-right", "bottom-left")
                if (!props.data.isFixedRatio()) {
                    arrayOf(
                        "middle-left",
                        "middle-right",
                        "top-center",
                        "bottom-center"
                    ).forEach { anchors.add(it) }
                }
                enabledAnchors = anchors.toTypedArray()
                keepRatio = props.data.isFixedRatio()
                rotateEnabled = false
            }
        }
    }
}
