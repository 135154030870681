package widgets.UserProfile.ui.components.UserNameWIthPhoto

import csstype.*
import utils.types.CssStyle
import utils.types.FitContent

val container: CssStyle = {
    display = Display.flex
    gap = 14.px
    alignItems = AlignItems.flexEnd
    justifyContent = JustifyContent.spaceBetween
    width = 100.pct
}

val userPhotoWithName: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 12.px
    height = FitContent.fitContent
}