package pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewCheckingSettings

import emotion.react.css
import enums.EButtonTypes
import pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.LinkSettingsContext
import react.VFC
import react.dom.html.ReactHTML
import react.useContext
import shared.components.checkbox.ButtonColor
import shared.components.checkboxWithLabel.CheckboxWithLabel

val CheckingSettings = VFC {
    val context = useContext(LinkSettingsContext)

    ReactHTML.div {
        ReactHTML.div {
            css(settingsHeader)
            +"CHECKING SETTINGS"
        }
        CheckboxWithLabel {
            id = EButtonTypes.INSTANT_CHECK_PREVIEW_SETTINGS_BUTTON.value
            this.checked = context.instantCheck
            label = "Instant check"
            onChange = {
                context.setInstantCheck?.invoke(it)
            }
            tooltipTitle = "Automatic check without buttons \"Check\" and \"Restart\""
            iconFilter = ButtonColor.BLUE
            width = 16
            height = 16
        }
    }
}