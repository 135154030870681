package widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders

import csstype.*
import utils.types.CssStyle

val radioPickersContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.stretch
    alignItems = AlignItems.start
    gap = 24.px
}
