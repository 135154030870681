package entities.interactivePicture.elements.editors.ScoreEditor.ui

import antd.Button
import app.appState.selectAppState
import app.useAppDispatch
import app.useAppSelector
import builders.enums.EInteractiveType
import emotion.react.css
import entities.interactivePicture.elements.editors.ScoreEditor.enum.EScoreEditorType
import entities.interactivePicture.selectFocusedElement
import enums.EButtonTypes
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.components.Icon

external interface ScoreEditorProps : Props {
    var type: EScoreEditorType
    var id: EButtonTypes
}

val ScoreEditor = FC<ScoreEditorProps> { props ->

    val selected = useAppSelector(selectFocusedElement)
    val appState = useAppSelector(selectAppState)
    val dispatch = useAppDispatch()
    val scores = selected?.scores
    val conditions = selected?.visibleElement?.showCondition?.scoreConditions

    if (selected == null) {
        return@FC
    }

    if (props.type == EScoreEditorType.SCORE && !shouldDrawScore(selected.type)) {
        return@FC
    }

    val uid = selected.identifier.id!!
    val drawDeleteButton = (if (props.type == EScoreEditorType.SCORE) scores?.size ?: 1 > 1 else conditions?.size ?: 0 > 0)

    div {
        css(container)
        div {
            css(header)
            +(props.type!!.header)
        }


        conditions?.mapIndexed { index, condition ->
            if (props.type == EScoreEditorType.SCORE) {
                return@mapIndexed
            }

            ScoreConditionInput {
                this.conditionIndex = index
                this.elementUID = uid
                this.condition = condition
            }
        }

        scores?.mapIndexed { index, score ->
            if (props.type == EScoreEditorType.SCORING) {
                return@mapIndexed
            }
            ScoreInput {
                this.scoreIndex = index
                this.elementUID = uid
                this.score = score
            }
        }

        if (drawDeleteButton) {
            Button {
                css(deleteButton)
                type = "link"
                +("Delete " + (props.type!!.shortName).toLowerCase())
                onClick = {
                    when (props.type) {
                        EScoreEditorType.SCORE -> dispatch(appState.deleteScoreData(uid, scores?.size!! - 1))
                        EScoreEditorType.SCORING -> dispatch(appState.deleteScoreCondition(uid, conditions?.size!! - 1))
                    }
                }
            }
        }

        Button {
            css(button)
            id = props.id.value
            onClick = {
                when (props.type) {
                    EScoreEditorType.SCORE -> dispatch(appState.addScoreData(uid))
                    EScoreEditorType.SCORING -> dispatch(appState.addScoreCondition(uid))
                }
            }
            Icon {
                src = "ic_plus_24x24.svg"
            }
            +("Add " + (props.type!!.addText).toLowerCase())
        }
    }
}

fun shouldDrawScore(type: String): Boolean {
    return type in EInteractiveType.values().filter { it.shouldDrawScore }.map { it.text };
}