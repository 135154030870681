package widgets.LanguageAutoTask.ui.components.SlideEditModal

import Modal
import antd.Button
import app.useAppDispatch
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.languageAutoCurrentRequest.selectCurrentRequestId
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.iframe
import react.dom.html.ReactHTML.section
import react.redux.useSelector
import shared.components.editSoundModal.EditSoundModal
import shared.components.header.Header
import utils.types.FitContent
import widgets.LanguageAutoTask.ui.components.HiddenIframe.*
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.TaskLineEditor

external interface SlideEditModalProps : Props {
    var closeModal: () -> Unit
    var slideIndex: Int?
    var taskLine: String
    var saveSlide: ((String, String) -> Unit)?
    var generateTask: (String) -> String
    var show: Boolean
    var slider: String
}

val SlideEditModal = FC<SlideEditModalProps> { props ->
    val (newTask, setNewTask) = useState(props.taskLine)
    val (slider, setSlider) = useState("")
    val (wordForNewSound, setWordForNewSound) = useState<String?>(null)

    val requestId = useSelector(selectCurrentRequestId)

    val dispatch = useAppDispatch()

    val update = useCallback(props.generateTask, requestId, callback = { actualTask: String ->
        if (requestId == null) return@useCallback

        val newTasks = props.generateTask(actualTask)
        dispatch(StartModalLoading("Preparing the content"))

        GlobalScope.launch {
            val fileContentWithGptAnswer = generateSliderWithNewSlide(newTasks, requestId)
            val tempSlider = fileContentWithGptAnswer?.fileContent
            if (tempSlider == null) {
                dispatch(OpenErrorModal())
                dispatch(EndModalLoading())
                return@launch
            }

            setSlider(tempSlider)

            val task = fileContentWithGptAnswer?.gptAnswer
            if (props.slideIndex != null) {
                val taskForThisSlider = task?.split("\n")?.get(props.slideIndex!!)?.substringAfter(".")?.trim()
                taskForThisSlider?.let { setNewTask(it) }
            }
        }
    })

    useEffect(props.taskLine) {
        setNewTask(props.taskLine)
    }

    useEffect(props.slideIndex, props.slider) {
        if (props.slideIndex == null) {
            return@useEffect
        }

        setSlider(props.slider)
    }

    val slideIndex = props.slideIndex

    if (slideIndex == null || requestId == null) {
        return@FC
    }

    EditSoundModal {
        isOpen = wordForNewSound != null
        onCancelClick = { setWordForNewSound(null) }
        onSaveSoundClick = f@{
            if (wordForNewSound == null) {
                return@f
            }

            dispatch(StartModalLoading("Saving new audio..."))
            GlobalScope.launch {
                val saved = saveNewSound(it, wordForNewSound)
                if (!saved) {
                    dispatch(EndModalLoading())
                    dispatch(OpenErrorModal(text = "Error saving new sound for $wordForNewSound"))
                    return@launch
                }

                setWordForNewSound(null)
                update(newTask)
            }
        }
    }

    Modal {
        open = props.show
        onCancel = { props.closeModal() }
        footer = null
        width = FitContent.fitContent
        zIndex = 100

        section {
            css(modal)

            Header {
                text = "Edit exercise"
            }

            div {
                css(container)
                div {
                    css(iFrameContainer)
                    iframe {
                        css(iFrame)
                        onLoad = {
                            dispatch(EndModalLoading())
                        }
                        srcDoc = getIframeWithCookieScript(slider, hideControlsButtonsForOneSlide(slideIndex))
                    }
                }
                div {
                    css(slideEditContainer)
                    TaskLineEditor {
                        taskLine = newTask
                        onChange = { setNewTask(it); console.log(it) }
                        slide = getInteractivePictureFromSliderByIndex(slider, slideIndex)
                        onEditSoundClick = { setWordForNewSound(it) }
                        onPatternChange = {
                            setNewTask(it); console.log(it)
                            update(it)
                        }
                    }

                    Button {
                        css(updateButton)
                        onClick = {
                            update(newTask)
                        }
                        +"Update"
                    }
                    div {
                        css(buttonsContainer)
                        Button {
                            css(cancelButton)
                            onClick = {
                                props.closeModal()
                            }
                            +"Cancel"
                        }
                        Button {
                            css(updateButton)
                            disabled = slider == props.slider
                            onClick = {
                                props.saveSlide?.invoke(newTask, slider)
                            }
                            +"Save & Apply"
                        }
                    }
                }
            }
        }
    }
}
