package widgets.StudentDuoContent.ui.SuggestedTopicsModal

import csstype.*
import utils.types.CssStyle

val button: CssStyle = {
    position = Position.absolute
    bottom = 14.px
    left = 50.pct
    transform = translatex((-50).pct)
    width = 80.pct
    color = Color("#4096ff")
}
