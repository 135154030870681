package entities.interactivePicture.guides

import enums.EOrientation
import enums.ESnapType
import kotlinx.serialization.Serializable

// Source Code:
// https://konvajs.org/docs/sandbox/Objects_Snapping.html

val GUIDELINE_OFFSET = 5;

@Serializable
data class LineGuide(
    var ownerUID: String, // UID владельца, чтобы исключить выравнивание самого по себе
    var position: Int, // Позиция линии относительно ориентации
    var offset: Int, // Величина отступа линии, от линии start в этой ориентации
    var diff: Int, // Расстояние в пикселях до ближайшей линии
    var snap: ESnapType, // Тип линии в ориентации: start - center - end
    var orientation: EOrientation // Ориентация линии: horizontal - vertical

) {
    fun clone() = copy()

    fun clone(snap: ESnapType) = clone().apply {
        applyTransformation(snap)
    }

    fun switchSnap() = clone().apply {
        applyTransformation(getOppositeAnchor(snap))
    }

    fun applyTransformation(snap: ESnapType) {
        this.snap = snap
    }
}

fun getOppositeAnchor(anchor: ESnapType): ESnapType {
    if (anchor == ESnapType.START) {
        return ESnapType.END
    }

    return ESnapType.START
}

fun getEmptyLineGuide(orientation: EOrientation): LineGuide {
    return LineGuide("", 0, 0, 0, ESnapType.SKIP, orientation)
}

data class ObjectSnappingEdges(
    val horizontal: List<LineGuide>, val vertical: List<LineGuide>
)

