package online.interactiver.common.api

import kotlinx.serialization.Serializable

//general for all requests
@Serializable
data class RequestParams(
    var debug: Boolean = false, // if true server will provide detail info during request handling and in the result
    var generateHtmlInReadableFormat: Boolean = true, // if true server will return human readable .html as the result of generation
    var includeHtmlHeader: Boolean = true, // if true server will include html header; otherwise only part to be embedded in html
    var includeLibrariesCodeToWorkOffline: Boolean = false, // if true server will include libraries code; otherwise only includes

    var generateHtmlSharedPart: Boolean = true, // one per html page: if we add more than one interactive on one page, functions mustn't be repeated to avoid errors
    var generateHtmlIndividualPart: Boolean = true, // one per each interactive on the page
    var reloadWindowOnRestart: Boolean = false,
    var shrinkTextToFitInElement: Boolean = true,

    var doNotDecreaseInteractiveSizeFromOriginal: Boolean = false,  // if true check buttons and puzzles do not decrease size of interactive to embed it in page
) {
    fun clone() = copy()
}