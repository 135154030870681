package widgets.StudentDuoLayout

import csstype.*
import kotlinx.browser.window
import utils.types.CssStyle
import utils.types.shadowArray

val page: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    justifyContent = JustifyContent.spaceBetween
    height = 100.pct
    width = 100.pct
    maxWidth = 605.px
    minHeight = 90.vh
    boxSizing = BoxSizing.borderBox
    backgroundColor = Color("white")
    borderRadius = 12.px
    boxShadow = shadowArray(
        BoxShadow(0.px, 3.px, 18.px, (-1).px, Color("#32324717")), BoxShadow(0.px, 0.px, 1.px, 0.px, Color("#0C1A4B3D"))
    )
}

val footer: CssStyle = {
    display = Display.flex
    width = 100.pct
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
}

const val headerFooterAndPaddingHeight = 51 + 72 + 20

internal val content: CssStyle = {
    minHeight = (window.innerHeight - headerFooterAndPaddingHeight).px
}
