package entities.languageAutoCurrentRequest

import online.interactiver.common.autogeneration.LanguageAutoState
import online.interactiver.common.enums.EContentType
import online.interactiver.common.interactivepicture.SliderGreetingScreenTitleWithScoreSettings
import pages.languageAuto.LanguageAutoStoreState
import pages.languageAuto.selectCurrentRequest
import redux.RAction

open class LanguageAutoCurrentRequestAction : RAction

data class UpdateTaskInCurrentRequest(val task: String): LanguageAutoCurrentRequestAction()

data class UpdateSliderInCurrentRequest(val slider: String): LanguageAutoCurrentRequestAction()

data class UpdateCurrentRequestLink(val link: String, val embedCode: String) : LanguageAutoCurrentRequestAction()

data class SetCurrentRequestId(val requestId: Int) : LanguageAutoCurrentRequestAction()

data class SetCurrentRequestTextOrTopic(val textOrTopic: String) : LanguageAutoCurrentRequestAction()

data class SetCurrentRequestVocabulary(val vocabulary: String) : LanguageAutoCurrentRequestAction()

data class SetCurrentRequestLanguageToLearn(val languageToLearn: String) : LanguageAutoCurrentRequestAction()

data class SetCurrentRequestInterfaceLanguage(val interfaceLanguage: String) : LanguageAutoCurrentRequestAction()

data class SetCurrentRequestLanguageLevel(val languageLevel: String) : LanguageAutoCurrentRequestAction()

data class SetCurrentRequestContentType(val contentType: String) : LanguageAutoCurrentRequestAction()

data class SetCurrentRequestName(val name: String) : LanguageAutoCurrentRequestAction()

data class SetTranslateRequestIntoTargetLang(val value: Boolean) : LanguageAutoCurrentRequestAction()

data class SetCurrentRequestSoundSrc(val soundSrc: String?) : LanguageAutoCurrentRequestAction()
data class SetCurrentRequestAskStudentName(val askStudentName: String) : LanguageAutoCurrentRequestAction()
data class UpdateCurrentRequestSettings(
    val continueLink: String,
    val continueText: String,
    val shareLink: String,
    val shareText: String,
    val titlesWithScoreSettings: List<SliderGreetingScreenTitleWithScoreSettings>,
    val askStudentName: String,
    val enableGamifiedPopups: Boolean,
    val enableThirdPassage: Boolean
) : LanguageAutoCurrentRequestAction()

val LanguageAutoCurrentRequestReducer = { state: LanguageAutoState, action: LanguageAutoCurrentRequestAction ->
    when (action) {
        is UpdateCurrentRequestSettings -> state.copy(
            continueButtonLink = action.continueLink,
            continueButtonText = action.continueText,
            shareButtonText = action.shareText,
            shareButtonLink = action.shareLink,
            titleWithScoreSettings = action.titlesWithScoreSettings,
            askStudentName = action.askStudentName,
            enableGamifiedPopups = action.enableGamifiedPopups,
            enableThirdPassage = action.enableThirdPassage
        )
        is UpdateTaskInCurrentRequest -> state.copy(task = action.task)
        is UpdateSliderInCurrentRequest -> state.copy(slider = action.slider)
        is UpdateCurrentRequestLink -> state.copy(link = action.link, embedCode = action.embedCode)
        is SetCurrentRequestId -> state.copy(requestId = action.requestId)
        is SetCurrentRequestTextOrTopic -> state.copy(textOrTopic = action.textOrTopic)
        is SetCurrentRequestVocabulary -> state.copy(vocabulary = action.vocabulary)
        is SetCurrentRequestLanguageToLearn -> state.copy(languageToLearn = action.languageToLearn)
        is SetCurrentRequestInterfaceLanguage -> state.copy(interfaceLanguage = action.interfaceLanguage)
        is SetCurrentRequestLanguageLevel -> state.copy(languageLevel = action.languageLevel)
        is SetCurrentRequestContentType -> state.copy(contentType = action.contentType)
        is SetCurrentRequestName -> state.copy(name = action.name)
        is SetTranslateRequestIntoTargetLang -> state.copy(
            translateRequestToTargetLang = action.value
        )
        is SetCurrentRequestSoundSrc -> state.copy(soundSrc = action.soundSrc)
        is SetCurrentRequestAskStudentName -> state.copy(askStudentName = action.askStudentName)

        else -> state
    }
}

val selectCurrentRequestSlider = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).slider
}

val selectCurrentRequestTask = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).task
}

val selectCurrentRequestId = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).requestId
}

val selectCurrentRequestTextOrTopic = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).textOrTopic
}

val selectCurrentRequestVocabulary = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).vocabulary
}

val selectCurrentRequestLanguageLevel = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).languageLevel
}

val selectCurrentRequestLanguageToLearn = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).languageToLearn
}

val selectCurrentRequestInterfaceLanguage = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).interfaceLanguage
}

val selectCurrentRequestContentType = { state: LanguageAutoStoreState ->
    EContentType.fromString(selectCurrentRequest(state).contentType)
}

val selectCurrentRequestLink = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).link
}

val selectCurrentRequestEmbedCode = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).embedCode
}

val selectCurrentRequestSliderName = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).name
}

val selectCurrentRequestContinueButtonText = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).continueButtonText
}

val selectCurrentRequestContinueButtonLink = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).continueButtonLink
}

val selectCurrentRequestTitlesWithScore = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).titleWithScoreSettings
}

val selectAskStudentName = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).askStudentName
}

val selectTranslateRequestToTargetLang = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).translateRequestToTargetLang
}

val selectNumberOfSlidesToGenerate = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).numberOfSlidesToGenerate
}

val selectCurrentRequestSoundSrc = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).soundSrc
}

val selectCurrentRequestHasEnabledGamifiedPopups = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).enableGamifiedPopups
}

val selectCurrentRequestHasEnabledThirdPassage = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).enableThirdPassage
}

val selectCurrentRequestShareButtonText = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).shareButtonText
}

val selectCurrentRequestShareButtonLink = { state: LanguageAutoStoreState ->
    selectCurrentRequest(state).shareButtonLink
}
