package pages.constructor.ui.components.editors.spacingBetweenPicker

import antd.Button
import app.appState.selectAppState
import app.useAppDispatch
import app.useAppSelector
import builders.enums.ESpacingBetween
import entities.interactivePicture.selectElementsUnderSelectionRectangle
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import emotion.react.css
import pages.constructor.ui.components.editors.alignElementsPicker.*
import react.key
import redux.RAction
import shared.canvas.interfaces.getElementBounds
import shared.components.Icon

val SpacingBetweenElementsPicker = FC<Props> {
    val dispatch = useAppDispatch()
    val appState = useAppSelector(selectAppState)
    val allElementOfSelectedGroup = useAppSelector(selectElementsUnderSelectionRectangle)
    val leftBounds: MutableList<Int> = emptyList<Int>().toMutableList()
    val rightBounds: MutableList<Int> = emptyList<Int>().toMutableList()
    val widthBetween:  MutableList<Int>
    val topBounds: MutableList<Int> = emptyList<Int>().toMutableList()
    val bottomBounds: MutableList<Int> = emptyList<Int>().toMutableList()
    val heightBetween:  MutableList<Int>
    var arrayWidthToOffset: Array<Int> = emptyArray()
    var arrayWidthAvgToOffset: Array<Int> = emptyArray()
    var arrayHeightToOffset: Array<Int> = emptyArray()
    var arrayHeightAvgToOffset: Array<Int> = emptyArray()
    var elementsIdsToOffsetHorizontal: Array<String> = emptyArray()
    var elementsIdsToOffsetVertical: Array<String> = emptyArray()
    if (allElementOfSelectedGroup.isNotEmpty()) {
        allElementOfSelectedGroup.forEach{
            leftBounds.add(it.getElementBounds().x)
            rightBounds.add(it.getElementBounds().x.plus(it.getElementBounds().width))
            topBounds.add(it.getElementBounds().y)
            bottomBounds.add(it.getElementBounds().y.plus(it.getElementBounds().height))
        }
        leftBounds.sort(); rightBounds.sort(); topBounds.sort(); bottomBounds.sort()

        leftBounds.removeAt(0); topBounds.removeAt(0)
        rightBounds.removeAt(rightBounds.lastIndex); bottomBounds.removeAt(bottomBounds.lastIndex)
        widthBetween = leftBounds.zip(rightBounds){left, right -> left - right}.toMutableList()
        heightBetween = topBounds.zip(bottomBounds){top, bottom -> top - bottom}.toMutableList()

        widthBetween.forEach {
            val last = arrayWidthToOffset.lastOrNull() ?: 0
            arrayWidthToOffset += it - widthBetween.min() + last
        }

        widthBetween.forEach {
            val last = arrayWidthAvgToOffset.lastOrNull() ?: 0
            arrayWidthAvgToOffset += it - widthBetween.average().toInt() + last
        }

        heightBetween.forEach {
            val last = arrayHeightToOffset.lastOrNull() ?: 0
            arrayHeightToOffset += it - heightBetween.min() + last
        }

        heightBetween.forEach {
            val last = arrayHeightAvgToOffset.lastOrNull() ?: 0
            arrayHeightAvgToOffset += it - heightBetween.average().toInt() + last
        }

        leftBounds.forEach {
            allElementOfSelectedGroup.forEach { element ->
                if (element.getElementBounds().x == it){
                    elementsIdsToOffsetHorizontal += element.identifier.id!!
                }
            }
        }

        topBounds.forEach {
            allElementOfSelectedGroup.forEach { element ->
                if (element.getElementBounds().y == it){
                    elementsIdsToOffsetVertical += element.identifier.id!!
                }
            }
        }
    }

    class SpacingBetweenMetadata(
        val label: String,
        val iconSrc: String,
        val value: Array<Int>,
        val elementsIds: Array<String>,
        val actionFactory: (String, Int) -> RAction
    )
    div {
        css(containerAlignElementsForHeader)
        div {
            css(alignElementsHeader)
            +"Spacing between"
        }
    }
    div {
        css(buttonContainerColumn)
        arrayOf(
            SpacingBetweenMetadata(ESpacingBetween.Horizontal.MINIMUM.label, "ic_horizontal_spacing_18x18.svg",arrayWidthToOffset, elementsIdsToOffsetHorizontal, appState::getSetElementsSpaceBetweenHorizontal),
            SpacingBetweenMetadata(ESpacingBetween.Vertical.MINIMUM.label, "ic_vertical_spacing_18x18.svg", arrayHeightToOffset, elementsIdsToOffsetVertical, appState::getSetElementsSpaceBetweenVertical),
            SpacingBetweenMetadata(ESpacingBetween.Horizontal.AVERAGE.label, "ic_horizontal_spacing_18x18.svg",arrayWidthAvgToOffset, elementsIdsToOffsetHorizontal, appState::getSetElementsSpaceBetweenHorizontal),
            SpacingBetweenMetadata(ESpacingBetween.Vertical.AVERAGE.label, "ic_vertical_spacing_18x18.svg", arrayHeightAvgToOffset, elementsIdsToOffsetVertical, appState::getSetElementsSpaceBetweenVertical),
        ).map {
            Button {
                css(buttonForAlignElements)
                key = it.iconSrc
                Icon {
                    src = it.iconSrc
                }
                div {
                    css(iconText)
                    +it.label
                }
                onClick = {
                    for (index in it.value.indices){
                        dispatch(it.actionFactory(it.elementsIds[index], it.value[index]))
                    }
                }
            }
        }
    }

}