package pages.constructor.ui.components.modalVersionDiff

import Modal
import antd.Button
import emotion.react.css
import kotlinx.js.jso
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import utils.types.impl

external interface ModalVersionDiffProps : Props {
    var show: Boolean
    var setShow: StateSetter<Boolean>
    var update: () -> Unit
    var copyAndUpdate: () -> Unit
}

val ModalVersionDiff = FC<ModalVersionDiffProps> { props ->
    Modal {
        css(modalTextStyle)

        centered = true
        open = props.show
        footer = createElement(FooterModalVersionDiff, impl{
            setShow = props.setShow
            update = props.update
            copyAndUpdate = props.copyAndUpdate
        })

        closable = false
        width = "328px"
        bodyStyle = jso(modalBodyStyle)
        maskStyle = jso(modalMaskStyle)

        div {
            p { +"Your version of project is not latest. Somebody edited project later than you." }
            p { +"So your version of project can not be saved and has to be updated on the latest version." }
            p { +"You can copy your current version into new project." }
        }
    }
}

external interface FooterModalVersionDiffProps : Props {
    var setShow: StateSetter<Boolean>
    var update: () -> Unit
    var copyAndUpdate: () -> Unit
}

val FooterModalVersionDiff = FC<FooterModalVersionDiffProps> { props ->
    div {
        css(modalFooterStyle)
        Button{
            onClick = {
                props.update()
                props.setShow(false)
            }
            +"Just Update"
        }
        Button{
            onClick = {
                props.copyAndUpdate()
                props.setShow(false)
            }
            +"Copy and Update"
        }
    }
}
