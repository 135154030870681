package widgets.LanguageAutoContent.ui.components.Content

import antd.TextArea
import app.useAppDispatch
import csstype.*
import emotion.react.css
import entities.errorModal.store.EErrorType
import entities.errorModal.store.selectErrorType
import entities.languageAutoCurrentRequest.SetCurrentRequestTextOrTopic
import entities.languageAutoCurrentRequest.SetCurrentRequestVocabulary
import entities.languageAutoCurrentRequest.selectCurrentRequestTextOrTopic
import entities.languageAutoCurrentRequest.selectCurrentRequestVocabulary
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.redux.useSelector
import shared.components.header.Header
import utils.types.extend
import utils.types.jsObject
import widgets.LanguageAutoContent.countVocabulary

const val vocabularyPlaceholder = "little cat, big dog, cold, warm, rain, to eat, tasty food, weather"
const val minNumberOfVocabulary = 4

val VocabularyContent = FC<Props> {
    val vocabulary = useSelector(selectCurrentRequestVocabulary)
    val textOrTopic = useSelector(selectCurrentRequestTextOrTopic)
    val dispatch = useAppDispatch()
    val errorType = useSelector(selectErrorType)
    val (hasError, setHasError) = useState(false)

    useEffect(errorType) {
        if (errorType == EErrorType.NOT_ENOUGH_VOCABULARY) {
            setHasError(true)
        }
    }

    div {
        css(contentInputContainer)
        div {
            css(headerContainer)
            Header {
                text = "Target vocabulary - at least $minNumberOfVocabulary words"
                fontSize = 14.0.px
                lineHeight = 18.9.px
                fontWeight = integer(600)

                tooltip = createElement(FC {
                    div {
                        +"Write the words or word combinations to practice"
                    }
                    div {
                        +"They will be used in word-matching and complete the sentence exercises"
                    }
                })
                tooltipOverlayStyle = jsObject { maxWidth = "500px" }
            }
            p {
                css(textLengthCss)
                +"${countVocabulary(vocabulary)}"
            }
        }
        TextArea {
            css(textarea.extend {
                minHeight = important(60.px)
                if (hasError) {
                    border = Border(1.px, LineStyle.solid, Color("red"))
                }
            })
            value = vocabulary
            placeholder = vocabularyPlaceholder
            onChange = {
                if (hasError) {
                    setHasError(false)
                }
                dispatch(SetCurrentRequestVocabulary(it.target.value))
            }
        }
    }

    TextAreaWithLengthRestriction {
        label = "Topic or text (optional)"
        value = textOrTopic
        placeholder = textPlaceholder
        onChange = { dispatch(SetCurrentRequestTextOrTopic(it)) }

        tooltip = createElement(FC {
            div {
                +"Put interesting topic or text to make exercises more engaging"
            }
            div {
                +"For example, about traveling or food"
            }
        })
        tooltipOverlayStyle = jsObject { maxWidth = "500px" }
    }
}
