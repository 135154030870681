package pages.constructor.ui.components.header.styleSettings.borderRadiusPicker

import react.FC
import react.Props
import shared.components.inputNumberWithPlusAndMinus.InputNumberWithPlusAndMinus

external interface BorderRadiusPickerProps : Props {
    var value: Int?
    var onChange: ((Int, Boolean) -> Unit)?
}

val BorderRadiusPicker = FC<BorderRadiusPickerProps> { props ->
    InputNumberWithPlusAndMinus {
        min = 0
        label = "Radius"
        value = props.value
        onChange = {
            props.onChange?.invoke(it, true)
        }
        onAfterChange = {
            props.onChange?.invoke(it, false)
        }
    }
}