package widgets.CourseMaterials.ui.components.Materials

import antd.Button
import csstype.px
import emotion.react.css
import enums.EButtonTypes
import enums.EMaterialType
import kotlinx.browser.document
import online.interactiver.common.course.MaterialToGenerateExercises
import online.interactiver.common.course.getCourseMaterials
import online.interactiver.common.course.getRawMaterials
import online.interactiver.common.course.getRawMaterialsWithId
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import pages.constructor.ui.components.elements.grayFilter
import react.*
import react.dom.html.InputType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.section
import react.redux.useSelector
import reactgriddnd.GridContextProvider
import reactgriddnd.GridDropZone
import reactgriddnd.swap
import shared.components.Icon
import utils.fileToString
import utils.types.impl
import widgets.CourseMaterials.ui.components.Material.Material
import widgets.CourseSettings.selectCourseName
import widgets.ExerciseSettings.components.CustomLogoSettings.inputHidden

external interface MaterialsProps : Props {
    var materials: List<MaterialToGenerateExercises>
    var onChange: (String) -> Unit
    var header: String
    var materialType: EMaterialType
    var withAudio: Boolean
}

val Materials = FC<MaterialsProps> { props ->
    val inputImportAndAddItemsRef = useRef(null) as MutableRefObject<HTMLInputElement>?
    val inputImportItemsRef = useRef(null) as MutableRefObject<HTMLInputElement>?
    val courseName = useSelector(selectCourseName)

    section {
        css(materials)
        div {
            css(headerContainer)
            p {
                css(header)
                +props.header
            }
            if (!props.withAudio) {
                Button {
                    css(button)
                    Icon {
                        css(grayFilter)
                        src = "ic_settings_21x21.svg"
                    }
                    onClick = {
                        val rawMaterials = props.materials.getRawMaterials()
                        val blob = Blob(arrayOf(rawMaterials), impl { type = "text/tab-separated-values;charset=utf-8" })
                        val url = URL.Companion.createObjectURL(blob)
                        val link = document.createElement("a") as HTMLAnchorElement
                        link.href = url
                        link.download = "${props.materialType.toString().lowercase()}_$courseName.tsv"
                        link.click()
                    }
                    +"Export"
                }
            }
        }
        GridContextProvider {
            onChange = { _, sourceIndex, targetIndex, _ ->
                val newSlides = swap(props.materials.toTypedArray(), sourceIndex, targetIndex)
                props.onChange(newSlides.toList().getRawMaterialsWithId())
            }
            GridDropZone {
                val rowHeight = 69
                id = props.materialType.toString().lowercase()
                boxesPerRow = 1
                this.rowHeight = rowHeight

                style = impl {
                    this?.height = (props.materials.size * rowHeight).px
                }
                props.materials.forEachIndexed { index, materialToGenerateExercises ->
                    Material {
                        material = materialToGenerateExercises
                        onChange = {
                            val newMaterials = props.materials.map { material -> material }.toMutableList()
                            newMaterials[index] = it
                            props.onChange(newMaterials.getRawMaterialsWithId())
                        }
                        materialType = props.materialType
                        onCopyClick = {
                            val newMaterials = props.materials.map { material -> material }.toMutableList()
                            val newId = newMaterials.maxOf { it.id } + 1
                            newMaterials.add(index, materialToGenerateExercises.copy(id = newId))
                            props.onChange(newMaterials.getRawMaterialsWithId())
                        }
                        onDeleteClick = {
                            val newMaterials = props.materials.filterIndexed { indexToDelete, _ ->
                                indexToDelete != index
                            }
                            props.onChange(newMaterials.getRawMaterialsWithId())
                        }
                        this.index = index
                        withAudio = props.withAudio
                        this.rowHeight = rowHeight
                    }
                }
            }
        }
        div {
            css(buttonsContainer)
            Button {
                css(button)
                Icon {
                    src = "ic_plus_32x32.svg"
                }
                onClick = {
                    inputImportItemsRef?.current?.click()
                }
                +"import items"
            }
            Button {
                css(button)
                Icon {
                    src = "ic_plus_32x32.svg"
                }
                onClick = {
                    val newId = props.materials.maxOfOrNull { it.id } ?: 0
                    val newMaterials = (props.materials + MaterialToGenerateExercises(id = newId + 1)).getRawMaterialsWithId()
                    props.onChange(newMaterials)
                }
                +"add item"
            }
            Button {
                css(button)
                Icon {
                    src = "ic_plus_32x32.svg"
                }
                onClick = {
                    inputImportAndAddItemsRef?.current?.click()
                }
                +"import & add items"
            }
            val fileForImportAndAddItemsOnChange = fileToString { tsv, _ ->
                if (tsv == null) {
                    return@fileToString
                }

                val newMaterials = (props.materials + tsv.getCourseMaterials()).getRawMaterialsWithId()
                props.onChange(newMaterials)
            }
            input {
                css(inputHidden)
                ref= inputImportAndAddItemsRef
                id = EButtonTypes.IMPORT_AND_ADD_EXERCISES_INPUT.value
                type = InputType.file
                accept = ".tsv"
                size = 1
                onChange = fileForImportAndAddItemsOnChange
            }
            val fileForImportItemsOnChange = fileToString { tsv, _ ->
                if (tsv == null) {
                    return@fileToString
                }

                props.onChange(tsv)
            }
            input {
                css(inputHidden)
                ref= inputImportItemsRef
                id = EButtonTypes.IMPORT_EXERCISES_INPUT.value
                type = InputType.file
                accept = ".tsv"
                size = 1
                onChange = fileForImportItemsOnChange
            }
        }
    }
}