package widgets.SliderStatistics.ui

import antd.Spin
import csstype.AlignItems
import csstype.Display
import csstype.JustifyContent
import csstype.px
import emotion.react.css
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.ETimePeriod
import online.interactiver.common.statistics.SlideStatisticsResponse
import react.VFC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.main
import react.router.useParams
import react.useEffect
import react.useState
import shared.components.selectWithLabel.SelectWithLabel
import widgets.Exercises.ui.components.StatisticsModal.components.SlidesChart.SlidesChart
import widgets.Exercises.ui.components.StatisticsModal.getStatisticsRequest
import widgets.Exercises.ui.components.StatisticsModal.select
import widgets.SliderStatistics.ui.components.SliderStudentsTable.SliderStudentsTable

val SliderStatistics = VFC {
    val params = useParams()
    val requestId = params["requestId"]?.toIntOrNull()

    val (statistics, setStatistics) = useState(listOf<SlideStatisticsResponse>())
    val (isLoading, setIsLoading) = useState(true)
    val (timePeriod, setTimePeriod) = useState(ETimePeriod.LAST_MONTH)

    useEffect(requestId) {
        if (requestId == null) return@useEffect

        setIsLoading(true)
        GlobalScope.launch {
            val response = getStatisticsRequest(requestId, ETimePeriod.LAST_DAY.value)
            setIsLoading(false)
            if (response == null) {
                return@launch
            }

            setStatistics(response)
        }
    }

    if (requestId == null) {
        return@VFC
    }

    main {
        css(widget)
        div {
            css(select)
            SelectWithLabel {
                label = "Time period"
                value = timePeriod.value
                onChange = { newTimePeriod ->
                    ETimePeriod.fromString(newTimePeriod)?.let { setTimePeriod(it) }
                }
                options = ETimePeriod.entries.map { it.value }
            }
        }
        if (isLoading) {
            div {
                css {
                    display = Display.flex
                    alignItems = AlignItems.center
                    justifyContent = JustifyContent.center
                    height = 322.px
                    width = 450.px
                }
                Spin {
                    size = "large"
                }
            }
        } else {
            SlidesChart {
                this.usersData = statistics
            }
        }
        SliderStudentsTable {
            this.requestId = requestId
            this.timePeriod = timePeriod
        }
    }
}
