package entities.interactivePicture.elements.controls.inputs.ui.editor

import app.useAppDispatch
import app.useAppSelector
import builders.enums.EInteractiveType
import csstype.FlexDirection
import entities.interactivePicture.elements.SetPlaceholderForElements
import entities.interactivePicture.elements.controls.inputs.SetPlaceholder
import entities.interactivePicture.elements.editors.textEditor.TextEditor
import entities.interactivePicture.elements.gapPuzzles.drags.SetDragPlaceholder
import entities.interactivePicture.selectElementsUnderSelectionRectangle
import entities.interactivePicture.selectFocusedElement
import enums.EButtonTypes
import online.interactiver.common.interactivepicture.InteractiveElement
import react.FC
import react.Props
import shared.canvas.interfaces.getValueForEditor
import shared.canvas.interfaces.isDraggable
import shared.canvas.interfaces.shouldDrawEditor

val PlaceholderEditor = FC<Props> f@{
    val selected = useAppSelector(selectFocusedElement)
    val elementsUnderSelectionRectangle = useAppSelector(selectElementsUnderSelectionRectangle)
    val dispatch = useAppDispatch()
    val disabled = !shouldDrawEditor(selected, elementsUnderSelectionRectangle) { element ->
        element.isDraggable() || element.type == EInteractiveType.INPUT_INTERACTIVE.text
    }


    if (disabled) {
        return@f
    }
    val inputs = arrayListOf<InteractiveElement>()
    val drags = arrayListOf<InteractiveElement>()
    elementsUnderSelectionRectangle.forEach {
        if (it.isDraggable()) {
            drags.add(it)
        } else {
            inputs.add(it)
        }
    }

    val text = getValueForEditor(selected, elementsUnderSelectionRectangle) { element ->
        if (element.isDraggable()) {
            element.gapPuzzle?.gap?.text?.simpleText
        } else {
            element.input?.placeholderText
        }
    }

    TextEditor {
        id = EButtonTypes.PLACEHOLDER_INPUT.value
        header = "Placeholder"
        value = text
        defaultValue = "placeholder"
        placeholder = "Type text"
        onChange = { evt ->
            if (selected != null && selected.isDraggable()) {
                dispatch(SetDragPlaceholder(selected.identifier.id!!, evt.target.value))
            } else if (selected != null) {
                dispatch(SetPlaceholder(selected.identifier.id!!, evt.target.value))
            } else {
                dispatch(SetPlaceholderForElements(inputs.map { it.identifier.id!! }.toTypedArray(), drags.map { it.identifier.id!! }.toTypedArray(), evt.target.value))
            }
        }
        height = 35
        width = 105
        flexDirection = FlexDirection.row
        fontWeight = 400
    }
}