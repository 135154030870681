package widgets.Exercises.ui.components.StatisticsModal.components.SlidesChart

import csstype.*
import utils.types.CssStyle
import utils.types.FitContent

val chartContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    width = FitContent.fitContent
}

val legendContainer: CssStyle = {
    display = Display.flex
    gap = 15.px
    marginLeft = Auto.auto
}

val legend: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 5.px
}

val legendColor: (String) -> CssStyle = {
    {
        width = 12.px
        height = 12.px
        borderRadius = 50.pct
        backgroundColor = Color(it)
    }
}

val legendText: (String) -> CssStyle = {
    {
        color = Color(it)
        margin = 0.px
    }
}
