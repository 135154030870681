package pages.constructor.ui.components.header.fileButton

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 5.px

    borderRadius = 5.px
}

val button: CssStyle = {
    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 14.px
    lineHeight = 130.pct

    border = None.none
    padding = 0.px
    color = Color("#FFFFFF")

    backgroundColor = rgba(0, 0, 0, 0.0)

    hover {
        color = important(Color("#FFFFFF"))
        filter = ("invert(52%) sepia(29%) saturate(5446%) " +
                "hue-rotate(207deg) brightness(96%) contrast(102%)").unsafeCast<FilterFunction>()
        backgroundColor = important(rgba(0, 0, 0, 0.0))
        transitionDuration = 0.s
    }
}

val popupButtons: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 5.px

    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 12.px
    lineHeight = 18.px
    color = Color("#101828")

    border = None.none
    width = 175.px
    padding = Padding(6.px, 0.px, 6.px, 20.px)
}

val rightArrow: CssStyle = {
    marginLeft = Auto.auto
}
