package pages.course

import online.interactiver.common.course.CourseState
import pages.languageAuto.LanguageAutoStoreState
import redux.RAction
import widgets.CourseMaterials.CourseMaterialsAction
import widgets.CourseMaterials.courseMaterialsReducer
import widgets.CourseSettings.CourseSettingsAction
import widgets.CourseSettings.courseSettingsReducer
import kotlin.js.Date

open class CourseAction : RAction

open class ResetCourseAction : CourseAction()

data class SetCourse(val courseState: CourseState) : CourseAction()


fun courseReducer(state: CourseState, action: CourseAction): CourseState {
    return when (action) {
        is CourseSettingsAction -> courseSettingsReducer(state, action)
        is CourseMaterialsAction -> courseMaterialsReducer(state, action)
        is ResetCourseAction -> CourseState(name = "Course ${Date().toLocaleString()}")
        is SetCourse -> action.courseState
        else -> state
    }
}

val selectCourseId = { state: LanguageAutoStoreState ->
    selectCourseState(state).id
}

val selectCourseState = { state: LanguageAutoStoreState ->
    state.course
}
