package entities.interactivePicture.background.ui.tools

import app.useAppDispatch
import app.useAppSelector
import emotion.css.ClassName
import emotion.react.css
import entities.backgroundPosScale.selectCanvasContainerHeight
import entities.backgroundPosScale.selectCanvasContainerWidth
import entities.interactivePicture.background.*
import entities.interactivePicture.elements.shared.tools.buttonText
import entities.interactivePicture.selectDeviceType
import enums.EDeviceType
import org.w3c.dom.events.MouseEvent
import pages.constructor.ui.components.inputImage.InputImage
import pages.constructor.ui.components.inputImage.inputImageButtonStyle
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.components.Icon
import utils.fileToPicture
import utils.structures.Point
import kotlin.math.min

val BackgroundTools = FC<Props> {
    val dispatch = useAppDispatch()
    val backgroundBase64 = useAppSelector(selectBackgroundBase64)
    val backgroundFilename = useAppSelector(selectBackgroundFilename)
    val canvasContainerWidth = useAppSelector(selectCanvasContainerWidth)
    val canvasContainerHeight = useAppSelector(selectCanvasContainerHeight)
    val canvasMaxWidth = useAppSelector(selectCanvasMaxWidth)
    val deviceType = useAppSelector(selectDeviceType)
    val enabled = backgroundBase64 == null
    val inputImageOnChange = fileToPicture { a, b, r -> dispatch(EditBackground(a, b)) }

    div {
        div {
            css(container)
            if (enabled) {
                div {
                    css(header)
                    +"Upload background"
                }
                InputImage {
                    className = ClassName(inputImageButtonStyle)
                    buttonIcon = "ic_upload_24x24.svg"
                    onChange = inputImageOnChange
                    multiple = false
                }
            } else {
                div {
                    css(header)
                    +"Background"
                }
                antd.Button {
                    css(button)
                    onClick = { evt: MouseEvent ->
                        val ratio = when (deviceType) {
                            EDeviceType.DESKTOP.value -> Point(16, 9)
                            EDeviceType.MOBILE.value -> Point(3, 4)
                            else -> Point(16, 9)
                        }
                        dispatch(EditBackground(null, ""))
                        dispatch(EditBackgroundWithRatio(
                            min(canvasMaxWidth ?: canvasContainerWidth, canvasContainerWidth),
                            canvasContainerHeight,
                            ratio
                        ))
                    }
                    div {
                        css(buttonText)
                        +backgroundFilename.toString().substring(0, min(12, backgroundFilename.toString().length))
                    }
                    type = "text"
                    Icon {
                        src = "ic_cross_24x24.svg"
                    }
                }
            }
        }
    }
}
