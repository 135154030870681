package widgets.CourseSettings.ui.components.LanguagesSettings

import csstype.*
import utils.types.CssStyle

val horizontalContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 18.px
}

val select: CssStyle = {
    width = 256.px
}

val name: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
    width = 50.pct
    minWidth = 200.px
}
