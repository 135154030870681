package entities.viewport

import kotlinx.browser.window
import org.w3c.dom.events.Event
import react.*

val ViewportContext = createContext(0)

val useViewportContext =  { useContext(ViewportContext) }

fun useViewport(): Int {
    val (width, setWidth) = useState(window.innerWidth)
    useEffectOnce {
        val handleWindowResize = { _: Event ->
            setWidth(window.innerWidth)
        }

        window.addEventListener("resize", handleWindowResize)

        cleanup {
            window.removeEventListener("resize", handleWindowResize)
        }
    }

    return width
}

enum class EAvailableBreakpoints(
    val width: Int
) {
    MOBILE(1220)
}
