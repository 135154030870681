package pages.constructor.ui.components.advancedSettings.puzzlesSettings

import antd.Option
import antd.Select
import antd.TypedInputNumber
import emotion.react.css
import entities.interactivePicture.elements.other.ui.tools.phrase.*
import enums.EButtonTypes
import enums.EDragDropPuzzleAction
import enums.EPuzzleAlign
import online.interactiver.common.interactivepicture.PuzzlesSettings
import pages.constructor.ui.components.advancedSettings.popupSettings.checkboxTitle
import pages.constructor.ui.components.advancedSettings.popupSettings.selectStyle
import pages.constructor.ui.components.advancedSettings.settingHeader
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.components.checkboxWithLabel.CheckboxWithLabel

external interface PuzzlesSettingsEditorProps : Props {
    var settings: PuzzlesSettings
    var onChange: (PuzzlesSettings) -> Unit
}

val PuzzlesSettingsEditor = FC<PuzzlesSettingsEditorProps> {props ->
    div {
        css(container)
        div {
            css(settingHeader)
            +"Puzzles"
        }
        CheckboxWithLabel {
            id = EButtonTypes.SHUFFLE_PUZZLES_SETTINGS_BUTTON.value
            checked = props.settings.shufflePuzzles
            label = "Shuffle puzzles"
            onChange = {
                props.onChange(props.settings.copy(shufflePuzzles = it))
            }
        }
        CheckboxWithLabel {
            id = EButtonTypes.PUZZLES_ON_PANEL_SETTINGS_BUTTON.value
            checked = props.settings.puzzlesOnPanel
            label = "Puzzles on panel"
            onChange = {
                props.onChange(props.settings.copy(puzzlesOnPanel = it))
            }
        }
        div {
            css(stylesContainer)

            div {
                css(styleParamContainerHorizontal)
                div {
                    css(radioLabel)
                    +"Bottom padding"
                }
                TypedInputNumber {
                    css(inputNumber)
                    min = 0
                    controls = false
                    precision = 0
                    value = props.settings.bottomPadding
                    disabled = !props.settings.puzzlesOnPanel
                    onChange = {
                        props.onChange(props.settings.copy(bottomPadding = it.toInt()))
                    }
                }
            }
            div {
                css(styleParamContainerHorizontal)
                div {
                    css(radioLabel)
                    +"Width padding"
                }
                TypedInputNumber {
                    css(inputNumber)
                    min = 0
                    controls = false
                    precision = 0
                    value = props.settings.widthPadding
                    onChange = {
                        props.onChange(props.settings.copy(widthPadding = it.toInt()))
                    }
                }
            }
            div {
                css(styleParamContainerVertical)
                div {
                    css(radioLabel)
                    +"Puzzles align"
                }
                Select {
                    css(selectStyle)
                    id = EButtonTypes.PUZZLES_ALIGN_SELECT.value
                    value = props.settings.elementsAlign
                    onSelect = { value, _ ->
                        props.onChange(props.settings.copy(elementsAlign = value))
                    }
                    EPuzzleAlign.entries.forEach {
                        Option {
                            value = it.value
                        }
                    }
                }
            }
        }
        div {
            css(checkboxTitle)
            +"Choose desktop drag/drop action:"
        }
        Select {
            css(selectStyle)
            id = EButtonTypes.DESKTOP_DRAG_DROP_ACTION_SELECT.value
            value = props.settings.desktopDragDropAction
            onSelect = { value, _ ->
                props.onChange(props.settings.copy(desktopDragDropAction = value))
            }
            EDragDropPuzzleAction.values().forEach {
                Option {
                    value = it.value
                }
            }
        }
        div {
            css(checkboxTitle)
            +"Choose mobile drag/drop action:"
        }
        Select {
            css(selectStyle)
            id = EButtonTypes.MOBILE_DRAG_DROP_ACTION_SELECT.value
            value = props.settings.mobileDragDropAction
            onSelect = { value, _ ->
                props.onChange(props.settings.copy(mobileDragDropAction = value))
            }
            EDragDropPuzzleAction.values().forEach {
                Option {
                    value = it.value
                }
            }
        }
    }
}