package pages.constructor.ui.components.header.logoButton

import csstype.*
import utils.types.CssStyle

val button: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 8.px
    flexDirection = FlexDirection.row

    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 14.px
    lineHeight = 130.pct

    border = None.none
    padding = 0.px
    color = Color("#FFFFFF")

    backgroundColor = rgba(0, 0, 0, 0.0)

    hover {
        color = important(Color("#597EF7"))
        backgroundColor = important(rgba(0, 0, 0, 0.0))
    }
}