package entities.interactivePicture.elements.editors.soundEditor.autoSoundButton

import Modal
import antd.*
import app.appState.selectAppState
import app.useAppDispatch
import app.useAppSelector
import online.interactiver.common.enums.ELanguage
import emotion.react.css
import entities.dictionary.ui.getSoundFromDictionaryOrGoogleCloud
import entities.interactivePicture.elements.SetSoundSrcAndFilename
import entities.interactivePicture.elements.editors.soundEditor.iconTextContainer
import entities.interactivePicture.elements.editors.soundEditor.uploadButton
import entities.interactivePicture.elements.editors.soundEditor.uploadFileText
import entities.interactivePicture.selectInteractiveGroup
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import enums.EButtonTypes
import getAttachedDictionary
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import online.interactiver.common.admin.interactive.Dictionary
import org.w3c.dom.events.MouseEvent
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useEffect
import react.useState
import shared.canvas.interfaces.getLanguageByCode
import shared.canvas.interfaces.getLanguageByText
import shared.components.Icon
import shared.components.checkboxWithLabel.CheckboxWithLabel

external interface AutoSoundButtonProps : Props {
    var elementId: String
    var text: String?
}

val AutoSoundButton = FC<AutoSoundButtonProps> { props ->
    val interactiveGroupId = useAppSelector(selectInteractiveGroup)
    val appState = useAppSelector(selectAppState)
    val dispatch = useAppDispatch()

    val (isOpen, setIsOpen) = useState(false)
    val (attachedDictionary, setAttachedDictionary) = useState<Dictionary>()
    val (textToSpeech, setTextToSpeech) = useState(props.text ?: "")

    val (originalLanguage, setOriginalLanguage) = useState(ELanguage.ENGLISH)

    val (fromDictionary, setFromDictionary) = useState(false)
    val (fromGoogleTextToSpeech, setFromGoogleTextToSpeech) = useState(true)

    useEffect(props.text) {
        setTextToSpeech(props.text ?: "")
    }

    useEffect(isOpen) {
        if (isOpen) {
            return@useEffect
        }

        setAttachedDictionary(null)
        setTextToSpeech(props.text ?: "")
    }

    Button {
        css(uploadButton)
        div {
            css(iconTextContainer)
            Icon {
                src = "ic_speaker_20x21.svg"
            }
            div {
                css(uploadFileText)
                +"Auto"
            }
        }
        onClick = f@{ evt: MouseEvent ->
            evt.preventDefault()
            if (interactiveGroupId == null) {
                setIsOpen(true)
                return@f
            }
            dispatch(StartModalLoading("Loading dictionary, please wait"))
            GlobalScope.launch {
                val response = getAttachedDictionary(interactiveGroupId)
                if (response.code == 200) {
                    val dictionary = Json.decodeFromString<Dictionary>(response.content)
                    setAttachedDictionary(dictionary)

                    ELanguage.values().firstOrNull {
                        dictionary.materialLanguage != null && it.code == dictionary.materialLanguage
                    }?.let {
                        setOriginalLanguage(it)
                        setFromDictionary(true)
                    }
                }

                setIsOpen(true)
                dispatch(EndModalLoading())
            }
        }
    }

    Modal {
        open = isOpen
        onOk = f@{
            if (interactiveGroupId == null || textToSpeech.isBlank()) {
                setIsOpen(false)
                return@f
            }
            setIsOpen(false)
            dispatch(StartModalLoading("Loading sound, please wait"))
            GlobalScope.launch {
                val phrase = getSoundFromDictionaryOrGoogleCloud(
                    textToSpeech,
                    interactiveGroupId,
                    fromDictionary,
                    fromGoogleTextToSpeech,
                    originalLanguage.code)
                phrase?.soundDescription?.let {
                    dispatch(SetSoundSrcAndFilename(props.elementId, it, phrase.altSoundDescription, it))
                    if (textToSpeech == props.text) {
                        return@let
                    }

                    dispatch(appState.getSetVisibleElementText(props.elementId, textToSpeech))
                    dispatch(appState.getSetSyncingPhrase(props.elementId, false))
                }
                dispatch(EndModalLoading())
            }
        }
        onCancel = {
            setIsOpen(false)
        }

        okText = "Convert to sound"

        div {
            css(header)
            +"Text-to-Speech"
        }
        div {
            css(explanation)
            +"Enter the phrase you want to be spoken and select your preferred audio pathway."
        }
        TextArea {
            css(textarea)
            id = EButtonTypes.AUTO_SOUND_INPUT.value
            value = textToSpeech
            placeholder = "Enter your phrase"
            onChange = {
                setTextToSpeech(it.target.value)
            }
        }

        div {
            css(subheader)
            +"Language selection"
        }
        div {
            css(row)
            div {
                div {
                    css(selectHeader)
                    +"Original"
                }
                Select {
                    css(select)
                    value = originalLanguage.toString()
                    onSelect = { value, _ ->
                        value.getLanguageByText()?.let {
                            setOriginalLanguage(it)
                            if (value == attachedDictionary?.materialLanguage) {
                                return@let
                            }

                            if (fromDictionary) {
                                setFromDictionary(false)
                            }
                        }
                    }
                    ELanguage.values().forEach {
                        Option {
                            value = it.text
                        }
                    }
                }
            }
        }
        div {
            css(subheader)
            +"Pathway selection"
            Tooltip {
                title = "Pathway selection involves extracting sound from a dictionary, or generating it with Google Text-to-Speech (TTS) if unavailable."
                placement = "topRight"
                div {
                    css(hint)
                    +"?"
                }
            }
        }
        CheckboxWithLabel {
            label = "Dictionary"
            id = EButtonTypes.AUTO_SOUND_FROM_DICTIONARY_BUTTON.value
            checked = fromDictionary
            disabled = attachedDictionary == null
            onChange = f@{
                setFromDictionary(it)
                if (!it) {
                    return@f
                }

                attachedDictionary?.materialLanguage?.let { language ->
                    language.getLanguageByCode()?.let { setOriginalLanguage(it) }
                }
            }
        }
        CheckboxWithLabel {
            label = "Google Text-to-Speech"
            id = EButtonTypes.AUTO_SOUND_FROM_GOOGLE_CLOUD_BUTTON.value
            checked = fromGoogleTextToSpeech
            onChange = {
                setFromGoogleTextToSpeech(it)
            }
        }
    }
}