package widgets.Exercises.ui.components.UploadManuallyButton.UploadManuallyModal

import csstype.*
import utils.types.CssStyle
import utils.types.extend

val body: CssStyle = {
    padding = Padding(0.px, 0.px, 10.px, 0.px)
}

val header: CssStyle = {
    fontFamily = string("Montserrat")
    fontWeight = FontWeight.bolder
    fontSize = 20.px
    marginBottom = 12.px
    color = Color("#282F3E")
}

val secondary: CssStyle = {
    fontFamily = string("Montserrat")
    fontWeight = FontWeight.normal
    fontSize = 14.px
    color = Color("#5D6676")
}

val inputHidden: CssStyle = {
    display = None.none
    width = 0.px
    height = 0.px
}

val uploadButton: CssStyle = {
    width = 100.pct
    marginTop = 16.px
}

val uploadedFilesGrid: CssStyle = {
    display = Display.grid
    gridTemplateColumns = repeat(2, 1.fr)
    alignItems = AlignItems.center
    justifyContent = JustifyContent.stretch
    gap = 12.px
    marginTop = 12.px
}

val fileLabelContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    gap = 10.px
    alignItems = AlignItems.center
}

val filename = secondary.extend {
    fontSize = 14.px
    color = Color("#282F3E")
}

val inputExerciseNameContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.center
    gap = 10.px
}

val deleteExerciseButton: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    border = None.none
    padding = 0.px
    boxShadow = None.none
}
