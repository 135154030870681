package entities.errorModal.store

import pages.languageAuto.LanguageAutoStoreState
import pages.languageAuto.selectErrorModal
import redux.RAction
import widgets.LanguageAutoContent.ui.components.Content.maxContentText

data class ErrorModalState(
    val open: Boolean,
    val text: String = "",
    val title: String = "Oops. Something unexpected",
    val type: EErrorType = EErrorType.NONE
)

enum class EErrorType {
    NONE, EMPTY_LANGUAGE_TO_LEARN, NOT_ENOUGH_VOCABULARY
}

val selectErrorType = { state: LanguageAutoStoreState ->
    selectErrorModal(state).type
}

open class ErrorModalAction: RAction

data class OpenErrorModal(val text: String = "", val title: String = "Oops. Something unexpected", val type: EErrorType = EErrorType.NONE) : ErrorModalAction()
open class CloseErrorModal : ErrorModalAction()

open class RemoveErrorType() : ErrorModalAction()

open class OpenTooLongTextErrorModal : ErrorModalAction()

val ErrorModalReducer = { state: ErrorModalState, action: ErrorModalAction ->
    when (action) {
        is OpenErrorModal -> ErrorModalState(open = true, text = action.text, title = action.title, type = action.type)
        is CloseErrorModal -> state.copy(open = false)
        is OpenTooLongTextErrorModal -> ErrorModalState(
            open = true,
            title = "Oops. Too many characters",
            text = "More than $maxContentText characters will not fit in the slider, split the text into 2 and make 2 sliders"
        )
        is RemoveErrorType -> state.copy(type = EErrorType.NONE)
        else -> state
    }
}
