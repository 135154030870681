package online.interactiver.common.autogeneration

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import online.interactiver.common.enums.EAskStudentName
import online.interactiver.common.enums.EContentType
import online.interactiver.common.enums.ELanguage
import online.interactiver.common.enums.ELanguageLevel
import online.interactiver.common.interactivepicture.SliderGreetingScreenTitleWithScoreSettings

@Serializable
data class Translation(
    val value: String,
    val language: ELanguage
)

@Serializable
data class StringWithTranslation(
    val value: String,
    val translation: Translation? = null
) {
    fun translated(): String {
        return translation?.value ?: value
    }
}

@Serializable
data class LanguageAutoState(
    val userId: Int? = null,
    val task: String? = null,
    val slider: String? = null,
    @SerialName("request_id")
    val requestId: Int? = null,
    val time: String? = null,
    var name: String? = null,
    @SerialName("custom_link")
    val link: String? = null,
    @SerialName("embed_code")
    val embedCode: String? = null,
    @SerialName("language_to_learn")
    val languageToLearn: String? = null,
    @SerialName("interface_language")
    val interfaceLanguage: String = ELanguage.ENGLISH.text,
    @SerialName("language_level")
    val languageLevel: String = ELanguageLevel.ANY.value,
    @SerialName("text_or_topic")
    val textOrTopic: String = "",
    val vocabulary: String = "",
    @SerialName("content_type")
    val contentType: String = EContentType.VOCABULARY_WORDS.value,
    @SerialName("continue_button_link")
    val continueButtonLink: String = "",
    @SerialName("continue_button_text")
    val continueButtonText: String = "",
    @SerialName("titles_with_score_settings")
    val titleWithScoreSettings: List<SliderGreetingScreenTitleWithScoreSettings> = listOf(),
    @SerialName("translate_request_to_target_lang")
    //This comment reverts develop/ff3d2377963098ea9cf5b95cdd82440c6fcb2a17
    val translateRequestToTargetLang: Boolean = false, // true
    @SerialName("number_of_slides_to_generate")
    val numberOfSlidesToGenerate: Int = 8,
    @SerialName("sound_src")
    val soundSrc: String? = null,
    @SerialName("enable_gamified_popups")
    val enableGamifiedPopups: Boolean = true,
    @SerialName("enable_third_passage")
    val enableThirdPassage: Boolean = true,
    @SerialName("ask_student_name")
    val askStudentName: String = EAskStudentName.DO_NOT_ASK.value,
    @SerialName("share_button_text")
    val shareButtonText: String = "",
    @SerialName("share_button_link")
    val shareButtonLink: String = ""
)
