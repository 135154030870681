package widgets.CourseGenerationType

import ApiResponse
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.serialization.json.Json
import online.interactiver.common.course.CourseState
import utils.getToken

suspend fun generateCourseMaterialsBasedOnTextOrTopicRequest(courseState: CourseState): ApiResponse<CourseState> {
    return try {
        val response = jsonClient.post("/api/course/generate-materials-based-on-text-or-topic") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(courseState)
        }

        if (response.status.value != 200) {
            return  ApiResponse(null, response.status.value)
        }

        ApiResponse(Json.decodeFromString(response.bodyAsText()), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}
