package shared.components.checkbox

import antd.Button
import emotion.react.css
import pages.constructor.ui.components.elements.blueFilter
import pages.constructor.ui.components.elements.grayFilter
import react.FC
import react.PropsWithClassName
import react.dom.html.ReactHTML.div
import shared.components.Icon
import utils.inject
import utils.types.CssStyle

enum class ButtonColor(val style: CssStyle?) {
    DEFAULT(null),
    BLUE(blueFilter)
}

external interface CheckboxProps : PropsWithClassName {
    var disabled: Boolean?
    var checked: Boolean?
    var id: String
    var onChange: ((Boolean) -> Unit)?
    var label: String?
    var iconFilter: ButtonColor?
    var height: Int?
    var width: Int?
}

val Checkbox = FC<CheckboxProps> { props ->
    val checked = props.checked ?: false
    val iconFilter = props.iconFilter ?: ButtonColor.DEFAULT
    val height = props.height ?: 20
    val width = props.width ?: 20

    div {
        css(checkboxContainer)
        onClick = {
            it.stopPropagation()
            props.onChange?.invoke(!checked)
        }
        Button {
            css(button(height, width))
            id = props.id
            disabled = props.disabled == true
            Icon {
                css{
                    inject(if (!checked) checkHidden else if (props.disabled == true) grayFilter else check)
                    if (iconFilter.style != null) {
                        inject(iconFilter.style)
                    }
                }
                src = "ic_checked_box_filled_18x18.svg"
            }
        }
        props.label?.let {
            div {
                className = props.className
                +it
            }
        }
    }
}
