package pages.constructor.ui.components.advancedSettings.scoredPointsSettings

import antd.Option
import antd.Select
import antd.TypedInputNumber
import app.useAppSelector
import builders.enums.EElementType
import emotion.react.css
import entities.interactivePicture.selectElements
import entities.interactivePicture.selectGapPuzzles
import enums.EScoresPointsType
import online.interactiver.common.export.ExportData
import online.interactiver.common.interactivepicture.InteractiveElement
import pages.constructor.ui.components.advancedSettings.settingHeader
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import online.interactiver.common.interactivepicture.ScoredPointsSettings
import online.interactiver.common.utils.getSpecificDefaultScore
import react.useEffect
import shared.components.Icon


external interface ScoredPointsSettingsProps : Props {
    var settings: ScoredPointsSettings
    var onChange: (ScoredPointsSettings) -> Unit
    var radioButtonMode: Boolean
}

val ScoredPointsSettingsEditor = FC<ScoredPointsSettingsProps> { props ->
    val verifiableElements = useAppSelector(selectElements)
    val gapsAmount = useAppSelector(selectGapPuzzles)?.size

    val shouldAddButtonScore = { it: InteractiveElement ->
        it.visibleElement.type != EElementType.BUTTON_CONTROL.text || it.button?.correctState == "selected"
    }
    val scores = verifiableElements.filter {
        it.scores?.size != 0
                && it.scores?.get(0)?.name == "General"
                && shouldAddButtonScore(it)
    }.sumOf { it.scores?.get(0)?.value ?: it.getSpecificDefaultScore(
        ExportData(gapsAmount)
    ) }


    div {
        div {
            css(settingHeader)
            +"Success rate"
        }
        div{
            css(settingUnderHeader)
            +"Total score: $scores "
            +" points"
        }
        div{
            css(settingUnderHeader)
            +"Task solved correctly if:"
        }
        div{
            css(container)
            div{
                css(textContainer)
                +"General score"
            }

            Icon {
                css(icon)
                src = "ic_greater_than_24x24.svg"
            }

            val minPoints = 1
            val maxPoints = if (props.settings.scorePointsType == EScoresPointsType.POINTS.value ) {
                scores
            } else {
                100
            }

            val currentValue = if (props.settings.pointsToSuccess == 0){
                scores
            } else {
                props.settings.pointsToSuccess
            }
            useEffect(props.settings.scorePointsType) {
                if (currentValue >= scores){
                    props.onChange(props.settings.copy(
                        pointsToSuccess = maxPoints
                    ))
                }
            }
            TypedInputNumber {
                css(inputNumber)
                min = minPoints
                max = maxPoints
                controls = false
                precision = 0
                this.value = currentValue
                onChange = {
                    val points = it.toInt()
                    if (points in minPoints..maxPoints) {
                        props.onChange.invoke(props.settings.copy(pointsToSuccess = points))
                    }
                }

            }
            Select {
                css(selectStyle)
                value = props.settings.scorePointsType
                disabled = false
                showArrow = true
                onSelect = { value, _ ->
                    if (value != props.settings.scorePointsType) {
                        props.onChange(props.settings.copy(scorePointsType = value))
                    }
                }
                EScoresPointsType.values().forEach {
                    Option {
                        value = it.value
                    }
                }
            }
        }
    }
}