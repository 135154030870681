package widgets.ExerciseSettings

import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.section
import react.redux.useSelector
import shared.components.ProtectedRoute.ProtectedRoute
import shared.components.header.Header
import shared.components.loader.Loader
import widgets.ExerciseSettings.components.AdvancedSettings.AdvancedSettings
import widgets.ExerciseSettings.components.LogoSettings.LogoSettings
import widgets.ExerciseSettings.components.NumberOfSlidesToGenerate.NumberOfSlidesToGenerate
import widgets.ExerciseSettings.components.SettingLabel.SettingLabel
import widgets.ExerciseSettings.components.SettingsContainer.SettingsContainer
import widgets.ExerciseSettings.components.SwitchWithLabel.container
import widgets.ExerciseSettings.components.Warning.Warning
import widgets.UserProfile.selectTariffPlan

val ExerciseSettings = FC<Props> {

    val userSliderSettings = useSelector(selectUserSliderSettings)
    val tariffPlan = useSelector(selectTariffPlan)

    ProtectedRoute {
        section {
            css(widget)
            if (userSliderSettings == null) {
                Loader {}
                return@section
            }
            Header {
                text = "Exercise settings"
            }
            Warning {}
            SettingsContainer {
                header = "GENERAL"
                GeneralSettings {}
            }
            if (tariffPlan?.uppercase() != "BASIC") {
                SettingsContainer {
                    header = "LOGO"
                    LogoSettings {}
                }
            }
            SettingsContainer {
                header = "ADVANCED"
                AdvancedSettings {}
            }
        }
    }
}

val GeneralSettings = FC<Props> {
    div {
        css(container)
        SettingLabel {
            label = "Number of exercises"
            hint = "Defines the number of exercises within one generation (applicable to all exercises)"
        }
        NumberOfSlidesToGenerate {}
    }
}
