package entities.interactivePicture.elements.editors.soundEditor

import antd.Button
import antd.Select
import app.appState.selectAppState
import app.useAppDispatch
import app.useAppSelector
import builders.enums.*
import emotion.react.css
import entities.interactivePicture.elements.*
import entities.interactivePicture.elements.editors.soundEditor.autoSoundButton.AutoSoundButton
import entities.interactivePicture.selectElementsUnderSelectionRectangle
import entities.interactivePicture.selectFocusedElement
import enums.EActiveStateType
import enums.EButtonTypes
import kotlinx.browser.document
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.MouseEvent
import react.FC
import react.Props
import react.dom.html.InputType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import shared.canvas.interfaces.getValueForEditor
import shared.canvas.interfaces.shouldDrawEditor
import shared.components.Icon
import utils.fileToBase64
import utils.types.jsObject

val SoundEditor = FC<Props> {
    val focusedElement = useAppSelector(selectFocusedElement)
    val elementsUnderSelectionRectangle = useAppSelector(selectElementsUnderSelectionRectangle)
    val appState = useAppSelector(selectAppState)
    val dispatch = useAppDispatch()
    val isSynced = focusedElement?.isSynced

    val elementType = EElementType.values().find { it.text == focusedElement?.visibleElement?.type }

    val shouldDraw = shouldDrawEditor(focusedElement, elementsUnderSelectionRectangle) { element ->
        EElementType.values().find { it.text == element.visibleElement.type }?.usesSoundEditor ?: false
    }

    if (!shouldDraw) {
        return@FC
    }

    if (focusedElement != null) {
        val inputAudioOnChange =
            fileToBase64 { src, filename ->
                src?.let {
                    dispatch(SetSound(focusedElement.identifier.id!!, it, filename))
                    if (isSynced != null) {
                        dispatch(appState.getSetSyncingPhrase(focusedElement.identifier.id!!, false))
                    }
                }
            }

        input {
            css(inputHidden)
            id = EButtonTypes.UPLOAD_SOUND_TO_ELEMENT_INPUT.value
            type = InputType.file
            accept = "audio/*"
            size = 1
            onChange = inputAudioOnChange
        }
    }

    div {
        css(container)
        div {
            css(header)
            +"Sound"
        }
        if (focusedElement != null && elementType?.usesSoundBadge == true) {
            if (focusedElement.sound == null) {
                div {
                    css(buttonsContainer)

                    Button {
                        css(uploadButton)
                        div {
                            css(iconTextContainer)
                            Icon {
                                src = "ic_upload_sound_24x24.svg"
                            }
                            div {
                                css(uploadFileText)
                                +"Upload file"
                            }
                        }
                        onClick = { evt: MouseEvent ->
                            evt.preventDefault()
                            val input = document.getElementById(EButtonTypes.UPLOAD_SOUND_TO_ELEMENT_INPUT.value)
                            (input as? HTMLInputElement)?.click()
                        }
                    }
                    AutoSoundButton {
                        elementId = focusedElement.identifier.id!!
                        text = focusedElement.visibleElement.text?.simpleText
                    }
                }
            } else {
                div {
                    css(filenameWrapContainer)
                    div {
                        css(filenameDeleteContainer)
                        div {
                            css(filenameText)
                            +focusedElement.sound!!.filename!!
                        }
                        Button {
                            css(deleteButton)
                            Icon {
                                src = "ic_delete_sound_20x20.svg"
                            }
                            onClick = {
                                dispatch(DeleteSound(focusedElement.identifier.id!!))
                            }
                        }
                    }
                }
            }
        }

        val disableActivation = !shouldDrawEditor(focusedElement, elementsUnderSelectionRectangle) {
            it.sound != null
        }
        val disableIconPosition = !shouldDrawEditor(focusedElement, elementsUnderSelectionRectangle) {
            it.sound != null
        }
        val disableShowing = disableIconPosition
        val shouldDrawIconPosition = shouldDrawEditor(focusedElement, elementsUnderSelectionRectangle) { element ->
            EElementType.values().find { it.text == element.visibleElement.type }?.usesSoundBadge ?: false
        }
        val activationValue = getValueForEditor(focusedElement, elementsUnderSelectionRectangle) {
            it.sound?.activation
        }
        val showingValue = getValueForEditor(focusedElement, elementsUnderSelectionRectangle) {
            EActiveStateType.fromSolvingStageCondition(it.sound?.showCondition?.solvingStageCondition)
        }

        div {
            css(verticalContainer)
            if (elementType?.usesSoundBadge == true) {
                div {
                    css(selectContainer)
                    div {
                        css(selectHeader)
                        +"Showing"
                    }
                    Select {
                        css(selector)
                        disabled = disableShowing
                        options = EActiveStateType.values().map {
                            jsObject {
                                this.value = it.text
                                this.label = it.text
                            }
                        }.toTypedArray()
                        value = showingValue?.text ?: EActiveStateType.DEFAULT.text
                        onChange = { value, _ ->
                            val showing = EActiveStateType.fromString(value)
                            if (focusedElement != null) {
                                dispatch(SetSoundShowing(focusedElement.identifier.id!!, showing))
                            } else {
                                dispatch(SetSoundShowingForElements(elementsUnderSelectionRectangle.map { it.identifier.id!! }.toTypedArray(), showing))
                            }
                        }
                    }
                }
            }
            div {
                css(selectContainer)
                div {
                    css(selectHeader)
                    +"Activation"
                }
                Select {
                    css(selector)
                    disabled = disableActivation
                    options = ESoundActivation.values().map {
                        jsObject {
                            this.value = it.textValue
                            this.label = it.textUi
                        }
                    }.toTypedArray()
                    value = activationValue ?: ESoundActivation.DEFAULT.textValue
                    onChange = { value, _ ->
                        if (focusedElement != null) {
                            dispatch(SetSoundActivation(focusedElement.identifier.id!!, value))
                        } else {
                            dispatch(SetSoundActivationForElements(elementsUnderSelectionRectangle.map { it.identifier.id!! }.toTypedArray(), value))
                        }
                    }
                }
            }
            if (shouldDrawIconPosition) {
                val iconPositionValue = getValueForEditor(focusedElement, elementsUnderSelectionRectangle) {
                    it.sound?.badgePosition
                }
                div {
                    css(selectContainer)
                    div {
                        css(selectHeader)
                        +"Icon position"
                    }
                    Select {
                        css(selector)
                        disabled = disableIconPosition
                        options = ESoundBadgePosition.values().map {
                            jsObject {
                                this.value = it.textValue
                                this.label = it.textUi
                            }
                        }.toTypedArray()
                        value = iconPositionValue ?: ESoundBadgePosition.DEFAULT.textValue
                        onChange = { value, _ ->
                            if (focusedElement != null) {
                                dispatch(SetSoundPosition(focusedElement.identifier.id!!, value))
                            } else {
                                dispatch(SetSoundPositionForElements(elementsUnderSelectionRectangle.map { it.identifier.id!! }.toTypedArray(), value))
                            }
                        }
                    }
                }
            }
        }
    }
}
