package widgets.LanguageAutoTask.ui.components.HiddenIframe

import csstype.*
import utils.types.CssStyle

val iFrameContainer: CssStyle = {
    height = 700.px
    width = 700.px
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    position = Position.absolute
    zIndex = integer(-1)
}

val iFrame: CssStyle = {
    height = 100.pct
    width = 100.pct
    border = Border(0.px, LineStyle.hidden)
    overflowX = Overflow.hidden
    overflowY = Overflow.hidden
    margin = 0.px
    padding = 0.px
}