package online.interactiver.common.admin.interactive.googlecloud

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SpeechToTextRequest(
    @SerialName("base64")
    val base64: String,
    @SerialName("language")
    val language: String,
    @SerialName("seconds")
    val seconds: Int
)