package widgets.LanguageAutoSlider.ui.components.IntegrateModal

import csstype.*
import utils.types.CssStyle

val modal: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 22.px
    width = 100.pct
}

val headerContainer: CssStyle = {
    display = Display.flex
    gap = 10.px
}

val backButton: CssStyle = {
    boxShadow = None.none
    border = None.none
    padding = 0.px
    margin = 0.px
}

val turn: CssStyle = {
    transform = rotate(90.deg)
}

val contentContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 14.px
    width = 100.pct
}

val embedCodeContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
    width = 100.pct
}

val embedCode: CssStyle = {
    margin = 0.px
    padding = 8.px
    borderRadius = 8.px
    border = Border(1.px, LineStyle.solid, Color("#D0D5DD"))
    boxSizing = BoxSizing.borderBox
    height = 236.px
    width = 100.pct
    fontSize = 16.px
    lineHeight = 21.6.px
    fontWeight = FontWeight.normal
    color = Color("black")
}