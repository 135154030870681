package pages.constructor.ui.components.hintStyleSettings.componentsForHintSettings

import antd.Button
import builders.enums.EAlign
import emotion.react.css
import pages.constructor.ui.components.header.styleSettings.stylePicker.container
import pages.constructor.ui.components.hintStyleSettings.iconsContainer
import pages.constructor.ui.components.hintStyleSettings.secondary
import pages.constructor.ui.components.header.styleSettings.weightPicker.button
import pages.constructor.ui.components.header.styleSettings.weightPicker.buttonActive
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.key
import shared.components.Icon

external interface HintHorizontalAlignProps : Props {
    var horizontalValue: String?
    var onChange: ((String) -> Unit)?
}
val HintHorizontalAlignPicker = FC<HintHorizontalAlignProps>{props ->
    val horizontalValue = props.horizontalValue ?: "center"
    div {
        css(container)
        div {
            css(secondary)
            +"Align"
        }
        div {
            css(iconsContainer)
            class Align(val align: EAlign, val iconSrc: String, val value: String, val callback: ((String) -> Unit)?)
            arrayOf(
                Align(EAlign.Horizontal.LEFT, "ic_align_left_24x24.svg", horizontalValue, props.onChange),
                Align(EAlign.Horizontal.CENTER, "ic_align_center_24x24.svg", horizontalValue, props.onChange),
                Align(EAlign.Horizontal.RIGHT, "ic_align_right_24x24.svg", horizontalValue, props.onChange),
            ).map {
                Button {
                    css(if (it.value == it.align.value) buttonActive else button)
                    key = it.iconSrc
                    Icon {
                        src = it.iconSrc
                    }
                    onClick = onClick@{
                        if (it.value == it.align.value) {
                            return@onClick
                        }
                        it.callback?.invoke(it.align.value)
                    }
                }
            }
        }
    }
}