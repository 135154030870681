package entities.interactivePicture.elements.controls.selectors.ui.editor

import antd.Button
import antd.Input
import app.appState.CommonState
import app.appState.OptionEditState
import app.appState.SetAppState
import app.useAppDispatch
import app.useAppSelector
import emotion.react.css
import entities.interactivePicture.elements.gapPuzzles.drags.*
import entities.selectedElement.SelectElement
import org.w3c.dom.HTMLInputElement
import pages.constructor.ui.components.elements.grayFilter
import react.*
import react.dom.html.ReactHTML.div
import shared.components.Icon
import shared.components.checkbox.Checkbox

val SelectorEditor = FC<Props> {
    val focusedSelector = useAppSelector(selectFocusedSelector)
    val focusedOption = useAppSelector(selectFocusedOption)
    val lastInputRef = useRef<HTMLInputElement>()

    val disabled = focusedSelector == null

    val dispatch = useAppDispatch()

    useEffect(focusedSelector?.selector?.options?.size) {
        lastInputRef.current?.focus()
    }

    useEffect(focusedSelector?.identifier?.id) {
        lastInputRef.current?.blur()
        if (focusedSelector != null) {
            dispatch(SetAppState(CommonState))
            dispatch(SelectElement(focusedSelector.identifier.id))
        }
    }

    div {
        css(container)
        div {
            css(header)
            +"Selector"
        }

        div {
            css(wrapContainer)
            if (disabled) {
                div {
                    css(inputContainer)
                    Input {
                        css(input)
                        placeholder = "Add selector option"
                        this.disabled = true
                    }
                    Checkbox {
                        checked = false
                        this.disabled = true
                    }
                    Button {
                        css(removeButtonDisabled)
                        this.disabled = true
                        Icon {
                            css(grayFilter)
                            src = "ic_cross_24x24.svg"
                        }
                        ghost = true
                    }
                }
            } else {
                focusedSelector?.selector?.options?.mapIndexed { index, option ->
                    div {
                        css(inputContainer)
                        key = "container_${option.identifier!!.id}"
                        Input {
                            css(input)
                            if (index == focusedSelector.selector?.options?.size?.minus(1)) {
                                ref = lastInputRef
                            }

                            key = option.identifier!!.id
                            value = option.text?.simpleText
                            onChange = {
                                dispatch(ChangeOptionText(option.identifier!!.id!!, it.target.value))
                            }
                            onFocus = {
                                dispatch(SetAppState(OptionEditState(option.identifier!!.id!!)))
                                dispatch(SelectElement(null))
                            }
//                            onBlur = {
//                                dispatch(SetAppState(null))
//                            }
                        }
                        Checkbox {
                            checked = option.isCorrect == "1"
                            onChange = { checked ->
                                dispatch(SetOptionIsCorrect(option.identifier!!.id!!, checked))
                            }
                        }
                        Button {
                            css(removeButton)
                            key = "remove_${option.identifier!!.id}"
                            Icon {
                                src = "ic_cross_24x24.svg"
                            }
                            ghost = true
                            onClick = {
                                dispatch(RemoveOption(option.identifier!!.id!!))
                                if (option.identifier?.id?.equals(focusedOption?.identifier?.id) == true) {
                                    dispatch(SetAppState(CommonState))
                                    dispatch(SelectElement(focusedSelector.identifier.id))
                                }
                            }
                        }
                    }
                }
                div {
                    css(inputContainer)
                    Input {
                        css(input)
                        placeholder = "Add selector option"
                        onChange = {
                            dispatch(AddOption(focusedSelector!!.identifier.id!!, it.target.value))
                        }
                        onFocus = {
                            dispatch(SetAppState(CommonState))
                            dispatch(SelectElement(focusedSelector?.identifier?.id))
                        }
                    }
                    Checkbox {
                        checked = false
                        this.disabled = true
                    }
                    Button {
                        css(removeButtonDisabled)
                        this.disabled = true
                        Icon {
                            css(grayFilter)
                            src = "ic_cross_24x24.svg"
                        }
                        ghost = true
                    }
                }
            }
            Button {
                css(if (disabled) saveButtonDisabled else saveButton)
                this.disabled = disabled
                +"Save selector"
                onClick = {
                    dispatch(SetAppState(CommonState))
                    dispatch(SelectElement(null))
                }
            }
        }
    }
}
