package pages.constructor.ui.components.advancedSettings.checkSettings

import online.interactiver.common.enums.ELanguage
import emotion.react.css
import enums.EButtonTypes
import online.interactiver.common.interactivepicture.CheckSettings
import pages.constructor.ui.components.advancedSettings.controlButtonsColorSettings.ControlButtonsColorSettings
import pages.constructor.ui.components.advancedSettings.controlButtonsSettings.ControlButtonsSettingsEditor
import pages.constructor.ui.components.advancedSettings.popupSettings.PopupSettingsEditor
import pages.constructor.ui.components.advancedSettings.scoredPointsSettings.ScoredPointsSettingsEditor
import pages.constructor.ui.components.advancedSettings.settingHeader
import pages.constructor.ui.components.advancedSettings.settingsHeader
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.components.checkboxWithLabel.CheckboxWithLabel

external interface CheckSettingsEditorProps: Props {
    var settings: CheckSettings
    var onChange: (CheckSettings) -> Unit
    var taskLanguage: ELanguage
    var radioButtonMode: Boolean
}

val CheckSettingsEditor = FC<CheckSettingsEditorProps>{ props ->
    div {
        css(settingsHeader)
        +"CHECKING"
    }
    div {
        css(checkContainer)
        div {
            css(settingHeader)
            +"Checking settings"
        }
        CheckboxWithLabel {
            id = EButtonTypes.INSTANT_CHECK_SETTINGS_BUTTON.value
            checked = props.settings.instantCheck
            label = "Instant check"
            onChange = {
                props.onChange(props.settings.copy(instantCheck = it, controlButtonsSettings = props.settings.controlButtonsSettings.copy(
                    enableCheckButton = if (it) false else props.settings.controlButtonsSettings.enableCheckButton
                )))
            }
        }
    }
    ControlButtonsSettingsEditor {
        settings = props.settings.controlButtonsSettings
        onChange = {
            props.onChange(props.settings.copy(controlButtonsSettings = it))
        }
        taskLanguage = props.taskLanguage
        instantCheck = props.settings.instantCheck
    }
    ControlButtonsColorSettings {
        color = props.settings.controlButtonsSettings.color
        onChange = { newColor ->
            props.onChange(props.settings.copy(controlButtonsSettings = props.settings.controlButtonsSettings.copy(
                color = newColor
            )))
        }
        disabled = !props.settings.controlButtonsSettings.enableCheckButton && !props.settings.controlButtonsSettings.enableRestartButton
    }
    PopupSettingsEditor {
        settings = props.settings.popupSettings
        onChange = {
            props.onChange(props.settings.copy(popupSettings = it))
        }
    }
    ScoredPointsSettingsEditor{
        settings = props.settings.scoredPointsSettings
        onChange = {
            props.onChange(props.settings.copy(scoredPointsSettings = it))
        }
        radioButtonMode = props.radioButtonMode
    }
}
