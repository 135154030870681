package widgets.UserProfile

import online.interactiver.common.user.UserProfile
import pages.languageAuto.LanguageAutoStoreState
import pages.studentDuo.StudentDuoStoreState
import pages.studentDuo.selectStudentDuoUserProfile
import redux.RAction
import widgets.ExerciseSettings.SliderSettingsAction
import widgets.ExerciseSettings.SliderSettingsReducer

val selectUserProfile = { state: LanguageAutoStoreState ->
    state.userProfile
}

val selectTariffPlan = { state: LanguageAutoStoreState ->
    selectUserProfile(state)?.tariffPlan
}

open class UserProfileAction : RAction

data class SetTariffPlan(val tariffPlan: String) : UserProfileAction()

data class SetUserName(val name: String) : UserProfileAction()

data class SetUserAbout(val about: String) : UserProfileAction()
data class SetUserProfile(val userProfile: UserProfile): UserProfileAction()
data class SetSocials(val socials: String): UserProfileAction()

data class SetStudentDuoAutoSoundExercise(val autoSoundExercise: Boolean): UserProfileAction()

data class SetAdditionalEmail(val additionalEmail: String): UserProfileAction()

val UserProfileReducer = f@{ state: UserProfile?, action: UserProfileAction ->
    return@f when (action) {
        is SetStudentDuoAutoSoundExercise -> state?.copy(
            autoSoundExercise = action.autoSoundExercise
        )
        is SetAdditionalEmail -> state?.copy(
            additionalEmail = action.additionalEmail
        )
        is SetSocials -> state?.copy(
            socials = action.socials
        )
        is SliderSettingsAction -> state?.copy(
            sliderSettings = SliderSettingsReducer(state.sliderSettings, action)
        )
        is SetTariffPlan -> state?.copy(tariffPlan = action.tariffPlan)
        is SetUserName -> state?.copy(userName = action.name)
        is SetUserAbout -> state?.copy(about = action.about)
        is SetUserProfile -> action.userProfile
        else -> state
    }
}

val selectStudentDuoAutoSoundExercise = { state: StudentDuoStoreState ->
    selectStudentDuoUserProfile(state)?.autoSoundExercise ?: false
}