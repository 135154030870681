package pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar

import emotion.react.css
import org.w3c.dom.url.URL
import pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewCheckingSettings.CheckingSettings
import pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewShareTabs.PreviewShareTabs
import react.*
import react.dom.html.ReactHTML.div

const val PREVIEW_ALERT_DURATION_MS = 2500

data class LinkSettingsContextData(
    val instantCheck: Boolean = false,
    val setInstantCheck: StateSetter<Boolean>? = null
) {
    fun applyToUrl(urlString: String?): String? {
        if (urlString == null) {
            return null
        }
        val url = URL(urlString)
        if (instantCheck) {
            url.searchParams.set("instantCheck", instantCheck.toString())
        }
        return url.toString()
    }

    /**
     * Sets searchParams to URL
     */
    fun applyToUrl(url: URL) {
        if (instantCheck) {
            url.searchParams.set("instantCheck", instantCheck.toString())
        }
    }
}

val LinkSettingsContext = createContext(LinkSettingsContextData())

val PreviewRightBar = FC<Props> {
    val (instantCheck, setInstantCheck) = useState(false)
    LinkSettingsContext.Provider {
        value = LinkSettingsContextData(
            instantCheck = instantCheck,
            setInstantCheck = setInstantCheck
        )

        div {
            css(container)
            CheckingSettings {}
            PreviewShareTabs {}
        }
    }

}