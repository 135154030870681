package widgets.SliderStatistics.ui.components.SliderStudentsTable

import csstype.*
import utils.types.CssStyle
import utils.types.extend

val grid: (Int) -> CssStyle = { numberOfSlides ->
    {
        display = Display.grid
        gridTemplateColumns = "2fr 1fr repeat(${numberOfSlides}, 1fr)".unsafeCast<GridTemplateColumns>()
        border = Border(1.px, LineStyle.solid)
        width = 100.pct
        overflowX = Auto.auto
    }
}

val name: CssStyle = {
    margin = 0.px
    padding = 10.px
    boxSizing = BoxSizing.borderBox
    border = Border(0.5.px, LineStyle.solid)
    textAlign = TextAlign.center
}

val attempt: CssStyle = {
    padding = Padding(10.px, 0.px)
    boxSizing = BoxSizing.borderBox
    border = Border(0.5.px, LineStyle.solid)
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
}

val header: CssStyle = {
    fontWeight = integer(600)
    padding = Padding(10.px, 0.px)
    boxSizing = BoxSizing.borderBox
    border = Border(0.5.px, LineStyle.solid)
    margin = 0.px
    textAlign = TextAlign.center
    minWidth = 80.px
}

val slideHeader: CssStyle = header.extend {
    minWidth = 30.px
}

val widget: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 15.px
    width = 100.pct
}

val inputContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
    width = 50.pct
}

val label: CssStyle = {
    margin = 0.px
}
