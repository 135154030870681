package pages.constructor.ui.components.header.templates.modal

import Modal
import emotion.react.css
import online.interactiver.common.api.TemplateMeta
import online.interactiver.common.api.TemplatesModalWindowData
import pages.constructor.ui.components.header.templates.gallery.TemplateGallery
import pages.constructor.ui.components.header.templates.preview.TemplatePreview
import react.FC
import react.Props
import react.useState

external interface TemplatesModalProps : Props {
    var isOpen: Boolean
    var setIsOpen: (flag: Boolean) -> Unit
    var data: TemplatesModalWindowData?
}

val TemplatesModal = FC<TemplatesModalProps> { props ->

    val (curTemplate, setCurTemplate) = useState(null as TemplateMeta?)

    Modal a@{
        css(modal)
        centered = true
        open = props.isOpen
        footer = null
        width = "calc(100vw - 198px)"
        onCancel = {
            setCurTemplate(null)
            props.setIsOpen(false)
        }

        if (curTemplate != null) {
            TemplatePreview {
                this.templateMeta = curTemplate
                this.tags = props.data!!.tagColors
                this.setCurTemplate = { template: TemplateMeta? -> setCurTemplate(template) }
                this.setIsOpen = props.setIsOpen
            }
            return@a
        }

        TemplateGallery {
            this.data = props.data
            this.setCurTemplate = { template: TemplateMeta -> setCurTemplate(template) }
        }
    }
}


