package shared.components.inputNumberWithPlusAndMinus

import antd.Button
import antd.TypedInputNumber
import emotion.react.css
import pages.constructor.ui.components.header.styleSettings.fontSizeInput.inputNumber
import pages.constructor.ui.components.header.styleSettings.fontSizeInput.plusMinusButton
import pages.constructor.ui.components.hintStyleSettings.primaryLabel
import pages.constructor.ui.components.hintStyleSettings.secondary
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useRef
import shared.components.Icon

enum class EInputNumberWithPlusAndMinusVariant {
    PRIMARY,
    SECONDARY
}

external interface InputNumberWithPlusAndMinusProps : Props {
    var value: Int?
    var min: Int?
    var max: Int?
    var onChange: ((Int) -> Unit)?
    var onAfterChange: ((Int) -> Unit)?
    var label: String
    var variant: EInputNumberWithPlusAndMinusVariant?
}

val InputNumberWithPlusAndMinus = FC<InputNumberWithPlusAndMinusProps> { props ->
    val min = props.min
    val max = props.max
    val value = props.value ?: 0

    val oldValue = useRef(value)

    div {
        css(container)
        div {
            css(when (props.variant) {
                EInputNumberWithPlusAndMinusVariant.PRIMARY -> primaryLabel
                else -> secondary
            })
            +props.label
        }
        div {
            css(inputContainer)
            Button {
                css(plusMinusButton)
                Icon {
                    src = "ic_minus_16x16.svg"
                }
                onClick = {
                    if (min == null || value > min) {
                        props.onAfterChange?.invoke(value - 1)
                    }
                }
            }
            TypedInputNumber {
                css(inputNumber)
                this.min = min
                this.max = max
                controls = false
                precision = 0
                this.value = value
                onChange = {
                    val size = it.toInt()
                    if (size in (min ?: size)..(max ?: size)) {
                        props.onChange?.invoke(it.toInt())
                    }
                }
                onBlur = {
                    val size = it.target.value.toInt()
                    props.onChange?.invoke(oldValue.current ?: 0)
                    props.onAfterChange?.invoke(size)
                    oldValue.current = size
                }
            }
            Button {
                css(plusMinusButton)
                Icon {
                    src = "ic_plus_16x16.svg"
                }
                onClick = {
                    if (max == null || value < max) {
                        props.onAfterChange?.invoke(value + 1)
                    }
                }
            }
        }
    }
}
