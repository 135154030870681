package pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewShareTabs

import entities.alert.*
import online.interactiver.common.utils.toBase
import pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.PREVIEW_ALERT_DURATION_MS
import redux.RAction
import kotlin.random.Random
import kotlin.random.nextULong

typealias PrepareLinkAlertCallback = () -> Unit

fun showAlert(
    dispatch: (RAction) -> (RAction), infoLabel: String, successLabel: String, errorLabel: String
): Triple<PrepareLinkAlertCallback, PrepareLinkAlertCallback, PrepareLinkAlertCallback> {
    val preparingInfoAlertId = Random.nextULong().toBase()
    val target = EAlertTarget.PREVIEW
    dispatch(
        ShowAlert(
            preparingInfoAlertId, infoLabel, EAlertType.INFO, target
        )
    )

    val closeInfo: PrepareLinkAlertCallback = {
        dispatch(CloseAlert(preparingInfoAlertId))
    }

    val showSuccess: PrepareLinkAlertCallback = {
        dispatch(
            ShowAutoCloseAlert(
                successLabel, EAlertType.SUCCESS, target, PREVIEW_ALERT_DURATION_MS
            )
        )
    }

    val showError: PrepareLinkAlertCallback = {
        dispatch(
            ShowAutoCloseAlert(
                errorLabel, EAlertType.ERROR, target, PREVIEW_ALERT_DURATION_MS
            )
        )
    }

    return Triple(closeInfo, showSuccess, showError)
}