package pages.constructor.ui.components.header.styleSettings

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 14.px
    borderRadius = 5.px
    padding = 8.px
}
val containerRow: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.flexEnd
    alignSelf = AlignSelf.stretch
}

val primary: CssStyle = {
    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 14.px
    lineHeight = 130.pct
    color = Color("#101828")
    zIndex = integer(2) // to prevent Popover's arrow overlapping
}

val secondary: CssStyle = {
    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 12.px
    lineHeight = 130.pct
    color = Color("#101828")
    zIndex = integer(2) // to prevent Popover's arrow overlapping
}

val button: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 10.px

    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 14.px
    lineHeight = 130.pct
    color = Color("#FFFFFF")

    border = None.none
    padding = 0.px

    transitionDuration = 0.s // because filter's transition is weird

    hover {
        color = important(Color("#FFFFFF"))
        filter = ("invert(52%) sepia(29%) saturate(5446%) " +
                "hue-rotate(207deg) brightness(96%) contrast(102%)").unsafeCast<FilterFunction>()
        backgroundColor = important(rgba(0, 0, 0, 0.0))
        transitionDuration = 0.s
    }
}

val divider: CssStyle = {
    alignSelf = AlignSelf.stretch
    height = 1.px
    backgroundColor = Color("#D0D5DD")
}
