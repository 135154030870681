package online.interactiver.common.admin.interactive.phrase

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PhrasesRequest(
    @SerialName("phrases")
    val phrases: Array<String>,
    @SerialName("material_language")
    val materialLanguage: String
)