package shared.components.selectWithLabel

import antd.Option
import antd.Select
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p

external interface SelectWithLabelProps : Props {
    var label: String
    var options: List<String>
    var onChange: (String) -> Unit
    var value: String
}

val SelectWithLabel = FC<SelectWithLabelProps> {
    div {
        css(verticalContainer)
        p {
            css(label)
            +it.label
        }
        Select {
            value = it.value
            onChange = { _, option ->
                it.onChange(option.value.toString())
            }
            it.options.forEach { option ->
                Option {
                    value = option
                }
            }
        }
    }
}
