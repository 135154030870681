package pages.wordsProgress

import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.serialization.json.Json
import online.interactiver.common.duo.wordsprogress.WordProgressCommon
import utils.getToken

suspend fun getWordsProgressForSliderRequest(sliderUuid: String): List<WordProgressCommon> {
    return try {
        val response = jsonClient.get("/api/v1/words-progress/${sliderUuid}") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return emptyList()
        }

        Json.decodeFromString(response.bodyAsText())
    } catch (e: ResponseException) {
        emptyList()
    }
}

suspend fun changeMarkedToPractice(keyWord: WordProgressCommon): Boolean {
    return try {
        val response = jsonClient.put("/api/v1/words-progress") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(keyWord)
        }

        response.status.value == 200
    } catch (e: ResponseException) {
        false
    }
}