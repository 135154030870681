package widgets.LanguageAutoContent.ui.components.Content.ListeningContent.components.ReadyText

import app.useAppDispatch
import entities.languageAutoCurrentRequest.SetCurrentRequestTextOrTopic
import entities.languageAutoCurrentRequest.selectCurrentRequestTextOrTopic
import react.FC
import react.Props
import react.redux.useSelector
import widgets.LanguageAutoContent.ui.components.Content.TextAreaWithLengthRestriction
import widgets.LanguageAutoContent.ui.components.Content.textPlaceholder

val ReadyText = FC<Props> {
    val dispatch = useAppDispatch()
    val textOrTopic = useSelector(selectCurrentRequestTextOrTopic)

    TextAreaWithLengthRestriction {
        label = "Or Ready text"
        value = textOrTopic
        placeholder = textPlaceholder
        onChange = { dispatch(SetCurrentRequestTextOrTopic(it)) }
    }
}