package pages.payment.ui.PromoCode

import csstype.*
import utils.types.CssStyle

val horizontalContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 30.px
}

val label: CssStyle = {
    margin = Margin(5.px, 0.px)
}

val error: CssStyle = {
    color = Color("red")
}
