package widgets.ExerciseSettings.components.SwitchWithLabel

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    width = 100.pct
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
}
