package builders.enums

sealed class ESpacingBetween(open val value: String, open val label: String) {
    sealed class Horizontal(override val value: String, override val label: String) : ESpacingBetween(value, label) {
        object AVERAGE : Horizontal("horByAvg", "Hor by Avg")
        object MINIMUM : Horizontal("horByMin", "Hor by Min")
    }

    sealed class Vertical(override val value: String, override val label: String) : ESpacingBetween(value, label) {
        object AVERAGE : Horizontal("verByAvg", "Ver by Avg")
        object MINIMUM : Horizontal("verByMin", "Ver by Min")
    }
}
