package entities.alert

import app.StoreState
import app.middlewares.RThunk
import app.middlewares.nullAction
import online.interactiver.common.utils.clone
import kotlinx.js.timers.setTimeout
import online.interactiver.common.utils.Copyable
import online.interactiver.common.utils.toBase
import redux.RAction
import redux.WrapperAction
import kotlin.random.Random
import kotlin.random.nextULong
import kotlin.time.Duration.Companion.milliseconds

enum class EAlertType(val text: String) {
    SUCCESS("success"), INFO("info"), WARNING("warning"), ERROR("error")
}

enum class EAlertTarget {
    PREVIEW, CONSTRUCTOR // here would be GLOBAL, ADVANCED_SETTINGS i.e. in the future
}

data class Alert(
    val id: String,
    val message: String,
    val type: EAlertType,
    val target: EAlertTarget,
) : Copyable<Alert> {
    override fun clone(): Alert = copy()
}

data class AlertsState(
    val alerts: Array<Alert> = arrayOf()
) : Copyable<AlertsState> {
    override fun clone(): AlertsState {
        return AlertsState(alerts.clone())
    }
}

open class AlertsAction : RAction
data class ShowAlert(val id: String, val message: String, val type: EAlertType, val target: EAlertTarget) : AlertsAction()
data class CloseAlert(val id: String) : AlertsAction()
data class ShowAutoCloseAlert(val message: String, val type: EAlertType,
                              val target: EAlertTarget, val delayMs: Int) : AlertsAction(), RThunk {
    override fun invoke(dispatch: (RAction) -> WrapperAction, getState: () -> StoreState): WrapperAction {
        val id = Random.nextULong().toBase()
        dispatch(ShowAlert(id, this.message, this.type, this.target))
        setTimeout(this.delayMs.milliseconds) {
            dispatch(CloseAlert(id))
        }
        return nullAction
    }
}

val AlertsReducer = { state: AlertsState, action: AlertsAction ->
    when(action) {
        is ShowAlert -> {
            val newAlerts = state.alerts.clone().toMutableList()
            newAlerts.add(Alert(action.id, action.message, action.type, action.target))
            AlertsState(newAlerts.toTypedArray())
        }
        is CloseAlert -> {
            val newAlerts = state.alerts.clone().toMutableList()
            newAlerts.removeAll { it.id == action.id }
            AlertsState(newAlerts.toTypedArray())
        }
        else -> state
    }
}

val selectAlerts = { state:StoreState -> state.alerts.alerts }
