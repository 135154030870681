package pages.constructor.ui

import app.*
import emotion.react.css
import entities.alert.EAlertTarget
import entities.backgroundPosScale.selectCanvasContainerHeight
import entities.backgroundPosScale.selectCanvasContainerWidth
import entities.interactivePicture.SetInteractivePicture
import entities.interactivePicture.background.EditBackgroundWithRatio
import entities.interactivePicture.selectInteractivePicture
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import entities.modalLoader.selectModalLoader
import entities.previewAndExport.SetInteractiveGroupOnServer
import entities.previewAndExport.SetInteractivesGroups
import entities.saver.SetCurrentPictureSaved
import entities.saver.selectSaver
import getAuthEndpoint
import getMyGroups
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event
import org.w3c.dom.get
import org.w3c.dom.url.URL
import pages.constructor.ui.components.advancedElementsSettings.AdvancedElementSettings
import pages.constructor.ui.components.alerts.Alerts
import pages.constructor.ui.components.canvas.Canvas
import pages.constructor.ui.components.editors.Editors
import pages.constructor.ui.components.header.Header
import pages.constructor.ui.components.header.interactiveParams.getGroupsConfig
import pages.constructor.ui.components.modalLoader.ModalLoader
import pages.constructor.ui.components.modalUpgradePlan.ModalUpgradePlan
import pages.constructor.ui.components.modalVersionDiff.ModalVersionDiff
import pages.constructor.ui.components.tools.Tools
import pages.constructor.ui.components.viewers.Viewers
import react.*
import react.dom.html.ReactHTML.div
import redux.RAction
import shared.canvas.interfaces.getCanvasMaxWidth
import utils.*
import utils.structures.Point
import validateToken
import kotlin.math.min

const val constructorBgColor = "#E7E7E8"

val Constructor = FC<Props> {
    document.body?.style?.margin = "0"
    document.body?.style?.backgroundColor = constructorBgColor
    document.body?.style?.height = "100%"
    (document.getElementsByTagName("html")[0] as HTMLElement).style.height = "100%"

    val stageRef = useRef<dynamic>()
    val dispatch = useAppDispatch()
    val canvasContainerWidth = useAppSelector(selectCanvasContainerWidth)
    val canvasContainerHeight = useAppSelector(selectCanvasContainerHeight)
    val modalLoader = useAppSelector(selectModalLoader)
    val context = useContext(ConstructorMetaContext)

    val auth = checkAuth()
    if (!auth) {
        GlobalScope.launch {
            redirectToAuth()
        }
        return@FC
    }

    if (extractGroupIdFromUrl(window.location.href) == "") {
        var uuid = extractUUIDFromUrl(window.location.href)
        if (uuid == null || uuid == "") {
            uuid = getUuid()
            if (uuid != "") {
                GlobalScope.launch {
                    clearCookiesAndGetInteractive(uuid)
                }
                return@FC
            }
        }
    }

    val updateInteractive = useUpdateInteractive()
    val copyInteractive = useCopyInteractive()

    div {
        css(alertsContainer)
        Alerts {
            css(alert)
            target = EAlertTarget.CONSTRUCTOR
        }
    }
    AdvancedElementSettings { }
    ModalUpgradePlan {
        this.show = context.showModalUpgradePlan
        this.setShow = context.setShowModalUpgradePlan!!
    }
    ModalVersionDiff {
        this.show = context.showModalVersionDiff
        this.setShow = context.setShowModalVersionDiff!!
        update = {
            updateInteractive { }
        }
        copyAndUpdate = {
            MainScope().launch {
                copyInteractive { }?.join()
                updateInteractive { }
            }
        }
    }
    ModalLoader {
        loading = modalLoader.loading
        text = modalLoader.text
    }
    Header { }
    div {
        css(container)
        div {
            Tools { }
        }
        div {
            css(middleContainer)
            div {
                css(canvasContainer)
                Canvas { this.stageRef = stageRef }
            }
            div {
                Viewers { this.stageRef = stageRef }
            }
        }
        div {
            Editors { this.stageRef = stageRef }
        }
    }


    GlobalScope.launch {
        val tokenValidation = validateToken()

        if (tokenValidation.code != 200) {
            redirectToAuth()
            return@launch
        }

    }

    useEffect(canvasContainerWidth, canvasContainerHeight) {
        dispatch(StartModalLoading("Loading file for editing, please wait"))
        GlobalScope.launch {
            setInteractiveGroupId(dispatch)
            uploadInteractive(dispatch, canvasContainerWidth, canvasContainerHeight)
            dispatch(EndModalLoading())
        }
    }


    val saver = useAppSelector(selectSaver)
    val savedPicture = saver.savedPicture
    val currentPicture = useAppSelector(selectInteractivePicture)
    useEffect(savedPicture, currentPicture) {
        if (savedPicture != currentPicture) {
            @Suppress("UNCHECKED_CAST")
            window.addEventListener("beforeunload", context.beforeUnloadListener as ((Event) -> Unit)?)
        } else {
            @Suppress("UNCHECKED_CAST")
            window.removeEventListener("beforeunload", context.beforeUnloadListener as ((Event) -> Unit)?)
        }
    }
}

suspend fun redirectToAuth() {
    val endpoint = URL(getAuthEndpoint())
    endpoint.search = mergeQueryParams(endpoint.search, window.location.search)
    window.location.href = endpoint.toString()
}

/**
 * Receives 2 strings with query parameters.
 * They must be empty or start with ?
 */
fun mergeQueryParams(firstQueries: String, secondQueries: String): String {
    val firstHasQueries = firstQueries.isNotEmpty()
    val secondHasQueries = secondQueries.isNotEmpty()
    return when {
        firstHasQueries && secondHasQueries -> "${firstQueries}&${secondQueries.substring(1)}"
        firstHasQueries && !secondHasQueries -> firstQueries
        !firstHasQueries && secondHasQueries -> secondQueries
        else -> ""
    }
}

fun redirectToEditor() {
    window.location.href = "/editor"
}

fun redirectToInteractive(uuid: String) {
    window.location.href = "/editor/${uuid}"
}

fun clearCookiesAndGetInteractive(uuid: String) {
    window.location.href = "/editor/clear/${uuid}"
}

suspend fun setInteractiveGroupId(dispatch: (RAction) -> RAction) {
    val interactiveGroupId = URL(window.location.href).searchParams.get("interactive_group_id")?.toIntOrNull()
    val response = getMyGroups()

    if (response.code != 200) {
        return
    }

    val groups = getGroupsConfig(response.content)
    dispatch(SetInteractivesGroups(groups))

    val usersGroupIds = groups.map { it.id }
    if (!usersGroupIds.contains(interactiveGroupId)) {
        dispatch(SetInteractiveGroupOnServer(groups.minByOrNull { it.creationTime ?: Long.MAX_VALUE }?.id))
        return
    }
    dispatch(SetInteractiveGroupOnServer(interactiveGroupId))
}

suspend fun uploadInteractive(dispatch: (RAction) -> RAction, width: Int, height: Int) {
    val uuid = extractUUIDFromUrl(window.location.href)

    if (uuid == null || uuid == "") {
        return
    }

    val newInteractivePicture = uuidToInteractivePicture(uuid)

    if (newInteractivePicture == null) {
        redirectToEditor()
        return
    }

    val interactiveData = uuidToInteractiveData(uuid)

    if (interactiveData == null) {
        redirectToEditor()
        return
    }

    val canvasMaxWidth = newInteractivePicture.getCanvasMaxWidth()

    dispatch(
        EditBackgroundWithRatio(
            min(width, canvasMaxWidth),
            height,
            Point(
                newInteractivePicture.background.width ?: 16,
                newInteractivePicture.background.height ?: 9
            )
        )
    )
    dispatch(SetInteractivePicture(newInteractivePicture, false))
    dispatch(SetInteractiveGroupOnServer(interactiveData.interactivesGroupId))
    dispatch(SetCurrentPictureSaved())
    console.log(interactiveData.interactivesGroupId)
}