package shared.components.description

import csstype.Color
import csstype.FontWeight
import csstype.px
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.p

external interface DescriptionProps : Props {
    var text: String
    var fontSize: Double?
    var lineHeight: Double?
}

val Description = FC<DescriptionProps> {
    p {
        css {
            margin = 0.px
            padding = 0.px
            color = Color("#98A2B3")
            fontWeight = FontWeight.normal
            fontSize = it.fontSize?.px
            lineHeight = it.lineHeight?.px
        }
        +it.text
    }
}