package online.interactiver.common.course

import kotlinx.serialization.Serializable
import online.interactiver.common.enums.ECourseLevel

val defaultNumberOfAppearances = ECourseLevel.REPEAT.timesToAppearInCourse
val numberOfLastGptGenerationAttempt = 3

@Serializable
data class MaterialToGenerateExercises(
    var value: String = "",
    var definition: String = "",
    var numberOfAppearances: Int = defaultNumberOfAppearances,
    var audioSrc: String? = null,
    var imageSrc: String? = null,
    var keyWord: String? = null,
    var keyWordDefinition: String? = null,
    var languageToLearn: String? = null,
    var interfaceLanguage: String? = null,
    var id: Int
)
