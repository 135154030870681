package widgets.LanguageAutoTask.ui

import csstype.*
import utils.inject
import utils.types.CssStyle
import utils.types.extend

val headerContainer: CssStyle = {
    width = 100.pct
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 16.px
}

val headerContent: CssStyle = {
    width = 100.pct
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.center
}

val numberOfSlides: CssStyle = {
    margin = 0.px
    fontSize = 16.px
    lineHeight = 21.6.px
    fontWeight = FontWeight.normal
    color = Color("#5D6676")
}

val divider: CssStyle = {
    width = 100.pct
    height = 1.px
    backgroundColor = Color("#E3E6EB")
}

val advancedEditButton: (Boolean) -> CssStyle = {
    {
        alignSelf = AlignSelf.flexEnd
        color = Color(if (it) "#5D6676" else "#98A2B3")
        fontSize = 14.px
        lineHeight = 18.2.px
        border = None.none
        textDecoration = TextDecoration.underline
        boxShadow = None.none
    }
}

val taskTextArea: CssStyle = {
    resize = important(None.none)
    minHeight = important(398.px)
}

val genMoreSlidesButton: CssStyle = {
    // font
    color = important(Color("#597EF7"))
    fontFamily = string("Montserrat, system-ui")
    fontSize = 14.px
    fontStyle = FontStyle.normal
    fontWeight = integer(500)

    // container
    display = Display.flex
    gap = 6.px
    alignItems = AlignItems.center
    height = Auto.auto

    // border
    border = None.none
}

val genMoreSlidesButtonDisabled: CssStyle = genMoreSlidesButton.extend {
    color = important(rgba(0, 0, 0, 0.25))
    cursor = Cursor.notAllowed
}

val iconDisabled: CssStyle = {
    filter = array(saturate(1), brightness(0), opacity(0.25))
}

val buttonsContainer: CssStyle = {
    width = 100.pct
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
}

val mainButtons: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 15.px
}

val resetButton: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    padding = Padding(6.px, 16.px)
    gap = 4.px
    backgroundColor = Color("#F2F4F7")
    color = Color("#5D6676")
    fontWeight = integer(600)
    fontSize = 16.px
    lineHeight = 20.8.px
    borderRadius = 44.px
    border = None.none
}

val addSlideButtonsGroup: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.spaceAround
}
