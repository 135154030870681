package shared.components.inputNumberWithPlusAndMinus

import csstype.*
import utils.inject
import utils.types.CssStyle


val inputContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 10.px
    marginTop = 5.px
}

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    justifyContent = JustifyContent.center
    alignItems = AlignItems.flexStart
    gap = 5.px
}