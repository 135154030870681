package pages.constructor.ui.components.advancedSettings

import Modal
import antd.Button
import app.useAppDispatch
import app.useAppSelector
import online.interactiver.common.enums.ELanguage
import emotion.react.css
import entities.advancedElementSettings.OpenAdvancedElementSettings
import entities.interactivePicture.advancedSettings.SetAdvancedSettings
import entities.interactivePicture.advancedSettings.selectAdvancedSettings
import entities.interactivePicture.selectElementIsFocused
import enums.EButtonTypes
import kotlinx.js.jso
import online.interactiver.common.interactivepicture.AdvancedSettings
import pages.constructor.ui.components.advancedSettings.analyticsSettings.AnalyticsSettingsEditor
import pages.constructor.ui.components.advancedSettings.buttonsSettings.ButtonsSettingsEditor
import pages.constructor.ui.components.advancedSettings.checkSettings.CheckSettingsEditor
import pages.constructor.ui.components.advancedSettings.hintsSettings.HintsSettingsEditor
import pages.constructor.ui.components.advancedSettings.puzzlesSettings.PuzzlesSettingsEditor
import pages.constructor.ui.components.advancedSettings.requestSettings.GeneratingSettingsEditor
import pages.constructor.ui.components.advancedSettings.selectorsSettings.SelectorsSettingsEditor
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useEffect
import react.useState
import shared.components.Icon


val AdvancedSettingsModal = FC<Props> {
    val advancedSettings = useAppSelector(selectAdvancedSettings)
    val dispatch = useAppDispatch()

    val (newSettings, setNewSettings) = useState(advancedSettings)
    val (isOpen, setIsOpen) = useState(false)

    useEffect(advancedSettings) {
        setNewSettings(advancedSettings)
    }

    val elementIsFocused = useAppSelector(selectElementIsFocused)

    val taskLanguage = ELanguage.values().find { it.text == newSettings.taskType.language }!!

    Button {
        css(advancedSettingsButton)
        id = EButtonTypes.SHOW_ADVANCED_SETTINGS_BUTTON.value
        Icon {
            src = "ic_settings_21x21.svg"
        }
        onClick = {
            if (elementIsFocused) {
                dispatch(OpenAdvancedElementSettings())
            } else {
                setIsOpen { wasOpen ->
                    !wasOpen
                }
            }
        }
        div {
            css(settingText)
            +"Advanced settings"
        }
    }
    Modal {
        this.width = 800
        this.open = isOpen
        this.onCancel = {
            setIsOpen(false)
            setNewSettings(advancedSettings)
        }
        this.onOk = {
            setIsOpen(false)
            dispatch(SetAdvancedSettings(newSettings))
        }
        this.bodyStyle = jso(container)

        div {
            css(header)
            +"Advanced settings"
            Button {
                css(resetSettingsButton)
                type = "text"
                onClick = {
                    setNewSettings(AdvancedSettings())
                }
                +"Reset all settings"
            }
        }
        div {
            css(settingsContainer)
            div {
                css(column)
                div {
                    css(settingsHeader)
                    +"ELEMENTS OPTIONS"
                }
                ButtonsSettingsEditor {
                    settings = newSettings.buttonSettings
                    onChange = {
                        setNewSettings { prevSettings ->
                            prevSettings.copy(
                                buttonSettings = it
                            )
                        }
                    }
                }
                HintsSettingsEditor {
                    settings = newSettings.hintsSettings
                    onChange = {
                        setNewSettings { prevSettings ->
                            prevSettings.copy(
                                hintsSettings = it
                            )
                        }
                    }
                }
                SelectorsSettingsEditor {
                    settings = newSettings.selectorsSettings
                    onChange = {
                        setNewSettings { prevSettings ->
                            prevSettings.copy(
                                selectorsSettings = it
                            )
                        }
                    }
                }
                PuzzlesSettingsEditor {
                    settings = newSettings.puzzleSettings
                    onChange = {
                        setNewSettings { prevSettings ->
                            prevSettings.copy(
                                puzzleSettings = it
                            )
                        }
                    }
                }
            }
            div {
                css(column)
                GeneratingSettingsEditor {
                    settings = newSettings.requestSettings
                    onChange = {
                        setNewSettings { prevSettings ->
                            prevSettings.copy(
                                requestSettings = it
                            )
                        }
                    }
                }
                CheckSettingsEditor {
                    settings = newSettings.checkSettings
                    onChange = {
                        setNewSettings { prevSettings ->
                            prevSettings.copy(
                                checkSettings = it
                            )
                        }
                    }
                    this.taskLanguage = taskLanguage
                    radioButtonMode = newSettings.buttonSettings.radioButtonMode
                }
                AnalyticsSettingsEditor {
                    settings = newSettings.analyticsSettings
                    onChange = {
                        setNewSettings { prevSettings ->
                            prevSettings.copy(
                                analyticsSettings = it
                            )
                        }
                    }
                }
            }
        }
    }
}
