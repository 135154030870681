package pages.constructor.ui.components.editors.alignElementsPicker

import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import emotion.react.css
import builders.enums.EAlign
import antd.Button
import app.appState.selectAppState
import app.useAppDispatch
import app.useAppSelector
import kotlin.math.min
import kotlin.math.max
import entities.interactivePicture.selectElementsUnderSelectionRectangle
import shared.components.Icon
import react.key
import redux.RAction
import shared.canvas.interfaces.getElementBounds



val AlignElementsPicker = FC<Props> {
    val dispatch = useAppDispatch()
    val appState = useAppSelector(selectAppState)
    val allElementOfSelectedGroup = useAppSelector(selectElementsUnderSelectionRectangle)
    var minLeft: Int? = null
    var minTop: Int? = null
    var maxRight: Int? = null
    var maxBottom: Int? = null
    var centerHorizontal: Int? = null
    var middleVertical: Int? = null
    if (allElementOfSelectedGroup.isNotEmpty()) {
        minLeft = allElementOfSelectedGroup[0].getElementBounds().x
        minTop = allElementOfSelectedGroup[0].getElementBounds().y
        maxRight = allElementOfSelectedGroup[0].getElementBounds().x.plus(allElementOfSelectedGroup[0].getElementBounds().width)
        maxBottom = allElementOfSelectedGroup[0].getElementBounds().y.plus(allElementOfSelectedGroup[0].getElementBounds().height)
        allElementOfSelectedGroup.forEach{
            minLeft = min(it.getElementBounds().x,minLeft ?: 0)
            minTop = min(it.getElementBounds().y,minTop ?: 0)
            maxRight = max(it.getElementBounds().x.plus(it.getElementBounds().width), maxRight ?: 0)
            maxBottom = max(it.getElementBounds().y.plus(it.getElementBounds().height), maxBottom ?: 0)
        }
        centerHorizontal = (0.5*(minLeft?.plus(maxRight!!)!!)).toInt()
        middleVertical = (0.5*(minTop?.plus(maxBottom!!)!!)).toInt()
    }
    class AlignMetadata(
        val label: String,
        val iconSrc: String,
        val value: Int?,
        val actionFactory: (Array<String>, Int, String) -> RAction
    )
    div {
        css(containerAlignElementsForHeader)
        div {
            css(alignElementsHeader)
            +"Align elements"
        }
    }
    div {
        css(buttonContainerColumn)
        arrayOf(
            AlignMetadata(EAlign.Horizontal.LEFT.label, "ic_elements_align_left_18x18.svg", minLeft, appState::getSetElementsHorizontalAlignForElements),
            AlignMetadata(EAlign.Vertical.TOP.label, "ic_elements_align_top_18x18.svg", minTop, appState::getSetElementsVerticalAlignForElements),
            AlignMetadata(EAlign.Horizontal.CENTER.label, "ic_elements_align_horizontal_center_18x18.svg", centerHorizontal, appState::getSetElementsHorizontalAlignForElements),
            AlignMetadata(EAlign.Vertical.MIDDLE.label, "ic_elements_align_vertical_center_18x18.svg", middleVertical, appState::getSetElementsVerticalAlignForElements),
            AlignMetadata(EAlign.Horizontal.RIGHT.label, "ic_elements_align_right_18x18.svg", maxRight, appState::getSetElementsHorizontalAlignForElements),
            AlignMetadata(EAlign.Vertical.BOTTOM.label, "ic_elements_align_bottom_18x18.svg", maxBottom, appState::getSetElementsVerticalAlignForElements),
        ).map {
            Button {
                css(buttonForAlignElements)
                key = it.iconSrc
                Icon {
                    src = it.iconSrc
                }
                div {
                    css(iconText)
                    +it.label
                }
                onClick = {
                    it.value?.let { value ->
                        dispatch(it.actionFactory(allElementOfSelectedGroup.map { it.identifier.id!! }.toTypedArray(), value, it.label))
                    }
                }

            }
        }
    }
}