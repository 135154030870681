package entities.interactivePicture.elements.editors.phraseTextEditor

import antd.TextArea
import app.useAppDispatch
import app.useAppSelector
import builders.enums.EInteractiveType
import emotion.react.css
import entities.interactivePicture.elements.editors.textEditor.getTextArea
import entities.interactivePicture.elements.editors.textEditor.header
import entities.interactivePicture.elements.editors.textEditor.wrapContainer
import entities.interactivePicture.elements.other.SetPhraseText
import entities.interactivePicture.selectFocusedElement
import enums.EButtonTypes
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useEffect
import react.useState

val PhraseTextEditor = FC<Props> {
    val selected = useAppSelector(selectFocusedElement)
    val dispatch = useAppDispatch()
    var value = ""
    selected?.phrase?.words?.forEach {
        value += it.word + " | "
    }
    if (value.isNotBlank()) {
        value = value.substringBeforeLast(" | ")
    }

    val (editorPhraseValue, setEditorPhraseValue) = useState(value)

    useEffect(value) {
        setEditorPhraseValue(value)
    }

    if (selected?.type != EInteractiveType.PHRASE_INTERACTIVE.text) {
        return@FC
    }

    val width = 210
    val height = 100

    div {
        css(container)
        div {
            css(header(width, 700))
            +"Phrase"
        }
        div {
            css(wrapContainer(width))
            TextArea {
                css(getTextArea(height, width))
                dir = "auto"
                id = EButtonTypes.PHRASE_INPUT.value
                this.value = editorPhraseValue
                placeholder = "Type phrase"
                onChange = { evt ->
                    setEditorPhraseValue(evt.target.value)
                    dispatch(SetPhraseText(selected.identifier.id!!, evt.target.value))
                }
            }
        }
    }
}