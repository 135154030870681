package app

import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.BeforeUnloadEvent
import pages.constructor.ui.Constructor
import pages.googleAuth.ui.GoogleAuth
import pages.languageAuto.languageAutoStore
import pages.languageAuto.ui.ERoute
import pages.languageAuto.ui.LanguageAuto
import pages.payment.ui.Payment.Payment
import pages.scoreViewer.ScoreViewer
import pages.studentDuo.ui.StudentDuo
import react.*
import react.redux.Provider
import react.router.Route
import react.router.Routes
import react.router.dom.BrowserRouter

data class ConstructorMetaContextData(
    val beforeUnloadListener: (BeforeUnloadEvent) -> Unit = { },
    val showModalVersionDiff: Boolean = false,
    val setShowModalVersionDiff: StateSetter<Boolean>? = null,
    val showModalUpgradePlan: Boolean = false,
    val setShowModalUpgradePlan: StateSetter<Boolean>? = null,
    val showModalPreviewShare: Boolean = false,
    val setShowModalPreviewShare: StateSetter<Boolean>? = null
)

val ConstructorMetaContext = createContext(ConstructorMetaContextData())

val AppContent = FC<Props> {
    val beforeUnloadHandler: ((BeforeUnloadEvent) -> Unit) = { event ->
        event.preventDefault()
        event.returnValue = ""
    }
    val (showModalVersionDiff, setShowModalVersionDiff) = useState(false)
    val (showModalUpgradePlan, setShowModalUpgradePlan) = useState(false)
    val (showModalPreviewShare, setShowModalPreviewShare) = useState(false)

    ConstructorMetaContext.Provider {
        value = ConstructorMetaContextData(
            beforeUnloadListener = beforeUnloadHandler,
            showModalVersionDiff = showModalVersionDiff,
            setShowModalVersionDiff = setShowModalVersionDiff,
            showModalUpgradePlan = showModalUpgradePlan,
            setShowModalUpgradePlan = setShowModalUpgradePlan,
            showModalPreviewShare = showModalPreviewShare,
            setShowModalPreviewShare = setShowModalPreviewShare
        )
        Constructor {}
    }
}

val App = FC<Props> {
    BrowserRouter {
        Routes {
            Route {
                path = "editor/*"
                element = VFC {
                    Provider {
                        store = app.store
                        AppContent { }
                    }
                }.create()
            }
            Route {
                path = "scores"
                element = ScoreViewer.create()
            }
            Route {
                path = ERoute.GOOGLE_AUTH.path
                element = GoogleAuth.create()
            }
            Route {
                path = "${ERoute.STUDENT_DUO.path}/*"
                element = StudentDuo.create()
            }
            Route {
                path = ERoute.PAYMENT.path
                element = Payment.create()
            }
            Route {
                path = "*"
                element = VFC {
                    Provider {
                        store = languageAutoStore
                        LanguageAuto {}
                    }
                }.create()
            }
        }
    }
}