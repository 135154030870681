package pages.duoSettings.duoExercisesSettings

import antd.Button
import app.useAppDispatch
import csstype.Auto
import csstype.Margin
import csstype.px
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.ELanguage
import online.interactiver.common.user.UpdateUserRequest
import pages.duoSettings.duoProfile.container
import pages.studentDuo.selectRequiredStudentDuoNativeLanguage
import react.FC
import react.VFC
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import react.router.useNavigate
import shared.components.ProtectedRoute.ProtectedRoute
import shared.components.checkboxWithLabel.CheckboxWithLabel
import utils.localization.Localization
import utils.types.extend
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton
import widgets.StudentDuoLayout.StudentDuoLayout
import widgets.UserProfile.SetStudentDuoAutoSoundExercise
import widgets.UserProfile.selectStudentDuoAutoSoundExercise
import widgets.UserProfile.updateUserRequest

val DuoExercisesSettings = VFC {
    val nativeLanguage = useSelector(selectRequiredStudentDuoNativeLanguage)
    val autoSoundExercise = useSelector(selectStudentDuoAutoSoundExercise)
    val dispatch = useAppDispatch()
    val navigate = useNavigate()

    ProtectedRoute {
        toStudentDuo = true
        StudentDuoLayout {
            buttons = FC {
                Button {
                    css(newTaskButton.extend {
                        margin = Margin(0.px, Auto.auto)
                    })
                    onClick = {
                        navigate(-1)
                    }
                    +Localization.getBackButtonText(nativeLanguage)
                }
            }
            div {
                css(container)
                CheckboxWithLabel {
                    label = getAutoSound(nativeLanguage)
                    checked = autoSoundExercise
                    onChange = {
                        dispatch(SetStudentDuoAutoSoundExercise(it))
                        GlobalScope.launch {
                            val updated = updateUserRequest(
                                UpdateUserRequest(
                                    autoSoundExercise = it
                                )
                            )
                            if (updated) {
                                return@launch
                            }

                            dispatch(OpenErrorModal("Error updating setting"))
                            dispatch(SetStudentDuoAutoSoundExercise(!it))
                        }
                    }
                }
            }
        }
    }
}

fun getAutoSound(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Aвтоматически озвучивать когда задание решено"
    } else {
        "Auto sound when exercise solved"
    }
}