package widgets.LanguageAutoSlider.ui

import csstype.*
import utils.types.CssStyle
import utils.types.shadowArray

val widget: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    gap = 14.px
    width = 100.pct
    maxWidth = 960.px
    minHeight = 700.px
    padding = 36.px
    boxSizing = BoxSizing.borderBox
    backgroundColor = Color("white")
    borderRadius = 12.px
    boxShadow = shadowArray(
        BoxShadow(0.px, 3.px, 18.px, (-1).px, Color("#32324717")), BoxShadow(0.px, 0.px, 1.px, 0.px, Color("#0C1A4B3D"))
    )
}

val sliderContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    width = 100.pct
    maxWidth = 710.px
    gap = 10.px
}

val iFrameContainer: CssStyle = {
    height = 562.px
    width = 100.pct
    display = Display.flex
    gap = 10.px
    justifyContent = JustifyContent.center
}

val iFrame: CssStyle = {
    height = 100.pct
    width = 100.pct
    border = Border(0.px, LineStyle.hidden)
    overflowX = Overflow.hidden
    overflowY = Overflow.hidden
    margin = 0.px
    padding = 0.px
}

val actions: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
    paddingTop = 15.px
}

val actionsWidth: CssStyle = {
    minWidth = 150.px
}

val actionButton: CssStyle = {
    borderRadius = 7.px
    boxSizing = BoxSizing.borderBox
    padding = 5.px
}
