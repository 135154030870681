package pages.constructor.ui.components.advancedElementsSettings.advancedInputSettings

import csstype.*
import pages.constructor.ui.constructorBgColor
import utils.types.CssStyle

val header: CssStyle = {
    fontFamily = string("\"Arial\", system-ui")
    fontSize = 18.px
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    lineHeight = 130.pct

    color = Color("#282F3E")

    marginBottom = 31.px
}

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    justifyContent = JustifyContent.center
    alignItems = AlignItems.start
    gap = 18.px

    width = 33.pct
    minWidth = 240.px
}

val elementTypeHeader: CssStyle = {
    fontFamily = string("\"Arial\", system-ui")
    fontSize = 14.px
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    lineHeight = 130.pct

    color = Color("#98A2B3")

    textTransform = TextTransform.uppercase
}

val totalCorrectAnswer: CssStyle = {
    fontFamily = string("\"Arial\", system-ui")
    fontSize = 12.px
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    lineHeight = 130.pct

    color = Color("#5D6676")
}

val addButton: CssStyle = {
    display = Display.flex
    borderWidth = 0.px
    backgroundColor = Color("#F2F4F7")
    width = 100.pct
    height = 35.px
    fontFamily = string("\"Inter\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 14.px
    lineHeight = 17.px
    color = Color("#101828")
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    order = integer(0)
    flexGrow = number(0.0)
    borderRadius = 5.px
}

val inputContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.center
    gap = 10.px
    marginTop = 10.px
}

val input: CssStyle = {
    resize = important(None.none)
    padding = Padding(10.px, 2.px, 10.px, 10.px)
    backgroundColor = Color("#FFFFFF")
    borderRadius = 3.px

    fontFamily = string("\"Inter\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 12.px
    color = Color("#2E2E2E")

    height = Globals.inherit
    width = 210.px
}

val deleteButton: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    padding = 0.px
    border = None.none
}