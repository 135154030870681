package shared.components.tooltipInfo

import csstype.Color
import csstype.Display
import csstype.None
import csstype.px
import utils.types.CssStyle

val tooltipButton: CssStyle = {
    padding = 0.px
    margin = 0.px

    width = 14.px
    height = 14.px

    display = Display.block

    border = None.none
    backgroundColor = Color("#fff")
}
