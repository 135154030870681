package pages.scoreViewer

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    paddingLeft = 140.px
    paddingRight = 140.px
    display = Display.flex
    flexDirection = FlexDirection.column
}

val medalStyle: CssStyle = {
    display = Display.flex
    justifyContent = JustifyContent.center
}

val titleStyle: CssStyle = {
    textAlign = TextAlign.center
    color = Color("#21254A")
    fontFamily = string("Montserrat")
    fontSize = 50.px
    fontWeight = integer(700)
    lineHeight = 120.pct
    textTransform = TextTransform.uppercase
}

val subtitleStyle: CssStyle = {
    color = Color("#181641")
    fontFamily = string("Montserrat")
    fontSize = 32.px
    fontWeight = integer(500)
    lineHeight = 120.pct
}

val noDataTitleStyle: CssStyle = {
    textAlign = TextAlign.center
    color = Color("#7B848D")
    fontFamily = string("Montserrat")
    fontSize =  24.px
    fontStyle = FontStyle.normal
    fontWeight = FontWeight.normal
    lineHeight = 120.pct
}

val progressBarsHeaderStyle: CssStyle = {
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.center
}

val sortButtonStyle: CssStyle = {
    cursor = Cursor.pointer
}