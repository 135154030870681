package widgets.Exercises.ui

import app.useAppDispatch
import emotion.react.css
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.autogeneration.LanguageAutoState
import online.interactiver.common.enums.EAccessType
import pages.languageAuto.getUserProfileRequest
import react.VFC
import react.dom.html.ReactHTML.section
import react.router.dom.useSearchParams
import react.useEffect
import react.useState
import widgets.Exercises.getLanguageAutoRequest
import widgets.Exercises.ui.components.PublicExerciseView.PublicExerciseView

val PublicExercises = VFC {
    val (searchParams, setSearchParams) = useSearchParams()
    val dispatch = useAppDispatch()

    val requestId = searchParams.get("request-id")?.toIntOrNull()
    val userName = searchParams.get("user-name")

    val (selectedRequest, setSelectedRequest) = useState<LanguageAutoState?>(null)
    val isUserOwns = searchParams.get("is-user-owns") == true.toString()

    useEffect(requestId) {
        if (requestId == null || selectedRequest != null) {
            return@useEffect
        }

        dispatch(StartModalLoading("Getting exercise..."))
        GlobalScope.launch {
            val request = getLanguageAutoRequest(requestId, true)

            dispatch(EndModalLoading())
            setSelectedRequest(request)
        }
    }

    PublicExerciseView {
        isOpen = requestId != null
        close = {
            setSelectedRequest(null)
            searchParams.delete("request-id")
            searchParams.delete("is-user-owns")
            searchParams.delete("user-name")
            setSearchParams(searchParams)
        }
        request = selectedRequest
        this.isUserOwns = isUserOwns
        this.userName = userName
    }
    section {
        css(widget)
        Exercises {
            accessType = EAccessType.PUBLIC
            onExerciseClick = {
                dispatch(StartModalLoading("Getting exercise..."))
                GlobalScope.launch {
                    val request = getLanguageAutoRequest(it.requestId, true)
                    val userName = getUserProfileRequest(request?.userId).data?.userName

                    dispatch(EndModalLoading())
                    searchParams.set("request-id", it.requestId.toString())
                    searchParams.set("is-user-owns", it.isUserOwns.toString())
                    userName?.let {searchParams.set("user-name", it) }
                    setSearchParams(searchParams)
                    setSelectedRequest(request)
                }
            }
        }
    }
}