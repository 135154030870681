package pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewShareTabs

import antd.Button
import emotion.react.css
import enums.EButtonTypes
import pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewShareTabs.integrateTabContent.IntegrateTabContent
import pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewShareTabs.publishTabContent.PublishTabContent
import pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewShareTabs.viewStatisticsTabContent.ViewStatisticsTabContent
import react.*
import react.dom.html.ReactHTML.div

enum class EPreviewShareTabs(
    val label: String,
    val buttonId: String,
    val content: ElementType<Props>? = null
) {
    PUBLISH("Publish", EButtonTypes.PUBLISH_TAB_PREVIEW_SHARE_BUTTON.value, PublishTabContent),
    INTEGRATE("Integrate", EButtonTypes.INTEGRATE_TAB_PREVIEW_SHARE_BUTTON.value, IntegrateTabContent),
    VIEW_STATISTICS("View statistics", EButtonTypes.VIEW_STATISTICS_TAB_PREVIEW_SHARE_BUTTON.value, ViewStatisticsTabContent)
}

val PreviewShareTabs = VFC {
    val (activeTab, setActiveTab) = useState(EPreviewShareTabs.PUBLISH)

    div {
        css(tabsContainer)
        EPreviewShareTabs.entries.forEach {
            PreviewShareTab {
                tab = it
                this.activeTab = activeTab
                this.setActiveTab = setActiveTab
            }
        }
    }
    activeTab.content?.invoke()
}

external interface PreviewShareTabProps: Props {
    var tab: EPreviewShareTabs
    var activeTab: EPreviewShareTabs
    var setActiveTab: StateSetter<EPreviewShareTabs>
}

val PreviewShareTab = FC<PreviewShareTabProps> { props ->
    div {
        css(headerContainer)
        Button {
            if (props.activeTab == props.tab) css(activeHeader) else css(defaultHeader)
            id = props.tab.buttonId
            onClick = { props.setActiveTab(props.tab) }
            div {
                css(tabText)
                +props.tab.label
            }
        }
    }
}