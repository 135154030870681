package pages.constructor.ui.components.header.previewShare

import antd.Button
import app.ConstructorMetaContext
import app.useSaveInteractive
import emotion.react.css
import enums.EButtonTypes
import kotlinx.browser.window
import pages.constructor.ui.components.header.previewShare.previewModal.PreviewModal
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useContext
import utils.extractUUIDFromUrl

val PreviewShare = FC<Props> {
    val metaContext = useContext(ConstructorMetaContext)
    val previewOpen = metaContext.showModalPreviewShare
    val setPreviewOpen = metaContext.setShowModalPreviewShare
    val uuid = extractUUIDFromUrl(window.location.href)

    val saveInteractive = useSaveInteractive()

    PreviewModal {
        isOpen = previewOpen
        onClose = { setPreviewOpen!!(false) }
    }

    div {
        css(container)
        Button {
            css(buttonPreviewShare)
            id = EButtonTypes.PREVIEW_SHARE_BUTTON.value
            ghost = true
            onClick = {
                if (uuid.isNullOrBlank()) {
                    saveInteractive {}
                } else {
                    setPreviewOpen!!(true)
                }
            }
            type = "text"
            +"Preview & Share"
        }
    }
}
