package pages.course.ui

import emotion.react.css
import features.GenerateAudiosForCourseButton.ui.GenerateAudiosForCourseButton
import features.GenerateCourseButton.ui.GenerateCourseButton
import react.VFC
import react.dom.html.ReactHTML.section
import shared.components.ProtectedRoute.ProtectedRoute
import widgets.CourseGenerationType.ui.CourseGenerationType.CourseGenerationType
import widgets.CourseMaterials.ui.CourseMaterials
import widgets.CourseSettings.ui.CourseSettings
import widgets.LanguageAutoContent.ui.widget

val Course = VFC {
    ProtectedRoute {
        section {
            css(widget)
            CourseSettings {}
            CourseGenerationType {}
            CourseMaterials {
                withAudio = false
            }
            GenerateAudiosForCourseButton {}
            CourseMaterials {
                withAudio = true
                header = "3. The last step: review audios"
            }
            GenerateCourseButton {}
        }
    }
}
