package widgets.Exercises.ui.components.PublicExerciseView

import csstype.*
import csstype.Display
import csstype.FlexDirection
import csstype.JustifyContent
import csstype.px
import utils.types.CssStyle
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton

val modalStyle: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 22.px
    alignItems = AlignItems.center
}

val infoContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
}

val buttonsContainer: CssStyle = {
    display = Display.flex
    gap = 16.px
}

val mobile = Selector("@media(max-width: 768px)")

val iFrameContainer: CssStyle = {
    height = 562.px
    width = 450.px
    display = Display.flex
    gap = 10.px
    justifyContent = JustifyContent.center

    mobile {
        height = 875.px
        width = 700.px
    }
}

val linkToAuthor: CssStyle = {
    textDecoration = TextDecoration.underline
    color = Color("#98A2B3")
}

val copyAndChangeButton = newTaskButton
