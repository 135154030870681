package widgets.LanguageAutoContent

import AdvancedResponse
import ApiResponse
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.serialization.json.Json
import online.interactiver.common.autogeneration.GenerateLanguageTaskAndSliderResponse
import online.interactiver.common.autogeneration.SliderAutoGenerationFolder
import online.interactiver.common.enums.EContentType
import online.interactiver.common.interactiveexercise.maxTopicLength
import utils.getToken

suspend fun generateTaskSliderRequest(
    contentType: String, textOrTopic: String, vocabulary: String,
    languageToLearn: String?, interfaceLanguage: String, languageLevel: String, name: String? = null,
    numberOfSlidesToGenerate: Int,
    soundSrc: String?, parentFolderId: Int?
): Pair<GenerateLanguageTaskAndSliderResponse?, String?> {
    var advancedResponse = AdvancedResponse("", 0)
    var requestAttempts = 0
    while (advancedResponse.code != 200 && requestAttempts < 3) {
        advancedResponse = try {
            val response = jsonClient.post("/api/v2/auto-generation/language-slider/generate-task-slider") {
                contentType(ContentType.Application.Json)
                bearerAuth(getToken())
                setBody(FormDataContent(Parameters.build {
                    if (contentType == EContentType.PRE_MADE_CONTENT.value || textOrTopic.length > maxTopicLength) {
                        append("input_text", textOrTopic)
                    } else {
                        append("topic", textOrTopic)
                    }

                    if (contentType == EContentType.STORYTELLING.value) {
                        append("input_text", vocabulary)
                    } else {
                        append("target_vocabulary", vocabulary)
                    }
                    append("content_type", contentType)
                    languageToLearn?.let { append("language_to_learn", it) }
                    append("interface_language", interfaceLanguage)
                    append("language_level", languageLevel)
                    name?.let { append("name", it) }
                    append("number_of_slides_to_generate", numberOfSlidesToGenerate.toString())
                    soundSrc?.let { append("sound_src", soundSrc) }
                    parentFolderId?.let { append("parent_folder_id", it.toString()) }
                }))
            }

            AdvancedResponse(response.bodyAsText(), response.status.value)
        } catch (e: ResponseException) {
            AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
        }
        requestAttempts += 1
    }

    if (advancedResponse.code != 200) {
        return Pair(null, advancedResponse.content)
    }

    return Pair(Json.decodeFromString(advancedResponse.content), null)
}

suspend fun generateTextFromTopicRequest(
    topic: String, languageToLearn: String, languageLevel: String
): ApiResponse<String> {
    return try {
        val response = jsonClient.get(
            "/api/v2/auto-generation/language-slider/generate-text-from-topic/$topic/$languageToLearn/$languageLevel"
        ) {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return ApiResponse(null, response.status.value)
        }

        ApiResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}

suspend fun getFolder(id: Int): ApiResponse<SliderAutoGenerationFolder> {
    return try {
        val response = jsonClient.get("api/v2/auto-generation/folder/$id") {
            bearerAuth(getToken())
        }

        ApiResponse(Json.decodeFromString(response.bodyAsText()), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}
