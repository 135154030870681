package widgets.Exercises.ui.components.StatisticsModal

import Modal
import antd.Button
import antd.Spin
import app.useAppDispatch
import csstype.AlignItems
import csstype.Display
import csstype.JustifyContent
import csstype.px
import emotion.react.css
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.ETimePeriod
import online.interactiver.common.statistics.SlideStatisticsResponse
import pages.languageAuto.SetHiddenIframeIsShown
import pages.languageAuto.UpdateCurrentLanguageAutoRequest
import pages.languageAuto.ui.ERoute
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.router.useNavigate
import shared.components.selectWithLabel.SelectWithLabel
import widgets.Exercises.getLanguageAutoRequest
import widgets.Exercises.ui.components.StatisticsModal.components.SlidesChart.SlidesChart
import widgets.LanguageAutoContent.ui.generateButton

external interface StatisticsModalProps : Props {
    var isOpen: Boolean
    var onClose: () -> Unit
    var requestId: Int?
    var update: Boolean?
}

val StatisticsModal = FC<StatisticsModalProps> { props ->

    val requestId = props.requestId
    val (usersData, setUsersData) = useState(listOf<SlideStatisticsResponse>())
    val (timePeriod, setTimePeriod) = useState(ETimePeriod.LAST_MONTH)
    val navigate = useNavigate()

    val (loading, setLoading) = useState(false)

    val dispatch = useAppDispatch()

    useEffect(props.update, requestId, timePeriod) {
        if (requestId == null || props.update == false) {
            return@useEffect
        }

        setLoading(true)
        GlobalScope.launch {
            val response = getStatisticsRequest(requestId, timePeriod.value) ?: return@launch
            setUsersData(response)
            setLoading(false)
        }
    }

    Modal {
        open = props.isOpen
        onCancel = { props.onClose() }
        footer = null
        width = 800
        div {
            css(modal)
            if (usersData.isEmpty() && !loading) {
                p {
                    css(emptyStatistics)
                    +"There are no statistics for this slider"
                }
            } else {
                div {
                    css(select)
                    SelectWithLabel {
                        label = "Time period"
                        value = timePeriod.value
                        onChange = { newTimePeriod ->
                            ETimePeriod.fromString(newTimePeriod)?.let { setTimePeriod(it) }
                        }
                        options = ETimePeriod.entries.map { it.value }
                    }
                }

                if (loading) {
                    div {
                        css {
                            display = Display.flex
                            alignItems = AlignItems.center
                            justifyContent = JustifyContent.center
                            height = 322.px
                            width = 450.px
                        }
                        Spin {
                            size = "large"
                        }
                    }
                } else {
                    SlidesChart {
                        this.usersData = usersData
                    }
                }
            }
            div {
                css(buttonsContainer)
                Button {
                    css(generateButton)
                    onClick = {
                        navigate("${ERoute.SLIDER_STATISTICS.path}/${requestId}")
                    }
                    +"Details"
                }
                Button {
                    css(editSliderButton)
                    onClick = f@{
                        props.onClose()
                        if (requestId == null) {
                            return@f
                        }

                        navigate("${ERoute.EXERCISE.path}/$requestId")
                    }
                    +"Edit slider"
                }
            }
        }
    }
}
