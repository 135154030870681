package pages.constructor.ui.components.editors.stretchEdgesOfElementsEditor

import antd.Button
import antd.Tooltip
import app.useAppDispatch
import app.useAppSelector
import builders.enums.EAlign
import emotion.react.css
import entities.interactivePicture.elements.StretchEdgesOfElements
import entities.interactivePicture.selectElementsUnderSelectionRectangle
import pages.constructor.ui.components.editors.alignElementsPicker.*
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.key
import shared.canvas.interfaces.getElementBounds
import shared.components.Icon
import utils.structures.Position

val StretchEdgesOfElementsEditor = FC<Props> {
    val dispatch = useAppDispatch()
    val allElementOfSelectedGroup = useAppSelector(selectElementsUnderSelectionRectangle)
    var minLeft: Position? = null
    var minTop: Position? = null
    var maxRight: Position? = null
    var maxBottom: Position? = null
    if (allElementOfSelectedGroup.isNotEmpty()) {
        allElementOfSelectedGroup.forEach {
            minLeft = if (minLeft == null || it.getElementBounds().x < (minLeft?.x ?: 0)) it.getElementBounds() else minLeft
            minTop = if (minTop == null || it.getElementBounds().y < (minTop?.y ?: 0)) it.getElementBounds() else minTop
            maxRight = if (maxRight == null ||
                it.getElementBounds().x.plus(it.getElementBounds().width) > (maxRight?.x?.plus(maxRight?.width ?: 0) ?: 0)) {
                it.getElementBounds()
            } else {
                maxRight
            }
            maxBottom = if (maxBottom == null ||
                it.getElementBounds().y.plus(it.getElementBounds().height) > (maxBottom?.y?.plus(maxBottom?.height ?: 0) ?: 0)) {
                it.getElementBounds()
            } else  {
                maxBottom
            }
        }
    }
    class StretchEdgesMetadata(
        val label: String,
        val iconSrc: String,
        val position: Position?,
        val hint: String
    )
    div {
        css(containerAlignElementsForHeader)
        div {
            css(alignElementsHeader)
            +"Stretch edges of elements"
        }
    }
    div {
        css(buttonContainerColumn)
        arrayOf(
            StretchEdgesMetadata(EAlign.Horizontal.LEFT.label, "ic_elements_align_left_18x18.svg", minLeft, "Increase width towards the left"),
            StretchEdgesMetadata(EAlign.Vertical.TOP.label, "ic_elements_align_top_18x18.svg", minTop, "Increase height towards the top"),
            StretchEdgesMetadata(EAlign.Horizontal.RIGHT.label, "ic_elements_align_right_18x18.svg", maxRight, "Increase width towards the right"),
            StretchEdgesMetadata(EAlign.Vertical.BOTTOM.label, "ic_elements_align_bottom_18x18.svg", maxBottom, "Increase height towards the bottom"),
        ).map {
            Tooltip {
                title = it.hint
                placement = "topLeft"
                Button {
                    css(buttonForAlignElements)
                    key = it.iconSrc
                    Icon {
                        src = it.iconSrc
                    }
                    div {
                        css(iconText)
                        +it.label
                    }
                    onClick = {
                        it.position?.let { position ->
                            dispatch(StretchEdgesOfElements(allElementOfSelectedGroup.map { it.identifier.id!! }.toTypedArray(), position, it.label))
                        }
                    }
                }
            }
        }
    }
}