package online.interactiver.common.autogeneration.taskLineContentFabric

import online.interactiver.common.autogeneration.taskLineContent.TextToLearnContent

object TextToLearnContentFabric: TaskLineContentFabric<TextToLearnContent> {
    override fun canBeBuiltFrom(taskLineContent: String, isOpenAiFormat: Boolean): Boolean {
        return taskLineContent.split(";").firstOrNull()?.isNotBlank() == true
    }

    override fun fromRawTaskLine(taskLineContent: String, isOpenAiFormat: Boolean): TextToLearnContent {
        val textToLearn = taskLineContent.split(";").first()
        return TextToLearnContent(textToLearn)
    }
}