package pages.studentDuoRoadMap.ui.StudentDuoRoadMap

import app.useAppDispatch
import kotlinx.browser.localStorage
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import online.interactiver.common.duo.DuoCourse
import online.interactiver.common.duo.EExerciseState
import pages.studentDuo.*
import pages.studentDuoRoadMap.getDuoCourseRequest
import react.redux.useSelector
import react.router.useNavigate
import react.router.useParams
import react.useEffect

val useDuoCourse = { setCourse: (DuoCourse) -> Unit ->
    val pathParams = useParams()
    val navigate = useNavigate()
    val dispatch = useAppDispatch()
    val courses = useSelector(selectStudentDuoCourses)
    val coursesWereFetched = useSelector(selectStudentDuoCoursesWereFetched)
    val userProfile = useSelector(selectStudentDuoUserProfile)

    val folderId = pathParams["folder_id"]?.toIntOrNull()

    useEffect(folderId, coursesWereFetched, userProfile?.hashCode()) {
        if (!coursesWereFetched || userProfile == null) {
            return@useEffect
        }

        if (folderId == null) {
            if (courses.isNotEmpty()) {
                navigate("/duo/folder/${courses.first().id}")
                return@useEffect
            }

            if (userProfile.nativeLanguage == null) {
                navigate("/duo/content/native-language")
                return@useEffect
            }

            navigate("/duo/content")
            return@useEffect
        }

        GlobalScope.launch {
            val courseResponse = getDuoCourseRequest(folderId)
            if (courseResponse == null || courseResponse.states.isEmpty()) {
                navigate("/duo/content")
                return@launch
            }

            val course = courses.singleOrNull { it.id == folderId }
            if (course == null) {
                val coursesResponse = getDuoCoursesRequest()
                if (coursesResponse.isNotEmpty()) {
                    dispatch(SetDuoCourses(coursesResponse))
                }
            }

            setCourse(courseResponse)
        }
    }
}
