package widgets.Exercises.ui.components.UploadManuallyButton.UploadManuallyModal

import Modal
import antd.Button
import antd.Input
import app.useAppDispatch
import csstype.*
import emotion.react.css
import entities.modalLoader.*
import io.ktor.client.statement.*
import jsonClient
import kotlinx.serialization.json.Json
import online.interactiver.common.enums.EUploadExerciseError
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.asList
import org.w3c.files.File
import react.*
import react.dom.html.InputType
import react.dom.html.ReactHTML.b
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.i
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ol
import react.router.dom.useSearchParams
import shared.components.Icon
import utils.types.impl

external interface UploadManuallyModalProps : Props {
    var open: Boolean?
    var onClose: (() -> Unit)?
    var onUploaded: (() -> Unit)?
}

data class ManuallyUploadedExercise (
    val file: File,
    var name: String = file.name
) {
    override fun equals(other: Any?): Boolean {
        return hashCode() == other.hashCode()
    }

    override fun hashCode(): Int {
        return file.name.hashCode()
    }
}

val UploadManuallyModal = FC<UploadManuallyModalProps> { props ->
    val (filesUploaded, setFilesUploaded) = useState(listOf<ManuallyUploadedExercise>())
    val (searchParams, setSearchParams) = useSearchParams()
    val parentFolderId = useMemo(searchParams) {
        searchParams.get("parent_folder_id")?.toIntOrNull()
    }

    val hiddenInputRef = useRef<HTMLInputElement>()

    val dispatch = useAppDispatch()

    input {
        css(inputHidden)
        ref = hiddenInputRef
        type = InputType.file
        accept = ".html"
        multiple = true
        onChange = {
            it.target.files?.asList()?.let { files ->
                val newFilesUploaded = filesUploaded.toMutableSet()
                newFilesUploaded.addAll(files.map { file ->
                    ManuallyUploadedExercise(file)
                })
                setFilesUploaded(newFilesUploaded.toList())
            }
            it.target.value = ""
        }
    }

    Modal {
        width = 800
        open = props.open
        onCancel = {
            props.onClose?.invoke()
            setFilesUploaded(listOf())
        }
        onOk = {
            props.onClose?.invoke()
            setFilesUploaded(listOf())

            val successfullyLoadedFiles = mutableListOf<String>()
            val failedToLoadFiles = mutableListOf<FailedToLoadFile>()

            var currentExerciseIndex = 0
            val mainLoaderLabel = "Uploading your exercises"
            val modalLoaderText = "$mainLoaderLabel: ${currentExerciseIndex + 1}/${filesUploaded.size}"
            dispatch(StartModalLoading(modalLoaderText))
            filesUploaded.forEach { exercise ->
                uploadManuallyGeneratedExercise(exercise, parentFolderId) {
                    if (it.status.value in 200..299) {
                        successfullyLoadedFiles.add(exercise.file.name)
                    } else {
                        failedToLoadFiles.add(
                            FailedToLoadFile(exercise.file, Json.decodeFromString(it.bodyAsText()))
                        )
                    }
                    currentExerciseIndex += 1
                    if (currentExerciseIndex < filesUploaded.size - 1) {
                        val newModalLoaderText = "$mainLoaderLabel: ${currentExerciseIndex + 1}/${filesUploaded.size}"
                        dispatch(ChangeModalLoadingText(newModalLoaderText))
                    } else {
                        val fileWordInRightForm = if (filesUploaded.size > 1) "files" else "file"
                        dispatch(
                            ShowSuccessfulLoadingImpl("Successfully uploaded ${successfullyLoadedFiles.size} out of ${filesUploaded.size} $fileWordInRightForm!",
                                sub = getFailedFilesListForSuccessLoaderAsReactNode(failedToLoadFiles, props.onUploaded)
                            )
                        )
                        props.onUploaded?.invoke()
                    }
                }
            }
        }
        div {
            css(body)
            div {
                css(header)
                +"Upload manually generated exercises"
            }
            div {
                css(secondary)
                +("Here you can upload manually generated exercises and set their name in the input field. " +
                        "Be aware, that some features, such as reordering slides, will not be available.")
            }
            if (filesUploaded.isNotEmpty()) {
                div uploadedFilesGrid@{
                    css(uploadedFilesGrid)
                    filesUploaded.mapIndexed { index, exercise ->
                        div {
                            css(fileLabelContainer)
                            Icon {
                                src = "ic_file_arrow_up_24x24.svg"
                            }
                            div {
                                css(filename)
                                +exercise.file.name
                            }
                        }
                        div {
                            css(inputExerciseNameContainer)
                            Input {
                                placeholder = "Exercise name"
                                value = exercise.name
                                onChange = {
                                    val newFilesUploaded = filesUploaded.toMutableList()
                                    newFilesUploaded[index].name = it.target.value
                                    setFilesUploaded(newFilesUploaded)
                                }
                            }
                            Button {
                                css(deleteExerciseButton)
                                Icon {
                                    src = "ic_delete_sound_20x20.svg"
                                }
                                onClick = {
                                    val newFilesUploaded = filesUploaded.toMutableList()
                                    newFilesUploaded.removeAt(index)
                                    setFilesUploaded(newFilesUploaded)
                                }
                            }
                        }
                    }
                }
            }
            Button {
                css(uploadButton)
                +"Upload exercises as .html file"
                onClick = {
                    hiddenInputRef.current?.click()
                }
            }
        }
    }
}

data class FailedToLoadFile(
    val file: File,
    val reason: EUploadExerciseError
)

external interface FailedFilesListForSuccessLoaderProps: Props {
    var files: List<FailedToLoadFile>?
    var onUploaded: (() -> Unit)?
}

val FailedFilesListForSuccessLoader: FC<FailedFilesListForSuccessLoaderProps> = FC<FailedFilesListForSuccessLoaderProps> { props ->
    val files = useMemo(props.files) {
        props.files ?: emptyList()
    }

    val duplicates = useMemo(files) {
        files.filter { it.reason == EUploadExerciseError.EXERCISE_WAS_ALREADY_UPLOADED }
    }

    val dispatch = useAppDispatch()

    if (files.isEmpty()) {
        return@FC
    }

    div {
        +"Failed to load these files:"
        css {
            width = 100.pct
        }
        ol {
            css {
                paddingInlineStart = 12.px
                marginBlockStart = (0.3).em
                textAlign = TextAlign.left
            }
            files.map { file ->
                li {
                    b { +file.file.name }
                    +": "
                    br {}
                    i {
                        css {
                            paddingLeft = 6.px
                        }
                        +file.reason.toString()
                    }
                }
            }
        }
        if (duplicates.isNotEmpty()) {
            Button {
                css {
                    color = Color("white")
                    backgroundColor = Color("#597EF7")
                    borderRadius = 6.px
                    border = None.none
                    fontWeight = FontWeight.bold
                    lineHeight = 20.8.px
                    fontSize = 16.px
                    fontFamily = string("\"Inter\", system-ui")
                    width = 100.pct
                    height = 44.px
                    display = Display.flex
                    alignItems = AlignItems.center
                    justifyContent = JustifyContent.center
                    gap = 13.px
                    hover {
                        backgroundColor = Color("#3E62D9")
                        color = important(Color("white"))
                    }
                }
                +"Replace all duplicates"
                onClick = {
                    val successfullyReplacedFiles = mutableListOf<String>()
                    val failedToReplaceFiles = mutableListOf<FailedToLoadFile>()

                    var currentExerciseIndex = 0
                    val mainLoaderLabel = "Replacing duplicates"
                    val modalLoaderText = "$mainLoaderLabel: ${currentExerciseIndex + 1}/${duplicates.size}"
                    dispatch(StartModalLoading(modalLoaderText))
                    duplicates.map { it.file }.forEach { file ->
                        replaceManuallyGeneratedExercise(file) {
                            if (it.status.value in 200..299) {
                                successfullyReplacedFiles.add(file.name)
                            } else {
                                failedToReplaceFiles.add(
                                    FailedToLoadFile(file, Json.decodeFromString(it.bodyAsText()))
                                )
                            }
                            currentExerciseIndex += 1
                            if (currentExerciseIndex < duplicates.size - 1) {
                                val newModalLoaderText = "$mainLoaderLabel: ${currentExerciseIndex + 1}/${duplicates.size}"
                                dispatch(ChangeModalLoadingText(newModalLoaderText))
                            } else {
                                val duplicateWordInRightForm = if (duplicates.size > 1) "duplicates" else "duplicate"
                                dispatch(
                                    ShowSuccessfulLoadingImpl("Successfully replaced ${successfullyReplacedFiles.size} out of ${duplicates.size} $duplicateWordInRightForm!",
                                        sub = getFailedFilesListForSuccessLoaderAsReactNode(failedToReplaceFiles, props.onUploaded)
                                    )
                                )
                                props.onUploaded?.invoke()
                            }
                        }
                    }
                }
            }
        }
    }
}

fun getFailedFilesListForSuccessLoaderAsReactNode(
    files: List<FailedToLoadFile>? = null,
    onUploaded: (() -> Unit)? = null
): ReactNode = createElement(
    FailedFilesListForSuccessLoader, impl {
        this.files = files
        this.onUploaded = onUploaded
    }
)
