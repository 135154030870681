package widgets.LanguageAutoSlider.ui.components.LanguageAutoSliderHeader

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    width = 100.pct
    display = Display.flex
    flexDirection = FlexDirection.column
}

val headerContainer: CssStyle = {
    width = 100.pct
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.center
}

val featuresContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 8.px
}

val pro: CssStyle = {
    alignSelf = AlignSelf.flexEnd
    padding = Padding(2.px, 8.px)
    backgroundColor = Color("#C9D9F6")
    color = Color("#4E89E6")
    fontSize = 10.px
    lineHeight = 13.px
    fontWeight = FontWeight.normal
    margin = 0.px
    borderRadius = 8.px
}

val advancedSettingsButton: CssStyle = {
    backgroundColor = Color("#F2F4F7")
    border = None.none
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    borderRadius = 44.px
    gap = 4.px
    padding = Padding(6.px, 16.px)
    color = Color("#98A2B3")
    fontSize = 16.px
    lineHeight = 20.8.px
    fontWeight = integer(600)
}

val view: CssStyle = {
    color = Color("#5D6676")
    fontWeight = integer(500)
    fontSize = 14.px
    lineHeight = 18.9.px
}

val toggle: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 3.px
    padding = 4.px
    borderRadius = 8.px
    backgroundColor = Color("#F2F4F7")
}

val toggleButton: (Boolean) -> CssStyle = { isActive ->
    {
        border = None.none
        boxShadow = None.none
        color = Color(if (isActive) "#FFFFFF" else "#5D6676")
        backgroundColor = Color(if (isActive) "#5D6676" else "#F2F4F7")
        fontWeight = integer(500)
        fontSize = 14.px
        lineHeight = 18.9.px
    }
}
