package pages.studentDuoRoadMap.ui.ExerciseButton

import antd.Button
import antd.Tooltip
import csstype.pct
import csstype.px
import emotion.react.css
import online.interactiver.common.duo.DuoExercise
import online.interactiver.common.duo.EExerciseState
import org.w3c.dom.HTMLAnchorElement
import pages.constructor.ui.components.elements.bgBlueFilter
import pages.constructor.ui.components.elements.blueFilter
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.router.dom.Link
import react.useEffectOnce
import react.useRef
import shared.components.Icon
import utils.types.extend
import utils.types.impl
import utils.types.jsObject

external interface ExerciseButtonProps : Props {
    var number: Int
    var exercise: DuoExercise
    var scroll: Boolean?
}

fun Int.toButtonTitle (isPassed: Boolean) = when (this) {
    4 -> if (isPassed) "⭐" else "★"
    7 -> if (isPassed) "\uD83C\uDF1F" else "★"
    8 -> if (isPassed) "\uD83C\uDFC6" else "★"
    9 -> if (isPassed) "\uD83C\uDF89" else "★"
    else -> this.toString()
}

val ExerciseButton = FC<ExerciseButtonProps> { props ->
    val marginLeft = getMarginLeft(props.number).pct
    val currentExerciseRef = useRef<HTMLAnchorElement>()

    useEffectOnce {
        if (props.scroll != true || currentExerciseRef == undefined) return@useEffectOnce
        currentExerciseRef.current?.scrollIntoView(jsObject { behavior = "smooth" })
    }

    val uuid = props.exercise.uuid

    if (uuid == "null") {
        Tooltip {
            title = "Start with above exercise"
            align = impl {
                offset = arrayOf(0, 5)
            }
            color = "#000"
            arrowPointAtCenter = true
            p {
                css(link(marginLeft).extend(disabled))
                +props.number.toButtonTitle(false)
            }
        }
        return@FC
    }

    when (props.exercise.state) {
        EExerciseState.NOT_SOLVED.value -> {
            Link {
                css(link(marginLeft).extend(unsolved))
                to = uuid
                +props.number.toButtonTitle(false)
            }
        }
        EExerciseState.SOLVED.value -> {
            Link {
                ref = currentExerciseRef
                css(link(marginLeft).extend(solved))
                to = uuid
                +props.number.toButtonTitle(true)
            }
        }
        EExerciseState.CURRENT.value -> {
            Link {
                ref = currentExerciseRef
                to = uuid
                css(link(marginLeft).extend(current))
                div {
                    css(currentIcon)
                    Icon {
                        css(bgBlueFilter)
                        src = "ic_right_short_arrow_28x28.svg"
                    }
                }
                +props.number.toButtonTitle(false)
            }
        }
    }
}

val getMarginLeft = { number: Int ->
    val numberToGetMarginFrom = if (number > 18) (number - 1) % 6 + 12 else number
    when (numberToGetMarginFrom) {
        1 -> 26.7
        2 -> 35.8
        3 -> 47.0
        4 -> 41.5
        5 -> 56.7
        6 -> 46.6
        7 -> 54.5
        8 -> 69.2
        9 -> 50.8
        10 -> 59.3
        11 -> 54.5
        12 -> 70.0
        13 -> 60.1
        14 -> 45.5
        15 -> 59.7
        16 -> 51.8
        17 -> 66.6
        18 -> 72.5
        else -> 50.0
    }
}
