package features.ShareButton.ui

import antd.Button
import emotion.react.css
import react.FC
import react.Props
import react.useState
import shared.components.Icon
import widgets.LanguageAutoSlider.ui.components.ShareModal.ShareModal

external interface ShareButtonProps : Props {
    var link: String
    var embedCode: String
    var slider: String
    var requestId: Int
    var isUserOwns: Boolean
}

val ShareButton = FC<ShareButtonProps> { props ->
    val (shareModalIsOpened, setShareModalIsOpened) = useState(false)
    ShareModal {
        isOpen = shareModalIsOpened
        onClose = { setShareModalIsOpened(false) }
        this.requestId = props.requestId
        openShareModal = { setShareModalIsOpened(true) }
        this.link = props.link
        this.embedCode = props.embedCode
        this.slider = props.slider
        isUserOwns = props.isUserOwns
    }

    Button {
        css(shareButton)
        onClick = { setShareModalIsOpened(true) }
        Icon {
            src = "ic_share_32x32.svg"
        }
        +"Share"
    }
}