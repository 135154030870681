package entities.dictionary.ui.syncButton

import csstype.*
import utils.types.CssStyle

val buttonStyle = { isSynced: Boolean ->
    val button: CssStyle = {
        display = Display.flex
        alignItems = AlignItems.center
        border = None.none
        width = 63.px
        height = 24.px
        borderRadius = 5.px
        textAlign = TextAlign.center
        fontSize = 12.px
        color = if (isSynced) Color("#2F54EB") else Color("#5D6676")
        marginLeft = 85.px
        backgroundColor = if (isSynced) Color("#F4F7FF") else Color("#F2F4F7")
        gap = 4.px
        justifyContent = JustifyContent.center
        padding = 0.px
        hover {
            color = important(if (isSynced) Color("#2F54EB") else Color("#5D6676"))
        }
    }
    button
}

val modalContainer: CssStyle = {
    width = 560.px
    height = 300.px
    padding = 0.px
}

val header: CssStyle = {
    fontFamily = string("\"Arial\", system-ui")
    fontSize = 18.px
    fontWeight = FontWeight.bold
    lineHeight = 23.4.px
    color = Color("#282F3E")
}

val explanation: CssStyle = {
    fontFamily = string("\"Arial\", system-ui")
    fontSize = 12.px
    fontWeight = FontWeight.normal
    lineHeight = 15.6.px
    color = Color("#282F3E")
    marginTop = 15.px
}
