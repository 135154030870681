package widgets.UserProfile.ui

import app.useAppDispatch
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.autogeneration.LanguageAutoState
import online.interactiver.common.user.UserProfile
import pages.languageAuto.getUserProfileRequest
import react.VFC
import react.router.dom.useSearchParams
import react.router.useParams
import react.useEffect
import react.useState
import widgets.Exercises.getLanguageAutoRequest
import widgets.Exercises.ui.components.PublicExerciseView.PublicExerciseView

val UserProfileToView = VFC {
    val params = useParams()
    val (searchParams, setSearchParams) = useSearchParams()
    val userId = params["id"]?.toIntOrNull()
    val (userProfile, setUserProfile) = useState<UserProfile>()
    val requestId = searchParams.get("request-id")?.toIntOrNull()

    val dispatch = useAppDispatch()

    val (selectedRequest, setSelectedRequest) = useState<LanguageAutoState?>(null)

    useEffect(userId) {
        if (userId == null) {
            return@useEffect
        }

        GlobalScope.launch {
            setUserProfile(getUserProfileRequest(userId).data)
        }
    }

    useEffect(requestId) {
        if (requestId == null || selectedRequest != null) {
            return@useEffect
        }

        dispatch(StartModalLoading("Getting exercise..."))
        GlobalScope.launch {
            val request = getLanguageAutoRequest(requestId, true)

            dispatch(EndModalLoading())
            setSelectedRequest(request)
        }
    }

    PublicExerciseView {
        isOpen = requestId != null
        close = {
            setSelectedRequest(null)
            searchParams.delete("request-id")
            setSearchParams(searchParams)
        }
        request = selectedRequest
        this.isUserOwns = false
        this.userName = userProfile?.userName
    }

    UserProfile {
        userViewsHisProfile = false
        this.userProfile = userProfile
        onExerciseClick = {
            dispatch(StartModalLoading("Getting exercise..."))
            GlobalScope.launch {
                val request = getLanguageAutoRequest(it.requestId, true)

                dispatch(EndModalLoading())
                searchParams.set("request-id", it.requestId.toString())
                setSearchParams(searchParams)
                setSelectedRequest(request)
            }
        }
    }
}