package pages.constructor.ui.components.copyrightLabel

import csstype.*
import utils.types.CssStyle

val label: CssStyle = {
    position = Position.absolute
    height = 24.px
    width = 122.px
    bottom = 14.px
    left = 89.px
    gap = 7.px
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.center
    alignItems = AlignItems.center
}

val icon: CssStyle = {
    width = 10.px
    height = 10.px
}

val text: CssStyle = {
    fontFamily = string("Arial")
    fontSize = 10.px
    fontWeight = integer(400)
    lineHeight = 13.px
    letterSpacing = 0.em
}