package widgets.UserProfile.ui.components.InputWithUserData

import antd.Button
import antd.TextArea
import emotion.react.css
import kotlinx.browser.document
import kotlinx.js.jso
import linkify.Linkify
import org.w3c.dom.HTMLTextAreaElement
import react.*
import react.dom.events.KeyboardEvent
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p

external interface TextAreaWithUserDataProps : Props {
    var label: String
    var value: String
    var onChange: (String) -> Unit
    var editable: Boolean
}

val TextAreaWithUserData = FC<TextAreaWithUserDataProps> { props ->
    val (value, setValue) = useState(props.value)
    val (inputFocused, setInputFocused) = useState(false)
    val (buttonClicked, setButtonClicked) = useState(false)

    val id = "${props.label}_user_input"

    useEffect(props.value) {
        setValue(props.value)
    }

    useEffect(inputFocused) {
        if (!inputFocused) {
            return@useEffect
        }

        (document.getElementById(id) as HTMLTextAreaElement?)?.focus()
    }

    fun updateValue() {
        if (value == props.value) {
            return
        }

        props.onChange(value)
    }

    div {
        css(container)
        p {
            css(label)
            +props.label
        }
        if (inputFocused) {
            TextArea {
                css(inputCss)
                this.value = value
                this.id = id
                onBlur = f@{
                    if (buttonClicked) {
                        return@f
                    }
                    setInputFocused(false)
                    updateValue()
                }
                onPressEnter = f@{ evt: KeyboardEvent<HTMLTextAreaElement> ->
                    if (evt.shiftKey) {
                        return@f
                    }

                    evt.currentTarget.blur()
                }
                onChange = {
                    setValue(it.currentTarget.value)
                }
                dir = "auto"
            }
        } else {
            Linkify {
                options = jso {
                    target = "_blank"
                }
                p {
                    css(inputCss)
                    +value
                }
            }
        }
        if (props.editable) {
            Button {
                css(changeButton)
                onMouseDown = { setButtonClicked(true) }
                onMouseUp = { setButtonClicked(false) }
                onClick = f@{
                    setInputFocused { !it }
                    if (!inputFocused) {
                        return@f
                    }

                    updateValue()
                }
                +"${if (inputFocused) "Save" else "Change"} ${props.label.lowercase()}"
            }
        }
    }
}