package pages.constructor.ui.components.advancedSettings.controlButtonsSettings

import antd.TextArea
import online.interactiver.common.enums.ELanguage
import emotion.react.css
import online.interactiver.common.interactivepicture.ControlButtonsSettings
import pages.constructor.ui.components.advancedSettings.settingHeader
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useEffectOnce
import shared.components.checkboxWithLabel.CheckboxWithLabel
import utils.localization.Library
import utils.localization.Literal

external interface ControlButtonsSettingsEditorProps: Props {
    var settings: ControlButtonsSettings
    var onChange: (ControlButtonsSettings) -> Unit
    var taskLanguage: ELanguage
    var instantCheck: Boolean
}

val ControlButtonsSettingsEditor = FC<ControlButtonsSettingsEditorProps> { props ->
    class InputMetadata(
        val label: String,
        val value: String,
        val checked: Boolean,
        val changeInputAction: (String) -> Unit,
        val changeCheckboxAction: (Boolean) -> Unit,
        val inputDisabled: Boolean,
        val checkboxDisabled: Boolean,
    )

    useEffectOnce {
        props.onChange(props.settings.copy(
            checkButtonText = Library(ELanguage.ENGLISH).get(Literal.CHECK_BUTTON_DEFAULT_TEXT),
            restartButtonText = Library(ELanguage.ENGLISH).get(Literal.RESTART_BUTTON_DEFAULT_TEXT),
        ))
    }

    div {
        css(settingHeader)
        +"Custom button text"
    }

    div {
        arrayOf(
            InputMetadata("Enable Check button", props.settings.checkButtonText, props.settings.enableCheckButton,
                { checkButtonText ->
                    props.onChange(props.settings.copy(checkButtonText = checkButtonText))
                },
                { enableCheckButton ->
                    props.onChange(props.settings.copy(enableCheckButton = enableCheckButton))
                },
                props.instantCheck || !props.settings.enableCheckButton,
                props.instantCheck),
            InputMetadata("Enable Restart button", props.settings.restartButtonText, props.settings.enableRestartButton,
                { restartButtonText ->
                    props.onChange(props.settings.copy(restartButtonText = restartButtonText))
                },
                { enableRestartButton ->
                    props.onChange(props.settings.copy(enableRestartButton = enableRestartButton))
                },
                !props.settings.enableRestartButton,
                false),
        ).map { input ->
            css(container)
            CheckboxWithLabel {
                checked = input.checked
                label = input.label
                onChange = input.changeCheckboxAction
                disabled = input.checkboxDisabled
            }
            TextArea {
                css(textArea)
                value = input.value
                onChange = {
                    input.changeInputAction(it.target.value)
                }
                disabled = input.inputDisabled
            }
        }
    }
}
