package widgets.Exercises.ui.components.ManageAccessModal

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 20.px
}

val newUsersContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 15.px
}

val buttonsContainer: CssStyle = {
    display = Display.flex
    gap = 15.px
    width = 100.pct
    justifyContent = JustifyContent.spaceBetween
}
