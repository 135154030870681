package pages.wordsProgress.ui.WordProgress

import csstype.*
import utils.types.CssStyle

val wordProgress: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 10.px
}

val wordButton: CssStyle = {
    height = 51.px
    minWidth = 80.pct
    fontSize = 16.px
}

val markButton: CssStyle = {
    border = None.none
    padding = 0.px
}
