package widgets.CourseGenerationType.ui.GenerateMaterialsBasedOnTopicButton

import antd.Button
import app.useAppDispatch
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import pages.course.SetCourse
import pages.course.selectCourseState
import react.FC
import react.Props
import react.redux.useSelector
import shared.components.Icon
import widgets.CourseGenerationType.generateCourseMaterialsBasedOnTextOrTopicRequest
import widgets.LanguageAutoContent.ui.generateButton

val GenerateMaterialsBasedOnTopicButton = FC<Props> {
    val courseState = useSelector(selectCourseState)

    val dispatch = useAppDispatch()

    Button {
        css(generateButton)
        disabled = courseState.text.isBlank()
        onClick = {
            dispatch(StartModalLoading("Generating materials..."))
            GlobalScope.launch {
                val response = generateCourseMaterialsBasedOnTextOrTopicRequest(courseState)

                dispatch(EndModalLoading())
                if (response.data == null) {
                    dispatch(OpenErrorModal("Error generating materials for course"))
                    return@launch
                }

                dispatch(SetCourse(response.data))
            }
        }
        Icon {
            src = "ic_magic_wand_24x24.svg"
        }
        +"Do the magic"
    }
}
