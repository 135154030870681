package entities.fonts

import app.StoreState
import org.khronos.webgl.ArrayBuffer
import redux.RAction

open class FontsAction : RAction
data class SetFonts(val fonts: Map<String, ArrayBuffer>) : FontsAction()

val FontsReducer = { state: Map<String, ArrayBuffer>, action: FontsAction ->
    when (action) {
        is SetFonts -> action.fonts
        else -> state
    }
}

val selectFonts = { store: StoreState ->
    store.fonts
}