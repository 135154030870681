package pages.constructor.ui.components.header.styleSettings.opacitySlider

import antd.Slider
import antd.TypedInputNumber
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useRef
import utils.types.impl

external interface OpacitySliderProps : Props {
    var value: Double?
    var onChange: ((Double) -> Unit)?
    var onAfterChange: ((Double) -> Unit)?
}

val OpacitySlider = FC<OpacitySliderProps> { props ->
    val value = ((props.value ?: 1.0) * 100).toInt()

    val oldValue = useRef(value)

    div {
        css(container)
        div {
            css(headerContainer)
            div {
                css(header)
                +"Opacity"
            }
            TypedInputNumber {
                css(inputNumber)
                min = 1
                max = 100
                controls = false
                precision = 0
                formatter = { value, _ -> "$value%" }
                parser = { str ->
                    val newValue = str.removeSuffix("%").toIntOrNull()
                    newValue ?: value
                }
                this.value = value
                if (value < 0){this.value = 1}
                onChange = {
                    if (value <= 0){this.value = 1}
                    props.onChange?.invoke(it.toDouble() / 100.0)
                }
                onBlur = {
                    val num = it.target.value.removeSuffix("%").toIntOrNull() ?: value
                    props.onChange?.invoke(oldValue.current?.div(100.0) ?: 1.0)
                    props.onAfterChange?.invoke(num.toDouble() / 100.0)
                    oldValue.current = num
                }
            }
        }
        Slider {
            min = 1
            max = 100
            step = 1
            this.value = value
            tooltip = impl {
                this?.formatter = null
            }
            onChange = {
                props.onChange?.invoke(it.toDouble() / 100.0)
            }
            onAfterChange = {
                props.onChange?.invoke(oldValue.current?.div(100.0) ?: 1.0)
                props.onAfterChange?.invoke(it.toDouble() / 100.0)
                oldValue.current = it.toInt()
            }
        }
    }
}
