package entities.advancedElementSettings

import app.StoreState
import builders.enums.EElementType
import online.interactiver.common.utils.Copyable
import redux.RAction

data class AdvancedElementSettingsState (
    val isOpen: Boolean = false,
) : Copyable<AdvancedElementSettingsState> {
    override fun clone(): AdvancedElementSettingsState = copy()
}

open class AdvancedElementSettingsModalAction : RAction

open class OpenAdvancedElementSettings : AdvancedElementSettingsModalAction()
open class CloseAdvancedElementSettings : AdvancedElementSettingsModalAction()

val AdvancedElementSettingsReducer = { state: AdvancedElementSettingsState, action: AdvancedElementSettingsModalAction ->
    when(action) {
        is OpenAdvancedElementSettings -> AdvancedElementSettingsState(true)
        is CloseAdvancedElementSettings -> AdvancedElementSettingsState(false)
        else -> state
    }
}

val selectAdvancedElementSettingsModalState = { state: StoreState -> state.advancedElementSettingsState }
