package widgets.ExerciseSettings.components.NumberOfSlidesToGenerate

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    width = 114.px
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
}

val button: (String) -> CssStyle = {
    {
        display = Display.flex
        alignItems = AlignItems.center
        justifyContent = JustifyContent.center
        width = 32.px
        height = 32.px
        borderRadius = 6.px
        border = None.none
        boxShadow = None.none
        padding = 0.px
        backgroundColor = Color(it)
    }
}

val inputNumber: CssStyle = {
    border = None.none
    width = 32.px
    padding = 2.px
    textAlign = TextAlign.center
}

val iconCss: CssStyle = {
    width = 32.px
    height = 32.px
}
