package widgets.Exercises.ui.components.UploadManuallyButton.UploadManuallyModal

import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.utils.io.charsets.*
import io.ktor.utils.io.core.*
import jsonClient
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.css.data
import org.w3c.files.File
import org.w3c.files.FileReader
import utils.getToken
import utils.result

fun uploadManuallyGeneratedExercise(
    exercise: ManuallyUploadedExercise,
    parentFolderId: Int?,
    onResponse: suspend (HttpResponse) -> Unit = { }
) {
    val reader = FileReader()
    reader.readAsText(exercise.file)
    reader.onload = {
        val formData = formData {
            append("name", exercise.name)
            parentFolderId?.let { append("parent_folder_id", it) }
            append("html", exercise.file.name) {
                val fileText = it.target.result!!
                val bytes = fileText.encodeToByteArray()
                writeFully(bytes, 0, bytes.size)
            }
        }

        MainScope().launch {
            try {
                val response = jsonClient.submitFormWithBinaryData("api/v2/auto-generation/upload-manually-generated-exercises", formData) {
                    bearerAuth(getToken())
                }
                onResponse(response)
            } catch (e: ResponseException) {
                onResponse(e.response)
            }
        }
    }
}

fun replaceManuallyGeneratedExercise(
    exerciseFile: File,
    onResponse: suspend (HttpResponse) -> Unit = { }
) {
    val reader = FileReader()
    reader.readAsText(exerciseFile)
    reader.onload = {
        val formData = formData {
            append("html", exerciseFile.name) {
                val fileText = it.target.result!!
                val bytes = fileText.encodeToByteArray()
                writeFully(bytes, 0, bytes.size)
            }
        }

        MainScope().launch {
            try {
                val response = jsonClient.submitFormWithBinaryData("api/v2/auto-generation/replace-manually-generated-exercises", formData) {
                    bearerAuth(getToken())
                }
                onResponse(response)
            } catch (e: ResponseException) {
                onResponse(e.response)
            }
        }
    }
}
