package pages.constructor.ui

import csstype.*
import pages.constructor.ui.components.header.headerHeight
import utils.types.CssStyle

val horizontalGap = 20
val headerMargin = 20

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    height = 100.pct.minus(headerHeight.px)
}

val middleContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.stretch
    gap = 20.px
    marginLeft = 20.px
    marginRight = 20.px
    flexGrow = number(1.0)
}

val canvasContainer: CssStyle = {
    height = 100.pct
    width = 100.pct
    marginTop = headerMargin.px
}

val alertsContainer: CssStyle = {
    position = Position.absolute
    bottom = 90.px
    left = 0.px
    zIndex = integer(1000)
}

val alert: CssStyle = {
    borderTopLeftRadius = 0.px
    borderBottomLeftRadius = 0.px
    borderLeft = None.none
    width = "fit-content".unsafeCast<Width?>()
}
