package online.interactiver.common.admin.interactive

import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import online.interactiver.common.interactivepicture.InteractivePicture

@Serializable
data class InteractiveResponse(
    @Contextual
    var uuid: String? = null,
    val name: String,
    @SerialName("interactives_group_id")
    val interactivesGroupId: Int,
    @SerialName("interactives_link")
    val interactivesLink: String? = null,
    @SerialName("interactive_id")
    val interactiveId: String? = null,
    @SerialName("last_edited_time")
    val lastEditedTime: Long? = null,
    @SerialName("has_json")
    val hasJson: Boolean?,
    @SerialName("has_html")
    val hasHtml: Boolean?,
    @SerialName("public_link")
    val publicLink: String? = null,
    @SerialName("public_link_name")
    val publicLinkName: String? = null,
    @SerialName("default_public_link_name")
    val defaultPublicLink: String? = null,
    @SerialName("interactive_picture")
    val interactivePicture: InteractivePicture? = null
)
