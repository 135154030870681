package pages.constructor.ui.components.editors

import antd.Button
import app.useAppSelector
import emotion.react.css
import entities.interactivePicture.selectFocusedElement
import enums.EButtonTypes
import pages.constructor.ui.components.editors.designEditor.DesignEditor
import pages.constructor.ui.components.editors.interactivityEditor.InteractivityEditor
import react.*
import react.dom.html.ReactHTML.div

enum class EditorsTabs {
    Interactivity, Design
}

external interface EditorsProps : Props {
    var stageRef: MutableRefObject<dynamic>
}

val Editors = FC<EditorsProps> { props ->
    val focusedElement = useAppSelector(selectFocusedElement)
    val (activeTab, setActiveTab) = useState(EditorsTabs.Design)

    useEffect(focusedElement) {
        if (focusedElement != null) {
            setActiveTab(EditorsTabs.Interactivity)
        }
    }

    div {
        css(container)
        div {
            css(headerContainer)
            Button {
                if (activeTab == EditorsTabs.Interactivity) css(activeHeader) else css(defaultHeader)
                id = EButtonTypes.INTERACTIVITY_TAB.value
                onClick = {
                    setActiveTab(EditorsTabs.Interactivity)
                }
                +"Interactivity"
            }

            Button {
                if (activeTab == EditorsTabs.Design) css(activeHeader) else css(defaultHeader)
                id = EButtonTypes.DESIGN_TAB.value
                onClick = {
                    setActiveTab(EditorsTabs.Design)
                }
                +"Design"
            }
        }
        when (activeTab) {
            EditorsTabs.Interactivity -> InteractivityEditor {
                stageRef = props.stageRef
            }

            EditorsTabs.Design -> DesignEditor {}
        }
    }
}
