package widgets.DuoCourses.ui.DuoCourses

import Modal
import antd.Button
import app.useAppDispatch
import csstype.ClassName
import emotion.react.css
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.browser.localStorage
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import online.interactiver.common.enums.ELanguage
import pages.studentDuo.SetStudentDuoTextOrTopic
import pages.studentDuo.selectRequiredStudentDuoNativeLanguage
import pages.studentDuo.selectStudentDuoCourses
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.key
import react.redux.useSelector
import react.router.useNavigate
import react.router.useParams
import react.useState
import utils.localization.Localization
import widgets.DuoCourses.ui.DuoCourse.DuoCourse
import widgets.DuoCourses.ui.getWordsProgress
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton

external interface DuoCoursesProps : Props {
    var close: () -> Unit
}

val DuoCourses = FC<DuoCoursesProps> { props ->
    val pathParams = useParams()
    val courses = useSelector(selectStudentDuoCourses)
    val nativeLanguage = useSelector(selectRequiredStudentDuoNativeLanguage)
    val navigate = useNavigate()
    val dispatch = useAppDispatch()
    val (isEmptyWordsProgressModalOpen, setIsEmptyWordsProgressModalOpen) = useState(false)
    val folderId = pathParams["folder_id"]?.toIntOrNull()

    div {
        css(container)
        Modal {
            zIndex = 100
            open = isEmptyWordsProgressModalOpen
            onCancel = { setIsEmptyWordsProgressModalOpen(false) }
            onOk = {
                navigate("/duo/content")
                setIsEmptyWordsProgressModalOpen(false)
            }
            div {
                +getModalText(nativeLanguage)
            }
        }
        div {
            css(classNames = arrayOf(ClassName("scroll")), coursesStyle)
            if (courses.isEmpty()) {
                p {
                    css(noCourses)
                    +Localization.getNoCourseText(nativeLanguage)
                }
            } else {
                courses.forEach {
                    DuoCourse {
                        key = it.id.toString()
                        course = it
                        onDelete = {
                            localStorage.setItem(
                                "duo_courses",
                                Json.encodeToString(courses.filter { course -> it.id != course.id })
                            )
                        }
                        close = props.close
                    }
                }
            }
        }
        div {
            css(buttonsContainer)
            Button {
                css(newTaskButton)
                onClick = {
                    navigate("/duo/content")
                }
                +Localization.getOtherTopicButtonTextWithLanguages(nativeLanguage)
            }
            Button {
                css(newTaskButton)
                onClick = {
                    dispatch(StartModalLoading("Loading words"))
                    GlobalScope.launch {
                        val wordsProgress = getWordsProgress(20, folderId)
                        dispatch(EndModalLoading())
                        props.close()
                        if (wordsProgress.isEmpty()) {
                            setIsEmptyWordsProgressModalOpen(true)
                            return@launch
                        }

                        dispatch(SetStudentDuoTextOrTopic(wordsProgress.joinToString(", ")))
                        navigate("/duo/content")
                    }
                }
                +getPracticeWordsFromTheVocabulary(nativeLanguage)
            }
            Button {
                css(newTaskButton)
                onClick = {
                    navigate("/duo/settings")
                }
                +Localization.getProfileAndSettingsButtonText(nativeLanguage)
            }
        }
    }
}

fun getPracticeWordsFromTheVocabulary(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Практика слов из словаря"
    } else {
        "Practice your vocabulary"
    }
}

fun getModalText(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "В вашем словаре пока нет слов.\n\nВведите слова которые хотите учить (на любом языке)"
    } else {
        "No words in your vocabulary yet.\n\nEnter words you want to learn (in any language)"
    }
}
