package widgets.CourseMaterials.ui.components.Materials

import csstype.*
import utils.types.CssStyle

val materials: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
    width = 100.pct
}

val headerContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
    width = 100.pct
}

val buttonsContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 21.px
}

val button: CssStyle = {
    backgroundColor = Color("#F5F5F5")
    border = None.none
    padding = Padding(6.px, 16.px)
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    gap = 5.px
    borderRadius = 44.px
}

val header: CssStyle = {
    margin = 0.px
    color = Color("#282F3E")
    fontWeight = integer(600)
    lineHeight = 18.9.px
    fontSize = 14.px
    fontFamily = string("\"Inter\", system-ui")
}
