package online.interactiver.common.autogeneration.taskLineContentFabric

import online.interactiver.common.autogeneration.taskLineContent.TextKeyPointsContent

class TextKeyPointsContentFabric(private val keyPointsCount: Int): TaskLineContentFabric<TextKeyPointsContent> {
    override fun canBeBuiltFrom(taskLineContent: String, isOpenAiFormat: Boolean): Boolean {
        val pairs = taskLineContent.split(";").map { it.trim() }.filter { it.isNotBlank() }
        return pairs.size == keyPointsCount + 1
    }

    override fun fromRawTaskLine(taskLineContent: String, isOpenAiFormat: Boolean): TextKeyPointsContent {
        val parts = taskLineContent.split(";").map { it.trim() }.filter { it.isNotBlank() }
        val keyPoints = parts.take(keyPointsCount)
        return TextKeyPointsContent(keyPoints, parts.last())
    }
}