package widgets.LanguageAutoSlider.ui.components.SliderName

import antd.Input
import emotion.react.css
import entities.languageAutoCurrentRequest.selectCurrentRequestSliderName
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.KeyboardEvent
import react.*
import react.redux.useSelector
import widgets.LanguageAutoSlider.updateSliderName

external interface SliderNameProps : Props {
    var requestId: Int
}

val SliderName = FC<SliderNameProps> {props ->
    val sliderName = useSelector(selectCurrentRequestSliderName)

    val (newSliderName, setNewSliderName) = useState("")

    useEffect(sliderName) {
        sliderName?.let { setNewSliderName(it) }
    }


    Input {
        css(sliderNameInput)
        value = newSliderName
        onChange = { setNewSliderName(it.target.value.take(100)) }
        placeholder = "Enter a title for your exercises..."
        onPressEnter = f@{ it: KeyboardEvent ->
            if (it.key != "Enter") {
                return@f
            }
            it.preventDefault()
            (it.target as? HTMLElement)?.blur()
        }
        onBlur = f@{
            if (newSliderName == sliderName) {
                return@f
            }

            GlobalScope.launch {
                updateSliderName(props.requestId, newSliderName)
            }
        }
    }
}