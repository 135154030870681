package entities.interactivePicture.elements.figuresAndLines.ui.tools

import antd.Button
import antd.Tooltip
import app.appState.selectAppState
import app.useAppDispatch
import app.useAppSelector
import app.useBackgroundCenteredPosition
import builders.enums.EElementType
import emotion.css.ClassName
import emotion.react.css
import entities.interactivePicture.elements.shared.tools.button
import entities.interactivePicture.elements.shared.tools.buttonText
import entities.interactivePicture.elements.shared.tools.buttonsGroup
import entities.lastCreatedType.SetLastCreatedType
import entities.lastCreatedType.selectLastCreatedType
import entities.selectedElement.SelectElement
import entities.selectedElement.SelectMultipleElements
import entities.selectedElement.selectElementsIdsUnderSelectionRectangle
import enums.EButtonTypes
import kotlinx.browser.document
import online.interactiver.common.interactivepicture.Point
import online.interactiver.common.utils.getTimeMillisInBase
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.MouseEvent
import pages.constructor.ui.components.inputImage.InputImage
import pages.constructor.ui.components.inputImage.inputImageStyle
import react.FC
import react.Props
import react.dom.html.InputType
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import shared.canvas.interfaces.getPointWithOffset
import shared.canvas.interfaces.getPositionWithOffset
import shared.components.Icon
import utils.fileToBase64
import utils.fileToPicture
import utils.types.jsObject

val StaticTools = FC<Props> {
    val bgCenteredPos = useBackgroundCenteredPosition()
    val soundPos = useBackgroundCenteredPosition(44, 44)
    val appState = useAppSelector(selectAppState)
    val elementsIdUnderSelectionRectangle = useAppSelector(selectElementsIdsUnderSelectionRectangle)
    val lastCreatedType = useAppSelector(selectLastCreatedType)
    val startLinePosition = Point(100, 100)
    val endLinePosition = Point(200, 200)

    val dispatch = useAppDispatch()

    val inputImageOnChange =
        fileToPicture { a, b, r ->
            val position = bgCenteredPos.getPositionWithOffset(lastCreatedType, EElementType.IMAGE_STATIC)
            val id = getTimeMillisInBase()
            dispatch(appState.getAddRectWithImage(id, position, a!!, b!!, r!!))
            if (elementsIdUnderSelectionRectangle.isNotEmpty()) {
                dispatch(SelectMultipleElements(emptyList<String>().toTypedArray()))
            }
            dispatch(SelectElement(id))
            dispatch(SetLastCreatedType(EElementType.IMAGE_STATIC))
        }

    val inputAudioOnChange =
        fileToBase64 { src, filename ->
            val position = soundPos.getPositionWithOffset(lastCreatedType, EElementType.SOUND_STATIC)
            val id = getTimeMillisInBase()
            dispatch(appState.getAddSound(id, position, src, filename))
            if (elementsIdUnderSelectionRectangle.isNotEmpty()) {
                dispatch(SelectMultipleElements(emptyList<String>().toTypedArray()))
            }
            dispatch(SelectElement(id))
            dispatch(SetLastCreatedType(EElementType.SOUND_STATIC))
        }

    ReactHTML.input {
        css(inputImageStyle)
        id = EButtonTypes.CREATE_SOUND_BUTTON.value
        type = InputType.file
        accept = "audio/*"
        size = 1
        onChange = inputAudioOnChange
    }

    div {
        css(buttonsGroup)
        Tooltip {
            title = "Enter text in a static rectangle"
            placement = "topLeft"
            Button {
                css(button)
                id = EButtonTypes.CREATE_RECT_BUTTON.value
                div {
                    css(buttonText)
                    +"Rectangle with text"
                }
                Icon {
                    src = "ic_text_static_24x24.svg"
                }
                onClick = {
                    val position = bgCenteredPos.getPositionWithOffset(lastCreatedType, EElementType.TEXT_STATIC)
                    val id = getTimeMillisInBase()
                    dispatch(appState.getAddRectWithText(id, position))
                    if (elementsIdUnderSelectionRectangle.isNotEmpty()) {
                        dispatch(SelectMultipleElements(emptyList<String>().toTypedArray()))
                    }
                    dispatch(SelectElement(id))
                    dispatch(SetLastCreatedType(EElementType.TEXT_STATIC))
                }
            }
        }
        Tooltip {
            title = "Enter text in a static circle"
            placement = "topRight"
            Button {
                css(button)
                id = EButtonTypes.CREATE_MARKER_BUTTON.value
                div {
                    css(buttonText)
                    +"Marker"
                }
                Icon {
                    src = "ic_marker_static_24x24.svg"
                }
                onClick = {
                    val position = bgCenteredPos.getPositionWithOffset(lastCreatedType, EElementType.MARKER_STATIC)
                    val id = getTimeMillisInBase()
                    dispatch(appState.getAddMarker(id, position))
                    if (elementsIdUnderSelectionRectangle.isNotEmpty()) {
                        dispatch(SelectMultipleElements(emptyList<String>().toTypedArray()))
                    }
                    dispatch(SelectElement(id))
                    dispatch(SetLastCreatedType(EElementType.MARKER_STATIC))
                }
            }
        }
        Tooltip {
            title = "Highlight an area with a rectangle"
            placement = "topLeft"
            Button {
                css(button)
                id = EButtonTypes.CREATE_FRAME_BUTTON.value
                div {
                    css(buttonText)
                    +"Frame Rect"
                }
                Icon {
                    src = "ic_text_hint_24x24.svg"
                }
                onClick = {
                    val position = bgCenteredPos.getPositionWithOffset(lastCreatedType, EElementType.RECT_FRAME)
                    val id = getTimeMillisInBase()
                    dispatch(appState.getAddFrameRect(id, position))
                    if (elementsIdUnderSelectionRectangle.isNotEmpty()) {
                        dispatch(SelectMultipleElements(emptyList<String>().toTypedArray()))
                    }
                    dispatch(SelectElement(id))
                    dispatch(SetLastCreatedType(EElementType.RECT_FRAME))
                }
            }
        }
        Tooltip {
            title = "Highlight an area with a dotted rectangle"
            placement = "topRight"
            overlayStyle =  jsObject { maxWidth = "500px" }
            Button {
                css(button)
                id = EButtonTypes.CREATE_AREA_BUTTON.value
                div {
                    css(buttonText)
                    +"Frame Area"
                }
                Icon {
                    src = "ic_frame_area_24x24.svg"
                }
                onClick = {
                    val position = bgCenteredPos.getPositionWithOffset(lastCreatedType, EElementType.AREA_FRAME)
                    val id = getTimeMillisInBase()
                    dispatch(appState.getAddFrameArea(id, position))
                    if (elementsIdUnderSelectionRectangle.isNotEmpty()) {
                        dispatch(SelectMultipleElements(emptyList<String>().toTypedArray()))
                    }
                    dispatch(SelectElement(id))
                    dispatch(SetLastCreatedType(EElementType.AREA_FRAME))
                }
            }
        }
        Tooltip {
            title = "Show sequence or direction"
            placement = "topLeft"
            Button {
                css(button)
                id = EButtonTypes.CREATE_ARROW_BUTTON.value
                div {
                    css(buttonText)
                    +"Arrow"
                }
                Icon {
                    src = "ic_arrow_static_24x24.svg"
                }
                onClick = {
                    val id = getTimeMillisInBase()
                    dispatch(
                        appState.getAddArrow(
                            id,
                            startLinePosition.getPointWithOffset(lastCreatedType, EElementType.ARROW_STATIC),
                            endLinePosition.getPointWithOffset(lastCreatedType, EElementType.ARROW_STATIC)
                        )
                    )
                    if (elementsIdUnderSelectionRectangle.isNotEmpty()) {
                        dispatch(SelectMultipleElements(emptyList<String>().toTypedArray()))
                    }
                    dispatch(SelectElement(id))
                    dispatch(SetLastCreatedType(EElementType.ARROW_STATIC))
                }
            }
        }
        Tooltip {
            title = "Underline or separate"
            placement = "topRight"
            Button {
                css(button)
                id = EButtonTypes.CREATE_LINE_BUTTON.value
                div {
                    css(buttonText)
                    +"Line"
                }
                Icon {
                    src = "ic_line_static_24x24.svg"
                }
                onClick = {
                    val id = getTimeMillisInBase()
                    dispatch(
                        appState.getAddLine(
                            id,
                            startLinePosition.getPointWithOffset(lastCreatedType, EElementType.LINE_STATIC),
                            endLinePosition.getPointWithOffset(lastCreatedType, EElementType.LINE_STATIC)
                        )
                    )
                    if (elementsIdUnderSelectionRectangle.isNotEmpty()) {
                        dispatch(SelectMultipleElements(emptyList<String>().toTypedArray()))
                    }
                    dispatch(SelectElement(id))
                    dispatch(SetLastCreatedType(EElementType.LINE_STATIC))
                }
            }
        }
        InputImage {
            className = ClassName(button)
            buttonIcon = "ic_image_static_24x24.svg"
            buttonText = "Upload image"
            tooltipTitle = "Add image from your device"
            onChange = inputImageOnChange
            multiple = true
            id = EButtonTypes.CREATE_IMAGE_BUTTON.value
        }
        Tooltip {
            title = "Add sound from your device"
            placement = "topRight"
            Button {
                css(button)
                id = EButtonTypes.CREATE_LINE_BUTTON.value
                div {
                    css(buttonText)
                    +"Sound"
                }
                Icon {
                    src = "ic_play_24x24.svg"
                }
                onClick = { evt: MouseEvent ->
                    evt.preventDefault()
                    val input = document.getElementById(EButtonTypes.CREATE_SOUND_BUTTON.value)
                    (input as? HTMLInputElement)?.click()
                }
            }
        }
    }
}
