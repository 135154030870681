package online.interactiver.common.autogeneration.taskLineContentFabric

import online.interactiver.common.autogeneration.taskLineContent.MarkTrueFalseContent

class MarkTrueFalseContentFabric(private val statementsCount: Int): TaskLineContentFabric<MarkTrueFalseContent> {
    override fun canBeBuiltFrom(taskLineContent: String, isOpenAiFormat: Boolean): Boolean {
        val pairs = taskLineContent.split(";").map { it.trim() }.filter { it.isNotBlank() }

        if (pairs.size != statementsCount + 1) return false

        for (i in 0 until statementsCount) {
            val hasTrue = pairs[i].contains("- T") || pairs[i].contains("-T")
            val hasFalse = pairs[i].contains("- F") || pairs[i].contains("-F")
            if (!hasTrue && !hasFalse) {
                return false
            }
        }

        return true
    }

    override fun fromRawTaskLine(taskLineContent: String, isOpenAiFormat: Boolean): MarkTrueFalseContent {
        val parts = taskLineContent.split(";").map { it.trim() }.filter { it.isNotBlank() }
        val keyPoints = parts.take(statementsCount).map {
            MarkTrueFalseContent.Statement(
                it.substringBeforeLast("-"),
                it.substringAfterLast("-").trim() == "T"
            )
        }
        return MarkTrueFalseContent(keyPoints, parts.last())
    }
}