package entities.interactivePicture.elements.editors.ScoreEditor.ui

import antd.BaseOptionType
import antd.Input
import antd.Select
import app.appState.selectAppState
import app.useAppDispatch
import app.useAppSelector
import emotion.react.css
import entities.interactivePicture.elements.editors.ScoreEditor.scoreTitles
import entities.interactivePicture.selectFocusedElement
import entities.interactivePicture.selectGapPuzzles
import online.interactiver.common.export.ExportData
import online.interactiver.common.interactivepicture.ScoreData
import online.interactiver.common.utils.getSpecificDefaultScore
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSelectElement
import org.w3c.dom.events.KeyboardEvent
import react.FC
import react.Props
import react.dom.events.FocusEvent
import react.dom.html.ReactHTML
import react.ref
import react.useRef
import utils.parseNumber
import utils.types.jsObject

external interface ScoreInputProps : Props {
    var elementUID: String
    var scoreIndex: Int
    var score: ScoreData
}

val ScoreInput = FC<ScoreInputProps> { props ->

    val dispatch = useAppDispatch()
    val appState = useAppSelector(selectAppState)
    val selected = useAppSelector(selectFocusedElement)
    val options = useAppSelector(scoreTitles)
    val gapsAmount = useAppSelector(selectGapPuzzles)?.size
    val value = props.score.getValue(selected?.getSpecificDefaultScore(ExportData(gapsAmount)) ?: 0)
    val selectRef = useRef<HTMLSelectElement>(null)

    val onFocusOut = a@{ event: FocusEvent<HTMLInputElement> ->
        val target = event.target as? HTMLInputElement

        val value = target!!.value
        if (value.isEmpty()) {
            return@a
        }

        dispatch(
                appState.setScoreData(
                        props.elementUID,
                        props.scoreIndex,
                        ScoreData(value, props.score.value)
                )
        )
    }


    val onValueInput = b@{ event: dynamic ->
        val value = event.target.value
        val number = if (event.target.value == "") null else parseNumber(value) ?: props.score.value
        dispatch(appState.setScoreData(props.elementUID, props.scoreIndex, ScoreData(props.score.name, number)))
        return@b
    }

    val onSelectValue = c@{ value: String, _: BaseOptionType ->
        dispatch(appState.setScoreData(props.elementUID, props.scoreIndex, ScoreData(value, props.score.value)))
        return@c
    }

    val onKeyDown = f@{ event: KeyboardEvent ->
        if (event.key != "Enter") {
            return@f
        }

        event.preventDefault()
        selectRef.current?.blur()
    }


    ReactHTML.div {
        css(horizontalContainer)

        Select {
            css(select)
            this.ref = selectRef
            this.showArrow = false
            this.showSearch = true
            disabled = props.scoreIndex == 0

            this.onSelect = onSelectValue
            this.onBlur = onFocusOut

            this.onKeyDown = onKeyDown

            this.options = options.map {
                jsObject {
                    this.value = it
                    this.label = it
                }
            }.toTypedArray()

            this.defaultValue = props.score.name
            this.value = props.score.name
        }

        Input {
            css(longInput)
            if (props.score.hasValue()) {
                this.value = props.score.value.toString()
                this.defaultValue = props.score.value.toString()
            }
            this.placeholder = value.toString()
            this.onBlur = onValueInput
            this.onChange = onValueInput
        }
    }

}
