package pages.constructor.ui.components.header.templates.gallery

import emotion.react.css
import online.interactiver.common.api.TagColor
import online.interactiver.common.api.TemplateMeta
import pages.constructor.ui.components.header.templates.element.TemplatesElement
import react.FC
import react.Props
import react.dom.html.ReactHTML.div

external interface ModalGroupProps : Props {
    var groupName: String
    var setCurTemplate: (template: TemplateMeta) -> Unit
    var templates: MutableList<TemplateMeta>
    var tags: MutableList<TagColor>
}


val ModalGroup = FC<ModalGroupProps> { props ->

    div {
        css(header)
        +props.groupName
    }

    div {
        css(horizontalContainer)
        props.templates.forEach { template ->
            TemplatesElement {
                this.templateMeta = template
                this.setCurTemplate = { template: TemplateMeta -> props.setCurTemplate(template) }
                this.tags = props.tags
            }
        }
    }
}
