package online.interactiver.common.autogeneration.taskLineContentFabric

import online.interactiver.common.autogeneration.taskLineContent.BuildSentenceContent

object BuildSentenceContentFabric : TaskLineContentFabric<BuildSentenceContent> {
    override fun fromRawTaskLine(taskLineContent: String, isOpenAiFormat: Boolean): BuildSentenceContent {
        val patternedPhrase = taskLineContent.substringBefore(';')
        val hint = taskLineContent.substringAfter(';', "")
        return BuildSentenceContent(patternedPhrase, hint)
    }

    override fun canBeBuiltFrom(taskLineContent: String, isOpenAiFormat: Boolean): Boolean = true
}
