package widgets.LanguageAutoSlider.ui.components.TitleWithScoreSetting

import csstype.*
import utils.types.CssStyle

val conditionsContainer: CssStyle = {
    width = 100.pct
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
}

val titlesContainer: CssStyle = {
    width = 100.pct
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 8.px
}

val input: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    width = 100.pct
    height = 39.px
    border = None.none
    borderRadius = 8.px
    backgroundColor = Color("#F9FAFC")
}

val generalScore: CssStyle = {
    margin = 0.px
    fontSize = 14.px
    lineHeight = 18.2.px
    color = Color("#5D6676")
    fontWeight = integer(500)
}
