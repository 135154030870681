package pages.constructor.ui.components.header.templates

import antd.Button
import emotion.react.css
import enums.EButtonTypes
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import getTemplates
import online.interactiver.common.api.TemplatesModalWindowData
import pages.constructor.ui.components.header.templates.modal.TemplatesModal
import react.FC
import react.Props
import react.createElement
import react.useState
import shared.components.Icon
import utils.types.buildProps

val TemplatesButton = FC<Props> {
    val (open, setOpen) = useState(false)
    val (templatesModalWindowData, setTemplatesModalWindowData) = useState(null as TemplatesModalWindowData?)

    if (templatesModalWindowData == null) {
        MainScope().launch {
            val templateJSON = getTemplates();
            val config = getTemplatesConfig(templateJSON);
            setTemplatesModalWindowData(config);
        }
    }

    Button {
        css(button)
        id = EButtonTypes.TEMPLATES_BUTTON.value
        ghost = true
        onClick = {
            setOpen(true)
        }
        icon = createElement(Icon, buildProps {
            src = "ic_template_24x24.svg"
        })
        +"Templates"
    }

    TemplatesModal {
        isOpen = open && templatesModalWindowData != null
        setIsOpen = { flag: Boolean -> setOpen(flag) }
        data = templatesModalWindowData
    }
}
