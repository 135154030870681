package online.interactiver.common.enums

enum class ETimePeriod(val value: String, val unit: ETimeUnit, val numberOfUnits: Long) {
    LAST_YEAR("Last year", ETimeUnit.YEAR, 1),
    LAST_MONTH("Last month", ETimeUnit.MONTH, 1),
    LAST_WEEK("Last week", ETimeUnit.DAY, 7),
    LAST_DAY("Last day", ETimeUnit.DAY, 1),
    LAST_HOUR("Last hour", ETimeUnit.HOUR, 1);

    companion object {
        fun fromString(timePeriod: String?): ETimePeriod? = entries.find { it.value == timePeriod }
    }
}
