package widgets.LanguageAutoTask.ui.components.HiddenIframe


import emotion.react.css
import kotlinx.browser.window
import org.w3c.dom.events.Event
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.iframe

external interface HiddenIframeProps : Props {
    var slider: String
    var show: Boolean
    var script: String
    var onHandleMessage: (Event) -> Unit
}

val HiddenIframe = FC<HiddenIframeProps> { props ->

    useEffect(props.onHandleMessage) {
        window.addEventListener("message", props.onHandleMessage)
        cleanup {
            window.removeEventListener("message", props.onHandleMessage)
        }
    }
    if (!props.show || props.slider.isBlank()) {
        return@FC
    }

    val iFrameSrc = getIframeSrc(props.slider, props.script)

    div {
        css(iFrameContainer)
        iframe {
            css(iFrame)
            srcDoc = iFrameSrc
        }
    }
}