package entities.interactivePicture.elements.editors.textEditor

import antd.TextArea
import emotion.react.css
import entities.dictionary.ui.syncButton.SyncButton
import kotlinx.css.FlexDirection
import org.w3c.dom.HTMLInputElement
import pages.constructor.ui.components.hintStyleSettings.HintStyleSettings
import react.FC
import react.Props
import react.dom.events.ChangeEventHandler
import react.dom.events.FocusEventHandler
import react.dom.html.ReactHTML.div

external interface TextEditorProps : Props {
    var disabled: Boolean?
    var header: String?
    var onChange: ChangeEventHandler<HTMLInputElement>?
    var value: String?
    var id: String?
    var defaultValue: String?
    var placeholder: String?
    var onFocus: FocusEventHandler<HTMLInputElement>?
    var height: Int
    var width: Int
    var flexDirection: csstype.FlexDirection
    var fontWeight: Int
}

val TextEditor = FC<TextEditorProps> { props ->
    div {
        css(container(props.flexDirection))
        div {
            css(header(props.width, props.fontWeight))
            +(props.header ?: "")
            if (props.header == "Hint") {
                HintStyleSettings { }
                SyncButton { }
            }
        }

        div {
            css(wrapContainer(props.width))
            TextArea {
                css(getTextArea(props.height, props.width))
                dir = "auto"
                id = props.id
                disabled = props.disabled == true
                value = props.value
                defaultValue = props.defaultValue
                placeholder = props.placeholder
                onChange = { evt ->
                    props.onChange?.invoke(evt)
                }
                onFocus = props.onFocus
            }
        }
    }
}
