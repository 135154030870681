package pages.constructor.ui.components.advancedSettings.hintsSettings.hintStyle

import emotion.react.css
import enums.EHintColor
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.components.radio.Radio

external interface HintStyleEditorProps : Props {
    var color: String
    var onChange: (String) -> Unit
}

val HintStyleEditor = FC<HintStyleEditorProps> { props ->
    div {
        css(text)
        +"Choose a hint style"
    }
    div {
        css(container)
        EHintColor.values().forEach {
            div {
                css(radioContainer)
                Radio {
                    current = props.color
                    value = it.value
                    onChange = { value ->
                        props.onChange(value)
                    }
                }
                div {
                    css(hintColor(it.color))
                    div {
                        css(textHint(it.textColor))
                        +"Hint text"
                    }
                }
            }
        }
    }
}