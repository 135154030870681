package widgets.LanguageAutoTask.ui.components.SlideEditModal

import csstype.*
import utils.types.CssStyle

val modal: CssStyle = {
    display = Display.flex
    gap = 22.px
    flexDirection = FlexDirection.column
    width = 100.pct
}

val iFrameContainer: CssStyle = {
    width = 400.px
    height = 400.px
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
}

val container: CssStyle = {
    display = Display.flex
    gap = 20.px
    width = 100.pct
}

val header: CssStyle = {
    margin = 0.px
    fontSize = 14.px
    lineHeight = 18.9.px
    fontWeight = integer(500)
    color = Color("#282F3E")
}

val slideEditContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 16.px
}

val buttonsContainer: CssStyle = {
    width = 385.px
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
    marginTop = Auto.auto
}

val updateButton: CssStyle = {
    width = 186.px
    height = 44.px
    backgroundColor = Color("#597EF7")
    border = None.none
    borderRadius = 8.px
    fontSize = 16.px
    lineHeight = 20.8.px
    fontWeight = integer(600)
    color = Color("white")
}

val cancelButton: CssStyle = {
    width = 186.px
    height = 44.px
    backgroundColor = Color("#F4F7FF")
    border = None.none
    borderRadius = 8.px
    fontSize = 16.px
    lineHeight = 20.8.px
    fontWeight = integer(600)
    color = Color("#597EF7")
}
