package shared.components.inputLabelledLimited

import builders.enums.EAlign
import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.stretch
    gap = 5.px
}

val labelContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.center
}

val label: CssStyle = {
    color = Color("#282F3E")

    fontFamily = string("Montserrat")
    fontWeight = integer(400)
    fontSize = 14.px
    fontStyle = FontStyle.normal
}

val counter: (EAlign.Horizontal, Int) -> CssStyle = { align: EAlign.Horizontal, minWidthCh: Int ->
    {
        color = Color("#98A2B3")

        fontFamily = string("Montserrat")
        fontWeight = integer(400)
        fontSize = 14.px
        fontStyle = FontStyle.normal

        minWidth = minWidthCh.ch

        textAlign = align.toTextAlign()
    }
}

val tooltipWrapper: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    gap = 8.px
    alignItems = AlignItems.center
}
