package widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.QuestionWithAnswersEditor

import app.useAppDispatch
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.autogeneration.TaskLine
import online.interactiver.common.autogeneration.taskLineContent.QuestionWithAnswersContent
import online.interactiver.common.interactiveexercise.EExerciseUiPattern
import online.interactiver.common.interactiveexercise.EExerciseUiPatternMetaTag
import online.interactiver.common.interactiveexercise.ExerciseUiPattern
import react.FC
import react.dom.html.ReactHTML.div
import react.useMemo
import shared.components.cropImage.useCropImage
import shared.components.inputLabelledLimited.InputLabelledLimited
import shared.components.inputLabelledLimited.InputType
import shared.components.inputLabelledLimitedWithCheckbox.InputLabelledLimitedWithCheckbox
import shared.components.inputLabelledLimitedWithRadio.InputLabelledLimitedWithRadio
import utils.types.CssStyle
import widgets.LanguageAutoTask.ui.components.SlideEditModal.savePictureRequest
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.TaskLineUiEditorWithSpecifiedPatternProps

enum class QuestionWithAnswersEditorContainerType(
    val answersCount: Int,
    val container: CssStyle,
    val inputLabel: String
) {
    GRID_2X2(4, grid_2x2_container, "Opt"),
    COLUMN_3(3, column_3_container, "Answer option");
}

external interface QuestionWithAnswersEditorProps : TaskLineUiEditorWithSpecifiedPatternProps {
    var type: QuestionWithAnswersEditorContainerType
}

val QuestionWithAnswersEditor = FC<QuestionWithAnswersEditorProps> { props ->
    val dispatch = useAppDispatch()
    val taskLine = useMemo(props.taskLine, props.slide, callback = {
        try {
            TaskLine(props.taskLine, slide = props.slide)
        } catch (e: TaskLine.Error) {
            null
        }
    })

    val content = useMemo(taskLine, callback = {
        taskLine?.content as? QuestionWithAnswersContent
    })

    val pattern = useMemo(taskLine, callback = {
        taskLine?.pattern
    })

    val (resetCropImage, cropImage, cropImageProps) = useCropImage()

    if (taskLine == null || content == null || pattern == null) {
        return@FC
    }

    div {
        css(container)
        InputLabelledLimited {
            css(textarea)
            placeholder = "Question"
            label = "Question"
            value = content.question
            onChange = {
                val newContent = QuestionWithAnswersContent(
                    it, content.answers, content.textToSound, content.picture
                )

                taskLine.content = newContent
                props.onChange?.invoke(taskLine.toRawTaskLine())
            }
            limit = props.maxMatchLength
            inputType = InputType.TEXTAREA
        }

        div {
            css(props.type.container)
            for (i in 0 until props.type.answersCount) {
                val label = "${props.type.inputLabel} ${i + 1}"
                if (pattern.metaTags.contains(EExerciseUiPatternMetaTag.NO_RADIO)) {
                    InputLabelledLimitedWithCheckbox {
                        css(textarea)
                        placeholder = label
                        this.label = label
                        value = content.answers[i].value
                        onChange = {
                            val newAnswers = content.answers.toMutableList()
                            newAnswers[i] = QuestionWithAnswersContent.Answer(it, newAnswers[i].isCorrect)
                            val newContent = QuestionWithAnswersContent(
                                content.question, newAnswers, content.textToSound, content.picture
                            )

                            taskLine.content = newContent
                            props.onChange?.invoke(taskLine.toRawTaskLine())
                        }
                        checked = content.answers[i].isCorrect
                        onCheck = {
                            val newAnswers = content.answers.toMutableList()
                            newAnswers[i] = QuestionWithAnswersContent.Answer(newAnswers[i].value, it)
                            val newContent = QuestionWithAnswersContent(
                                content.question, newAnswers, content.textToSound, content.picture
                            )

                            taskLine.content = newContent
                            props.onChange?.invoke(taskLine.toRawTaskLine())
                        }
                        limit = props.maxWordLength
                        inputType = InputType.TEXTAREA
                        soundSrc = content.answers.getOrNull(i)?.soundSrc
                        onEditSoundClick = {
                            content.answers.getOrNull(i)?.value?.let { props.onEditSoundClick?.invoke(it) }
                        }
                    }
                } else {
                    InputLabelledLimitedWithRadio {
                        css(textarea)
                        placeholder = label
                        this.label = label
                        value = content.answers[i].value
                        onChange = {
                            val newAnswers = content.answers.toMutableList()
                            newAnswers[i] = QuestionWithAnswersContent.Answer(it, newAnswers[i].isCorrect)
                            val newContent = QuestionWithAnswersContent(
                                content.question, newAnswers, content.textToSound, content.picture
                            )

                            taskLine.content = newContent
                            props.onChange?.invoke(taskLine.toRawTaskLine())
                        }
                        radioCurrent = content.answers.indexOfFirst { it.isCorrect }.toString()
                        radioValue = i.toString()
                        onRadioChange = onRadioChange@{
                            val index = it.toIntOrNull() ?: return@onRadioChange

                            val newAnswers = content.answers.toMutableList()
                            newAnswers.forEach { it.isCorrect = false }
                            newAnswers[index].isCorrect = true

                            val newContent = QuestionWithAnswersContent(
                                content.question, newAnswers, content.textToSound, content.picture
                            )

                            taskLine.content = newContent
                            props.onChange?.invoke(taskLine.toRawTaskLine())
                        }
                        limit = props.maxWordLength
                        inputType = InputType.TEXTAREA
                        soundSrc = content.answers.getOrNull(i)?.soundSrc
                        onEditSoundClick = {
                            content.answers.getOrNull(i)?.value?.let { props.onEditSoundClick?.invoke(it) }
                        }
                    }
                }
            }
        }

        div {
            css(verticalContainer)
            cropImage {
                savedImage = content.picture
                isOpen = cropImageProps.isOpen
                openModal = cropImageProps.openModal
                closeModal = cropImageProps.closeModal
                crop = cropImageProps.crop
                cropOnChange = cropImageProps.cropOnChange
                onDeleteClick = {
                    val newContent = QuestionWithAnswersContent(
                        content.question, content.answers, content.textToSound, null
                    )

                    taskLine.content = newContent
                    taskLine.pattern = ExerciseUiPattern(
                        if (props.type.answersCount == 3) {
                            EExerciseUiPattern.THREE_BUTTONS
                        } else {
                            EExerciseUiPattern.FOUR_BUTTONS
                        },
                        pattern.metaTags
                    )

                    props.onPatternChange?.invoke(taskLine.toRawTaskLine())
                }
                onSaveClick = {
                    dispatch(StartModalLoading("Saving picture"))
                    GlobalScope.launch {
                        val hashedPicture = savePictureRequest(it)
                        if (hashedPicture.data == null) {
                            dispatch(EndModalLoading())
                            dispatch(OpenErrorModal("Error saving picture. Try again"))
                            return@launch
                        }

                        resetCropImage()
                        val newContent = QuestionWithAnswersContent(
                            content.question, content.answers, content.textToSound, hashedPicture.data
                        )

                        taskLine.content = newContent
                        taskLine.pattern = ExerciseUiPattern(
                            if (props.type.answersCount == 3) {
                                EExerciseUiPattern.THREE_BUTTONS_WITH_PICTURE
                            } else {
                                EExerciseUiPattern.FOUR_BUTTONS_WITH_PICTURE
                            },
                            pattern.metaTags
                        )

                        props.onPatternChange?.invoke(taskLine.toRawTaskLine())
                    }
                }
            }
        }
    }
}
