package widgets.languageAutoTask.ui.components.SlideEditModal.patternMetaTagsEditor.soundShowingMetaTagEditor

import emotion.react.css
import online.interactiver.common.interactiveexercise.EExerciseUiPatternMetaTag
import react.FC
import react.dom.html.ReactHTML.div
import react.useMemo
import react.useState
import shared.components.radio.Radio
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.PatternMetaTagsEditorProps

enum class SoundShowingMetaTagEditorChoices(
    val associatedTag: EExerciseUiPatternMetaTag?,
    val uiLabel: String = associatedTag?.uiLabel ?: "text and sound"
) {
    TEXT_AND_SOUND(null),
    NO_SOUND(EExerciseUiPatternMetaTag.NO_SOUND),
    NO_TEXT(EExerciseUiPatternMetaTag.NO_TEXT);

    companion object {
        fun fromUiLabel(uiLabel: String) = SoundShowingMetaTagEditorChoices.entries.find { it.uiLabel == uiLabel }
    }
}

val SoundShowingMetaTagEditor = FC<PatternMetaTagsEditorProps> { props ->
    val value = useMemo(props.value) {
        props.value ?: listOf()
    }

    val (curChoice, setCurChoice) = useState(
        SoundShowingMetaTagEditorChoices.entries.find { value.contains(it.associatedTag) } ?:
        SoundShowingMetaTagEditorChoices.entries.find { it.associatedTag == null }!!
    )

    div {
        div {
            css(header)
            +"Sound pattern"
        }
        div {
            css(grid)
            SoundShowingMetaTagEditorChoices.entries.map {
                Radio {
                    this.value = it.uiLabel
                    onChange = { uiLabel ->
                        val newTag = SoundShowingMetaTagEditorChoices.fromUiLabel(uiLabel)!!.associatedTag
                        val prevTag = curChoice.associatedTag
                        val newValue = value.toMutableList()
                        val indexOfPrevTag = value.indexOf(prevTag)

                        if (indexOfPrevTag >= 0) {
                            if (newTag != null) {
                                newValue[indexOfPrevTag] = newTag
                            } else {
                                newValue.removeAt(indexOfPrevTag)
                            }
                        } else {
                            if (newTag != null) {
                                newValue.add(newTag)
                            }
                        }

                        setCurChoice(it)
                        props.onChange?.invoke(newValue.toList())
                    }
                    label = it.uiLabel
                    current = curChoice.uiLabel
                }
            }
        }
    }
}
