package pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewCheckingSettings

import csstype.*
import utils.types.CssStyle

val settingsHeader: CssStyle = {
    fontStyle = FontStyle.normal
    fontWeight = FontWeight.normal
    fontSize = 14.px
    lineHeight = 130.pct
    textTransform = TextTransform.uppercase
    color = Color("#98A2B3")
    marginBottom = 18.px
}