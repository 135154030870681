package widgets.SliderStatistics

import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.serialization.json.Json
import online.interactiver.common.statistics.UserDetailedStatisticsOfSliderResponse
import online.interactiver.common.statistics.UserDetailedStatisticsRequest
import utils.getToken

suspend fun getStatisticsWithDetailsAboutUsersRequest(
    requestId: Int, timePeriod: String, studentName: String
): List<UserDetailedStatisticsOfSliderResponse>? {
    val response = jsonClient.post("api/v2/auto-generation/language-slider/requests/$requestId/statistics-with-details-about-users") {
        bearerAuth(getToken())
        contentType(ContentType.Application.Json)
        setBody(UserDetailedStatisticsRequest(timePeriod, studentName))
    }

    if (response.status.value != 200) {
        return null
    }

    return Json.decodeFromString(response.bodyAsText())
}