package pages.constructor.ui.components.header.interactiveParams.selectors

import csstype.*
import utils.inject
import utils.types.CssStyle


val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column

    gap = 6.px

    width = 100.pct
    height = 63.px
}

val header: CssStyle = {
    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 14.px
    lineHeight = 17.px
    color = Color("#101828")
}

val select: CssStyle = {
    height = 32.px
    width = 100.pct
    borderRadius = 3.px

    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween

    whiteSpace = WhiteSpace.normal
    textAlign = TextAlign.left

    padding = 0.px
}

