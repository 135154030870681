package pages.constructor.ui.components.header.templates.element

import csstype.*
import utils.types.CssStyle

val container= { heightElement: Length ->
    val containerElement: CssStyle = {
        display = Display.flex
        flexDirection = FlexDirection.column
        alignItems = AlignItems.flexStart

        width = 180.px
        height = heightElement

        gap = 16.px

        paddingLeft = 8.px
        paddingRight = 8.px
        paddingTop = 8.px
        paddingBottom = 16.px

        borderRadius = 5.px
        boxShadow = BoxShadow(0.px, 3.px, 8.px, rgba(20, 38, 74, 0.1))
        background = Color("#FFFFFF")
    }
    containerElement
}

val mainContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.flexStart

    gap = 12.px

    width = 180.px
    height = 180.px
}

val infoContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.start
    paddingLeft = 6.px
    paddingRight = 6.px
    gap = 4.px
    width = 179.px
}

val tagContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.flexStart
    padding = Padding(0.px, 6.px)
    gap = 8.px
    width = 180.px
    minHeight = 21.px
    overflowX = Overflow.hidden
}


val preview: CssStyle = {
    width = 180.px
    height = 120.px
    minHeight = 120.px
    borderRadius = 5.px
    background = Color("#D9D9D9")
}
val previewImage: CssStyle = {
    width = 180.px
    height = 120.px
    borderRadius = 5.px
}

val header: CssStyle = {
    width = 168.px
    display = Display.inline
    overflow = Overflow.hidden
    whiteSpace = WhiteSpace.nowrap
    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 14.px
    lineHeight = 18.px
    color = Color("#282F3E")
}

val description: CssStyle = {
    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 10.px
    lineHeight = 13.px
    maxWidth = 168.px
    maxHeight = 26.px
    overflow = Overflow.hidden
    color = Color("#5D6676")
    alignSelf = AlignSelf.stretch
}