package entities.interactivePicture.elements.controls.button

import online.interactiver.common.interactivepicture.GeometryStyle
import online.interactiver.common.interactivepicture.Point
import online.interactiver.common.interactivepicture.TextStyle

val buttonStyle = GeometryStyle(
    fillColor = "#FFFFFF",
    strokeColor = "#D0D5DD",
    cornerRadius = 5,
    strokeWidth = 1,
    shadowBlur = 2,
    shadowColor = "rgb(16, 24, 40)",
    shadowOffset = Point(0, 2),
    shadowOpacity = 0.1,
    opacity = 1.0
)

val buttonHoverStyle = GeometryStyle(
    fillColor = "#FFFFFF",
    strokeColor = "#597EF7",
    strokeWidth = 1,
    cornerRadius = 5,
    shadowBlur = 2,
    shadowColor = "rgb(16, 24, 40)",
    shadowOffset = Point(0, 2),
    shadowOpacity = 0.1
)

val buttonFocusStyle = GeometryStyle(
    fillColor = "#FFFFFF",
    strokeColor = "#597EF7",
    strokeWidth = 1,
    cornerRadius = 5,
    shadowBlur = 2,
    shadowColor = "rgb(16, 24, 40)",
    shadowOffset = Point(0, 2),
    shadowOpacity = 0.1
)

val buttonTextStyle = TextStyle(
    textColor = "#101828",
    fontFamily = "Arial",
    fontStyle = "700",
    fontSize = 14,
    align = "center",
    padding = 5,
)

val buttonSelectStyle = GeometryStyle(
    fillColor = "#597EF7",
    strokeColor = "#4096ff",
    strokeWidth = 1,
    cornerRadius = 5,
    shadowBlur = 2,
    shadowColor = "rgb(16, 24, 40)",
    shadowOffset = Point(0, 2),
    shadowOpacity = 0.1

)
