package widgets.Exercises.ui.components.Exercise

import antd.Button
import antd.Popover
import csstype.deg
import csstype.integer
import csstype.px
import csstype.rotate
import emotion.react.css
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.autogeneration.LanguageAutoGeneration
import online.interactiver.common.autogeneration.LanguageAutoState
import online.interactiver.common.enums.EAccessType
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import pages.constructor.ui.components.elements.blueFilter
import react.*
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import shared.components.Icon
import shared.components.checkbox.Checkbox
import utils.types.extend
import utils.types.impl
import widgets.ExerciseSettings.components.CustomLogoSettings.iconButton
import widgets.Exercises.moveExerciseInsideFolder
import widgets.Exercises.ui.components.FolderUserActions.FolderUserActions
import widgets.Exercises.ui.components.UserActions.UserActions
import kotlin.js.Date

external interface ExerciseProps : Props {
    var request: LanguageAutoGeneration
    var selected: Boolean?
    var disabled: Boolean?
    var onOpenStatistics: () -> Unit
    var openShareModal: () -> Unit
    var setSelectedRequest: (LanguageAutoState) -> Unit
    var updateExercises: () -> Unit
    var onExerciseClick: (LanguageAutoGeneration) -> Unit
    var onExerciseDoubleClick: ((LanguageAutoGeneration) -> Unit)?
    var hideMenu: Boolean?
    var hideStatistics: Boolean?
    var accessType: EAccessType?
    var onChooseRequest: () -> Unit
    var foldersExploringMode: Boolean?
}

val Exercise = FC<ExerciseProps> { props ->
    val (isMenuOpened, setIsMenuOpened) = useState(false)
    val rowRef = useRef(null) as MutableRefObject<HTMLDivElement>?

    div {
        css(if (props.disabled == true) rowDisabled else if (props.selected == true) rowSelected else row)
        ref = rowRef
        onClick = f@{
            if (props.disabled == true) {
                return@f
            }

            val target = it.target as HTMLElement
            if (target != rowRef?.current && rowRef?.current?.contains(target) == false) {
                return@f
            }

            props.onExerciseClick(props.request)
        }
        onDoubleClick = f@{
            if (props.disabled == true) {
                return@f
            }

            val target = it.target as HTMLElement
            if (target != rowRef?.current && rowRef?.current?.contains(target) == false) {
                return@f
            }

            props.onExerciseDoubleClick?.invoke(props.request)
        }
        div {
            css(state(props.request.parentFolderId != null))
            if (props.request.parentFolderId != null) {
                div {
                    if (props.request.numberInFolder != -1) {
                        css(numberInFolderContainer)
                        div {
                            css(arrows)
                            Button {
                                css(iconButton)
                                onClick = {
                                    GlobalScope.launch {
                                        if (!moveExerciseInsideFolder(props.request.requestId, true)) {
                                            return@launch
                                        }

                                        props.updateExercises()
                                    }
                                }
                                Icon {
                                    css(arrowIcon)
                                    src = "ic_arrow_long_up_56x30.svg"
                                }
                            }
                            Button {
                                css(iconButton.extend {
                                    transform = rotate(180.deg)
                                })
                                onClick = {
                                    GlobalScope.launch {
                                        if (!moveExerciseInsideFolder(props.request.requestId, false)) {
                                            return@launch
                                        }

                                        props.updateExercises()
                                    }
                                }
                                Icon {
                                    css(arrowIcon)
                                    src = "ic_arrow_long_up_56x30.svg"
                                }
                            }
                        }
                        p {
                            css(data(integer(400)))
                            +"${props.request.numberInFolder}."
                        }
                    }
                }
            }
            p {
                css(data(integer(500)).extend {
                    if (props.selected == true) {
                        marginLeft = 10.px
                    }
                })
                +props.request.name
            }
            p {
                css(data(integer(400)))
                +(props.request.contentType ?: "")
            }
            p {
                css(data(integer(400)))
                +props.request.languageLevel
            }
            p {
                css(data(integer(400)))
                if (props.hideStatistics != true && !props.request.isFolder()) {
                    Button {
                        css(statisticsButton)
                        onClick = {
                            props.onOpenStatistics()
                        }
                        Icon {
                            css {
                                hover(blueFilter)
                            }
                            src = "ic_maximize_24x24.svg"
                        }
                    }
                }
            }
            val localTime = Date(props.request.time).toString().split(" ")
            val date = try {
                localTime.subList(1, 4).joinToString(" ")
            } catch (_: IndexOutOfBoundsException) {
                ""
            }
            val time = localTime.getOrNull(4) ?: ""

            div {
                css(timeCss)
                p {
                    css(data(integer(400)))
                    +"$date $time"
                }
            }
            if (props.hideMenu != true) {
                Popover {
                    trigger = "click"
                    align = impl {
                        points = arrayOf("tl", "bl")
                        offset = arrayOf(-20, 6)
                    }
                    arrowPointAtCenter = false
                    content = if (props.request.contentType != "Folder") {
                        createElement(UserActions, impl {
                            request = props.request
                            closePopover = { setIsMenuOpened(false) }
                            openStatistics = props.onOpenStatistics
                            openShareModal = props.openShareModal
                            setSelectedRequest = props.setSelectedRequest
                            isPublic = props.request.isPublic
                            updateExercises = props.updateExercises
                            accessType = props.accessType
                        })
                    } else {
                        createElement(FolderUserActions, impl {
                            folder = props.request.asFolder()
                            onUpdate = {
                                props.updateExercises()
                            }
                            closePopover = { setIsMenuOpened(false) }
                            accessType = props.accessType
                        })
                    }
                    open = isMenuOpened
                    onOpenChange = { newOpen: Boolean ->
                        setIsMenuOpened(newOpen)
                    }
                    Button {
                        css(menu)
                        onClick = { evt: MouseEvent<HTMLButtonElement, *> ->
                            evt.stopPropagation()
                        }
                        Icon {
                            src = if (props.request.isPublic) "ic_more-2_24x24.svg" else "ic_menu_32x32.svg"
                        }
                    }
                }
            }
            if (props.foldersExploringMode != true && props.accessType == EAccessType.PRIVATE) {
                Checkbox {
                    checked = props.request.isChosen
                    onChange = {
                        props.onChooseRequest()
                    }
                    id = "choose_${props.request.requestId}"
                }
            }
        }
    }
}