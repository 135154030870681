package shared.components.editableSound

import antd.Button
import csstype.px
import emotion.react.css
import online.interactiver.common.interactiveexercise.EExerciseUiPatternMetaTag
import pages.constructor.ui.components.elements.blackFilter
import react.*
import react.dom.html.ReactHTML.audio
import react.dom.html.ReactHTML.div
import shared.components.Icon
import utils.types.extend
import widgets.languageAutoTask.ui.components.SlideEditModal.patternMetaTagsEditor.soundShowingMetaTagEditor.SoundShowingMetaTagEditorChoices

external interface EditableSoundProps: Props {
    var soundSrc: String?
    var onEditClick: (() -> Unit)?

    var patternMetaTags: List<EExerciseUiPatternMetaTag>?
    var onPatternMetaTagsChange: ((List<EExerciseUiPatternMetaTag>) -> Unit)?
}

val EditableSound = FC<EditableSoundProps> { props ->
    val (play, pause, audioRef, isAudioPaused) = useAudioListener()

    val patternMetaTags = useMemo(props.patternMetaTags) {
        props.patternMetaTags ?: listOf()
    }

    div {
        css(soundContainer)
        if (!props.soundSrc.isNullOrBlank()) {
            Button {
                css(soundButton)
                Icon {
                    src = if (isAudioPaused) "ic_speaker_20x21.svg" else "ic_pause_44x44.svg"
                }
                onClick = {
                    if (isAudioPaused) {
                        play()
                    } else {
                        pause()
                    }
                }
            }
            audio {
                ref = audioRef
                src = props.soundSrc
            }
            Button {
                css(soundButton.extend {
                    padding = 3.px
                })
                Icon {
                    css(blackFilter)
                    src = "ic_pencil_simple_24x24.svg"
                }
                onClick = props.onEditClick
            }
        }
        props.patternMetaTags?.let {
            Button {
                css(soundButton.extend {
                    padding = 3.px
                })
                Icon {
                    css(blackFilter)
                    src = if (patternMetaTags.contains(EExerciseUiPatternMetaTag.NO_SOUND))
                        "ic_enable_sound_24x24.svg" else "ic_disable_sound_24x24.svg"
                }
                onClick = {
                    val newValue = patternMetaTags.toMutableList()

                    if (patternMetaTags.contains(EExerciseUiPatternMetaTag.NO_SOUND)) {
                        newValue.remove(EExerciseUiPatternMetaTag.NO_SOUND)
                    } else {
                        newValue.add(EExerciseUiPatternMetaTag.NO_SOUND)
                    }

                    props.onPatternMetaTagsChange?.invoke(newValue.toList())
                }
            }
        }
    }
}
