package widgets.Exercises.ui.components.ManageAccessModal

import Modal
import antd.Button
import antd.Input
import app.useAppDispatch
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.accessgroupuser.AccessGroupUserByEmail
import online.interactiver.common.accessgroupuser.AccessGroupUserWithEmail
import online.interactiver.common.accessgroupuser.Role
import online.interactiver.common.autogeneration.SliderAutoGenerationFolder
import react.FC
import react.Props
import react.dom.html.InputType
import react.dom.html.ReactHTML.div
import react.useEffect
import react.useState
import shared.components.header.Header
import shared.components.selectWithLabel.SelectWithLabel
import widgets.Exercises.ui.components.ManageAccessModal.components.AllowedUsers
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoTask.ui.components.SlideEditModal.cancelButton

external interface ManageAccessModalProps : Props {
    var isOpen: Boolean
    var closeModal: () -> Unit
    var folder: SliderAutoGenerationFolder
}

val ManageAccessModal = FC<ManageAccessModalProps> { props ->
    val dispatch = useAppDispatch()

    val (users, setUsers) = useState<List<AccessGroupUserWithEmail>>()
    val (accessGroupId, setAccessGroupId) = useState<Int>()
    val (userEmail, setUserEmail) = useState("")
    val (role, setRole) = useState(Role.AUTHOR.get)

    val folderId = props.folder.id

    useEffect(folderId, props.isOpen) {
        if (!props.isOpen) {
            return@useEffect
        }

        GlobalScope.launch {
            val accessGroupIdResponse = getAccessGroupIdByFolderId(folderId)
            if (accessGroupIdResponse.data == null) {
                dispatch(OpenErrorModal("This folder can't be accessed"))
                props.closeModal()
                return@launch
            }

            setAccessGroupId(accessGroupIdResponse.data)
            val usersResponse = getAllowedUsersByAccessGroupId(accessGroupIdResponse.data)
            if (usersResponse.data == null) {
                dispatch(OpenErrorModal("No access to look allowed users"))
                props.closeModal()
                return@launch
            }

            setUsers(usersResponse.data)
        }
    }

    Modal {
        open = props.isOpen
        onCancel = { props.closeModal() }
        footer = null
        zIndex = 100

        div {
            css(container)
            div {
                css(newUsersContainer)
                Header {
                    text = "Add user"
                }
                Input {
                    value = userEmail
                    autoComplete = "email"
                    type = InputType.email
                    onInput = { setUserEmail(it.currentTarget.value) }
                    placeholder = "Email"
                }

                SelectWithLabel {
                    label = "Role"
                    value = role
                    options = Role.getValuesWithoutStudent()
                    onChange = {
                        setRole(it)
                    }
                }
                div {
                    css(buttonsContainer)
                    Button {
                        css(cancelButton)
                        onClick = {
                            props.closeModal()
                        }
                        +"Cancel"
                    }
                    Button {
                        css(generateButton)
                        onClick = f@{
                            if (accessGroupId == null) {
                                props.closeModal()
                                return@f
                            }

                            dispatch(StartModalLoading("Adding access..."))
                            GlobalScope.launch {
                                val response = addAllowedUserByAccessGroupId(
                                    accessGroupId,
                                    listOf(
                                        AccessGroupUserByEmail(userEmail, role)
                                    )
                                )

                                dispatch(EndModalLoading())
                                if (response.data == null) {
                                    dispatch(OpenErrorModal("Error adding new users"))
                                    return@launch
                                }


                                setUsers {
                                    (it?.filter { it.email != userEmail } ?: listOf()) + response.data
                                }
                            }
                        }
                        +"Send invite"
                    }
                }
            }
            AllowedUsers {
                this.users = users
                this.accessGroupId = accessGroupId
                onDelete = { userId ->
                    setUsers { it?.filter { it.userId != userId } }
                }
                ownerId = props.folder.ownerId
            }
        }
    }
}
