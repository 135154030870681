package pages.constructor.ui.components.header.previewShare.previewModal.previewIframe

import app.useAppDispatch
import app.useAppSelector
import emotion.react.css
import entities.backgroundPosScale.selectBgBasedLinearTransformation
import entities.interactivePicture.selectGapPuzzles
import entities.interactivePicture.selectInteractivePicture
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.browser.document
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import makeGenerateAndDownloadInteractivePictureRequest
import online.interactiver.common.export.ExportData
import online.interactiver.common.utils.export
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLIFrameElement
import pages.constructor.ui.components.header.previewShare.previewModal.PreviewModalContext
import react.*
import react.dom.html.ReactHTML.div
import utils.types.injectStyle

val PreviewIframe = FC<Props> {
    val modalContext = useContext(PreviewModalContext)
    val interactivePicture = useAppSelector(selectInteractivePicture)
    val linearTransformation = useAppSelector(selectBgBasedLinearTransformation)
    val gapsAmount = useAppSelector(selectGapPuzzles)?.size

    val iframeContainerRef = useRef<HTMLDivElement>()

    val dispatch = useAppDispatch()

    useEffect(modalContext.isOpen, interactivePicture) {
        if (modalContext.isOpen != true) {
            return@useEffect
        }

        dispatch(StartModalLoading("Your interactive picture is being prepared for preview"))
        val interactiveToShow = interactivePicture.clone(linearTransformation).export(ExportData(gapsAmount))
        interactiveToShow.advancedSettings.analyticsSettings.enableMetrics = false
        MainScope().launch {
            val generatedContent = makeGenerateAndDownloadInteractivePictureRequest(interactiveToShow)

            // iframe with blob as src is not loading js scripts,
            // so we need to use this workaround
            // Credit: https://stackoverflow.com/questions/29667922/set-a-blob-as-the-src-of-an-iframe
            val iframeContainer = iframeContainerRef.current
            val iframe = document.createElement("iframe") as HTMLIFrameElement
            iframe.id = "preview-iframe"
            iframe.injectStyle(iFrame)
            iframe.src = "about:blank"
            iframe.onload = { dispatch(EndModalLoading()) }
            iframeContainer?.innerHTML = ""
            iframeContainer?.appendChild(iframe)

            // Chrome will use document and FireFox will use contentWindow
            val iframeDoc = iframe.contentWindow?.document ?: iframe.contentWindow
            iframeDoc?.let {
                it.asDynamic().write(generatedContent)
                it.asDynamic().close()
            }
        }

        cleanup {
            val iframe = document.getElementById("preview-iframe")
            iframe?.let { iframeContainerRef.current?.removeChild(iframe) }
        }
    }

    div {
        css(container)
        div {
            css(iFrameContainer)
            ref = iframeContainerRef
        }
    }
}
