package shared.canvas.implementations


import react.FC
import reactkonva.Arrow
import shared.canvas.KonvaElementProps
import shared.canvas.interfaces.getElementBounds


val TransformableArrow = FC<KonvaElementProps> { props ->

    val style = props.style
    val curve = props.data.visibleElement.geometry!!.curve!!
    val start = curve.start!!.absolutePosition!!
    val middle = curve.middle!!.absolutePosition!!
    val end = curve.end!!.absolutePosition!!
    val bounds = props.data.getElementBounds()

    Arrow {
        points = arrayOf(
            (start.x - bounds.x).toDouble() / bounds.width * props.bounds.width,
            (start.y - bounds.y).toDouble() / bounds.height * props.bounds.height,
            (middle.x - bounds.x).toDouble() / bounds.width * props.bounds.width,
            (middle.y - bounds.y).toDouble() / bounds.height * props.bounds.height,
            (end.x - bounds.x).toDouble() / bounds.width * props.bounds.width,
            (end.y - bounds.y).toDouble() / bounds.height * props.bounds.height,
        )

        pointerLength = 10
        pointerWidth = 10
        fill = style?.fillColor
        stroke = style?.fillColor
        strokeWidth = style?.strokeWidth
        dash = style.dash?.toTypedArray()
        tension = 0.5
        pointerAtBeginning = curve.startType != null
        pointerAtEnding = curve.endType != null
    }
}