package utils.localization

// Hebrew translated to Russian
val Library.HebrewRussian: (Literal) -> String
    get() = fun(literal: Literal): String {
        return when(literal) {
            Literal.CHECK_BUTTON_DEFAULT_TEXT -> "[бдика] בדיקה\nПроверить"
            Literal.RESTART_BUTTON_DEFAULT_TEXT -> "[итхул] איתחול\nПерезапустить"
        }
    }
