package utils

import kotlinx.browser.window
import online.interactiver.common.interactivepicture.InteractiveElement
import kotlin.math.max
import kotlin.math.roundToInt

fun List<InteractiveElement>.measureNotCustomHeight(lineWidth: Int): Int = measureNotCustomPuzzlesHeight(this, lineWidth, (window.innerWidth * 0.02).roundToInt(), (window.innerHeight * 0.01).roundToInt())

fun measureNotCustomPuzzlesHeight(puzzles: List<InteractiveElement>, backgroundEndX: Int, widthPadding: Int, heightPadding: Int): Int {
    val backgroundStartX = 0
    var currentFreeSpaceX = 0
    var currentFreeSpaceY = 0
    var newLineSpaceY = 0

    puzzles.forEach {
        if (it.gapPuzzle?.customPuzzleInitialPosition == "1") {
            // skip custom puzzles
            return@forEach
        }
        val objectWidth = it.visibleElement.geometry?.rect?.width ?: 0
        val objectHeight = it.visibleElement.geometry?.rect?.height ?: 0

        if (currentFreeSpaceX + objectWidth > backgroundEndX) { // this line is finished; go to the next
            currentFreeSpaceY = newLineSpaceY
            val x = backgroundStartX
            val y = currentFreeSpaceY
            currentFreeSpaceX = x + objectWidth + widthPadding
            newLineSpaceY = y + objectHeight + heightPadding
        } else { // continue current line
            val x = currentFreeSpaceX
            val y = currentFreeSpaceY
            currentFreeSpaceX = x + objectWidth + widthPadding
            newLineSpaceY = max(newLineSpaceY, y + objectHeight + heightPadding); // if objects has different heights such solution might be not the best
        }
    }

    return newLineSpaceY
}