package widgets.StudentDuoContent

import kotlinx.browser.document
import react.useEffect

fun useFocusIf(focused: Boolean, id: String){
    useEffect(focused) {
        if (!focused) return@useEffect

        document.getElementById(id)?.asDynamic().focus()
    }
}
