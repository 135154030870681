package pages.constructor.ui.components.advancedSettings.scoredPointsSettings

import csstype.*
import utils.types.CssStyle

val settingUnderHeader: CssStyle = {
    fontFamily = string("\"Arial\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = FontWeight.normal
    fontSize = 14.px
    lineHeight = 130.pct
    marginBottom = 8.px
    color = Color("#5D6676")
}
val selectStyle: CssStyle = {
    width = 80.px
    height = 40.px
    borderRadius = 5.px
    paddingTop = 5.px

}
val icon: CssStyle = {
    width = 24.px
    height = 24.px

}

val inputNumber: CssStyle = {
    width = 55.px
    height = 33.px
    paddingTop = important(2.px)
    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 12.px
    lineHeight = 130.pct
    color = Color("#101828")
}
val container: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 6.px
}
val textContainer: CssStyle = {
    borderRadius = 5.px
    border = Border(0.7.px, LineStyle.solid, Color("#D0D5DD"))
    display = Display.flex
    width = 100.px
    padding = Padding(5.px, 2.px, 5.px, 10.px)
    alignItems = AlignItems.center
    gap = 10.px


}