package entities.interactivePicture.elements.editors.correctAnswerPositionEditor

import antd.Option
import antd.Select
import app.useAppDispatch
import app.useAppSelector
import builders.enums.EInteractiveType
import emotion.react.css
import entities.interactivePicture.elements.controls.inputs.SetCorrectAnswerPosition
import entities.interactivePicture.elements.controls.inputs.SetCorrectAnswerPositionForInputs
import entities.interactivePicture.elements.editors.checkmarkEditor.container
import entities.interactivePicture.elements.editors.checkmarkEditor.selector
import entities.interactivePicture.elements.editors.checkmarkEditor.text
import entities.interactivePicture.selectElementsUnderSelectionRectangle
import entities.interactivePicture.selectFocusedElement
import enums.EButtonTypes
import enums.ECorrectAnswerPosition
import react.FC
import react.Props
import react.dom.html.ReactHTML
import shared.canvas.interfaces.getValueForEditor
import shared.canvas.interfaces.shouldDrawEditor

val CorrectAnswerPositionEditor = FC<Props> {
    val focused = useAppSelector(selectFocusedElement)
    val elementsUnderSelectionRectangle = useAppSelector(selectElementsUnderSelectionRectangle)
    val dispatch = useAppDispatch()

    if (!shouldDrawEditor(focused, elementsUnderSelectionRectangle) {element -> element.type == EInteractiveType.INPUT_INTERACTIVE.text}) {
        return@FC
    }

    val value = getValueForEditor(focused, elementsUnderSelectionRectangle) { element -> element.input!!.correctAnswerPosition }

    ReactHTML.div {
        css(text)
        +"Correct answer position"
    }
    ReactHTML.div {
        css(container)
        Select {
            css(selector)
            this.value = value
            showArrow = true
            onSelect = { value, _ ->
                if (focused != null) {
                    dispatch(SetCorrectAnswerPosition(focused.identifier.id!!, value))
                } else {
                    dispatch(SetCorrectAnswerPositionForInputs(elementsUnderSelectionRectangle.map { it.identifier.id!! }.toTypedArray(), value))
                }
            }
            id = EButtonTypes.CORRECT_ANSWER_POSITION_SELECTOR.value
            ECorrectAnswerPosition.values().forEach {
                Option {
                    this.value = it.value
                }
            }
        }
    }
}