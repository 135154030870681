package pages.duoSettings.notifications

import antd.Button
import emotion.react.css
import react.VFC
import react.createElement
import react.router.useNavigate
import utils.types.impl
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton
import widgets.Notifications.ui.ManageNotifications

val Notifications = VFC {
    val navigate = useNavigate()
    ManageNotifications {
        buttons = createElement(
            Button,
            impl {
                css(newTaskButton)
                onClick = {
                    navigate(-1)
                }
                children = "Back"
            }
        )
    }
}
