package pages.constructor.ui.components.header.previewShare.previewModal

import csstype.*
import react.CSSProperties
import utils.types.CssStyle
import utils.types.impl

val modal: CSSProperties = impl {
    padding = Padding(10.px, 6.px)
}

val globContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    justifyContent = JustifyContent.stretch
    alignItems = AlignItems.stretch
}

val contentContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.stretch
    alignItems = AlignItems.start
    gap = 32.px
    height = 100.vh.minus(190.px)
}

val backToEditorButton: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
    gap = 12.px

    marginBottom = 30.px
    padding = 0.px

    fontFamily = string("\"Arial\", system-ui")
    fontSize = 14.px
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    lineHeight = 20.px
    color = Color("#282F3E")

    alignSelf = AlignSelf.start

    boxShadow = None.none
    border = None.none

    hover {
        color = important(Color("#282F3E"))
    }
}

val interactiveHeader: CssStyle = {
    marginBottom = 19.px

    fontFamily = string("\"Arial\", system-ui")
    fontSize = 18.px
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    lineHeight = 130.pct
    color = Color("#282F3E")
}


val alert: CssStyle = {
    borderTopRightRadius = 0.px
    borderBottomRightRadius = 0.px
    borderRight = None.none
    width = "fit-content".unsafeCast<Width?>()
}

val alertsContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    justifyContent = JustifyContent.center
    alignItems = AlignItems.end
    overflow = Overflow.hidden
    gap = 6.px
    position = Position.absolute
    top = 80.px
    zIndex = integer(1000)
    right = 0.px
}

