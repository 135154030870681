package entities.interactivePicture.elements.frames

import online.interactiver.common.interactivepicture.GeometryStyle

val frameAreaStyle = GeometryStyle(
    opacity = 1.0,
    cornerRadius = 5,
    strokeColor = "#1C274C",
    strokeWidth = 2,
    dash = mutableListOf(5.0, 3.0),
)

val frameRectStyle = GeometryStyle(
    opacity = 1.0,
    cornerRadius = 5,
    strokeColor = "#1C274C",
    strokeWidth = 2,
    fillColor = "#F0F0F9"
)
