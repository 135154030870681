package pages.constructor.ui.components.editors.interactivityEditor

import app.useAppSelector
import builders.enums.EInteractiveType
import entities.interactivePicture.drag.ui.tools.CutFromBackgroundEditor
import entities.interactivePicture.elements.controls.button.ui.editor.CheckboxStateEditor
import entities.interactivePicture.elements.controls.inputs.ui.editor.CaseAndWhiteSpaceEditor
import entities.interactivePicture.elements.controls.inputs.ui.editor.MaxLengthEditor
import entities.interactivePicture.elements.controls.inputs.ui.editor.PlaceholderEditor
import entities.interactivePicture.elements.controls.selectors.ui.editor.SelectorEditor
import entities.interactivePicture.elements.editors.HeaderForSettings.HeaderForSettings
import entities.interactivePicture.elements.editors.ScoreEditor.enum.EScoreEditorType
import entities.interactivePicture.elements.editors.ScoreEditor.ui.ScoreEditor
import entities.interactivePicture.elements.editors.activeStateEditor.ActiveStateEditor
import entities.interactivePicture.elements.editors.checkmarkEditor.CheckmarkEditor
import entities.interactivePicture.elements.editors.correctAnswerPositionEditor.CorrectAnswerPositionEditor
import entities.interactivePicture.elements.editors.hintPositionEditor.HintPositionEditor
import entities.interactivePicture.elements.editors.hintTextEditor.HintTextEditor
import entities.interactivePicture.elements.editors.phraseTextEditor.PhraseTextEditor
import entities.interactivePicture.elements.editors.soundEditor.SoundEditor
import entities.interactivePicture.elements.gapPuzzles.drags.selectFocusedSelector
import entities.interactivePicture.selectFocusedElement
import enums.EButtonTypes
import react.FC
import react.MutableRefObject
import react.Props

external interface InteractivityEditorProps : Props {
    var stageRef: MutableRefObject<dynamic>
}

val InteractivityEditor = FC<InteractivityEditorProps> { props ->
    val selected = useAppSelector(selectFocusedElement)

    HintTextEditor {}

    PhraseTextEditor {}

//    HintPositionEditor {} //сказали убрать, но может понадобится потом

    ActiveStateEditor {
        id = EButtonTypes.HINT_ACTIVE_SELECTOR.value
        isHint = true
    }

    SoundEditor {}

    ActiveStateEditor {
        id = EButtonTypes.ELEMENT_SHOWING_SELECTOR.value
        isHint = false
    }

    HeaderForSettings{}

    CutFromBackgroundEditor {
        this.stageRef = props.stageRef
    }

    CaseAndWhiteSpaceEditor {}
    PlaceholderEditor {}

    CorrectAnswerPositionEditor {}
    CheckboxStateEditor {}
    CheckmarkEditor {}
    val focusedSelector = useAppSelector(selectFocusedSelector)
    if (selected?.type?.equals(EInteractiveType.SELECTOR_INTERACTIVE.text) == true ||
        focusedSelector != null
    ) {
        SelectorEditor { }
    }

    ScoreEditor {
        type = EScoreEditorType.SCORING
        id = EButtonTypes.SCORING_ADD_BUTTON
    }

    ScoreEditor {
        type = EScoreEditorType.SCORE
        id = EButtonTypes.SCORE_ADD_BUTTON
    }
}