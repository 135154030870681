package widgets.LanguageAutoTask.ui.components.SlideEditModal

import AdvancedResponse
import ApiResponse
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.serialization.json.Json
import online.interactiver.common.autogeneration.FileContentWithGptAnswer
import online.interactiver.common.autogeneration.SaveNewSoundRequest
import utils.getToken

suspend fun generateSliderWithNewSlide(task: String, requestId: Int): FileContentWithGptAnswer? {
    return try {
        val response = jsonClient.post("/api/v2/auto-generation/language-slider/requests/$requestId/update-slide") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(FormDataContent(Parameters.build {
                append("task", task)
            }))
        }

        if (response.status.value != 200) {
            return null
        }

        val advancedResponse = AdvancedResponse(response.bodyAsText(), response.status.value)
        Json.decodeFromString(advancedResponse.content)
    } catch (_: ResponseException) {
        null
    }
}

suspend fun saveNewSound(newSoundSrc: String, word: String): Boolean {
    return try {
        val response = jsonClient.post("api/v2/dictionaries/language-auto") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(SaveNewSoundRequest(newSoundSrc, word))
        }

        response.status.value == 200
    } catch (_: ResponseException) {
        false
    }
}

suspend fun savePictureRequest(pictureBase64: String): ApiResponse<String> {
    return try {
        val response = jsonClient.post("api/v1/pictures-hashing") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(FormDataContent(Parameters.build {
                append("picture", pictureBase64)
            }))
        }

        if (response.status.value != 200) {
            return ApiResponse(null, response.status.value)
        }

        ApiResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}
