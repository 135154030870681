package pages.constructor.ui.components.header.templates


import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import online.interactiver.common.api.TemplatesModalWindowData

fun getTemplatesConfig(json: String): TemplatesModalWindowData {
    val data = Json{ ignoreUnknownKeys = true }.decodeFromString<TemplatesModalWindowData>(json)
    return data
}