package app.appState

import antd.Button
import app.useAppDispatch
import app.useAppSelector
import builders.enums.EElementGroup
import builders.enums.EFrameType
import builders.enums.ELineType
import csstype.minus
import csstype.plus
import csstype.px
import csstype.s
import emotion.react.css
import entities.backgroundPosScale.selectBackgroundMargin
import entities.backgroundPosScale.selectCanvasContainerHeight
import entities.interactivePicture.background.selectCanvasHeight
import entities.interactivePicture.elements.controls.selectors.ui.editor.saveButton
import entities.interactivePicture.elements.gapPuzzles.drags.*
import entities.selectedElement.SelectElement
import online.interactiver.common.interactivepicture.*
import org.w3c.dom.HTMLButtonElement
import pages.constructor.ui.components.elements.ElementsTabs
import pages.constructor.ui.components.viewers.ViewersTabs
import react.*
import redux.RAction
import utils.inject
import utils.structures.Position

class OptionEditState(private val optionId: String) : IAppState {
    override fun getAddRectWithText(id: String, position: Position?): RAction {
        return AddRectWithTextToOption(optionId, id, position)
    }

    override fun getAddRectWithImage(id: String, position: Position?, base64: String, fileName: String, ratio: Double): RAction {
        return AddRectWithImageToOption(optionId, id, position, base64, fileName, ratio)
    }

    override fun getAddMarker(id: String, position: Position): RAction {
        return AddMarkerToOption(optionId, id, position)
    }

    override fun getAddLine(id: String, start: Point, end: Point): RAction {
        return AddLineToOption(optionId, id, start, end, ELineType.LINE)
    }

    override fun getAddArrow(id: String, start: Point, end: Point): RAction {
        return AddLineToOption(optionId, id, start, end, ELineType.ARROW_LINE)
    }

    override fun getAddSound(id: String, position: Position?, src: String?, filename: String?): RAction {
        return AddSoundToOption(optionId, id, position, src, filename)
    }

    override fun getAddFrameRect(id: String, position: Position?): RAction {
        return AddFrameToOption(optionId, id, position, EFrameType.RECT)
    }

    override fun setScoreData(elementID: String, index: Int, score: ScoreData): RAction {
        return SetOptionScoreData(optionId, elementID, index, score)
    }

    override fun setScoreCondition(elementID: String, index: Int, condition: ScoreCondition): RAction {
        return SetOptionScoreCondition(optionId, elementID, index, condition)
    }

    override fun addScoreData(elementID: String): RAction {
        return AddOptionScoreData(optionId, elementID)
    }

    override fun addScoreCondition(elementID: String): RAction {
        return AddOptionScoreCondition(optionId, elementID)
    }

    override fun deleteScoreData(elementID: String, index: Int): RAction {
        return DeleteOptionScoreData(optionId, elementID, index)
    }

    override fun deleteScoreCondition(elementID: String, index: Int): RAction {
        return DeleteOptionScoreCondition(optionId, elementID, index)
    }

    override fun getAddFrameArea(id: String, position: Position?): RAction {
        return AddFrameToOption(optionId, id, position, EFrameType.AREA)
    }

    override fun getTransformElement(id: String, geometry: Geometry): RAction {
        return TransformElementOfOption(optionId, id, geometry)
    }

    override fun getTransformElementCurve(id: String, curve: Curve): RAction {
        return TransformElementCurveOfOption(optionId, id, curve)
    }

    override fun getSetVisibleElementText(id: String, text: String): RAction {
        return SetVisibleElementTextOfOption(optionId, id, text)
    }

    override fun getSetVisibleElementRenderView(id: String, base64: String): RAction {
        return SetVisibleElementRenderViewOfOption(optionId, id, base64)
    }
    override fun getSetElementsHorizontalAlignForElements(ids: Array<String>, position: Int, flag: String ): RAction {
        return SetElementsHorizontalAlignForElementsOfOption(ids[0], position, flag)
    }
    override fun getSetElementsVerticalAlignForElements(ids: Array<String>, position: Int, flag: String ): RAction {
        return SetElementsVerticalAlignForElementsOfOption(ids[0], position, flag)
    }
    override fun getSetElementsSpaceBetweenHorizontal(id: String, offset: Int): RAction {
        return SetElementsSpaceBetweenHorizontalOfOption(id, offset)
    }
    override fun getSetElementsSpaceBetweenVertical(id: String, offset: Int): RAction {
        return SetElementsSpaceBetweenVerticalOfOption(id, offset)
    }

    override fun getSetHintText(id: String, text: String): RAction {
        return SetElementHintTextOfOption(optionId, id, text)
    }

    override fun getSetHintRect(id: String, rect: Rect): RAction {
        return SetHintRectOfOption(optionId, id, rect)
    }

    override fun getSetHintVerticalAlign(id: String, align: String?): RAction {
        return SetElementHintVerticalAlignOfOption(optionId, id, align)
    }

    override fun getMoveSelectedElements(ids: Array<String>, direction: String): RAction {
        return MoveSelectedElementsOfOption(optionId, ids, direction)
    }

    override fun getRemoveElement(id: String): RAction {
        return RemoveElementOfOption(optionId, id)
    }

    override fun getCopyElement(id: String, newId: String): RAction {
        return CopyElementOfOption(optionId, id, newId)
    }

    override fun getSetFillColor(id: String, color: String): RAction {
        return SetFillColorOfOption(optionId, id, color)
    }

    override fun getSetFillColorForElements(ids: Array<String>, color: String): RAction {
        return SetFillColorOfOption(optionId, ids[0], color) //это никогда не вызовется, у опций селеткора нет групп
    }

    override fun getSetFillOpacity(id: String, opacity: Double, preventHistoryUpdate: Boolean): RAction {
        return SetFillOpacityOfOption(optionId, id, opacity, preventHistoryUpdate)
    }
    override fun getSetFillOpacityForElements(ids: Array<String>, opacity: Double, preventHistoryUpdate: Boolean): RAction {
        return SetFillOpacityOfOption(optionId, ids[0], opacity, preventHistoryUpdate) //это никогда не вызовется, у опций селеткора нет групп
    }

    override fun getSetBorderColor(id: String, color: String): RAction {
        return SetBorderColorOfOption(optionId, id, color)
    }
    override fun getSetBorderColorForElements(ids: Array<String>, color: String): RAction {
        return SetBorderColorOfOption(optionId, ids[0], color)
    }

    override fun getSetBorderWidth(id: String, width: Int): RAction {
        return SetBorderWidthOfOption(optionId, id, width)
    }
    override fun getSetBorderWidthForElements(ids: Array<String>, width: Int): RAction {
        return SetBorderWidthOfOption(optionId, ids[0], width)
    }

    override fun getSetBorderDash(id: String, dash: Array<Double>?): RAction {
        return SetBorderDashOfOption(optionId, id, dash)
    }

    override fun getSetBorderDashForElements(ids: Array<String>, dash: Array<Double>?): RAction {
        return SetBorderDashOfOption(optionId, ids[0], dash)
    }

    override fun getSetTextColor(id: String, color: String): RAction {
        return SetTextColorOfOption(optionId, id, color)
    }
    override fun getSetTextColorForElements(ids: Array<String>, color: String): RAction {
        return SetTextColorOfOption(optionId, ids[0], color)
    }

    override fun getSetTextHorizontalAlign(id: String, align: String): RAction {
        return SetTextHorizontalAlignOfOption(optionId, id, align)
    }
    override fun getSetTextHorizontalAlignForElements(ids: Array<String>, align: String): RAction {
        return SetTextHorizontalAlignOfOption(optionId, ids[0], align)
    }

    override fun getSetTextVerticalAlign(id: String, align: String): RAction {
        return SetTextVerticalAlignOfOption(optionId, id, align)
    }
    override fun getSetTextVerticalAlignForElements(ids: Array<String>, align: String): RAction {
        return SetTextVerticalAlignOfOption(optionId, ids[0], align)
    }

    override fun getSetTextFontSize(id: String, size: Int, preventHistoryUpdate: Boolean): RAction {
        return SetTextFontSizeOfOption(optionId, id, size, preventHistoryUpdate)
    }
    override fun getSetTextFontSizeForElements(ids: Array<String>, size: Int, preventHistoryUpdate: Boolean): RAction {
        return SetTextFontSizeOfOption(optionId, ids[0], size, preventHistoryUpdate)
    }
    override fun getSetTextPadding(id: String, size: Int, preventHistoryUpdate: Boolean): RAction {//пока это всё не вызывается, но если мы когда-нибудь дойдем до групп в опциях, то тут вообще не те функции вызываются
        return SetTextPaddingOfOption(optionId, id, size, preventHistoryUpdate)
    }
    override fun getSetTextPaddingForElements(ids: Array<String>, size: Int, preventHistoryUpdate: Boolean): RAction {
        return SetTextPaddingOfOption(optionId, ids[0], size, preventHistoryUpdate)
    }
    override fun getSetTextStyle(id: String, style: String): RAction {
        return SetTextStyleOfOption(optionId, id, style)
    }
    override fun getSetTextStyleForElements(ids: Array<String>, style: String): RAction {
        return SetTextStyleOfOption(optionId, ids[0], style)
    }
    override fun getSetTextDecoration(id: String, style: String): RAction {
        return SetTextDecorationOfOption(optionId, id, style)
    }
    override fun getSetTextDecorationForElements(ids: Array<String>, style: String): RAction {
        return SetTextDecorationOfOption(optionId, ids[0], style)
    }
    override fun getSetTextLineHeight(id: String, lineHeight: Double, preventHistoryUpdate: Boolean): RAction {
        return SetTextLineHeightOfOption(optionId, id, lineHeight, preventHistoryUpdate)
    }
    override fun getSetTextLineHeightForElements(ids: Array<String>, lineHeight: Double, preventHistoryUpdate: Boolean): RAction {
        return SetTextLineHeightOfOption(optionId, ids[0], lineHeight, preventHistoryUpdate)
    }
    override fun getSetHintTextFontSize(id: String, size: Int, preventHistoryUpdate: Boolean): RAction {
        return SetHintTextFontSizeOfOption(optionId, id, size, preventHistoryUpdate)
    }
    override fun getSetHintTextFontSizeForElements(ids: Array<String>, size: Int, preventHistoryUpdate: Boolean): RAction {
        return SetHintTextFontSizeOfOption(optionId, ids[0], size, preventHistoryUpdate)
    }
    override fun getSetHintFontStyle(id: String, style: String): RAction {
        return SetHintFontStyleOfOption(optionId, id, style)
    }
    override fun getSetHintFontStyleForElements(ids: Array<String>, style: String): RAction {
        return SetHintFontStyleOfOption(optionId, ids[0], style)
    }
    override fun getSetHintDecoration(id: String, style: String): RAction {
        return SetHintDecorationOfOption(optionId, id, style)
    }
    override fun getSetHintDecorationForElements(ids: Array<String>, style: String): RAction {
        return SetHintDecorationOfOption(optionId, ids[0], style)
    }
    override fun getSetHintHorizontalAlign(id: String, align: String): RAction {
        return SetHintHorizontalAlignOfOption(optionId, id, align)
    }
    override fun getSetHintHorizontalAlignForElements(ids: Array<String>, align: String): RAction {
        return SetHintHorizontalAlignOfOption(optionId, ids[0], align)
    }
    override fun getSetHintLineHeight(id: String, lineHeight: Double, preventHistoryUpdate: Boolean): RAction {//пока это всё не вызывается, но если мы когда-нибудь дойдем до групп в опциях, то тут вообще не те функции вызываются
        return SetHintLineHeightOfOption(optionId, id, lineHeight, preventHistoryUpdate)
    }
    override fun getSetHintLineHeightForElements(ids: Array<String>, lineHeight: Double, preventHistoryUpdate: Boolean): RAction {
        return SetHintLineHeightOfOption(optionId, ids[0], lineHeight, preventHistoryUpdate)
    }
    override fun getSetHintTextPadding(id: String, size: Int, preventHistoryUpdate: Boolean): RAction {//пока это всё не вызывается, но если мы когда-нибудь дойдем до групп в опциях, то тут вообще не те функции вызываются
        return SetHintTextPaddingOfOption(optionId, id, size, preventHistoryUpdate)
    }
    override fun getSetHintTextPaddingForElements(ids: Array<String>, size: Int, preventHistoryUpdate: Boolean): RAction {
        return SetHintTextPaddingOfOption(optionId, ids[0], size, preventHistoryUpdate)
    }

    override fun getSetElementActiveState(id: String, activeState: String): RAction {
        return SetElementActiveStateOfOption(optionId, id, activeState)
    }

    override fun getSetHintActiveState(id: String, activeState: String): RAction {
        return SetHintActiveStateOfOption(optionId, id, activeState)
    }

    override fun getSetElementCode(id: String, code: String): RAction {
        return SetElementCodeOfOption(optionId, id, code)
    }

    override fun getDefaultTab(): ElementsTabs {
        return ElementsTabs.Static
    }

    override fun getStaticIsDisabled(): Boolean {
        return false
    }

    override fun getControlIsDisabled(): Boolean {
        return true
    }

    override fun getPuzzleIsDisabled(): Boolean {
        return true
    }

    override fun getOtherIsDisabled(): Boolean {
        return true
    }

    override fun getStaticGroup(): EElementGroup {
        return EElementGroup.FOCUSED_OPTION_STATIC
    }

    override fun getDefaultViewerTab(): ViewersTabs {
        return ViewersTabs.Static
    }

    override fun getStaticViewerIsDisabled(): Boolean {
        return false
    }

    override fun getControlViewerIsDisabled(): Boolean {
        return true
    }

    override fun getPuzzleViewerIsDisabled(): Boolean {
        return true
    }

    override fun getOtherViewerIsDisabled(): Boolean {
        return true
    }

    override fun getExtraUnderCanvasDOM(): FC<Props> {
        return FC {
            val dispatch = useAppDispatch()
            val canvasHeight = useAppSelector(selectCanvasHeight)
            val canvasContainerHeight = useAppSelector(selectCanvasContainerHeight)
            val canvasMargin = useAppSelector(selectBackgroundMargin)
            val focusedSelector = useAppSelector(selectFocusedSelector)
            val focusedOption = useAppSelector(selectFocusedOption)

            val btnRef = useRef<HTMLButtonElement>()

            val (buttonWidth, setButtonWidth) = useState(0)
            val (buttonHeight, setButtonHeight) = useState(0)

            useLayoutEffect {
                btnRef.current?.let {
                    setButtonWidth(it.offsetWidth)
                    setButtonHeight(it.offsetHeight)
                }
            }

            Button {
                css {
                    inject(saveButton)
                    margin = 0.px
                    position = csstype.Position.absolute
                    top = (canvasContainerHeight / 2.0).px + ((canvasHeight ?: 0) / 2.0).px - (buttonHeight / 2.0).px + canvasMargin.px
                    transition = 0.s
                }
                ref = btnRef
                +"Save option \"${focusedOption?.text?.simpleText}\""
                onClick = {
                    dispatch(SetAppState(CommonState))
                    dispatch(SelectElement(focusedSelector?.identifier?.id))
                }
            }
        }
    }

    override fun getMessage(): String {
        return "State of editing option $optionId"
    }

    override fun getSelectedOptionId(): String {
        return optionId
    }

    override fun clone(): IAppState {
        return OptionEditState(optionId)
    }

    override fun getSetSyncingPhrase(id: String, isSynced: Boolean): RAction {
        return SetSyncingPhraseOfOption(optionId, id, isSynced)
    }
}
