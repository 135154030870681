package builders.enums

enum class ELineType(val startType: String?, val endType: String?, val elementType: EElementType) {
    LINE(null, null, EElementType.LINE_STATIC),
    ARROW_LINE(null, "pointer", EElementType.ARROW_STATIC);

    fun toEElementType(): EElementType {
        return when (this) {
            LINE -> EElementType.LINE_STATIC
            ARROW_LINE -> EElementType.ARROW_STATIC
        }
    }
}
