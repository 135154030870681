package online.interactiver.common.admin.interactive.phrase

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PhraseRequest(
    @SerialName("phrase")
    val phrase: String?
)
