package pages.constructor.ui.components.header.templates

import csstype.*
import utils.types.CssStyle

val button: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 10.px

    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 14.px
    lineHeight = 130.pct
    color = Color("#FFFFFF")

    border = None.none
    padding = 0.px

    transitionDuration = 0.s // because filter's transition is weird

    hover {
        color = important(Color("#FFFFFF"))
        filter = ("invert(52%) sepia(29%) saturate(5446%) " +
                "hue-rotate(207deg) brightness(96%) contrast(102%)").unsafeCast<FilterFunction>()
        backgroundColor = important(rgba(0, 0, 0, 0.0))
        transitionDuration = 0.s
    }
}
