package features.EnableContinueButton.ui

import csstype.*
import utils.types.CssStyle

val inputsContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
}

val input: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    width = 100.pct
    height = 39.px
    border = None.none
    borderRadius = 8.px
    backgroundColor = Color("#F9FAFC")
}