package features.EnableContinueButton.ui

import antd.Input
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.components.switchWIthLabelAndTooltip.SwitchWithLabelAndTooltip

external interface EnableButtonWithTextAndLinkProps : Props {
    var label: String
    var tooltipHint: String
    var enableButton: Boolean
    var onEnableButtonChange: (Boolean) -> Unit
    var buttonText: String
    var onButtonTextChange: (String) -> Unit
    var buttonTextPlaceholder: String
    var buttonLink: String
    var onButtonLinkChange: (String) -> Unit
    var buttonLinkPlaceholder: String
}

val EnableButtonWithTextAndLink = FC<EnableButtonWithTextAndLinkProps> { props ->
    SwitchWithLabelAndTooltip {
        label = props.label
        tooltipHint = props.tooltipHint
        checked = props.enableButton
        onChange = props.onEnableButtonChange
    }
    if (props.enableButton) {
        div {
            css(inputsContainer)
            Input {
                css(input)
                value = props.buttonText
                placeholder = props.buttonTextPlaceholder
                onChange = { props.onButtonTextChange(it.target.value) }
            }
            Input {
                css(input)
                value = props.buttonLink
                placeholder = props.buttonLinkPlaceholder
                onChange = { props.onButtonLinkChange(it.target.value) }
            }
        }
    }
}