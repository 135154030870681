package widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.FourShortPairsTaskLineEditor

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.start
    gap = 6.px
}

val pairLabel: CssStyle = {
    color = Color("#282F3E")

    fontFamily = string("Montserrat")
    fontWeight = integer(400)
    fontStyle = FontStyle.normal

    marginTop = 5.px
}
