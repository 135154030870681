package widgets.ExerciseSettings

import csstype.*
import utils.types.CssStyle

val widget: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 22.px
    padding = 34.px
    borderRadius = 12.px
    backgroundColor = Color("white")
    boxSizing = BoxSizing.borderBox
    maxWidth = 954.px
    width = 100.pct
    fontFamily = string("\"Montserrat\", system-ui")
}
