package entities.interactivePicture.elements.editors.phraseTextEditor

import csstype.Display
import csstype.FlexDirection
import csstype.px
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    paddingLeft = 20.px
    flexDirection = FlexDirection.column
    gap = 5.px
}