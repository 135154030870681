package pages.constructor.ui.components.header.styleSettings.weightPicker

import csstype.*
import utils.inject
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.flexStart
}

val iconsContainer: CssStyle = {
    display = Display.grid
    gridTemplateColumns = repeat(6, minmax(0.px, 1.fr))
    gap = 10.px
    alignContent = AlignContent.center
    justifyContent = JustifyContent.center
    marginTop = 5.px
}

val button: CssStyle = {
    display = Display.flex
    justifyContent = JustifyContent.center
    alignItems = AlignItems.center
    padding = 0.px
    margin = 0.px
    width = 24.px
    height = 24.px
    borderRadius = 3.px
    border = Border(0.7.px, LineStyle.solid, Color("#D0D5DD"))
}

val buttonActive: CssStyle = {
    inject(button)
    border = Border(1.px, LineStyle.solid, Color("#597EF7"))
}
