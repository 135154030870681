package utils

import kotlinx.browser.window
import online.interactiver.common.duo.courseprogress.PlatformInfo

fun getPlatformInfo(): PlatformInfo {
    return PlatformInfo(
        browserName = window.navigator.appName,
        browserVersion = window.navigator.appVersion,
        platform = window.navigator.platform,
        platformLanguage = window.navigator.language,
        screenWidth = window.screen.width,
        screenHeight = window.screen.height
    )
}
