package entities.interactivePicture.background

import app.StoreState
import app.UndoableAction
import entities.interactivePicture.selectInteractivePicture
import online.interactiver.common.interactivepicture.Background
import online.interactiver.common.interactivepicture.Picture
import online.interactiver.common.interactivepicture.Source
import utils.structures.Point

open class BackgroundAction(
    override val preventHistoryUpdate: Boolean,
    override val preventFutureUpdate: Boolean = false
) : UndoableAction(preventHistoryUpdate, preventFutureUpdate)

data class EditBackground(val base64: String?, val fileName: String?) : BackgroundAction(false)
data class EditBackgroundSize(val width: Int?, val height: Int?) : BackgroundAction(true)

data class EditBackgroundWithRatio(val canvasContainerWidth: Int, val canvasContainerHeight: Int, val ratio: Point) :
    BackgroundAction(false)

data class EditBackgroundWithRatioWithFuture(
    val canvasContainerWidth: Int,
    val canvasContainerHeight: Int,
    val ratio: Point
) : BackgroundAction(true, true)

val editBackgroundWithRatio =
    { state: Background, ratio: Point, canvasContainerWidth: Int, canvasContainerHeight: Int ->
        val newState = state.clone()
        val scale = ratio.x.toDouble() / ratio.y
        val (width, height) = if (canvasContainerWidth.toDouble() / canvasContainerHeight > scale) {
            Point((canvasContainerHeight * scale).toInt(), canvasContainerHeight)
        } else {
            Point(canvasContainerWidth, (canvasContainerWidth / scale).toInt())
        }
        newState.width = width
        newState.height = height
        newState
    }

val BackgroundReducer = { state: Background, action: BackgroundAction ->
    when (action) {
        is EditBackground -> {
            val newState = state.clone()
            newState.picture = if (action.base64 != null)
                Picture(base64 = action.base64, source = Source(fileName = action.fileName)) else null
            newState
        }

        is EditBackgroundSize -> {
            val newState = state.clone()
            newState.width = action.width
            newState.height = action.height
            newState
        }

        is EditBackgroundWithRatio -> editBackgroundWithRatio(
            state,
            action.ratio,
            action.canvasContainerWidth,
            action.canvasContainerHeight
        )

        is EditBackgroundWithRatioWithFuture -> editBackgroundWithRatio(
            state,
            action.ratio,
            action.canvasContainerWidth,
            action.canvasContainerHeight
        )

        else -> state
    }
}

val selectBackgroundStoreField = { state: StoreState -> selectInteractivePicture(state).background }
val selectBackgroundBase64 = { state: StoreState -> selectBackgroundStoreField(state).picture?.base64 }
val selectBackgroundFilename = { state: StoreState -> selectBackgroundStoreField(state).picture?.source?.fileName }
val selectCanvasWidth = { state: StoreState -> selectBackgroundStoreField(state).width }
val selectCanvasHeight = { state: StoreState -> selectBackgroundStoreField(state).height }
val selectCanvasMaxWidth = { state: StoreState -> selectBackgroundStoreField(state).maxWidth }
