package widgets.LanguageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.TextToLearnTaskLineEditor

import csstype.None
import csstype.important
import csstype.px
import utils.types.CssStyle

val textarea: CssStyle = {
    resize = important(None.none)
    height = important(200.px)
}
