package pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewShareTabs.publishTabContent

import csstype.*
import utils.types.CssStyle
import utils.types.extend

val container: CssStyle = {
    height = 100.pct
    display = Display.flex
    flexDirection = FlexDirection.column
}

val publishSection: CssStyle = {
    marginBottom = 28.px
}

val settingsHeader: CssStyle = {
    fontStyle = FontStyle.normal
    fontWeight = FontWeight.normal
    fontSize = 14.px
    lineHeight = 130.pct
    textTransform = TextTransform.uppercase
    color = Color("#98A2B3")
    marginBottom = 20.px
    textTransform = TextTransform.uppercase
}

val inputStyle: CssStyle = {
    marginBottom = 22.px
    height = 35.px
}

val buttonGeneral: CssStyle = {
    width = 100.pct
    height = 40.px
    border = None.none
    borderRadius = 5.px
    padding = Padding(10.px, 16.px)
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.center
    gap = 16.px
    alignItems = AlignItems.center
    fontSize = 14.px
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    lineHeight = 20.px
}

val buttonPrimary = buttonGeneral.extend {
    color = Color("#FFFFFF")
    backgroundColor = Color("#597EF7")
    hover {
        color = important(Color("#FFFFFF"))
        backgroundColor = important(Color("#7B98F9"))
    }
}

val buttonSecondary = buttonGeneral.extend {
    color = Color("#2F54EB")
    backgroundColor = Color("#F4F7FF")
}

val fileIncreaseCheckbox: CssStyle = {
    marginBottom = 22.px
}

val footnote: CssStyle = {
    marginTop = Auto.auto
    color = Color("#5D6676")
}

val whiteFilter: CssStyle = {
    filter = invert(100.pct)
}
