package pages.constructor.ui.components.editors.alignElementsPicker

import csstype.*
import utils.types.CssStyle

val containerAlignElementsForHeader: CssStyle = {
    display = Display.flex
    width = 250.px
    padding = Padding(20.px, 20.px, 0.px, 20.px)
    alignItems = AlignItems.flexStart
    gap = 10.px
}

val alignElementsHeader: CssStyle = {
    color = Color("#101828")
    fontFamily = string("\"Arial\", system-ui")
    fontSize = 12.px
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    lineHeight = 130.pct
}
val buttonForAlignElements: CssStyle = {
    borderRadius = 4.px
    border = None.none
    background = Color("#FFFFFF");
    display = Display.flex
    height = 30.px
    alignItems = AlignItems.center
    gap = 6.px
    alignSelf= AlignSelf.stretch
}
val buttonContainerColumn: CssStyle = {
    display = Display.grid
    gridTemplateColumns = repeat(2, minmax(0.px, 1.fr))
    padding = Padding(10.px, 20.px, 20.px, 20.px)
    width = 220.px
    flexDirection = FlexDirection.column
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.flexStart
    alignSelf= AlignSelf.stretch
    gap = 6.px
}

val iconText: CssStyle = {
    color = Color("#101828")
    fontFamily = string("\"Arial\", system-ui")
    fontSize = 12.px
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    lineHeight = 130.pct
}