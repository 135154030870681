package pages.constructor.ui.components.header.styleSettings.weightPicker

import antd.Button
import emotion.react.css
import pages.constructor.ui.components.header.styleSettings.secondary
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.key
import shared.components.Icon

external interface WeightPickerProps : Props {
    var value: Int?
    var light: Int?
    var medium: Int?
    var heavy: Int?
    var noStokeAllowed: Boolean?
    var onChange: ((Int) -> Unit)?
}

val WeightPicker = FC<WeightPickerProps> { props ->
    div {
        css(container)
        div {
            css(secondary)
            +"Weight"
        }
        div {
            css(iconsContainer)
            class Weight(val weight: Int, val iconSrc: String)
            arrayOf(
                Weight(0, "ic_no_border_24x24.svg"),
                Weight(props.light ?: 1, "ic_border_light_24x24.svg"),
                Weight(props.medium ?: 2, "ic_border_medium_24x24.svg"),
                Weight(props.heavy ?: 3, "ic_border_heavy_24x24.svg"),
            ).map {
                val disabled = it.weight == 0 && props.noStokeAllowed == false
                Button {
                    val value = props.value ?: 0
                    css(if (value == it.weight) buttonActive else button)
                    key = it.iconSrc
                    this.disabled = disabled
                    Icon {
                        src = it.iconSrc
                    }
                    onClick = onClick@{
                        if (value == it.weight) {
                            return@onClick
                        }
                        props.onChange?.invoke(it.weight)
                    }
                }
            }
        }
    }
}