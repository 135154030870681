package pages.constructor.ui.components.header.fileButton

import AdvancedResponse
import antd.Button
import antd.Popover
import antd.Tooltip
import app.*
import createInteractiveJSONRequest
import emotion.react.css
import entities.alert.EAlertTarget
import entities.alert.EAlertType
import entities.alert.ShowAutoCloseAlert
import entities.backgroundPosScale.selectCanvasContainerHeight
import entities.backgroundPosScale.selectCanvasContainerWidth
import entities.interactivePicture.*
import entities.interactivePicture.background.EditBackgroundWithRatio
import entities.interactivePicture.elements.SetHintsAfterImport
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import entities.selectedElement.SelectElement
import enums.EButtonTypes
import kotlinx.browser.document
import kotlinx.browser.window
import online.interactiver.common.interactivepicture.InteractivePicture
import online.interactiver.common.utils.getTimeMillisInULong
import online.interactiver.common.utils.toBase
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.url.URL
import org.w3c.files.FileReader
import org.w3c.files.get
import pages.constructor.ui.components.header.interactiveParams.enums.EParamPopupState
import pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.PREVIEW_ALERT_DURATION_MS
import pages.constructor.ui.components.inputImage.inputImageStyle
import react.*
import react.dom.html.InputType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import shared.canvas.interfaces.getCanvasMaxWidth
import shared.components.Icon
import utils.*
import utils.structures.Point
import utils.types.buildProps
import utils.types.impl
import kotlin.math.min
import kotlin.time.Duration.Companion.minutes

external interface FileButtonProps : Props {
    var setParamPopupState: StateSetter<EParamPopupState>
}

val FileButton = FC<FileButtonProps> { props ->
    val autosavePeriod = 5.minutes

    val dispatch = useAppDispatch()
    val interactivePicture = useAppSelector(selectInteractivePicture)
    val canvasContainerWidth = useAppSelector(selectCanvasContainerWidth)
    val canvasContainerHeight = useAppSelector(selectCanvasContainerHeight)

    val interactiveName = useAppSelector(selectInteractiveName)
    val interactiveGroup = useAppSelector(selectInteractiveGroup)
    val interactiveElements = useAppSelector(selectElements)

    val (open, setOpen) = useState(false)
    val (importOpen, setImportOpen) = useState(false)
    val (exportOpen, setExportOpen) = useState(false)
    val (newInteractivePicture, setNewInteractivePicture) = useState<InteractivePicture?>(null)

    val saveInteractive = useSaveInteractive()
    val copyInteractive = useCopyInteractive()
    val downloadInteractiveHTML = useDownloadInteractiveHTML()
    val metaContext = useContext(ConstructorMetaContext)
    val setPreviewOpen = metaContext.setShowModalPreviewShare

    useEffect(interactivePicture.background) {
        if (newInteractivePicture != null) {
            dispatch(SetInteractivePicture(newInteractivePicture, true))
            setNewInteractivePicture(null)
        }
    }

    val (interactiveLink, setInteractiveLink) = useState<String?>(null)
    val updateInteractiveLink = useUpdateInteractiveLink(EAlertTarget.CONSTRUCTOR, interactiveLink, setInteractiveLink)

    val onError = useCallback(dispatch) {
        dispatch(
            ShowAutoCloseAlert(
                "Some error occurred!",
                EAlertType.ERROR, EAlertTarget.CONSTRUCTOR, PREVIEW_ALERT_DURATION_MS
            )
        )
    }

    val copyLink = useCallback(dispatch) { link: String? ->
        window.navigator.clipboard.writeText(link.toString())
        dispatch(
            ShowAutoCloseAlert(
                "The link has been copied successfully!",
                EAlertType.SUCCESS, EAlertTarget.CONSTRUCTOR, PREVIEW_ALERT_DURATION_MS
            )
        )
    }

    val uuid = extractUUIDFromUrl(window.location.href)

    useAutoSave(autosavePeriod)

    input {
        css(inputImageStyle)
        id = EButtonTypes.UPLOAD_JSON_BUTTON.value
        type = InputType.file
        size = 1
        onChange = fileToInteractivePicture(
            onBeforeLoad = {
                dispatch(StartModalLoading("Importing JSON file, please wait"))
            },
            onLoad = {
                it?.let {
                    val canvasMaxWidth = it.getCanvasMaxWidth()
                    dispatch(
                        EditBackgroundWithRatio(
                            min(canvasMaxWidth, canvasContainerWidth),
                            canvasContainerHeight,
                            Point(it.background.width ?: 16, it.background.height ?: 9)
                        )
                    )
                    setNewInteractivePicture(it)
                    dispatch(SelectElement(null))
                }
                setImportOpen(false)
                dispatch(EndModalLoading())
            }
        )
    }

    input {
        css(inputImageStyle)
        id = EButtonTypes.UPLOAD_HINTS_BUTTON.value
        type = InputType.file
        size = 1
        onChange = f@{ evt ->
            dispatch(StartModalLoading("Importing file hints, please wait"))
            if (evt.target.files?.length != 1) {
                setImportOpen(false)
                dispatch(EndModalLoading())
                return@f
            }

            val file = evt.target.files!![0]!!
            evt.target.value = ""
            val fileReader = FileReader()
            fileReader.readAsText(file)
            fileReader.onload = {
                try {
                    dispatch(SetHintsAfterImport(it.target.result))
                } catch (e: Exception) {
                    console.log(e.message)
                } finally {
                    setImportOpen(false)
                    dispatch(EndModalLoading())
                }
            }
        }
    }

    val exportPopoverContent = FC<Props> {
        Button {
            css(popupButtons)
            id = EButtonTypes.LOAD_JSON_BUTTON.value
            Icon {
                src = "ic_export_json_24x24.svg"
            }
            +"Export .json"
            type = "text"
            onClick = {
                downloadInteractivePictureAsJson(interactivePicture)
                setExportOpen(false)
            }
        }
        Button {
            css(popupButtons)
            id = EButtonTypes.LOAD_HINTS_BUTTON.value
            Icon {
                src = "ic_export_json_24x24.svg"
            }
            +"Export Hints (.txt)"
            type = "text"
            onClick = {
                downloadHintsTextAsTxt(interactiveElements, interactiveName)
                setExportOpen(false)
            }
        }
        Button {
            css(popupButtons)
            id = EButtonTypes.LOAD_HTML_BUTTON.value
            Icon {
                src = "ic_export_json_24x24.svg"
            }
            +"Export HTML"
            type = "text"
            onClick = {
                downloadInteractiveHTML()
                setExportOpen(false)
            }
        }
    }

    val importPopoverContent = FC<Props> {
        Button {
            css(popupButtons)
            Icon {
                src = "ic_import_json_24x24.svg"
            }
            +"Import .json"
            type = "text"
            onClick =
                { (document.getElementById(EButtonTypes.UPLOAD_JSON_BUTTON.value) as HTMLInputElement).click() }
        }
        Button {
            css(popupButtons)
            Icon {
                src = "ic_import_json_24x24.svg"
            }
            +"Import Hints (.txt)"
            type = "text"
            onClick =
                { (document.getElementById(EButtonTypes.UPLOAD_HINTS_BUTTON.value) as HTMLInputElement).click() }
        }
    }

    val popoverContent = FC<Props> {
        div {
            css(container)
            Button {
                css(popupButtons)
                Icon {
                    src = "ic_circle_plus_24x24.svg"
                }
                +"Create new file"
                type = "text"
                onClick = {
                    val url = URL(window.location.protocol + "//" + window.location.host + "/editor")
                    if (interactiveGroup != null && interactiveGroup != 0) {
                        url.searchParams.set("interactive_group_id", interactiveGroup.toString())
                    }
                    window.location.href = url.toString()
                }
            }
            Button {
                css(popupButtons)
                Icon {
                    src = "ic_files_24x24.svg"
                }
                +"Copy file & Open"
                type = "text"
                onClick = a@{
                    if (interactiveGroup == null || interactiveGroup == 0) {
                        props.setParamPopupState(EParamPopupState.OPEN_INPUT_CHECK)
                        return@a
                    }
                    copyInteractive { }
                }
            }
            Popover {
                placement = "rightTop"
                trigger = "hover"
                arrowPointAtCenter = false
                content = createElement(importPopoverContent, buildProps {

                })
                this.open = importOpen
                onOpenChange = { newOpen: Boolean ->
                    setImportOpen(newOpen)
                }
                Button {
                    css(popupButtons)
                    id = EButtonTypes.LOAD_JSON_BUTTON.value
                    Icon {
                        src = "ic_import_json_24x24.svg"
                    }
                    +"Import"
                    Icon {
                        css(rightArrow)
                        src = "ic_arrow_right_24x24.svg"
                    }
                    type = "text"
                }
            }
            Popover {
                placement = "rightTop"
                trigger = "hover"
                arrowPointAtCenter = false
                content = createElement(exportPopoverContent, buildProps {

                })
                this.open = exportOpen
                onOpenChange = { newOpen: Boolean ->
                    setExportOpen(newOpen)
                }
                Button {
                    css(popupButtons)
                    id = EButtonTypes.LOAD_JSON_BUTTON.value
                    Icon {
                        src = "ic_export_json_24x24.svg"
                    }
                    +"Export"
                    Icon {
                        css(rightArrow)
                        src = "ic_arrow_right_24x24.svg"
                    }
                    type = "text"
                }
            }
            Button {
                css(popupButtons)
                Icon {
                    src = "ic_save_json_24x24.svg"
                }
                +"Save"
                type = "text"
                onClick = a@{
                    if (interactiveGroup == null || interactiveGroup == 0) {
                        props.setParamPopupState(EParamPopupState.OPEN_INPUT_CHECK)
                        return@a
                    }

                    saveInteractive { }

                    setOpen(false)
                }
            }
            Button {
                css(popupButtons)
                Icon {
                    src = "ic_file_arrow_up_24x24.svg"
                }
                +"Publish & Get link"
                type = "text"
                onClick = a@{
                    if (uuid.isNullOrBlank()) {
                        saveInteractive {}
                    }
                    updateInteractiveLink(copyLink, onError)
                    setOpen(false)
                }
            }
            Button {
                css(popupButtons)
                Icon {
                    src = "ic_preview_24x24.svg"
                }
                +"Preview & Share"
                type = "text"
                onClick = a@{
                    setPreviewOpen!!(true)
                    setOpen(false)
                }
            }
        }
    }

    Popover {
        trigger = "click"
        align = impl {
            points = arrayOf("tl", "bl")
            offset = arrayOf(-20, 6)
        }
        arrowPointAtCenter = false
        content = createElement(popoverContent, buildProps {

        })
        this.open = open
        onOpenChange = { newOpen: Boolean ->
            setOpen(newOpen)
            if (!newOpen) {
                setImportOpen(false)
                setExportOpen(false)
            }
        }
        Tooltip {
            title = "Main menu"
            placement = "bottomLeft"
            Button {
                css(button)
                id = EButtonTypes.FILE_BUTTON.value
                +"File"
                ghost = true
                type = "text"
            }
        }
    }

}

suspend fun copyInteractive(
    interactivePicture: InteractivePicture,
    interactiveGroup: Int
): AdvancedResponse {
    val interactivePictureCopy = interactivePicture.clone()
    val newTime = getTimeMillisInULong()
    interactivePictureCopy.identifier.name += " - Copy"
    interactivePictureCopy.identifier.setUniqueCodeByName()
    interactivePictureCopy.identifier.id = newTime.toBase()
    val createResponse = createInteractiveJSONRequest(
        interactiveGroup, interactivePictureCopy.identifier.name!!, interactivePictureCopy
    )
    return createResponse
}