package widgets.Exercises.ui.components.StatisticsModal.components.SlidesChart

import emotion.react.css
import kotlinx.js.jso
import online.interactiver.common.statistics.SlideStatisticsResponse
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import recharts.*
import widgets.Exercises.ui.components.StatisticsModal.ECharts
import widgets.Exercises.ui.components.StatisticsModal.components.CustomTooltip.CustomTooltip

external interface SlidesChartProps : Props {
    var usersData: List<SlideStatisticsResponse>
}

external interface ViewsChart {
    var name: String
    var users: Int
    var successfulUsers: Int
}

val SlidesChart = FC<SlidesChartProps> { props ->

    val viewData: Array<ViewsChart> = props.usersData.mapIndexed { index, slide ->
        jso<ViewsChart> {
            name = "${index + 1}"
            users = slide.numberOfViews - slide.numberOfSuccessfulAttempts
            successfulUsers = slide.numberOfSuccessfulAttempts
        }
    }.toTypedArray()
    val slideChartsEnums = listOf(ECharts.SUCCESSFUL_ATTEMPTS, ECharts.VIEWS)

    div {
        css(chartContainer)
        BarChart {
            width = 450
            height = 300
            margin = jso {
                bottom = 10
            }
            this.data = viewData

            CartesianGrid {
                strokeDasharray = "3 3"
            }

            XAxis {
                dataKey = "name"
                allowDecimals = false
                Label {
                    value = "Slide"
                    position = "bottom"
                    offset = -5
                }
            }

            YAxis {
                allowDecimals = false
                Label {
                    value = "Students"
                    position = "insideLeft"
                    offset = 10
                    angle = -90
                }
            }
            Tooltip {
                content = CustomTooltip
            }
            slideChartsEnums.forEach {
                Bar {
                    stackId = "a"
                    dataKey = it.type
                    fill = it.color
                }
            }
        }
        // Не использовал Legend из recharts, потому что там какая-то беда с позиционированием начинается, если есть и легенда,
        // и подпись оси
        div {
            css(legendContainer)
            slideChartsEnums.forEach {
                div {
                    css(legend)
                    span {
                        css(legendColor(it.color))
                    }
                    p {
                        css(legendText(it.color))
                        +it.legendLabel
                    }
                }
            }
        }
    }
}
