package widgets.AdminPanel.ui.components.CreateNewButton

import antd.Button
import emotion.react.css
import pages.constructor.ui.components.elements.whiteFilter
import react.FC
import react.Props
import react.useMemo
import shared.components.Icon

external interface CreateNewButtonProps : Props {
    var onClick: () -> Unit
    var label: String
    var bgColor: String?
    var bgColorHovered: String?
}

val CreateNewButton = FC<CreateNewButtonProps> {
    val bgColor = useMemo(it.bgColor) {
        it.bgColor ?: "#597EF7"
    }

    val bgColorHovered = useMemo(it.bgColorHovered) {
        it.bgColorHovered ?: "#3E62D9"
    }

    Button {
        css(createNewButton(bgColor, bgColorHovered))
        onClick = {
            it.onClick()
        }
        Icon {
            css(whiteFilter)
            src = "ic_plus_32x32.svg"
        }
        +it.label
    }
}