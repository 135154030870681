package entities.interactivePicture.elements.editors.soundEditor.autoSoundButton

import csstype.*
import utils.types.CssStyle

val header: CssStyle = {
    color = Color("#282F3E")
    fontFamily = string("\"Arial\", system-ui")
    fontSize = 18.px
    lineHeight = 23.4.px
    fontWeight = FontWeight.bold
}

val explanation: CssStyle = {
    color = Color("#282F3E")
    fontFamily = string("\"Arial\", system-ui")
    fontSize = 12.px
    lineHeight = 15.6.px
    fontWeight = FontWeight.normal
    marginTop = 14.px
    width = 356.px
    marginBottom = 20.px
}

val subheader: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    color = Color("#101828")
    fontFamily = string("\"Arial\", system-ui")
    fontSize = 14.px
    lineHeight = 18.2.px
    fontWeight = FontWeight.bold
    marginTop = 20.px
    marginBottom = 12.px
}

val row: CssStyle = {
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
    width = 388.px
}

val textarea: CssStyle = {
    width = 406.px
    height = 64.px
    resize = important(None.none)
}

val select: CssStyle = {
    width = 184.px
}

val selectHeader: CssStyle = {
    fontWeight = FontWeight.normal
    fontFamily = string("\"Arial\", system-ui")
    fontSize = 14.px
    lineHeight = 18.9.px
    color = Color("#5D6676")
}

val hint: CssStyle = {
    width = 18.px
    height = 18.px
    textAlign = TextAlign.center
    borderRadius = 50.pct
    border = Border(1.2.px, LineStyle.solid, Color("#494A50"))
    color = Color("#494A50")
    marginLeft = 8.px
}