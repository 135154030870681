package widgets.StudentDuoContent.ui.LanguageSettingsModal

import csstype.*
import utils.types.CssStyle

val button: CssStyle = {
    width = 100.pct
    border = None.none
    boxShadow = None.none
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
    backgroundColor = Color("#d6e4ff")
    padding = Padding(0.px, 20.px)
}

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 20.px
}