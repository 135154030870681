package shared.canvas.implementations

import builders.enums.EInteractiveType
import builders.enums.ESoundBadgeAlign
import builders.enums.ESoundBadgePosition
import online.interactiver.common.interactivepicture.InteractiveElement
import online.interactiver.common.interactivepicture.TextStyle
import react.FC
import react.ref
import reactkonva.Text
import shared.canvas.KonvaElementProps
import shared.canvas.soundWidthOverlappingRect
import shared.components.textArea.getDisabled

fun getElementText(element: InteractiveElement): String {
    return element.visibleElement.text?.simpleText ?: ""
}

fun getTextStyle(element: InteractiveElement): TextStyle {
    return element.visibleElement.text?.style ?: TextStyle()
}

val TEXT_PADDING = 10

val TextComponent = FC<KonvaElementProps> { props ->
    if (getDisabled({ type -> type.usesTextEditor }, props.data)) {
        return@FC
    }

    val text = getElementText(props.data)
    val bounds = props.bounds

    val textStyle = props.textStyle ?: getTextStyle(props.data)
    val padding = textStyle.padding ?: TEXT_PADDING
    val placeHolderText = if (props.data.type == EInteractiveType.INPUT_INTERACTIVE.text){ "Enter answer"} else {"Type text"}
    val soundIconSize = props.data.sound?.iconSize ?: 0

    val textX = if (props.data.sound == null) padding else {
        val soundPosition = ESoundBadgePosition.fromString(props.data.sound?.badgePosition)
        when(soundPosition.align) {
            ESoundBadgeAlign.LEFT -> padding + soundWidthOverlappingRect(soundPosition, soundIconSize)
            ESoundBadgeAlign.RIGHT -> padding
        }
    }
    val textWidth = if (props.data.sound == null) bounds.width - 2 * padding else {
        val soundPosition = ESoundBadgePosition.fromString(props.data.sound?.badgePosition)
        bounds.width - 2 * padding - soundWidthOverlappingRect(soundPosition, soundIconSize)
    }
    Text {
        this.ref = props.textRef
        this.text = if (text == "") placeHolderText else text
        x = textX
        y = padding
        width = textWidth
        height = bounds.height - 2 * padding
        fontSize = textStyle.fontSize
        fontFamily = textStyle.fontFamily
        align = textStyle.align
        verticalAlign = textStyle.verticalAlign
        fill = if (text == "") "grey" else textStyle.textColor
        wrap = "word"
        ellipsis = true
        fontStyle = textStyle.fontStyle
        textDecoration = textStyle.textDecoration
        lineHeight = textStyle.lineHeight ?: 1.0
        onClick = {
            if (props.isUnderSelectionRectangle && !it.evt.shiftKey) {
                it.cancelBubble = true
            }
        }
    }
}
