package pages.studentDuoRoadMap.ui.StudentDuoRoadMap

import csstype.*
import utils.types.CssStyle
import utils.types.shadowArray

val buttonsContainer: CssStyle = {
    display = Display.flex
    width = 100.pct
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
}

val roadmap: CssStyle = {
    position = Position.relative
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 30.px
    height = 75.vh
    overflowY = Auto.auto
    background = "url('${kotlinext.js.require("./images/bubbles.png").default}') center no-repeat".unsafeCast<Background>()
    backgroundSize = BackgroundSize.cover
    padding = Padding(20.px, 0.px)
    boxSizing = BoxSizing.borderBox
    backgroundPositionX = BackgroundPositionX.left
    backgroundPositionY = BackgroundPositionY.bottom
}

val page: CssStyle = {
    width = 100.pct
    maxWidth = 605.px
    boxSizing = BoxSizing.borderBox
    backgroundColor = Color("white")
    borderRadius = 12.px
    boxShadow = shadowArray(
        BoxShadow(0.px, 3.px, 18.px, (-1).px, Color("#32324717")), BoxShadow(0.px, 0.px, 1.px, 0.px, Color("#0C1A4B3D"))
    )
}

val courseName: CssStyle = {
    margin = Margin(0.px, Auto.auto)
    padding = 20.px
    paddingTop = 0.px
    boxSizing = BoxSizing.borderBox
    backgroundColor = Color("#597EF7")
    color = Color("white")
    fontWeight = integer(500)
    fontSize = 1.25.rem
    textAlign = TextAlign.center
}
