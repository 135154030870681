package pages.constructor.ui.components.inputImage

import antd.Tooltip
import csstype.ClassName
import emotion.react.css
import entities.interactivePicture.elements.shared.tools.buttonText
import kotlinx.browser.document
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.MouseEvent
import react.FC
import react.Props
import react.dom.events.ChangeEventHandler
import react.dom.html.InputType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import shared.components.Icon

external interface InputImageProps : Props {
    var id: String? // some page-unique string to make button toggle correct input
    var onChange: ChangeEventHandler<HTMLInputElement>
    var className: ClassName
    var buttonIcon: String
    var buttonText: String?
    var multiple: Boolean
    var tooltipTitle: String?
}

val InputImage = FC<InputImageProps> { props ->
    val inputId = "input-hidden-${props.id ?: (0 until Int.MAX_VALUE).random().toString()}"

    div {
        input {
            css(inputImageStyle)
            id = inputId
            type = InputType.file
            accept = "image/*"
            size = 1
            onChange = props.onChange
            multiple = props.multiple
        }
        Tooltip {
            title = props.tooltipTitle ?: ""
            placement = "topLeft"
            antd.Button {
                className = props.className
                onClick = { evt: MouseEvent ->
                    evt.preventDefault()
                    val input = document.getElementById(inputId)
                    (input as HTMLInputElement).click()
                }

                type = "text"
                if (props.buttonText != null) {
                    div {
                        css(buttonText)
                        +props.buttonText!!
                    }
                }

                Icon {
                    src = props.buttonIcon
                }
            }
        }
    }
}