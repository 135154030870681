package widgets.ExerciseSettings.components.AdvancedSettings

import antd.Option
import antd.Select
import app.useAppDispatch
import csstype.ClassName
import emotion.react.css
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.EGptType
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import widgets.ExerciseSettings.*
import widgets.ExerciseSettings.components.InputGptModelName.InputGptModelName
import widgets.ExerciseSettings.components.SettingLabel.SettingLabel
import widgets.LanguageAutoContent.ui.components.TranslateRequestToTargetLanguageSwitch.TranslateRequestToTargetLanguageSwitch
import widgets.UserProfile.selectTariffPlan

val AdvancedSettings = FC<Props> {
    val gptModel = useSelector(selectGptModel)
    val tariffPlan = useSelector(selectTariffPlan)
    val dispatch = useAppDispatch()

    if (gptModel == null) {
        return@FC
    }

    TranslateRequestToTargetLanguageSwitch {}
    div {
        css(gptModelContainer)
        SettingLabel {
            label = "GPT-model"
            hint = ""
        }
        Select {
            css(ClassName("select-logo-use-type")) {}
            value = EGptType.fromString(gptModel)?.uiValue
            onChange = { value, _ ->
                EGptType.fromUiValue(value)?.let {
                    if (it.type == gptModel) {
                        return@let
                    }

                    dispatch(SetGptModelAction(it.type, EGptType.getDefaultModelName(it.type)))
                    GlobalScope.launch {
                        updateGptModelRequest(it.type)
                    }
                }
            }
            EGptType.entries.forEach {
                Option {
                    value = it.uiValue
                    className = ClassName("select-logo-use-type-option")
                }
            }
        }
    }
    if (tariffPlan?.uppercase() == "BASIC") {
        return@FC
    }

    InputGptModelName {

    }
}