package widgets.Exercises.ui.components.FoldersPath

import emotion.react.css
import online.interactiver.common.autogeneration.SliderAutoGenerationFolder
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useMemo
import shared.components.Icon

external interface FoldersPathProps : Props {
    var rootTitle: String?
    var path: List<SliderAutoGenerationFolder>?
    var onClickFolder: ((Int?) -> Unit)?
}

// Component for visual representation of folders path of List<SliderAutoGenerationFolder>?
val FoldersPath = FC<FoldersPathProps> { props ->
    val rootTitle = useMemo(props.rootTitle) {
        props.rootTitle ?: "My exercises"
    }

    if (props.path == null) {
        return@FC
    }

    div {
        css(container)

        div {
            css(folderName(false))
            +rootTitle
            onClick = {
                props.onClickFolder?.invoke(null)
            }
        }

        props.path?.mapIndexed { index, folder ->
            Icon {
                css(iconRight)
                src = "ic_arrow_right_24x24.svg"
            }
            div {
                css(folderName(index == props.path!!.lastIndex))
                +folder.name
                onClick = {
                    props.onClickFolder?.invoke(folder.id)
                }
            }
        }

    }
}
