package entities.previewAndExport

import online.interactiver.common.admin.interactive.InteractivesGroup
import redux.RAction

open class InteractiveParamAction : RAction
data class SetInteractiveFilenameOnServer(val filename: String?) : InteractiveParamAction()
data class SetInteractiveGroupOnServer(val groupID: Int?) : InteractiveParamAction()
data class SetInteractivesGroups(val groups: Collection<InteractivesGroup>) : InteractiveParamAction()


val InteractiveFilenameReducer = { state: String?, action: InteractiveParamAction ->
    when (action) {
        is SetInteractiveFilenameOnServer -> action.filename
        else -> state
    }
}

val InteractiveGroupReducer = { state: Int?, action: InteractiveParamAction ->
    when (action) {
        is SetInteractiveGroupOnServer -> action.groupID
        else -> state
    }
}

val InteractivesGroupsReducer = { state: Map<Int?, InteractivesGroup>, action: InteractiveParamAction ->
    when(action) {
        is SetInteractivesGroups -> action.groups.associateBy { it.id }
        else -> state
    }
}
