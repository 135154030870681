package online.interactiver.common.interactivepicture

//TODO: hint trigger area? or Popup area?

import kotlinx.serialization.Serializable
import online.interactiver.common.math.LinearTransformation
import online.interactiver.common.utils.Copyable
import online.interactiver.common.utils.clone

@Serializable
data class InteractivePicture(
    var identifier: Identifier,
    var background: Background,

    var style: Style,
    var designCode: String? = null, // design type chosen by user

    // separate lists for elements with separate order levels
    // Elements should be rendered in order from top to bottom list and from first in list to last in each list
    // To fast search by code InteractiveElements could be associated in map by code on application level? Or add specific function to search by code (and type?)
    var frames: MutableList<InteractiveElement>? = null,
    var figuresAndLines: MutableList<InteractiveElement>? = null,
    var controls: MutableList<InteractiveElement>? = null,
    var gapPuzzles: MutableList<InteractiveElement>? = null,
    var others: MutableList<InteractiveElement>? = null,

    var deviceType: String? = "Desktop", // "Desktop", "Mobile"
    var advancedSettings: AdvancedSettings = AdvancedSettings(),
    var lastSavedTime: ULong? = null,
    var taskToGenerateSlide: String? = null
) : Copyable<InteractivePicture> {
    override fun clone(): InteractivePicture {
        return copy(
            identifier = identifier.clone(),
            background = background.clone(),
            style = style.clone(),
            frames = frames?.map { it.clone() }?.toMutableList(),
            figuresAndLines = figuresAndLines?.map { it.clone() }?.toMutableList(),
            controls = controls?.map { it.clone() }?.toMutableList(),
            gapPuzzles = gapPuzzles?.map { it.clone() }?.toMutableList(),
            advancedSettings = advancedSettings.clone(),
            lastSavedTime  = lastSavedTime,
            others = others?.map { it.clone() }?.toMutableList()
        )
    }

    fun clone(linearTransformation: LinearTransformation) = clone().apply {
        applyTransformation(linearTransformation)
    }

    fun applyTransformation(linearTransformation: LinearTransformation) {
        frames?.forEach { it.applyTransformation(linearTransformation) }
        figuresAndLines?.forEach { it.applyTransformation(linearTransformation) }
        controls?.forEach { it.applyTransformation(linearTransformation) }
        gapPuzzles?.forEach { it.applyTransformation(linearTransformation) }
        style.applyTransformation(linearTransformation)
    }
}
