package online.interactiver.common

import io.ktor.http.HttpStatusCode

enum class EBackendError(
    val code: HttpStatusCode,
    val content: String
) {
    UNKNOWN(HttpStatusCode.fromValue(0), "Unknown error"),
    TARIFF_LIMIT(HttpStatusCode.Forbidden, "Tariff plan limit exceeded"),
    UNAUTHORIZED_SCORES(HttpStatusCode.Forbidden, "You should be authorized to receive scores of multiple users")
}