package online.interactiver.common.interactivepicture

import kotlinx.serialization.Serializable
import online.interactiver.common.utils.Copyable

const val DEFAULT_SCORE = "General";

@Serializable
data class ScoreData(
    var name: String,
    var value: Int?
) : Copyable<ScoreData> {
    override fun clone() = copy()

    fun hasValue(): Boolean {
        return value != null;
    }

    fun export(arg: Int) = copy(name = name, value = getValue(arg))

    fun getValue(arg: Int): Int {

        if (hasValue()) {
            return value!!;
        }

        return arg;
    }
}