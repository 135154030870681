package pages.constructor.ui.components.header.styleSettings.stylePicker

import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import emotion.react.css
import antd.Button
import pages.constructor.ui.components.header.styleSettings.weightPicker.button
import pages.constructor.ui.components.header.styleSettings.weightPicker.buttonActive
import react.key
import shared.components.Icon

external interface StylePickerProps : Props {
    var styleValue: String?
    var decorationValue: String?
    var onChangeFontStyle: ((String) -> Unit)?
    var onChangeTextDecoration: ((String) -> Unit)?
}

val StylePicker = FC<StylePickerProps>{ props ->
    div {
        css(container)
        div {
            css(header)
            +"Style"
        }
        div {
            css(buttonContainer)
            class Style (val style: String, val value: String, val iconSrc: String, val onChange: ((String) -> Unit)?)
            arrayOf(
                Style("bold", "700","ic_text_bold_24x24.svg", props.onChangeFontStyle),
                Style ("italic", "italic","ic_text_italic_24x24.svg", props.onChangeFontStyle),
                Style ( "underline", "underline", "ic_text_underline_24x24.svg", props.onChangeTextDecoration)
            ).map{
                Button {
                    val fontValue = props.styleValue ?: "normal"
                    val decorationValue = props.decorationValue ?: "none"
                    css(if (fontValue.contains(it.style) || fontValue.contains(it.value) || decorationValue == it.style) buttonActive else button)
                    key = it.iconSrc
                    Icon {
                        src = it.iconSrc
                    }
                    onClick = onClick@{
                        if (it.onChange == props.onChangeTextDecoration){
                            it.onChange?.invoke(if (decorationValue == it.style) "none" else it.style)
                            return@onClick
                        }
                        if (fontValue.contains(it.style) || fontValue.contains(it.value)){
                            it.onChange?.invoke(fontValue.replace(if (fontValue.contains(it.style)) it.style else it.value, "").trim().ifEmpty { "normal" })
                        } else {
                            it.onChange?.invoke(if (fontValue == "normal") it.style else fontValue + " ${it.style}")
                        }
                    }
                }
            }
        }
    }
}