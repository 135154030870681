package widgets.StudentDuoSlider.ui

import app.useAppDispatch
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import online.interactiver.common.duo.DuoCourse
import online.interactiver.common.duo.EExerciseState
import pages.studentDuo.SetDuoCourses
import pages.studentDuo.SetDuoCoursesWereFetched
import pages.studentDuo.getDuoCoursesRequest
import pages.studentDuo.selectStudentDuoCourses
import react.VFC
import react.redux.useSelector
import react.router.useNavigate
import react.router.useParams
import shared.components.ProtectedRoute.ProtectedRoute

val StudentDuoSliderFromCourse = VFC {
    val pathParams = useParams()
    val navigate = useNavigate()
    val dispatch = useAppDispatch()

    val folderId = pathParams["folder_id"]?.toIntOrNull()

    ProtectedRoute {
        toStudentDuo = true
        StudentDuoSlider {
            showBackButton = true
            onContinueClick = {
                console.log("${window.location.pathname}/words-progress")
                navigate("${window.location.pathname}/words-progress")
            }
            onSolved = f@{
                GlobalScope.launch {
                    val coursesResponse = getDuoCoursesRequest()
                    if (coursesResponse.isNotEmpty()) {
                        dispatch(SetDuoCourses(coursesResponse))
                        localStorage.setItem("duo_courses", Json.encodeToString(coursesResponse))
                    }
                }
            }
        }
    }
}
