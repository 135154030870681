package entities.interactivePicture.elements.controls.ui.tools

import antd.Button
import antd.Tooltip
import app.useAppDispatch
import app.useAppSelector
import app.useBackgroundCenteredPosition
import builders.enums.EElementType
import emotion.react.css
import entities.interactivePicture.elements.controls.inputs.AddInput
import entities.interactivePicture.elements.gapPuzzles.drags.AddButton
import entities.interactivePicture.elements.gapPuzzles.drags.AddSelector
import entities.interactivePicture.elements.shared.tools.button
import entities.interactivePicture.elements.shared.tools.buttonText
import entities.interactivePicture.elements.shared.tools.buttonsGroup
import entities.lastCreatedType.SetLastCreatedType
import entities.lastCreatedType.selectLastCreatedType
import entities.selectedElement.SelectElement
import entities.selectedElement.SelectMultipleElements
import entities.selectedElement.selectElementsIdsUnderSelectionRectangle
import enums.EButtonTypes
import online.interactiver.common.utils.getTimeMillisInBase
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.canvas.interfaces.getPositionWithOffset
import shared.components.Icon

val ControlTools = FC<Props> {
    val bgCenteredPos = useBackgroundCenteredPosition()
    val lastCreatedType = useAppSelector(selectLastCreatedType)
    val elementsIdUnderSelectionRectangle = useAppSelector(selectElementsIdsUnderSelectionRectangle)
    val dispatch = useAppDispatch()

    div {
        css(buttonsGroup)
        Tooltip {
            title = "Click on it to toggle states"
            placement = "topLeft"
            Button {
                css(button)

                id = EButtonTypes.CREATE_BUTTON_BUTTON.value

                div {
                    css(buttonText)
                    +"Button"
                }
                Icon {
                    src = "ic_button_static_24x24.svg"
                }
                onClick = {
                    val position = bgCenteredPos.getPositionWithOffset(lastCreatedType, EElementType.BUTTON_CONTROL)
                    val id = getTimeMillisInBase()
                    dispatch(AddButton(id, position))
                    if (elementsIdUnderSelectionRectangle.isNotEmpty()) {
                        dispatch(SelectMultipleElements(emptyList<String>().toTypedArray()))
                    }
                    dispatch(SelectElement(id))
                    dispatch(SetLastCreatedType(EElementType.BUTTON_CONTROL))
                }
            }
        }
        Tooltip {
            title = "Enter text in a field"
            placement = "topRight"
            Button {
                css(button)

                id = EButtonTypes.CREATE_INPUT_BUTTON.value

                div {
                    css(buttonText)
                    +"Input"
                }
                Icon {
                    src = "ic_input_24x24.svg"
                }
                onClick = {
                    val position = bgCenteredPos.getPositionWithOffset(lastCreatedType, EElementType.INPUT_CONTROL)
                    val id = getTimeMillisInBase()
                    dispatch(AddInput(id, position))
                    if (elementsIdUnderSelectionRectangle.isNotEmpty()) {
                        dispatch(SelectMultipleElements(emptyList<String>().toTypedArray()))
                    }
                    dispatch(SelectElement(id))
                    dispatch(SetLastCreatedType(EElementType.INPUT_CONTROL))
                }
            }
        }
        Tooltip {
            title = "Choose from a drop-down list"
            placement = "topLeft"
            Button {
                css(button)

                id = EButtonTypes.CREATE_SELECTOR_BUTTON.value

                div {
                    css(buttonText)
                    +"Selector"
                }
                Icon {
                    src = "ic_selector_24x24.svg"
                }
                onClick = {
                    val position = bgCenteredPos.getPositionWithOffset(lastCreatedType, EElementType.SELECTOR_CONTROL)
                    val id = getTimeMillisInBase()
                    dispatch(AddSelector(id, position))
                    if (elementsIdUnderSelectionRectangle.isNotEmpty()) {
                        dispatch(SelectMultipleElements(emptyList<String>().toTypedArray()))
                    }
                    dispatch(SelectElement(id))
                    dispatch(SetLastCreatedType(EElementType.SELECTOR_CONTROL))
                }
            }
        }
    }
}
