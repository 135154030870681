package widgets.Exercises.ui.components.FolderUserActions.FolderMovingModal

import Modal
import app.useAppDispatch
import csstype.Auto
import csstype.Padding
import csstype.px
import emotion.react.css
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import io.ktor.http.*
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.css.section
import online.interactiver.common.autogeneration.EExercisesUiContentType
import online.interactiver.common.autogeneration.FoldersAndExercisesRequest
import online.interactiver.common.autogeneration.LanguageAutoGeneration
import online.interactiver.common.enums.EAccessType
import react.FC
import react.Props
import react.dom.html.ReactHTML.section
import react.useEffect
import react.useState
import utils.types.extend
import widgets.Exercises.moveExercise
import widgets.Exercises.moveFolder
import widgets.Exercises.moveFoldersAndExercises
import widgets.Exercises.ui.Exercises
import widgets.Exercises.ui.widget

external interface MovingModalProps : Props {
    var open: Boolean?
    var onClose: (() -> Unit)?
    var generations: List<LanguageAutoGeneration?>
    var onMoved: ((Int?) -> Unit)?
}

val MovingModal = FC<MovingModalProps> { props ->
    val dispatch = useAppDispatch()

    val (pickedFolderId, setPickedFolderId) = useState<Int?>(null)
    val (parentFolderId, setParentFolderId) = useState(props.generations.firstOrNull()?.parentFolderId)

    val (forceUpdate, setForceUpdate) = useState(false)

    useEffect(props.open) {
        if (props.open != true) {
            return@useEffect
        }

        if (props.generations.firstOrNull()?.parentFolderId != parentFolderId) {
            setParentFolderId(props.generations.firstOrNull()?.parentFolderId)
        } else {
            setForceUpdate(true)
        }

        setPickedFolderId(null)
    }

    val generations = props.generations.filterNotNull()

    if (generations.isEmpty()) {
        return@FC
    }

    Modal {
        width = 800.px
        open = props.open
        onCancel = {
            props.onClose?.invoke()
        }
        closable = false
        onOk = {
            props.onClose?.invoke()
            dispatch(StartModalLoading("Moving..."))
            MainScope().launch {
                val response = if (generations.singleOrNull()?.isFolder() == true) {
                    moveFolder(generations.first().requestId, pickedFolderId ?: parentFolderId)
                } else if (generations.singleOrNull() != null) {
                    moveExercise(generations.first().requestId, pickedFolderId ?: parentFolderId)
                } else {
                    moveFoldersAndExercises(
                        generations.map { FoldersAndExercisesRequest(it.isFolder(), it.requestId) },
                        pickedFolderId ?: parentFolderId
                    )
                }

                dispatch(EndModalLoading())

                if (response.value >= 400) {
                    // we do not reset folder name if failed so user can try again without re-entering a name again
                    console.error("Failed to move folder.")
                } else {
                    props.onMoved?.invoke(pickedFolderId ?: parentFolderId)
                }
            }
        }
        section {
            css(widget.extend {
                padding = 0.px
                width = Auto.auto
            })
            Exercises {
                accessType = EAccessType.PRIVATE
                contentType = EExercisesUiContentType.ONLY_FOLDERS
                onFolderClick = {
                    setPickedFolderId(
                        if (it.id == pickedFolderId) null else it.id
                    )
                }
                onFolderDoubleClick = {
                    goToFolder?.invoke(it.id)
                }
                goToFolder = {
                    setParentFolderId(it)
                }

                disabledFolderIds = generations.filter { it.isFolder() }.map { it.requestId }

                selectedFolderId = pickedFolderId
                this.parentFolderId = parentFolderId

                this.forceUpdate = forceUpdate
                onForceUpdated = {
                    setForceUpdate(false)
                }

                hideMenu = true
                foldersExploringMode = true
            }
        }
    }
}
