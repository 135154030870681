package widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.FourShortPairsTaskLineEditor

import emotion.react.css
import online.interactiver.common.autogeneration.TaskLine
import online.interactiver.common.autogeneration.taskLineContent.WordMatchingContent
import react.FC
import react.dom.html.ReactHTML.div
import react.useMemo
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.TaskLineUiEditorWithSpecifiedPatternProps
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.FourShortPairsTaskLineEditor.components.WordMatchPairEditor.WordMatchPairEditor

val FourShortPairsTaskLineEditor = FC<TaskLineUiEditorWithSpecifiedPatternProps> { props ->
    val taskLine = useMemo(props.taskLine, props.slide, callback = {
        try {
            TaskLine(props.taskLine, slide = props.slide)
        } catch(e: TaskLine.Error) {
            null
        }
    })

    val content = useMemo(taskLine, callback = {
        taskLine?.content as? WordMatchingContent
    })

    if (taskLine == null || content == null) {
        return@FC
    }

    div {
        css(container)
        for (i in 0 until 4) {
            WordMatchPairEditor {
                value = content.wordMatchPairs[i]
                onChange = onChange@{
                    val mutablePairs = content.wordMatchPairs.toMutableList()
                    mutablePairs[i] = it
                    content.wordMatchPairs = mutablePairs.toList()
                    if (taskLine.pattern?.canBeBuiltFrom(content.toRawTaskContent(), isOpenAiFormat = false, null) == false) {
                        return@onChange
                    }

                    taskLine.content = content
                    props.onChange?.invoke(taskLine.toRawTaskLine())
                }
                wordLabel = "Word ${i + 1}"
                matchLabel = "Match ${i + 1}"
                maxWordLength = props.maxWordLength
                maxMatchLength = props.maxMatchLength
                onEditSoundClick = props.onEditSoundClick
            }
        }
    }
}
