package entities.interactivePicture.viewer

import app.StoreState
import builders.enums.EInteractiveType
import redux.RAction

open class ViewerFilterAction : RAction
data class SetViewerFilter(val type: EInteractiveType, val value: Boolean) : ViewerFilterAction()

val ViewerFilterReducer = { state: HashMap<EInteractiveType, Boolean>, action: ViewerFilterAction ->
    when (action) {
        is SetViewerFilter -> {
            val result = hashMapOf<EInteractiveType, Boolean>()
            state.forEach { (key, value) -> result.set(key, value) }
            result.set(action.type, action.value)
            result
        }

        else -> state
    }
}

val viewerFilter = { state: StoreState ->
    state.viewerFilter
}