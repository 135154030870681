package pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewShareTabs

import csstype.*
import utils.inject
import utils.types.CssStyle

val tabsContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    borderBottom = Border(1.px, LineStyle.solid, Color("#D7D7D7"))
    paddingLeft = 20.px
    gap = 30.px
}

val tabText: CssStyle = {
    fontSize = 14.px
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    lineHeight = 130.pct

    padding = Padding(11.px, 0.px)
}

val tab: CssStyle = {
    display = Display.flex
    flexGrow = 1.unsafeCast<FlexGrow>()
}

val tabButton: CssStyle = {
    display = Display.flex
    background = Color("#00000000")
    flexDirection = FlexDirection.column
    flexGrow = 1.unsafeCast<FlexGrow>()
    alignItems = AlignItems.center

    whiteSpace = WhiteSpace.normal
    width = 68.px
    height = Auto.auto

    borderWidth = 0.px
    borderRadius = 0.px

    borderTopWidth = 0.px
    borderRightWidth = 0.px
    borderLeftWidth = 0.px
    borderBottomWidth = 1.px
    borderStyle = LineStyle.solid
    borderColor = Color("#000")
    borderRadius = 0.px
}

val headerContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.center
    height = 50.px
    backgroundColor = Color("#FFFFFF")
}

val header: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    borderWidth = 0.px
    fontSize = 14.px
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    lineHeight = 130.pct
}

val defaultHeader: CssStyle = {
    inject(header)
    color = Color("#282F3E")
}

val activeHeader: CssStyle = {
    inject(header)
    color = Color("#597EF7")
}