package utils

import kotlinx.browser.document
import kotlinx.browser.localStorage

fun readCookies(): Map<String, String> {
    val cookieString = document.cookie
    val cookieMap = mutableMapOf<String, String>()

    if (cookieString.isNotEmpty()) {
        val cookies = cookieString.split(";")
        for (cookie in cookies) {
            val parts = cookie.split("=")
            if (parts.size == 2) {
                val name = parts[0].trim()
                val value = parts[1].trim()
                cookieMap[name] = value
            }
        }
    }

    return cookieMap
}

fun deleteCookieByName(name: String) {
    document.cookie = "$name=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

fun getToken(): String {
    val cookies = readCookies()
    val token = cookies.get("access_token")
    return token ?: ""
}

fun checkAuth(): Boolean {
    val token = getToken()

    if (token == null || token == "") {
        return false;
    }

    localStorage.setItem("access_token", token)
    return true;
}

fun getUuid(): String {
    val cookies = readCookies()
    val uuid = cookies["uuid"]
    return uuid ?: ""
}