package widgets.Exercises.ui.components.UploadManuallyButton

import csstype.*
import pages.constructor.ui.components.elements.whiteFilter
import utils.types.CssStyle

val button: CssStyle = {
    backgroundColor = Color("#597EF7")
    color = Color("white")
    borderRadius = 10.px
    gap = 2.px
    height = Auto.auto
    display = Display.flex
    alignItems = AlignItems.center
    alignSelf = AlignSelf.stretch
    hover {
        backgroundColor = Color("#3E62D9")
        color = important(Color("white"))
    }
}

val uploadIcon: CssStyle = whiteFilter
