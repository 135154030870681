package widgets.CourseSettings.ui.components.SliderSettings

import csstype.*
import utils.types.CssStyle

val horizontalContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 18.px
    justifyContent = JustifyContent.spaceBetween
}

val verticalContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 15.px
    width = 60.pct
}
