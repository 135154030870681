package widgets.ExerciseSettings.components.InputWithCustomLink

import csstype.px
import csstype.string
import utils.types.CssStyle

val input: CssStyle = {
    padding = 10.px
    fontFamily = string("\"Montserrat\", system-ui")
}
