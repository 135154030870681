package pages.constructor.ui.components.hintStyleSettings


import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    flexDirection = FlexDirection.column
    gap = 14.px
    display = Display.inlineFlex
    alignItems = AlignItems.flexEnd
    borderRadius = 5.px
    padding = 10.px
}

val primary: CssStyle = {
    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 14.px
    lineHeight = 130.pct
    color = Color("#101828")
    zIndex = integer(2) // to prevent Popover's arrow overlapping
}

val secondary: CssStyle = {
    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 12.px
    lineHeight = 130.pct
    color = Color("#101828")
    zIndex = integer(2) // to prevent Popover's arrow overlapping
}

val primaryLabel: CssStyle = {
    fontStyle = FontStyle.normal
    fontWeight = integer(600)
    fontSize = 14.px
    lineHeight = 130.pct
    color = Color("#282F3E")
    zIndex = integer(2) // to prevent Popover's arrow overlapping
}

val button: CssStyle = {
    display = Display.inlineFlex
    alignItems = AlignItems.center
    gap = 10.px

    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 14.px
    lineHeight = 130.pct
    color = Color("#FFFFFF")

    border = None.none
    padding = 0.px
    marginLeft = 16.px

    transitionDuration = 0.s // because filter's transition is weird

    hover {
        color = important(Color("#FFFFFF"))
        filter = ("invert(52%) sepia(29%) saturate(5446%) " +
                "hue-rotate(207deg) brightness(96%) contrast(102%)").unsafeCast<FilterFunction>()
        backgroundColor = important(rgba(0, 0, 0, 0.0))
        transitionDuration = 0.s
    }
}

val divider: CssStyle = {
    alignSelf = AlignSelf.stretch
    height = 1.px
    backgroundColor = Color("#D0D5DD")
}
val iconsContainer: CssStyle = {
    display = Display.grid
    gridTemplateColumns = repeat(3, minmax(0.px, 1.fr))
    gap = 10.px
    alignContent = AlignContent.center
    justifyContent = JustifyContent.center
    marginTop = 5.px
}