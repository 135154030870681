package entities.interactivePicture.elements.figuresAndLines.lines

import builders.enums.EInteractiveType
import builders.enums.ELineType
import builders.getLineBuilder
import entities.interactivePicture.elements.InteractiveElementAction
import entities.interactivePicture.elements.addElementToList
import online.interactiver.common.interactivepicture.InteractiveElement
import online.interactiver.common.interactivepicture.Point

open class LinesAction : InteractiveElementAction(false)
data class AddLine(val id: String, val start: Point, val end: Point, val type: ELineType) : LinesAction()

fun addLineToList(list: MutableList<InteractiveElement>, id: String, start: Point, end: Point, type: ELineType, toOption: Boolean):
        MutableList<InteractiveElement> {
    val builder = getLineBuilder().setType(EInteractiveType.CONNECTION_INTERACTIVE).setType(type.toEElementType())
        .setCurve(start, type.startType, end, type.endType).setHoverFocusStyling(lineStyle).setID(id)

    if (toOption)
        builder.setType(EInteractiveType.OPTION_CONNECTION_INTERACTIVE)

    val element = builder.build()
    // There is no hint on lines but hint: SimpleElement is non-nullable
    // which states that every(!) element has a hint? Probably development task mistake

    // element.hint = null

    return addElementToList(list, element)
}

val LinesReducer = { state: MutableList<InteractiveElement>, action: LinesAction ->
    when (action) {
        is AddLine -> addLineToList(state, action.id, action.start, action.end, action.type, false)
        else -> state
    }
}

