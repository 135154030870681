package entities.interactivePicture.styles

import app.StoreState
import app.UndoableAction
import entities.interactivePicture.selectInteractivePicture
import online.interactiver.common.interactivepicture.ElementStyle
import online.interactiver.common.interactivepicture.GeometryStyle
import online.interactiver.common.interactivepicture.Style

open class StylesAction(override val preventHistoryUpdate: Boolean) : UndoableAction(preventHistoryUpdate)

data class SetBackgroundColor(val color: String) : StylesAction(false)
data class SetBackgroundBorderColor(val color: String) : StylesAction(false)
data class SetBackgroundBorderWidth(val width: Int) : StylesAction(false)
data class SetBackgroundBorderDash(val dash: Array<Double>?) : StylesAction(false)
data class SetBackgroundOpacity(val opacity: Double, override val preventHistoryUpdate: Boolean) :
    StylesAction(preventHistoryUpdate)
data class SetBackgroundBorderRadius(val borderRadius: Int, override val preventHistoryUpdate: Boolean) : StylesAction(preventHistoryUpdate)

val setBackgroundColor = { state: Style, color: String ->
    val newState = state.clone()
    newState.background = newState.background?.copy(
        usual = newState.background?.usual?.copy(
            fillColor = color
        ) ?: GeometryStyle(
            fillColor = color
        )
    ) ?: ElementStyle(
        usual = GeometryStyle(
            fillColor = color
        )
    )
    newState
}

val setBackgroundBorderColor = { state: Style, color: String ->
    val newState = state.clone()
    newState.background = newState.background?.copy(
        usual = newState.background?.usual?.copy(
            strokeColor = color
        ) ?: GeometryStyle(
            strokeColor = color
        )
    ) ?: ElementStyle(
        usual = GeometryStyle(
            strokeColor = color
        )
    )
    newState
}

val setBackgroundBorderWidth = { state: Style, width: Int ->
    val newState = state.clone()
    newState.background = newState.background?.copy(
        usual = newState.background?.usual?.copy(
            strokeWidth = width
        ) ?: GeometryStyle(
            strokeWidth = width
        )
    ) ?: ElementStyle(
        usual = GeometryStyle(
            strokeWidth = width
        )
    )
    newState
}

val setBackgroundBorderDash = { state: Style, dash: Array<Double>? ->
    val newState = state.clone()
    newState.background = newState.background?.copy(
        usual = newState.background?.usual?.copy(
            dash = dash?.toMutableList()
        ) ?: GeometryStyle(
            dash = dash?.toMutableList()
        )
    ) ?: ElementStyle(
        usual = GeometryStyle(
            dash = dash?.toMutableList()
        )
    )
    newState
}

val setBackgroundOpacity = { state: Style, opacity: Double ->
    val newState = state.clone()
    newState.background = newState.background?.copy(
        usual = newState.background?.usual?.copy(
            opacity = opacity
        ) ?: GeometryStyle(
            opacity = opacity
        )
    ) ?: ElementStyle(
        usual = GeometryStyle(
            opacity = opacity
        )
    )
    newState
}

val setBackgroundBorderRadius = { state: Style, borderRadius: Int ->
    val newState = state.clone()
    newState.background = newState.background?.copy(
        usual = newState.background?.usual?.copy(
            cornerRadius = borderRadius
        ) ?: GeometryStyle(
            cornerRadius = borderRadius
        )
    ) ?: ElementStyle(
        usual = GeometryStyle(
            cornerRadius = borderRadius
        )
    )
    newState
}

val StylesReducer = { state: Style, action: StylesAction ->
    when (action) {
        is SetBackgroundColor -> setBackgroundColor(state, action.color)
        is SetBackgroundBorderColor -> setBackgroundBorderColor(state, action.color)
        is SetBackgroundBorderWidth -> setBackgroundBorderWidth(state, action.width)
        is SetBackgroundBorderDash -> setBackgroundBorderDash(state, action.dash)
        is SetBackgroundOpacity -> setBackgroundOpacity(state, action.opacity)
        is SetBackgroundBorderRadius -> setBackgroundBorderRadius(state, action.borderRadius)
        else -> state
    }
}

val selectBackgroundStyle = { state: StoreState -> selectInteractivePicture(state).style.background?.usual }