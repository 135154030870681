package widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders

import emotion.react.css
import online.interactiver.common.autogeneration.TaskLine
import online.interactiver.common.autogeneration.taskLineContent.QuestionWithAnswersContent
import online.interactiver.common.autogeneration.taskLineContent.TaskLineContent
import online.interactiver.common.interactiveexercise.EExerciseUiPattern
import online.interactiver.common.interactiveexercise.EExerciseUiPatternMetaTag
import online.interactiver.common.interactiveexercise.ExerciseUiPattern
import online.interactiver.common.interactivepicture.InteractivePicture
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useMemo
import shared.components.inputLabelledLimited.DEFAULT_BUILD_PHRASE_RAW_LIMIT
import utils.inject
import widgets.LanguageAutoTask.ui.components.SlideEditModal.patternMetaTagsEditor.soundChecboxMetaTagEditor.SoundCheckboxMetaTagEditor
import widgets.LanguageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.CorrectOrderTaskLineEditor.CorrectOrderFromFourTaskLineEditor.CorrectOrderFromFourTaskLineEditor
import widgets.LanguageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.CorrectOrderTaskLineEditor.CorrectOrderFromThreeTaskLineEditor.CorrectOrderFromThreeTaskLineEditor
import widgets.LanguageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.MarkTrueFalseTaskLineEditor.MarkFourTrueFalseTaskLineEditor.MarkFourTrueFalseTaskLineEditor
import widgets.LanguageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.MarkTrueFalseTaskLineEditor.MarkThreeTrueFalseTaskLineEditor.MarkThreeTrueFalseTaskLineEditor
import widgets.LanguageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.TextToLearnTaskLineEditor.TextToLearnTaskLineEditor
import widgets.languageAutoTask.ui.components.SlideEditModal.patternMetaTagsEditor.radioModeMetaTagEditor.RadioModeMetaTagEditor
import widgets.languageAutoTask.ui.components.SlideEditModal.patternMetaTagsEditor.soundShowingMetaTagEditor.SoundShowingMetaTagEditor
import widgets.languageAutoTask.ui.components.SlideEditModal.patternRadioPicker.PatternRadioPicker
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.BuildSentenceTaskLineEditor.BuildSentenceTaskLineEditor
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.FourShortPairsTaskLineEditor.FourShortPairsTaskLineEditor
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.QuestionWithAnswersEditor.QuestionWithFourAnswersEditor.QuestionWithFourAnswersEditor
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.QuestionWithAnswersEditor.QuestionWithThreeAnswersEditor.QuestionWithThreeAnswersEditor
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.RawTaskLineEditor.RawTaskLineEditor
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.ThreeLongPairsTaskLineEditor.ThreeLongPairsTaskLineEditor

external interface TaskLineUiEditorProps : Props {
    var taskLine: String
    var onChange: ((String) -> Unit)?
    var onPatternChange: ((String) -> Unit)?
    var slide: InteractivePicture?
    var onEditSoundClick: ((String) -> Unit)?
}

external interface WordMatchingUiEditorProps : Props {
    var maxWordLength: Int?
    var maxMatchLength: Int?

    var maxPhraseLengthWithTranslation: Int?
    var maxPhraseLengthWithoutTranslation: Int?
    var maxTranslationLength: Int?
}

external interface TaskLineUiEditorWithSpecifiedPatternProps :
    TaskLineUiEditorProps,
    WordMatchingUiEditorProps

external interface PatternMetaTagsEditorProps : Props {
    var value: List<EExerciseUiPatternMetaTag>?
    var onChange: ((List<EExerciseUiPatternMetaTag>) -> Unit)?
}

typealias TaskLinePreprocessor = (TaskLine) -> TaskLine

/**
 * Bulds a taskLine preprocessor - special workaround for somehow changing the taskLine when the pattern is changed
 * i.e. if we have long_buttons__no_radio -> long_buttons we should leave only one answer BEFORE generation so
 * user will be provided with actual version of slider
 */
fun <U: TaskLineContent> taskLinePreprocessorBuilder(preprocessor: TaskLine.() -> Unit): TaskLinePreprocessor {
    return fun(taskLine: TaskLine): TaskLine {
        val newTaskLine = taskLine
        preprocessor(newTaskLine)
        return taskLine
    }
}

val radioLeaveOneCorrectAnswerPreprocessor = taskLinePreprocessorBuilder<QuestionWithAnswersContent> {
    if (pattern?.metaTags?.contains(EExerciseUiPatternMetaTag.NO_RADIO) == true) return@taskLinePreprocessorBuilder

    try {
        val content = content as QuestionWithAnswersContent

        val indexOfFirstCorrect = content.answers.indexOfFirst { it.isCorrect }
        if (indexOfFirstCorrect < 0) return@taskLinePreprocessorBuilder

        val newAnswers = content.answers.toMutableList()
        newAnswers.forEach { it.isCorrect = false }
        newAnswers[indexOfFirstCorrect].isCorrect = true

        content.answers = newAnswers
    } catch(e: Exception) {
        return@taskLinePreprocessorBuilder
    }
}

enum class TaskLineUiEditor(
    val suitablePatterns: List<EExerciseUiPattern>,
    val reactComponent: FC<TaskLineUiEditorWithSpecifiedPatternProps>,
    val patternMetaTagsEditors: List<FC<PatternMetaTagsEditorProps>> = listOf(),
    val taskLineContentPreprocessors: List<TaskLinePreprocessor> = listOf(),
) {
    WORD_MATCHING_4_SHORT_PAIRS(
        listOf(
            EExerciseUiPattern.FOUR_SHORT_PUZZLES,
            EExerciseUiPattern.FOUR_SHORT_SELECTORS,
            EExerciseUiPattern.FOUR_SHORT_INPUTS
        ),
        FourShortPairsTaskLineEditor,
        listOf(
            SoundShowingMetaTagEditor
        )
    ),
    WORD_MATCHING_3_LONG_PAIRS(
        listOf(
            EExerciseUiPattern.THREE_LONG_PUZZLES,
            EExerciseUiPattern.THREE_LONG_SELECTORS,
            EExerciseUiPattern.THREE_LONG_INPUTS
        ),
        ThreeLongPairsTaskLineEditor,
        listOf(
            SoundShowingMetaTagEditor
        )
    ),
    WORD_MATCHING_QUESTION_WITH_3_ANSWERS(
        listOf(
            EExerciseUiPattern.THREE_BUTTONS,
            EExerciseUiPattern.THREE_BUTTONS_WITH_PICTURE
        ),
        QuestionWithThreeAnswersEditor,
        listOf(
            RadioModeMetaTagEditor,
            SoundCheckboxMetaTagEditor
        ),
        listOf(
            radioLeaveOneCorrectAnswerPreprocessor
        )
    ),
    WORD_MATCHING_QUESTION_WITH_4_ANSWERS(
        listOf(
            EExerciseUiPattern.FOUR_BUTTONS,
            EExerciseUiPattern.FOUR_BUTTONS_WITH_PICTURE
        ),
        QuestionWithFourAnswersEditor,
        listOf(
            RadioModeMetaTagEditor,
            SoundCheckboxMetaTagEditor
        ),
        listOf(
            radioLeaveOneCorrectAnswerPreprocessor
        )
    ),
    BUILD_SENTENCE(
        listOf(
            EExerciseUiPattern.BUILD_SENTENCE_WHOLE,
            EExerciseUiPattern.BUILD_SENTENCE_PUZZLES,
            EExerciseUiPattern.BUILD_SENTENCE_SELECTORS,
            EExerciseUiPattern.BUILD_SENTENCE_INPUTS,
            EExerciseUiPattern.BUILD_SENTENCE_WHOLE_WITH_EXTRA_PUZZLES,
            EExerciseUiPattern.BUILD_SENTENCE_PUZZLES_WITH_EXTRA_PUZZLES
        ),
        BuildSentenceTaskLineEditor
    ),
    TEXT_TO_LEARN(
        listOf(
            EExerciseUiPattern.TEXT_AUDIO_INFO
        ),
        TextToLearnTaskLineEditor
    ),
    MARK_3_TRUE_FALSE(
        listOf(
            EExerciseUiPattern.CHOOSE_THREE_CORRECT,
        ),
        MarkThreeTrueFalseTaskLineEditor
    ),
    MARK_4_TRUE_FALSE(
        listOf(
            EExerciseUiPattern.CHOOSE_FOUR_CORRECT,
        ),
        MarkFourTrueFalseTaskLineEditor
    ),
    CORRECT_ORDER_FROM_THREE(
        listOf(
            EExerciseUiPattern.THREE_GAPS_IN_CORRECT_ORDER
        ),
        CorrectOrderFromThreeTaskLineEditor
    ),
    CORRECT_ORDER_FROM_FOUR(
        listOf(
            EExerciseUiPattern.FOUR_GAPS_IN_CORRECT_ORDER
        ),
        CorrectOrderFromFourTaskLineEditor
    );

    companion object {
        fun findSuitableTaskLineUiBuilder(pattern: EExerciseUiPattern?): FC<TaskLineUiEditorProps> {
            val uiEditor = entries.find { pattern in it.suitablePatterns } ?: return RawTaskLineEditor
            return FC { props ->
                val taskLine = useMemo(props.taskLine, callback = {
                    try {
                        TaskLine(props.taskLine)
                    } catch (e: TaskLine.Error) {
                        null
                    }
                })

                val curPattern = useMemo(taskLine, callback = {
                    taskLine?.pattern
                })

                val patternwiseAdditionalProps = useMemo(curPattern, callback = {
                    curPattern?.type?.getAdditionalProps() ?: { }
                })

                if (taskLine == null) {
                    return@FC
                }

                uiEditor.reactComponent {
                    this.taskLine = props.taskLine
                    onChange = props.onChange
                    onPatternChange = props.onPatternChange
                    slide = props.slide
                    onEditSoundClick = props.onEditSoundClick

                    inject(patternwiseAdditionalProps)
                }
                div {
                    css(radioPickersContainer)
                    if (uiEditor.suitablePatterns.size > 1) {
                        PatternRadioPicker {
                            value = taskLine.pattern?.type
                            onChange = {
                                taskLine.pattern = ExerciseUiPattern(it, taskLine.pattern?.metaTags ?: listOf())
                                props.onPatternChange?.invoke(taskLine.toRawTaskLine())
                            }
                            patterns = uiEditor.suitablePatterns
                            taskLineContent = taskLine.content.toRawTaskContent()
                        }
                    }
                    uiEditor.patternMetaTagsEditors.map {
                        it {
                            value = taskLine.pattern?.metaTags
                            onChange = {
                                var newTaskLine = taskLine
                                newTaskLine.pattern =
                                    newTaskLine.pattern?.type?.let { type -> ExerciseUiPattern(type, it) }

                                for (preprocessor in uiEditor.taskLineContentPreprocessors) {
                                    newTaskLine = newTaskLine?.let { taskLine -> preprocessor(taskLine) }
                                }

                                newTaskLine?.toRawTaskLine()?.let { taskLine -> props.onPatternChange?.invoke(taskLine) }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun EExerciseUiPattern.getAdditionalProps(): TaskLineUiEditorWithSpecifiedPatternProps.() -> Unit =
    {
        maxWordLength = this@getAdditionalProps.maxWordLength
        maxMatchLength = this@getAdditionalProps.maxMatchLength

        maxPhraseLengthWithTranslation = DEFAULT_BUILD_PHRASE_RAW_LIMIT
        maxPhraseLengthWithoutTranslation = DEFAULT_BUILD_PHRASE_RAW_LIMIT
        maxTranslationLength = this@getAdditionalProps.maxTranslationLength
    }


val TaskLineEditor = FC<TaskLineUiEditorProps> { props ->
    val taskLine = useMemo(props.taskLine, callback = {
        try {
            TaskLine(props.taskLine)
        } catch (e: TaskLine.Error) {
            null
        }
    })

    val curPattern = useMemo(taskLine, callback = {
        taskLine?.pattern
    })
    val uiEditor = TaskLineUiEditor.entries.find { curPattern?.type in it.suitablePatterns }

    val patternwiseAdditionalProps = useMemo(curPattern, callback = {
        curPattern?.type?.getAdditionalProps() ?: { }
    })

    if (taskLine == null) {
        return@FC
    }

    if (uiEditor == null) {
        RawTaskLineEditor {}
        return@FC
    }

    uiEditor.reactComponent {
        this.taskLine = props.taskLine
        onChange = props.onChange
        slide = props.slide
        onEditSoundClick = props.onEditSoundClick
        onPatternChange = props.onPatternChange

        inject(patternwiseAdditionalProps)
    }
    div {
        css(radioPickersContainer)
        if (uiEditor.suitablePatterns.size > 1) {
            PatternRadioPicker {
                value = taskLine.pattern?.type
                onChange = {
                    taskLine.pattern = ExerciseUiPattern(it, taskLine.pattern?.metaTags ?: listOf())
                    props.onPatternChange?.invoke(taskLine.toRawTaskLine())
                }
                patterns = uiEditor.suitablePatterns
                taskLineContent = taskLine.content.toRawTaskContent()
            }
        }
        uiEditor.patternMetaTagsEditors.map {
            it {
                value = taskLine.pattern?.metaTags
                onChange = {
                    var newTaskLine = taskLine
                    newTaskLine.pattern =
                        newTaskLine.pattern?.type?.let { type -> ExerciseUiPattern(type, it) }

                    for (preprocessor in uiEditor.taskLineContentPreprocessors) {
                        newTaskLine = newTaskLine?.let { taskLine -> preprocessor(taskLine) }
                    }

                    newTaskLine?.toRawTaskLine()?.let { taskLine -> props.onPatternChange?.invoke(taskLine) }
                }
            }
        }
    }
}