package widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.ThreeLongPairsTaskLineEditor

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.start
    gap = 6.px
    width = 100.pct
}
