package pages.constructor.ui.components.viewers

import csstype.*
import pages.constructor.ui.constructorBgColor
import utils.types.CssStyle

val tabsContainer: CssStyle = {
    display = Display.flex
    justifyContent = JustifyContent.start
}

val tabActive: CssStyle = {
    padding = Padding(10.px, 20.px)
    backgroundColor = Color("#FFFFFF")
    borderTopWidth = 1.px
    borderLeftWidth = 1.px
    borderRightWidth = 1.px
    borderBottomWidth = 0.px
    borderStyle = LineStyle.solid
    borderColor = Color("#D7D7D7")
    borderTopLeftRadius = 3.px
    borderTopRightRadius = 3.px
    borderBottomLeftRadius = 0.px
    borderBottomRightRadius = 0.px

    display = Display.block
    whiteSpace = WhiteSpace.normal
    textAlign = TextAlign.center
    height = Auto.auto

    fontFamily = string("\"Inter\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 14.px
    lineHeight = 22.px

    color = Color("#2E2E2E")

    transition = None.none
}

val tabInactive: CssStyle = {
    padding = Padding(10.px, 20.px)
    backgroundColor = Color(constructorBgColor)
    borderWidth = 0.px

    display = Display.block
    whiteSpace = WhiteSpace.normal
    textAlign = TextAlign.center
    height = Auto.auto

    fontFamily = string("\"Inter\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(500)
    fontSize = 14.px
    lineHeight = 17.px

    color = Color("#6F6F6F")

    transition = None.none
}
