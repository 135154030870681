package widgets.StudentDuoContent.ui.TopicTextArea

import antd.TextArea
import builders.enums.EAlign
import emotion.react.css
import enums.EButtonTypes
import react.*
import react.dom.html.ReactHTML.div
import shared.components.inputLabelledLimited.Counter
import utils.localization.Localization
import widgets.LanguageAutoContent.ui.components.Content.maxContentText
import widgets.StudentDuoContent.ui.SuggestedTopicsModal.SuggestedTopicsModal

external interface TopicTextAreaProps : Props {
    var value: String?
    var onChange: ((String) -> Unit)?
    var interfaceLanguage: String
    var maxUserTextLen: Int
}

val TopicTextArea = FC<TopicTextAreaProps> { props ->
    val (value, setValue) = useState(props.value)

    useEffect(props.value) {
        setValue(props.value)
    }

    div {
        css(container)
        div {
            css(labelContainer)
            div {
                div {
                    css(label)
                    +Localization.getTopicTitle(props.interfaceLanguage)
                }
            }
            Counter {
                this.limit = props.maxUserTextLen
                this.value = value?.length
                this.align = EAlign.Horizontal.RIGHT
            }
        }
        TextArea {
            id = EButtonTypes.DUO_CONTENT_TEXTAREA.value
            css(textArea)
            placeholder = Localization.getTopicPlaceholder(props.interfaceLanguage)
            this.value = value
            onBlur = {
                val value = it.target.value.take(props.maxUserTextLen)
                props.onChange?.invoke(value)
            }
            onChange = {
                setValue(it.currentTarget.value.take(props.maxUserTextLen))
            }
            dir = "auto"
        }
        if (value?.isEmpty() != false) {
            SuggestedTopicsModal {}
        }
    }
}