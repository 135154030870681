package utils

import builders.enums.EInteractiveType
import kotlinx.browser.document
import online.interactiver.common.interactivepicture.InteractiveElement
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import shared.canvas.interfaces.getPlaceholder
import utils.types.impl

fun downloadHintsTextAsTxt(interactiveElements: MutableList<InteractiveElement>, name: String?) {
    var hintsTexts = ""
    interactiveElements.forEach f@{
        if (it.type == EInteractiveType.CONNECTION_INTERACTIVE.text) {
            return@f
        }
        val map = mutableMapOf<String, String?>()
        map["visible"] = it.visibleElement.text?.simpleText
        map["hint"] = it.hint.text?.simpleText
        map["placeholder"] = it.getPlaceholder()
        if (map.all { field -> field.value == null || field.value == "" }) {
            return@f
        }

        var fields = "\nid: \"${it.identifier.id}\","
        map.forEach { field ->
            if (field.value != null && field.value != "") {
                fields += "\n${field.key}: \"\"\"\n${field.value}\n\"\"\","
            }
        }

        hintsTexts += "{$fields\n},\n"
    }

    val blob = Blob(arrayOf(hintsTexts), impl { type = "text/plain" })
    val url = URL.Companion.createObjectURL(blob)
    val link = document.createElement("a") as HTMLAnchorElement
    link.href = url
    link.download = "${name}_hints.txt"
    link.click()
}