package pages.constructor.ui.components.advancedSettings.controlButtonsSettings

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    marginBottom = 24.px
}

val textArea: CssStyle = {
    height = important(48.px)
    width = 170.px
    resize = important(None.none)
    backgroundColor = Color("#FFFFFF")
    border = Border(1.px, LineStyle.solid, Color("#D0D5DD"))
    borderRadius = 5.px
    padding = Padding(2.px, 10.px)

    fontFamily = string("Arial")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 14.px
    lineHeight = 17.px

    color = Color("#282F3E")

    marginBottom = 8.px

    lastChild {
        marginBottom = 0.px
    }
}

val inputLabel: CssStyle = {
    fontFamily = string("Arial")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 12.px
    lineHeight = 130.pct

    color = Color("#5D6676")
}
