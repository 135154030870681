package entities.interactivePicture.elements.gapPuzzles.drags

import builders.enums.EElementType
import builders.enums.EInteractiveType
import builders.getDragBuilder
import entities.interactivePicture.elements.InteractiveElementAction
import entities.interactivePicture.elements.addElementToList
import entities.interactivePicture.elements.controls.button.*
import entities.interactivePicture.elements.setValueForElements
import online.interactiver.common.interactivepicture.Button
import online.interactiver.common.interactivepicture.DEFAULT_SCORE
import online.interactiver.common.interactivepicture.InteractiveElement
import online.interactiver.common.interactivepicture.ScoreData

open class ButtonsAction : InteractiveElementAction(false)


data class AddButton(val id: String, val position: utils.structures.Position?) : ButtonsAction()

data class SetCorrectState(val id: String, val option: String) : ButtonsAction()

data class SetCorrectStateForElements(val ids: Array<String>, val option: String) : ButtonsAction()

data class SetScoresBySelectedAndUnselected(val id: String, val scoresBySelected: MutableList<ScoreData>, val scoresByUnselected: MutableList<ScoreData>) : ButtonsAction()

val setCorrectState = f@{ list: MutableList<InteractiveElement>, id: String,
                          option: String ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList

    newList[elementIndex] = newList[elementIndex].copy(
            button = Button(correctState = option)
    )

    newList
}

val setCorrectStateForElements = {list: MutableList<InteractiveElement>, ids: Array<String>, option: String ->
    setValueForElements(list, ids)  { newList, id->
        setCorrectState(newList, id, option)
    }
}

val setScoresBySelectedAndUnselected = f@{
        list: MutableList<InteractiveElement>,
        id: String,
        scoresBySelected: MutableList<ScoreData>,
        scoresByUnselected: MutableList<ScoreData> ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList

    newList[elementIndex] = newList[elementIndex].copy(
        button = newList[elementIndex].button?.copy(
            scoresBySelected = scoresBySelected,
            scoresByUnselected = scoresByUnselected
        )
    )

    newList
}

val ButtonsReducer = { state: MutableList<InteractiveElement>, action: ButtonsAction ->
    when (action) {

        is SetCorrectState -> setCorrectState(state, action.id, action.option)

        is SetCorrectStateForElements -> setCorrectStateForElements(state, action.ids, action.option)

        is SetScoresBySelectedAndUnselected -> setScoresBySelectedAndUnselected(state, action.id, action.scoresBySelected, action.scoresByUnselected)

        is AddButton -> {
            val builder =
                    getDragBuilder().setType(EInteractiveType.BUTTON_INTERACTIVE)
                            .setType(EElementType.BUTTON_CONTROL)
                            .setHoverFocusStyling(buttonStyle)
                            .setHoverStyle(buttonHoverStyle)
                            .setFocusStyle(buttonFocusStyle)
                            .setSelectStyle(buttonSelectStyle)
                            .setTextStyle(buttonTextStyle)
                            .setRect(action.position)
                            .setRectSize(128, 32)
                            .setButton("selected")
                            .addScore(ScoreData(DEFAULT_SCORE, null))
                            .setID(action.id)
                            .setIsSynced(false)

            addElementToList(state, builder.build())
        }


        else -> state
    }
}
