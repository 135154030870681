package widgets.CourseMaterials.ui.components.UploadImage

import app.useAppDispatch
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.components.cropImage.useCropImage
import widgets.LanguageAutoTask.ui.components.SlideEditModal.savePictureRequest

external interface UploadImageProps : Props {
    var pictureSrc: String?
    var onChange: (String) -> Unit
}

val UploadImage = FC<UploadImageProps> { props ->
    val (resetCropImage, cropImage, cropImageProps) = useCropImage()
    val dispatch = useAppDispatch()

    div {
        css(uploadImage)
        cropImage {
            savedImage = props.pictureSrc
            isOpen = cropImageProps.isOpen
            openModal = cropImageProps.openModal
            closeModal = cropImageProps.closeModal
            crop = cropImageProps.crop
            cropOnChange = cropImageProps.cropOnChange
            onSaveClick = {
                dispatch(StartModalLoading("Saving picture"))
                GlobalScope.launch {
                    val hashedPicture = savePictureRequest(it)
                    dispatch(EndModalLoading())
                    if (hashedPicture.data == null) {
                        dispatch(OpenErrorModal("Error saving picture. Try again"))
                        return@launch
                    }

                    resetCropImage()
                    props.onChange(hashedPicture.data)
                }
            }
            onDeleteClick = { props.onChange("") }
        }
    }
}