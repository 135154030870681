package widgets.languageAutoTask.ui.components.SlideEditModal.patternMetaTagsEditor.radioModeMetaTagEditor

import csstype.*
import utils.types.CssStyle

val grid: CssStyle = {
    display = Display.grid
    gridTemplateColumns = repeat(2, Auto.auto)
    gridTemplateRows = repeat(3, Auto.auto)
    gridAutoFlow = GridAutoFlow.column
    rowGap = 5.px
    columnGap = 20.px

    justifyContent = JustifyContent.start
}

val header: CssStyle = {
    textTransform = TextTransform.uppercase
    fontFamily = string("Montserrat")
    fontStyle = FontStyle.normal
    fontSize = 12.px

    color = Color("#98A2B3")

    marginBottom = 5.px
}
