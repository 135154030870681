package pages.constructor.ui.components.header.styleSettings.alignPicker

import antd.Button
import app.useAppSelector
import builders.enums.EAlign
import builders.enums.EElementType
import emotion.react.css
import entities.interactivePicture.selectFocusedElement
import pages.constructor.ui.components.header.styleSettings.secondary
import pages.constructor.ui.components.header.styleSettings.weightPicker.button
import pages.constructor.ui.components.header.styleSettings.weightPicker.buttonActive
import pages.constructor.ui.components.header.styleSettings.weightPicker.container
import pages.constructor.ui.components.header.styleSettings.weightPicker.iconsContainer
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.key
import shared.components.Icon

external interface AlignPickerProps : Props {
    var horizontalValue: String?
    var verticalValue: String?
    var onHorizontalAlignChange: ((String) -> Unit)?
    var onVerticalAlignChange: ((String) -> Unit)?
}

val AlignPicker = FC<AlignPickerProps> { props ->
    val selected = useAppSelector(selectFocusedElement)
    if (selected?.visibleElement?.type.equals(EElementType.MARKER_STATIC.text)) {
        return@FC
    }
    val verticalValue = props.verticalValue ?: "top"
    val horizontalValue = props.horizontalValue ?: "left"

    div {
        css(container)
        div {
            css(secondary)
            +"Align"
        }
        div {
            css(iconsContainer)
            class Align(val align: EAlign, val iconSrc: String, val value: String, val callback: ((String) -> Unit)?)
            arrayOf(
                Align(
                    EAlign.Horizontal.LEFT,
                    "ic_align_left_24x24.svg",
                    horizontalValue,
                    props.onHorizontalAlignChange
                ),
                Align(
                    EAlign.Horizontal.CENTER,
                    "ic_align_center_24x24.svg",
                    horizontalValue,
                    props.onHorizontalAlignChange
                ),
                Align(
                    EAlign.Horizontal.RIGHT,
                    "ic_align_right_24x24.svg",
                    horizontalValue,
                    props.onHorizontalAlignChange
                ),
                Align(EAlign.Vertical.TOP, "ic_align_top_24x24.svg", verticalValue, props.onVerticalAlignChange),
                Align(EAlign.Vertical.MIDDLE, "ic_align_middle_24x24.svg", verticalValue, props.onVerticalAlignChange),
                Align(EAlign.Vertical.BOTTOM, "ic_align_bottom_24x24.svg", verticalValue, props.onVerticalAlignChange),
            ).map {
                Button {
                    css(if (it.value == it.align.value) buttonActive else button)
                    key = it.iconSrc
                    Icon {
                        src = it.iconSrc
                    }
                    onClick = onClick@{
                        if (it.value == it.align.value) {
                            return@onClick
                        }
                        it.callback?.invoke(it.align.value)
                    }
                }
            }
        }
    }
}
