package widgets.languageAutoTask.ui.components.GenerateMoreSlidesButton

import antd.Button
import antd.Tooltip
import app.useAppDispatch
import emotion.react.css
import entities.languageAutoCurrentRequest.*
import pages.languageAuto.ui.useLanguageAutoContext
import react.*
import react.redux.useSelector
import shared.components.Icon
import widgets.LanguageAutoTask.ui.genMoreSlidesButton
import widgets.LanguageAutoTask.ui.genMoreSlidesButtonDisabled
import widgets.LanguageAutoTask.ui.iconDisabled

const val maxSlides = 20

external interface GenerateMoreSlidesButtonProps : Props {
    var onClick: (() -> Unit)?
}

val GenerateMoreSlidesButton = FC<GenerateMoreSlidesButtonProps> { props ->
    val dispatch = useAppDispatch()

    val task = useSelector(selectCurrentRequestTask)
    val sliderRequestId = useSelector(selectCurrentRequestId)

    val (entertainingLoader) = useLanguageAutoContext()

    val slidesCount = useMemo(task, callback = {
        if (task != null) task.count { it == '\n' } + 1 else 0
    })

    Tooltip {
        val disabled = slidesCount > maxSlides
        title = if (disabled) "You have too much slides! Make their count not more than $maxSlides." else null
        Button {
            css(if (disabled) genMoreSlidesButtonDisabled else genMoreSlidesButton)
            ghost = true
            Icon {
                if (disabled) css(iconDisabled)
                src = "ic_plus_blue_32x32.svg"
            }
            +"Generate more slides"
            onClick = onClick@{
                if (disabled) {
                    return@onClick
                }

                props.onClick?.invoke()
            }
        }
    }
}
