package pages.constructor.ui.components.editors

import csstype.*
import csstype.AlignItems.Companion.center
import utils.types.CssStyle

val container: CssStyle = {
    backgroundColor = Color("#FFFFFF")
    width = 250.px
    height = 100.pct
    overflowY = Auto.auto
    overflowX = Overflow.hidden
}

val headerContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = center
    height = 60.px
    paddingLeft = 20.px
    paddingRight = 20.px
    backgroundColor = Color("#FFFFFF")
    borderBottom = Border(1.px, LineStyle.solid, Color("#D7D7D7"))
}

val defaultHeader: CssStyle = {
    borderWidth = 0.px
    fontFamily = string("\"Inter\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 14.px
    lineHeight = 22.px
    letterSpacing = (-0.002).em
    flex = None.none
    order = integer(0)
    flexGrow = number(0.0)
    color = Color("#2E2E2E")
}

val activeHeader: CssStyle = {
    borderWidth = 0.px
    fontFamily = string("\"Inter\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 14.px
    lineHeight = 22.px
    letterSpacing = (-0.002).em
    flex = None.none
    order = integer(0)
    flexGrow = number(0.0)
    color = Color("#2F54EB")
}