package widgets.Exercises.ui.components.StatisticsModal

import csstype.*
import utils.types.CssStyle

val modal: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 30.px
}

val select: CssStyle = {
    width = 256.px
}

val editSliderButton: CssStyle = {
    border = None.none
    backgroundColor = Color("#F5F5F5")
    color = Color("#BDBDBD")
    height = 42.px
    maxWidth = 220.px
}

val emptyStatistics: CssStyle = {
    fontSize = 20.px
    lineHeight = 26.px
    fontWeight = integer(500)
}

val buttonsContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 20.px
}
