package widgets.LanguageAutoContent.ui.components.Content.ListeningContent

import csstype.*
import utils.types.CssStyle

val generateTextForListeningContainer: CssStyle = {
    display = Display.flex
    width = 100.pct
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.center
}

val contentForGenerateText: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
    width = 80.pct
}