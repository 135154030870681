package online.interactiver.common.accessgroupuser

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class AccessGroupUserWithEmail(
    @SerialName("user_id") val userId: Int,
    val email: String,
    val role: String = "STUDENT",
    @SerialName("access_group_id") val accessGroupId: Int,
    @SerialName("addition_time") val additionTime: Long?
)