package pages.payment.ui.PaymentCard

import csstype.*
import utils.types.CssStyle

val paymentCard: (Boolean?) -> CssStyle = { withDiscount ->
    {
        border = Border(2.px, LineStyle.solid, Color(if (withDiscount == true) "#597EF7" else "#D0D5DD"))
        display = Display.flex
        flexDirection = FlexDirection.column
        gap = 20.px
        borderRadius = 10.px
        padding = 20.px
        boxSizing = BoxSizing.borderBox
        position = Position.relative
        width = 100.pct
    }
}

val header: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
}

val discount: CssStyle = {
    position = Position.absolute
    padding = 6.px
    borderRadius = 12.px
    fontSize = 14.px
    boxSizing = BoxSizing.borderBox
    background = Color("#597EF7")
    color = Color("white")
    fontWeight = integer(600)
    margin = Margin(0.px, Auto.auto)
    height = 40.px
    right = 5.px
    top = (-20).px
    border = Border(5.px, LineStyle.solid, Color("white"))
}
