package widgets.ExerciseSettings.components.SettingLabel

import antd.Tooltip
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import shared.components.Icon

external interface SettingLabelProps : Props {
    var label: String
    var hint: String
}

val SettingLabel = FC<SettingLabelProps> { props ->
    div {
        css(labelContainer)
        p {
            css(label)
            +props.label
        }
        Tooltip {
            title = props.hint
            placement = "topRight"
            div {
                Icon {
                    src = "ic_info_24x24.svg"
                }
            }
        }
    }
}