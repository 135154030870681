package widgets.Exercises.ui.components.ActionsForMultipleExercises

import csstype.*
import utils.types.CssStyle

val actionButton: CssStyle = {
    border = None.none
    height = 32.px
    padding = 0.px
    display = Display.flex
    alignItems = AlignItems.center
    gap = 4.px
    hover {
        backgroundColor = Color("#F4F7FF")
    }
}

val actions: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    width = 100.px
}

val ic: CssStyle = {
    minWidth = 32.px
}
