package widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.QuestionWithAnswersEditor

import csstype.*
import utils.types.CssStyle
import utils.types.extend

val textarea: CssStyle = {
    resize = important(None.none)
}

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.stretch
    justifyContent = JustifyContent.center
    gap = 24.px
}

val column_3_container: CssStyle = container.extend {
    gap = 12.px
}

val grid_2x2_container: CssStyle = {
    display = Display.grid
    gridTemplateColumns = repeat(2, Auto.auto)
    gap = 12.px
}

val verticalContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
}
