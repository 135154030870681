package pages.constructor.ui.components.advancedSettings.popupSettings

import antd.Option
import antd.Select
import antd.TextArea
import emotion.react.css
import enums.EButtonTypes
import enums.EPopupStyle
import online.interactiver.common.interactivepicture.PopupSettings
import pages.constructor.ui.components.advancedSettings.settingHeader
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.components.checkboxWithLabel.CheckboxWithLabel

external interface PopupSettingsProps : Props {
    var settings: PopupSettings
    var onChange: (PopupSettings) -> Unit
}

val PopupSettingsEditor = FC<PopupSettingsProps> { props ->
    div {
        div {
            css(settingHeader)
            +"Congratulations popup"
        }
        div {
            css(checkboxTitle)
            +"Choose a popup style:"
        }
        CheckboxWithLabel {
            id = EButtonTypes.SHOW_POPUP_SETTINGS_BUTTON.value
            checked = !props.settings.showPopup
            label = "Without popup"
            onChange = {
                props.onChange(props.settings.copy(showPopup = !it))
            }
        }
        Select {
            css(selectStyle)
            id = EButtonTypes.POPUP_STYLE_SETTINGS_SELECT.value
            value = props.settings.popupStyle
            disabled = !props.settings.showPopup
            showArrow = true
            onSelect = { value, _ ->
                if (value != props.settings.popupStyle) {
                    props.onChange(props.settings.copy(popupStyle = value))
                }
            }
            EPopupStyle.values().forEach {
                Option {
                    value = it.value
                }
            }
        }

        div {
            css(checkboxTitle)
            +"Enter a popup message"
        }

        CheckboxWithLabel {
            id = EButtonTypes.DEFAULT_POPUP_MESSAGE_SETTINGS_BUTTON.value
            checked = props.settings.isDefaultMessageUsed
            label = "Default popup message"
            onChange = {
                props.onChange(props.settings.copy(isDefaultMessageUsed = it))
            }
        }
        div {
            css(textSubtitle)
            +"Title"
        }
        TextArea {
            css(textInput(30))
            value = props.settings.title
            onChange = { eventChangeTitleText ->
                props.onChange(props.settings.copy(title = eventChangeTitleText.target.value))
            }
            disabled = (props.settings.isDefaultMessageUsed)
        }
        div {
            css(textSubtitle)
            +"Subtitle"
        }
        TextArea {
            css(textInput(48))
            value = props.settings.subtitle
            onChange = { eventChangeTitleText ->
                props.onChange(props.settings.copy(subtitle = eventChangeTitleText.target.value))
            }
            disabled = (props.settings.isDefaultMessageUsed)
        }
        div {
            css(annotation)
            +"*Type “\u0024GeneralScore” to indicate the scores"
        }
    }
}
