package pages.scoreViewer.components.scoreProgressBar

import antd.Progress
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import utils.types.impl
import kotlin.math.roundToInt

external interface ScoreProgressBarProps : Props {
    var current: Number
    var target: Number
    var label: String?
}

val ScoreProgressBar = FC<ScoreProgressBarProps> { props ->
    val ratio = props.current.toDouble()/props.target.toDouble()
    val fullDoneColor = "#423CAA"
    val notDoneColor = "#B7B6F1"
    val lineBgColor = "#EFEEF4"

    div {
        css(labelStyle)
        div {
            css(nameStyle)
            +(props.label ?: "")
        }
        div {
            css(scoreStyle)
            +"${props.current}/${props.target}"
        }
    }
    Progress {
        percent = ratio*100
        strokeColor = hexBetween(notDoneColor, fullDoneColor, ratio)
        trailColor = lineBgColor
        showInfo = false
        success = impl {
            strokeColor = fullDoneColor
        }
    }
}

private data class Rgb(
    var red: Int,
    var green: Int,
    var blue: Int
) {
    override fun toString(): String {
        return "#${red.toString(16)}${green.toString(16)}${blue.toString(16)}"
    }
}

private fun RgbFromString(str: String): Rgb {
    var hexString = str.removePrefix("#")
    if (hexString.length == 3) {
        hexString = hexString.map { "$it$it" }.joinToString("")
    }
    val rgbValue = hexString.toInt(16)
    val red = (rgbValue shr 16 and 0xFF)
    val green = (rgbValue shr 8 and 0xFF)
    val blue = (rgbValue and 0xFF)
    return Rgb(red, green, blue)
}
private fun hexBetween(hex1: String, hex2: String, coefficient: Double): String {
    val rgb1 = RgbFromString(hex1)
    val rgb2 = RgbFromString(hex2)

    val between = Rgb(
        red = intBetween(rgb1.red, rgb2.red, coefficient),
        green = intBetween(rgb1.green, rgb2.green, coefficient),
        blue = intBetween(rgb1.blue, rgb2.blue, coefficient)
    )
    return between.toString()
}

private fun intBetween(int1: Int, int2: Int, coefficient: Double): Int {
    return (int1 + (int2 - int1)*coefficient).roundToInt()
}