package shared.components.radio

import csstype.*
import utils.types.CssStyle
import utils.types.FitContent

val radio: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    padding = 0.px
    border = Border(1.px, LineStyle.solid, Color("#D0D5DD"))
    borderRadius = 100.px
    width = 16.px
    height = 16.px
}

val activeRadio: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    padding = 0.px
    border = Border(1.px, LineStyle.solid, Color("#2F54EB"))
    borderRadius = 100.px
    width = 16.px
    height = 16.px
}

val radioCircle: CssStyle = {
    width = 8.px
    height = 8.px
    borderRadius = 20.px
    backgroundColor = Color("#2F54EB")
}

val radioContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
}

val radioText: CssStyle = {
    marginLeft = 12.px
}

val radioWithLabel: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    gap = 12.px
    justifyContent = JustifyContent.start
    alignItems = AlignItems.center
    width = FitContent.fitContent

    hover {
        cursor = Cursor.pointer
    }
}

val tooltipButton: CssStyle = {
    padding = 0.px
    margin = 0.px

    width = 14.px
    height = 14.px

    display = Display.block

    border = None.none
    backgroundColor = Color("#fff")
}

val tooltipWrapper: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    gap = 8.px
    alignItems = AlignItems.center
}
