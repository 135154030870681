package pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewShareTabs.publishTabContent

import antd.Button
import antd.Input
import app.useAppDispatch
import app.useAppSelector
import app.useDownloadInteractiveHTML
import app.useSaveInteractive
import emotion.react.css
import entities.alert.*
import entities.interactivePicture.advancedSettings.SetAdvancedSettings
import entities.interactivePicture.advancedSettings.selectAdvancedSettings
import entities.interactivePicture.selectInteractiveGroup
import entities.interactivePicture.selectInteractivesGroups
import enums.EButtonTypes
import generateHtmlInteractiveByUuid
import generatePublicInteractiveHtml
import getToMainEndpoint
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import online.interactiver.common.admin.interactive.InteractiveResponse
import pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.LinkSettingsContext
import pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewShareTabs.PrepareLinkAlertCallback
import pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewShareTabs.showAlert
import react.*
import react.dom.html.ReactHTML
import redux.RAction
import shared.components.Icon
import shared.components.checkbox.ButtonColor
import shared.components.checkboxWithLabelAndComment.CheckboxWithLabelAndComment
import updateInteractiveHTMLByPublicLink
import utils.extractUUIDFromUrl
import utils.uuidToInteractiveData

val PublishTabContent = VFC {
    ReactHTML.div {
        css(container)
        PublishGetLinkSection {}
        DownloadHtmlSection {}
        AdminPanelFootNote {}
    }
}

val PublishGetLinkSection = VFC {
    val doUrl = "https://do.inream.com/"
    val uuid = extractUUIDFromUrl(window.location.href)
    val (publishedLinkName, setPublishedLinkName) = useState<String?>(null)
    val (publishedLink, setPublishedLink) = useState<String?>(null)

    val (input, setInput) = useState(uuid ?: "")

    val currentInteractiveId = useAppSelector(selectInteractiveGroup)
    val interactivesGroups = useAppSelector(selectInteractivesGroups)
    val (inputDisabled, setInputDisabled) = useState(true)

    val dispatch = useAppDispatch()

    useEffect(currentInteractiveId, interactivesGroups) {
        if (interactivesGroups.isEmpty()) {
            setInputDisabled(true)
            return@useEffect
        }

        val oldestGroup = interactivesGroups.minBy { it.value.creationTime ?: Long.MAX_VALUE }.value
        val group = interactivesGroups[currentInteractiveId] ?: oldestGroup

        if (group.isPublic) {
            setInputDisabled(false)
        } else {
            setInputDisabled(true)
        }
    }

    useEffectOnce {
        MainScope().launch {
            val response = uuidToInteractiveData(uuid!!) ?: return@launch
            setInput(response.publicLinkName ?: uuid)
            val linkName = response.publicLinkName ?: (if (response.defaultPublicLink != null) uuid else null)
            val link = response.publicLink ?: response.defaultPublicLink ?: response.interactivesLink
            setPublishedLinkName(linkName)
            setPublishedLink(link)
            setInput(linkName ?: "")
            setInputDisabled(linkName.isNullOrBlank())
        }
    }

    val linkSettingsContext = useContext(LinkSettingsContext)

    val saveInteractive = useSaveInteractive()
    ReactHTML.div {
        css(publishSection)
        ReactHTML.div {
            css(settingsHeader)
            +"Publishing"
        }
        if (!inputDisabled) {
            Input {
                css(inputStyle)
                value = input
                addonBefore = doUrl
                onChange = {
                    setInput(it.target.value)
                }
            }
        }
        Button {
            disabled = uuid.isNullOrBlank()
            css(buttonPrimary)
            id = EButtonTypes.PUBLISH_LINK_PUBLISH_TAB_INPUT.value
            +"Publish & Get link"
            Icon {
                css(whiteFilter)
                src = "ic_link_45deg_24x24.svg"
            }
            onClick = {
                MainScope().launch {
                    val (closeInfoAlert, showSuccessAlert, showErrorAlert) = showPrepareLinkAlert(dispatch)
                    saveInteractive {}?.join()
                    async { generateHtmlInteractiveByUuid(uuid!!) }.await()
                    var url: String? = publishedLink

                    when {
                        // input disabled because of interactive is not public, so do nothing with url
                        inputDisabled -> {}

                        // interactive is public and input is not matching previous link name, so setting new one
                        !inputDisabled && publishedLinkName != input -> {
                            val interactive = async { setNewPublicName(uuid!!, input) }.await()
                            val linkName = interactive?.publicLinkName ?: interactive?.uuid ?: ""
                            val link =
                                interactive?.publicLink ?: interactive?.defaultPublicLink ?: interactive?.interactivesLink
                            setPublishedLinkName(linkName)
                            setPublishedLink(link)
                            url = link
                        }

                        // interactive is public and input equals previous, so just regenerating
                        else -> {
                            launch { updateInteractiveHTMLByPublicLink(uuid!!) }.join()
                        }
                    }

                    closeInfoAlert()
                    val modified = linkSettingsContext.applyToUrl(url)
                    if (modified != null) {
                        window.navigator.clipboard.writeText(modified)
                        showSuccessAlert()
                    } else {
                        showErrorAlert()
                    }
                }
            }
        }
    }
}

private fun showPrepareLinkAlert(dispatch: (RAction) -> (RAction)): Triple<PrepareLinkAlertCallback, PrepareLinkAlertCallback, PrepareLinkAlertCallback> {
    return showAlert(
        dispatch,
        "Your interactive link is being prepared...",
        "The link has been copied successfully!",
        "Some error occurred!"
    )
}

val DownloadHtmlSection = VFC {
    val advancedSettings = useAppSelector(selectAdvancedSettings)
    val dispatch = useAppDispatch()

    val (newSettings, setNewSettings) = useState(advancedSettings)
    useEffect(advancedSettings) {
        setNewSettings(advancedSettings)
    }

    val downloadHtml = useDownloadInteractiveHTML()

    ReactHTML.div {
        ReactHTML.div {
            css(settingsHeader)
            +"Host by yourself"
        }
        ReactHTML.div {
            css(fileIncreaseCheckbox)
            CheckboxWithLabelAndComment {
                id = EButtonTypes.HOST_BY_YOURSELF_PUBLISH_TAB_BUTTON.value
                this.checked = advancedSettings.requestSettings.includeLibrariesCodeToWorkOffline
                label = "Turn it on to use without internet"
                comment = "(file size will be increased)"
                onChange = {
                    newSettings.requestSettings.includeLibrariesCodeToWorkOffline = it
                    dispatch(SetAdvancedSettings(newSettings))
                }
                iconFilter = ButtonColor.BLUE
                width = 16
                height = 16
            }
        }
        Button {
            css(buttonSecondary)
            id = EButtonTypes.DOWNLOAD_HTML_PUBLISH_TAB_BUTTON.value
            +"Download HTML"
            Icon {
                src = "ic_download_blue_24x24.svg"
            }
            onClick = {
                downloadHtml()
            }
        }
    }
}

val AdminPanelFootNote = VFC {
    val (href, setHref) = useState("#")

    useEffectOnce {
        MainScope().launch {
            val url = getToMainEndpoint()
            setHref(url)
        }
    }

    ReactHTML.div {
        css(footnote)
        +"You can also integrate it in LMS by LTI via share at "
        ReactHTML.a {
            this.href = href
            +"admin panel"
        }
    }
}

suspend fun setNewPublicName(uuid: String, input: String): InteractiveResponse? {
    val response = generatePublicInteractiveHtml(uuid, input)

    if (response.code != 200 && uuid != "") {
        return null
    }

    return Json {
        ignoreUnknownKeys = true
        explicitNulls = false
    }.decodeFromString<InteractiveResponse>(response.content)
}

suspend fun getPublicName(uuid: String, input: String): String? {
    val response = generatePublicInteractiveHtml(uuid, input)

    if (response.code != 200 && uuid != "") {
        return null
    }

    val interactive = Json {
        ignoreUnknownKeys = true
        explicitNulls = false
    }.decodeFromString<InteractiveResponse>(response.content)

    return interactive.publicLink ?: interactive.defaultPublicLink ?: interactive.interactivesLink
}