package pages.constructor.ui.components.header.templates.tag

import csstype.*
import utils.types.CssStyle

val getContainer = { color: String ->
    val container: CssStyle = {
        display = Display.flex
        gap = 6.px
        padding = Padding(2.px, 8.px)
        alignItems = AlignItems.center
        height = 17.px
        borderRadius = 10.px
        background = Color(color)
    }
    container
}

val tagText = {colorText: String ->
    val textStyle : CssStyle = {
        fontFamily = string("\"Arial\"")
        fontStyle = FontStyle.normal
        fontWeight = integer(400)
        fontSize = 12.px
        lineHeight = 130.pct
        whiteSpace = WhiteSpace.nowrap
        color = Color(colorText)
    }
    textStyle
}