package widgets.LanguageAutoContent.ui.components.Content.ListeningContent.components.FromTopic

import antd.Button
import antd.Input
import app.useAppDispatch
import csstype.Color
import csstype.integer
import csstype.px
import emotion.react.css
import entities.errorModal.store.EErrorType
import entities.errorModal.store.OpenErrorModal
import entities.languageAutoCurrentRequest.SetCurrentRequestTextOrTopic
import entities.languageAutoCurrentRequest.selectCurrentRequestLanguageLevel
import entities.languageAutoCurrentRequest.selectCurrentRequestLanguageToLearn
import entities.languageAutoCurrentRequest.selectCurrentRequestTextOrTopic
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import react.useState
import shared.components.Icon
import shared.components.header.Header
import utils.types.extend
import widgets.LanguageAutoContent.generateTextFromTopicRequest
import widgets.LanguageAutoContent.ui.components.Content.ListeningContent.components.ReadyText.ReadyText
import widgets.LanguageAutoContent.ui.components.Content.contentInputContainer
import widgets.LanguageAutoContent.ui.components.Content.textPlaceholder
import widgets.LanguageAutoContent.ui.generateButton

val FromTopic = FC<Props> {
    val (topicToGenerateText, setTopicToGenerateText) = useState("")
    val textOrTopic = useSelector(selectCurrentRequestTextOrTopic)
    val languageToLearn = useSelector(selectCurrentRequestLanguageToLearn)
    val languageLevel = useSelector(selectCurrentRequestLanguageLevel)

    val dispatch = useAppDispatch()

    div {
        css(contentInputContainer)
        Header {
            text = "Topic"
            fontSize = 14.0.px
            lineHeight = 18.9.px
            fontWeight = integer(600)
        }
        Input {
            value = topicToGenerateText
            placeholder = textPlaceholder
            onChange = { setTopicToGenerateText(it.currentTarget.value) }
        }
    }

    Button {
        css(generateButton.extend {
            if (textOrTopic.isNotBlank()) {
                backgroundColor = Color("#adc6ff")
            }
        })
        Icon {
            src = "ic_magic_wand_24x24.svg"
        }
        onClick = f@{
            if (languageToLearn == null) {
                dispatch(OpenErrorModal(
                    title = "Please select the language to learn",
                    type = EErrorType.EMPTY_LANGUAGE_TO_LEARN
                ))
                return@f
            }
            dispatch(StartModalLoading("Generating text"))
            GlobalScope.launch {
                val response = generateTextFromTopicRequest(topicToGenerateText, languageToLearn, languageLevel)
                dispatch(EndModalLoading())
                if (response.data == null) {
                    dispatch(OpenErrorModal("Can't generate text"))
                    return@launch
                }

                dispatch(SetCurrentRequestTextOrTopic(response.data))
            }
        }
        +"Get text"
    }

    if (textOrTopic.isBlank()) {
        return@FC
    }

    ReadyText {}
}
