package entities.interactivePicture.elements.figuresAndLines.static

import builders.enums.EElementType
import builders.enums.EInteractiveType
import builders.enums.ESoundActivation
import builders.enums.ESoundBadgePosition
import builders.getFigureBuilder
import entities.interactivePicture.elements.InteractiveElementAction
import entities.interactivePicture.elements.addElementToList
import entities.interactivePicture.elements.other.PHRASE_CUSTOM_SEPARATOR
import entities.interactivePicture.elements.other.hintGeometryStyle
import entities.interactivePicture.elements.other.hintTextStyle
import entities.interactivePicture.elements.other.ui.tools.phrase.EPhraseElementsAlign
import entities.interactivePicture.elements.setValueForElements
import online.interactiver.common.admin.interactive.phrase.Phrase
import online.interactiver.common.interactivepicture.*
import online.interactiver.common.utils.clone
import utils.measureAsKonvaText
import utils.structures.Position

const val SOUND_WIDTH = 44

open class StaticsAction : InteractiveElementAction(false)
data class AddRectWithText(val id: String, val position: Position?) : StaticsAction()
data class AddRectWithImage(
    val id: String,
    val position: Position?,
    val base64: String,
    val fileName: String,
    val ratio: Double
) : StaticsAction()
data class AddSound(val id: String, val position: Position?, val src: String?, val filename: String?) : StaticsAction()
data class AddStaticPhrase(val ids: Array<String>, val phrase: String, val position: Position?, val style: PhraseStyle = PhraseStyle(), val hasSounds: Boolean) : StaticsAction()

data class SetSounds(val ids: Array<String>, val phrases: Array<Phrase>) : StaticsAction()

data class SetLink(val id: String, val link: String) : StaticsAction()

fun addRectWithTextToList(list: MutableList<InteractiveElement>, id: String, position: Position?,
                          toOption: Boolean = false, text: String? = null, textStyle: TextStyle = rectWithTextTextStyle):
        MutableList<InteractiveElement> {
    val builder = getFigureBuilder().setType(EElementType.TEXT_STATIC).setRect(position)
        .setHoverFocusStyling(rectWithTextStyle)
        .setTextStyle(textStyle).setHintDefaultStyle(hintGeometryStyle)
        .setHintTextStyle(hintTextStyle).setID(id).setIsSynced(false)

    if (toOption) {
        builder.setType(EInteractiveType.OPTION_FIGURE_INTERACTIVE)
    }

    text?.let { builder.setText(text) }

    return addElementToList(list, builder.build())
}

fun addRectWithImageToList(
    list: MutableList<InteractiveElement>, id: String, position: Position?, base64: String,
    fileName: String, ratio: Double, toOption: Boolean
): MutableList<InteractiveElement> {
    val style = GeometryStyle()

    val builder = getFigureBuilder().setType(EElementType.IMAGE_STATIC).setColor("#1677ff")
    builder.setPicture(base64, fileName).setHoverFocusStyling(style).setRatio(ratio).setID(id)

    builder.setRect(position)

    if (toOption) {
        builder.setType(EInteractiveType.OPTION_FIGURE_INTERACTIVE)
    }

    return addElementToList(list, builder.build())
}

fun addSoundToList(
    list: MutableList<InteractiveElement>, id: String, position: Position?, src: String?,
    fileName: String?, toOption: Boolean
): MutableList<InteractiveElement> {
    if (src == null)
        return list

    val style = GeometryStyle()

    val builder = getFigureBuilder().setType(EElementType.SOUND_STATIC).setColor("#1677ff").setRect(position)
        .setSound(src, fileName, null, ESoundActivation.DEFAULT.textValue).setHoverFocusStyling(style).setRatio(1.0).setID(id)

    if (toOption) {
        builder.setType(EInteractiveType.OPTION_FIGURE_INTERACTIVE)
    }

    return addElementToList(list, builder.build())
}

const val PHRASE_ELEMENT_HEIGHT = 24

fun addStaticPhrase(list: MutableList<InteractiveElement>, ids: Array<String>, phrase: String, position: Position?,
                    style: PhraseStyle = PhraseStyle(), hasSounds: Boolean
): MutableList<InteractiveElement> {
    if (phrase.isBlank()) {
        return list
    }

    val align = EPhraseElementsAlign.fromString(style.elementsAlign)
    val textStyle = rectWithTextTextStyleOfPhrase(style.elementPadding, style.fontSize)

    val useCustomSeparator = phrase.any { it == PHRASE_CUSTOM_SEPARATOR }
    val words = phrase.trim().split(
        if (useCustomSeparator) PHRASE_CUSTOM_SEPARATOR else ' '
    ).filter { it.isNotBlank() }.map { it.trim() }.toMutableList()

    var resList = list.clone()
    var curX = when(align) {
        EPhraseElementsAlign.LEFT -> position?.x
        EPhraseElementsAlign.RIGHT -> position?.width?.let { position.x.plus(it) }
    } ?: 0
    var curY = position?.y ?: 0
    var curWidth = 0

    words.forEachIndexed { index, word ->
        if (word.isBlank()) {
            return@forEachIndexed
        }

        val (wordWidth, _) = word.measureAsKonvaText(textStyle)

        val soundWidth = if (hasSounds) SOUND_WIDTH else 0

        // If we exceeded width we should go to the next line
        if (curWidth + wordWidth + soundWidth > (position?.width ?: Int.MAX_VALUE) && curWidth > 0) {
            // curWidth > 0 guarantees than we won't have any empty lines
            curX = when(align) {
                EPhraseElementsAlign.LEFT -> position?.x
                EPhraseElementsAlign.RIGHT -> position?.width?.let { position.x.plus(it) }
            } ?: 0
            curY += PHRASE_ELEMENT_HEIGHT + style.lineSpacing; curWidth = 0
        }

        val phrasePosition = when(align) {
            EPhraseElementsAlign.LEFT -> Position(curX, curY, wordWidth + soundWidth / 2, PHRASE_ELEMENT_HEIGHT)
            EPhraseElementsAlign.RIGHT -> Position(curX - wordWidth - soundWidth / 2, curY, wordWidth + soundWidth, PHRASE_ELEMENT_HEIGHT)
        }

        resList = addRectWithTextToList(
            resList, ids[index], phrasePosition,
            text = word, textStyle = textStyle
        )
        when(align) {
            EPhraseElementsAlign.LEFT -> curX += wordWidth + style.elementSpacing + soundWidth
            EPhraseElementsAlign.RIGHT -> curX -= wordWidth + style.elementSpacing + soundWidth
        }
        curWidth += wordWidth + style.elementSpacing
    }
    return resList
}

val setSounds = { list: MutableList<InteractiveElement>, ids: Array<String>, phrases: Array<Phrase> ->
    setValueForElements(list, ids) f@{ oldList, id ->
        val newList = oldList.clone()
        val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
        if (elementIndex == -1) return@f newList
        val phrase = phrases.firstOrNull { it.phrase == newList[elementIndex].visibleElement.text?.simpleText }
            ?: return@f newList
        newList[elementIndex] = newList[elementIndex].copy(
            sound = newList[elementIndex].sound?.copy(
                src = phrase.soundDescription,
                altSrc = phrase.altSoundDescription,
                filename = phrase.soundDescription,
                badgePosition = ESoundBadgePosition.RIGHT_LINE.textValue
            ) ?: Sound(
                src = phrase.soundDescription,
                altSrc = phrase.altSoundDescription,
                filename = phrase.soundDescription,
                badgePosition = ESoundBadgePosition.RIGHT_LINE.textValue
            )
        )
        newList
    }
}

val setLink = f@{ list: MutableList<InteractiveElement>, id: String, link: String ->
    val newList = list.clone()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList

    newList[elementIndex] = newList[elementIndex].copy(
        visibleElement = newList[elementIndex].visibleElement.copy(
            link = link
        )
    )
    newList
}

val StaticsReducer = { state: MutableList<InteractiveElement>, action: StaticsAction ->
    when (action) {
        is AddRectWithText -> addRectWithTextToList(state, action.id,action.position, false)
        is AddRectWithImage -> addRectWithImageToList(
            state, action.id, action.position, action.base64,
            action.fileName, action.ratio, false
        )
        is AddSound -> addSoundToList(state, action.id, action.position, action.src, action.filename, false)
        is AddStaticPhrase -> addStaticPhrase(state, action.ids, action.phrase, action.position, action.style, action.hasSounds)
        is SetSounds -> setSounds(state, action.ids, action.phrases)
        is SetLink -> setLink(state, action.id, action.link)
        else -> state
    }
}
