package pages.constructor.ui.components.editors.interactivityEditor

import csstype.*
import utils.types.CssStyle

val blockHeader: CssStyle = {
    fontFamily = string("\"Inter\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 12.px
    lineHeight = 18.px
    letterSpacing = (-0.002).em
    color = Color("#2E2E2E")
    flex = None.none
    order = integer(0)
    flexGrow = number(0.0)
    marginBottom = 10.px
}

val textContainer: CssStyle = {
    paddingLeft = 20.px
    paddingTop = 20.px
    paddingBottom = 10.px
}

val activeStateHeader: CssStyle = {
    fontFamily = string("\"Inter\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 12.px
    lineHeight = 18.px
    letterSpacing = (-0.002).em
    color = Color("#2E2E2E")
    flex = None.none
    order = integer(0)
    flexGrow = number(0.0)
    paddingLeft = 20.px
    paddingBottom = 8.5.px
}