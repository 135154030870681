package pages.googleAuth.ui

import csstype.*
import utils.phones
import utils.types.CssStyle
import utils.types.shadowArray

val page: CssStyle = {
    width = 100.vw
    height = 100.vh
    padding = 10.px
    boxSizing = BoxSizing.borderBox
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    backgroundColor = Color("#F4F7FF")
}

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 18.px
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
}

val googleIcon: CssStyle = {
    width = 37.px
    minHeight = 34.px
}

val auth: CssStyle = {
    backgroundColor = Color("#FFFFFF")
    maxWidth = 560.px
    width = 100.pct
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 20.px
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    padding = Padding(40.px, 80.px)
    borderRadius = 12.px
    boxSizing = BoxSizing.borderBox
    phones {
        padding = Padding(20.px, 40.px)
    }
    boxShadow = shadowArray(
        BoxShadow(0.px, 3.px, 18.px, (-1).px, Color("#32324717")), BoxShadow(0.px, 0.px, 1.px, 0.px, Color("#0C1A4B3D"))
    )
}

val authContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 24.px
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
}

val header: CssStyle = {
    color = Color("#282F3E")
    fontWeight = FontWeight.bold
    lineHeight = 31.2.px
    fontSize = 24.px
    fontFamily = string("\"Inter\", system-ui")
    textAlign = TextAlign.center
    margin = 0.px
}

val formCss: CssStyle = {
    width = 100.pct
}

val buttonCss: CssStyle = {
    backgroundColor = Color("#F4F7FF")
    border = Border(1.px, LineStyle.solid, Color("#597EF7"))
    borderRadius = 6.px
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    gap = 14.px
    color = Color("#597EF7")
    cursor = Cursor.pointer
    height = 48.px
    width = 100.pct
    fontWeight = integer(600)
    lineHeight = 20.8.px
    fontSize = 16.px
    fontFamily = string("\"Inter\", system-ui")
    hover {
        opacity = number(0.8)
    }
}

val documents: CssStyle = {
    color = Color("#98A2B3")
    fontFamily = string("\"Inter\", system-ui")
    fontSize = 14.px
    lineHeight = 18.2.px
    margin = 0.px
}

val link: CssStyle = {
    textDecoration = TextDecoration.underline
    color = Color("inherit")
}
