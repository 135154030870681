package widgets.LanguageAutoContent.ui.components.Contents

import antd.Button
import app.useAppDispatch
import csstype.*
import emotion.react.css
import entities.languageAutoCurrentRequest.SetCurrentRequestSoundSrc
import entities.languageAutoCurrentRequest.SetCurrentRequestTextOrTopic
import kotlinx.browser.window
import online.interactiver.common.enums.EContentType
import widgets.UserProfile.selectTariffPlan
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import react.useState
import shared.components.Icon
import utils.types.extend
import widgets.LanguageAutoContent.ui.components.ContentCard.ContentCard

external interface ContentsProps : Props {
    var contentType: String
    var onChange: (String) -> Unit
    var onScrollYChange: (Double) -> Unit
}

val Contents = FC<ContentsProps> { props ->
    val tariffPlan = useSelector(selectTariffPlan)

    val dispatch = useAppDispatch()

    val (startContentIndex, setStartContentIndex) = useState(0)
    val numberOfContentsInRow = 5

    val contentsMeta = EContentType.entries.filter { it.handleAllFormat && (tariffPlan?.uppercase() != "BASIC" ||
            (it.value != EContentType.PRE_MADE_CONTENT.value &&
                    it.value != EContentType.LISTENING_BY_TEXT.value &&
                    it.value != EContentType.STORYTELLING.value)) }

    val endContentIndex = if (startContentIndex + numberOfContentsInRow > contentsMeta.size) {
        contentsMeta.size - 1
    } else {
        startContentIndex + numberOfContentsInRow
    }

    div {
        css(contents)
        contentsMeta.forEachIndexed { index, it ->
            val left = if (index < startContentIndex) -190 else 16 + 190 * (index - startContentIndex)
            div {
                css {
                    position = Position.absolute
                    transition = "left 500ms ease-in-out".unsafeCast<Transition>()
                    this.left = left.px
                }
                ContentCard {
                    this.header = it.uiValue
                    this.description = it.description
                    this.icon = it.icon
                    this.onClick = {
                        if (it.value == EContentType.LISTENING_BY_TEXT.value) {
                            dispatch(SetCurrentRequestTextOrTopic(""))
                        } else {
                            // if we change contentType from listening to something else
                            // we might not be able to change audio, but we can change text.
                            // Then we don't want to save this sound to unrelated text when we generate new slider
                            dispatch(SetCurrentRequestSoundSrc(null))
                        }
                        props.onChange(it.value)
                        props.onScrollYChange(window.scrollY)
                    }
                    this.currentContentType = props.contentType
                    this.contentType = it.value
                    this.isBeta = it.isBeta
                }
            }
        }
        if (startContentIndex != 0) {
            Button {
                css(navigateButton.extend {
                    left = 16.px
                })
                Icon {
                    css {
                        transform = rotate(90.deg)
                    }
                    src = "ic_arrow_down_24x24.svg"
                }
                onClick = {
                    setStartContentIndex { it - 1 }
                }
            }
        }
        if (endContentIndex - startContentIndex == numberOfContentsInRow) {
            Button {
                css(navigateButton.extend {
                    right = 40.px
                })
                Icon {
                    css {
                        transform = rotate(270.deg)
                    }
                    src = "ic_arrow_down_24x24.svg"
                }
                onClick = {
                    setStartContentIndex { it + 1 }
                }
            }
        }
    }
}