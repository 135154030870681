package utils.types

import csstype.*
import org.w3c.dom.HTMLElement
import org.w3c.dom.css.CSSStyleDeclaration
import utils.inject

typealias CssStyle = PropertiesBuilder.() -> Unit
inline fun CssStyle.extend(crossinline style: CssStyle): CssStyle {
    val thisStyle = this
    return { inject(thisStyle); inject(style) }
}

typealias NativeCssStyle = CSSStyleDeclaration.() -> Unit
inline fun HTMLElement.injectStyle(crossinline style: NativeCssStyle) {
    style(this.style)
}

object FitContent {
    val fitContent: Length
        get() = "fit-content".unsafeCast<Length>()
}

fun shadowArray(vararg values: BoxShadow): BoxShadow = values.joinToString(", ").unsafeCast<BoxShadow>()
