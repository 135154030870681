package online.interactiver.common.autogeneration

import kotlinx.serialization.Serializable

@Serializable
data class GenerateLanguageTaskAndSliderResponse(
    val requestId: Int,
    val task: String,
    val slider: String,
    val time: String,
    val name: String,
    val numberOfOpenAiUsages: Int,
    val link: String? = null,
    val embedCode: String? = null
)
