package pages.constructor.ui.components.header.previewShare

import csstype.*
import utils.inject
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.center
    alignItems = AlignItems.center
    padding = 0.px
    gap = 25.px
    flex = null
    order = integer(3)
    flexGrow = number(0.0)
}

val buttonPreview: CssStyle = {
    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 14.px
    lineHeight = 22.px
    color = Color("#FFFFFF")
    borderWidth = 0.px
    padding = 0.px

    hover {
        color = important(Color("#597EF7"))
        backgroundColor = important(rgba(0, 0, 0, 0.0))
    }
}

val buttonPreviewShare: CssStyle = {
    inject(buttonPreview)

    padding = Padding(6.px, 12.px)
    backgroundColor = Color("#597EF7")
    height = Auto.auto

    hover {
        color = important(Color("#FFFFFF"))
        backgroundColor = important(Color("#597EF7"))
    }
}
