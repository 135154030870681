package pages.duoTheme.ui

import antd.Button
import antd.TextArea
import antd.TextAreaRef
import app.useAppDispatch
import csstype.Color
import csstype.pct
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import entities.modalLoader.useModalLoaderWithEntertainingTexts
import entities.solvedTasks.ui.SolvedTasks
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import pages.studentDuo.SetStudentDuoTextOrTopic
import pages.studentDuo.selectStudentDuoNativeLanguage
import pages.studentDuo.selectStudentDuoState
import react.VFC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.section
import react.redux.useSelector
import react.router.useNavigate
import react.useEffectOnce
import react.useRef
import shared.components.Icon
import shared.components.header.Header
import utils.localization.Localization
import utils.types.extend
import utils.types.impl
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton
import widgets.StudentDuoContent.generateStudentDuoCourse
import widgets.StudentDuoContent.ui.TopicTextArea.textArea

val DuoTheme = VFC {
    val navigate = useNavigate()
    val state = useSelector(selectStudentDuoState)
    val nativeLanguage = useSelector(selectStudentDuoNativeLanguage)
    val dispatch = useAppDispatch()
    val textAreaRef = useRef<TextAreaRef>()

    val (startLoading, endLoading) = useModalLoaderWithEntertainingTexts(true, nativeLanguage ?: "", { dispatch(StartModalLoading(it))}) {
        dispatch(EndModalLoading())
    }

    useEffectOnce {
        if (nativeLanguage == null) {
            navigate("/duo/content/native-language")
        }
    }

    if (nativeLanguage == null) {
        return@VFC
    }

    section {
        css(page)
        SolvedTasks {}
        div {
            css(container)
            Header {
                text = Localization.getChooseTopicTitle(nativeLanguage)
            }
            div {
                css(topicsCss)
                Localization.getStartTopics(nativeLanguage).forEach {
                    Button {
                        css(topicButton)
                        onClick = f@{
                            dispatch(SetStudentDuoTextOrTopic(it))
                            val textArea = textAreaRef.current ?: return@f
                            textArea.focus(impl {})
                        }
                        +it
                    }
                }
                Button {
                    css(topicButton.extend {
                        color = Color("#2F54EB")
                    })
                    onClick = f@{
                        dispatch(SetStudentDuoTextOrTopic(""))
                        val textArea = textAreaRef.current ?: return@f
                        textArea.focus(impl {})
                    }
                    +Localization.getOwnTopicButtonText(nativeLanguage)
                }
            }
            div {
                css(textAreaContainer)
                p {
                    css(label)
                    +Localization.getTopicTitle(nativeLanguage)
                }
                TextArea {
                    ref = textAreaRef
                    css(textArea)
                    value = state.text
                    placeholder = Localization.getOnboardingTopicPlaceholder(nativeLanguage)
                    onChange = {
                        dispatch(SetStudentDuoTextOrTopic(it.currentTarget.value))
                    }
                }
            }
            div {
                css(buttonsContainer)
                Button {
                    css(generateButton.extend {
                        width = 100.pct
                    })
                    onClick = f@{
                        if (state.text.isBlank()) {
                            dispatch(
                                OpenErrorModal(
                                    title = Localization.getPleaseTypeTheText(state.interfaceLanguage)
                                )
                            )
                            return@f
                        }
                        startLoading()
                        GlobalScope.launch {
                            val response = generateStudentDuoCourse(state)
                            endLoading()
                            if (response.data == null) {
                                dispatch(
                                    OpenErrorModal(
                                        title = "Network error. Please try again"
                                    )
                                )
                                return@launch
                            }

                            navigate("/duo/folder/${response.data}")
                        }
                    }
                    Icon {
                        src = "ic_magic_wand_24x24.svg"
                    }
                    +Localization.getGenerateButtonText(nativeLanguage)
                }
                Button {
                    css(newTaskButton.extend {
                        width = 100.pct
                    })
                    onClick = {
                        navigate(-1)
                    }
                    +Localization.getBackButtonText(nativeLanguage)
                }
            }
        }
    }
}