package entities.interactivePicture.background.ui.canvas

import app.useAppDispatch
import app.useAppImage
import app.useAppSelector
import builders.enums.EElementGroup
import entities.backgroundPosScale.selectCanvasContainerHeight
import entities.backgroundPosScale.selectCanvasContainerWidth
import entities.interactivePicture.advancedSettings.selectAdvancedSettings
import entities.interactivePicture.background.EditBackgroundWithRatioWithFuture
import entities.interactivePicture.background.selectBackgroundBase64
import entities.interactivePicture.background.selectCanvasMaxWidth
import entities.interactivePicture.styles.selectBackgroundStyle
import entities.selectedElement.SelectElement
import entities.selectedElement.SelectMultipleElements
import org.w3c.dom.HTMLImageElement
import react.*
import reactkonva.Group
import reactkonva.Image
import reactkonva.Rect
import shared.canvas.implementations.PHRASE_BOUNDS_PADDING
import shared.canvas.implementations.UnplacedPuzzlesSpace
import utils.measureNotCustomHeight
import utils.structures.Point
import kotlin.math.min

external interface BackgroundCanvasProps : Props {
    var x: Int
    var y: Int
    var width: Int?
    var height: Int?
    var backgroundRef: MutableRefObject<dynamic>
}

val BackgroundCanvas = FC<BackgroundCanvasProps> { props ->
    val canvasContainerWidth = useAppSelector(selectCanvasContainerWidth)
    val canvasContainerHeight = useAppSelector(selectCanvasContainerHeight)
    val canvasMaxWidth = useAppSelector(selectCanvasMaxWidth)

    val backgroundStyle = useAppSelector(selectBackgroundStyle)

    val backgroundBase64 = useAppSelector(selectBackgroundBase64)
    val image = useAppImage(backgroundBase64 ?: "")[0] as HTMLImageElement?

    val dispatch = useAppDispatch()

    useEffect(image) {
        backgroundBase64?.let {
            image?.let { image ->
                dispatch(
                    EditBackgroundWithRatioWithFuture(
                        min(canvasMaxWidth ?: canvasContainerWidth, canvasContainerWidth),
                        canvasContainerHeight,
                        Point(image.width, image.height)
                    )
                )
            }
        }
    }

    Group {
        onMouseDown = { evt ->
            dispatch(SelectElement(null))
            dispatch(SelectMultipleElements(arrayOf()))
        }
        if (backgroundBase64 == null) {
            Rect {
                ref = props.backgroundRef
                x = props.x
                y = props.y
                width = props.width
                height = props.height
                fill = backgroundStyle?.fillColor ?: "white"
                strokeWidth = backgroundStyle?.strokeWidth
                stroke = backgroundStyle?.strokeColor
                dash = backgroundStyle?.dash?.toTypedArray()
                cornerRadius = backgroundStyle?.cornerRadius
                opacity = backgroundStyle?.opacity
            }
        } else {
            Image {
                ref = props.backgroundRef
                this.image = image
                x = props.x
                y = props.y
                width = props.width
                height = props.height
                strokeWidth = backgroundStyle?.strokeWidth
                stroke = backgroundStyle?.strokeColor
                dash = backgroundStyle?.dash?.toTypedArray()
                opacity = backgroundStyle?.opacity
            }
        }
    }

    val puzzles = useAppSelector(EElementGroup.PUZZLE.selector)
    val advancedSettings = useAppSelector(selectAdvancedSettings)
    val width = (props.width ?: 0) - 2 * PHRASE_BOUNDS_PADDING - 2 * advancedSettings.puzzleSettings.widthPadding
    val unplacedPuzzlesSpaceHeight: Int = puzzles?.measureNotCustomHeight(width) ?: 0
    if (advancedSettings.puzzleSettings.puzzlesOnPanel && unplacedPuzzlesSpaceHeight > 0) {
        UnplacedPuzzlesSpace {
            x = props.x + PHRASE_BOUNDS_PADDING + advancedSettings.puzzleSettings.widthPadding
            y = props.y + (props.height ?: 0) - unplacedPuzzlesSpaceHeight - PHRASE_BOUNDS_PADDING - advancedSettings.puzzleSettings.bottomPadding
            this.width = width
            height = unplacedPuzzlesSpaceHeight
            text = "Puzzles with no custom position will be placed here"
        }
    }
}