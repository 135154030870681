package widgets.DuoCourses.ui

import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.serialization.json.Json
import utils.getToken

suspend fun deleteCourseRequest(folderId: Int): Boolean {
    return try {
        val response = jsonClient.delete("/api/v2/student-duo/course/$folderId") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        response.status.value == 200
    } catch (e: ResponseException) {
        false
    }
}

suspend fun getWordsProgress(limit: Int? = null, folderId: Int? = null): List<String> {
    return try {
        val response = jsonClient.get("/api/v1/words-progress?limit=$limit&folderId=$folderId") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return emptyList()
        }

        Json.decodeFromString(response.bodyAsText())
    } catch (e: ResponseException) {
        emptyList()
    }
}
