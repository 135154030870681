package widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.QuestionWithAnswersEditor.QuestionWithFourAnswersEditor

import react.FC
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.TaskLineUiEditorWithSpecifiedPatternProps
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.QuestionWithAnswersEditor.QuestionWithAnswersEditor
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.QuestionWithAnswersEditor.QuestionWithAnswersEditorContainerType

val QuestionWithFourAnswersEditor = FC<TaskLineUiEditorWithSpecifiedPatternProps> { props ->
    QuestionWithAnswersEditor {
        taskLine = props.taskLine
        onChange = props.onChange
        onPatternChange = props.onPatternChange
        slide = props.slide
        onEditSoundClick = props.onEditSoundClick

        maxWordLength = props.maxWordLength
        maxMatchLength = props.maxMatchLength

        maxPhraseLengthWithTranslation = props.maxPhraseLengthWithTranslation
        maxPhraseLengthWithoutTranslation = props.maxPhraseLengthWithoutTranslation
        maxTranslationLength = props.maxTranslationLength

        type = QuestionWithAnswersEditorContainerType.GRID_2X2
    }
}
