package pages.constructor.ui.components.advancedSettings.analyticsSettings

import emotion.react.css
import enums.EButtonTypes
import online.interactiver.common.interactivepicture.AnalyticsSettings
import pages.constructor.ui.components.advancedSettings.puzzlesSettings.container
import pages.constructor.ui.components.advancedSettings.settingsHeader
import react.FC
import react.Props
import react.dom.html.ReactHTML
import shared.components.checkboxWithLabel.CheckboxWithLabel

external interface AnalyticsSettingsEditorProps : Props {
    var settings: AnalyticsSettings
    var onChange: (AnalyticsSettings) -> Unit
}

val AnalyticsSettingsEditor = FC<AnalyticsSettingsEditorProps> {props ->
    ReactHTML.div {
        css(container)
        ReactHTML.div {
            css(settingsHeader)
            +"ANALYTICS"
        }
        CheckboxWithLabel {
            id = EButtonTypes.ENABLE_ANALYTICS_SETTINGS_BUTTON.value
            checked = props.settings.enableMetrics
            label = "Enable analytics"
            onChange = {
                props.onChange(props.settings.copy(enableMetrics = it))
            }
        }
    }
}