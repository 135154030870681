package entities.interactivePicture.elements.figuresAndLines.marker

import builders.enums.EElementType
import builders.enums.EInteractiveType
import builders.getFigureBuilder
import entities.interactivePicture.elements.InteractiveElementAction
import entities.interactivePicture.elements.addElementToList
import entities.interactivePicture.elements.other.hintGeometryStyle
import entities.interactivePicture.elements.other.hintTextStyle
import online.interactiver.common.interactivepicture.InteractiveElement
import online.interactiver.common.interactivepicture.Point
import online.interactiver.common.interactivepicture.Position

open class MarkerAction : InteractiveElementAction(false)
data class AddMarker(val id: String, val position: utils.structures.Position) : MarkerAction()

fun addMarkerToList(
    list: MutableList<InteractiveElement>, id: String, pos: utils.structures.Position,
    toOption: Boolean
): MutableList<InteractiveElement> {
    val position = Position(absolutePosition = Point(pos.x, pos.y))

    val builder = getFigureBuilder().refreshCircle()
        .setType(EElementType.MARKER_STATIC).setPosition(position)
        .setHoverFocusStyling(markerStyle)
        .setTextStyle(markerTextStyle)
        .setHintDefaultStyle(hintGeometryStyle)
        .setHintTextStyle(hintTextStyle)
        .setID(id)
        .setIsSynced(false)

    if (toOption) {
        builder.setType(EInteractiveType.OPTION_FIGURE_INTERACTIVE)
    }

    return addElementToList(list, builder.build())
}

val MarkerReducer = { state: MutableList<InteractiveElement>, action: MarkerAction ->
    when (action) {
        is AddMarker -> addMarkerToList(state, action.id, action.position, false)
        else -> state
    }
}
