package shared.viewers

import builders.enums.EElementGroup
import builders.enums.EStyleType
import online.interactiver.common.interactivepicture.InteractiveElement
import online.interactiver.common.interactivepicture.TextStyle
import react.FC
import react.MutableRefObject
import react.Props
import react.dom.html.ReactHTML.div
import react.useRef
import reactkonva.Group
import reactkonva.Layer
import reactkonva.Rect
import reactkonva.Stage
import shared.canvas.KonvaElementProps
import shared.canvas.interfaces.getElementBounds
import shared.canvas.interfaces.getElementStyle
import shared.canvas.interfaces.resizeSavingRatio
import utils.structures.Position

external interface ElementViewerProps : Props {
    var group: EElementGroup
    var drawable: FC<KonvaElementProps>
    var data: InteractiveElement
    var isSelected: Boolean
    var isUnderSelectionRectangle: Boolean
    var onSelect: (Boolean) -> Unit
    var stageRef: MutableRefObject<dynamic>
}

fun getBoundsByGroup(group: EElementGroup): Position {
    if (group == EElementGroup.PUZZLE) {
        return Position(0, 0, 100, 40)
    }

    if (group == EElementGroup.CONTROLS) {
        return Position(0, 0, 100, 40)
    }

    return Position(0, 0, 60, 60)
}


val ElementViewer = FC<ElementViewerProps>() { props ->
    val size = getBoundsByGroup(props.group)
    val elementRef = useRef<dynamic>()
    val bounds = props.data.resizeSavingRatio(Position(0, 0, size.width - 4, size.height - 4))

    val textStyle = props.data.visibleElement.text?.style?.clone() ?: TextStyle()
    textStyle.padding = 2
    textStyle.fontSize = 9

    val style = props.data.getElementStyle(false, EStyleType.USUAL).copy()
    val ratio = bounds.width.toDouble() / props.data.getElementBounds().width
    if (style.cornerRadius != undefined) {
        style.cornerRadius = (style.cornerRadius!! * ratio).toInt()
    }

    div {
        Stage {
            onClick = {
                props.onSelect(it.evt.shiftKey)
            }
            onTap = {
                props.onSelect(false)
            }
            height = size.height
            width = size.width
            Layer {
                Rect {
                    height = size.height
                    width = size.width
                    cornerRadius = 3
                    fill = "#F9F5F2"

                    if (props.isSelected || props.isUnderSelectionRectangle) {
                        this.strokeWidth = 4
                        this.stroke = "#1677ff"
                    }

                }
                Group {
                    x = 2
                    y = 2
                    Group {
                        x = bounds.x
                        y = bounds.y
                        props.drawable {
                            this.stageRef = props.stageRef
                            this.elementRef = elementRef
                            this.bounds = bounds
                            this.data = props.data
                            this.style = style
                            this.textStyle = textStyle
                        }
                    }
                }
            }
        }
    }
}

