package entities.interactivePicture.elements.editors.checkmarkEditor

import antd.Option
import antd.Select
import app.useAppDispatch
import app.useAppSelector
import emotion.react.css
import entities.interactivePicture.elements.SetCheckmarkPosition
import entities.interactivePicture.elements.SetCheckmarkPositionForElements
import entities.interactivePicture.selectElementsUnderSelectionRectangle
import entities.interactivePicture.selectFocusedElement
import enums.EButtonTypes
import enums.ECheckmarkPosition
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.canvas.interfaces.getCheckmark
import shared.canvas.interfaces.getValueForEditor
import shared.canvas.interfaces.shouldDrawCheckmark
import shared.canvas.interfaces.shouldDrawEditor

val CheckmarkEditor = FC<Props> {
    val focused = useAppSelector(selectFocusedElement)
    val elementsUnderSelectionRectangle = useAppSelector(selectElementsUnderSelectionRectangle)
    val dispatch = useAppDispatch()

    if (!shouldDrawEditor(focused, elementsUnderSelectionRectangle) { element -> element.shouldDrawCheckmark()}) {
        return@FC
    }

    val checkmark = getValueForEditor(focused, elementsUnderSelectionRectangle) {element -> element.getCheckmark()}

    div {
        css(text)
        +"Result icon position"
    }
    div {
        css(containerBottom)
        Select {
            css(selector)
            id = EButtonTypes.CHECKMARK_POSITION_SELECTOR.value
            value = checkmark
            showArrow = true
            onSelect = { value, _ ->
                if (focused != null) {
                    dispatch(SetCheckmarkPosition(focused.identifier.id!!, value))
                } else {
                    dispatch(SetCheckmarkPositionForElements(elementsUnderSelectionRectangle.map { it.identifier.id!! }.toTypedArray(), value))
                }
            }
            ECheckmarkPosition.values().forEach {
                Option {
                    value = it.value
                }
            }
        }
    }
}