package widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.RawTaskLineEditor

import csstype.*
import utils.types.CssStyle

val header: CssStyle = {
    margin = 0.px
    fontSize = 14.px
    lineHeight = 18.9.px
    fontWeight = integer(500)
    color = Color("#282F3E")
}

val slideInputContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 8.px
}

val textAreaEdit: CssStyle = {
    resize = important(None.none)
    minHeight = important(159.px)
    width = 385.px
}
