package pages.constructor.ui.components.advancedSettings.popupSettings

import csstype.*
import utils.types.CssStyle

val checkboxTitle: CssStyle = {
    marginBottom = 18.px
}

val selectStyle: CssStyle = {
    width = 140.px
    borderRadius = 5.px
    marginBottom = 24.px
}

val textInput= { height: Int ->
    val text: CssStyle = {
        this.height = important(height.px)
        width = 170.px
        resize = important(None.none)
        backgroundColor = Color("#FFFFFF")
        border = Border(1.px, LineStyle.solid, Color("#D0D5DD"))
        borderRadius = 5.px
        padding = Padding(2.px, 10.px)

        fontFamily = string("Arial")
        fontStyle = FontStyle.normal
        fontWeight = integer(400)
        fontSize = 14.px
        lineHeight = 17.px

        color = Color("#282F3E")

        marginBottom = 8.px
    }
    text
}

val textSubtitle: CssStyle = {
    resize = important(None.none)
    backgroundColor = Color("#FFFFFF")
    fontFamily = string("Arial")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 12.px
    lineHeight = 17.px
    color = Color("#5D6676")
    marginBottom = 2.px
}

val annotation: CssStyle = {
    fontFamily = string("Arial")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 10.px
    lineHeight = 12.px
    color = Color("#5D6676")
    marginBottom = 10.px
}
