package shared.canvas

import app.appState.selectAppState
import app.useAppDispatch
import app.useAppSelector
import builders.enums.EElementType
import entities.interactivePicture.elements.TransformElementGapPuzzle
import online.interactiver.common.interactivepicture.SimpleElement
import react.FC
import react.key
import shared.canvas.implementations.TransformableRect

val TransformableGapPuzzleAdvanced = FC<TransformableProps> { props ->
    val dispatch = useAppDispatch()
    val appState = useAppSelector(selectAppState)

    val elementToDrawGap = props.data.clone()
    elementToDrawGap.identifier.id += "_gap"
    elementToDrawGap.visibleElement = elementToDrawGap.gapPuzzle?.gap!!
    elementToDrawGap.hint = SimpleElement("Hint")

    Transformable {
        this.guidesLines = props.guidesLines
        horizontalRef = props.horizontalRef
        verticalRef = props.verticalRef
        parentTrRef = props.parentTrRef
        key = elementToDrawGap.identifier.id + "_gap"
        data = elementToDrawGap
        drawable = TransformableRect
        isSelected = props.isSelected
        this.isUnderSelectionRectangle = props.isUnderSelectionRectangle
        this.onSelect = props.onSelect
        onChange = { geometry -> dispatch(TransformElementGapPuzzle(props.data.identifier.id!!, geometry)) }
    }

    Transformable {
        this.guidesLines = props.guidesLines
        horizontalRef = props.horizontalRef
        verticalRef = props.verticalRef
        parentTrRef = props.parentTrRef
        key = props.data.identifier.id
        data = props.data
        drawable = EElementType.values().find { type -> type.text == props.data.visibleElement.type }!!.drawable
        isSelected = props.isSelected
        this.isUnderSelectionRectangle = props.isUnderSelectionRectangle
        this.onSelect = props.onSelect
        onChange = { geometry -> dispatch(appState.getTransformElement(props.data.identifier.id!!, geometry)) }
    }
}
