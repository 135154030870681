package pages.studentDuoRoadMap.ui.ProtectedStudentDuoRoadMap

import pages.studentDuoRoadMap.ui.StudentDuoRoadMap.StudentDuoRoadMap
import react.VFC
import shared.components.ProtectedRoute.ProtectedRoute

val ProtectedStudentDuoRoadMap = VFC {
    ProtectedRoute {
        toStudentDuo = true
        StudentDuoRoadMap {}
    }
}