package shared.components.checkbox

import csstype.*
import utils.inject
import utils.types.CssStyle

fun button(width: Int, height: Int): CssStyle = {
    this.width = important(width.px)
    this.height = important(height.px)
    borderRadius = 3.px
    padding = 0.px
    flexShrink = number(0.0)

    display = Display.flex
    justifyContent = JustifyContent.center
    alignItems = AlignItems.center
}

val checkTransition: CssStyle = {
    transition = Transition.ease
    transitionDuration = 0.2.s
}

val checkHidden: CssStyle = {
    opacity = number(0.0)
    inject(checkTransition)
}

val check: CssStyle = {
    opacity = number(1.0)
    inject(checkTransition)
}

val checkboxContainer: CssStyle = {
    display = Display.flex
    marginBottom = Auto.auto
    marginTop = Auto.auto
    alignItems = AlignItems.center

    hover {
        cursor = Cursor.pointer
    }
}
