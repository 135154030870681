package entities.interactivePicture.elements.gapPuzzles.ui.tools

import antd.Button
import antd.Tooltip
import app.useAppDispatch
import app.useAppSelector
import app.useBackgroundCenteredPosition
import builders.enums.EElementType
import emotion.css.ClassName
import emotion.react.css
import entities.interactivePicture.elements.gapPuzzles.drags.AddBackgroundDrag
import entities.interactivePicture.elements.gapPuzzles.drags.AddImageDrag
import entities.interactivePicture.elements.gapPuzzles.drags.AddTextDrag
import entities.interactivePicture.elements.shared.tools.button
import entities.interactivePicture.elements.shared.tools.buttonText
import entities.interactivePicture.elements.shared.tools.buttonsGroup
import entities.lastCreatedType.SetLastCreatedType
import entities.lastCreatedType.selectLastCreatedType
import entities.selectedElement.SelectElement
import entities.selectedElement.SelectMultipleElements
import entities.selectedElement.selectElementsIdsUnderSelectionRectangle
import enums.EButtonTypes
import online.interactiver.common.utils.getTimeMillisInBase
import pages.constructor.ui.components.inputImage.InputImage
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.canvas.interfaces.getPositionWithOffset
import shared.components.Icon
import utils.fileToPicture


val DragTools = FC<Props> {

    val lastCreatedType = useAppSelector(selectLastCreatedType)
    val elementsIdUnderSelectionRectangle = useAppSelector(selectElementsIdsUnderSelectionRectangle)
    val dispatch = useAppDispatch()
    val bgCenteredPos = useBackgroundCenteredPosition()
    val inputImageOnChange = fileToPicture { a, b, r ->
        val position = bgCenteredPos.getPositionWithOffset(lastCreatedType, EElementType.BACKGROUND_DRAG)
        val id = getTimeMillisInBase()
        dispatch(AddImageDrag(id, position, a!!, b!!, r!!))
        if (elementsIdUnderSelectionRectangle.isNotEmpty()) {
            dispatch(SelectMultipleElements(emptyList<String>().toTypedArray()))
        }
        dispatch(SelectElement(id))
        dispatch(SetLastCreatedType(EElementType.IMAGE_DRAG))
    }

    div {
        css(buttonsGroup)
        Tooltip {
            title = "Extract area for drag-and-drop"
            placement = "topLeft"
            Button {
                css(button)
                id = EButtonTypes.CREATE_CUT_BUTTON.value
                div {
                    css(buttonText)
                    +"Area from bg"
                }
                Icon {
                    src = "ic_cut_drag_24x24.svg"
                }
                onClick = {
                    val position = bgCenteredPos.getPositionWithOffset(lastCreatedType, EElementType.BACKGROUND_DRAG)
                    val id = getTimeMillisInBase()
                    dispatch(AddBackgroundDrag(id, position))
                    if (elementsIdUnderSelectionRectangle.isNotEmpty()) {
                        dispatch(SelectMultipleElements(emptyList<String>().toTypedArray()))
                    }
                    dispatch(SelectElement(id))
                    dispatch(SetLastCreatedType(EElementType.BACKGROUND_DRAG))
                }
            }
        }
        Tooltip {
            title = "Enter text in a draggable rectangle"
            placement = "topRight"
            Button {
                css(button)
                div {
                    css(buttonText)
                    id = EButtonTypes.CREATE_RECT_BUTTON.value
                    +"Rectangle with text"
                }
                Icon {
                    src = "ic_text_drag_24x24.svg"
                }
                onClick = {
                    val position = bgCenteredPos.getPositionWithOffset(lastCreatedType, EElementType.TEXT_DRAG)
                    val id = getTimeMillisInBase()
                    dispatch(AddTextDrag(id, position))
                    if (elementsIdUnderSelectionRectangle.isNotEmpty()) {
                        dispatch(SelectMultipleElements(emptyList<String>().toTypedArray()))
                    }
                    dispatch(SelectElement(id))
                    dispatch(SetLastCreatedType(EElementType.TEXT_DRAG))
                }
            }
        }
        InputImage {
            className = ClassName(button)
            buttonIcon = "ic_image_drag_24x24.svg"
            buttonText = "Upload image"
            tooltipTitle = "Add image for drag-and-drop"
            onChange = inputImageOnChange
            multiple = true
            id = EButtonTypes.CREATE_IMAGE_BUTTON.value
        }
    }
}
