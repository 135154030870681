package pages.constructor.ui.components.advancedSettings.buttonsSettings

import antd.Tooltip
import emotion.react.css
import entities.interactivePicture.elements.other.ui.tools.phrase.tooltipButton
import enums.EButtonTypes
import online.interactiver.common.interactivepicture.ButtonsSettings
import pages.constructor.ui.components.advancedSettings.settingHeader
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import shared.components.Icon
import shared.components.checkboxWithLabel.CheckboxWithLabel

external interface ButtonsSettingsEditorProps : Props {
    var settings: ButtonsSettings
    var onChange: (ButtonsSettings) -> Unit
}

val ButtonsSettingsEditor = FC<ButtonsSettingsEditorProps> { props ->
    div {
        css(container)
        div {
            css(settingHeader)
            +"Buttons"
        }
        CheckboxWithLabel {
            id = EButtonTypes.RADIO_BUTTON_MODE_SETTINGS_BUTTON.value
            checked = props.settings.radioButtonMode
            label = "Radio button mode"
            tooltipTitle = "Activate for single-button selection. New selections will deselect others"
            onChange = {
                props.onChange(props.settings.copy(radioButtonMode = it))
            }
        }
        CheckboxWithLabel {
            id = EButtonTypes.ADD_CHECKBOX_SETTINGS_BUTTON.value
            checked = props.settings.addCheckbox
            label = "Add a checkbox"
            onChange = {
                props.onChange(props.settings.copy(addCheckbox = it))
            }
        }
        CheckboxWithLabel {
            id = EButtonTypes.UNSELECTED_STATES_SETTINGS_BUTTON.value
            checked = props.settings.showUnselectedStates
            label = "Display incorrect and correct unselected items after checking"
            onChange = {
                props.onChange(props.settings.copy(showUnselectedStates = it))
            }
        }
    }
}