package pages.constructor.ui.components.header

import app.useHotKeys
import emotion.react.css
import pages.constructor.ui.components.header.fileButton.FileButton
import pages.constructor.ui.components.header.interactiveParams.InteractiveParamsEditor
import pages.constructor.ui.components.header.interactiveParams.enums.EParamPopupState
import pages.constructor.ui.components.header.logoButton.LogoButton
import pages.constructor.ui.components.header.previewShare.PreviewShare
import pages.constructor.ui.components.header.styleSettings.StyleSettings
import pages.constructor.ui.components.header.templates.TemplatesButton
import pages.constructor.ui.components.header.undoRedoCopyDelete.UndoRedoCopyDelete
import pages.constructor.ui.components.saver.Saver
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useState

val Header = FC<Props> {
    val (paramPopupState, setState) = useState(EParamPopupState.CLOSE)

    useHotKeys()

    div {
        css(headerContainer)
        div {
            css(dividedBlock)
            div {
                css(dividedBlockItem)
                LogoButton { }
            }
            div { css(divider) }
            div {
                css(dividedBlockItem)
                FileButton {
                    this.setParamPopupState = setState
                }
            }
            div { css(divider) }
            div {
                css(dividedBlockItem)
                TemplatesButton { }
            }
            div { css(divider) }
            div {
                css(dividedBlockItem)
                UndoRedoCopyDelete { }
            }
            div { css(divider) }
            div {
                css(dividedBlockItem)
                Saver { }
            }
        }
        InteractiveParamsEditor {
            this.popupState = paramPopupState
            this.setState = setState
        }
        div {
            css(dividedBlock)
            div {
                css(dividedBlockItem)
                StyleSettings { }
            }
            div { css(divider) }
            div {
                css(dividedBlockItem)
                PreviewShare { }
            }
        }
    }
}
