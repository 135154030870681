package pages.constructor.ui.components.advancedSettings.hintsSettings.hintStyle

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
    width = 230.px
}

val radioContainer: CssStyle = {
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
    width = 94.px
}

val hintColor = { color: String ->
    val hint: CssStyle = {
        width = 60.px
        height = 50.px
        background = Color(color)
        borderRadius = 8.px
        flexDirection = FlexDirection.column
        justifyContent = JustifyContent.center
        alignItems = AlignItems.center
        boxShadow = BoxShadow(0.px, 1.px, 10.px, rgba(17, 50, 117, 0.15))
        before {
            content = Content.noOpenQuote
            width = 0.px
            height = 0.px
            right = (-30).px
            top =  56.px
            position =  Position.relative
            borderLeft = Border(6.px, LineStyle.solid, NamedColor.transparent)
            borderTop = Border(8.px, LineStyle.solid,  Color(color))
            borderRight =Border(6.px,  LineStyle.solid, NamedColor.transparent)
        }
    }
    hint
}

val text: CssStyle = {
    marginBottom = 18.px
}
val textHint = { colorText: String ->
    val hint: CssStyle = {
        marginTop = (-5).px
        marginLeft = 13.px
        fontSize = 9.px
        flexDirection = FlexDirection.column
        justifyContent = JustifyContent.center
        alignItems = AlignItems.center
        color = Color(colorText)
        fontFamily = string("\"Arial\"")
        fontStyle = FontStyle.normal
    }
    hint
}