package builders.enums

enum class EPhraseType(val value: String, val label: String, val tooltip: String) {
    WORD_EXPLORER("WordExplorer", "Word explorer",
        "Utilize rectangles with individual words, adding hints. Perfect for vocabulary expansion"),
    SENTENCE_BUILDER("SentenceBuilder", "Sentence builder",
        "Displays puzzle pieces of a phrase below the canvas. Learners assemble the phrase on the canvas"),
    // Uncomment when realized
//    SHUFFLED_SENTENCE("ShuffledSentence", "Shuffled sentence",
//        "Shuffles puzzle pieces of a phrase on the canvas. Learners rearrange them into the correct order")
}
