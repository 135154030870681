package widgets.CourseGenerationType.ui.CourseGenerationType

import app.useAppDispatch
import emotion.react.css
import online.interactiver.common.enums.ECourseGenerationType
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.section
import react.redux.useSelector
import shared.components.header.Header
import widgets.CourseGenerationType.ui.TextAndWordsType.TextAndWordsType
import widgets.CourseGenerationType.ui.TopicAndWordsType.TopicAndWordsType
import widgets.CourseMaterials.SetCourseGenerationType
import widgets.CourseMaterials.selectCourseGenerationType
import widgets.LanguageAutoContent.ui.components.ContentCard.ContentCard

val CourseGenerationType = FC<Props> {
    val courseGenerationType = useSelector(selectCourseGenerationType)

    val dispatch = useAppDispatch()

    section {
        css(courseGeneration)
        Header {
            text = "2. Bring materials for your course"
        }

        div {
            css(types)
            ECourseGenerationType.entries.forEach {
                ContentCard {
                    contentType = it.value
                    description = it.description
                    header = it.uiValue
                    currentContentType = courseGenerationType
                    onClick = {
                        dispatch(SetCourseGenerationType(it.value))
                    }
                    icon = it.icon
                }
            }
        }

        when (courseGenerationType) {
            ECourseGenerationType.TEXT_WORDS.value -> TextAndWordsType {}
            ECourseGenerationType.TOPIC_WORDS.value -> TopicAndWordsType {}
            else -> {}
        }
    }
}
