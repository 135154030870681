package widgets.LanguageAutoContent.ui.components.Content

import csstype.*
import utils.types.CssStyle

val contentInputContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
}

val shrinkTo80Pct: CssStyle = {
    width = 80.pct
}

val textarea: CssStyle = {
    resize = important(None.none)
    minHeight = important(100.px)
    overflow = Overflow.hidden
}

val headerContainer: CssStyle = {
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
    width = 100.pct
}

val textLengthCss: CssStyle = {
    margin = 0.px
    color = Color("#98A2B3")
    fontWeight = FontWeight.normal
    lineHeight = 18.9.px
    fontSize = 14.px
}
