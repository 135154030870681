package widgets.ExerciseSettings.components.InputGptModelName

import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.serialization.json.Json
import online.interactiver.common.user.UserSliderSettings
import utils.getToken

suspend fun updateGptModelNameRequest(gptModelName: String): UserSliderSettings? {
    return try {
        val response = jsonClient.put("api/v1/user-account/slider-settings/update-gpt-model-name") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(FormDataContent(Parameters.build {
                append("gpt_model_name", gptModelName)
            }))
        }

        if (response.status.value != 200) {
            return null
        }

        Json.decodeFromString(response.bodyAsText())
    } catch (_: ResponseException) {
        null
    }
}