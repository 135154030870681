package widgets.LanguageAutoContent.ui.components.TranslateRequestToTargetLanguageSwitch

import app.useAppDispatch
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.FC
import react.Props
import react.redux.useSelector
import widgets.ExerciseSettings.SetTranslateRequestToTargetLanguageAction
import widgets.ExerciseSettings.components.SwitchWithLabel.SwitchWithLabel
import widgets.ExerciseSettings.saveUserSliderSettingsRequest
import widgets.ExerciseSettings.selectTranslateRequestToTargetLanguage

val TranslateRequestToTargetLanguageSwitch = FC<Props> {
    val dispatch = useAppDispatch()
    val shouldTranslateRequest = useSelector(selectTranslateRequestToTargetLanguage)

    SwitchWithLabel {
        checked = shouldTranslateRequest
        onChange = {
            dispatch(SetTranslateRequestToTargetLanguageAction(it))
            GlobalScope.launch {
                saveUserSliderSettingsRequest(translateRequestToTargetLanguage = it)
            }
        }
        label = "Translate request to the learning language to achieve better generation results"
        hint = "TODO"
    }
}
