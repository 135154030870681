package entities.saver

import app.StoreState
import app.middlewares.RThunk
import app.middlewares.nullAction
import entities.interactivePicture.selectInteractiveGroup
import entities.interactivePicture.selectInteractivePicture
import kotlinx.js.timers.setTimeout
import online.interactiver.common.interactivepicture.InteractivePicture
import redux.RAction
import redux.WrapperAction
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

enum class SaverStatus(val iconSrc: String, val label: String, val tooltip: String) {
    IDLE("ic_cloud_check_24x24.svg", "", "Unsaved changes"),
    PROCESSING("ic_cloud_arrow_up_24x24.svg", "Saving", "Saving"),
    DONE_SUCCESS("ic_cloud_check_24x24.svg", "Saved", "All changes saved"),
    DONE_FAIL("ic_cloud_slash_24x24.svg", "Not saved", "Some error occurred")
}
val doneSaverStateDuration = 5.seconds
data class SaverState(val status: SaverStatus, val savedPicture: InteractivePicture, val savedGroupId: Int?) {
    val lastSavedTime: ULong? = savedPicture.lastSavedTime
    val loading: Boolean = status == SaverStatus.PROCESSING
}

open class SaverAction : RAction
class SetSavedPicture(val savedPicture: InteractivePicture): SaverAction()
class SetSavedGroupId(val savedGroupId: Int?): SaverAction()
class SetCurrentPictureSaved: SaverAction(), RThunk {
    override fun invoke(dispatch: (RAction) -> WrapperAction, getState: () -> StoreState): WrapperAction {
        val state = getState()
        val currentPicture = selectInteractivePicture(state)
        dispatch(SetSavedPicture(currentPicture))

        val currentGroup = selectInteractiveGroup(state)
        dispatch(SetSavedGroupId(currentGroup))
        return nullAction
    }
}
class SetStatus(val status: SaverStatus) : SaverAction()
class SetSaverProcessing: SaverAction(), RThunk {
    override fun invoke(dispatch: (RAction) -> WrapperAction, getState: () -> StoreState): WrapperAction {
        val saveable = selectSaveable(getState())

        if (!saveable) {
            dispatch(SetFakeSaverProcessing())
            return nullAction
        }

        dispatch(SetStatus(SaverStatus.PROCESSING))

        return nullAction
    }
}

class SetFakeSaverProcessing: SaverAction(), RThunk {
    private val processingDuration: Duration = 2.seconds
    override fun invoke(dispatch: (RAction) -> WrapperAction, getState: () -> StoreState): WrapperAction {
        dispatch(SetStatus(SaverStatus.PROCESSING))
        setTimeout(processingDuration) {
            dispatch(SetStatus(SaverStatus.DONE_SUCCESS))
            setTimeout(doneSaverStateDuration) {
                dispatch(SetStatus(SaverStatus.IDLE))
            }
        }

        return nullAction
    }
}
class SetSaverDoneSuccess: SaverAction(), RThunk {
    override fun invoke(dispatch: (RAction) -> WrapperAction, getState: () -> StoreState): WrapperAction {
        if (!getState().saver.loading) {
            return nullAction
        }
        dispatch(SetStatus(SaverStatus.DONE_SUCCESS))
        dispatch(SetCurrentPictureSaved())
        setTimeout(doneSaverStateDuration) {
            dispatch(SetStatus(SaverStatus.IDLE))
        }

        return nullAction
    }
}

class SetSaverDoneFail: SaverAction(), RThunk {
    override fun invoke(dispatch: (RAction) -> WrapperAction, getState: () -> StoreState): WrapperAction {
        if (!getState().saver.loading) {
            return nullAction
        }
        dispatch(SetStatus(SaverStatus.DONE_FAIL))
        setTimeout(doneSaverStateDuration) {
            dispatch(SetStatus(SaverStatus.IDLE))
        }

        return nullAction
    }
}

val SaverReducer = { state: SaverState, action: SaverAction ->
    when (action) {
        is SetStatus -> state.copy(status = action.status)
        is SetSavedPicture -> state.copy(savedPicture = action.savedPicture)
        is SetSavedGroupId -> state.copy(savedGroupId = action.savedGroupId)
        else -> state
    }
}

val selectSaver = { store: StoreState ->
    store.saver
}

val selectSaveable = { state: StoreState ->
    val saver = selectSaver(state)
    selectInteractivePicture(state) != saver.savedPicture || selectInteractiveGroup(state) != saver.savedGroupId
}
