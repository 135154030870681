package widgets.CourseMaterials.ui.components.Material

import csstype.*
import utils.types.CssStyle

val materialCss: (Int) -> CssStyle = { rowHeight ->
    val margin = 10
    {
        display = Display.flex
        alignItems = AlignItems.center
        gap = 10.px
        width = 100.pct
        height = (rowHeight - margin).px
        marginBottom = margin.px
    }
}

val number: CssStyle = {
    width = 20.px
}

val settingButton: CssStyle = {
    padding = 0.px
    backgroundColor = Color("white")
    boxShadow = None.none
    border = None.none
}

val numberedInput: CssStyle = {
    maxWidth = 50.px
    padding = 4.px
}

val textArea: (Boolean) ->CssStyle = { isSentences ->
    {
        resize = important(None.none)
        maxWidth = important(if (isSentences) 400.px else 250.px)
    }
}