package app

import app.appState.AppStateReducer
import app.appState.CommonState
import app.appState.IAppState
//import app.middlewares.rLogger
import app.middlewares.rThunk
import builders.enums.EInteractiveType
import entities.advancedElementSettings.AdvancedElementSettingsModalAction
import entities.advancedElementSettings.AdvancedElementSettingsReducer
import entities.advancedElementSettings.AdvancedElementSettingsState
import entities.alert.AlertsAction
import entities.alert.AlertsReducer
import entities.alert.AlertsState
import entities.backgroundPosScale.BackgroundPosScaleReducer
import entities.backgroundPosScale.StoreBackgroundPosScale
import entities.fonts.FontsAction
import entities.fonts.FontsReducer
import entities.interactivePicture.InteractivePictureReducer
import entities.interactivePicture.selectUndoableInteractivePicture
import entities.interactivePicture.viewer.ViewerFilterAction
import entities.interactivePicture.viewer.ViewerFilterReducer
import entities.lastCreatedType.LastCreatedTypeAction
import entities.lastCreatedType.LastCreatedTypeReducer
import entities.lastCreatedType.LastCreatedTypeState
import entities.modalLoader.ModalLoaderAction
import entities.modalLoader.ModalLoaderState
import entities.modalLoader.ModalLoaderReducer
import entities.previewAndExport.InteractiveFilenameReducer
import entities.previewAndExport.InteractiveGroupReducer
import entities.previewAndExport.InteractiveParamAction
import entities.previewAndExport.InteractivesGroupsReducer
import entities.saver.SaverAction
import entities.saver.SaverReducer
import entities.saver.SaverState
import entities.saver.SaverStatus
import entities.selectedElement.MultiSelectedElementsIdsReducer
import entities.selectedElement.SelectedElementIdAction
import entities.selectedElement.SelectedElementIdReducer
import online.interactiver.common.admin.interactive.InteractivesGroup
import online.interactiver.common.interactivepicture.*
import online.interactiver.common.utils.getTimeMillisInBase
import org.khronos.webgl.ArrayBuffer
import redux.RAction
import redux.compose
import redux.createStore
import redux.rEnhancer

data class StoreState(
    val interactivePicture: Undoable<InteractivePicture>,
    val backgroundPosScale: Undoable<StoreBackgroundPosScale>,
    val interactiveFileNameOnServer: String?,
    val interactiveGroupIDOnServer: Int?,
    val interactivesGroups: Map<Int?, InteractivesGroup>,
    val selectedElementId: String?,
    val viewerFilter: HashMap<EInteractiveType, Boolean>,
    val appState: Undoable<IAppState>,
    val multiSelectedIds: Array<String>,
    val fonts: Map<String, ArrayBuffer>,
    val modalLoader: ModalLoaderState,
    val saver: SaverState,
    val alerts: AlertsState,
    val lastCreatedType: LastCreatedTypeState,
    val advancedElementSettingsState: AdvancedElementSettingsState,
)

fun appReducer(state: StoreState, action: RAction): StoreState {
    return when (action) {
        is UndoableAction -> {
            state.copy(
                interactivePicture = undoableReducer(InteractivePictureReducer)(state.interactivePicture, action),
                backgroundPosScale = undoableReducer(BackgroundPosScaleReducer)(state.backgroundPosScale, action),
                appState = undoableReducer(AppStateReducer)(state.appState, action)
            )
        }

        is InteractiveParamAction -> {
            state.copy(
                interactiveFileNameOnServer = InteractiveFilenameReducer(
                    state.interactiveFileNameOnServer,
                    action
                ),
                interactiveGroupIDOnServer = InteractiveGroupReducer(
                    state.interactiveGroupIDOnServer,
                    action
                ),
                interactivesGroups = InteractivesGroupsReducer(
                    state.interactivesGroups,
                    action
                )
            )
        }


        is SelectedElementIdAction -> {
            state.copy(
                selectedElementId = SelectedElementIdReducer(state.selectedElementId, action),
                multiSelectedIds = MultiSelectedElementsIdsReducer(state.multiSelectedIds, action)
            )
        }

        is ViewerFilterAction -> {
            state.copy(viewerFilter = ViewerFilterReducer(state.viewerFilter, action))
        }

        is FontsAction -> {
            state.copy(fonts = FontsReducer(state.fonts, action))
        }

        is ModalLoaderAction -> {
            state.copy(modalLoader = ModalLoaderReducer(state.modalLoader, action))
        }

        is SaverAction -> {
            state.copy(saver = SaverReducer(state.saver, action))
        }

        is AlertsAction -> {
            state.copy(alerts = AlertsReducer(state.alerts, action))
        }

        is LastCreatedTypeAction -> {
            state.copy(lastCreatedType = LastCreatedTypeReducer(state.lastCreatedType, action))
        }

        is AdvancedElementSettingsModalAction -> {
            state.copy(advancedElementSettingsState = AdvancedElementSettingsReducer(state.advancedElementSettingsState, action))
        }

        else -> state
    }
}
val initInteractivePicture = InteractivePicture(
    identifier = Identifier(
        name = "My interactive picture",
        code = "myInteractivePicture",
        id = getTimeMillisInBase()
    ),
    background = Background(),
    style = Style(
        background = ElementStyle(
            usual = GeometryStyle(
                fillColor = "white",
                strokeWidth = 2,
                strokeColor = "#D0D5DD",
                cornerRadius = 6
            )
        )
    ),
    frames = mutableListOf(),
    figuresAndLines = mutableListOf(),
    controls = mutableListOf(),
    gapPuzzles = mutableListOf(),
    others = mutableListOf()
)

val store = createStore(
    reducer = ::appReducer,
    preloadedState = StoreState(
        interactivePicture = undoable(initInteractivePicture),
        backgroundPosScale = undoable(StoreBackgroundPosScale()),
        interactiveFileNameOnServer = null,
        interactiveGroupIDOnServer = 0,
        interactivesGroups = mapOf(),
        selectedElementId = null,
        viewerFilter = hashMapOf(),
        appState = undoable(CommonState),
        multiSelectedIds = arrayOf(),
        fonts = mapOf(),
        modalLoader = ModalLoaderState(false, null),
        saver = SaverState(SaverStatus.IDLE, initInteractivePicture, null),
        alerts = AlertsState(),
        lastCreatedType = LastCreatedTypeState(),
        advancedElementSettingsState = AdvancedElementSettingsState(),
    ),
//     And since these are all function calls, they all return from that call stack. So, the rThunk middleware is the first to run, and the last to finish.
    enhancer = compose(
        rThunk(),
//        rLogger(),
        rEnhancer()
    )
)

val selectCanUndo = useCanUndo(selectUndoableInteractivePicture)
val selectCanRedo = useCanRedo(selectUndoableInteractivePicture)
