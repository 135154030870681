package pages.constructor.ui.components.saver

import antd.Tooltip
import app.useAppSelector
import emotion.react.css
import entities.saver.SaverStatus
import entities.saver.selectSaver
import pages.constructor.ui.components.loadingDots.LoadingDots
import react.VFC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import shared.components.Icon

val Saver = VFC {
    val saver = useAppSelector(selectSaver)
    Tooltip {
        title = saver.status.tooltip
        placement = "bottomLeft"
        div {
            css(containerStyle)
            Icon {
                src = saver.status.iconSrc
            }
            span {
                css(textStyle)
                +saver.status.label
                if (saver.loading)
                    LoadingDots {}
            }
        }
    }
}
