package utils.structures

import pages.constructor.ui.components.canvas.SelectionRectangle

data class Point(
    val x: Int,
    val y: Int,
) {
    fun isInside(rect: Position): Boolean {
        val x1 = kotlin.math.min(rect.x, rect.x + rect.width)
        val x2 = kotlin.math.max(rect.x, rect.x + rect.width)
        val y1 = kotlin.math.min(rect.y, rect.y + rect.height)
        val y2 = kotlin.math.max(rect.y, rect.y + rect.height)
        return x in x1 .. x2 && y in y1 .. y2
    }

    fun isInside(rect: SelectionRectangle): Boolean {
        val x1 = kotlin.math.min(rect.start.x, rect.end.x)
        val x2 = kotlin.math.max(rect.start.x, rect.end.x)
        val y1 = kotlin.math.min(rect.start.y, rect.end.y)
        val y2 = kotlin.math.max(rect.start.y, rect.end.y)
        return x in x1 .. x2 && y in y1 .. y2
    }
}
