package entities.dictionary.ui.syncButton

import Modal
import antd.Button
import app.appState.IAppState
import app.appState.selectAppState
import app.useAppDispatch
import app.useAppSelector
import builders.enums.EElementType
import csstype.px
import emotion.react.css
import entities.dictionary.ui.*
import entities.dictionary.ui.phrase.Phrase
import entities.interactivePicture.SyncPhrasesFromDictionary
import entities.interactivePicture.elements.DeleteSound
import entities.interactivePicture.elements.SetSoundSrcAndFilename
import entities.interactivePicture.selectFocusedElement
import entities.interactivePicture.selectInteractiveGroup
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import enums.EButtonTypes
import io.ktor.client.fetch.*
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.js.jso
import online.interactiver.common.admin.interactive.phrase.Phrase
import online.interactiver.common.interactivepicture.Sound
import org.w3c.files.FileReader
import pages.constructor.ui.components.editors.designEditor.activeFilter
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useState
import redux.RAction
import shared.components.Icon
import utils.result

enum class EPhraseToSave(val value: String) {
    CONSTRUCTOR("constructor"),
    DICTIONARY("dictionary")
}

val SyncButton = FC<Props> {
    val selected = useAppSelector(selectFocusedElement)
    val interactiveGroupId = useAppSelector(selectInteractiveGroup)
    val appState = useAppSelector(selectAppState)
    val (isResolveConflictModalOpen, setIsResolveConflictModalOpen) = useState(false)
    val (isSoundFromGoogleCloudModalOpen, setIsSoundFromGoogleCloudModalOpen) = useState(false)
    val (phraseFromDictionary, setPhraseFromDictionary) = useState<Phrase?>(null)
    val (phraseToSave, setPhraseToSave) = useState(EPhraseToSave.CONSTRUCTOR.value)

    val dispatch = useAppDispatch()
    val isSynced = selected?.isSynced
    if (selected == null || isSynced == null || !EElementType.values().filter { it.usesTextEditor }.map { it.text }.contains(selected.visibleElement.type)) {
        return@FC
    }
    val phrase = selected.visibleElement.text?.simpleText
    val textDescription = selected.hint.text?.simpleText
    val soundDescription = selected.sound

    Button {
        css(buttonStyle(isSynced))
        onClick = f@{
            if (phrase.isNullOrBlank() || interactiveGroupId == null || interactiveGroupId == 0 || isSynced) {
                if (isSynced == false) {
                    return@f
                }
                dispatch(appState.getSetSyncingPhrase(selected.identifier.id!!, false))
                return@f
            }
            dispatch(StartModalLoading("Syncing phrase, please wait"))
            GlobalScope.launch {
                syncPhrase(
                    dispatch,
                    appState,
                    phrase,
                    textDescription,
                    soundDescription,
                    selected.identifier.id!!,
                    selected.visibleElement.type,
                    interactiveGroupId,
                    { setIsResolveConflictModalOpen(it) },
                    { setPhraseFromDictionary(it) },
                    { setIsSoundFromGoogleCloudModalOpen(it) }
                )
            }
        }
        Icon {
            if (isSynced) css(activeFilter)
            src = "ic_sync_arrow_16x16.svg"
        }
        +"Sync"
    }
    Modal {
        width = 400.px
        open = isSoundFromGoogleCloudModalOpen
        this.onCancel = {
            dispatch(appState.getSetSyncingPhrase(selected.identifier.id!!, false))
            setIsSoundFromGoogleCloudModalOpen(false)
        }
        this.onOk = f@{
            val text = selected.visibleElement.text?.simpleText
            if (interactiveGroupId == null || text == null) {
                return@f
            }
            dispatch(StartModalLoading("Loading sound, please wait"))
            GlobalScope.launch {
                val phraseFromDict = getSoundFromGoogleCloud(text, interactiveGroupId)
                phraseFromDict?.soundDescription?.let {
                    dispatch(SetSoundSrcAndFilename(selected.identifier.id!!, it, phraseFromDict.altSoundDescription, it))
                    dispatch(appState.getSetSyncingPhrase(selected.identifier.id!!, true))
                }
                setIsSoundFromGoogleCloudModalOpen(false)
                dispatch(EndModalLoading())
            }
        }
        this.okText = "Load"
        div {
            +"There is no such phrase in attached dictionary.\nDo you want to load sound from google cloud?"
        }
    }
    Modal {
        width = 600.px
        open = isResolveConflictModalOpen
        this.onCancel = {
            setIsResolveConflictModalOpen(false)
        }
        this.onOk = f@{
            if (interactiveGroupId == null) {
                setIsResolveConflictModalOpen(false)
                setPhraseFromDictionary(null)
                return@f
            }

            if (phraseToSave == EPhraseToSave.CONSTRUCTOR.value) {
                dispatch(StartModalLoading("Saving phrase, please wait"))
                GlobalScope.launch {
                    savePhraseByInteractiveGroupId(phrase!!, textDescription, soundDescription?.src, interactiveGroupId)
                    setIsResolveConflictModalOpen(false)
                    setPhraseFromDictionary(null)
                    dispatch(appState.getSetSyncingPhrase(selected.identifier.id!!, true))
                    val allSyncingPhrasesInJson = getSyncingPhrasesFromDictionary(interactiveGroupId)
                    if (allSyncingPhrasesInJson.isNotEmpty()) {
                        dispatch(SyncPhrasesFromDictionary(allSyncingPhrasesInJson, selected.identifier.id))
                    }
                    dispatch(EndModalLoading())
                }
                return@f
            }

            setIsResolveConflictModalOpen(false)
            setPhraseFromDictionary(null)
            dispatch(appState.getSetVisibleElementText(selected.identifier.id!!, phraseFromDictionary?.phrase ?: ""))
            dispatch(appState.getSetHintText(selected.identifier.id!!, phraseFromDictionary?.textDescription ?: ""))
            setOrDeleteSound(
                dispatch,
                soundDescription,
                selected.identifier.id!!,
                selected.visibleElement.type,
                phraseFromDictionary?.soundDescription,
                phraseFromDictionary?.altSoundDescription
            )
            dispatch(appState.getSetSyncingPhrase(selected.identifier.id!!, true))
            dispatch(StartModalLoading("Syncing phrases, please wait"))
            GlobalScope.launch {
                val allSyncingPhrasesInJson = getSyncingPhrasesFromDictionary(interactiveGroupId)
                if (allSyncingPhrasesInJson.isNotEmpty()) {
                    dispatch(SyncPhrasesFromDictionary(allSyncingPhrasesInJson, selected.identifier.id))
                }
                dispatch(EndModalLoading())
            }

        }
        this.bodyStyle = jso(modalContainer)
        this.okText = "Save"

        div {
            css(header)
            +"Which phrase do you want to save?"
        }
        div {
            css(explanation)
            +"This phrase is already in the dictionary. Please select the one you want to save."
        }

        Phrase {
            id = EButtonTypes.CHOOSE_PHRASE_FROM_CONSTRUCTOR_BUTTON.value
            this.phrase = phrase ?: ""
            this.textDescription = textDescription ?: ""
            this.soundDescription = soundDescription?.src
            this.phraseToSave = phraseToSave
            radioValue = EPhraseToSave.CONSTRUCTOR.value
            header = "Phrase from constructor"
            onChange = {
                setPhraseToSave(it)
            }
        }
        Phrase {
            id = EButtonTypes.CHOOSE_PHRASE_FROM_DICTIONARY_BUTTON.value
            this.phrase = phraseFromDictionary?.phrase ?: ""
            this.textDescription = phraseFromDictionary?.textDescription ?: ""
            this.soundDescription = phraseFromDictionary?.soundDescription
            this.phraseToSave = phraseToSave
            radioValue = EPhraseToSave.DICTIONARY.value
            header = "Phrase from dictionary"
            onChange = {
                setPhraseToSave(it)
            }
        }
    }
}

fun setOrDeleteSound(
    dispatch: (RAction) -> RAction,
    soundDescription: Sound?,
    elementId: String,
    type: String,
    newSoundDescription: String?,
    newAltSoundDescription: String?
) {
    if (type == EElementType.MARKER_STATIC.text || newSoundDescription == null && soundDescription == null) {
        return
    }

    if (newSoundDescription == null && soundDescription != null) {
        dispatch(DeleteSound(elementId))
        return
    }

    newSoundDescription?.let { dispatch(SetSoundSrcAndFilename(elementId, it, newAltSoundDescription, it)) }
}

fun getSoundBase64FromUrl(url: String?, onLoad: (String?) -> Unit) {
    if (url == null) {
        onLoad(null)
        return
    }
    fetch(url).then { it.blob() }.then {
        val fileReader = FileReader()
        fileReader.readAsDataURL(it)
        fileReader.onload = { event ->
            onLoad(event.target.result?.replace("data:application/octet-stream", "data:audio/mpeg"))
        }
    }.catch {
        onLoad(null)
    }
}

suspend fun syncPhrase(
    dispatch: (RAction) -> RAction,
    appState: IAppState,
    phrase: String,
    textDescription: String?,
    soundDescription: Sound?,
    elementId: String,
    type: String,
    interactiveGroupId: Int,
    useSetIsResolveConflictModalOpen: (Boolean) -> Unit,
    useSetPhraseFromDictionary: (Phrase) -> Unit,
    useSetIsSoundFromGoogleCloudModalOpen: (Boolean) -> Unit
) {
    val (syncedPhrase, code) = phraseAndInteractiveGroupIdToSyncedPhrase(phrase, interactiveGroupId)
    if (textDescription.isNullOrBlank() && soundDescription == null) {
        if (syncedPhrase == null) {
            if (code == 404) {
                useSetIsSoundFromGoogleCloudModalOpen(true)
            }
            dispatch(EndModalLoading())
            return
        }
        val allSyncingPhrasesInJson = getSyncingPhrasesFromDictionary(interactiveGroupId)
        if (allSyncingPhrasesInJson.isNotEmpty()) {
            dispatch(SyncPhrasesFromDictionary(allSyncingPhrasesInJson, elementId))
        }
        dispatch(appState.getSetVisibleElementText(elementId, syncedPhrase.phrase))
        dispatch(appState.getSetHintText(elementId, syncedPhrase.textDescription ?: ""))
        setOrDeleteSound(dispatch, soundDescription, elementId, type, syncedPhrase.soundDescription, syncedPhrase.altSoundDescription)
        dispatch(appState.getSetSyncingPhrase(elementId, true))
        dispatch(EndModalLoading())
        return
    }

    if (syncedPhrase == null) {
        val response = savePhraseByInteractiveGroupId(phrase, textDescription, soundDescription?.src, interactiveGroupId)
        dispatch(appState.getSetSyncingPhrase(elementId, response != null))
        val allSyncingPhrasesInJson = getSyncingPhrasesFromDictionary(interactiveGroupId)
        if (allSyncingPhrasesInJson.isNotEmpty()) {
            dispatch(SyncPhrasesFromDictionary(allSyncingPhrasesInJson, elementId))
        }
        dispatch(EndModalLoading())
        return
    }

    val openSyncingModal = {
        useSetIsResolveConflictModalOpen(true)
        useSetPhraseFromDictionary(syncedPhrase)
        dispatch(EndModalLoading())
    }

    val closeLoadingModal = f@{ newSoundSrc: String? ->
        val isHintEqual = textDescription.isNullOrBlank() && syncedPhrase.textDescription.isNullOrBlank()
                || textDescription == syncedPhrase.textDescription
        if (!isHintEqual || soundDescription?.src != newSoundSrc) {
            openSyncingModal()
            return@f
        }

        dispatch(appState.getSetSyncingPhrase(elementId, true))
        dispatch(EndModalLoading())
    }

    if (soundDescription?.src?.startsWith("https://") == true) {
        closeLoadingModal(syncedPhrase.soundDescription)
        return
    }

    getSoundBase64FromUrl(syncedPhrase.soundDescription) {
        closeLoadingModal(it)
    }
}
