package entities.interactivePicture.elements.editors.ScoreEditor

import app.StoreState
import app.UndoableAction
import entities.interactivePicture.selectElements
import entities.interactivePicture.selectFocusedElement
import online.interactiver.common.interactivepicture.*


open class ScoreAction : UndoableAction(true)

data class SetCommonScoreData(val elementID: String, val index: Int, val score: ScoreData) : ScoreAction()
data class SetCommonScoreCondition(val elementID: String, val index: Int, val condition: ScoreCondition) : ScoreAction()
data class AddCommonScoreData(val elementID: String) : ScoreAction()
data class AddCommonScoreCondition(val elementID: String) : ScoreAction()
data class DeleteCommonScoreData(val elementID: String, val index: Int) : ScoreAction()
data class DeleteCommonScoreCondition(val elementID: String, val index: Int) : ScoreAction()

val addCommonScoreDataToList = f@{ list: MutableList<InteractiveElement>, elementID: String ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(elementID) }
    if (elementIndex == -1) return@f newList

    val newScores = ArrayList<ScoreData>()
    newList[elementIndex].scores?.forEach { score -> newScores.add(score.clone()) }

    val score = ScoreData(DEFAULT_SCORE, null);

    newScores.add(score)

    newList[elementIndex] = newList[elementIndex].copy(scores = newScores)

    newList
}
val deleteCommonScoreDataFromList = f@{ list: MutableList<InteractiveElement>, elementID: String, index: Int ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(elementID) }
    if (elementIndex == -1) return@f newList

    val newScores = ArrayList<ScoreData>()
    newList[elementIndex].scores?.forEachIndexed { i, score ->
        if (i != index) {
            newScores.add(score.clone())
        }
    }

    newList[elementIndex] = newList[elementIndex].copy(scores = newScores)

    newList
}

val setCommonScoreDataToList =
    f@{ list: MutableList<InteractiveElement>, elementID: String, index: Int, score: ScoreData ->
        val newList = list.map { it.clone() }.toMutableList()
        val elementIndex = newList.indexOfFirst { it.identifier.id.equals(elementID) }
        if (elementIndex == -1) return@f newList

        val newScores = ArrayList<ScoreData>()
        newList[elementIndex].scores?.forEachIndexed { i, s ->
            if (i == index) {
                newScores.add(score.clone())
            } else {
                newScores.add(s.clone())
            }
        }

        newList[elementIndex] = newList[elementIndex].copy(scores = newScores)
        newList
    }

val addCommonScoreConditionToList = f@{ list: MutableList<InteractiveElement>, elementID: String ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(elementID) }
    if (elementIndex == -1) return@f newList

    val newConditions = ArrayList<ScoreCondition>()
    newList[elementIndex].visibleElement?.showCondition?.scoreConditions?.forEach { cond ->
        newConditions.add(cond.clone())
    }

    val condition = ScoreCondition(0, DEFAULT_SCORE, 0)

    newConditions.add(condition)

    val newShowCondition =
        newList[elementIndex].visibleElement?.showCondition?.clone(scoreConditions = newConditions) ?: ShowCondition(
            SolvingStageCondition(),
            newConditions
        )

    val newVisibleElement = newList[elementIndex].visibleElement?.copy(showCondition = newShowCondition)!!

    newList[elementIndex] = newList[elementIndex].copy(visibleElement = newVisibleElement)

    newList
}

val deleteCommonScoreConditionFromList = f@{ list: MutableList<InteractiveElement>, elementID: String, index: Int ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(elementID) }
    if (elementIndex == -1) return@f newList

    val newConditions = ArrayList<ScoreCondition>()
    newList[elementIndex].visibleElement?.showCondition?.scoreConditions?.forEachIndexed() { i, cond ->
        if (i != index) {
            newConditions.add(cond.clone())
        }
    }

    val newShowCondition =
        newList[elementIndex].visibleElement?.showCondition?.clone(scoreConditions = newConditions) ?: ShowCondition(
            SolvingStageCondition(),
            newConditions
        )

    val newVisibleElement = newList[elementIndex].visibleElement?.copy(showCondition = newShowCondition)!!

    newList[elementIndex] = newList[elementIndex].copy(visibleElement = newVisibleElement)

    newList
}

val setCommonScoreConditionToList =
    f@{ list: MutableList<InteractiveElement>, elementID: String, index: Int, condition: ScoreCondition ->
        val newList = list.map { it.clone() }.toMutableList()
        val elementIndex = newList.indexOfFirst { it.identifier.id.equals(elementID) }
        if (elementIndex == -1) return@f newList

        val newConditions = ArrayList<ScoreCondition>()
        newList[elementIndex].visibleElement?.showCondition?.scoreConditions?.forEachIndexed { i, c ->
            if (i == index) {
                newConditions.add(condition.clone())
            } else {
                newConditions.add(c.clone())
            }
        }

        val newShowCondition =
            newList[elementIndex].visibleElement?.showCondition?.clone(scoreConditions = newConditions)
                ?: ShowCondition(SolvingStageCondition(), newConditions)

        val newVisibleElement = newList[elementIndex].visibleElement?.copy(showCondition = newShowCondition)!!

        newList[elementIndex] = newList[elementIndex].copy(visibleElement = newVisibleElement)

        newList
    }

val ScoreReducer = f@{ list: MutableList<InteractiveElement>, action: ScoreAction ->
    when (action) {
        is AddCommonScoreData -> addCommonScoreDataToList(list, action.elementID)

        is AddCommonScoreCondition -> addCommonScoreConditionToList(list, action.elementID)

        is SetCommonScoreData -> setCommonScoreDataToList(list, action.elementID, action.index, action.score)

        is SetCommonScoreCondition -> setCommonScoreConditionToList(
            list,
            action.elementID,
            action.index,
            action.condition
        )

        is DeleteCommonScoreData -> deleteCommonScoreDataFromList(list, action.elementID, action.index)
        is DeleteCommonScoreCondition -> deleteCommonScoreConditionFromList(list, action.elementID, action.index)

        else -> list
    }
}

val scoreTitles = { state: StoreState ->
    val set = hashMapOf<String, Boolean>()
    val elements = selectElements(state)
    elements.forEach { element -> element.scores?.forEach { score -> set.set(score.name, true) } }
    elements.forEach { element ->
        element.visibleElement.showCondition?.scoreConditions?.forEach { condition ->
            set.set(
                condition.scoreName, true
            )
        }
        element.selector?.options?.forEach { option ->
            option.scores?.forEach {
                set.set(it.name, true)
            }
        }
        element.button?.scoresBySelected?.forEach { score ->
            set.set(score.name, true)
        }
        element.button?.scoresByUnselected?.forEach { score ->
            set.set(score.name, true)
        }
    }
    set.keys
}

val getSelectedScore = { state: StoreState ->
    selectFocusedElement(state)?.scores
}

val getSelectedScoreConditions = { state: StoreState ->
    selectFocusedElement(state)?.visibleElement?.showCondition?.scoreConditions
}
