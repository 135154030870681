package widgets.CourseSettings

import app.useAppDispatch
import kotlinx.browser.localStorage
import online.interactiver.common.enums.ECourseLevel
import online.interactiver.common.enums.ELanguage
import online.interactiver.common.enums.ELanguageLevel
import org.w3c.dom.get
import pages.course.selectCourseId
import react.redux.useSelector
import react.useEffectOnce
import widgets.CourseSettings.ui.components.LanguagesSettings.ELanguageSettingsLocalStorageKeys
import widgets.CourseSettings.ui.components.SliderSettings.ESliderSettingsLocalStorageKeys

fun useLanguageSettingsFromLocalStorageEffect() {
    val id = useSelector(selectCourseId)

    val dispatch = useAppDispatch()

    useEffectOnce {
        if (id != null) {
            return@useEffectOnce
        }

        ELanguage.entries.find {
            it.text == localStorage[ELanguageSettingsLocalStorageKeys.LANGUAGE_TO_LEARN.value]
        }?.let { dispatch(SetCourseLanguageToLearn(it.text)) }

        ELanguage.entries.find {
            it.text == localStorage[ELanguageSettingsLocalStorageKeys.INTERFACE_LANGUAGE.value]
        }?.let { dispatch(SetCourseInterfaceLanguage(it.text)) }

        ELanguageLevel.entries.find {
            it.value == localStorage[ELanguageSettingsLocalStorageKeys.LANGUAGE_LEVEL.value]
        }?.let { dispatch(SetCourseLanguageLevel(it.value)) }
    }
}

fun useSliderSettingsFromLocalStorageEffect(enableContinueButton: (Boolean) -> Unit) {
    val id = useSelector(selectCourseId)

    val dispatch = useAppDispatch()

    useEffectOnce {
        if (id != null) {
            return@useEffectOnce
        }

        ECourseLevel.entries.find {
            it.value == localStorage[ESliderSettingsLocalStorageKeys.COURSE_LEVEL.value]
        }?.let { dispatch(SetCourseLevel(it.value)) }

        localStorage[ESliderSettingsLocalStorageKeys.START_FROM_SENTENCES.value]?.toBooleanStrictOrNull()
            ?.let { dispatch(SetCourseStartFromSentences(it)) }

        localStorage[ESliderSettingsLocalStorageKeys.NUMBER_OF_SLIDES_IN_SLIDER.value]?.toIntOrNull()
            ?.let { dispatch(SetCourseNumberOfSlidesInSlider(it)) }

        localStorage[ESliderSettingsLocalStorageKeys.ENABLE_GAMIFIED_POPUPS.value]?.toBooleanStrictOrNull()
            ?.let { dispatch(SetCourseEnableGamifiedPopups(it)) }

        localStorage[ESliderSettingsLocalStorageKeys.NUMBER_OF_SLIDERS_IN_COURSE.value]?.toIntOrNull()
            ?.let { dispatch(SetCourseNumberOfSliders(it)) }

        val continueButtonText = localStorage[ESliderSettingsLocalStorageKeys.CONTINUE_BUTTON_TEXT.value]
        val continueButtonLink = localStorage[ESliderSettingsLocalStorageKeys.CONTINUE_BUTTON_LINK.value]

        enableContinueButton(!continueButtonText.isNullOrBlank() || !continueButtonLink.isNullOrBlank())

        continueButtonLink?.let { dispatch(SetCourseContinueButtonLink(it)) }
        continueButtonText?.let { dispatch(SetCourseContinueButtonText(it)) }
    }
}