package builders.enums

import anyAscii
import online.interactiver.common.interactivepicture.InteractiveElement

// This is a callback to get element's text which will define its identifier and other things if needed
enum class EElementMainTextTransliterated(val textField: (InteractiveElement) -> String) {
    VISIBLE_ELEMENT_TEXT({ elem ->
        anyAscii(elem.visibleElement.text?.simpleText ?: "")
    }),
    HINT_TEXT({ elem ->
        anyAscii(elem.hint.text?.simpleText ?: "")
    }),
    INPUT_PLACEHOLDER({ elem ->
        anyAscii(elem.input?.placeholderText ?: "")
    }),
    SOUND_FILENAME({ elem ->
        anyAscii(elem.sound?.filename ?: "")
    }),
    PHRASE_TEXT({ elem ->
        anyAscii(elem.phrase?.getPhraseText() ?: "")
    })
}
