package widgets.StudentDuoContent.ui.LanguageSettingsModal

import Modal
import antd.Button
import antd.Option
import antd.Select
import app.useAppDispatch
import csstype.AlignItems
import emotion.react.css
import kotlinx.browser.localStorage
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.ELanguageLevel
import pages.onboarding.languageLevelChoice.label
import pages.onboarding.languageLevelChoice.verticalContainer
import pages.onboarding.nativeLanguageChoice.updateUserLanguagesRequest
import pages.studentDuo.SetStudentDuoInterfaceLanguage
import pages.studentDuo.SetStudentDuoLanguageLevel
import pages.studentDuo.SetStudentDuoLanguageToLearn
import pages.studentDuo.selectStudentDuoState
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.redux.useSelector
import react.router.dom.useSearchParams
import react.useEffect
import react.useState
import shared.components.Icon
import utils.localization.Localization
import utils.types.extend
import widgets.LanguageAutoContent.ui.components.SelectLanguage.SelectLanguage
import widgets.LanguageAutoContent.ui.generateButton
import widgets.StudentDuoContent.ui.EStudentDuoSearchParams
import widgets.StudentDuoContent.ui.languagesContainer

val LanguageSettingsModal = FC<Props> {
    val state = useSelector(selectStudentDuoState)
    val (isOpen, setIsOpen) = useState(false)
    val (languageToLearn, setLanguageToLearn) = useState(state.languageToLearn)
    val (interfaceLanguage, setInterfaceLanguage) = useState(state.interfaceLanguage)
    val (languageLevel, setLanguageLevel) = useState(state.languageLevel)
    val dispatch = useAppDispatch()
    val (searchParams, setSearchParams) = useSearchParams()

    useEffect(state.languageLevel) {
        setLanguageLevel(state.languageLevel)
    }

    useEffect(state.languageToLearn) {
        setLanguageToLearn(state.languageToLearn)
    }

    useEffect(state.interfaceLanguage) {
        setInterfaceLanguage(state.interfaceLanguage)
    }

    Button {
        css(button)
        onClick = {
            setIsOpen(true)
        }
        p {
            css(label)
            +Localization.getLanguageSettingsButtonModalText(
                state.interfaceLanguage,
                state.languageToLearn,
                state.languageLevel
            )
        }
        Icon {
            src = "ic_pencil_simple_24x24.svg"
        }
    }
    Modal {
        open = isOpen
        onCancel = {
            setIsOpen(false)
            setInterfaceLanguage(state.interfaceLanguage)
            setLanguageLevel(state.languageLevel)
            setLanguageToLearn(state.languageToLearn)
        }
        footer = null
        div {
            css(container)
            div {
                css(languagesContainer)
                SelectLanguage {
                    label = Localization.getLanguageToLearnLabel(interfaceLanguage)
                    value = languageToLearn
                    this.onChange = {
                        setLanguageToLearn(it)
                    }
                    excludedLanguageValue = interfaceLanguage
                }
                SelectLanguage {
                    label = Localization.getNativeLanguageLabel(interfaceLanguage)
                    value = interfaceLanguage
                    this.onChange = {
                        setInterfaceLanguage(it)
                    }
                }
            }
            div {
                css(verticalContainer)
                p {
                    css(label)
                    +Localization.getLanguageLevelTitle(interfaceLanguage)
                }
                Select {
                    value = ELanguageLevel.fromString(languageLevel)?.toString(interfaceLanguage)
                    onChange = { _, option ->
                        setLanguageLevel(option.label.toString())
                    }
                    ELanguageLevel.entries.forEach {
                        Option {
                            value = it.toString(interfaceLanguage)
                            label = it.value
                        }
                    }
                }
            }
            Button {
                css(generateButton.extend {
                    alignItems = AlignItems.center
                })
                onClick = {
                    dispatch(SetStudentDuoLanguageToLearn(languageToLearn))
                    searchParams.set(EStudentDuoSearchParams.LANGUAGE_TO_LEARN.value, languageToLearn)
                    localStorage.setItem("duo_${EStudentDuoSearchParams.LANGUAGE_TO_LEARN.value}", languageToLearn)

                    dispatch(SetStudentDuoInterfaceLanguage(interfaceLanguage))
                    searchParams.set(EStudentDuoSearchParams.INTERFACE_LANGUAGE.value, interfaceLanguage)
                    localStorage.setItem("duo_${EStudentDuoSearchParams.INTERFACE_LANGUAGE.value}", interfaceLanguage)

                    dispatch(SetStudentDuoLanguageLevel(languageLevel))
                    localStorage.setItem("duo_${EStudentDuoSearchParams.LANGUAGE_LEVEL.value}", languageLevel)

                    setSearchParams(searchParams)
                    setIsOpen(false)

                    GlobalScope.launch {
                        updateUserLanguagesRequest(interfaceLanguage, languageToLearn)
                    }
                }
                +Localization.getApplyButtonText(state.interfaceLanguage)
            }
        }
    }
}
