package online.interactiver.common.duo.courseprogress

import kotlinx.serialization.Serializable

@Serializable
data class PlatformInfo(
    val browserName: String,
    val browserVersion: String,
    val platform: String,
    val platformLanguage: String,
    val screenWidth: Int,
    val screenHeight: Int,
)
