package online.interactiver.common.interactivepicture

import io.ktor.util.*
import kotlinx.serialization.Serializable
import online.interactiver.common.utils.TRUE_STRING
import online.interactiver.common.utils.getTimeMillisInBase
import online.interactiver.common.utils.snakeToCamelCase
import online.interactiver.common.utils.toBase
import kotlin.math.min
import kotlin.random.Random
import kotlin.random.nextULong


// {"name": "static", "uid": "s1k4zl51", "code": "staticRedRect"}
@Serializable
data class Identifier(
    var name: String? = null, // will be displayed to teacher (interactive creator) in interface, logs, ... // konva name?
    var code: String? = null, // may contain latin lower case, digits, '_' - unique not changed identifier // konva id?
    var id: String? = null, // should be invariant through the all of element's lifecycle
    var nameEditedManually: String? = null, // "1" if user manually changed suggested name to not change it automatically
    var codeEditedManually: String? = null // "1" if user manually changed suggested code to not change it automatically
) {
    fun setUniqueCodeByName(name: String = this.name ?: "", maxLength: Int = 17) {
        if (codeEditedManually != TRUE_STRING) {
            val newCode = name.toLowerCasePreservingASCIIRules()
                .replace(Regex("[- \t]"), "_")
                .filter { it == '_' || it.isLetterOrDigit() }
                .snakeToCamelCase()
            code = newCode.slice(0 until min(maxLength, newCode.length))
        }
    }

    fun update(text: String) {
        //for test
        id = getTimeMillisInBase()
        setIdentifierByText(text)
    }

    fun updateWithRand(text: String) {
        id = Random.nextULong().toBase()
        setIdentifierByText(text)
    }

    fun updateCopiedElements(text: String, id: String?) {
        this.id = id
        setIdentifierByText(text)
    }

    fun setIdentifierByText(text: String) {
        if (nameEditedManually != TRUE_STRING) {
            var i = 0
            val maxNamePartLength = 50
            var lastSpaceIndex = maxNamePartLength
            while (i < text.length && i < maxNamePartLength) {
                if (text[i].isWhitespace()) {
                    lastSpaceIndex = i
                }
                i++
            }
            val nameText = if (i == text.length) {
                text
            } else {
                text.substring(0, min(lastSpaceIndex, i - 1))
            }
            setUniqueCodeByName(nameText)
        }
    }


    fun clone() = copy()
}

@Serializable
data class Source(
    var fileName: String? = null,
    var link: String? = null,
) {
    fun clone() = copy()
}