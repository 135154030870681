package widgets.ExerciseSettings.components.NumberOfSlidesToGenerate

import antd.Button
import antd.Input
import app.useAppDispatch
import emotion.react.css
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLInputElement
import pages.constructor.ui.components.elements.blueFilter
import pages.constructor.ui.components.elements.whiteFilter
import react.FC
import react.Props
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import shared.components.Icon
import utils.types.extend
import widgets.ExerciseSettings.SetNumberOfSlidesToGenerateAction
import widgets.ExerciseSettings.saveUserSliderSettingsRequest
import widgets.ExerciseSettings.selectNumberOfSlidesToGenerate

private const val minNumberOfSlidesToGenerate = 6
private const val maxNumberOfSlidesToGenerate = 20

val NumberOfSlidesToGenerate = FC<Props> {
    val numberOfSlides = useSelector(selectNumberOfSlidesToGenerate)

    val dispatch = useAppDispatch()

    if (numberOfSlides == null) {
        return@FC
    }

    val handleNumberOfSlidesChanged = { newNumberOfSlides: Int ->
        dispatch(SetNumberOfSlidesToGenerateAction(newNumberOfSlides))
        GlobalScope.launch {
            saveUserSliderSettingsRequest(numberOfSlidesToGenerate = newNumberOfSlides)
        }
    }

    div {
        css(container)
        Button {
            css(button("#F4F7FF"))
            onClick = f@{
                if (numberOfSlides == minNumberOfSlidesToGenerate) {
                    return@f
                }

                handleNumberOfSlidesChanged(numberOfSlides - 1)
            }
            Icon {
                css(blueFilter.extend(iconCss))
                src = "ic_minus_16x16.svg"
            }
        }
        Input {
            css(inputNumber)
            value = numberOfSlides.toString()
            onPressEnter = { evt: MouseEvent<HTMLInputElement, *> ->
                evt.currentTarget.blur()
            }
            onInput = { evt ->
                val newNumberOfSlides = evt.currentTarget.value.toIntOrNull() ?: 0
                dispatch(SetNumberOfSlidesToGenerateAction(newNumberOfSlides))
            }
            onBlur = {
                val newNumberOfSlides = if (numberOfSlides < minNumberOfSlidesToGenerate) {
                    minNumberOfSlidesToGenerate
                } else if (numberOfSlides > maxNumberOfSlidesToGenerate) {
                    maxNumberOfSlidesToGenerate
                } else {
                    numberOfSlides
                }
                handleNumberOfSlidesChanged(newNumberOfSlides)
            }
        }
        Button {
            css(button("#597EF7"))
            onClick = f@{
                if (numberOfSlides == maxNumberOfSlidesToGenerate) {
                    return@f
                }

                handleNumberOfSlidesChanged(numberOfSlides + 1)
            }
            Icon {
                css(whiteFilter.extend(iconCss))
                src = "ic_plus_16x16.svg"
            }
        }
    }
}