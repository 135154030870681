package app.appState

import app.StoreState
import app.UndoableAction
import app.undoableSelector

data class SetAppState(val state: IAppState) : UndoableAction(false)

val AppStateReducer = { state: IAppState, action: UndoableAction ->
    when (action) {
        is SetAppState -> action.state
        else -> state
    }
}

val selectAppState = undoableSelector({ state: StoreState -> state.appState }) { state: IAppState -> state }
val selectFocusedOptionId = { state: StoreState -> selectAppState(state).getSelectedOptionId() }
