package widgets.ExerciseSettings.components.CustomLogoSettings

import antd.Button
import app.useAppDispatch
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import enums.EButtonTypes
import kotlinx.browser.document
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.user.defaultLink
import online.interactiver.common.user.defaultLogo
import org.w3c.dom.HTMLInputElement
import react.FC
import react.Props
import react.dom.html.InputType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.redux.useSelector
import shared.components.Icon
import utils.fileToBase64WithMaxFileSizeInMb
import widgets.ExerciseSettings.*
import widgets.ExerciseSettings.components.InputWithCustomLink.InputWithCustomLink
import widgets.ExerciseSettings.components.SettingLabel.SettingLabel
import widgets.ExerciseSettings.components.SwitchWithLabel.SwitchWithLabel
import widgets.ExerciseSettings.components.SwitchWithLabel.container

val CustomLogoSettings = FC<Props> {
    val linkInLogo = useSelector(selectLinkInLogo)
    val logo = useSelector(selectLogo)
    val filename = useSelector(selectLogoFileName)
    val dispatch = useAppDispatch()

    val inputImageOnChange = fileToBase64WithMaxFileSizeInMb(
        { dispatch(OpenErrorModal("Sound file must be less 15Kb")) },
        15 / 1024.0
    ) { src, filename ->
        GlobalScope.launch {
            val newSettings = updateLogoRequest(src, filename ?: "")
            if (newSettings == null) {
                dispatch(OpenErrorModal("Error saving custom logo"))
                return@launch
            }
            dispatch(SetLogoAction(newSettings.logo, newSettings.logoFileName))
        }
    }

    div {
        css(container)
        SettingLabel {
            label = if (logo == defaultLogo) "Upload your logo (.png or .jpg less 15Kb)" else filename ?: ""
            hint = ""
        }
        Button {
            css(iconButton)
            Icon {
                src = if (logo == defaultLogo) "ic_upload_down_24x24.svg" else "ic_delete_24x24.svg"
            }
            onClick = f@{
                if (logo == defaultLogo) {
                    val input = document.getElementById(EButtonTypes.USER_SETTINGS_UPLOAD_LOGO_INPUT.value)
                    (input as? HTMLInputElement)?.click()
                    return@f
                }

                GlobalScope.launch {
                    updateLogoRequest(defaultLogo, "")
                }
                dispatch(SetLogoAction(defaultLogo, ""))
            }
        }
    }
    input {
        css(inputHidden)
        id = EButtonTypes.USER_SETTINGS_UPLOAD_LOGO_INPUT.value
        type = InputType.file
        accept = "image/*"
        size = 1
        onChange = inputImageOnChange
    }

    SwitchWithLabel {
        checked = linkInLogo != null
        label = "When clicking on the logo, follow the link"
        hint = ""
        onChange = {
            val newLink = if (it) defaultLink else null

            dispatch(SetLinkInLogoAction(newLink))
            GlobalScope.launch {
                updateLinkInLogoRequest(newLink)
            }
        }
    }

    if (linkInLogo == null) {
        return@FC
    }

    InputWithCustomLink {
        value = linkInLogo
        onChange = {
            dispatch(SetLinkInLogoAction(it))
            GlobalScope.launch {
                updateLinkInLogoRequest(it)
            }
        }
    }
}