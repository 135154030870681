package entities.interactivePicture.elements.figuresAndLines.lines

import online.interactiver.common.interactivepicture.GeometryStyle

val lineStyle = GeometryStyle(
    strokeColor = "#98A2B3",
    strokeWidth = 2,
    fillColor = "#98A2B3",
    opacity = 1.0
)
