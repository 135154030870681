package entities.interactivePicture.elements.editors.hintTextEditor

import app.appState.selectAppState
import app.useAppDispatch
import app.useAppSelector
import builders.enums.EElementType
import builders.enums.EInteractiveType
import csstype.FlexDirection
import entities.interactivePicture.elements.SetHintTextForElements
import entities.interactivePicture.elements.SetSyncingPhrases
import entities.interactivePicture.elements.editors.textEditor.TextEditor
import entities.interactivePicture.selectElementsUnderSelectionRectangle
import entities.interactivePicture.selectFocusedElement
import enums.EButtonTypes
import react.FC
import react.Props
import shared.canvas.interfaces.getValueForEditor
import shared.canvas.interfaces.shouldDrawEditor

val HintTextEditor = FC<Props> {
    val selected = useAppSelector(selectFocusedElement)
    val elementsUnderSelectionRectangle = useAppSelector(selectElementsUnderSelectionRectangle)
    val appState = useAppSelector(selectAppState)
    val dispatch = useAppDispatch()
    val isSynced = selected?.isSynced

    if (selected?.type == EInteractiveType.PHRASE_INTERACTIVE.text) {
        return@FC
    }

    val typesWithHint = EElementType.values().filter { it.usesHintEditor }.map { it.text }

    val disabled = !shouldDrawEditor(selected, elementsUnderSelectionRectangle) { element -> typesWithHint.contains(element.visibleElement.type) }
    val value = if (!disabled) {
        getValueForEditor(selected, elementsUnderSelectionRectangle) { element -> element.hint.text?.simpleText }
    } else {
        ""
    }

    TextEditor {
        id = EButtonTypes.HINT_INPUT.value
        this.disabled = disabled
        header = "Hint"
        onChange = f@{ evt ->
            if (selected == null) {
                val ids = elementsUnderSelectionRectangle.map { it.identifier.id!! }.toTypedArray()
                dispatch(SetHintTextForElements(ids, evt.target.value))
                dispatch(SetSyncingPhrases(ids, false))
                return@f
            }
            dispatch(appState.getSetHintText(selected.identifier.id!!, evt.target.value))
            if (isSynced != null) {
                dispatch(appState.getSetSyncingPhrase(selected.identifier.id!!, false))
            }
        }
        this.value = value
        defaultValue = "Hint"
        placeholder = "Type hint text"
        height = 100
        width = 210
        flexDirection = FlexDirection.column
        fontWeight = 700
    }
}
