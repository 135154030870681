package pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewShareTabs.viewStatisticsTabContent

import kotlinx.browser.window
import org.w3c.dom.url.URL
import react.StateSetter
import react.createContext

data class ScoreDetails(
    val show: Boolean,
    val setShow: StateSetter<Boolean>,
    val currentName: String,
    val displayedName: String,
    val setDisplayedName: StateSetter<String>,
)
data class ViewStatisticsContextData(
    val title: String? = null,
    val setTitle: StateSetter<String>? = null,
    val subtitle: String? = null,
    val setSubtitle: StateSetter<String>? = null,
    val timePeriod: String? = null,
    val setTimePeriod: StateSetter<String>? = null,
    val scoreDetails: Collection<ScoreDetails>? = null,

    val interactiveId: String? = null,
) {
    fun generateInteractiveIdFilter(): String? {
        if (interactiveId.isNullOrBlank()) {
            return null
        }
        return "interactiveId[isEqual]=$interactiveId"
    }

    fun generateTimePeriodFilter(): String? {
        if (timePeriod.isNullOrBlank()) {
            return null
        }
        return "endSecUtc[greaterThan]=$timePeriod"
    }

    fun generateFilters(): String {
        val filters = mutableListOf(
            generateInteractiveIdFilter(),
            generateTimePeriodFilter()
        ).filter { !it.isNullOrBlank() }
        return filters.joinToString(",")
    }

    fun generateAliases(): String? {
        if (scoreDetails == null) {
            return null
        }
        val aliases= scoreDetails.filter { it.show }.map { "${it.currentName}=${it.displayedName}" }
        if (aliases.isEmpty()) {
            return null
        }
        return aliases.joinToString(",")
    }
    fun generateResultsScreenLink(): String {
        val url = URL(window.location.protocol + "//" + window.location.host + "/scores")
        if (!title.isNullOrBlank()){
            url.searchParams.set("title", title)
        }
        if (!subtitle.isNullOrBlank()){
            url.searchParams.set("subtitle", subtitle)
        }

        url.searchParams.set("filter", generateFilters())

        val aliases = generateAliases()
        if (aliases != null){
            url.searchParams.set("alias", aliases)
        }

        return url.toString()
    }
}

val ViewStatisticsContext = createContext(ViewStatisticsContextData())
