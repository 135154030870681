package shared.components.inputLabelledLimited

import antd.Input
import antd.TextArea
import builders.enums.EAlign
import emotion.react.css
import online.interactiver.common.interactiveexercise.EExerciseUiPatternMetaTag
import react.*
import react.dom.html.ReactHTML.div
import shared.components.editableSound.EditableSound
import shared.components.tooltipInfo.TooltipInfo

enum class InputCounterPosition {
    TOP_RIGHT, RIGHT
}

enum class InputType {
    INPUT, TEXTAREA;
}

external interface InputLabelledLimitedProps : PropsWithClassName {
    var value: String?
    var onChange: ((String) -> Unit)?
    var placeholder: String?
    var label: String?
    var limit: Int?
    var counterPosition: InputCounterPosition?
    var counterAlign: EAlign.Horizontal?
    var inputType: InputType?
    var disabled: Boolean?
    var tooltip: dynamic // String? or ReactNode?
    var tooltipOverlayStyle: dynamic
    var soundSrc: String?
    var onEditSoundClick: (() -> Unit)?

    var patternMetaTags: List<EExerciseUiPatternMetaTag>?
    var onPatternMetaTagsChange: ((List<EExerciseUiPatternMetaTag>) -> Unit)?
}

const val DEFAULT_BUILD_PHRASE_RAW_LIMIT = 200

val InputLabelledLimited = FC<InputLabelledLimitedProps> { props ->
    val limit = useMemo(props.limit, callback = {
        props.limit ?: DEFAULT_BUILD_PHRASE_RAW_LIMIT
    })

    val counterPosition = useMemo(props.counterPosition, callback = {
        props.counterPosition ?: InputCounterPosition.TOP_RIGHT
    })

    val counterAlign = useMemo(props.counterAlign, callback = {
        props.counterAlign ?: EAlign.Horizontal.RIGHT
    })

    val inputType = useMemo(props.inputType, callback = {
        props.inputType ?: InputType.INPUT
    })

    val inputComponent = useMemo(inputType, callback = {
        when (inputType) {
            InputType.INPUT -> Input
            InputType.TEXTAREA -> TextArea
        }
    })

    val (value, setValue) = useState(props.value)

    useEffect(props.value) {
        setValue(props.value)
    }

    val patternMetaTags = useMemo(props.patternMetaTags) {
        props.patternMetaTags ?: listOf()
    }

    div {
        css(container)
        div {
            css(labelContainer)
            div {
                css(tooltipWrapper)
                div {
                    css(label)
                    props.label?.let { +it }
                }
                TooltipInfo {
                    title = props.tooltip
                    overlayStyle = props.tooltipOverlayStyle
                }
            }
            EditableSound {
                soundSrc = props.soundSrc
                onEditClick = props.onEditSoundClick

                this.patternMetaTags = props.patternMetaTags
                onPatternMetaTagsChange = props.onPatternMetaTagsChange
            }

            if (counterPosition == InputCounterPosition.TOP_RIGHT) {
                Counter {
                    this.value = value?.length
                    this.limit = limit
                    align = counterAlign
                }
            }
        }
        div {
            css(labelContainer)
            inputComponent {
                className = props.className
                this.value = value
                placeholder = props.placeholder
                onBlur = {
                    val value = it.target.value.take(limit)
                    props.onChange?.invoke(value)
                }
                onInput = {
                    setValue(it.currentTarget.value.take(limit))
                }
                disabled = props.disabled == true
                dir = "auto"
            }
            if (counterPosition == InputCounterPosition.RIGHT) {
                Counter {
                    this.value = value?.length
                    this.limit = limit
                    align = counterAlign
                }
            }
        }
    }
}

external interface CounterProps : Props {
    var value: Int?
    var limit: Int?
    var align: EAlign.Horizontal
}

val Counter = FC<CounterProps> { props ->
    div {
        css(counter(props.align, props.limit.toString().length * 2 + 1)) // double limit + slash i.e. 200/200 = 7 chars
        +"${props.value ?: 0}/${props.limit ?: 0}"
    }
}
