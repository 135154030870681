package pages.studentDuo.ui

import csstype.*
import utils.types.CssStyle
import utils.types.FitContent

val studentStory: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    flexDirection = FlexDirection.column
    backgroundColor = Color("#F4F7FF")
    gap = 40.px
    minHeight = 100.vh
    height = FitContent.fitContent
    fontFamily = string("\"Inter\", system-ui")
    padding = 10.px
    boxSizing = BoxSizing.borderBox
    position = Position.relative
    animation
}

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 5.px
}

val alert: CssStyle = {
    position = Position.absolute
    margin = Margin(0.px, Auto.auto)
    bottom = 70.px
}
