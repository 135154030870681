package widgets.LanguageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.CorrectOrderTaskLineEditor

import emotion.react.css
import online.interactiver.common.autogeneration.TaskLine
import online.interactiver.common.autogeneration.taskLineContent.TextKeyPointsContent
import react.FC
import react.useMemo
import shared.components.inputLabelledLimited.InputLabelledLimited
import shared.components.inputLabelledLimited.InputType
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.TaskLineUiEditorWithSpecifiedPatternProps
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.QuestionWithAnswersEditor.textarea

external interface CorrectOrderTaskLineEditorProps : TaskLineUiEditorWithSpecifiedPatternProps {
    var keyPointsCount: Int
}

val CorrectOrderTaskLineEditor = FC<CorrectOrderTaskLineEditorProps> { props ->
    val taskLine = useMemo(props.taskLine, callback = {
        try {
            TaskLine(props.taskLine)
        } catch(e: TaskLine.Error) {
            null
        }
    })

    val content = useMemo(taskLine, callback = {
        taskLine?.content as? TextKeyPointsContent
    })

    val pattern = useMemo(taskLine) {
        taskLine?.pattern
    }

    if (taskLine == null || content == null || pattern == null) {
        return@FC
    }

    for (i in 0 until props.keyPointsCount) {
        val label = "Key point ${i + 1}"

        InputLabelledLimited {
            css(textarea)
            placeholder = label
            this.label = label
            value = content.keyPoints[i]
            onChange = {
                val newWordMatchPairs = content.keyPoints.toMutableList()
                newWordMatchPairs[i] = it
                val newContent = TextKeyPointsContent(newWordMatchPairs, content.text)

                taskLine.content = newContent
                props.onChange?.invoke(taskLine.toRawTaskLine())
            }
            limit = props.maxWordLength
            inputType = InputType.TEXTAREA
        }
    }
}