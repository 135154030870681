package shared.components.switchWIthLabelAndTooltip

import antd.Switch
import antd.Tooltip
import csstype.Display
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import shared.components.Icon

external interface SwitchWithLabelAndTooltipProps : Props {
    var checked: Boolean
    var onChange: (Boolean) -> Unit
    var label: String
    var tooltipHint: String
}

val SwitchWithLabelAndTooltip = FC<SwitchWithLabelAndTooltipProps> { props ->
    div {
        css(enableButtonContainer)
        div {
            css(textContainer)
            p {
                css(enableText)
                +props.label
            }
            Tooltip {
                title = props.tooltipHint
                placement = "top"
                div {
                    css {
                        display = Display.flex
                    }
                    Icon {
                        src = "ic_info_24x24.svg"
                    }
                }
            }
        }
        Switch {
            checked = props.checked
            onChange = { checked, _ ->
                props.onChange(checked)
            }
        }
    }
}