package widgets.Exercises.ui

import csstype.*
import utils.types.CssStyle

val widget: CssStyle = {
    width = 954.px
    backgroundColor = Color("white")
    padding = 34.px
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 22.px
    borderRadius = 12.px
    boxSizing = BoxSizing.borderBox
}

val rows: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 12.px
}

val filtersContainer: CssStyle = {
    width = 100.pct
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 20.px
}

val headerContainer: CssStyle = {
    width = 100.pct
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
}

val searchButton: CssStyle = {
    border = None.none
    padding = 0.px
    display = Display.flex
    alignItems = AlignItems.center
}

val searchContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    border = Border(1.px, LineStyle.solid, Color("#E3E6EB"))
    gap = 4.px
    padding = Padding(4.px, 8.px)
    borderRadius = 20.px
    width = 60.pct
}

val searchInput: CssStyle = {
    border = None.none
    padding = 0.px
}

val filters: CssStyle = {
    display = Display.flex
    height = 36.px
    gap = 14.px
}

val filter: (Int) -> CssStyle = { widthPx: Int ->
    {
        width = widthPx.px
        border = None.none
        backgroundColor = Color("#F9FAFC")
        color = Color("#98A2B3")
        fontSize = 14.px
        lineHeight = 18.9.px
        fontWeight = integer(400)
    }
}

val table: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 14.px
    width = 100.pct
}

val tableHeader: (Boolean) -> CssStyle = { hasParentFolder ->
    {
        height = 51.px
        display = Display.grid
        gridAutoFlow = GridAutoFlow.column
        gridAutoColumns = "${if (hasParentFolder) "0.9fr " else ""}3fr 2fr 1fr 1fr 2fr 1.2fr 0.3fr".unsafeCast<GridAutoColumns>()
        alignItems = AlignItems.center
        gap = 5.px
        borderBottom = Border(1.px, LineStyle.solid, Color("#E3E6EB"))
    }
}

val dataHeader: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 2.px
}

val filterArrow: (Boolean) -> CssStyle = {
    {
        if (!it) {
            transform = rotate(180.deg)
        }
        border = None.none
        padding = 0.px
        boxShadow = None.none
        display = Display.flex
        alignItems = AlignItems.center
    }
}

val noExercisesContainer: CssStyle = {
    width = 100.pct
    flexGrow = number(1.0)
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
}

val noExercises: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    gap = 22.px
}

val textContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    gap = 6.px
}

val showingCount: CssStyle = {
    textAlign = TextAlign.end
}

val selectFiltersWrapper: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.center
}

val headerWithPrevBtn: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.center
    justifyContent = JustifyContent.stretch
    gap = 6.px
}

val prevBtn: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.center
    padding = 0.px
    cursor = Cursor.pointer

    // these are minor changes to make button look nicely aligned with title
    scale = number(0.9)
    position = Position.relative
    top = 1.px

    hover {
        border = important(None.none)
    }
}

val showMoreContainer: CssStyle = {
    width = 100.pct
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
    paddingTop = 15.px
    borderTop = Border(1.px, LineStyle.solid, rgb(227, 230, 235))
}
