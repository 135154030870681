package widgets.languageAutoTask.ui.components.SlideEditModal.patternMetaTagsEditor.radioModeMetaTagEditor

import emotion.react.css
import online.interactiver.common.interactiveexercise.EExerciseUiPatternMetaTag
import react.FC
import react.dom.html.ReactHTML.div
import react.useMemo
import react.useState
import shared.components.radio.Radio
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.PatternMetaTagsEditorProps

/**
 * This represents radio buttons inner content
 *
 * ! Make sure only one enum entry has associatedTag as null which indicates default behavior !
 */
enum class RadioModeMetaTagEditorChoices(
    val associatedTag: EExerciseUiPatternMetaTag?,
    val uiLabel: String = associatedTag?.uiLabel ?: "one answer"
) {
    ONE_ANSWER(null),
    MULTIPLE_ANSWERS(EExerciseUiPatternMetaTag.NO_RADIO);

    companion object {
        fun fromUiLabel(uiLabel: String) = entries.find { it.uiLabel == uiLabel }
    }
}

val RadioModeMetaTagEditor = FC<PatternMetaTagsEditorProps> { props ->
    val value = useMemo(props.value) {
        props.value ?: listOf()
    }

    val (curChoice, setCurChoice) = useState(
        RadioModeMetaTagEditorChoices.entries.find { value.contains(it.associatedTag) } ?:
        RadioModeMetaTagEditorChoices.entries.find { it.associatedTag == null }!!
    )

    div {
        div {
            css(header)
            +"Answer picking mode"
        }
        div {
            css(grid)
            RadioModeMetaTagEditorChoices.entries.map {
                Radio {
                    this.value = it.uiLabel
                    onChange = { uiLabel ->
                        val newTag = RadioModeMetaTagEditorChoices.fromUiLabel(uiLabel)!!.associatedTag
                        val prevTag = curChoice.associatedTag
                        val newValue = value.toMutableList()
                        val indexOfPrevTag = value.indexOf(prevTag)

                        if (indexOfPrevTag >= 0) {
                            if (newTag != null) {
                                newValue[indexOfPrevTag] = newTag
                            } else {
                                newValue.removeAt(indexOfPrevTag)
                            }
                        } else {
                            if (newTag != null) {
                                newValue.add(newTag)
                            }
                        }

                        setCurChoice(it)
                        props.onChange?.invoke(newValue.toList())
                    }
                    label = it.uiLabel
                    current = curChoice.uiLabel
                }
            }
        }
    }
}
