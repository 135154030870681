package app

import app.appState.selectAppState
import builders.enums.EPhraseType
import createInteractiveJSONRequest
import entities.alert.*
import entities.backgroundPosScale.selectBackgroundPosition
import entities.backgroundPosScale.selectBgBasedLinearTransformation
import entities.interactivePicture.*
import entities.interactivePicture.background.selectCanvasHeight
import entities.interactivePicture.background.selectCanvasWidth
import entities.interactivePicture.elements.MoveSelectedElements
import entities.interactivePicture.elements.RemoveMultipleElements
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import entities.saver.*
import entities.selectedElement.SelectElement
import entities.selectedElement.SelectMultipleElements
import entities.selectedElement.selectElementsIdsUnderSelectionRectangle
import enums.EKeyboardArrows
import generateHtmlInteractiveByUuid
import getInteractivePublicLink
import getTextFromSoundRequest
import io.ktor.util.date.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.Job
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.js.timers.*
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import makeGenerateAndDownloadInteractivePictureRequest
import online.interactiver.common.EBackendError
import online.interactiver.common.admin.interactive.InteractiveResponse
import online.interactiver.common.admin.interactive.googlecloud.SpeechToTextResponse
import online.interactiver.common.export.ExportData
import online.interactiver.common.interactivepicture.InteractiveElement
import online.interactiver.common.interactivepicture.InteractivePicture
import online.interactiver.common.interactivepicture.PhraseStyle
import online.interactiver.common.utils.export
import online.interactiver.common.utils.getTimeMillisInBase
import online.interactiver.common.utils.getTimeMillisInULong
import online.interactiver.common.utils.toBase
import org.w3c.dom.BeforeUnloadEvent
import org.w3c.dom.Element
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTextAreaElement
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent
import pages.constructor.ui.components.header.interactiveParams.getInteractiveResponse
import pages.constructor.ui.components.header.fileButton.copyInteractive
import pages.constructor.ui.redirectToInteractive
import react.*
import react.redux.useDispatch
import react.redux.useSelector
import reactkonva.useImage
import redux.RAction
import saveInteractiveJSONRequest
import shared.canvas.implementations.PHRASE_LINE_SPACING_FOR_UNPLACED_PUZZLES
import shared.canvas.implementations.PHRASE_PUZZLES_GROUP_PADDING
import shared.canvas.interfaces.*
import shared.canvas.utils.EDGE_PADDING
import updateInteractiveHTMLByPublicLink
import utils.downloadInteractiveHtml
import utils.extractUUIDFromUrl
import utils.measureHeightAsPhrase
import utils.structures.Position
import utils.uuidToInteractivePicture
import kotlin.random.Random
import kotlin.random.nextULong
import kotlin.time.Duration

fun useAppDispatch() = useDispatch<RAction, RAction>()
fun <U> useAppSelector(selector: (StoreState) -> U): U = useSelector(selector)
fun <U> useAppSelector(selector: (StoreState) -> Array<U>): Array<U> = useSelector(selector)
fun useAppImage(src: String): dynamic = useImage(src, "Anonymous")
fun useBackgroundCenteredPosition(width: Int = 100, height: Int = 40): Position {
    val backgroundPosition = useAppSelector(selectBackgroundPosition)
    val x = (backgroundPosition.x + backgroundPosition.width / 2.0).toInt() - width / 2
    val y = (backgroundPosition.y + backgroundPosition.height / 2.0).toInt() - height / 2
    return Position(x, y, width, height)
}

fun useHotKeys() {
    val dispatch = useAppDispatch()
    val focusedElement = useAppSelector(selectFocusedElement)
    val canRedo = useAppSelector(selectCanRedo)
    val canUndo = useAppSelector(selectCanUndo)
    val appState = useAppSelector(selectAppState)
    val elementsIdsUnderSelectionRectangle = useAppSelector(selectElementsIdsUnderSelectionRectangle)
    val elementsUnderSelectionRectangle = useAppSelector(selectElementsUnderSelectionRectangle)

    val isTextAreaActive = f@{ activeElement: Element? ->
        return@f (activeElement is HTMLTextAreaElement ||
                activeElement is HTMLInputElement) && activeElement.clientWidth != 0
    }

    useEffect(focusedElement, elementsIdsUnderSelectionRectangle) {
        if (focusedElement == null && elementsIdsUnderSelectionRectangle.isEmpty()) {
            return@useEffect
        }

        val onRemoveElement = { evt: Event ->
            val event = evt as KeyboardEvent
            val activeElement = document.activeElement
            if (event.removeIsPressed() && !isTextAreaActive(activeElement)) {
                event.preventDefault()
                if (focusedElement != null) {
                    dispatch(appState.getRemoveElement(focusedElement.identifier.id!!))
                }
                if (elementsIdsUnderSelectionRectangle.isNotEmpty()) {
                    dispatch(RemoveMultipleElements(elementsIdsUnderSelectionRectangle))
                    dispatch(SelectMultipleElements(arrayOf()))
                }
            }
        }
        document.addEventListener("keyup", onRemoveElement)

        val onCopyElement = { evt: Event ->
            val event = evt as KeyboardEvent
            val activeElement = document.activeElement
            if (event.copyIsPressed() && !isTextAreaActive(activeElement)) {
                event.preventDefault()
                if (focusedElement != null) {
                    val jsonString = Json.encodeToString(focusedElement)
                    window.navigator.clipboard.writeText(jsonString).catch {
                        console.log(it)
                    }
                } else if (elementsIdsUnderSelectionRectangle.isNotEmpty()) {
                    val jsonString = Json.encodeToString(elementsUnderSelectionRectangle)
                    window.navigator.clipboard.writeText(jsonString).catch {
                        console.log(it)
                    }
                }
            }
        }
        document.addEventListener("keydown", onCopyElement)

        val onMoveElements = f@{ evt: Event ->
            val event = evt as KeyboardEvent
            val activeElement = document.activeElement
            if (!event.arrowIsPressed() || isTextAreaActive(activeElement)) {
                return@f
            }

            if (focusedElement != null) {
                dispatch(appState.getMoveSelectedElements(arrayOf(focusedElement.identifier.id!!), event.key))
            }

            if (elementsIdsUnderSelectionRectangle.isNotEmpty()) {
                dispatch(MoveSelectedElements(elementsIdsUnderSelectionRectangle, event.key))
            }
        }

        document.addEventListener("keydown", onMoveElements)

        cleanup {
            document.removeEventListener("keydown", onCopyElement)
            document.removeEventListener("keyup", onRemoveElement)
            document.removeEventListener("keydown", onMoveElements)
        }
    }

    useEffect(canUndo, canRedo) {
        val onPasteElement = { evt: Event ->
            val event = evt as KeyboardEvent
            val activeElement = document.activeElement
            if (event.pasteIsPressed() && !isTextAreaActive(activeElement)) {
                event.preventDefault()
                window.navigator.clipboard.readText().then {
                    if (!it.startsWith("{") && !it.startsWith("[")) {
                        return@then
                    }
                    if (it.startsWith("{")) {
                        val copiedElement = Json.decodeFromString<InteractiveElement>(it)
                        val newId = getTimeMillisInBase()
                        dispatch(AddCopiedElements(arrayOf(newId), mutableListOf(copiedElement)))
                        dispatch(SelectElement(newId))
                        return@then
                    }

                    val copiedElements = Json.decodeFromString<MutableList<InteractiveElement>>(it)
                    val newIds = copiedElements.indices.map { index -> (getTimeMillis() + index).toULong().toBase() }
                    dispatch(AddCopiedElements(newIds.toTypedArray(), copiedElements))
                    dispatch(SelectMultipleElements(newIds.toTypedArray()))
                }.catch {
                    console.log(it)
                }
            }
        }
        document.addEventListener("keydown", onPasteElement)

        val onRedo = { evt: Event ->
            val event = evt as KeyboardEvent
            val activeElement = document.activeElement
            if (event.redoIsPressed() && !isTextAreaActive(activeElement) && canRedo) {
                event.preventDefault()
                dispatch(Redo())
            }
        }
        document.addEventListener("keydown", onRedo)

        val onUndo = { evt: Event ->
            val event = evt as KeyboardEvent
            val activeElement = document.activeElement
            if (event.undoIsPressed() && !isTextAreaActive(activeElement) && canUndo) {
                event.preventDefault()
                dispatch(Undo())
            }
        }
        document.addEventListener("keydown", onUndo)
        cleanup {
            document.removeEventListener("keydown", onPasteElement)
            document.removeEventListener("keydown", onRedo)
            document.removeEventListener("keydown", onUndo)
        }
    }
}

fun KeyboardEvent.removeIsPressed(): Boolean {
    return this.key == "Delete" || this.key == "Backspace"
}

fun KeyboardEvent.copyIsPressed(): Boolean {
    return this.ctrlKey && this.keyCode == "C".first().code
}

fun KeyboardEvent.pasteIsPressed(): Boolean {
    return this.ctrlKey && this.keyCode == "V".first().code
}

fun KeyboardEvent.redoIsPressed(): Boolean {
    return this.ctrlKey && this.shiftKey && this.keyCode == "Z".first().code
}

fun KeyboardEvent.undoIsPressed(): Boolean {
    return this.ctrlKey && !this.shiftKey && this.keyCode == "Z".first().code
}

fun KeyboardEvent.arrowIsPressed(): Boolean {
    return this.key in EKeyboardArrows.values().map { it.value }
}

suspend fun copyInteractive(
    dispatch: (RAction) -> RAction,
    interactiveGroup: Int,
    saveableInteractivePicture: InteractivePicture,
    beforeUnloadHandler: (BeforeUnloadEvent) -> Unit,
    setShowModalUpgradePlan: StateSetter<Boolean>,
    onAfterCopy: () -> Unit
) {
    val response = copyInteractive(saveableInteractivePicture, interactiveGroup)
    val error = response.ToBackendError()
    when (error) {
        null -> {
            val createResponseDeserialized = getInteractiveResponse(response.content)
            val uuid = createResponseDeserialized.uuid
            if (!uuid.isNullOrBlank()) {
                @Suppress("UNCHECKED_CAST")
                window.removeEventListener("beforeunload", beforeUnloadHandler as ((Event) -> Unit)?)
                window.open("/editor/$uuid")
            }
        }

        EBackendError.TARIFF_LIMIT -> {
            handleTariffLimitError(setShowModalUpgradePlan)
        }

        else -> {
            handleUnknownError(dispatch, EAlertTarget.CONSTRUCTOR)
        }
    }
    onAfterCopy()
}

fun useCopyInteractive(): (() -> Unit) -> Job? {
    val saveableInteractivePicture = useAppSelector(selectInteractivePicture)
    val interactiveGroup = useAppSelector(selectInteractiveGroup)
    val metaContext = useContext(ConstructorMetaContext)
    val dispatch = useAppDispatch()

    return useCallback(
        dispatch,
        saveableInteractivePicture,
        interactiveGroup,
        metaContext.beforeUnloadListener,
        metaContext.setShowModalUpgradePlan
    ) { onAfterCopy: () -> Unit ->
        MainScope().launch {
            if (interactiveGroup == null) {
                return@launch
            }

            copyInteractive(
                dispatch,
                interactiveGroup,
                saveableInteractivePicture,
                metaContext.beforeUnloadListener,
                metaContext.setShowModalUpgradePlan!!,
                onAfterCopy
            )
        }
    }
}

fun updateInteractive(
    dispatch: (RAction) -> RAction,
    remoteInteractivePicture: InteractivePicture,
    onAfterSaved: () -> Unit
) {
    dispatch(SetSaverProcessing())

    dispatch(SetInteractivePicture(remoteInteractivePicture, false))
    dispatch(SetSaverDoneSuccess())
    onAfterSaved()
}

fun useUpdateInteractive(): (() -> Unit) -> Job? {
    val interactiveGroup = useAppSelector(selectInteractiveGroup)

    val dispatch = useAppDispatch()
    return useCallback(dispatch) { onAfterSaved: () -> Unit ->
        MainScope().launch {
            if (interactiveGroup == null) {
                return@launch
            }

            val uuid = extractUUIDFromUrl(window.location.href)
            val creatingNewInteractive = uuid.isNullOrBlank()
            val remoteSavedPicture =
                (if (creatingNewInteractive) null else uuidToInteractivePicture(uuid!!)) ?: return@launch

            updateInteractive(dispatch, remoteSavedPicture, onAfterSaved)
        }
    }
}

fun handleDifferenceBetweenLocalAndRemoteSaves(
    setShowModalVersionDiff: StateSetter<Boolean>,
    onAfterSaved: () -> Unit
) {
    setShowModalVersionDiff(true)
    onAfterSaved()
}

suspend fun saveExistingInteractive(
    dispatch: (RAction) -> RAction,
    interactiveGroup: Int,
    interactiveName: String,
    saveableInteractivePicture: InteractivePicture,
    setShowModalUpgradePlan: StateSetter<Boolean>,
    onAfterSaved: () -> Unit
) {
    dispatch(SetSaverProcessing())
    val currentTime = getTimeMillisInULong()
    saveableInteractivePicture.lastSavedTime = currentTime

    val uuid = extractUUIDFromUrl(window.location.href)
        ?: throw IllegalArgumentException("Missing uuid on saving existing interactive.")

    val saveJsonResponse = MainScope().async {
        saveInteractiveJSONRequest(
            interactiveGroup, interactiveName, saveableInteractivePicture
        )
    }.await()
    val interactivePublicLink = MainScope().async {
        getInteractivePublicLink(uuid)
    }.await()

    var updatedInteractiveHTMLOnPublicLinkLocation = interactivePublicLink.code == 200
    if (interactivePublicLink.code == 200) {
        if (interactivePublicLink.content.isNotBlank()) {
            val updateInteractivePublicLinkResponse = MainScope().async {
                updateInteractiveHTMLByPublicLink(uuid)
            }.await()
            if (updateInteractivePublicLinkResponse.code != 200) {
                updatedInteractiveHTMLOnPublicLinkLocation = false
            }
        }
    }

    when {
        saveJsonResponse.code == 200 && updatedInteractiveHTMLOnPublicLinkLocation -> {
            store.getState().interactivePicture.present.lastSavedTime = currentTime
            if (saveJsonResponse.content.isNotBlank()) {
                val interactivePictureFromServer = getInteractiveResponse(saveJsonResponse.content).interactivePicture
                if (interactivePictureFromServer != null) {
                    dispatch(ReplaceInteractivePicture(interactivePictureFromServer, false))
                }
            }
            dispatch(SetSaverDoneSuccess())
        }

        saveJsonResponse.ToBackendError() == EBackendError.TARIFF_LIMIT -> {
            handleTariffLimitError(setShowModalUpgradePlan)
            dispatch(SetSaverDoneFail())
        }
    }
    onAfterSaved()
}

fun handleTariffLimitError(
    setShowModalUpgradePlan: StateSetter<Boolean>
) {
    setShowModalUpgradePlan(true)
}

fun handleUnknownError(
    dispatch: (RAction) -> RAction,
    target: EAlertTarget
) {
    val duration = 5000
    dispatch(
        ShowAutoCloseAlert(
            EBackendError.UNKNOWN.content,
            EAlertType.ERROR,
            target,
            duration
        )
    )
}

suspend fun saveNewInteractive(
    dispatch: (RAction) -> RAction,
    interactiveGroup: Int,
    interactiveName: String,
    saveableInteractivePicture: InteractivePicture,
    beforeUnloadHandler: (BeforeUnloadEvent) -> Unit,
    setShowModalUpgradePlan: StateSetter<Boolean>,
    onAfterSaved: () -> Unit
) {
    dispatch(SetSaverProcessing())
    dispatch(StartModalLoading("Saving interactive"))
    val currentTime = getTimeMillisInULong()
    saveableInteractivePicture.lastSavedTime = currentTime
    val response = MainScope().async {
        createInteractiveJSONRequest(
            interactiveGroup, interactiveName, saveableInteractivePicture
        )
    }.await()
    console.log(response.content)
    val error = response.ToBackendError()
    when (error) {
        null -> {
            dispatch(SetInteractivePicture(saveableInteractivePicture, false))
            dispatch(SetSaverDoneSuccess())
            val interactiveResponse = getInteractiveResponse(response.content)
            @Suppress("UNCHECKED_CAST")
            window.removeEventListener("beforeunload", beforeUnloadHandler as ((Event) -> Unit)?)
            redirectToInteractive(interactiveResponse.uuid ?: "")
        }

        EBackendError.TARIFF_LIMIT -> {
            handleTariffLimitError(setShowModalUpgradePlan)
            dispatch(SetSaverDoneFail())
        }

        else -> {
            handleUnknownError(dispatch, EAlertTarget.CONSTRUCTOR)
        }
    }

    dispatch(EndModalLoading())
    onAfterSaved()
}

fun saveInteractive(
    dispatch: (RAction) -> RAction,
    saveable: Boolean,
    interactiveGroup: Int?,
    interactiveName: String?,
    lastSavedTime: ULong?,
    saveableInteractivePicture: InteractivePicture,
    beforeUnloadHandler: (BeforeUnloadEvent) -> Unit,
    showModalVersionDiff: Boolean,
    setShowModalVersionDiff: StateSetter<Boolean>,
    setShowModalUpgradePlan: StateSetter<Boolean>,
    onAfterSaved: () -> Unit = { }
) = MainScope().launch {
    if (interactiveGroup == null || interactiveGroup == 0 || showModalVersionDiff) return@launch

    val uuid = extractUUIDFromUrl(window.location.href)
    val creatingNewInteractive = uuid.isNullOrBlank()
    val remoteSavedPicture = if (creatingNewInteractive) null else uuidToInteractivePicture(uuid!!)
    val remoteSavedTime: ULong? = remoteSavedPicture?.lastSavedTime
    val remoteIsNewer = (remoteSavedTime ?: ULong.MIN_VALUE) > (lastSavedTime ?: ULong.MIN_VALUE)
    val remoteIsOlder = !remoteIsNewer

    when {
        creatingNewInteractive && saveable -> saveNewInteractive(
            dispatch,
            interactiveGroup,
            interactiveName ?: "",
            saveableInteractivePicture,
            beforeUnloadHandler,
            setShowModalUpgradePlan,
            onAfterSaved
        )

        remoteIsNewer -> handleDifferenceBetweenLocalAndRemoteSaves(
            setShowModalVersionDiff,
            onAfterSaved
        )

        remoteIsOlder && saveable -> saveExistingInteractive(
            dispatch,
            interactiveGroup,
            interactiveName ?: "",
            saveableInteractivePicture,
            setShowModalUpgradePlan,
            onAfterSaved
        )

        else -> onAfterSaved()
    }
}

fun useSaveInteractive(): (() -> Unit) -> Job? {
    val saveableInteractivePicture = useAppSelector(selectInteractivePicture)
    val interactiveName = useAppSelector(selectInteractiveName)
    val interactiveGroup = useAppSelector(selectInteractiveGroup)
    val saver = useAppSelector(selectSaver)
    val saveable = useAppSelector(selectSaveable)

    val metaContext = useContext(ConstructorMetaContext)

    val dispatch = useAppDispatch()

    return useCallback(
        saveableInteractivePicture,
        interactiveName,
        interactiveGroup,
        saver,
        dispatch,
        saveable,
        metaContext,
        dispatch
    ) { onAfterSaved: () -> Unit ->
        saveInteractive(
            dispatch,
            saveable,
            interactiveGroup,
            interactiveName,
            saver.lastSavedTime,
            saveableInteractivePicture,
            metaContext.beforeUnloadListener,
            metaContext.showModalVersionDiff,
            metaContext.setShowModalVersionDiff!!,
            metaContext.setShowModalUpgradePlan!!,
            onAfterSaved
        )
    }
}

fun useAutoSave(period: Duration) {
    val saveableInteractivePicture = useAppSelector(selectInteractivePicture)
    val interactiveName = useAppSelector(selectInteractiveName)
    val interactiveGroup = useAppSelector(selectInteractiveGroup)
    val saver = useAppSelector(selectSaver)
    val saveable = useAppSelector(selectSaveable)

    val metaContext = useContext(ConstructorMetaContext)

    val dispatch = useAppDispatch()

    val dispatchRef = useRef(dispatch)
    dispatchRef.current = dispatch
    val interactiveNameRef = useRef(interactiveName)
    interactiveNameRef.current = interactiveName
    val interactiveGroupRef = useRef(interactiveGroup)
    interactiveGroupRef.current = interactiveGroup
    val saveableRef = useRef(saveable)
    saveableRef.current = saveable
    val lastSavedTimeRef = useRef(saver.lastSavedTime)
    lastSavedTimeRef.current = saver.lastSavedTime
    val saveableInteractivePictureRef = useRef(saveableInteractivePicture)
    saveableInteractivePictureRef.current = saveableInteractivePicture
    val metaContextRef = useRef(metaContext)
    metaContextRef.current = metaContext

    useEffectOnce {
        val timer = setInterval(period) {
            saveInteractive(
                dispatchRef.current!!,
                saveableRef.current!!,
                interactiveGroupRef.current,
                interactiveNameRef.current,
                lastSavedTimeRef.current,
                saveableInteractivePictureRef.current!!,
                metaContextRef.current!!.beforeUnloadListener,
                metaContextRef.current!!.showModalVersionDiff,
                metaContextRef.current!!.setShowModalVersionDiff!!,
                metaContext.setShowModalUpgradePlan!!
            )
        }

        cleanup { clearInterval(timer) }
    }
}

fun useDownloadInteractiveHTML(): () -> Unit {
    val dispatch = useAppDispatch()
    val gapsAmount = useAppSelector(selectGapPuzzles)?.size
    val interactivePicture = useAppSelector(selectInteractivePicture)
    val linearTransformation = useAppSelector(selectBgBasedLinearTransformation)

    return useCallback(dispatch, gapsAmount, interactivePicture, linearTransformation) {
        dispatch(StartModalLoading("Generating HTML file, please wait"))
        val exportData = ExportData(gapsAmount)
        val interactiveToDownload =
            interactivePicture.clone(linearTransformation).export(exportData)
        MainScope().launch {
            val content =
                makeGenerateAndDownloadInteractivePictureRequest(interactiveToDownload)
                //makeGenerateAndDownloadMultitaskTestRequest(interactiveToDownload)
            downloadInteractiveHtml(
                interactivePicture.identifier.name ?: "interactive",
                content
            )
            dispatch(EndModalLoading())
        }
    }
}

fun useUpdateInteractiveLink(target: EAlertTarget, interactiveLink: String?, setInteractiveLink: StateSetter<String?>):
            (((String?) -> Unit)?, () -> Unit) -> Unit {
    val dispatch = useAppDispatch()
    val saveInteractive = useSaveInteractive()
    val saver = useAppSelector(selectSaver)
    val saveable = useAppSelector(selectSaveable)
    val uuid = extractUUIDFromUrl(window.location.href)
    return useCallback(
        interactiveLink,
        saveInteractive,
        saver,
        saveable,
        dispatch
    ) { onAfterLinkLoaded: ((String?) -> Unit)?, onError: () -> Unit ->
        if (uuid == null || uuid == "") {
            return@useCallback
        } else {
            if (interactiveLink == null || saveable) {
                val savingInfoAlertId = Random.nextULong().toBase()
                dispatch(
                    ShowAlert(
                        savingInfoAlertId, "Saving your interactive...",
                        EAlertType.INFO, target
                    )
                )
                MainScope().launch {
                    val saveJob = saveInteractive { dispatch(CloseAlert(savingInfoAlertId)) }
                    saveJob?.join() // we should wait until the saving is done
                    val preparingInfoAlertId = Random.nextULong().toBase()
                    dispatch(
                        ShowAlert(
                            preparingInfoAlertId, "Your interactive link is being prepared...",
                            EAlertType.INFO, target
                        )
                    )
                    val generateHtmlResponse = async { generateHtmlInteractiveByUuid(uuid) }.await()
                    val updatePublicResponse = async { updateInteractiveHTMLByPublicLink(uuid)}.await()
                    dispatch(CloseAlert(preparingInfoAlertId))
                    if (generateHtmlResponse.code == 200 && updatePublicResponse.code == 200) {
                        val decodeFromString =
                            Json {
                                ignoreUnknownKeys = true
                                explicitNulls = false
                            }.decodeFromString<InteractiveResponse>(updatePublicResponse.component1())
                        val link = decodeFromString.defaultPublicLink ?: decodeFromString.interactivesLink
                        onAfterLinkLoaded?.invoke(link)
                        setInteractiveLink(link)
                    } else {
                        onError()
                    }
                }
            } else {
                onAfterLinkLoaded?.invoke(interactiveLink)
            }
        }
    }
}

fun usePhraseCenteredPosition(phrase: String, type: EPhraseType, style: PhraseStyle, width: Int): Position {
    val canvasHeight = useAppSelector(selectCanvasHeight) ?: Int.MAX_VALUE
    val canvasWidth = useAppSelector(selectCanvasWidth) ?: Int.MAX_VALUE
    val (y, setY) = useState(0)

    val x = EDGE_PADDING

    useEffect(phrase, type, style) {
        val height = when (type) {
            EPhraseType.WORD_EXPLORER -> phrase.measureHeightAsPhrase(style, width, false)
            EPhraseType.SENTENCE_BUILDER -> phrase.measureHeightAsPhrase(style, width, true) +
                    phrase.measureHeightAsPhrase(
                        style.copy(lineSpacing = PHRASE_LINE_SPACING_FOR_UNPLACED_PUZZLES),
                        width,
                        false
                    ) +
                    PHRASE_PUZZLES_GROUP_PADDING
        }
        setY((canvasHeight - height) / 2)
    }

    return Position(x, y, width, Int.MAX_VALUE)
}

suspend fun getTextFromSound(base64: String, language: String, seconds: Int): String? {
    val response = getTextFromSoundRequest(base64, language, seconds)
    if (response.code != 200) {
        return null
    }

    return Json.decodeFromString<SpeechToTextResponse>(response.content).text
}

data class DebouncedStateResult<T>(
    val state: T,
    val setState: (T, () -> Unit) -> Unit,
)

fun <T : Any>useDebouncedState(initialValue: T, milliseconds: Int): DebouncedStateResult<T> {
    val (timeoutId, setTimeoutId) = useState<Timeout>()
    val (state, setState) = useState(initialValue)

    return DebouncedStateResult(
        state = state,
        setState = { newState, callback ->
            setState(newState)
            if (timeoutId != null) {
                clearTimeout(timeoutId)
            }

            val id = setTimeout(
                callback,
                milliseconds
            )

            setTimeoutId(id)
        }
    )
}