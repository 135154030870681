package pages.constructor.ui.components.advancedElementsSettings.advancedSelectorSettings

import csstype.*
import utils.types.CssStyle

val header: CssStyle = {
    fontFamily = string("\"Arial\", system-ui")
    fontSize = 18.px
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    lineHeight = 130.pct

    color = Color("#282F3E")

    marginBottom = 31.px
}

val elementTypeHeader: CssStyle = {
    fontFamily = string("\"Arial\", system-ui")
    fontSize = 14.px
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    lineHeight = 130.pct

    color = Color("#98A2B3")

    textTransform = TextTransform.uppercase
}

val optionSubHeader: CssStyle = {
    fontFamily = string("\"Arial\", system-ui")
    fontSize = 12.px
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    lineHeight = 130.pct

    color = Color("#5D6676")
}

val optionContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    justifyContent = JustifyContent.center
    alignItems = AlignItems.start
    gap = 8.px
    width = 100.pct
}

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    justifyContent = JustifyContent.center
    alignItems = AlignItems.start
    gap = 18.px

    width = 33.pct
    minWidth = 240.px
}

val inputsContainer: CssStyle = {
    width = 100.pct
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.start
    alignItems = AlignItems.center
    gap = 10.px
}

val nameSelector: CssStyle = {
    flex = number(1.0)
}

val valueInput: CssStyle = {
    flex = number(0.0)
    minWidth = 50.px
}

val addScoreButton: CssStyle = {
    fontFamily = string("\"Arial\", system-ui")
    fontSize = 10.px
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    lineHeight = 130.pct

    color = Color("#2F54EB")

    border = None.none
    padding = 0.px
}

val deleteScoreButton: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    padding = 0.px
    border = None.none
}

val fakeDeleteButtonContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    padding = 0.px
    border = None.none
    width = 24.px // icon's default width
}
