package widgets.CourseGenerationType.ui.CourseGenerationType

import csstype.*
import utils.types.CssStyle

val courseGeneration: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    width = 100.pct
    gap = 26.px
}

val types: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 15.px
    flexWrap = FlexWrap.wrap
}
