package pages.googleAuth

import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*
import jsonClient
import utils.getToken

suspend fun updateUserChannel(channel: String): Boolean {
    return try {
        val response = jsonClient.put("api/v1/user-account/update-channel/${channel}") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        response.status.value == 200
    } catch (e: ResponseException) {
        false
    }
}
