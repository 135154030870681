package widgets.UserProfile.ui.components.InputWithUserData

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 14.px
    alignItems = AlignItems.flexStart
    width = 100.pct
}

val label: CssStyle = {
    margin = 0.px
    color = Color("#BDBDBD")
    textTransform = TextTransform.uppercase
    fontSize = 12.px
    fontWeight = FontWeight.normal
    lineHeight = 135.pct
}

val inputCss: CssStyle = {
    backgroundColor = Color("#f9fafc")
    padding = 10.px
    border = None.none
    borderRadius = 8.px
    color = Color("#282f3e")
    fontSize = 14.px
    lineHeight = 135.pct
    resize = important(None.none)
    margin = 0.px
    width = 100.pct
    height = 64.px
    boxSizing = BoxSizing.borderBox
}

val changeButton: CssStyle = {
    alignSelf = AlignSelf.flexEnd
    border = None.none
    boxShadow = None.none
    margin = 0.px
    color = Color("#BDBDBD")
    fontSize = 14.px
    fontWeight = FontWeight.normal
    lineHeight = 135.pct
    background = None.none
    padding = 0.px
}