package online.interactiver.common.admin.interactive

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Dictionary(
    val id: Int? = null,
    @SerialName("name")
    var name: String,
    @SerialName("owner_id")
    val ownerId: Int?,
    @SerialName("explanation_language")
    var explanationLanguage: String? = null,
    @SerialName("material_language")
    var materialLanguage: String? = null
)
