package widgets.StudentDuoSlider.ui

import antd.Button
import app.useAppDispatch
import csstype.*
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import entities.solvedTasks.getDaysWithTasksRequest
import io.ktor.client.fetch.*
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.js.jso
import org.w3c.dom.MessageEvent
import org.w3c.dom.events.Event
import pages.studentDuo.*
import pages.studentDuo.ui.components.UsedGenerations.NUMBER_OF_FREE_ATTEMPTS
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.iframe
import react.redux.useSelector
import react.router.useNavigate
import react.router.useParams
import reactga.ReactGA
import utils.localization.Localization
import utils.types.extend
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton
import widgets.LanguageAutoSlider.ui.iFrame
import widgets.LanguageAutoTask.ui.components.HiddenIframe.getIframeWithDuoScript
import widgets.StudentDuoLayout.StudentDuoLayout
import widgets.StudentDuoLayout.headerFooterAndPaddingHeight
import widgets.UserProfile.selectStudentDuoAutoSoundExercise

external interface StudentDuoSliderProps : Props {
    var onContinueClick: (() -> Unit)?
    var onSolved: (() -> Unit)?
    var showBackButton: Boolean?
}

const val iFrameScale = 1.3
const val padding = 20
const val maxWidth = 605

val StudentDuoSlider = FC<StudentDuoSliderProps> { props ->
    val nativeLanguage = useSelector(selectRequiredStudentDuoNativeLanguage)
    val dispatch = useAppDispatch()

    val (slider, setSlider) = useState<String>()
    val numberOfUsedGenerations = useSelector(selectStudentDuoNumberOfUsedGenerations)
    val isBasicTariffPlan = useSelector(selectIsBasicTariffPlan)
    val autoSoundExercise = useSelector(selectStudentDuoAutoSoundExercise)
    val navigate = useNavigate()
    val pathParams = useParams()
    val requestUuid = pathParams["request_uuid"]
    val folderId = pathParams["folder_id"]?.toIntOrNull()

    useEffect(requestUuid) {
        if (requestUuid == null) {
            navigate("/duo")
            return@useEffect
        }

        dispatch(StartModalLoading("Loading exercise..."))
        fetch("https://do.inream.com/$requestUuid.html").then {
            it.text()
        }.then {
            setSlider(it)
        }.catch {
            dispatch(OpenErrorModal("Error getting exercise..."))
            dispatch(EndModalLoading())
        }
    }

    val handleMessage = f@{ event: Event ->
        try {
            val data: dynamic = (event as MessageEvent).data ?: return@f
            when (data.event) {
                "onSolved" -> {
                    val sliderSolvedCorrectly = data.value.toString().toBooleanStrict()
                    if (sliderSolvedCorrectly) {
                        props.onSolved?.invoke()
                    }
                    GlobalScope.launch {
                        getDaysWithTasksRequest()?.let {
                            dispatch(SetStudentDuoDaysWithTasks(it))
                        }
                    }
                }
                "onContinuePlatformButtonClick" -> props.onContinueClick?.invoke()
                else -> return@f
            }
        } catch (e: Exception) {
            console.log("Error $e")
        }
    }

    useEffectOnce {
        window.addEventListener("message", handleMessage)
        cleanup {
            window.removeEventListener("message", handleMessage)
        }
    }

    if (slider.isNullOrBlank()) {
        return@FC
    }

    val height = minOf(
        (maxWidth - padding) * iFrameScale,
        (window.innerHeight - headerFooterAndPaddingHeight - padding).toDouble(),
        (window.innerWidth - 2 * padding) * iFrameScale
    )
    val width = minOf(
        (maxWidth - padding).toDouble(),
        (window.innerHeight - headerFooterAndPaddingHeight - padding) / 1.3,
        (window.innerWidth - 2 * padding).toDouble()
    )
    StudentDuoLayout {
        buttons = FC {
            if (props.showBackButton == true) {
                Button {
                    css(newTaskButton.extend {
                        flex = number(1.0)
                        borderRadius = 0.px
                        borderBottomLeftRadius = 12.px
                    })
                    onClick = {
                        navigate(-1)
                    }
                    +Localization.getBackButtonText(nativeLanguage)
                }
            }
            Button {
                css(newTaskButton.extend {
                    flex = number(1.0)
                    borderRadius = 0.px
                    borderBottomRightRadius = 12.px
                    if (props.showBackButton != true) {
                        borderBottomLeftRadius = 12.px
                    }
                })
                disabled =
                    isBasicTariffPlan && (numberOfUsedGenerations != null && numberOfUsedGenerations >= NUMBER_OF_FREE_ATTEMPTS)
                onClick = {
                    ReactGA.event(jso {
                        category = "navigation"
                        action = "button_click"
                        label = "Study another topic button"
                    })
                    navigate("../content")
                }
                +Localization.getOtherTopicButtonText(nativeLanguage)
            }
        }
        div {
            css(container)
            div {
                css(iFrameContainer(width, height))
                iframe {
                    css(iFrame)
                    id = "iframe-result-slider-preview"
                    srcDoc = getIframeWithDuoScript(slider, folderId, requestUuid, autoSoundExercise)
                    onLoad = f@{
                        dispatch(EndModalLoading())
                    }
                }
            }
        }
    }
}