package widgets.CourseMaterials.ui

import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.section
import react.useState
import shared.components.header.Header
import widgets.CourseMaterials.ui.components.SentencesMaterials.SentencesMaterials
import widgets.CourseMaterials.ui.components.WordsMaterials.WordsMaterials

external interface CourseMaterialsProps : Props {
    var header: String?
    var withAudio: Boolean
}

val CourseMaterials = FC<CourseMaterialsProps> { props ->
    section {
        css(courseMaterials)
        props.header?.let {
            Header {
                text = it
            }
        }
        SentencesMaterials {
            withAudio = props.withAudio
        }
        WordsMaterials {
            withAudio = props.withAudio
        }
    }
}