package widgets.LanguageAutoSlider.ui.components.CustomLinkModal

import Modal
import antd.Button
import antd.Input
import app.useAppDispatch
import csstype.integer
import csstype.px
import emotion.react.css
import entities.languageAutoCurrentRequest.UpdateCurrentRequestLink
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.section
import react.useEffect
import react.useState
import shared.components.Icon
import shared.components.header.Header
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoSlider.ui.components.IntegrateModal.*
import widgets.LanguageAutoSlider.updateCustomLink

external interface CustomLinkModalProps : Props {
    var isOpen: Boolean
    var onClose: () -> Unit
    var openShareModal: () -> Unit
    var link: String
    var requestId: Int
    var slider: String
}

enum class ECopyCustomLinkState {
    NO, SAVING, YES
}

val CustomLinkModal = FC<CustomLinkModalProps> { props ->
    val domainStr = "https://do.inream.com/"
    val htmlExt = ".html"
    val (isCopied, setIsCopied) = useState(ECopyCustomLinkState.NO)
    val (customLink, setCustomLink) = useState(props.link.substringAfter(domainStr))
    val (customLinkAlreadyExists, setCustomLinkAlreadyExists) = useState(false)

    val dispatch = useAppDispatch()

    useEffect(props.link) {
        setCustomLink(props.link.substringAfter(domainStr))
        setIsCopied(ECopyCustomLinkState.NO)
    }

    Modal {
        open = props.isOpen
        footer = null
        onCancel = {
            props.onClose()
            if (isCopied != ECopyCustomLinkState.NO) {
                setIsCopied(ECopyCustomLinkState.NO)
            }
        }
        width = 650

        section {
            css(modal)
            div {
                css(headerContainer)
                Button {
                    css(backButton)
                    onClick = {
                        props.openShareModal()
                        props.onClose()
                        if (isCopied != ECopyCustomLinkState.NO) {
                            setIsCopied(ECopyCustomLinkState.NO)
                        }
                    }
                    Icon {
                        css(turn)
                        src = "ic_arrow_down_24x24.svg"
                    }
                }
                Header {
                    text = "Custom link"
                }
            }
            div {
                css(contentContainer)
                div {
                    css(embedCodeContainer)
                    Header {
                        text = "Custom link"
                        fontSize = 14.0.px
                        lineHeight = 18.9.px
                        fontWeight = integer(600)
                    }
                    div {
                        css(linkContainer)
                        p {
                            css(domain)
                            +domainStr
                        }
                        Input {
                            css(custom)
                            value = customLink
                            onInput = {
                                if (customLinkAlreadyExists) {
                                    setCustomLinkAlreadyExists(false)
                                }

                                setCustomLink(it.currentTarget.value)
                            }
                            placeholder = "Type custom link..."
                        }
                    }
                }
                if (customLinkAlreadyExists) {
                    p {
                        css(alreadyExists)
                        +"Link with such name already exists"
                    }
                }
                Button {
                    css(generateButton)
                    onClick = f@{
                        if (customLink.isBlank()) {
                            return@f
                        }

                        if ("$domainStr$customLink" == props.link) {
                            setIsCopied(ECopyCustomLinkState.YES)
                            window.navigator.clipboard.writeText(props.link)
                            return@f
                        }

                        setIsCopied(ECopyCustomLinkState.SAVING)
                        GlobalScope.launch {
                            val response = updateCustomLink(props.requestId, customLink.substringBefore(htmlExt), props.slider)
                            if (response != null) {
                                window.navigator.clipboard.writeText(response.link)
                                setIsCopied(ECopyCustomLinkState.YES)
                                dispatch(UpdateCurrentRequestLink(response.link, response.embedCode))
                            } else {
                                setCustomLinkAlreadyExists(true)
                                setIsCopied(ECopyCustomLinkState.NO)
                            }
                        }
                    }
                    +when (isCopied) {
                        ECopyCustomLinkState.YES -> "Custom link is copied"
                        ECopyCustomLinkState.SAVING -> "Custom link is applied"
                        ECopyCustomLinkState.NO -> "Copy link"
                    }
                }
            }
        }
    }
}