package pages.constructor.ui.components.modalLoader

import csstype.*
import utils.types.CssStyle
import utils.types.FitContent

val loaderMaskStyle: CssStyle = {
    background = rgba(5, 10, 49, 0.45)
}

val loaderBodyStyle: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    justifyContent = JustifyContent.center
    alignItems = AlignItems.center
    gap = 10.px
    height = FitContent.fitContent
}

val loaderTextStyle: CssStyle = {
    fontFamily = string("\"Inter\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 14.px
    lineHeight = 17.px

    textAlign = TextAlign.center

    color = rgb(73, 124, 255)
}

val header: CssStyle = {
    color = Color("#282F3E")
    fontWeight = FontWeight.bold
    lineHeight = 21.6.px
    fontSize = 16.px
    fontFamily = string("\"Inter\", system-ui")
    margin = 0.px
    textAlign = TextAlign.center
}

val description: CssStyle = {
    width = 100.pct
    color = Color("#5D6676")
    fontWeight = FontWeight.normal
    lineHeight = 16.2.px
    fontSize = 12.px
    fontFamily = string("\"Inter\", system-ui")
    margin = 0.px
    textAlign = TextAlign.center
}

val textContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    gap = 4.px
}

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    gap = 16.px
    boxSizing = BoxSizing.borderBox
}
