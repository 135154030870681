package pages.constructor.ui.components.hintStyleSettings

import antd.Button
import antd.Popover
import app.appState.IAppState
import app.appState.selectAppState
import app.useAppDispatch
import app.useAppSelector
import builders.enums.EElementType
import emotion.react.css
import entities.interactivePicture.elements.SetSoundIconSize
import entities.interactivePicture.elements.SetSoundIconSizeForElements
import entities.interactivePicture.selectElementsUnderSelectionRectangle
import entities.interactivePicture.selectFocusedElement
import entities.selectedElement.selectElementsIdsUnderSelectionRectangle
import enums.EButtonTypes
import online.interactiver.common.interactivepicture.InteractiveElement
import pages.constructor.ui.components.elements.darkBlackFilter
import pages.constructor.ui.components.header.styleSettings.lineHeightInput.LineHeightInput
import pages.constructor.ui.components.header.styleSettings.stylePicker.StylePicker
import pages.constructor.ui.components.hintStyleSettings.componentsForHintSettings.HintHorizontalAlignPicker
import react.dom.html.ReactHTML.div
import react.*
import shared.canvas.interfaces.getValueForEditor
import shared.canvas.interfaces.shouldDrawEditor
import shared.components.Icon
import shared.components.inputNumberWithPlusAndMinus.InputNumberWithPlusAndMinus
import utils.types.impl

external interface StyleHintPopoverProps : Props {
    var focusedElement: InteractiveElement?
    var appState: IAppState?
}

val PopoverHintContent = FC<StyleHintPopoverProps> { props ->
    val dispatch = useAppDispatch()
    val elementsIdsUnderSelectionRectangle = useAppSelector(selectElementsIdsUnderSelectionRectangle)

    val allElementOfSelectedGroup = useAppSelector(selectElementsUnderSelectionRectangle)

    if (props.focusedElement == null && elementsIdsUnderSelectionRectangle.isEmpty()) {
        return@FC
    }

    val fontSizeValue = getValueForEditor(props.focusedElement, allElementOfSelectedGroup) { it.hint.text?.style?.fontSize.toString() }?.toIntOrNull() ?: 0

    val paddingSizeValue = getValueForEditor(props.focusedElement, allElementOfSelectedGroup) { it.hint.text?.style?.padding.toString() }?.toIntOrNull() ?: 0

    val fontStyleValue = getValueForEditor(props.focusedElement, allElementOfSelectedGroup){ it.hint.text?.style?.fontStyle }
    val textDecorationValue = getValueForEditor(props.focusedElement, allElementOfSelectedGroup){ it.hint.text?.style?.textDecoration }
    val hintAlignValue = getValueForEditor(props.focusedElement, allElementOfSelectedGroup){ it.hint.text?.style?.align }
    val lineHeightValue = getValueForEditor(props.focusedElement, allElementOfSelectedGroup){ it.hint.text?.style?.lineHeight.toString() }?.toDoubleOrNull() ?: 0.0
    div {
        css(container)
        InputNumberWithPlusAndMinus {
            value = fontSizeValue
            min = 8
            max = 72
            label = "Font size"
            onChange = {
                if (elementsIdsUnderSelectionRectangle.isNotEmpty()){
                    dispatch(props.appState?.getSetHintTextFontSizeForElements(elementsIdsUnderSelectionRectangle, it, true) ?: impl {})
                } else {
                    dispatch(props.appState?.getSetHintTextFontSize(props.focusedElement!!.identifier.id!!, it, true) ?: impl {})
                }
            }
            onAfterChange = {
                if (elementsIdsUnderSelectionRectangle.isNotEmpty()){
                    dispatch(props.appState?.getSetHintTextFontSizeForElements(elementsIdsUnderSelectionRectangle, it, false) ?: impl {})
                } else {
                    dispatch(props.appState?.getSetHintTextFontSize(props.focusedElement!!.identifier.id!!, it, false) ?: impl {})
                }
            }
        }
        StylePicker {
            styleValue = fontStyleValue
            decorationValue = textDecorationValue
            onChangeFontStyle = {
                if (elementsIdsUnderSelectionRectangle.isNotEmpty()){
                    dispatch(props.appState?.getSetHintFontStyleForElements(elementsIdsUnderSelectionRectangle, it) ?: impl {})
                } else {
                    dispatch(props.appState?.getSetHintFontStyle(props.focusedElement!!.identifier.id!!, it) ?: impl {})
                }
            }
            onChangeTextDecoration = {
                if (elementsIdsUnderSelectionRectangle.isNotEmpty()){
                    dispatch(props.appState?.getSetHintDecorationForElements(elementsIdsUnderSelectionRectangle, it) ?: impl {})
                } else {
                    dispatch(props.appState?.getSetHintDecoration(props.focusedElement!!.identifier.id!!, it) ?: impl {})
                }
            }
        }
        HintHorizontalAlignPicker{
            horizontalValue = hintAlignValue
            onChange = {
                if (elementsIdsUnderSelectionRectangle.isNotEmpty()){
                    dispatch(props.appState?.getSetHintHorizontalAlignForElements(elementsIdsUnderSelectionRectangle, it) ?: impl {})
                } else {
                    dispatch(props.appState?.getSetHintHorizontalAlign(props.focusedElement!!.identifier.id!!, it) ?: impl {})                }
            }
        }
        LineHeightInput {
            value = lineHeightValue
            min = 1.0
            max = 2.0
            onChange = {
                if (elementsIdsUnderSelectionRectangle.isNotEmpty()) {
                    dispatch(props.appState?.getSetHintLineHeightForElements(elementsIdsUnderSelectionRectangle, it, true) ?: impl {})
                } else {
                    dispatch(props.appState?.getSetHintLineHeight(props.focusedElement!!.identifier.id!!, it, true) ?: impl {})
                }
            }
            onAfterChange = {
                if (elementsIdsUnderSelectionRectangle.isNotEmpty()) {
                    dispatch(props.appState?.getSetHintLineHeightForElements(elementsIdsUnderSelectionRectangle, it, false) ?: impl {})
                } else {
                    dispatch(props.appState?.getSetHintLineHeight(props.focusedElement!!.identifier.id!!, it, false) ?: impl {})
                }
            }
        }
        InputNumberWithPlusAndMinus {
            value = paddingSizeValue
            min = 1
            max = 100000
            label = "Padding"
            fun changePadding (size: Int, boolean: Boolean) {
                if (elementsIdsUnderSelectionRectangle.isNotEmpty()){
                    dispatch(props.appState?.getSetHintTextPaddingForElements(elementsIdsUnderSelectionRectangle, size, boolean) ?: impl {})
                } else {
                    dispatch(props.appState?.getSetHintTextPadding(props.focusedElement!!.identifier.id!!, size, boolean) ?: impl {})
                }
            }
            onChange = {
                changePadding(it, true)
            }
            onAfterChange = {
                changePadding(it, false)
            }
        }
        val shouldDrawIconSize = shouldDrawEditor(props.focusedElement, allElementOfSelectedGroup) { element ->
            element.sound != null && (EElementType.values().find { it.text == element.visibleElement.type }?.usesSoundBadge ?: false)
        }
        if (!shouldDrawIconSize) {
            return@div
        }
        val soundIconSize = getValueForEditor(props.focusedElement, allElementOfSelectedGroup) {
            it.sound?.iconSize.toString()
        }?.toIntOrNull() ?: 0
        InputNumberWithPlusAndMinus {
            value = soundIconSize
            min = 5
            label = "Sound icon size"
            fun changeIconSize(iconSize: Int, preventHistoryUpdate: Boolean) {
                if (props.focusedElement != null) {
                    dispatch(SetSoundIconSize(props.focusedElement!!.identifier.id!!, iconSize, preventHistoryUpdate))
                } else {
                    dispatch(SetSoundIconSizeForElements(elementsIdsUnderSelectionRectangle, iconSize, preventHistoryUpdate))
                }
            }
            onChange = {
                changeIconSize(it, true)
            }
            onAfterChange = {
                changeIconSize(it, false)
            }
        }
    }
}

val HintStyleSettings = FC<Props> {
    val appState = useAppSelector(selectAppState)
    val focusedElement = useAppSelector(selectFocusedElement)
    val elementsIdsUnderSelectionRectangle = useAppSelector(selectElementsIdsUnderSelectionRectangle)

    val (open, setOpen) = useState(false)

    useEffect(focusedElement, elementsIdsUnderSelectionRectangle) {
        if (focusedElement == null && elementsIdsUnderSelectionRectangle.isEmpty()) {
            setOpen(false)
        }
    }

    Popover {
        trigger = "click"
        align = impl {
            points = arrayOf("tl", "bl")
            offset = arrayOf(-20, 6)
        }
        arrowPointAtCenter = false
        content = createElement(
            PopoverHintContent, impl {
            this.focusedElement = focusedElement
            this.appState = appState
        })
        this.open = if (focusedElement != null || elementsIdsUnderSelectionRectangle.isNotEmpty()) open else false
        onOpenChange = { newOpen: Boolean ->
            setOpen(newOpen)
        }

        Button {
            css(button)
            id = EButtonTypes.STYLE_BUTTON.value
            Icon {
                css(darkBlackFilter)
                src = "ic_brush_24x24.svg"
            }
            ghost = true
        }

    }
}