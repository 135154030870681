package shared.components

import kotlinext.js.require
import react.FC
import react.PropsWithClassName
import inlinesvg.SVG

const val iconsPath = "icons"

external interface IconProps : PropsWithClassName {
    var src: String
    var rotate: Boolean?
}

val Icon = FC<IconProps> { props ->
    SVG {
        className = props.className
        src = require("./${iconsPath}/${props.src}").default
    }
}
