package widgets.ExerciseSettings.components.AdvancedSettings

import csstype.Display
import csstype.FlexDirection
import csstype.px
import utils.types.CssStyle


val gptModelContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
}
