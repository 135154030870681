package online.interactiver.common.autogeneration

import kotlinx.serialization.Serializable

@Serializable
data class GenerateLanguageSliderResponse(
    val slider: String,
    val link: String?,
    val embedCode: String?
)
