package widgets.SliderStatistics.ui

import csstype.*
import utils.types.CssStyle

val widget: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.flexStart
    gap = 30.px
    padding = 34.px
    boxSizing = BoxSizing.borderBox
    borderRadius = 12.px
    backgroundColor = Color("white")
    boxSizing = BoxSizing.borderBox
    maxWidth = 954.px
    width = 100.pct
}
