package widgets.CourseGenerationType.ui.TextAndWordsType

import app.useAppDispatch
import entities.languageAutoCurrentRequest.SetCurrentRequestTextOrTopic
import react.FC
import react.Props
import react.redux.useSelector
import widgets.CourseGenerationType.ui.GenerateMaterialsBasedOnTextButton.GenerateMaterialsBasedOnTextButton
import widgets.CourseMaterials.SetCourseText
import widgets.CourseMaterials.selectCourseText
import widgets.LanguageAutoContent.ui.components.Content.TextAreaWithLengthRestriction

val TextAndWordsType = FC<Props> {
    val text = useSelector(selectCourseText)

    val dispatch = useAppDispatch()

    TextAreaWithLengthRestriction {
        label = "Your text and any details"
        value = text
        placeholder = "Your course data in any format"
        onChange = { dispatch(SetCourseText(it)) }
        maxContentText = 5000
    }

    GenerateMaterialsBasedOnTextButton {}
}
