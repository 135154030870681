package pages.constructor.ui.components.header.templates.preview

import emotion.react.css
import online.interactiver.common.api.TemplateMeta
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.iframe

external interface TemplateWindowProps : Props {
    var templateMeta: TemplateMeta
}

val TemplateWindow = FC<TemplateWindowProps> { props ->
    div {
        css(window)
        div {
            css(windowHeader)
        }
        div {
            css(windowBody)
            iframe {
                css(htmlFrame)
                src = props.templateMeta.getHtmlURL()
            }
        }
    }
}