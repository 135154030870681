package shared.canvas.implementations


import IRect
import app.useAppImage
import org.w3c.dom.HTMLImageElement
import react.FC
import react.MutableRefObject
import react.ref
import react.useRef
import reactkonva.Image
import reactkonva.Rect
import shared.canvas.KonvaElementProps
import shared.canvas.interfaces.getElementBounds
import utils.types.ImpRect

val BackgroundCutComponent = FC<KonvaElementProps> { props ->


    if (props.stageRef == null) {
        TransformableRect {
            this.elementRef = props.elementRef
            this.onTransform = props.onTransform
            this.onTransformEnd = props.onTransformEnd
            this.textRef = props.textRef
            this.data = props.data
            this.style = props.style
            this.bounds = props.bounds
        }
        return@FC
    }

    val backgroundBase64 = props.stageRef.current?.toDataURL() ?: ""
    val image = useAppImage(backgroundBase64)[0] as HTMLImageElement?
    val imageRef = useRef<dynamic>()
    val bounds = props.data.getElementBounds()
    val crop: IRect =
        ImpRect { this.x = bounds.x; this.y = bounds.y;this.width = bounds.width;this.height = bounds.height }


    if (image != null) {
        Rect {
            this.ref = props.elementRef
            this.width = props.bounds.width
            this.height = props.bounds.height
            this.onTransform =
                { event ->
                    props.onTransform(event)
                    val node = (props.elementRef as MutableRefObject<dynamic>).current
                    val image = imageRef.current

                    image.width(node.width())
                    image.height(node.height())
                    image.scaleX(1)
                    image.scaleY(1)
                }
            this.onTransformEnd = props.onTransformEnd
        }
        Image {
            this.ref = imageRef
            this.crop = crop
            this.width = props.bounds.width
            this.height = props.bounds.height
            this.image = image
        }
    }
}