package utils.localization

import online.interactiver.common.enums.ELanguage

enum class Literal {
    CHECK_BUTTON_DEFAULT_TEXT,
    RESTART_BUTTON_DEFAULT_TEXT,
}

class Library(language: ELanguage) {
    class MissingLanguageException(language: ELanguage): RuntimeException(
        "The language $language is missing in the Library!", null
    )

    val get: (Literal) -> String = when(language) {
        ELanguage.ENGLISH -> this.English
        ELanguage.HEBREW -> this.HebrewRussian
        else -> throw MissingLanguageException(language)
    }
}
