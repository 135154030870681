package pages.constructor.ui.components.header.styleSettings.lineHeightInput

import antd.TypedInputNumber
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.components.Icon
external interface LineHeightProps : Props {
    var value: Double?
    var min: Double?
    var max: Double?
    var onChange: ((Double) -> Unit)?
    var onAfterChange: ((Double) -> Unit)?
}
val LineHeightInput= FC<LineHeightProps> {props ->
    div {
        css(container)
        div {
            css(header)
            +"Line height"
        }
        div {
            css(lineContainer)
            Icon {
                css(button)
                src = "ic_line_height_24x24.svg"
            }
            TypedInputNumber {
                css(inputNumber)
                controls = true
                step = 0.1
                value = props.value ?: 1.0
                min = props.min ?: 1.0
                max = props.max ?: 2.0
                onChange = {
                    props.onChange?.invoke(it.toDouble())
                }
                onBlur = {
                    val lineHeight = it.target.value.toDouble()
                    props.onAfterChange?.invoke(lineHeight)
                }
            }
        }
    }
}