package online.interactiver.common.user

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import online.interactiver.common.enums.EGptType
import online.interactiver.common.enums.ELogoUseType
import online.interactiver.common.enums.defaultOpenAIModelName

const val defaultLogo = "https://pictures-map.fra1.cdn.digitaloceanspaces.com/inream.png"
const val defaultLink = "https://inream.com"

@Serializable
data class UserSliderSettings(
    val id: Int? = null,
    @SerialName("user_id")
    val userId: Int,
    @SerialName("translate_request_to_target_language")
    var translateRequestToTargetLanguage: Boolean,
    @SerialName("number_of_slides_to_generate")
    var numberOfSlidesToGenerate: Int,
    @SerialName("private_dictionary_id")
    var privateDictionaryId: Int? = null,
    var logo: String? = defaultLogo,
    @SerialName("logo_use_type")
    var logoUseType: String = ELogoUseType.DEFAULT_LOGO.value,
    @SerialName("logo_file_name")
    var logoFileName: String = "",
    @SerialName("link_in_logo")
    var linkInLogo: String? = defaultLink,
    @SerialName("gpt_model")
    var gptModel: String = EGptType.OPEN_AI.type,
    @SerialName("gpt_model_name")
    var gptModelName: String = defaultOpenAIModelName
)
