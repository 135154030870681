package widgets.CourseMaterials.ui

import csstype.*
import utils.types.CssStyle

val courseMaterials: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    width = 100.pct
    gap = 26.px
}
