package shared.components.editSoundModal

import csstype.*
import emotion.css.keyframes
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 22.px
}

val buttonsContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    width = 345.px
    gap = 10.px
    alignSelf = AlignSelf.center
}

val button: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    padding = 0.px
    gap = 4.px
    backgroundColor = Color("#f2f4f7")
    fontWeight = integer(500)
    width = 100.pct
    height = 58.px
    border = None.none
    borderRadius = 5.px
    margin = 0.px
}

val footerContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
}

val rowContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
    width = 100.pct
    gap = 10.px
}

val iconCss: CssStyle = {
    width = 29.px
    height = 29.px
}

val countdown: CssStyle = {
    animationDuration = 1.s
    animationName = "fade-in".unsafeCast<AnimationName>()
}
