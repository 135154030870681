package widgets.Exercises.ui.components.UploadManuallyButton

import antd.Button
import emotion.react.css
import react.FC
import react.Props
import react.useState
import shared.components.Icon
import widgets.Exercises.ui.components.UploadManuallyButton.UploadManuallyModal.UploadManuallyModal

external interface UploadManuallyButtonProps : Props {
    var onUploaded: (() -> Unit)?
}

val UploadManuallyButton = FC<UploadManuallyButtonProps> { props ->
    val (modalIsOpened, setModalIsOpened) = useState(false)

    Button {
        css(button)
        Icon {
            css(uploadIcon)
            src = "ic_upload_24x24.svg"
        }
        onClick = {
            setModalIsOpened(true)
        }
    }

    UploadManuallyModal {
        open = modalIsOpened
        onClose = {
            setModalIsOpened(false)
        }
        onUploaded = props.onUploaded
    }
}
