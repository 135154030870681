package widgets.UserProfile.ui

import antd.Spin
import app.useAppDispatch
import csstype.AlignItems
import csstype.FlexDirection
import csstype.JustifyContent
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.autogeneration.LanguageAutoGeneration
import online.interactiver.common.enums.EAccessType
import online.interactiver.common.user.UserProfile
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.section
import shared.components.ProtectedRoute.ProtectedRoute
import shared.components.header.Header
import utils.types.extend
import widgets.Exercises.ui.Exercises
import widgets.LanguageAutoTask.ui.divider
import widgets.UserProfile.SetUserAbout
import widgets.UserProfile.ui.components.InputWithUserData.TextAreaWithUserData
import widgets.UserProfile.ui.components.UserNameWIthPhoto.UserNameWithPhoto
import widgets.UserProfile.updateAboutRequest

external interface UserProfileProps : Props {
    var userViewsHisProfile: Boolean
    var userProfile: UserProfile?
    var onExerciseClick: (LanguageAutoGeneration) -> Unit
}

val UserProfile = FC<UserProfileProps> { props ->
    val dispatch = useAppDispatch()
    val userProfile = props.userProfile

    ProtectedRoute {
        section {
            css(profile)
            if (userProfile == null) {
                css(profile.extend {
                    flexDirection = FlexDirection.row
                    alignItems = AlignItems.center
                    justifyContent = JustifyContent.center
                })
                Spin {
                    size = "large"
                }
                return@section
            }
            Header {
                text = "Profile settings"
            }

            UserNameWithPhoto {
                editable = props.userViewsHisProfile
                userName = userProfile.userName
                photoSrc = userProfile.photoSrc
            }

            div {
              css(divider)
            }
            TextAreaWithUserData {
                editable = props.userViewsHisProfile
                label = "about"
                value = userProfile.about
                onChange = {
                    dispatch(SetUserAbout(it))
                    GlobalScope.launch {
                        val response = updateAboutRequest(it)
                        if (response.data == null) {
                            dispatch(OpenErrorModal("Error updating about"))
                            return@launch
                        }
                    }
                }
            }
            Exercises {
                accessType = EAccessType.PUBLIC_OF_USER
                onExerciseClick = props.onExerciseClick
                userId = props.userProfile?.userId
            }
        }
    }
}