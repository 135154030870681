package online.interactiver.common.accessgroupuser

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class AccessGroupUserBase(
    @SerialName("user_id") val userId: Int,
    val role: String
)

enum class Role(val get: String, val order: Int) {
    STUDENT("STUDENT", 1),
    AUTHOR("AUTHOR", 2),
    ADMIN("ADMIN", 3);

    companion object {
        fun getValuesWithoutStudent(): List<String> {
            return entries.filter { it != STUDENT }.map { it.get }
        }
    }
}