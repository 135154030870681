package shared.components.ScrollToTop

import kotlinx.browser.window
import org.w3c.dom.SMOOTH
import org.w3c.dom.ScrollBehavior
import org.w3c.dom.ScrollToOptions
import react.FC
import react.PropsWithChildren
import react.router.useLocation
import react.useEffect

val ScrollToTop = FC<PropsWithChildren> {
    val location = useLocation()
    val pathname = location.pathname

    useEffect(pathname) {
        window.scrollTo(ScrollToOptions(0.0, 0.0, ScrollBehavior.SMOOTH))
    }

    +it.children
}