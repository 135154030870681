package utils

import getInteractiveByUID
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import getInteractiveJSONByUID
import getTemplateByCode
import online.interactiver.common.admin.interactive.InteractiveResponse
import online.interactiver.common.interactivepicture.InteractivePicture
import org.w3c.dom.HTMLInputElement
import org.w3c.files.FileReader
import org.w3c.files.get
import react.dom.events.ChangeEvent
import react.dom.events.ChangeEventHandler

fun fileToInteractivePicture(onBeforeLoad: (() -> Unit)? = null, onLoad: (InteractivePicture?) -> Unit): ChangeEventHandler<HTMLInputElement> {
    return fun(evt: ChangeEvent<HTMLInputElement>) {
        onBeforeLoad?.invoke()
        if (evt.target.files?.length != 1) {
            onLoad(null)
            return
        }
        val file = evt.target.files!![0]!!
        evt.target.value = ""
        val fileReader = FileReader()
        fileReader.readAsText(file)
        fileReader.onload = {
            try {
                val interactivePicture =
                    it.target.result?.let { Json {
                        explicitNulls = false
                        ignoreUnknownKeys = true
                    }.decodeFromString<InteractivePicture>(it) }
                onLoad(interactivePicture)
            } catch (e: Exception) {
                console.log(e.message)
                onLoad(null)
            }
        }
    }
}

suspend fun codeToInteractivePicture(code: String): InteractivePicture {
    val jsonContent = getTemplateByCode(code)
    return Json {
        ignoreUnknownKeys = true
        explicitNulls = false
    }.decodeFromString<InteractivePicture>(jsonContent)
}

suspend fun uuidToInteractivePicture(uuid: String): InteractivePicture? {
    val response = getInteractiveJSONByUID(uuid)

    if (response.code != 200 && uuid != "") {
        return null
    }

    return Json {
        ignoreUnknownKeys = true
        explicitNulls = false
    }.decodeFromString<InteractivePicture>(response.content)
}

suspend fun uuidToInteractiveData(uuid: String): InteractiveResponse? {
    val response = getInteractiveByUID(uuid)

    if (response.code != 200 && uuid != "") {
        return null
    }

    return Json {
        ignoreUnknownKeys = true
        explicitNulls = false
    }.decodeFromString<InteractiveResponse>(response.content)
}