package widgets.ExerciseSettings.components.InputGptModelName

import antd.Input
import app.useAppDispatch
import emotion.react.css
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLInputElement
import react.FC
import react.Props
import react.dom.events.KeyboardEvent
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import react.useEffect
import react.useState
import widgets.ExerciseSettings.SetGptModelNameAction
import widgets.ExerciseSettings.components.SettingLabel.SettingLabel
import widgets.ExerciseSettings.selectGptModelName

val InputGptModelName = FC<Props> {
    val gptModelName = useSelector(selectGptModelName)
    val dispatch = useAppDispatch()
    val (newModelName, setNewModelName) = useState(gptModelName ?: "")

    useEffect(gptModelName) {
        gptModelName?.let { setNewModelName(it) }
    }

    div {
        css(modelNameContainer)
        SettingLabel {
            label = "GPT-model name"
            hint = ""
        }
        Input {
            css(input)
            value = newModelName
            onInput = {
                setNewModelName(it.currentTarget.value)
            }
            onPressEnter = { evt: KeyboardEvent<HTMLInputElement> ->
                evt.currentTarget.blur()
            }
            onBlur = f@{
                if (newModelName.isBlank() || newModelName == gptModelName) {
                    return@f
                }

                dispatch(SetGptModelNameAction(newModelName))
                GlobalScope.launch {
                    updateGptModelNameRequest(newModelName)
                }
            }
        }
    }
}