package entities.interactivePicture.elements.controls.inputs

import builders.enums.EElementType
import builders.enums.EInteractiveType
import builders.getDragBuilder
import online.interactiver.common.utils.clone
import entities.interactivePicture.elements.*
import entities.interactivePicture.elements.controls.selectors.selectorStyle
import entities.interactivePicture.elements.gapPuzzles.drags.*
import io.ktor.util.date.*
import online.interactiver.common.interactivepicture.*

open class InputsAction : InteractiveElementAction(false)


data class AddInput(val id: String, val position: utils.structures.Position?) : InputsAction()

data class SetPlaceholder(val id: String, val placeholder: String) : InputsAction()
data class SetPlaceholderForInputs(val ids: Array<String>, val placeholder: String) : InputsAction()
data class SetCorrectValue(val id: String, val value: String) : InputsAction()
data class SetCorrectValueForInputs(val ids: Array<String>, val value: String) : InputsAction()
data class SetMaxLength(val id: String, val length: String) : InputsAction()
data class SetMaxLengthForInputs(val ids: Array<String>, val length: String) : InputsAction()
data class SetCorrectAnswerPosition(val id: String, val correctAnswerPosition: String) : InputsAction()
data class SetCorrectAnswerPositionForInputs(val ids: Array<String>, val correctAnswerPosition: String) : InputsAction()
data class SetIgnoreSpaces(val id: String, val option: String) : InputsAction()
data class SetIgnoreSpacesForInputs(val ids: Array<String>, val option: String) : InputsAction()
data class SetIgnoreCase(val id: String, val option: String) : InputsAction()
data class SetIgnoreCaseForInputs(val ids: Array<String>, val option: String) : InputsAction()
data class AddInputOption(val inputId: String, val text: String) : InputsAction()
data class SetInputOptions(val id: String, val options: MutableList<InputOption>?) : InputsAction()

val setPlaceholder = f@{ list: MutableList<InteractiveElement>, id: String,
                         placeholder: String ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList

    newList[elementIndex] = newList[elementIndex].copy(
            input = newList[elementIndex].input?.copy(
                    placeholderText = placeholder,
            ),
    )

    val typeStr = newList[elementIndex].visibleElement.type
    val elemType = EElementType.values().find { it.text == typeStr }!!
    val textToGenerateIdentifier = "${elemType.shortName} ${elemType.textToDefineIdentifier.textField(newList[elementIndex])}"
    newList[elementIndex].identifier.setIdentifierByText(
        textToGenerateIdentifier
    )

    newList
}

val setCorrectValue = f@{ list: MutableList<InteractiveElement>, id: String,
                          value: String ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList

    newList[elementIndex] = newList[elementIndex].copy(
        input = newList[elementIndex].input?.copy(
                correctValue = value,
        ),
        visibleElement = newList[elementIndex].visibleElement.copy(
            text = newList[elementIndex].visibleElement.text?.copy(
                simpleText = value,
            )
        )
    )

    newList
}

val setMaxLength = f@{ list: MutableList<InteractiveElement>, id: String,
                       length: String ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList

    val len = if (length != "") length.toInt() else 0

    newList[elementIndex] = newList[elementIndex].copy(
            input = newList[elementIndex].input?.copy(
                    maxLength = len,
            ),
    )

    newList
}

val setCorrectAnswerPosition = f@{ list: MutableList<InteractiveElement>, id: String, correctAnswerPosition: String ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList

    newList[elementIndex] = newList[elementIndex].copy(
        input = newList[elementIndex].input?.copy(
            correctAnswerPosition = correctAnswerPosition,
        ),
    )

    newList
}
val setIgnoreSpaces = f@{ list: MutableList<InteractiveElement>, id: String,
                          option: String ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList

    newList[elementIndex] = newList[elementIndex].copy(
        input = newList[elementIndex].input?.copy(
            ignoreWhiteSpaces = option,
        )
    )
    newList
}
val setIgnoreCase= f@{ list: MutableList<InteractiveElement>, id: String,
                          option: String ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList

    newList[elementIndex] = newList[elementIndex].copy(
        input = newList[elementIndex].input?.copy(
            ignoreCaseSensitivity = option,
        )
    )

    newList
}

val setCorrectAnswerPositionForInputs = f@{ list: MutableList<InteractiveElement>, ids: Array<String>, correctAnswerPosition: String ->
    setValueForElements(list, ids) { newList, id ->
        setCorrectAnswerPosition(newList, id, correctAnswerPosition)
    }
}

val setMaxLengthForInputs = f@{ list: MutableList<InteractiveElement>, ids: Array<String>, length: String ->
    setValueForElements(list, ids) { newList, id ->
        setMaxLength(newList, id, length)
    }
}

val setPlaceholderForInputs = f@{ list: MutableList<InteractiveElement>, ids: Array<String>, placeholder: String ->
    setValueForElements(list, ids) { newList, id ->
        setPlaceholder(newList, id, placeholder)
    }
}

val setCorrectValueForInputs = f@{ list: MutableList<InteractiveElement>, ids: Array<String>, value: String ->
    setValueForElements(list, ids) { newList, id ->
        setCorrectValue(newList, id, value)
    }
}

val setIgnoreSpacesForInputs = f@{ list: MutableList<InteractiveElement>, ids: Array<String>, option: String ->
    setValueForElements(list, ids) { newList, id ->
        setIgnoreSpaces(newList, id, option)
    }
}

val setIgnoreCaseForInputs = f@{ list: MutableList<InteractiveElement>, ids: Array<String>, option: String ->
    setValueForElements(list, ids) { newList, id ->
        setIgnoreCase(newList, id, option)
    }
}

fun buildInputOption(answer: String): InputOption {
    return InputOption(
        identifier = Identifier(id = "InputOption_${getTimeMillis()}${(0 until Int.MAX_VALUE).random()}"),
        answer = answer,
    )
}

val InputReducer = { state: MutableList<InteractiveElement>, action: InputsAction ->
    when (action) {

        is AddInput -> {
            val builder =
                    getDragBuilder().setType(EInteractiveType.INPUT_INTERACTIVE)
                            .setType(EElementType.INPUT_CONTROL)
                            .setHoverFocusStyling(inputStyle)
                            .setHoverStyle(inputHoverStyle)
                            .setFocusStyle(inputFocusStyle)
                            .setTextStyle(inputTextStyle)
                            .setRect(action.position)
                            .setRectSize(196, 32)
                            .setInput()
                            .addScore(ScoreData(DEFAULT_SCORE, null))
                            .setID(action.id)
                            .setIsSynced(false)

            addElementToList(state, builder.build())
        }
        is SetInputOptions -> {
            val newState = state.clone()
            val inputIndex = newState.indexOfFirst { it.identifier.id.equals(action.id) }

            if (inputIndex >= 0) {
                val newSelector = state[inputIndex].clone()
                newSelector.input?.answerOptions = action.options
                newState[inputIndex] = newSelector
            }

            newState
        }
        is AddInputOption -> {
            val newState = state.clone()
            val option = buildInputOption(action.text)
            option.identifier?.setIdentifierByText(action.text)
            val inputIndex = newState.indexOfFirst { it.identifier.id.equals(action.inputId) }

            if (inputIndex >= 0) {
                val newInput = state[inputIndex].clone()

                if (newInput.input == null) {
                    newInput.input = Input()
                }

                if (newInput.input!!.answerOptions == null) {
                    newInput.input!!.answerOptions = mutableListOf(option)
                } else {
                    newInput.input!!.answerOptions!!.add(option)
                }

                newState[inputIndex] = newInput
            }

            newState

        }

        is SetPlaceholder -> setPlaceholder(state, action.id, action.placeholder)

        is SetPlaceholderForInputs -> setPlaceholderForInputs(state, action.ids, action.placeholder)

        is SetCorrectValue -> setCorrectValue(state, action.id, action.value)

        is SetCorrectValueForInputs -> setCorrectValueForInputs(state, action.ids, action.value)

        is SetMaxLength -> setMaxLength(state, action.id, action.length)

        is SetMaxLengthForInputs -> setMaxLengthForInputs(state, action.ids, action.length)

        is SetCorrectAnswerPosition -> setCorrectAnswerPosition(state, action.id, action.correctAnswerPosition)

        is SetCorrectAnswerPositionForInputs -> setCorrectAnswerPositionForInputs(state, action.ids, action.correctAnswerPosition)

        is SetIgnoreSpaces -> setIgnoreSpaces(state, action.id, action.option)

        is SetIgnoreSpacesForInputs -> setIgnoreSpacesForInputs(state, action.ids, action.option)

        is SetIgnoreCase -> setIgnoreCase(state, action.id, action.option)

        is SetIgnoreCaseForInputs -> setIgnoreCaseForInputs(state, action.ids, action.option)

        else -> state
    }
}
