package widgets.Exercises.ui.components.UserActions.ExerciseMovingModal

import online.interactiver.common.autogeneration.LanguageAutoGeneration
import react.FC
import react.Props
import widgets.Exercises.ui.components.FolderUserActions.FolderMovingModal.MovingModal

external interface MovingModalProps : Props {
    var open: Boolean?
    var onClose: (() -> Unit)?
    var generation: LanguageAutoGeneration?
    var onMoved: ((Int?) -> Unit)?
}

val ExerciseMovingModal = FC<MovingModalProps> { props ->
    MovingModal {
        open = props.open
        onClose = props.onClose
        generations = listOf(props.generation)
        onMoved = props.onMoved
    }
}
