package pages.constructor.ui.components.advancedElementsSettings

import app.useAppSelector
import builders.enums.EElementType
import entities.interactivePicture.selectFocusedElement
import react.FC
import react.Props

val AdvancedElementSettings = FC<Props> {
    val focusedElement = useAppSelector(selectFocusedElement)
    val focusedElementType = EElementType.values().find{ focusedElement?.visibleElement?.type == it.text }

    focusedElementType?.advancedSettingsFC?.let { it { } }
}
