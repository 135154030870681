package online.interactiver.common.autogeneration.taskLineContentFabric

import online.interactiver.common.autogeneration.taskLineContent.QuestionWithAnswersContent

class QuestionWithAnswersContentFabric(private val answersCount: Int) : TaskLineContentFabric<QuestionWithAnswersContent> {

    private data class QuestionContent(val question: String, val sound: String?, val picture: String?)
    private fun getQuestionAndTextToSoundFromRawQuestionContent(rawQuestionContent: String): QuestionContent {
        val questionSoundAndPicture = rawQuestionContent.split(" -")
        val question = questionSoundAndPicture[0].trim()

        val sound = questionSoundAndPicture.getOrNull(1)?.trim()?.ifBlank { null }
        val picture = questionSoundAndPicture.getOrNull(2)?.trim()?.ifBlank { null }

        return QuestionContent(question, sound, picture)
    }

    override fun fromRawTaskLine(taskLineContent: String, isOpenAiFormat: Boolean): QuestionWithAnswersContent {
        if (isOpenAiFormat) {
            val asWordMatching = WordMatchingContentFabric(answersCount).fromRawTaskLine(taskLineContent, true)
            val (question, textToSound, picture) = getQuestionAndTextToSoundFromRawQuestionContent(
                asWordMatching.wordMatchPairs[0].match
            )
            val answers = asWordMatching.wordMatchPairs.take(answersCount)
                .mapIndexed { index, value ->
                    val answer = value.word
                    val isCorrect = index == 0
                    return@mapIndexed QuestionWithAnswersContent.Answer(answer, isCorrect)
                }.shuffled()

            return QuestionWithAnswersContent(question, answers, textToSound, picture)
        } else {
            val split = taskLineContent.split(";").map { it.trim() }
            val (question, textToSound, picture) = getQuestionAndTextToSoundFromRawQuestionContent(split[0])
            val answers = split.slice(1 until split.size).map {
                val answerAndIsCorrectRaw = it.split("- ").map { it.trim() }
                val answer = answerAndIsCorrectRaw[0]
                val isCorrect = answerAndIsCorrectRaw[1] == "T"
                return@map QuestionWithAnswersContent.Answer(answer, isCorrect)
            }

            return QuestionWithAnswersContent(question, answers, textToSound, picture)
        }
    }

    override fun canBeBuiltFrom(taskLineContent: String, isOpenAiFormat: Boolean): Boolean {
        if (isOpenAiFormat) {
            return WordMatchingContentFabric(answersCount).canBeBuiltFrom(taskLineContent, isOpenAiFormat)
        } else {
            val split = taskLineContent.split(";").map { it.trim() }.filter { it.isNotBlank() }
            if (split.size != answersCount + 1) {
                return false
            }

            for (answer in split.slice(1 until split.size)) {
                if (!answer.contains(" - ") && !answer.contains("- ") && !answer.contains(" -")) {
                    return false
                }

                val answerIsCorrect = answer.substringAfterLast("-").trim()
                if (answerIsCorrect != "T" && answerIsCorrect != "F") {
                    return false
                }
            }
            return true
        }
    }
}
