package widgets.Exercises.ui

import app.useAppDispatch
import emotion.react.css
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.EAccessType
import pages.languageAuto.SetHiddenIframeIsShown
import pages.languageAuto.UpdateCurrentLanguageAutoRequest
import pages.languageAuto.ui.ERoute
import pages.studentDuo.useConfirmPayment
import react.VFC
import react.dom.html.ReactHTML.section
import react.router.dom.useSearchParams
import react.router.useNavigate
import shared.components.ProtectedRoute.ProtectedRoute
import widgets.Exercises.getLanguageAutoRequest

val PrivateExercises = VFC {
    val dispatch = useAppDispatch()
    val navigate = useNavigate()

    val (searchParams, setSearchParams) = useSearchParams()

    useConfirmPayment()

    ProtectedRoute {
        section {
            css(widget)
            Exercises {
                accessType = EAccessType.PRIVATE
                parentFolderId = searchParams.get(EFilterParams.PARENT_FOLDER_ID.value)?.toIntOrNull()
                onFolderClick = {
                    goToFolder?.invoke(it.id)
                }
                goToFolder = {
                    if (it != null) {
                        searchParams.set(EFilterParams.PARENT_FOLDER_ID.value, it.toString())
                    } else {
                        searchParams.delete(EFilterParams.PARENT_FOLDER_ID.value)
                    }
                    setSearchParams(searchParams)
                }
                onExerciseClick = {
                    navigate("${ERoute.EXERCISE.path}/${it.requestId}")
                }
            }
        }
    }
}