package online.interactiver.common.autogeneration.taskLineContent

class QuestionWithAnswersContent(
    val question: String, var answers: List<Answer>, val textToSound: String?, val picture: String?
): TaskLineContent {

    data class Answer(val value: String, var isCorrect: Boolean, var soundSrc: String? = null)

    override fun toRawTaskContent(): String {
        val rawAnswers = answers.joinToString("; ") {
            "${it.value} - ${if (it.isCorrect) 'T' else 'F'}"
        }

        val textToSoundContent = if (textToSound != null) "$textToSound" else ""
        val pictureContent = if (picture != null) "$picture" else ""
        val hasAdditionalContent = textToSound != null || picture != null
        val questionContent = "$question${if (hasAdditionalContent) " - " else ""}$textToSoundContent${if (hasAdditionalContent) " - " else ""}$pictureContent"
        return "$questionContent; $rawAnswers"
    }

    override fun getVocabularyToLearn(): List<String> = answers.map { it.value }
}
