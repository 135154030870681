package enums

// Будет переиспользованно при интеграции тестов внутрь проекта
enum class EButtonTypes(open val value: String) {

    // Tabs
    STATIC_TAB("static_tab_button"),
    CONTROL_TAB("control_tab_button"),
    PUZZLE_TAB("puzzle_tab_button"),
    OTHER_TAB("other_tab_button"),
    INTERACTIVITY_TAB("interactivity_tab_button"),
    DESIGN_TAB("design_tab_button"),

    // Filter TABS
    STATIC_FILTER_TAB("static_filter_tab_button"),
    CONTROL_FILTER_TAB("control_filter_tab_button"),
    PUZZLE_FILTER_TAB("puzzle_filter_tab_button"),
    OTHER_FILTER_TAB("other_filter_tab_button"),

    // Static Buttons
    CREATE_MARKER_BUTTON("create_marker_button"),
    CREATE_FRAME_BUTTON("create_frame_button"),
    CREATE_AREA_BUTTON("create_area_button"),
    CREATE_ARROW_BUTTON("create_arrow_button"),
    CREATE_LINE_BUTTON("create_line_button"),

    // Create Shared Buttons
    CREATE_IMAGE_BUTTON("create_image_button"),
    CREATE_SOUND_BUTTON("create_sound_button"),
    CREATE_RECT_BUTTON("create_rect_button"),

    // Create Control Button
    CREATE_BUTTON_BUTTON("create_button_button"),
    CREATE_INPUT_BUTTON("create_input_button"),
    CREATE_SELECTOR_BUTTON("create_selector_button"),

    // Create Puzzle Button
    CREATE_CUT_BUTTON("create_cut_button"),

    // Collapses
    ELEMENT_COLLAPSE("element_collapse_button"),
    EXPORT_COLLAPSE("export_collapse_button"),

    // Export Buttons
    DOWNLOAD_HTML_BUTTON("download_html_button"),
    PREVIEW_BUTTON("preview_button"),
    SHARE_BUTTON("share_button"),
    PREVIEW_SHARE_BUTTON("preview_share_button"),
    INTEGRATE_BUTTON("share_button"),

    // Header Buttons
    FILE_BUTTON("header_file_button"),
    LOAD_JSON_BUTTON("header_load_json_button"),
    UPLOAD_JSON_BUTTON("header_upload_json_button"),
    UNDO_BUTTON("header_undo_button"),
    REDO_BUTTON("header_redo_button"),
    COPY_BUTTON("header_copy_button"),
    DELETE_BUTTON("header_delete_button"),
    STYLE_BUTTON("header_style_button"),
    TEMPLATES_BUTTON("header_templates_button"),
    PARAMS_BUTTON("params_button"),
    LOAD_HINTS_BUTTON("load_hints_button"),
    LOAD_HTML_BUTTON("load_html_button"),
    UPLOAD_HINTS_BUTTON("upload_hints_button"),

    //Params
    SAVE_PARAMS_BUTTON("save_params_button"),

    //Templates
    BACK_TO_TEMPLATES_BUTTON("back_to_templates_button"),
    SELECT_TEMPLATE_BUTTON("select_template_button"),
    TEMPLATE_BUTTON("template_button"),

    // Interactivity Inputs
    HINT_INPUT("hint_input"),
    PLACEHOLDER_INPUT("placeholder_input"),
    HINT_ACTIVE_SELECTOR("hint_active_selector"),
    ELEMENT_SHOWING_SELECTOR("hint_active_selector"),
    CORRECT_STATE_BUTTON("correct_state_button"),
    IGNORE_WHITE_SPACES_BUTTON("ignore_white_spaces"),
    IGNORE_CASE_SENSITIVITY("ignore_case_sensitivity"),
    CHECKMARK_POSITION_SELECTOR("checkmark_position_selector"),
    CORRECT_ANSWER_POSITION_SELECTOR("correct_answer_position_selector"),
    UPLOAD_SOUND_TO_ELEMENT_INPUT("upload_sound_to_element_input"),
    PHRASE_INPUT("phrase_input"),

    // Design Buttons

    // Device type Buttons
    DESKTOP_DEVICE_BUTTON("desktop_device_button"),
    MOBILE_DEVICE_BUTTON("mobile_device_button"),

    // Canvas size Buttons
    RESOLUTION_A_BUTTON("resolution_a_button"),
    RESOLUTION_B_BUTTON("resolution_b_button"),
    RESOLUTION_C_BUTTON("resolution_c_button"),
    RESOLUTION_D_BUTTON("resolution_d_button"),
    RESOLUTION_E_BUTTON("resolution_e_button"),
    RESOLUTION_F_BUTTON("resolution_f_button"),


    // Score Button
    SCORE_ADD_BUTTON("score_add_button"),
    SCORING_ADD_BUTTON("scoring_add_button"),

    // Advanced Settings
    SHOW_ADVANCED_SETTINGS_BUTTON("show_advanced_settings_button"),

    // Buttons Settings
    RADIO_BUTTON_MODE_SETTINGS_BUTTON("radio_button_mode_settings_button"),
    ADD_CHECKBOX_SETTINGS_BUTTON("add_checkbox_settings_button"),
    UNSELECTED_STATES_SETTINGS_BUTTON("unselected_states_settings_button"),

    // Hints Settings
    HINT_BY_HOVER_SETTINGS_BUTTON("hint_by_hover_settings_button"),
    HINT_BY_CLICK_SETTINGS_BUTTON("hint_by_click_settings_button"),

    // Generating Settings
    MINIFY_HTML_SETTINGS_BUTTON("minify_html_settings_button"),
    INCLUDE_LIBS_SETTINGS_BUTTON("include_libs_settings_button"),
    INCLUDE_SHARED_SETTINGS_BUTTON("include_shared_settings_button"),
    INCLUDE_INDIVIDUAL_SETTINGS_BUTTON("include_individual_settings_button"),
    RELOAD_WINDOW_ON_RESTART("reload_window_on_restart"),
    SHRINK_TEXT_TO_FIT_IN_ELEMENT_BUTTON("shrink_text_to_fit_in_element_button"),

    // Checking Settings
    INSTANT_CHECK_SETTINGS_BUTTON("instant_check_settings_button"),
    BUTTON_CHECK_SETTINGS_BUTTON("button_not_instant_check_settings_button"),

    // Popup Settings
    SHOW_POPUP_SETTINGS_BUTTON("show_popup_settings_button"),
    RANDOM_STYLE_POPUP_SETTINGS_BUTTON("random_style_popup_settings_button"),
    POPUP_STYLE_SETTINGS_SELECT("popup_style_settings_button"),
    DEFAULT_POPUP_MESSAGE_SETTINGS_BUTTON("default_popup_message_settings_button"),

    // Puzzles Settings
    SHUFFLE_PUZZLES_SETTINGS_BUTTON("shuffle_puzzles_settings_button"),
    PUZZLES_ON_PANEL_SETTINGS_BUTTON("puzzles_on_panel_settings_button"),
    PUZZLES_ALIGN_SELECT("puzzles_align_select"),
    MOBILE_DRAG_DROP_ACTION_SELECT("mobile_drag_drop_action_select"),
    DESKTOP_DRAG_DROP_ACTION_SELECT("desktop_drag_drop_action_select"),

    // Selectors Settings
    AUTO_CLOSE_SELECTORS_BUTTON("auto-close_selectors_button"),

    // Analytics Settings
    ENABLE_ANALYTICS_SETTINGS_BUTTON("enable_analytics_settings_button"),

    //Dictionary
    CHOOSE_PHRASE_FROM_CONSTRUCTOR_BUTTON("choose_phrase_from_constructor_button"),
    CHOOSE_PHRASE_FROM_DICTIONARY_BUTTON("choose_phrase_from_dictionary_button"),

    //AutoSound
    AUTO_SOUND_INPUT("auto_sound_input"),
    AUTO_SOUND_ORIGINAL_LANGUAGE_SELECT("auto_sound_original_language_select"),
    AUTO_SOUND_TARGET_LANGUAGE_SELECT("auto_sound_target_language_select"),
    AUTO_SOUND_FROM_DICTIONARY_BUTTON("auto_sound_from_dictionary_button"),
    AUTO_SOUND_FROM_GOOGLE_CLOUD_BUTTON("auto_sound_from_google_cloud_button"),

    // Settings from PreviewShare
    INSTANT_CHECK_PREVIEW_SETTINGS_BUTTON("instant_check_preview_settings_button"),

    // Tabs from PreviewShare
    PUBLISH_TAB_PREVIEW_SHARE_BUTTON("publish_tab_preview_share_button"),
    INTEGRATE_TAB_PREVIEW_SHARE_BUTTON("integrate_tab_preview_share_button"),
    VIEW_STATISTICS_TAB_PREVIEW_SHARE_BUTTON("view_statistics_tab_preview_share_button"),

    // PreviewShare PublishTab
    PUBLISH_LINK_PUBLISH_TAB_INPUT("publish_link_publish_tab_input"),
    HOST_BY_YOURSELF_PUBLISH_TAB_BUTTON("host_by_yourself_publish_tab_button"),
    DOWNLOAD_HTML_PUBLISH_TAB_BUTTON("download_html_publish_tab_button"),

    // PreviewShare IntegrateTab

    // PreviewShare ViewStatisticsTab
    RESULTS_SCREEN_LINK_VIEW_STATS_TAB_BUTTON("results_screen_link_view_stats_tab_button"),
    SCORE_DETAILS_VIEW_STATS_TAB_CHECKBOX_PREFIX("score_detaisl_view_stats_tab_checkbox"),

    //Edit sound modal
    EDIT_SOUND_MODAL_UPLOAD_BUTTON("edit_sound_modal_upload_button"),

    //Language auto user settings
    USER_SETTINGS_UPLOAD_LOGO_INPUT("user_settings_upload_logo_input"),

    //Course-folder
    IMPORT_EXERCISES_INPUT("import_exercises_input"),
    IMPORT_AND_ADD_EXERCISES_INPUT("import_and_add_exercises_input"),

    //duo
    DUO_CONTENT_TEXTAREA("duo_content_textarea")
}
