import app.App
import kotlinext.js.require
import kotlinext.js.requireAll
import kotlinx.browser.document
import react.create
import react.dom.client.createRoot
import reactga.ReactGA

fun main() {
    ReactGA.initialize("G-D5Y6H2632K")
    require("react-image-crop/dist/ReactCrop.css")
    requireAll(require.context(".", true, js("/\\.css$/")))

    val container = document.getElementById("root") ?: error("Couldn't find root container!")
    //createRoot(container).render(InteractivePictureCostructor.create())
    createRoot(container).render(App.create())

//    val container = document.createElement("div")
//    document.body!!.appendChild(container)
//
//    val welcome = Welcome.create {
//        name = "Kotlin/JS+"
//    }
//    createRoot(container).render(welcome)
}