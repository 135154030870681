package widgets.LanguageAutoSlider.ui

import app.useAppDispatch
import csstype.px
import emotion.react.css
import entities.languageAutoCurrentRequest.*
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLIFrameElement
import org.w3c.dom.MessageEvent
import org.w3c.dom.events.Event
import pages.constructor.ui.components.elements.grayFilter
import pages.languageAuto.SetHiddenIframeIsShown
import pages.languageAuto.ui.useLanguageAutoContext
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.iframe
import react.dom.html.ReactHTML.section
import react.redux.useSelector
import shared.components.Icon
import utils.types.extend
import utils.types.jsObject
import widgets.Exercises.ui.components.StatisticsModal.StatisticsModal
import widgets.Exercises.ui.components.UserActions.action
import widgets.Exercises.ui.components.UserActions.iconContainer
import widgets.LanguageAutoSlider.ui.components.LanguageAutoSliderHeader.LanguageAutoSliderHeader
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.ShareOrCreateNew
import widgets.LanguageAutoSlider.ui.components.SliderName.SliderName
import widgets.LanguageAutoTask.generateSliderRequest
import widgets.LanguageAutoTask.ui.LanguageAutoTask
import widgets.LanguageAutoTask.ui.components.HiddenIframe.getIframeSrc
import widgets.LanguageAutoTask.ui.components.HiddenIframe.getIframeWithCookieScript
import widgets.LanguageAutoTask.ui.components.HiddenIframe.goToSlideScript
import widgets.LanguageAutoTask.ui.components.HiddenIframe.trackSlideIndexScript
import widgets.LanguageAutoTask.ui.components.SlideEditModal.SlideEditModal
import widgets.LanguageAutoTask.ui.divider

val LanguageAutoSlider = FC<Props> { props ->
    val slider = useSelector(selectCurrentRequestSlider)
    val task = useSelector(selectCurrentRequestTask)
    val requestId = useSelector(selectCurrentRequestId)
    val link = useSelector(selectCurrentRequestLink)
    val embedCode = useSelector(selectCurrentRequestEmbedCode)

    val (entertainingLoader) = useLanguageAutoContext()

    val (isLanguageAutoTaskOpened, setIsLanguageAutoTaskOpened) = useState(false)

    val (slideIndex, setSlideIndex) = useState(0)
    val (showEditModal, setShowEditModal) = useState(false)

    val (isSavingSliderLoading, setIsSavingSliderLoading) = useState(false)

    val (isStatisticsModalOpened, setIsStatisticsModalOpened) = useState(false)

    val dispatch = useAppDispatch()

    val widgetRef = useRef(null) as MutableRefObject<HTMLElement>?

    val (lastSlideIndexWhenModalWasOpened, setLastSlideIndexWhenModalWasOpened) = useState(0)

    useEffect(slider, widgetRef?.current) {
        if (widgetRef == undefined) return@useEffect
        val component = widgetRef.current ?: return@useEffect

        component.scrollIntoView(jsObject { behavior = "smooth" })
    }

//    Закомменчено, потому что по неизвестной причине при попытке редактирования существующего задания из-за эффекта ниже
//    не появлялся слайдер

//    useEffect(window.frames["iframe-result-slider-preview"]) {
//        val iframe = window.frames["iframe-result-slider-preview"]
//        if (iframe) {
//            val searchParams = URLSearchParams(iframe.contentWindow.location.search)
//            if (searchParams.get("saveProgressToLocalStorage") != "0") {
//                searchParams.set("saveProgressToLocalStorage", "0")
//                iframe.contentWindow.location.search = searchParams
//            }
//        }
//    }

    val handleMessage = f@{ event: Event ->
        try {
            val data: dynamic = (event as MessageEvent).data ?: return@f

            setSlideIndex(data as Int)
        } catch (e: Exception) {
            console.log("Error $e")
        }
    }

    useEffectOnce {
        window.addEventListener("message", handleMessage)
        cleanup {
            window.removeEventListener("message", handleMessage)
        }
    }

    if (slider == null || requestId == null ||
        link.isNullOrBlank() || embedCode.isNullOrBlank() || task == null) {
        return@FC
    }

    SlideEditModal {
        this.slider = slider
        closeModal = {
            setShowEditModal(false)

            if (slideIndex == lastSlideIndexWhenModalWasOpened) {
                val iframe = document.getElementById("iframe-result-slider-preview") as? HTMLIFrameElement
                if (iframe != null) {
                    // this will refresh iframe
                    iframe.srcdoc = iframe.srcdoc
                }
            } else {
                setLastSlideIndexWhenModalWasOpened(slideIndex)
            }
        }

        val taskLine = task.split("\n").filterIndexed { index, s ->
                index == slideIndex
            }.firstOrNull() ?: ""

        val taskLineWithoutNumber = if (taskLine.length > 2 && taskLine.firstOrNull()?.isDigit() == true) {
            taskLine.substringAfter('.').trim()
        } else {
            taskLine
        }

        this.taskLine = taskLineWithoutNumber
        this.show = showEditModal
        this.slideIndex = slideIndex
        this.saveSlide = { taskFromEditSlide, sliderFromEditSlide ->
            val newTasks = generateTask(taskFromEditSlide)
            dispatch(UpdateTaskInCurrentRequest(newTasks))
            dispatch(UpdateSliderInCurrentRequest(sliderFromEditSlide))
            dispatch(SetHiddenIframeIsShown(true))
            dispatch(StartModalLoading("Preparing the content..."))
            closeModal()
            GlobalScope.launch {
                val sliderResponse = generateSliderRequest(newTasks, requestId, true)

                if (sliderResponse == null) {
                    dispatch(EndModalLoading())
                    return@launch
                }

                if (sliderResponse.link == null || sliderResponse.embedCode == null) {
                    return@launch
                }

                dispatch(UpdateCurrentRequestLink(sliderResponse.link, sliderResponse.embedCode))
            }
        }
        this.generateTask = {
            task.split("\n").mapIndexed { index, s ->
                if (index == slideIndex) "${index + 1}. $it" else s
            }.joinToString("\n")
        }
    }

    StatisticsModal {
        isOpen = isStatisticsModalOpened
        onClose = { setIsStatisticsModalOpened(false) }
        this.requestId = requestId
        update = isStatisticsModalOpened
    }

    section {
        ref = widgetRef
        css(widget)
        LanguageAutoSliderHeader {
            this.isLanguageAutoTaskOpened = isLanguageAutoTaskOpened
            this.requestId = requestId
            this.task = task
        }

        LanguageAutoTask {
            this.isOpened = isLanguageAutoTaskOpened
            onSave = {
                setIsLanguageAutoTaskOpened(false)
                dispatch(StartModalLoading("Preparing the content"))
                setIsSavingSliderLoading(true)
                GlobalScope.launch {
                    val sliderResponse = generateSliderRequest(it, requestId, true)
                    setIsSavingSliderLoading(false)
                    if (sliderResponse == null) {
                        dispatch(EndModalLoading())
                        return@launch
                    }

                    setSlideIndex(0)
                    setLastSlideIndexWhenModalWasOpened(0)
                    dispatch(UpdateSliderInCurrentRequest(sliderResponse.slider))
                    dispatch(UpdateTaskInCurrentRequest(it))

                    if (sliderResponse.link == null || sliderResponse.embedCode == null) {
                        return@launch
                    }

                    dispatch(UpdateCurrentRequestLink(sliderResponse.link, sliderResponse.embedCode))
                }
            }
            this.onCancelClick = { setIsLanguageAutoTaskOpened(false) }
        }
        if (isLanguageAutoTaskOpened) {
            return@section
        }
        div {
            css(sliderContainer)
            SliderName {
                this.requestId = requestId
            }
            div {
                css(iFrameContainer)
                div {
                    css(actionsWidth)
                }
                iframe {
                    css(iFrame)
                    id = "iframe-result-slider-preview"
                    srcDoc = getIframeSrc(getIframeWithCookieScript(slider, trackSlideIndexScript), goToSlideScript(lastSlideIndexWhenModalWasOpened))
                    onLoad = f@{
                        if (isSavingSliderLoading) {
                            return@f
                        }

                        entertainingLoader.endLoading()
                    }
                }
                div {
                    css(actions)
                    div {
                        css(action.extend(actionButton).extend(actionsWidth))
                        onClick = {
                            setShowEditModal(true)
                        }
                        div {
                            css(iconContainer)
                            Icon {
                                css {
                                    width = 22.px
                                    height = 22.px
                                }
                                src = "ic_pencil_simple_24x24.svg"
                            }
                        }
                        +"Edit exercise"
                    }
                    div {
                        css(action.extend(actionButton).extend(actionsWidth).extend {
                            marginBottom = 40.px
                        })
                        onClick = {
                            setIsLanguageAutoTaskOpened(true)
                        }
                        div {
                            css(iconContainer)
                            Icon {
                                css(grayFilter)
                                src = "ic_element_plus_24x24.svg"
                            }
                        }
                        +"Edit all exercises set"
                    }
                    div {
                        css(action.extend(actionButton))
                        onClick = {
                            setIsStatisticsModalOpened(true)
                        }
                        div {
                            css(iconContainer)
                            Icon {
                                css(grayFilter)
                                src = "ic_chart_32x22.svg"
                            }
                        }
                        +"Statistics"
                    }
                }
            }
        }
        div {
            css(divider)
        }
        ShareOrCreateNew {
            this.slider = slider
            this.link = link
            this.embedCode = embedCode
            this.requestId = requestId
        }
    }
}
