package online.interactiver.common.api

const val urlRequestDownloadPathPart = "/download"
const val urlRequestDownloadInteractivePathPart = "$urlRequestDownloadPathPart/interactive/"

const val interactivePictureUrlRequestPath = "/interactivePicture"
const val interactivePictureUrlRequestDownloadPath = "/interactivePicture$urlRequestDownloadPathPart"
const val multitaskTestRequestDownloadPath = "$interactivePictureUrlRequestDownloadPath/multitask-test"

const val urlRequestTemplates = "/templates"
const val urlRequestAuthEndpoint = "/auth/endpoint"
const val urlRequestToMainPageEndpoint = "/to-main"
const val urlRequestToFolderEndpoint = "/to-folder"