package shared.components.loader

import antd.Spin
import csstype.Display
import csstype.PlaceItems
import csstype.pct
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div

val Loader = FC<Props> {
    div {
        css {
            display = Display.grid
            placeItems = PlaceItems.center
            width = 100.pct
            height = 100.pct
        }
        Spin {
            size = "large"
        }
    }
}
