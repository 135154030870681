package widgets.ExerciseSettings.components.CustomLogoSettings

import csstype.*
import utils.types.CssStyle

val iconButton: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    border = None.none
    background = None.none
    boxShadow = None.none
    padding = 0.px
    height = 24.px
}

val inputHidden: CssStyle = {
    display = None.none
    width = 0.px
    height = 0.px
}
