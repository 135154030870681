package entities.interactivePicture.elements.editors.HeaderForSettings

import app.useAppDispatch
import app.useAppSelector
import builders.enums.EInteractiveType
import emotion.react.css
import entities.interactivePicture.selectElementsUnderSelectionRectangle
import entities.interactivePicture.selectFocusedElement
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.canvas.interfaces.getValueForEditor
import shared.canvas.interfaces.shouldDrawEditor

val HeaderForSettings = FC<Props> f@{
    val selected = useAppSelector(selectFocusedElement)
    val elementsUnderSelectionRectangle = useAppSelector(selectElementsUnderSelectionRectangle)

    val dispatch = useAppDispatch()

    val disabled = !shouldDrawEditor(selected, elementsUnderSelectionRectangle) { element ->
        element.type == EInteractiveType.INPUT_INTERACTIVE.text
                || element.type == EInteractiveType.BUTTON_INTERACTIVE.text
                || element.type == EInteractiveType.GAP_PUZZLE_INTERACTIVE.text

    }
    if (disabled) {
        return@f
    }
    var typeSettings = getValueForEditor( selected, elementsUnderSelectionRectangle ){ it.type }
    if (typeSettings.isNullOrEmpty()) typeSettings = "Common"


    div {
        css(container)
        div {
            css(header)
            +(typeSettings)
            +" settings"
        }

    }
}