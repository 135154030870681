package entities.interactivePicture.elements.figuresAndLines.static

import online.interactiver.common.interactivepicture.GeometryStyle
import online.interactiver.common.interactivepicture.PhraseStyle
import online.interactiver.common.interactivepicture.TextStyle

val rectWithTextStyle = GeometryStyle(
    fillColor = "#E0E8FC",
    opacity = 1.0,
    cornerRadius = 5,
    strokeWidth = 2,
)

val rectWithTextTextStyle = TextStyle(
    textColor = "#1C274C",
    fontFamily = "Arial",
    fontStyle = "700",
    fontSize = 14,
    align = "center",
    padding = 5,
)

val rectWithTextTextStyleOfPhrase = { padding: Int, fontSize: Int ->
    TextStyle(
        textColor = "#1C274C",
        fontFamily = "Arial",
        fontStyle = "bold",
        fontSize = fontSize,
        align = "center",
        padding = padding,
    )
}
