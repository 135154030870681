package app

import online.interactiver.common.utils.Copyable
import redux.RAction
import redux.Reducer

open class UndoableAction(open val preventHistoryUpdate: Boolean, open val preventFutureUpdate: Boolean = false) :
    RAction

open class Undo : UndoableAction(false)
open class Redo : UndoableAction(false)

data class Undoable<T>(
    val past: Array<T>,
    val present: T,
    val future: Array<T>,
)

fun <T> undoable(state: T): Undoable<T> {
    return Undoable(
        past = arrayOf(),
        present = state,
        future = arrayOf()
    )
}

fun <T : Copyable<T>, A : UndoableAction> undoableReducer(reducer: Reducer<T, A>): Reducer<Undoable<T>, A> {
    return fun(state: Undoable<T>, action: A): Undoable<T> {
        val newState = when (action) {
            is Undo -> {
                if (state.past.isNotEmpty()) {
                    val newPast = state.past.toMutableList()
                    val newFuture = state.future.toMutableList()
                    val newPresent = newPast.removeAt(newPast.size - 1)
                    newFuture.add(0, state.present.clone())
                    state.copy(newPast.toTypedArray(), newPresent, newFuture.toTypedArray())
                } else {
                    state
                }
            }

            is Redo -> {
                if (state.future.isNotEmpty()) {
                    val newPast = state.past.toMutableList()
                    val newFuture = state.future.toMutableList()
                    val newPresent = newFuture.removeAt(0)
                    newPast.add(state.present.clone())
                    state.copy(newPast.toTypedArray(), newPresent, newFuture.toTypedArray())
                } else {
                    state
                }
            }

            else -> {
                val newPast = state.past.toMutableList()
                if (!action.preventHistoryUpdate) {
                    newPast.add(state.present.clone())
                }
                val newPresent = reducer(state.present, action)
                if (action.preventFutureUpdate) {
                    state.copy(newPast.toTypedArray(), newPresent, state.future)
                } else {
                    state.copy(newPast.toTypedArray(), newPresent, arrayOf())
                }
            }
        }
        return newState
    }
}

fun <R, T, U> undoableSelector(selectUndoableField: (R) -> Undoable<T>, selector: (T) -> U): (R) -> U {
    return fun(state: R): U {
        return selector(selectUndoableField(state).present)
    }
}

fun <R> useCanUndo(undoableFieldSelector: (R) -> Undoable<out Copyable<*>>): (R) -> Boolean {
    return fun(state: R): Boolean {
        return undoableFieldSelector(state).past.isNotEmpty()
    }
}

fun <R> useCanUndo(undoableFieldsSelectors: Array<(R) -> Undoable<out Copyable<*>>>): (R) -> Boolean {
    return fun(state: R): Boolean {
        return undoableFieldsSelectors.fold(false) { canUndo: Boolean, selector: (R) -> Undoable<out Copyable<*>> ->
            canUndo || selector(state).past.isNotEmpty()
        }
    }
}

fun <R> useCanRedo(undoableFieldSelector: (R) -> Undoable<out Copyable<*>>): (R) -> Boolean {
    return fun(state: R): Boolean {
        return undoableFieldSelector(state).future.isNotEmpty()
    }
}

fun <R> useCanRedo(undoableFieldsSelectors: Array<(R) -> Undoable<out Copyable<*>>>): (R) -> Boolean {
    return fun(state: R): Boolean {
        return undoableFieldsSelectors.fold(false) { canUndo: Boolean, selector: (R) -> Undoable<out Copyable<*>> ->
            canUndo || selector(state).future.isNotEmpty()
        }
    }
}
