package entities.interactivePicture

import app.StoreState
import app.UndoableAction
import app.undoableSelector
import builders.enums.EElementGroup
import builders.enums.EInteractiveType
import entities.interactivePicture.advancedSettings.AdvancedSettingsAction
import entities.interactivePicture.advancedSettings.AdvancedSettingsReducer
import entities.interactivePicture.background.BackgroundAction
import entities.interactivePicture.background.BackgroundReducer
import entities.interactivePicture.elements.InteractiveElementAction
import entities.interactivePicture.elements.addCopiedElement
import entities.interactivePicture.elements.controls.ControlsReducer
import entities.interactivePicture.elements.editors.ScoreEditor.ScoreAction
import entities.interactivePicture.elements.editors.ScoreEditor.ScoreReducer
import entities.interactivePicture.elements.figuresAndLines.FiguresAndLinesReducer
import entities.interactivePicture.elements.frames.FramesReducer
import entities.interactivePicture.elements.gapPuzzles.GapPuzzlesReducer
import entities.interactivePicture.elements.gapPuzzles.drags.selectFocusedOption
import entities.interactivePicture.elements.other.OthersReducer
import entities.interactivePicture.elements.other.selectOthers
import entities.interactivePicture.styles.StylesAction
import entities.interactivePicture.styles.StylesReducer
import entities.selectedElement.selectElementsIdsUnderSelectionRectangle
import enums.EDeviceType
import online.interactiver.common.admin.interactive.phrase.Phrase
import online.interactiver.common.interactivepicture.*
import online.interactiver.common.math.LinearTransformation
import online.interactiver.common.utils.clone
import online.interactiver.common.utils.getSyncedInteractivePicture
import online.interactiver.common.utils.getTimeMillisInBase
import shared.canvas.interfaces.*
import utils.structures.ElementPosition

data class SetInteractivePicture(val interactivePicture: InteractivePicture, val shouldGenerateNewId: Boolean) : UndoableAction(false)
data class ReplaceInteractivePicture(val interactivePicture: InteractivePicture, val shouldGenerateNewId: Boolean) : UndoableAction(true)
data class SetInteractiveName(val name: String) : UndoableAction(false)
data class SetDeviceType(val deviceType: String, val maxWidth: Int) : UndoableAction(false)
data class SyncPhrasesFromDictionary(val phrases: Set<Phrase>, val newSyncedElementId: String? = null) : UndoableAction(false)
data class AddCopiedElements(val ids: Array<String>, val copiedElements: MutableList<InteractiveElement>) : UndoableAction(false)

val addCopiedElements = { state: InteractivePicture, ids: Array<String>, copiedElements: MutableList<InteractiveElement> ->
    val newState = state.clone()

    for (i in ids.indices) {
        if (copiedElements[i].isDraggable()) {
            newState.gapPuzzles = addCopiedElement(newState.gapPuzzles ?: mutableListOf(), ids[i], copiedElements[i])
        } else if (copiedElements[i].isControl()) {
            newState.controls = addCopiedElement(newState.controls ?: mutableListOf(), ids[i], copiedElements[i])
        } else if (copiedElements[i].isFiguresAndLines()) {
            newState.figuresAndLines = addCopiedElement(newState.figuresAndLines ?: mutableListOf(), ids[i], copiedElements[i])
        } else if (copiedElements[i].isFrame()) {
            newState.frames = addCopiedElement(newState.frames ?: mutableListOf(), ids[i], copiedElements[i])
        } else if (copiedElements[i].isOther()) {
            newState.others = addCopiedElement(newState.others ?: mutableListOf(), ids[i], copiedElements[i])
        }
    }

    newState
}

val InteractivePictureReducer = { state: InteractivePicture, action: UndoableAction ->
    when (action) {
        is BackgroundAction -> {
            state.copy(background = BackgroundReducer(state.background, action))
        }

        is AddCopiedElements -> addCopiedElements(state, action.ids, action.copiedElements)

        is InteractiveElementAction -> {
            state.copy(
                frames = FramesReducer(state.frames!!, action),
                figuresAndLines = FiguresAndLinesReducer(state.figuresAndLines!!, action),
                controls = ControlsReducer(state.controls!!, action),
                gapPuzzles = GapPuzzlesReducer(state.gapPuzzles!!, action),
                others = OthersReducer(state.others ?: mutableListOf(), action)
            )
        }

        is ScoreAction -> {
            state.copy(
                frames = ScoreReducer(state.frames!!, action),
                figuresAndLines = ScoreReducer(state.figuresAndLines!!, action),
                controls = ScoreReducer(state.controls!!, action),
                gapPuzzles = ScoreReducer(state.gapPuzzles!!, action),
                others = ScoreReducer(state.others ?: mutableListOf(), action)
            )
        }

        is StylesAction -> {
            state.copy(style = StylesReducer(state.style, action))
        }

        is AdvancedSettingsAction -> {
            state.copy(advancedSettings = AdvancedSettingsReducer(state.advancedSettings, action))
        }

        is SetInteractivePicture -> setInteractivePicture(state, action.interactivePicture, action.shouldGenerateNewId)

        is ReplaceInteractivePicture -> setInteractivePicture(state, action.interactivePicture, action.shouldGenerateNewId)

        is SetInteractiveName -> {
            val newIdentifier = state.identifier.copy(name = action.name)
            newIdentifier.setUniqueCodeByName()
            state.copy(identifier = newIdentifier)
        }

        is SyncPhrasesFromDictionary -> state.getSyncedInteractivePicture(action.phrases, action.newSyncedElementId)

        is SetDeviceType -> state.copy(
            deviceType = action.deviceType,
            background = state.background.copy(
                maxWidth = action.maxWidth
            )
        )

        else -> state
    }
}

val selectInteractiveName = { state: StoreState -> selectInteractivePicture(state).identifier.name }
val selectInteractiveGroup = { state: StoreState -> state.interactiveGroupIDOnServer }
val selectInteractivesGroups = { state: StoreState -> state.interactivesGroups }

val selectUndoableInteractivePicture = { state: StoreState -> state.interactivePicture }
val selectInteractivePicture = undoableSelector(selectUndoableInteractivePicture) { s: InteractivePicture -> s }

val selectFiguresAndLines = { state: StoreState -> selectInteractivePicture(state).figuresAndLines }
val selectFrames = { state: StoreState -> selectInteractivePicture(state).frames }
val selectControls = { state: StoreState -> selectInteractivePicture(state).controls }
val selectGapPuzzles = { state: StoreState -> selectInteractivePicture(state).gapPuzzles }

val selectFocusedElement = { state: StoreState ->
    val elements = mutableListOf<InteractiveElement?>()
    arrayOf(
        selectFiguresAndLines,
        selectFrames,
        selectControls,
        selectGapPuzzles,
        selectOthers,
        { s: StoreState -> selectFocusedOption(s)?.frames },
        { s: StoreState -> selectFocusedOption(s)?.figuresAndLines },
    ).forEach { selector ->
        val element = selector(state)?.find { it.identifier.id.equals(state.selectedElementId) }
        elements.add(element)
    }
    elements.find { it != null }
}

val selectStatic = { state: StoreState ->
    val elements = mutableListOf<InteractiveElement>()
    arrayOf(selectFrames, selectFiguresAndLines).forEach { selector ->
        selector(state)!!.forEach { elements.add(it) }
    }
    elements
}

val selectElements = { state: StoreState ->
    val elements = mutableListOf<InteractiveElement>()
    arrayOf(selectFrames, selectFiguresAndLines, selectControls, selectGapPuzzles, selectOthers).forEach { selector ->
        selector(state)?.forEach { elements.add(it) }
    }
    elements
}

val selectInteractiveElementsBounds = { state: StoreState ->
    selectElements(state).map { ElementPosition(it.identifier.id!!, it.getElementBounds()) }.toTypedArray()
}


val selectElementsUnderSelectionRectangle = { state: StoreState ->
    val result = arrayListOf<InteractiveElement>()
    for (index in 0 until selectElementsIdsUnderSelectionRectangle(state).size){
        selectElements(state).find { it.identifier.id!! == selectElementsIdsUnderSelectionRectangle(state)[index] }
            ?.let { result.add(it) }
    }
    result
}

val selectByGroup = { state: StoreState, group: EElementGroup ->
    val filter = EInteractiveType.values().filter { it.group == group }.map { it.text }
    selectElements(state).filter { filter.contains(it.type) }
}

val refreshScore = f@{ list: MutableList<InteractiveElement>? ->
    if (list == null) {
        return@f null
    }

    for (i in 0 until list.size) {
        if (list[i].scores == null) {
            list[i].scores = mutableListOf(ScoreData(DEFAULT_SCORE, null))
        }
    }

    list
}

val refreshSyncingPhrasesOfList = f@{ list: MutableList<InteractiveElement>? ->
    if (list == null) {
        return@f null
    }

    for (i in 0 until list.size) {
        if (list[i].isSynced == null && list[i].shouldSyncPhrase()) {
            list[i].isSynced = false
        }
    }

    list
}

val refreshSyncingPhrasesOfSelectorOptions = f@{ list: MutableList<SelectorOption>? ->
    if (list == null) {
        return@f null
    }

    for (i in 0 until list.size) {
        list[i].figuresAndLines = refreshSyncingPhrasesOfList(list[i].figuresAndLines)
    }

    list
}

val refreshSyncingPhrasesOfControls = f@{ list: MutableList<InteractiveElement>? ->
    val controls = refreshSyncingPhrasesOfList(list) ?: return@f null

    for (i in 0 until controls.size) {
        if (controls[i].isSelector()) {
            controls[i] = controls[i].copy(
                selector = controls[i].selector?.copy(
                    options = refreshSyncingPhrasesOfSelectorOptions(controls[i].selector?.options?.clone())
                )
            )
        }
    }

    controls
}

val setInteractivePicture = { state: InteractivePicture, newState: InteractivePicture, shouldGenerateNewId: Boolean ->
    val scale = (state.background.width?.toDouble() ?: 1.0) / (newState.background.width ?: 1)
    newState.applyTransformation(LinearTransformation(scale))
    newState.background.width = state.background.width
    newState.background.height = state.background.height

    newState.gapPuzzles = refreshScore(newState.gapPuzzles?.clone())
    newState.controls = refreshScore(newState.controls?.clone())

    newState.controls = refreshSyncingPhrasesOfControls(newState.controls?.clone())
    newState.gapPuzzles = refreshSyncingPhrasesOfList(newState.gapPuzzles?.clone())
    newState.figuresAndLines = refreshSyncingPhrasesOfList(newState.figuresAndLines)

    if (newState.background.maxWidth == null) {
        when (newState.deviceType) {
            EDeviceType.DESKTOP.value -> newState.background.maxWidth = EDeviceType.DESKTOP.maxWidth
            EDeviceType.MOBILE.value -> newState.background.maxWidth = EDeviceType.MOBILE.maxWidth
        }
    }

    if (shouldGenerateNewId) {
        newState.identifier.id = getTimeMillisInBase()
    }

    newState
}

val selectDeviceType = { state: StoreState ->
    selectInteractivePicture(state).deviceType
}

val selectElementIsFocused = { state: StoreState ->
    selectFocusedElement(state) != null
}

val selectFocusedElementCustomPuzzleInitPosition = { state: StoreState ->
    selectFocusedElement(state)?.gapPuzzle?.customPuzzleInitialPosition
}

val selectFocusedElementId = { state: StoreState ->
    selectFocusedElement(state)?.identifier?.id
}

fun filterArray(array: List<InteractiveElement>?, filter: Map<EInteractiveType, Boolean>): List<InteractiveElement> {
    val result = arrayListOf<InteractiveElement>()

    array!!.forEach { element ->
        val eType = EInteractiveType.values().find { element.type == it.text }
        if (!filter.containsKey(eType) || filter.get(eType)!!) {
            result.add(element)
        }
    }

    return result
}