package pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewShareTabs.integrateTabContent

import antd.Button
import antd.TextArea
import antd.TypedInputNumber
import app.useAppDispatch
import app.useSaveInteractive
import emotion.react.css
import entities.alert.EAlertTarget
import entities.interactivePicture.elements.other.ui.tools.phrase.inputNumber
import entities.interactivePicture.elements.other.ui.tools.phrase.styleParamContainerVertical
import enums.EButtonTypes
import generateHtmlInteractiveByUuid
import kotlinx.browser.window
import kotlinx.coroutines.*
import kotlinx.serialization.json.Json
import online.interactiver.common.admin.interactive.InteractiveResponse
import org.w3c.dom.url.URL
import pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.LinkSettingsContext
import pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewShareTabs.PrepareLinkAlertCallback
import pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewShareTabs.publishTabContent.buttonPrimary
import pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewShareTabs.publishTabContent.whiteFilter
import pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewShareTabs.showAlert
import react.*
import react.dom.html.ReactHTML
import redux.RAction
import shared.components.Icon
import updateInteractiveHTMLByPublicLink
import utils.extractUUIDFromUrl

val IntegrateTabContent = VFC {
    ReactHTML.div {
        css(container)
        Annotation {}
        IframeCodeEditor {}
    }
}

val Annotation = VFC {
    ReactHTML.div {
        css(annotation)
        +"When integrating into another platform (Tilda, Wix, and others), you need to select an iframe or HTML block and insert the code there"
    }
}

val IframeCodeEditor = VFC {
    val uuid = extractUUIDFromUrl(window.location.href)

    val (widthScale, setWidthScale) = useState(0.9)
    val (heightScale, setHeightScale) = useState(0.9)
    val context = useContext(LinkSettingsContext)
    val (publishLink, setPublishLink) = useState<String?>(null)

    val (iframeSrc, setIframeSrc) = useState<String?>(null)

    val dispatch = useAppDispatch()

    useEffect(widthScale, heightScale, context, publishLink) {
        if (publishLink == null) {
            return@useEffect
        }

        val newIframeSrc = URL(publishLink)
        newIframeSrc.searchParams.set("widthScale", widthScale.toString())
        newIframeSrc.searchParams.set("heightScale", heightScale.toString())
        context.applyToUrl(newIframeSrc)

        setIframeSrc(newIframeSrc.toString())
    }

    val (iframeCode, setIframeCode) = useState<String?>(null)

    useEffect(iframeSrc) {
        setIframeCode(getIframeCode(iframeSrc))
    }


    val saveInteractive = useSaveInteractive()
    useEffectOnce {
        if (uuid.isNullOrBlank()) {
            return@useEffectOnce
        }
        MainScope().launch {
            val link = getInteractiveLink(uuid, saveInteractive) ?: return@launch
            setPublishLink(link)
        }
    }

    if (iframeCode == null) {
        return@VFC
    }

    ReactHTML.div {
        css(iframeEditorContainer)
        ReactHTML.div {
            css(iframeEditorHeading)
            +"HTML embed code"
        }
        ReactHTML.div {
            css(textAreaAndInputs)

            TextArea {
                css(textArea)
                autoSize = true
                readOnly = true
                value = iframeCode
            }
            ReactHTML.div {
                css(inputs)
                ReactHTML.div {
                    css(styleParamContainerVertical)
                    ReactHTML.div {
                        css(radioLabel)
                        +"Width"
                    }
                    TypedInputNumber {
                        css(inputNumber)
                        min = 0.01
                        controls = true
                        step = 0.01
                        precision = 2
                        value = widthScale
                        onChange = {
                            setWidthScale(it.toDouble())
                        }
                    }
                }
                ReactHTML.div {
                    css(styleParamContainerVertical)
                    ReactHTML.div {
                        css(radioLabel)
                        +"Height"
                    }
                    TypedInputNumber {
                        css(inputNumber)
                        min = 0.01
                        controls = true
                        step = 0.01
                        precision = 2
                        value = heightScale
                        onChange = {
                            setHeightScale(it.toDouble())
                        }
                    }
                }
            }
        }
    }
    ReactHTML.div {
        Button {
            css(buttonPrimary)
            id = EButtonTypes.PUBLISH_LINK_PUBLISH_TAB_INPUT.value
            +"Publish & Copy"
            Icon {
                css(whiteFilter)
                src = "ic_link_45deg_24x24.svg"
            }
            onClick = {
                MainScope().launch {
                    val (closeInfoAlert, showSuccessAlert, showErrorAlert) = showPrepareIframeAlert(dispatch)
                    val link = getInteractiveLink(uuid!!, saveInteractive)
                    closeInfoAlert()
                    if (link != null) {
                        setPublishLink(link)
                        window.navigator.clipboard.writeText(iframeCode)
                        showSuccessAlert()
                    } else {
                        showErrorAlert()
                    }
                }
            }
        }
    }
}

private fun showPrepareIframeAlert(dispatch: (RAction) -> (RAction)): Triple<PrepareLinkAlertCallback, PrepareLinkAlertCallback, PrepareLinkAlertCallback> {
    return showAlert(
        dispatch,
        "Your iframe embed code is being prepared...",
        "The iframe embed code has been copied successfully!",
        "Some error occurred!"
    )
}

fun getIframeCode(src: String?) = if (src == null) null else """
    <iframe 
        src="$src" 
        width="360.0" height="417.0"
        style="border:0;" 
        allowfullscreen="" 
        scrolling="no" 
        align="absmiddle"
        loading="lazy">
    </iframe>
""".trimIndent()

suspend fun getInteractiveLink(uuid: String, saveInteractive: (() -> Unit) -> Job?): String? {
    return coroutineScope {
        saveInteractive {}?.join()
        val generateHtmlResponse = async { generateHtmlInteractiveByUuid(uuid) }.await()
        val updatePublicResponse = async { updateInteractiveHTMLByPublicLink(uuid) }.await()
        if (generateHtmlResponse.code != 200 || updatePublicResponse.code != 200) {
            return@coroutineScope null
        }
        val decodeFromString = Json {
            ignoreUnknownKeys = true
            explicitNulls = false
        }.decodeFromString<InteractiveResponse>(updatePublicResponse.component1())
        return@coroutineScope decodeFromString.defaultPublicLink ?: decodeFromString.interactivesLink
    }
}