package widgets.ExerciseSettings.components.Warning

import csstype.*
import utils.types.CssStyle

val warningContainer: CssStyle = {
    padding = Padding(0.px, 10.px)
    boxSizing = BoxSizing.borderBox
    width = 100.pct
    borderRadius = 12.px
    backgroundColor = Color("#F4F7FF")
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
}

val warning: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 2.px
}

val warningText: CssStyle = {
    fontSize = 14.px
    lineHeight = 18.2.px
    fontWeight = integer(500)
    color = Color("#597EF7")
}

val closeWarningButton: CssStyle = {
    boxShadow = None.none
    border = None.none
    padding = 0.px
    backgroundColor = Color("#F4F7FF")
    height = 24.px
}