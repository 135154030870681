package online.interactiver.common.enums

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Serializable(with = EUploadExerciseErrorSerializer::class)
enum class EUploadExerciseError(val response: String) {
    CANT_UPDATE_INTERACTIVE("Can't update interactive."),
    NOT_UNIQUE_CUSTOM_LINK("Not unique custom link."),
    CANT_FIND_INTERACTIVE_GROUP("Can't find group for interactive."),
    EXERCISE_WAS_ALREADY_UPLOADED("Duplicate: this exercise was already uploaded."),
    CANT_SAVE_EXERCISE("Can't save your exercise."),
    INTERACTIVE_IS_NULL("Null interactive."),
    LINK_IS_NULL("Link is null."),
    EXERCISE_IS_NOT_PROVIDED("Exercise is not provided"),
    FAILED_TO_GET_FILE_CONTENT("Failed to get file content."),
    NO_DUPLICATE_FOUND("No interactive with such interactive_id found."),
    NO_AUTOGENERATION_REQUEST("No request with interactive_uuid of provied interactive found."),
    NO_ACCESS("You have no access to this exercise");

    override fun toString(): String = response

    companion object {
        fun fromResponseOrNull(response: String) = entries.firstOrNull { it.response == response }
        fun fromResponse(response: String) = entries.first { it.response == response }
    }
}

class EUploadExerciseErrorSerializer : KSerializer<EUploadExerciseError> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("EUploadExerciseError", PrimitiveKind.STRING)

    override fun deserialize(decoder: Decoder): EUploadExerciseError {
        val response = decoder.decodeString()
        return EUploadExerciseError.fromResponse(response)
    }
    override fun serialize(encoder: Encoder, value: EUploadExerciseError) {
        encoder.encodeString(value.toString())
    }
}
