package entities.interactivePicture.elements.editors.HeaderForSettings

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    padding = 20.px
    paddingBottom = 0.px
    borderTop = Border(1.px, LineStyle.solid, Color("#D7D7D7"))
    alignItems = AlignItems.start
}

val header: CssStyle = {
    fontFamily = string("\"Inter\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 12.px
    lineHeight = 18.px
    letterSpacing = (-0.002).em
    color = Color("#2E2E2E")
    flex = None.none
    order = integer(0)
    flexGrow = number(0.0)
    marginBottom = 6.px
}