package widgets.StudentDuoSlider.ui

import react.VFC
import react.router.dom.useSearchParams
import react.router.useNavigate
import shared.components.ProtectedRoute.ProtectedRoute

val ProtectedStudentDuoSlider = VFC {
    val (searchParams) = useSearchParams()
    val isPublic = searchParams.get("public")?.toBooleanStrictOrNull()
    val navigate = useNavigate()

    if (isPublic == true) {
        StudentDuoSlider {
            onContinueClick = {
                navigate("/duo/content")
            }
        }
        return@VFC
    }

    ProtectedRoute {
        toStudentDuo = true
        StudentDuoSlider {
            onContinueClick = {
                navigate("/duo/content")
            }
        }
    }
}
