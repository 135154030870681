package widgets.Exercises.ui.components.StatisticsModal.components.CustomTooltip

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    backgroundColor = Color("white")
    opacity = number(0.8)
    borderRadius = 5.px
    display = Display.flex
    flexDirection = FlexDirection.column
    padding = 20.px
    boxSizing = BoxSizing.borderBox
    gap = 15.px
}

val header: CssStyle = {
    margin = 0.px
    fontWeight = integer(500)
}

val bar: (String?) -> CssStyle = {
    {
        it?.let {  color = Color(it) }
        margin = 0.px
    }
}
