package pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewShareTabs.viewStatisticsTabContent

import csstype.*
import utils.types.CssStyle
import utils.types.extend

val tabContainer: CssStyle = {
    height = 100.pct
    display = Display.flex
    flexDirection = FlexDirection.column
}

val filtersContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    rowGap = 12.px
    marginBottom = 22.px
}

val filter: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.center
}

val filterName: CssStyle = {
    color = Color("#282F3E")

    width = 30.pct
    fontSize = 14.px
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    lineHeight = 135.pct
}

val filterEditor: CssStyle = {
    width = 70.pct
}

val selectStyle: CssStyle = {
    width = 100.pct
}

val buttonGeneral: CssStyle = {
    width = 100.pct
    height = 40.px
    border = None.none
    borderRadius = 5.px
    padding = Padding(10.px, 16.px)
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.center
    gap = 16.px
    alignItems = AlignItems.center
    fontSize = 14.px
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    lineHeight = 20.px
}

val buttonPrimary = buttonGeneral.extend {
    color = Color("#FFFFFF")
    backgroundColor = Color("#597EF7")
    hover {
        color = important(Color("#FFFFFF"))
        backgroundColor = important(Color("#7B98F9"))
    }
}

val resultButton = buttonPrimary.extend {
    marginBottom = 24.px
}

// #FFFFFF - color filter
val whiteFilter: CssStyle = {
    filter = invert(100.pct)
}


val scoreAliasCheckboxWidth = 6.pct
val scoreAliasInputWidth = 47.pct

val scoreAliasesContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
}

val scoreAliasesSectionHeader: CssStyle = {
    color = Color("#282F3E")
    fontSize = 14.px
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    lineHeight = 135.pct
    marginBottom = 10.px
}

val scoreAliasesHeader: CssStyle = {
    color = Color("#5D6676")
    fontSize = 12.px
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    lineHeight = 135.pct
    marginBottom = 8.px
}

val scoreAliases: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 12.px
}

val scoreAlias: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    gap = 12.px
    justifyContent = JustifyContent.spaceBetween
}