package pages.constructor.ui.components.header.styleSettings.lineHeightInput

import utils.types.CssStyle
import csstype.*
import pages.constructor.ui.components.header.styleSettings.secondary
import utils.inject

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.flexStart
    gap = 10.px
    alignSelf = AlignSelf.stretch
    width = 92.px
}
val header: CssStyle = {
    color = Color("#101828")
    fontFamily= string("\"Arial\", system-ui")
    fontSize = 12.px
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    lineHeight = 130.pct
}
val lineContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.flexStart
    justifyContent = JustifyContent.spaceBetween
}
val button: CssStyle = {
    display = Display.flex
    justifyContent = JustifyContent.center
    alignItems = AlignItems.center
    padding = 0.px
    margin = 0.px
    width = 24.px
    height = 24.px
    borderRadius = 3.px
    border = None.none
    marginRight = 12.px
}
val inputNumber: CssStyle = {
    inject(secondary)
    width = 56.px
    padding = important(Padding(0.px, 2.px))
}