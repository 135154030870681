package pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewShareTabs.integrateTabContent

import csstype.*
import utils.types.CssStyle
import utils.types.extend

val container: CssStyle = {
    height = 100.pct
    display = Display.flex
    flexDirection = FlexDirection.column
}

val annotation: CssStyle = {
    color = Color("#5D6676")

    fontSize = 14.px
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    lineHeight = 135.pct

    marginBottom = 24.px
}

val iframeEditorContainer: CssStyle = {
    marginBottom = 20.px
}

val iframeEditorHeading: CssStyle = {
    color = Color("#282F3E")
    fontSize = 14.px
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    lineHeight = 135.pct

    paddingBottom = 6.px
}

val textAreaAndInputs: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    columnGap = 16.px
}

val textArea: CssStyle = {
    color = Color("#5D6676")
    fontSize = 12.px
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    lineHeight = 130.pct

    resize = important(None.none)
}

val inputs: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    rowGap = 20.px
}

val buttonGeneral: CssStyle = {
    width = 100.pct
    height = 40.px
    border = None.none
    borderRadius = 5.px
    padding = Padding(10.px, 16.px)
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.center
    gap = 16.px
    alignItems = AlignItems.center

    fontSize = 14.px
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    lineHeight = 20.px
}

val buttonPrimary = buttonGeneral.extend {
    color = Color("#FFFFFF")
    backgroundColor = Color("#597EF7")
    hover {
        color = important(Color("#FFFFFF"))
        backgroundColor = important(Color("#7B98F9"))
    }
}

val whiteFilter: CssStyle = {
    filter = invert(100.pct)
}

val subheader: CssStyle = {
    fontSize = 14.px
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    lineHeight = 135.pct
    color = Color("#282F3E")
    marginBottom = 17.px
}

val radioLabel: CssStyle = subheader.extend {
    marginBottom = 0.px
}
