package pages.constructor.ui.components.canvas.selectionRectangle

import KonvaEventObject
import org.w3c.dom.events.MouseEvent
import pages.constructor.ui.components.canvas.SelectionRectangle
import react.FC
import react.Props
import react.Ref
import react.ref
import reactkonva.Rect

external interface SelectionRectangleProps : Props {
    var selectionRectangleRef: Ref<dynamic>?
    var selectionRectangle: SelectionRectangle?
    var onMouseUp: ((evt: KonvaEventObject<MouseEvent>) -> Unit)?
}

val SelectionRectangle = FC<SelectionRectangleProps> { props ->
    props.selectionRectangle?.let {
        Rect {
            this.ref = props.selectionRectangleRef
            x = it.start.x
            y = it.start.y
            width = it.end.x - it.start.x
            height = it.end.y - it.start.y
            opacity = 0.5
            fill = "#3081cb"
            strokeWidth = 2
            stroke = "#034f95"
            onMouseUp = props.onMouseUp
        }
    }
}
