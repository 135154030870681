package widgets.Exercises.ui.components.FoldersPath

import csstype.*
import utils.types.CssStyle

val folderName: (Boolean) -> CssStyle = { isLastChild ->
    {
        fontSize = 16.px
        if (!isLastChild) {
            cursor = Cursor.pointer
            hover {
                textDecoration = TextDecoration.underline
            }
            color = Color("#98A2B3")
        }

        if (isLastChild) {
            color = Color("#282F3E")
        }
    }
}

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.center
    flexWrap = FlexWrap.wrap
}

val iconRight: CssStyle = {
    position = Position.relative
    top = 2.px
    scale = number(1.2)
}
