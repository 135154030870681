package pages.constructor.ui.components.header.templates.element

import csstype.px
import emotion.react.css
import enums.EButtonTypes
import online.interactiver.common.api.TagColor
import online.interactiver.common.api.TemplateMeta
import pages.constructor.ui.components.header.templates.tag.TemplateTag
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div

external interface TemplatesElementProps : Props {
    var templateMeta: TemplateMeta
    var setCurTemplate: (template: TemplateMeta) -> Unit
    var tags: MutableList<TagColor>
}

val TemplatesElement = FC<TemplatesElementProps> { props ->
    val containerHeight = if (props.templateMeta.tags.isEmpty()) 150 else 210
    div {
        css(container(containerHeight.px))
        id = EButtonTypes.TEMPLATE_BUTTON.value
        onClick = { props.setCurTemplate(props.templateMeta) }

        div {
            css(mainContainer)
            div {
                css(preview)
                ReactHTML.img {
                    css(previewImage)
                    src = props.templateMeta.getImageURL()
                }
            }
            div {
                css(infoContainer)
                div {
                    css(header)
                    +props.templateMeta.name
                }
                div {
                    css(description)
                    +props.templateMeta.description
                }
            }
            div {
                css(tagContainer)
                props.templateMeta.tags.forEach {
                    val color = props.tags.filter { tag -> tag.tagName == it }
                    TemplateTag {
                        tagName = it
                        tagColor = if (color.isNotEmpty()) color.first().color else "#D9D9D9"
                        tagTextColor = if (color.isNotEmpty()) color.first().textColor else "#737373"
                    }
                }
            }
        }

    }
}
