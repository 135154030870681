package widgets.StudentDuoContent.ui

import antd.Button
import antd.notification
import app.useAppDispatch
import csstype.*
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import entities.modalLoader.useModalLoaderWithEntertainingTexts
import entities.solvedTasks.ui.SolvedTasks
import enums.EButtonTypes
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import pages.constructor.ui.components.elements.lightBlueFilter
import pages.languageAuto.ui.ERoute
import pages.studentDuo.*
import pages.studentDuo.ui.components.UsedGenerations.NUMBER_OF_FREE_ATTEMPTS
import pages.studentDuo.ui.components.UsedGenerations.UsedGenerations
import react.ReactNode
import react.VFC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.section
import react.redux.useSelector
import react.router.useNavigate
import react.useEffectOnce
import shared.components.Icon
import shared.components.ProtectedRoute.ProtectedRoute
import utils.localization.Localization
import utils.types.extend
import utils.types.impl
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton
import widgets.StudentDuoContent.generateStudentDuoCourse
import widgets.StudentDuoContent.ui.LanguageSettingsModal.LanguageSettingsModal
import widgets.StudentDuoContent.ui.TopicTextArea.TopicTextArea
import widgets.StudentDuoContent.useFocusIf

enum class EStudentDuoSearchParams(val value: String) {
    INTERFACE_LANGUAGE("interface_language"),
    LANGUAGE_TO_LEARN("language_to_learn"),
    LANGUAGE_LEVEL("language_level")
}

val StudentStoryContent = VFC {
    val state = useSelector(selectStudentDuoState)
    val userProfile = useSelector(selectStudentDuoUserProfile)
    val numberOfUsedGenerations = useSelector(selectStudentDuoNumberOfUsedGenerations)
    val prevExercise = useSelector(selectStudentDuoPrevExercise)

    val dispatch = useAppDispatch()

    val (startLoading, endLoading) = useModalLoaderWithEntertainingTexts(true, userProfile?.nativeLanguage ?: "", { dispatch(StartModalLoading(it))}) {
        dispatch(EndModalLoading())
    }

    useConfirmPayment()
    useFocusIf(state.languageToLearn != null, EButtonTypes.DUO_CONTENT_TEXTAREA.value)

    val navigate = useNavigate()
    val isBasicTariff = userProfile?.tariffPlan?.uppercase() == "BASIC"

    ProtectedRoute {
        toStudentDuo = true
        section {
            css(content)
            SolvedTasks {}
            LanguageSettingsModal {}
            div {
                css(pageContainer)
                TopicTextArea {
                    value = state.text
                    this.onChange = {
                        dispatch(SetStudentDuoTextOrTopic(it))
                    }
                    interfaceLanguage = state.interfaceLanguage
                    maxUserTextLen = if (isBasicTariff) 1000 else 5000
                }

                val isFreeGenerationsUsed = isBasicTariff
                        && (numberOfUsedGenerations != null && numberOfUsedGenerations >= NUMBER_OF_FREE_ATTEMPTS)
                Button {
                    css((if (isFreeGenerationsUsed) generateButton.extend(disabledButton) else generateButton).extend {
                        width = Auto.auto
                    })
                    Icon {
                        src = "ic_magic_wand_24x24.svg"
                    }
                    onClick = f@{
                        if (isFreeGenerationsUsed) {
                            navigate(ERoute.PAYMENT.path)
                        } else {
                            if (state.text.isBlank()) {
                                dispatch(
                                    OpenErrorModal(
                                        title = Localization.getPleaseTypeTheText(state.interfaceLanguage)
                                    )
                                )
                                return@f
                            }
                            startLoading()
                            GlobalScope.launch {
                                val response = generateStudentDuoCourse(state)
                                endLoading()
                                if (response.data == null) {
                                    dispatch(
                                        OpenErrorModal(
                                            title = Localization.getGenerateNetworkErrorPleaseTryAgain(state.interfaceLanguage)
                                        )
                                    )
                                    return@launch
                                }

                                dispatch(SetStudentDuoNumberOfUsedGenerations((numberOfUsedGenerations ?: 0) + 1))

                                navigate("/duo/folder/${response.data}")
                            }
                        }
                    }
                    +Localization.getGenerateButtonText(state.interfaceLanguage)
                }
                if (prevExercise != null) {
                    Button {
                        css(newTaskButton.extend {
                            width = 100.pct
                        })
                        disabled = isBasicTariff && (numberOfUsedGenerations != null && numberOfUsedGenerations >= NUMBER_OF_FREE_ATTEMPTS)
                        Icon {
                            css(lightBlueFilter)
                            src = "ic_magic_wand_24x24.svg"
                        }
                        onClick = {
                            startLoading()
                            GlobalScope.launch {
                                val response = generateStudentDuoByPrevExerciseRequest(prevExercise.id)
                                endLoading()
                                if (response.data?.slider == null || response.data.requestId == null) {
                                    return@launch
                                }

                                dispatch(SetStudentDuoNumberOfUsedGenerations((numberOfUsedGenerations ?: 0) + 1))

                                dispatch(StartModalLoading("Painting exercise..."))
                                navigate("/duo/exercise/${response.data.requestId}")
                            }
                        }
                        +"Back to previous topic"
                    }
                }

                UsedGenerations {}
            }
        }
    }
}