package pages.constructor.ui.components.header.previewShare.previewModal.previewIframe

import csstype.*
import utils.types.CssStyle
import utils.types.NativeCssStyle

val iFrame: NativeCssStyle = {
    height = "100%"
    width = "100%"
    border = "0px hidden"
    overflowX = "hidden"
    overflowY = "hidden"
    margin = "0px"
    padding = "0px"
}

val iFrameContainer: CssStyle = {
    height = 100.pct
    borderRadius = 10.px
    background = Color("#D0D5DD")
    boxShadow = BoxShadow(0.px, 4.px, 20.px, 0.px, Color("#14264A26"))
}

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    height = 100.pct
    width = 70.pct.minus(16.px)
}
