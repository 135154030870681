package widgets.LanguageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.CorrectOrderTaskLineEditor.CorrectOrderFromThreeTaskLineEditor

import react.FC
import widgets.LanguageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.CorrectOrderTaskLineEditor.CorrectOrderTaskLineEditor
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.TaskLineUiEditorWithSpecifiedPatternProps

val CorrectOrderFromThreeTaskLineEditor = FC<TaskLineUiEditorWithSpecifiedPatternProps> { props ->
    CorrectOrderTaskLineEditor {
        keyPointsCount = 3

        onChange = props.onChange
        taskLine = props.taskLine
        maxWordLength = props.maxWordLength
    }
}