package widgets.LanguageAutoContent.ui.components.Contents

import csstype.*
import utils.types.CssStyle

val contents: CssStyle = {
    display = Display.flex
    gap = 16.px
    overflow = Overflow.hidden
    width = 936.px
    height = 198.px
    position = Position.relative
    boxSizing = BoxSizing.borderBox
    left = (-16).px
    padding = Padding(10.px, 16.px)
    position = Position.relative
}

val navigateButton: CssStyle = {
    position = Position.absolute
    top = 77.px
    border = None.none
    boxShadow = BoxShadow(0.px, 2.px, 10.px, 0.px, Color("#0000001A"))
    borderRadius = 50.pct
    padding = 0.px
    width = 44.px
    height = 44.px
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
}
