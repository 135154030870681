package pages.constructor.ui.components.header.styleSettings.fontSizeInput

import antd.Button
import antd.TypedInputNumber
import emotion.react.css
import pages.constructor.ui.components.header.styleSettings.colorPicker.container
import pages.constructor.ui.components.header.styleSettings.secondary
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useRef
import shared.components.Icon

external interface FontSizeInputProps : Props {
    var value: Int?
    var min: Int?
    var max: Int?
    var onChange: ((Int) -> Unit)?
    var onAfterChange: ((Int) -> Unit)?
}

val FontSizeInput = FC<FontSizeInputProps> { props ->
    val min = props.min ?: 8
    val max = props.max ?: 72
    val value = props.value ?: 0

    val oldValue = useRef(value)

    div {
        css(container)
        div {
            css(secondary)
            +"Font size"
        }
        div {
            css(inputContainer)
            Button {
                css(plusMinusButton)
                Icon {
                    src = "ic_minus_16x16.svg"
                }
                onClick = {
                    if (value > min) {
                        props.onAfterChange?.invoke(value - 1)
                    }
                }
            }
            TypedInputNumber {
                css(inputNumber)
                this.min = min
                this.max = max
                controls = false
                precision = 0
                this.value = value
                onChange = {
                    val size = it.toInt()
                    if (size in min..max) {
                        props.onChange?.invoke(it.toInt())
                    }
                }
                onBlur = {
                    val size = it.target.value.toInt()
                    props.onChange?.invoke(oldValue.current ?: 0)
                    props.onAfterChange?.invoke(size)
                    oldValue.current = size
                }
            }
            Button {
                css(plusMinusButton)
                Icon {
                    src = "ic_plus_16x16.svg"
                }
                onClick = {
                    if (value < max) {
                        props.onAfterChange?.invoke(value + 1)
                    }
                }
            }
        }
    }
}
