package widgets.Exercises.ui.components.ManageAccessModal

import ApiResponse
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.serialization.json.Json
import online.interactiver.common.accessgroupuser.AccessGroupUserByEmail
import online.interactiver.common.accessgroupuser.AccessGroupUserWithEmail
import online.interactiver.common.accessgroupuser.CreateRequestByEmails
import online.interactiver.common.accessgroupuser.DeleteRequest
import utils.getToken

suspend fun getAccessGroupIdByFolderId(folderId: Int): ApiResponse<Int> {
    return try {
        val response = jsonClient.get("/api/v2/auto-generation/folder/$folderId/access_group") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return ApiResponse(null, response.status.value)
        }

        ApiResponse(Json.decodeFromString(response.bodyAsText()), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}

suspend fun getAllowedUsersByAccessGroupId(accessGroupId: Int): ApiResponse<List<AccessGroupUserWithEmail>> {
    return try {
        val response = jsonClient.get("/access_group_user/$accessGroupId") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return ApiResponse(null, response.status.value)
        }

        ApiResponse(Json.decodeFromString(response.bodyAsText()), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}

suspend fun deleteAllowedUsersByAccessGroupId(accessGroupId: Int, userId: Int): ApiResponse<Boolean> {
    return try {
        val response = jsonClient.delete("/access_group_user") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(DeleteRequest(listOf(userId), accessGroupId))
        }

        return ApiResponse(response.status.value == 204, response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}

suspend fun addAllowedUserByAccessGroupId(accessGroupId: Int, users: List<AccessGroupUserByEmail>): ApiResponse<List<AccessGroupUserWithEmail>> {
    return try {
        val response = jsonClient.post("/access_group_user") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(CreateRequestByEmails(users, accessGroupId))
        }

        if (response.status.value != 200) {
            return ApiResponse(null, response.status.value)
        }

        ApiResponse(Json.decodeFromString(response.bodyAsText()), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}