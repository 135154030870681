package pages.constructor.ui.components.advancedElementsSettings.advancedStaticSettings

import Modal
import antd.Input
import app.useAppDispatch
import app.useAppSelector
import builders.enums.EElementType
import emotion.react.css
import entities.advancedElementSettings.CloseAdvancedElementSettings
import entities.advancedElementSettings.selectAdvancedElementSettingsModalState
import entities.interactivePicture.elements.SetElementCode
import entities.interactivePicture.elements.editors.elementCodeEditor.ElementCodeEditor
import entities.interactivePicture.elements.editors.elementCodeEditor.useFocusedElementCode
import entities.interactivePicture.elements.figuresAndLines.static.SetLink
import entities.interactivePicture.selectFocusedElement
import org.w3c.dom.HTMLInputElement
import pages.constructor.ui.components.advancedElementsSettings.advancedInputSettings.elementTypeHeader
import pages.constructor.ui.components.advancedElementsSettings.advancedInputSettings.header
import pages.constructor.ui.components.advancedElementsSettings.advancedSelectorSettings.container
import react.FC
import react.Props
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import shared.canvas.interfaces.isMarker

val AdvancedStaticSettings = FC<Props> {
    val modalState = useAppSelector(selectAdvancedElementSettingsModalState)
    val dispatch = useAppDispatch()
    val focusedElement = useAppSelector(selectFocusedElement)
        ?: throw RuntimeException("Rendered advanced static settings with no button focused!")

    val (elementCode, setElementCode) = useFocusedElementCode()

    Modal {
        width = 800
        open = modalState.isOpen
        onCancel = {
            setElementCode(focusedElement.identifier.code ?: "")
            dispatch(CloseAdvancedElementSettings())
        }

        onOk = {
            dispatch(SetElementCode(focusedElement.identifier.id!!, elementCode))
            dispatch(CloseAdvancedElementSettings())
        }

        okText = "Save changes"
        div {
            css(header)
            +"Advanced element settings"
        }
        div {
            css(container)
            div {
                css(elementTypeHeader)
                +"Static"
            }
            ElementCodeEditor {
                value = elementCode
                onChange = {
                    setElementCode(it)
                }
            }
            if (focusedElement.isMarker()
                || focusedElement.visibleElement.type == EElementType.TEXT_STATIC.text
                || focusedElement.visibleElement.type == EElementType.IMAGE_STATIC.text) {
                div {
                    css(settingContainer)
                    p {
                        css(settingHeader)
                        +"Link"
                    }
                    Input {
                        css(input)
                        value = focusedElement.visibleElement.link
                        onPressEnter = { evt: MouseEvent<HTMLInputElement, *> -> evt.currentTarget.blur() }
                        onInput = { dispatch(SetLink(focusedElement.identifier.id!!, it.currentTarget.value)) }
                    }
                }
            }
        }
    }
}