package widgets.Exercises.ui.components.ManageAccessModal.components

import antd.Button
import antd.Input
import antd.Spin
import app.useAppDispatch
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.accessgroupuser.AccessGroupUserWithEmail
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.useState
import shared.components.Icon
import widgets.Exercises.ui.components.ManageAccessModal.deleteAllowedUsersByAccessGroupId
import kotlin.js.Date

external interface AllowedUsersProps : Props {
    var users: List<AccessGroupUserWithEmail>?
    var accessGroupId: Int?
    var onDelete: (Int) -> Unit
    var ownerId: Int
}

val AllowedUsers = FC<AllowedUsersProps> { props ->
    val (searchedUsers, setSearchedUsers) = useState("")
    val dispatch = useAppDispatch()

    val users = props.users
    val accessGroupId = props.accessGroupId ?: return@FC

    if (users == null) {
        Spin {
            size = "large"
        }
    } else {
        div {
            css(container)
            Input {
                value = searchedUsers
                onInput = { setSearchedUsers(it.currentTarget.value) }
                placeholder = "Search"
            }
            div {
                css(grid)
                listOf("Email", "Role", "Addition time", "Delete").forEach {
                    p {
                        css(header)
                        +it
                    }
                }
                users.forEach {
                    p {
                        css(data)
                        +it.email
                    }
                    p {
                        css(data)
                        +it.role
                    }
                    val additionTime = if (it.additionTime != null) {
                        Date(it.additionTime * 1000).toLocaleString()
                    } else {
                      ""
                    }
                    p {
                        css(data)
                        +additionTime
                    }
                    if (props.ownerId == it.userId) {
                        div {}
                        return@forEach
                    }

                    Button {
                        css(deleteButton)
                        Icon {
                            src = "ic_delete_24x24.svg"
                        }
                        onClick = {
                            GlobalScope.launch {
                                val deleteRequest = deleteAllowedUsersByAccessGroupId(
                                    accessGroupId,
                                    it.userId
                                )

                                if (deleteRequest.data != true) {
                                    dispatch(OpenErrorModal("Error deleting access from user with id ${it.userId}"))
                                    return@launch
                                }

                                props.onDelete(it.userId)
                            }
                        }
                    }
                }
            }
        }
    }
}