package widgets.LanguageAutoSlider.ui.components.SliderName

import csstype.*
import utils.types.CssStyle

val sliderNameInput: CssStyle = {
    border = None.none
    color = Color("#282F3E")
    fontSize = 18.px
    lineHeight = 23.4.px
    fontWeight = FontWeight.bold
    maxWidth = 300.px
}
