package online.interactiver.common.interactivepicture

import kotlinx.serialization.Serializable
import online.interactiver.common.math.LinearTransformation

@Serializable
data class Point(
    var x: Int,
    var y: Int,
) {
    fun clone() = copy()
    fun clone(linearTransformation: LinearTransformation) = clone().apply {
        applyTransformation(linearTransformation)
    }

    fun applyTransformation(linearTransformation: LinearTransformation) {
        x = linearTransformation.applyToX(x)
        y = linearTransformation.applyToY(y)
    }
}


@Serializable
data class Anchor(
    var elementCode: String? = null, // if null - linked to global area (for example hint is placed in center)
    var placement: String, // "left", "right", "down", "up"  // later add combinations
    // functions that computes real coordinates by placement for different geometry needed
) {
    fun clone() = copy()
}

@Serializable
data class Position(
    // author choose one of options
    var absolutePosition: Point? = null, // scaled + transformed corresponding to window size
    var relativeToMousePosition: Point? = null, // if not null, X of position will be (CursorPosX + posRelativeToCursorPosX); can be used for hints //  default values
    var otherElementAnchorPoint: Anchor? = null, // only for curves

    // maybe later
    // rotation: Rotation? = null
    // elementWithCodeXRelativePos - maybe in such case absolutePosition should be computed on front? important for connections?
) {
    fun clone() = copy(
        absolutePosition = absolutePosition?.clone(),
        relativeToMousePosition = relativeToMousePosition?.clone(),
        otherElementAnchorPoint = otherElementAnchorPoint?.clone(),
    )

    fun clone(linearTransformation: LinearTransformation) = clone().apply {
        applyTransformation(linearTransformation)
    }

    fun applyTransformation(linearTransformation: LinearTransformation) {
        absolutePosition?.applyTransformation(linearTransformation)
        relativeToMousePosition?.applyTransformation(linearTransformation)
    }
}


@Serializable
data class Rect(
    val type: String = "Rect",
    var leftTopPosition: Position? = null,
    var verticalAlign: String? = null, // if provided then placed as align says, ignoring the leftTopPosition, only for hints
    var width: Int? = null,
    var height: Int? = null,
) {
    fun clone() = copy(
        leftTopPosition = leftTopPosition?.clone(),
    )

    fun clone(linearTransformation: LinearTransformation) = clone().apply {
        applyTransformation(linearTransformation)
    }

    fun applyTransformation(linearTransformation: LinearTransformation) {
        leftTopPosition?.applyTransformation(linearTransformation)
        width = linearTransformation.applyToWidth(width)
        height = linearTransformation.applyToHeight(height)
    }
}

@Serializable
data class Circle(
    val type: String = "Circle",
    var centerPosition: Position? = null,
    var radius: Int? = null,
) {
    fun clone() = copy(
        centerPosition = centerPosition?.clone(),
    )

    fun clone(linearTransformation: LinearTransformation) = clone().apply {
        applyTransformation(linearTransformation)
    }

    fun applyTransformation(linearTransformation: LinearTransformation) {
        centerPosition?.applyTransformation(linearTransformation)
        radius = linearTransformation.applyToWidth(radius) // circle form is more important than proportion
    }
}

@Serializable
data class Curve(
    val type: String = "Curve",
    var start: Position? = null, // absolute or could be joined to object point
    var middle: Position? = null, // absolute (shifting with connected objects harmful, curve can intersect other objects)
    var end: Position? = null, // absolute or could be joined to object point
    var startType: String? = null, // null - simple line; "usual", "<typeX>" - arrow with type X
    var endType: String? = null, // null - simple line; "usual", "<typeX>" - arrow with type X
) {
    fun clone() = copy(
        start = start?.clone(),
        middle = middle?.clone(),
        end = end?.clone(),
    )

    fun clone(linearTransformation: LinearTransformation) = clone().apply {
        applyTransformation(linearTransformation)
    }

    fun applyTransformation(linearTransformation: LinearTransformation) {
        start?.applyTransformation(linearTransformation)
        middle?.applyTransformation(linearTransformation)
        end?.applyTransformation(linearTransformation)
    }
}

@Serializable
data class PolyBlobLine(
    // later
    val type: String = "PolyBlobLine",
    var points: String? = null, // all coordinates are absolute
    var pathData: String? = null, // can be used instead of points // all coordinates are absolute
    // relative coordinates will be added later if needed
    var closed: String? = "1", // otherwise opened
    var tension: String? = null,
) {
    fun clone() = copy()

    fun clone(linearTransformation: LinearTransformation) = clone().apply {
        applyTransformation(linearTransformation)
    }

    fun applyTransformation(linearTransformation: LinearTransformation) {
        TODO("not implemented") // parse array of points? // TODO: implement
    }
}

@Serializable
data class Geometry(
    var rect: Rect? = null,
    var circle: Circle? = null,
    var curve: Curve? = null,
    var polyBlobLine: PolyBlobLine? = null,
) {
    fun clone() = copy(
        rect = rect?.clone(),
        circle = circle?.clone(),
        curve = curve?.clone(),
        polyBlobLine = polyBlobLine?.clone(),
    )

    fun clone(linearTransformation: LinearTransformation) = clone().apply {
        applyTransformation(linearTransformation)
    }

    fun applyTransformation(linearTransformation: LinearTransformation) {
        rect?.applyTransformation(linearTransformation)
        circle?.applyTransformation(linearTransformation)
        curve?.applyTransformation(linearTransformation)
        polyBlobLine?.applyTransformation(linearTransformation) // if polyBlobLine is not implemented, assert should be
    }

    //toKonvaFigureCode() {}
    //drawKonvaFigure() {}
}