package widgets.LanguageAutoSlider.ui.components.TitleWithScoreSetting

import antd.Input
import antd.TypedInputNumber
import csstype.px
import emotion.react.css
import online.interactiver.common.interactivepicture.SliderGreetingScreenTitleWithScoreSettings
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import shared.components.Icon
import utils.types.extend

external interface TitleWithScoreSettingProps : Props {
    var setting: SliderGreetingScreenTitleWithScoreSettings
    var onChange: (SliderGreetingScreenTitleWithScoreSettings) -> Unit
}

val TitleWithScoreSetting = FC<TitleWithScoreSettingProps> { props ->

    div {
        css(conditionsContainer)
        TypedInputNumber {
            css(input.extend {
                width = 50.px
            })
            value = props.setting.minGeneralScore
            onChange = {
                props.onChange(props.setting.copy(minGeneralScore = it.toInt()))
            }
            controls = false
        }

        Icon {
            src = "ic_less_equal_24x24.svg"
        }

        p {
            css(generalScore)
            +"General score in %"
        }

        Icon {
            src = "ic_less_equal_24x24.svg"
        }

        TypedInputNumber {
            css(input.extend {
                width = 50.px
            })
            value = props.setting.maxGeneralScore
            onChange = {
                props.onChange(props.setting.copy(maxGeneralScore = it.toInt()))
            }
            controls = false
        }
    }
    div {
        css(titlesContainer)
        Input {
            css(input)
            value = props.setting.title
            placeholder = "Enter score title..."
            onChange = { props.onChange(props.setting.copy(title = it.target.value)) }
        }
        Input {
            css(input)
            value = props.setting.subtitle
            placeholder = "Enter score subtitle..."
            onChange = { props.onChange(props.setting.copy(subtitle = it.target.value)) }
        }
    }
}
