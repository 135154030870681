package pages.constructor.ui.components.header.templates.tag

import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML

external interface TemplateTagProps : Props {
    var tagName: String
    var tagColor: String
    var tagTextColor: String
}


val TemplateTag = FC<TemplateTagProps> { props ->
    ReactHTML.div {
        css(getContainer(props.tagColor))
        ReactHTML.div {
            css(tagText(props.tagTextColor))
            +props.tagName
        }
    }
}