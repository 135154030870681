package shared.components.checkboxWithLabelAndComment

import csstype.*
import utils.types.CssStyle

val checkboxContainer: CssStyle = {
    display = Display.grid;
    columnGap = 12.px
    marginBottom = 8.px
    alignItems = AlignItems.center
    justifyContent = JustifyContent.start
    marginBottom = 8.px
}

val checkboxText: CssStyle = {
    gridColumn = integer(2)
    lineHeight = 130.pct
}
