package pages.constructor.ui.components.advancedSettings.controlButtonsColorSettings

import antd.TextArea
import emotion.react.css
import enums.EControlButtonColor
import pages.constructor.ui.components.advancedSettings.popupSettings.textInput
import pages.constructor.ui.components.advancedSettings.settingHeader
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.useEffect
import react.useState
import shared.components.radio.Radio
import kotlin.js.RegExp

external interface ControlButtonsColorSettingsProps : Props {
    var color: String
    var onChange: (String) -> Unit
    var disabled: Boolean
}

val ControlButtonsColorSettings = FC<ControlButtonsColorSettingsProps> { props ->
    val (customColor, setCustomColor) = useState("#F2F4F7")
    val (customInput, setCustomInput) = useState("")
    val (customInputDisabled, setCustomInputDisabled) = useState(true)

    useEffect(props.color) {
        if (colorInEnums(props.color)) {
            setCustomInputDisabled(true)
        } else {
            setCustomColor(props.color)
            setCustomInput(props.color)
            setCustomInputDisabled(false)
        }
    }

    ReactHTML.div {
        css(settingHeader)
        +"Button color"
    }

    ReactHTML.div {
        css(container)

        EControlButtonColor.entries.forEach {
            ControlButtonsColorSettingsElement{
                controlButtonsColorSettingsProps = props

                hex = it.hex
                label = it.label

                beforeChange = { setCustomInputDisabled(true) }
            }
        }

        ControlButtonsColorSettingsElement{
            controlButtonsColorSettingsProps = props

            hex = customColor
            label = "Custom"

            beforeChange = { setCustomInputDisabled(false) }
        }

        TextArea {
            css(textInput(30))
            onChange = cb@{ eventChangeTitleText ->
                val newColor = eventChangeTitleText.target.value
                setCustomInput(newColor)

                if (!testHex(newColor)) {
                    return@cb
                }
                setCustomColor(newColor)
                props.onChange(eventChangeTitleText.target.value)
            }
            value = customInput
            placeholder = "HEX Code"
            disabled = customInputDisabled || props.disabled
            status = if (testHex(customInput)) null else "error"
            spellCheck = false
        }
    }

}

external interface ControlButtonsColorSettingsElementProps : Props {
    var controlButtonsColorSettingsProps: ControlButtonsColorSettingsProps

    var hex: String
    var label: String

    var beforeChange: () -> Unit
}

val ControlButtonsColorSettingsElement = FC<ControlButtonsColorSettingsElementProps> { props ->
    ReactHTML.div {
        css(radioLabelSquare)
        ReactHTML.div {
            css(radioLabel)
            Radio {
                current = props.controlButtonsColorSettingsProps.color
                value = props.hex
                onChange = { value ->
                    props.beforeChange()
                    props.controlButtonsColorSettingsProps.onChange(value)
                }
                disabled = props.controlButtonsColorSettingsProps.disabled
            }
            ReactHTML.div {
                css(text)
                +props.label
            }
        }

        ReactHTML.div {
            css(colorSquare(props.hex))
        }
    }
}

private fun colorInEnums(color: String) = EControlButtonColor.entries.map { entry -> entry.hex }.contains(color)
fun testHex(color: String): Boolean {
    // pattern: /^#([0-9A-F]{3}){1,2}\$/i
    return RegExp("^#([0-9a-f]{3}){1,2}$", "i").test(color)
}