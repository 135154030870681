package utils

import Text
import entities.interactivePicture.elements.figuresAndLines.static.PHRASE_ELEMENT_HEIGHT
import entities.interactivePicture.elements.figuresAndLines.static.rectWithTextTextStyleOfPhrase
import online.interactiver.common.interactivepicture.Phrase
import online.interactiver.common.interactivepicture.PhraseStyle
import online.interactiver.common.interactivepicture.TextStyle
import shared.canvas.implementations.PHRASE_LINE_WIDTH
import shared.canvas.implementations.PHRASE_LINE_ELEMENT_GAP
import shared.canvas.implementations.PHRASE_LINE_SPACING_FOR_UNPLACED_PUZZLES

// Measures width and height of text rendered with Konva with style applied
fun String.measureAsKonvaText(style: TextStyle): Pair<Int, Int> {
    val safeWidthFixer = 10 // this additional width make us sure the text is measured with enough width
    val konvaText = Text().apply {
        text(this@measureAsKonvaText)
        style.fontFamily?.let { fontFamily(it) }
        style.fontSize?.let { fontSize(it) }
        style.fontStyle?.let { fontStyle(it) }
        style.align?.let { align(it) }
        style.verticalAlign?.let { verticalAlign(it) }
        style.padding?.let { padding(it) }
        style.textColor?.let { fill(it) }
    }
    return Pair(konvaText.width().toInt() + safeWidthFixer, konvaText.height().toInt())
}

fun String.measureHeightAsPhrase(style: PhraseStyle, width: Int, drawLines: Boolean = false): Int {
    var curWidth = 0; var curY = 0
    val textStyle = rectWithTextTextStyleOfPhrase(style.elementPadding, style.fontSize)
    val words = this.trim().split(" ").filter{ it.isNotBlank() }
    words.forEach { word ->
        val (wordWidth, _) = word.measureAsKonvaText(textStyle)
        // If we exceeded width we should go to the next line
        if (curWidth + wordWidth > width && curWidth > 0) {
            // curWidth > 0 guarantees than we won't have any empty lines
            curY += PHRASE_ELEMENT_HEIGHT + style.lineSpacing; curWidth = 0
            if (drawLines) {
                curY += PHRASE_LINE_ELEMENT_GAP
            }
        }
        curWidth += wordWidth + style.elementSpacing
    }
    var res = curY + PHRASE_ELEMENT_HEIGHT
    if (drawLines) {
        res += PHRASE_LINE_ELEMENT_GAP
    }
    return res
}

fun Phrase.measureHeight() = getPhraseText().measureHeightAsPhrase(style, width, true)
fun Phrase.measurePuzzlesBlockHeight() = getPhraseText().measureHeightAsPhrase(
    style.copy(lineSpacing = PHRASE_LINE_SPACING_FOR_UNPLACED_PUZZLES), width, false)
