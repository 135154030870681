package online.interactiver.common.autogeneration.taskLineContent

class WordMatchingContent(var wordMatchPairs: List<WordMatch>) : TaskLineContent {
    // TODO: here you can also use more complicated data type if you need, i.e use ExerciseVocabularyToLearn
    // TODO: if it fits your needs when realizing getVocabularyToLearn()
    data class WordMatch(
        val word: String,
        val match: String,
        var soundSrc: String? = null
    )

    override fun toRawTaskContent(): String = wordMatchPairs
        .joinToString("; ") { "${it.word} - ${it.match}" }

    override fun getVocabularyToLearn(): List<String> = wordMatchPairs.map { it.word }
}
