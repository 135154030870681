package online.interactiver.common.accessgroupuser

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import online.interactiver.common.accessgroupuser.AccessGroupUserByEmail

@Serializable
data class CreateRequestByEmails(
    val users: List<AccessGroupUserByEmail>,
    @SerialName("access_group_id") val accessGroupId: Int
)
