package widgets.CourseMaterials.ui.components.WordsMaterials

import app.useAppDispatch
import enums.EMaterialType
import online.interactiver.common.course.getCourseMaterials
import react.FC
import react.Props
import react.redux.useSelector
import widgets.CourseMaterials.SetCourseSentencesToGenerateExercises
import widgets.CourseMaterials.SetCourseWordsToGenerateExercises
import widgets.CourseMaterials.selectWordsToGenerateExercises
import widgets.CourseMaterials.ui.components.Materials.Materials

external interface WordsMaterialsProps : Props {
    var withAudio: Boolean
}

val WordsMaterials = FC<WordsMaterialsProps> { props ->
    val rawSentencesToGenerateExercises = useSelector(selectWordsToGenerateExercises)

    val dispatch = useAppDispatch()

    val sentencesToGenerateExercises = rawSentencesToGenerateExercises.getCourseMaterials()

    Materials {
        materials = sentencesToGenerateExercises
        onChange = {
            dispatch(SetCourseWordsToGenerateExercises(it))
        }
        header = if (props.withAudio) "Audios words review" else "Words review"
        materialType = EMaterialType.WORDS
        withAudio = props.withAudio
    }
}
