package utils

import kotlinx.browser.document
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.HTMLCanvasElement

fun getStringWidth(text: String, fontSize: Int, font: String): Int {
    val canvas = document.createElement("canvas") as HTMLCanvasElement
    val context = canvas.getContext("2d") as CanvasRenderingContext2D

    if (context == null) {
        return 0;
    }

    context.font = fontSize.toString() + "px " + font;
    return context.measureText(text).width.toInt()
}

fun parseNumber(input: String): Int? {
    val trimmedInput = input.trim()

    return when {
        trimmedInput.startsWith("+") -> trimmedInput.substring(1).toIntOrNull()
        trimmedInput.startsWith("-") -> {
            val number = trimmedInput.substringAfter("-").toIntOrNull()
            if (number != null) -number else null
        }
        else -> trimmedInput.toIntOrNull()
    }
}

fun extractUUIDFromUrl(url: String): String? {
    val regex = Regex("""/editor/([a-fA-F0-9-]+)""")
    val matchResult = regex.find(url)
    return matchResult?.groupValues?.getOrNull(1)
}

fun extractGroupIdFromUrl(url: String): String {
    val param = "/editor?interactive_group_id="
    return if (url.contains(param)) {
        url.substringAfter(param)
    } else {
        ""
    }

}