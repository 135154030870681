package shared.components.checkboxWithLabel

import csstype.AlignItems
import csstype.Display
import csstype.pct
import csstype.px
import utils.types.CssStyle

val checkboxContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 12.px
    marginBottom = 8.px
}

val checkboxText: CssStyle = {
    lineHeight = 130.pct
}
