package entities.interactivePicture.elements.controls

import entities.interactivePicture.elements.CommonInteractiveElementAction
import entities.interactivePicture.elements.CommonListReducer
import entities.interactivePicture.elements.InteractiveElementAction
import entities.interactivePicture.elements.controls.inputs.InputReducer
import entities.interactivePicture.elements.controls.inputs.InputsAction
import entities.interactivePicture.elements.gapPuzzles.drags.ButtonsAction
import entities.interactivePicture.elements.gapPuzzles.drags.ButtonsReducer
import entities.interactivePicture.elements.gapPuzzles.drags.SelectorReducer
import entities.interactivePicture.elements.gapPuzzles.drags.SelectorsAction
import online.interactiver.common.interactivepicture.InteractiveElement

val ControlsReducer = { state: MutableList<InteractiveElement>, action: InteractiveElementAction ->
    when (action) {
        is CommonInteractiveElementAction -> CommonListReducer(state, action)
        is InputsAction -> InputReducer(state, action)
        is ButtonsAction -> ButtonsReducer(state, action)
        is SelectorsAction -> SelectorReducer(state, action)
        else -> state
    }
}
