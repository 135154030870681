package entities.interactivePicture.elements.gapPuzzles.drags

import app.StoreState
import builders.enums.EElementType
import builders.enums.EInteractiveType
import builders.getDragBuilder
import entities.interactivePicture.elements.InteractiveElementAction
import entities.interactivePicture.elements.addElementToList
import entities.interactivePicture.elements.setValueForElements
import entities.interactivePicture.selectGapPuzzles
import online.interactiver.common.interactivepicture.DEFAULT_SCORE
import online.interactiver.common.interactivepicture.InteractiveElement
import online.interactiver.common.interactivepicture.ScoreData
import online.interactiver.common.interactivepicture.TextFrame

open class DragsAction : InteractiveElementAction(false)


data class AddTextDrag(val id: String, val position: utils.structures.Position?) : DragsAction()
data class AddBackgroundDrag(val id: String, val position: utils.structures.Position?) : DragsAction()
data class SetDragPlaceholder(val id: String, val placeholder: String) : DragsAction()
data class SetDragPlaceholderForElements(val ids: Array<String>, val placeholder: String) : DragsAction()

data class AddImageDrag(
        val id: String,
        val position: utils.structures.Position?,
        val base64: String,
        val fileName: String,
        val ratio: Double
) : DragsAction()

val setPlaceholder = f@{ list: MutableList<InteractiveElement>, id: String,
                                              placeholder: String ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }

    if (elementIndex == -1) return@f newList

    newList[elementIndex] = newList[elementIndex].copy(
        gapPuzzle = newList[elementIndex].gapPuzzle?.copy(
            gap = newList[elementIndex].gapPuzzle?.gap?.copy(
                text = newList[elementIndex].gapPuzzle?.gap?.text?.copy(
                    simpleText = placeholder
                ) ?: TextFrame(
                    simpleText = placeholder
                )
            )
        )
    )


    newList
}

val setPlaceholderForDrags = { list: MutableList<InteractiveElement>, ids: Array<String>, placeholder: String ->
    setValueForElements(list, ids) { newList, id ->
        setPlaceholder(newList, id, placeholder)
    }
}

val DragsReducer = { state: MutableList<InteractiveElement>, action: DragsAction ->
    when (action) {

        is AddTextDrag -> {

            val builder = getDragBuilder()
                    .setType(EElementType.TEXT_DRAG)
                    .setHoverFocusStyling(puzzleTextStyle)
                    .setHintText("")
                    .setTextStyle(puzzleTextTextStyle)
                    .addScore(ScoreData(DEFAULT_SCORE, null))
                    .setGap(action.position).setID(action.id).setIsSynced(false)

            addElementToList(state, builder.build())
        }

        is AddBackgroundDrag -> {
            val builder = getDragBuilder()
                    .setType(EElementType.BACKGROUND_DRAG)
                    .setHoverFocusStyling(puzzleAreaFromBgStyle)
                    .addScore(ScoreData(DEFAULT_SCORE, null))
                    .setGap(action.position).setID(action.id)

            addElementToList(state, builder.build())
        }

        is SetDragPlaceholder -> setPlaceholder(state, action.id, action.placeholder)

        is SetDragPlaceholderForElements -> setPlaceholderForDrags(state, action.ids, action.placeholder)

        is AddImageDrag -> {
            val builder = getDragBuilder()
                    .setType(EElementType.IMAGE_DRAG)
                    .setColor("#1677ff")
                    .setPicture(action.base64, action.fileName)
                    .setHoverFocusStyling(puzzleImageStyle)
                    .setGap(action.position)
                    .setRatio(action.ratio)
                    .addScore(ScoreData(DEFAULT_SCORE, null))
                    .setID(action.id)

            addElementToList(state, builder.build())
        }

        else -> state
    }
}

val selectDrags = { state: StoreState ->
    selectGapPuzzles(state)?.filter { it.type == EInteractiveType.GAP_PUZZLE_INTERACTIVE.text }?.toMutableList()
}