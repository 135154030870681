package entities.interactivePicture.viewer

import app.appState.selectAppState
import app.useAppDispatch
import app.useAppSelector
import builders.enums.EElementGroup
import builders.enums.EElementType
import builders.enums.EInteractiveType
import emotion.css.ClassName
import emotion.react.css
import entities.interactivePicture.filterArray
import entities.interactivePicture.selectFocusedElement
import entities.interactivePicture.viewer.filter.ViewerFilter
import entities.selectedElement.selectElementsIdsUnderSelectionRectangle
import react.FC
import react.MutableRefObject
import react.Props
import react.dom.html.ReactHTML.div
import shared.canvas.utils.selectElements
import shared.viewers.ElementViewer

external interface ElementViewerProps : Props {
    var group: EElementGroup
    var stageRef: MutableRefObject<dynamic>
}

val ElementViewerPanel = FC<ElementViewerProps> { props ->
    val filter = useAppSelector(viewerFilter)
    val elements = filterArray(useAppSelector(props.group.selector), filter)
    val selected = useAppSelector(selectFocusedElement)
    val elementsIdsUnderSelectionRectangle = useAppSelector(selectElementsIdsUnderSelectionRectangle)
    val appState = useAppSelector(selectAppState)

    val dispatch = useAppDispatch()

    val filterTypes = EInteractiveType.values().filter { it.group == props.group }

    div {
        css(container)
        if (filterTypes.size > 1) {
            ViewerFilter {
                this.filter = filter
                this.group = props.group
            }
        }
        div {
            className = ClassName(props.group.containerCSS)
            elements.map { element ->
                val isFocused = element.identifier.id!! == selected?.identifier?.id
                val isUnderSelectionRectangle = elementsIdsUnderSelectionRectangle.contains(element.identifier.id!!)
                ElementViewer {
                    this.onSelect = f@{ isShiftPressed: Boolean ->
                        selectElements(
                            isShiftPressed,
                            element,
                            selected,
                            elementsIdsUnderSelectionRectangle,
                            isFocused,
                            isUnderSelectionRectangle,
                            dispatch,
                            appState
                        )
                    }
                    this.group = props.group
                    this.data = element
                    this.isSelected = isFocused
                    this.isUnderSelectionRectangle = isUnderSelectionRectangle
                    this.stageRef = props.stageRef
                    this.drawable =
                        EElementType.values().find { type -> type.text.equals(element.visibleElement.type) }!!.drawable
                }
            }
        }
    }
}
