package builders.enums

import entities.interactivePicture.elements.frames.frameAreaStyle
import entities.interactivePicture.elements.frames.frameRectStyle
import online.interactiver.common.interactivepicture.GeometryStyle

enum class EFrameType(val style: GeometryStyle) {
    AREA(frameAreaStyle),
    RECT(frameRectStyle);

    fun toEElementType(): EElementType {
        return when (this) {
            AREA -> EElementType.AREA_FRAME
            RECT -> EElementType.RECT_FRAME
        }
    }
}
