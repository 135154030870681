package widgets.CourseSettings

import online.interactiver.common.course.CourseState
import online.interactiver.common.course.getCourseMaterials
import online.interactiver.common.course.getRawMaterialsWithId
import online.interactiver.common.enums.ECourseLevel
import pages.course.CourseAction
import pages.course.selectCourseState
import pages.languageAuto.LanguageAutoStoreState

open class CourseSettingsAction : CourseAction()

data class SetCourseLanguageToLearn(val languageToLearn: String) : CourseSettingsAction()
data class SetCourseInterfaceLanguage(val interfaceLanguage: String) : CourseSettingsAction()
data class SetCourseLanguageLevel(val languageLevel: String) : CourseSettingsAction()
data class SetCourseNumberOfSlidesInSlider(val numberOfSlidesInSlider: Int) : CourseSettingsAction()
data class SetCourseContinueButtonLink(val continueButtonLink: String) : CourseSettingsAction()
data class SetCourseContinueButtonText(val continueButtonText: String) : CourseSettingsAction()
data class SetCourseEnableGamifiedPopups(val enableGamifiedPopups: Boolean) : CourseSettingsAction()
data class SetCourseStartFromSentences(val startFromSentences: Boolean) : CourseSettingsAction()
data class SetCourseLevel(val courseLevel: String) : CourseSettingsAction()
data class SetCourseName(val courseName: String) : CourseSettingsAction()
data class SetCourseNumberOfSliders(val numberOfSliders: Int) : CourseSettingsAction()

val setCourseLevel = { state: CourseState, courseLevel: String ->
    val newState = state.copy(courseLevel = courseLevel)
    ECourseLevel.fromString(courseLevel)?.timesToAppearInCourse?.let { timesToAppearInCourse ->
        val sentences = state.sentencesToGenerateExercises.getCourseMaterials().map {
            it.copy(numberOfAppearances = timesToAppearInCourse)
        }
        newState.sentencesToGenerateExercises = sentences.getRawMaterialsWithId()
        val words = state.wordsToGenerateExercises.getCourseMaterials().map {
            it.copy(numberOfAppearances = timesToAppearInCourse)
        }
        newState.wordsToGenerateExercises = words.getRawMaterialsWithId()
    }
    newState
}

fun courseSettingsReducer(state: CourseState, action: CourseSettingsAction): CourseState {
    return when (action) {
        is SetCourseLanguageToLearn -> state.copy(languageToLearn = action.languageToLearn)
        is SetCourseInterfaceLanguage -> state.copy(interfaceLanguage = action.interfaceLanguage)
        is SetCourseLanguageLevel -> state.copy(languageLevel = action.languageLevel)
        is SetCourseNumberOfSlidesInSlider -> state.copy(numberOfSlidesInSlider = action.numberOfSlidesInSlider)
        is SetCourseContinueButtonLink -> state.copy(continueButtonLink = action.continueButtonLink)
        is SetCourseContinueButtonText -> state.copy(continueButtonText = action.continueButtonText)
        is SetCourseEnableGamifiedPopups -> state.copy(enableGamifiedPopups = action.enableGamifiedPopups)
        is SetCourseStartFromSentences -> state.copy(startFromSentences = action.startFromSentences)
        is SetCourseLevel -> setCourseLevel(state, action.courseLevel)
        is SetCourseName -> state.copy(name = action.courseName)
        is SetCourseNumberOfSliders -> state.copy(minNumberOfSliders = action.numberOfSliders)
        else -> state
    }
}

val selectCourseNumberOfSlidesInSlider = { state: LanguageAutoStoreState ->
    selectCourseState(state).numberOfSlidesInSlider
}

val selectCourseNumberOfSliders = { state: LanguageAutoStoreState ->
    selectCourseState(state).minNumberOfSliders
}

val selectCourseContinueButtonText = { state: LanguageAutoStoreState ->
    selectCourseState(state).continueButtonText
}

val selectCourseContinueButtonLink = { state: LanguageAutoStoreState ->
    selectCourseState(state).continueButtonLink
}

val selectCourseEnableGamifiedPopup = { state: LanguageAutoStoreState ->
    selectCourseState(state).enableGamifiedPopups
}

val selectCourseStartFromSentences = { state: LanguageAutoStoreState ->
    selectCourseState(state).startFromSentences
}

val selectCourseLevel = { state: LanguageAutoStoreState ->
    selectCourseState(state).courseLevel
}

val selectCourseLanguageToLearn = { state: LanguageAutoStoreState ->
    selectCourseState(state).languageToLearn
}

val selectCourseInterfaceLanguage = { state: LanguageAutoStoreState ->
    selectCourseState(state).interfaceLanguage
}

val selectCourseLanguageLevel = { state: LanguageAutoStoreState ->
    selectCourseState(state).languageLevel
}

val selectCourseName = { state: LanguageAutoStoreState ->
    selectCourseState(state).name
}
