package features.UndoAlert.ui

import csstype.*
import emotion.css.keyframes
import utils.types.CssStyle

val fadeOut = keyframes {
    from {
        opacity = number(1.0)
    }
    to {
        opacity = number(0.3)
    }
}

val undoAlert: CssStyle = {
    backgroundColor = Color("#5d6676")
    color = Color("white")
    animationName = fadeOut
    animationDuration = "10s".unsafeCast<AnimationDuration>()
    animationFillMode = AnimationFillMode.forwards
    hover {
        opacity = number(1.0)
    }
}

val button: CssStyle = {
    color = Color("rgb(89, 126, 247)")
    backgroundColor = "inherit".unsafeCast<BackgroundColor>()
    fontWeight = FontWeight.bold
    boxShadow = None.none
    border = None.none
    hover {
        opacity = number(1.0)
    }
}
