package widgets.LanguageAutoContent.ui.components.Content

import antd.TextArea
import app.useAppDispatch
import csstype.integer
import csstype.px
import emotion.react.css
import entities.errorModal.store.OpenTooLongTextErrorModal
import kotlinx.browser.document
import online.interactiver.common.utils.getTimeMillisInBase
import org.w3c.dom.HTMLTextAreaElement
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import shared.components.header.Header

const val maxContentText = 1000

external interface TextAreaWithLengthRestrictionProps : Props {
    var label: String
    var value: String
    var placeholder: String
    var onChange: (String) -> Unit

    var tooltip: dynamic
    var tooltipOverlayStyle: dynamic
    var maxContentText: Int?
}

val TextAreaWithLengthRestriction = FC<TextAreaWithLengthRestrictionProps> { props ->
    val dispatch = useAppDispatch()

    val id = "textarea" + getTimeMillisInBase()

    val maxTextLength = props.maxContentText ?: maxContentText

    useEffectOnce {
        val textArea = document.getElementById(id) as HTMLTextAreaElement? ?: return@useEffectOnce

        textArea.style.height = "auto"
        textArea.style.height = "${textArea.scrollHeight + 4}px"
    }

    div {
        css(contentInputContainer)
        div {
            css(headerContainer)
            Header {
                text = props.label
                fontSize = 14.0.px
                lineHeight = 18.9.px
                fontWeight = integer(600)

                tooltip = props.tooltip
                tooltipOverlayStyle = props.tooltipOverlayStyle
            }
            p {
                css(textLengthCss)
                style
                +"${props.value.length}/$maxTextLength"
            }
        }
        TextArea {
            css(textarea)
            this.id = id
            value = props.value
            placeholder = props.placeholder
            onChange = f@{
                if (it.target.value.length > maxTextLength) {
                    dispatch(OpenTooLongTextErrorModal())
                    return@f
                }
                it.target.style.height = "auto"
                it.target.style.height = "${it.target.scrollHeight + 4}px"
                props.onChange(it.target.value)
            }
        }
    }
}