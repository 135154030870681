package pages.languageAuto

import entities.errorModal.store.ErrorModalAction
import entities.errorModal.store.ErrorModalReducer
import entities.errorModal.store.ErrorModalState
import entities.languageAutoCurrentRequest.LanguageAutoCurrentRequestAction
import entities.languageAutoCurrentRequest.LanguageAutoCurrentRequestReducer
import entities.modalLoader.ModalLoaderAction
import entities.modalLoader.ModalLoaderReducer
import entities.modalLoader.ModalLoaderState
import online.interactiver.common.autogeneration.LanguageAutoGeneration
import online.interactiver.common.autogeneration.LanguageAutoState
import online.interactiver.common.course.CourseState
import online.interactiver.common.user.UserProfile
import pages.course.CourseAction
import pages.course.courseReducer
import redux.RAction
import redux.createStore
import redux.rEnhancer
import widgets.UserProfile.UserProfileAction
import widgets.UserProfile.UserProfileReducer
import kotlin.js.Date

data class LanguageAutoStoreState(
    val allRequests: MutableList<LanguageAutoGeneration>,
    val modalLoader: ModalLoaderState,
    val errorLoader: ErrorModalState,
    val currentRequest: LanguageAutoState,
    val hiddenIframeIsShown: Boolean = false,
    val shouldRegenerate: Boolean = false,
    val userProfile: UserProfile? = null,
    val course: CourseState = CourseState(name = "Course ${Date().toLocaleString()}")
)

data class SetShouldRegenerate(val shouldRegenerate: Boolean): RAction
data class SetTariffPlan(val tariffPlan: String) : RAction

data class SetTaskIsApproved(val taskIsApproved: Boolean) : RAction

data class AddLanguageAutoRequest(val requestId: Int, val time: String, val name: String) : RAction

data class UpdateCurrentLanguageAutoRequest(val request: LanguageAutoState) : RAction

data class SetLanguageAutoRequests(val requests: MutableList<LanguageAutoGeneration>) : RAction

data class DeleteLanguageAutoRequest(val requestId: Int) : RAction

data class SetHiddenIframeIsShown(val hiddenIframeIsShown: Boolean): RAction

val selectErrorModal = { state: LanguageAutoStoreState ->
    state.errorLoader
}

val selectLanguageAutoLoader = { state: LanguageAutoStoreState ->
    state.modalLoader
}

val selectLanguageAutoSliders = { state: LanguageAutoStoreState ->
    state.allRequests
}

val selectCurrentRequest = { state: LanguageAutoStoreState ->
    state.currentRequest
}

val selectHiddenIframeIsShown = { state: LanguageAutoStoreState ->
    state.hiddenIframeIsShown
}

val selectShouldRegenerate = { state: LanguageAutoStoreState ->
    state.shouldRegenerate
}

fun languageAutoStateReducer(languageAutoStoreState: LanguageAutoStoreState, action: RAction): LanguageAutoStoreState {
    return when (action) {
        is UserProfileAction -> languageAutoStoreState.copy(userProfile = UserProfileReducer(languageAutoStoreState.userProfile, action))

        is LanguageAutoCurrentRequestAction -> languageAutoStoreState.copy(
            currentRequest = LanguageAutoCurrentRequestReducer(
                languageAutoStoreState.currentRequest.copy(), action
            )
        )
        is SetShouldRegenerate -> languageAutoStoreState.copy(
            shouldRegenerate = action.shouldRegenerate
        )
        is SetHiddenIframeIsShown -> languageAutoStoreState.copy(hiddenIframeIsShown = action.hiddenIframeIsShown)
        is SetLanguageAutoRequests -> languageAutoStoreState.copy(
            allRequests = action.requests
        )
        is ModalLoaderAction -> languageAutoStoreState.copy(
            modalLoader = ModalLoaderReducer(languageAutoStoreState.modalLoader, action)
        )
        is ErrorModalAction -> languageAutoStoreState.copy(
            errorLoader = ErrorModalReducer(languageAutoStoreState.errorLoader, action)
        )
        is UpdateCurrentLanguageAutoRequest -> languageAutoStoreState.copy(
            currentRequest = action.request
        )
        is DeleteLanguageAutoRequest -> languageAutoStoreState.copy(
            allRequests = languageAutoStoreState.allRequests.filter { it.requestId != action.requestId }.toMutableList()
        )

        is CourseAction -> languageAutoStoreState.copy(
            course = courseReducer(languageAutoStoreState.course, action)
        )

        else -> languageAutoStoreState
    }
}

val languageAutoStore = createStore(
    reducer = ::languageAutoStateReducer,
    preloadedState = LanguageAutoStoreState(
        modalLoader = ModalLoaderState(false, ""),
        allRequests = mutableListOf(),
        errorLoader = ErrorModalState(false),
        currentRequest = LanguageAutoState()
    ),
    enhancer = rEnhancer()
)
