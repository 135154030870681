package pages.constructor.ui.components.header.templates.gallery

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    height = 100.vh.minus(110.px)
    gap = 16.px
}

val groupContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 6.px

    height = 100.pct

    overflowY = Auto.auto

    "::-webkit-scrollbar" {
        width = 4.px;
        height = 4.px;
    }

    /* Track (background) of the scrollbar */
    "::-webkit-scrollbar-track" {
        background = Color("#FFFFFF")
    }

    /* Handle (thumb) of the scrollbar */
    "::-webkit-scrollbar-thumb" {
        background = Color("#C1C1C1")
        borderRadius = 2.px;
    }

    /* On hover, style the thumb */
    "::-webkit-scrollbar-thumb:hover" {
        background = Color("#F0F0F0")
    }
}

val horizontalContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.flexStart

    paddingLeft = 10.px
    paddingRight = 10.px

    height = 240.px
    minHeight = 240.px
    paddingTop = 5.px
    paddingBottom = 5.px

    gap = 24.px
    overflowY = Overflow.hidden
    overflowX = Auto.auto

    "::-webkit-scrollbar" {
        width = 4.px;
        height = 8.px;
    }
    "::-webkit-scrollbar-track" {
        background = Color("#FFFFFF")
    }
    "::-webkit-scrollbar-thumb" {
        background = Color("#C1C1C1")
        borderRadius = 2.px;
    }
    "::-webkit-scrollbar-thumb:hover" {
        background = Color("#F0F0F0")
    }

    marginBottom = 16.px
}

val searchIcon: CssStyle = {
    width = 20.px
    height = 20.px
}

val searchHolder: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row

    borderRadius = 8.px

    justifyContent = JustifyContent.center
    alignItems = AlignItems.center

    height = 36.px
    width = 422.px


    marginTop = (16).px;

    marginLeft = 35.px

    paddingLeft = 24.px
    paddingRight = 24.px
    background = Color("#F2F4F7")

    ":hover" {
        background = Color("#F2F4F7")
    }

    ":focus" {
        background = Color("#F2F4F7")
    }
}

val search: CssStyle = {
    height = 100.pct
    width = 100.pct

    borderRadius = 8.px

    background = Color("#F2F4F7")
    ":hover" {
        background = Color("#F2F4F7")
    }
    ":focus" {
        background = Color("#F2F4F7")
    }
}

val topLine: CssStyle = {
    height = 70.px
    width = 100.pct.plus(50.px)
    marginLeft = (-25).px;
    marginTop = (-25).px;

    marginBottom = 16.px

    boxShadow = BoxShadow(0.px, 2.px, 5.px, Color("#03030310"))
}

val header: CssStyle = {
    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)

    marginLeft = 10.px

    fontSize = 16.px
    lineHeight = 18.px
    color = Color("#282F3E")
}
