package pages.duoSettings.duoProfile

import antd.Button
import antd.Input
import app.useAppDispatch
import csstype.Auto
import csstype.Margin
import csstype.px
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import kotlinx.browser.localStorage
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.js.jso
import online.interactiver.common.enums.ELanguage
import online.interactiver.common.user.UpdateUserRequest
import org.w3c.dom.HTMLInputElement
import pages.languageAuto.ui.ERoute
import pages.onboarding.emailAndSocials.ui.geSocialsPlaceholder
import pages.onboarding.emailAndSocials.ui.getEmailLabel
import pages.onboarding.emailAndSocials.ui.getEmailPlaceholder
import pages.onboarding.emailAndSocials.ui.getSocialsLabel
import pages.onboarding.languageLevelChoice.label
import pages.onboarding.languageLevelChoice.verticalContainer
import pages.studentDuo.selectRequiredStudentDuoNativeLanguage
import pages.studentDuo.selectStudentDuoUserProfile
import react.FC
import react.VFC
import react.dom.events.KeyboardEvent
import react.dom.html.InputType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.redux.useSelector
import react.router.useLocation
import react.router.useNavigate
import react.useEffect
import react.useState
import shared.components.ProtectedRoute.ProtectedRoute
import utils.deleteCookieByName
import utils.localization.Localization
import utils.types.extend
import utils.types.jsObject
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton
import widgets.StudentDuoLayout.StudentDuoLayout
import widgets.UserProfile.SetAdditionalEmail
import widgets.UserProfile.SetSocials
import widgets.UserProfile.updateUserRequest

val DuoProfile = VFC {
    val nativeLanguage = useSelector(selectRequiredStudentDuoNativeLanguage)
    val location = useLocation()
    val userProfile = useSelector(selectStudentDuoUserProfile)
    val navigate = useNavigate()
    val dispatch = useAppDispatch()
    val (additionalEmail, setAdditionalEmail) = useState(userProfile?.additionalEmail ?: "")
    val (socials, setSocials) = useState(userProfile?.socials ?: "")

    useEffect(userProfile?.additionalEmail) {
        userProfile?.additionalEmail?.let {
            setAdditionalEmail(it)
        }
    }

    useEffect(userProfile?.socials) {
        userProfile?.socials?.let {
            setSocials(it)
        }
    }

    ProtectedRoute {
        toStudentDuo = true
        if (userProfile == null) {
            return@ProtectedRoute
        }

        StudentDuoLayout {
            buttons = FC {
                Button {
                    css(newTaskButton.extend {
                        margin = Margin(0.px, Auto.auto)
                    })
                    onClick = {
                        navigate(-1)
                    }
                    +Localization.getBackButtonText(nativeLanguage)
                }
            }
            div {
                css(container)
                div {
                    css(verticalContainer)
                    p {
                        css(label)
                        +getEmailLabel(nativeLanguage)
                    }
                    Input {
                        value = additionalEmail
                        onInput = {
                            setAdditionalEmail(it.currentTarget.value)
                        }
                        type = InputType.email
                        placeholder = getEmailPlaceholder(nativeLanguage)
                        onBlur = {
                            val oldEmail = userProfile.additionalEmail
                            dispatch(SetAdditionalEmail(additionalEmail))
                            GlobalScope.launch {
                                val saved = updateUserRequest(UpdateUserRequest(additionalEmail = additionalEmail))
                                if (saved) return@launch

                                dispatch(OpenErrorModal("Error updating additional email"))
                                dispatch(SetAdditionalEmail(oldEmail))
                            }
                        }
                        onPressEnter = { e: KeyboardEvent<HTMLInputElement> ->
                            e.currentTarget.blur()
                        }
                    }
                }
                div {
                    css(verticalContainer)
                    p {
                        css(label)
                        +getSocialsLabel(nativeLanguage)
                    }
                    Input {
                        value = socials
                        onInput = {
                            setSocials(it.currentTarget.value)
                        }
                        placeholder = geSocialsPlaceholder(nativeLanguage)
                        onBlur = {
                            val oldSocials = userProfile.socials
                            dispatch(SetSocials(socials))
                            GlobalScope.launch {
                                val saved = updateUserRequest(UpdateUserRequest(socials = socials))
                                if (saved) return@launch

                                dispatch(OpenErrorModal("Error updating socials"))
                                dispatch(SetSocials(oldSocials))
                            }
                        }
                        onPressEnter = { e: KeyboardEvent<HTMLInputElement> ->
                            e.currentTarget.blur()
                        }
                    }
                }
                Button {
                    css(newTaskButton)
                    onClick = {
                        deleteCookieByName("access_token")
                        localStorage.removeItem("access_token")
                        navigate(to = "${ERoute.GOOGLE_AUTH.path}?duo=${true}")
                    }
                    +getLogout(nativeLanguage)
                }
            }
        }
    }
}

fun getLogout(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Выйти"
    } else {
        "Log out"
    }
}