package widgets.LanguageAutoTask

import AdvancedResponse
import ApiResponse
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.serialization.json.Json
import online.interactiver.common.autogeneration.GenerateLanguageSliderResponse
import online.interactiver.common.autogeneration.GenerateLanguageTaskAndSliderResponse
import utils.getToken

suspend fun generateSliderRequest(task: String, id: Int, finalStage: Boolean, shouldSave: Boolean = true): GenerateLanguageSliderResponse? {
    val response = try {
        val response = jsonClient.post("/api/v2/auto-generation/language-slider/generate-slider") {
            contentType(ContentType.Application.Json)
            bearerAuth(getToken())
            setBody(FormDataContent(Parameters.build {
                append("task", task)
                append("id", id.toString())
                if (finalStage) {
                    append("final_stage", "1")
                }
                if (shouldSave) {
                    append("should_save", "1")
                }
            }))
        }

        AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }

    if (response.code != 200) {
        return null
    }

    return Json.decodeFromString(response.content)
}

suspend fun generateMoreSlides(task: String, id: Int): Pair<GenerateLanguageTaskAndSliderResponse?, String?> {
    val response = try {
        val response = jsonClient.post("/api/v2/auto-generation/language-slider/generate-more-slides") {
            contentType(ContentType.Application.Json)
            bearerAuth(getToken())
            setBody(FormDataContent(Parameters.build {
                append("task", task)
                append("id", id.toString())
            }))
        }

        AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }

    if (response.code != 200) {
        return Pair(null, response.content)
    }

    return Pair(Json.decodeFromString(response.content), null)
}


suspend fun getTaskRequest(requestId: Int): ApiResponse<String> {
    return try {
        val response = jsonClient.get("/api/v2/auto-generation/language-slider/requests/$requestId/task") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return ApiResponse(null, response.status.value)
        }

        ApiResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}
