package features.PromoCodePopup

import antd.Button
import emotion.react.css
import online.interactiver.common.enums.ELanguage
import pages.constructor.ui.components.elements.whiteFilter
import pages.languageAuto.ui.ERoute
import pages.payment.ui.Payment.EPaymentPerks
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.section
import react.router.useNavigate
import react.useState
import shared.components.Icon
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton

external interface PromoCodePopupProps : Props {
    var open: Boolean
    var fromLanguageAuto: Boolean?
    var nativeLanguage: String?
}

val PromoCodePopup = FC<PromoCodePopupProps> { props ->
    val (open, setOpen) = useState(true)
    val navigate = useNavigate()
    val nativeLanguage = props.nativeLanguage ?: ELanguage.ENGLISH.text

    if (!open || !props.open) {
        return@FC
    }

    section {
        css(popup(props.fromLanguageAuto == true))
        img {
            css(icon)
            src = kotlinext.js.require("./images/discount.png").default
        }
        div {
            css(text)
            p {
                css(label)
                +localizeOfferTitle(nativeLanguage)
            }
            p {
                css(offer)
                +localizeOfferDescription(nativeLanguage)
            }
        }
        Button {
            css(newTaskButton)
            onClick = {
                val path = if (props.fromLanguageAuto == true) {
                    "${ERoute.PAYMENT.path}?${EPaymentPerks.LANGUAGE_AUTO.param}=${true}"
                } else {
                    ERoute.PAYMENT.path
                }
                navigate(path)
            }
            +localizeOfferButton(nativeLanguage)
        }
        Button {
            css(closeButton)
            onClick = {
                setOpen(false)
            }
            Icon {
                css(whiteFilter)
                src = "ic_cross_24x24.svg"
            }
        }
    }
}

private fun localizeOfferTitle(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Специальное предложение"
    } else {
        "Special offer"
    }
}

private fun localizeOfferDescription(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Получи скидку 15% по промокоду OFF15 сегодня"
    } else {
        "Get 15% off with the code OFF15 today"
    }
}

private fun localizeOfferButton(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Перейти на PRO"
    } else {
        "Upgrade to PRO"
    }
}