package widgets.Exercises.ui.components.StatisticsModal

import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.serialization.json.Json
import online.interactiver.common.statistics.SlideStatisticsResponse
import utils.getToken

suspend fun getStatisticsRequest(requestId: Int, timePeriod: String): List<SlideStatisticsResponse>? {
    val response = jsonClient.post("api/v2/auto-generation/language-slider/requests/$requestId/statistics") {
        bearerAuth(getToken())
        contentType(ContentType.Application.Json)
        setBody(FormDataContent(Parameters.build {
            append("time_period", timePeriod)
        }))
    }

    if (response.status.value != 200) {
        return null
    }

    return Json.decodeFromString(response.bodyAsText())
}
