package widgets.CourseMaterials

import online.interactiver.common.course.CourseState
import online.interactiver.common.enums.EContentForm
import pages.course.CourseAction
import pages.course.selectCourseState
import pages.languageAuto.LanguageAutoStoreState

open class CourseMaterialsAction : CourseAction()

data class SetCourseWordsToGenerateExercises(val wordsToGenerateExercises: String) : CourseMaterialsAction()
data class SetCourseSentencesToGenerateExercises(val sentencesToGenerateExercises: String) : CourseMaterialsAction()

data class SetCourseGenerationType(val courseGenerationType: String) : CourseMaterialsAction()

data class SetCourseText(val text: String) : CourseMaterialsAction()
data class SetCourseContentForm(val contentForm: EContentForm) : CourseMaterialsAction()

fun courseMaterialsReducer(state: CourseState, action: CourseMaterialsAction): CourseState {
    return when (action) {
        is SetCourseContentForm -> state.copy(contentForm = action.contentForm)
        is SetCourseGenerationType -> state.copy(courseGenerationType = action.courseGenerationType)
        is SetCourseWordsToGenerateExercises -> state.copy(wordsToGenerateExercises = action.wordsToGenerateExercises)
        is SetCourseSentencesToGenerateExercises -> state.copy(sentencesToGenerateExercises = action.sentencesToGenerateExercises)
        is SetCourseText -> state.copy(text = action.text)
        else -> state
    }
}

val selectSentencesToGenerateExercises = { state: LanguageAutoStoreState ->
    selectCourseState(state).sentencesToGenerateExercises
}

val selectWordsToGenerateExercises = { state: LanguageAutoStoreState ->
    selectCourseState(state).wordsToGenerateExercises
}

val selectCourseGenerationType = { state: LanguageAutoStoreState ->
    selectCourseState(state).courseGenerationType
}

val selectCourseText = { state: LanguageAutoStoreState ->
    selectCourseState(state).text
}

val selectCourseContentForm = { state: LanguageAutoStoreState ->
    selectCourseState(state).contentForm
}