package pages.constructor.ui.components.canvas

import Blur
import app.appState.OptionEditState
import app.appState.selectAppState
import app.useAppDispatch
import app.useAppSelector
import builders.enums.EElementGroup
import emotion.react.css
import entities.backgroundPosScale.SetCanvasContainerSize
import entities.backgroundPosScale.selectBackgroundMargin
import entities.interactivePicture.background.EditBackgroundWithRatioWithFuture
import entities.interactivePicture.background.selectCanvasHeight
import entities.interactivePicture.background.selectCanvasMaxWidth
import entities.interactivePicture.background.selectCanvasWidth
import entities.interactivePicture.background.ui.canvas.BackgroundCanvas
import entities.interactivePicture.elements.gapPuzzles.drags.selectFocusedOptionFiguresAndLines
import entities.interactivePicture.elements.gapPuzzles.drags.selectFocusedOptionFrames
import entities.interactivePicture.guides.ui.canvas.GuidesCanvas
import entities.interactivePicture.guides.ui.canvas.updateGuidesOnDrag
import entities.interactivePicture.selectInteractiveElementsBounds
import entities.saver.SetCurrentPictureSaved
import entities.selectedElement.SelectElement
import entities.selectedElement.SelectMultipleElements
import entities.selectedElement.selectElementsIdsUnderSelectionRectangle
import enums.ESnapType
import kotlin_konva_external_typealiases.Filter
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.HTMLDivElement
import pages.constructor.ui.components.canvas.selectionRectangle.SelectionRectangle
import react.*
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import reactkonva.Layer
import reactkonva.Rect
import reactkonva.Stage
import reactkonva.Transformer
import shared.canvas.ElementCanvas
import shared.canvas.utils.getGuideLines
import shared.canvas.utils.getObjectsSnappingEdges
import shared.canvas.utils.setTargetsPositionUnderSelectionByGuide
import utils.structures.*
import utils.types.jsObject
import kotlin.math.min

external interface CanvasProps : Props {
    var stageRef: Ref<dynamic>
}

data class SelectionRectangle(val start: Point, val end: Point) {
    fun intersectsWith(interactiveElement: ElementPosition): Boolean {
        val other = interactiveElement.bounds

        val thisCorners = arrayOf(
            Point(start.x, start.y),
            Point(start.x, end.y),
            Point(end.x, end.y),
            Point(end.x, start.y),
        )

        val otherCorners = arrayOf(
            Point(other.x, other.y),
            Point(other.x, other.y + other.height),
            Point(other.x + other.width, other.y + other.height),
            Point(other.x + other.width, other.y)
        )

        val cornerInside = otherCorners.any { it.isInside(this) } || thisCorners.any { it.isInside(other) }

        val thisSegments = arrayOf(
            HorizontalSegment(start.y, start.x, end.x),
            HorizontalSegment(end.y, start.x, end.x),
            VerticalSegment(start.x, start.y, end.y),
            VerticalSegment(end.x, start.y, end.y)
        )

        val otherSegments = arrayOf(
            HorizontalSegment(other.y, other.x, other.x + other.width),
            HorizontalSegment(other.y + other.height, other.x, other.x + other.width),
            VerticalSegment(other.x, other.y, other.y + other.height),
            VerticalSegment(other.x + other.width, other.y, other.y + other.height)
        )

        val edgesIntersecting = thisSegments.any { thisSeg -> otherSegments.any { it.intersectsWith(thisSeg) } }

        return cornerInside || edgesIntersecting
    }
}

val Canvas = FC<CanvasProps> { props ->

    val canvasDivRef = useRef<HTMLDivElement>(null)
    val divRef = useRef<HTMLDivElement>(null)
    val stageRef = useRef<HTMLCanvasElement>(null)
    val verticalRef = useRef<dynamic>(null)
    val horizontalRef = useRef<dynamic>(null)
    val trRef = useRef<dynamic>(null)

    val appState = useAppSelector(selectAppState)
    val backgroundMargin = useAppSelector(selectBackgroundMargin)

    val canvasWidth = useAppSelector(selectCanvasWidth) ?: 0
    val canvasHeight = useAppSelector(selectCanvasHeight) ?: 0
    val canvasMaxWidth = useAppSelector(selectCanvasMaxWidth)

    val (selectionRectangle, setSelectionRectangle) = useState<SelectionRectangle?>(null)
    val selectionRectangleRef = useRef<dynamic>(null)
    val backgroundRef = useRef<dynamic>(null)
    val interactiveElements = useSelector(selectInteractiveElementsBounds) { l, r -> l.contentEquals(r) }
    val elementsIdsUnderSelectionRectangle = useSelector(selectElementsIdsUnderSelectionRectangle) { l, r -> l.contentEquals(r) }
    val guidesLines = useSelector(getObjectsSnappingEdges) { l, r -> l.toTypedArray().contentEquals(r.toTypedArray()) }

    val dispatch = useAppDispatch()

    useLayoutEffectOnce {
        divRef.current?.let {
            val width = it.offsetWidth - 2 * backgroundMargin
            val height = it.offsetHeight - 2 * backgroundMargin
            dispatch(
                SetCanvasContainerSize(
                    width,
                    height
                )
            )
            dispatch(
                EditBackgroundWithRatioWithFuture(
                    min(canvasMaxWidth ?: width, width),
                    height,
                    Point(16, 9)
                )
            )
            dispatch(SetCurrentPictureSaved())
        }
    }

    useEffect(elementsIdsUnderSelectionRectangle) {
        if (elementsIdsUnderSelectionRectangle.isEmpty()) {
            trRef.current?.nodes(arrayOf<dynamic>())
            trRef.current?.getLayer()?.batchDraw()
        }
    }

    canvasDivRef.current?.style?.width = "${canvasWidth + 2 * backgroundMargin}px"
    canvasDivRef.current?.style?.height = "${canvasHeight + 2 * backgroundMargin}px"

    val controlsLayer = useRef<dynamic>()

    val layers = arrayOf(controlsLayer)

    val optionBlurRectRef = useRef<dynamic>()

    useEffect(appState, optionBlurRectRef, layers) {
        cleanup {
            for (ref: MutableRefObject<dynamic> in layers) {
                val layer = ref.current
                layer?.filters(arrayOf<Filter>())
                layer?.blurRadius(0)
                layer?.clearCache()
            }
        }

        if (appState !is OptionEditState) {
            return@useEffect
        }

        for (ref: MutableRefObject<dynamic> in layers) {
            val layer = ref.current
            layer?.filters(arrayOf(Blur))
            layer?.blurRadius(2)
            layer?.cache(jsObject { offset = 100 })
        }

        val rect = optionBlurRectRef.current
        val stage = rect?.getStage()
        rect?.width(stage?.width() ?: 0)
        rect?.height(stage?.height() ?: 0)
    }

    div {
        css(container)
        ref = divRef
        onClick = { evt ->
            if (evt.target == divRef.current) {
                dispatch(SelectElement(null))
                dispatch(SelectMultipleElements(arrayOf()))
                trRef.current?.nodes(arrayOf<dynamic>())
                trRef.current?.getLayer()?.batchDraw()
            }
        }
        div f@{
            css(canvasContainer)
            ref = canvasDivRef
            id = "canvas"

            if (canvasWidth + 2 * backgroundMargin == 0 || canvasHeight + 2 * backgroundMargin == 0) {
                return@f
            }

            Stage {
                this.x = 0
                this.y = 0
                this.width = canvasWidth + 2 * backgroundMargin
                this.height = canvasHeight + 2 * backgroundMargin
                this.ref = stageRef
                onClick = { evt ->
                    if (stageRef.current == evt.target) {
                        dispatch(SelectElement(null))
                        dispatch(SelectMultipleElements(arrayOf()))
                        trRef.current?.nodes(arrayOf<dynamic>())
                        trRef.current?.getLayer()?.batchDraw()
                    }
                }
                onMouseDown = onMouseDown@{ evt ->
                    if (evt.target._id != backgroundRef.current._id) {
                        return@onMouseDown
                    }
                    if (selectionRectangle == null) {
                        val stage = evt.target.getStage()
                        val x = stage.getPointerPosition().x as Int
                        val y = stage.getPointerPosition().y as Int
                        setSelectionRectangle(SelectionRectangle(Point(x, y), Point(x,y)))
                    }
                }
                onMouseMove = { evt ->
                    if (selectionRectangle != null) {
                        val stage = evt.target.getStage()
                        val x = stage.getPointerPosition().x as Int
                        val y = stage.getPointerPosition().y as Int
                        setSelectionRectangle(selectionRectangle.copy(end = Point(x,y)))
                    }
                }
                onMouseUp = {
                    if (selectionRectangle != null) {
                        val newInteractiveElementsIds = interactiveElements.filter { selectionRectangle.intersectsWith(it) }
                            .map { it.id }
                        dispatch(SelectElement(null))
                        dispatch(SelectMultipleElements(newInteractiveElementsIds.toTypedArray()))
                        setSelectionRectangle(null)
                    }
                }
                onMouseLeave = onMouseUp

                Layer {
                    this.ref = props.stageRef

                    BackgroundCanvas {
                        this.backgroundRef = backgroundRef
                        x = backgroundMargin
                        y = backgroundMargin
                        this.width = canvasWidth
                        this.height = canvasHeight
                    }
                    ElementCanvas {
                        this.horizontalRef = horizontalRef
                        this.verticalRef = verticalRef
                        parentTrRef = trRef
                        selector = EElementGroup.STATIC.selector
                        this.guidesLines = guidesLines
                    }
                    ElementCanvas {
                        this.horizontalRef = horizontalRef
                        this.verticalRef = verticalRef
                        parentTrRef = trRef
                        selector = EElementGroup.OTHER.selector
                        this.guidesLines = guidesLines
                    }
                }

                Layer {
                    ref = controlsLayer
                    ElementCanvas {
                        this.horizontalRef = horizontalRef
                        this.verticalRef = verticalRef
                        parentTrRef = trRef
                        selector = EElementGroup.CONTROLS.selector
                        this.guidesLines = guidesLines
                    }
                }
                Layer {
                    ElementCanvas {
                        this.horizontalRef = horizontalRef
                        this.verticalRef = verticalRef
                        parentTrRef = trRef
                        selector = EElementGroup.PUZZLE.selector
                        this.guidesLines = guidesLines
                    }
                }

                if (appState is OptionEditState) {
                    Layer {
                        Rect {
                            ref = optionBlurRectRef
                            filters = arrayOf(Blur)
                            fill = "#eeeeee"
                            opacity = 0.5
                            onClick = { evt ->
                                if (optionBlurRectRef.current == evt.target) {
                                    dispatch(SelectElement(null))
                                }
                            }
                        }
                        ElementCanvas {
                            this.horizontalRef = horizontalRef
                            this.verticalRef = verticalRef
                            selector = selectFocusedOptionFrames
                            this.guidesLines = guidesLines
                        }
                        ElementCanvas {
                            this.horizontalRef = horizontalRef
                            this.verticalRef = verticalRef
                            selector = selectFocusedOptionFiguresAndLines
                            this.guidesLines = guidesLines
                        }
                    }
                }

                Layer {
                    GuidesCanvas {
                        this.horizontalRef = horizontalRef
                        this.verticalRef = verticalRef
                    }
                    SelectionRectangle {
                        this.selectionRectangleRef = selectionRectangleRef
                        this.selectionRectangle = selectionRectangle
                        this.onMouseUp = onMouseUp
                    }
                    Transformer {
                        ref = trRef
                        enabledAnchors = arrayOf()
                        rotateEnabled = false
                        onDragMove = {
                            val tr = it.target
                            val guides = getGuideLines(
                                guidesLines.filter { it.horizontal.firstOrNull()?.ownerUID !in elementsIdsUnderSelectionRectangle },
                                "",
                                Position(tr.x(), tr.y(), tr.width(), tr.height()),
                                ESnapType.ALL,
                                ESnapType.ALL
                            )

                            updateGuidesOnDrag(horizontalRef, verticalRef, guides)
                            setTargetsPositionUnderSelectionByGuide(guides, (tr.nodes() as Array<dynamic>).toMutableList(), tr)
                        }
                    }
                }
            }
        }
        (appState.getExtraUnderCanvasDOM()) { }
    }
}
