package widgets.LanguageAutoSlider.ui.components.AdditionalSettingsModal

import csstype.*
import utils.types.CssStyle

val modal: CssStyle = {
    gap = 20.px
    display = Display.flex
    flexDirection = FlexDirection.column
}

val settingContainer: CssStyle = {
    width = 100.pct
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 16.px
    padding = 20.px
    boxSizing = BoxSizing.borderBox
    borderRadius = 12.px
    border = Border(1.px, LineStyle.solid, Color("#F2F4F7"))
}

val header: CssStyle = {
    color = Color("#98A2B3")
    fontSize = 12.px
    lineHeight = 16.2.px
    fontWeight = integer(500)
    margin = 0.px
}

val buttonsContainer: CssStyle = {
    width = 100.pct
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
}

val addButton: CssStyle = {
    width = 327.px
    height = 42.px
    backgroundColor = Color("#F4F7FF")
    color = Color("#597EF7")
    fontSize = 14.px
    lineHeight = 18.2.px
    fontWeight = integer(500)
    display = Display.flex
    alignItems = AlignItems.center
    gap = 10.px
    border = None.none
    borderRadius = 10.px
}

val deleteButton: CssStyle = {
    border = None.none
    borderRadius = 10.px
    backgroundColor = important(Color("#FFEDEB"))
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    width = 42.px
    height = 42.px
    padding = 0.px
}
