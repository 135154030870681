package utils.types

import RectConfig
import ShapeConfig
import TextConfig
import Vector2d
import online.interactiver.common.interactivepicture.GeometryStyle
import online.interactiver.common.interactivepicture.TextStyle

inline fun ShapeConfig.style(style: GeometryStyle) {
    strokeWidth = style.strokeWidth
    stroke = style.strokeColor
    lineCap = style.lineCap
    lineJoin = style.lineJoin
    dash = style.dash?.toTypedArray()
    fill = style.fillColor
    shadowColor = style.shadowColor
    shadowBlur = style.shadowBlur
    shadowOffset = style.shadowOffset?.let { impl<Vector2d> { x = it.x; y = it.y } }
    shadowOpacity = style.shadowOpacity
}

inline fun RectConfig.style(style: GeometryStyle) {
    cornerRadius = style.cornerRadius
    strokeWidth = style.strokeWidth
    stroke = style.strokeColor
    lineCap = style.lineCap
    lineJoin = style.lineJoin
    dash = style.dash?.toTypedArray()
    fill = style.fillColor
    shadowColor = style.shadowColor
    shadowBlur = style.shadowBlur
    shadowOffset = style.shadowOffset?.let { impl<Vector2d> { x = it.x; y = it.y } }
    shadowOpacity = style.shadowOpacity
}

inline fun TextConfig.style(style: TextStyle) {
    fontSize = style.fontSize
    fontFamily = style.fontFamily
    fontStyle = style.fontStyle
    align = style.align
    verticalAlign = style.verticalAlign
    fill = style.textColor
    padding = style.padding
}
