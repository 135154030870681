package widgets.LanguageAutoSlider.ui.components.ShareModal

import csstype.*
import utils.types.CssStyle

val modal: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 22.px
    width = 100.pct
}

val copyLinkContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 14.px
    width = 100.pct
}

val linkContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
    width = 100.pct
}

val link: CssStyle = {
    border = Border(1.px, LineStyle.solid, Color("#D0D5DD"))
    borderRadius = 8.px
    padding = 8.px
    margin = 0.px
    color = Color("#000000")
    fontWeight = FontWeight.normal
    fontSize = 16.px
    lineHeight = 21.6.px
}

val optionsContainer: CssStyle = {
    display = Display.flex
    gap = 18.px
}

val optionContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 7.px
    alignItems = AlignItems.center
}

val optionButton: CssStyle = {
    backgroundColor = Color("#F2F4F7")
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    width = 48.px
    height = 48.px
    borderRadius = 50.pct
    border = None.none
}

val optionText: CssStyle = {
    color = Color("#5D6676")
    fontSize = 12.px
    lineHeight = 16.2.px
    fontWeight = FontWeight.normal
    margin = 0.px
    position = Position.relative
}
