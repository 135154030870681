package pages.constructor.ui.components.advancedElementsSettings.advancedButtonSettings

import Modal
import app.useAppDispatch
import app.useAppSelector
import builders.enums.EElementType
import emotion.react.css
import entities.advancedElementSettings.CloseAdvancedElementSettings
import entities.advancedElementSettings.selectAdvancedElementSettingsModalState
import entities.interactivePicture.elements.SetElementCode
import entities.interactivePicture.elements.editors.ScoreEditor.scoreTitles
import entities.interactivePicture.elements.editors.elementCodeEditor.ElementCodeEditor
import entities.interactivePicture.elements.editors.elementCodeEditor.useFocusedElementCode
import entities.interactivePicture.elements.gapPuzzles.drags.SetScoresBySelectedAndUnselected
import entities.interactivePicture.selectFocusedElement
import pages.constructor.ui.components.advancedElementsSettings.advancedInputSettings.elementTypeHeader
import pages.constructor.ui.components.advancedElementsSettings.advancedInputSettings.header
import pages.constructor.ui.components.advancedElementsSettings.advancedSelectorSettings.container
import react.*
import react.dom.html.ReactHTML.div

val AdvancedButtonSettings = FC<Props> {
    val modalState = useAppSelector(selectAdvancedElementSettingsModalState)
    val dispatch = useAppDispatch()
    val focusedElement = useAppSelector(selectFocusedElement)
    val focusedElementType = EElementType.values().find{ focusedElement?.visibleElement?.type == it.text }
    if (focusedElement == null || focusedElementType != EElementType.BUTTON_CONTROL) {
        throw RuntimeException("Rendered advanced button settings with no button focused!")
    }

    val existingScoresNames = useAppSelector(scoreTitles)
    val (allScoresNames, setAllScoresNames) = useState(existingScoresNames)

    val (scoresBySelected, setScoresBySelected) = useState(focusedElement.button?.scoresBySelected ?: mutableListOf())
    val (scoresByUnselected, setScoresByUnselected) = useState(focusedElement.button?.scoresByUnselected ?: mutableListOf())
    val (elementCode, setElementCode) = useFocusedElementCode()

    useEffect(focusedElement) {
        setScoresBySelected(focusedElement.button?.scoresBySelected ?: mutableListOf())
        setScoresByUnselected(focusedElement.button?.scoresByUnselected ?: mutableListOf())
    }

    useEffect(scoresBySelected, scoresByUnselected) {
        val newAllScores = existingScoresNames.toMutableSet()
        newAllScores.addAll(scoresBySelected.map { it.name })
        newAllScores.addAll(scoresByUnselected.map { it.name })
        setAllScoresNames(newAllScores.filter { it.isNotBlank() }.toMutableSet())
    }

    val addScoreBySelected = useAddScoreToOption(scoresBySelected)
    val setScoreValueBySelected = useSetScoreValue(scoresBySelected)
    val setScoreNameBySelected = useSetScoreName(scoresBySelected)
    val removeScoreBySelected = useRemoveScore(scoresBySelected)

    val addScoreByUnselected = useAddScoreToOption(scoresByUnselected)
    val setScoreValueByUnselected = useSetScoreValue(scoresByUnselected)
    val setScoreNameByUnselected = useSetScoreName(scoresByUnselected)
    val removeScoreByUnselected = useRemoveScore(scoresByUnselected)

    Modal {
        width = 800
        open = modalState.isOpen
        onCancel = {
            setScoresBySelected(focusedElement.button?.scoresBySelected ?: mutableListOf())
            setScoresByUnselected(focusedElement.button?.scoresByUnselected ?: mutableListOf())
            setElementCode(focusedElement.identifier.code ?: "")
            dispatch(CloseAdvancedElementSettings())
        }

        onOk = {
            dispatch(SetScoresBySelectedAndUnselected(focusedElement.identifier.id!!, scoresBySelected, scoresByUnselected))
            dispatch(SetElementCode(focusedElement.identifier.id!!, elementCode))
            dispatch(CloseAdvancedElementSettings())
        }

        okText = "Save changes"
        div {
            css(header)
            +"Advanced element settings"
        }
        div {
            css(container)
            div {
                css(elementTypeHeader)
                +"Button"
            }
            ElementCodeEditor {
                value = elementCode
                onChange = {
                    setElementCode(it)
                }
            }

            div {
                +"Selected"
                css(scoreType)
                Scores {
                    scores = scoresBySelected
                    addScore = addScoreBySelected
                    setScoreValue = setScoreValueBySelected
                    setScoreName = setScoreNameBySelected
                    removeScore = removeScoreBySelected
                    onChangeScores = { setScoresBySelected(it) }
                    this.allScoresNames = allScoresNames
                }
            }
            div {
                +"Unselected"
                css(scoreType)
                Scores {
                    scores = scoresByUnselected
                    addScore = addScoreByUnselected
                    setScoreValue = setScoreValueByUnselected
                    setScoreName = setScoreNameByUnselected
                    removeScore = removeScoreByUnselected
                    onChangeScores = { setScoresByUnselected(it) }
                    this.allScoresNames = allScoresNames
                }
            }

        }
    }
}
