package online.interactiver.common.autogeneration

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import online.interactiver.common.enums.EAccessType

@Serializable
enum class EExercisesUiContentType {
    @SerialName("any") ANY,
    @SerialName("only_folders") ONLY_FOLDERS
}

@Serializable
data class GenerationsWithFiltersRequest(
    val languageToLearn: String? = null,
    val interfaceLanguage: String? = null,
    val languageLevel: String? = null,
    val exerciseSearchName: String = "",
    val exerciseType: String? = null,
    val exercisesUiType: EExercisesUiContentType = EExercisesUiContentType.ANY,
    val isNameFilteringDescending: Boolean? = null,
    val isTimeFilteringDescending: Boolean? = null,
    val accessType: String,
    val parentFolderId: Int? = null, // null means root
    val offsetCount: Long = 0
) {
    val shouldShowFolders: Boolean
        get() {
            val isPrivateOrSharedAccess = accessType == EAccessType.PRIVATE.value || accessType == EAccessType.SHARED.value
            // We have default filter by time, so user can be confused when he sees no folders, that's why commented
            //val nameFilterIsUsed = isNameFilteringDescending != null
            val languageToLearnFilterIsOmitted = languageToLearn == null
            val interfaceLanguageIsOmitted = interfaceLanguage == null
            val exerciseTypeIsFolderOrOmitted = (exerciseType == "Folder") || (exerciseType == null)
            val levelFilterIsOmitted = languageLevel == null
            return isPrivateOrSharedAccess && /* nameFilterIsUsed && */ languageToLearnFilterIsOmitted && interfaceLanguageIsOmitted
                    && exerciseTypeIsFolderOrOmitted && levelFilterIsOmitted
        }
}
