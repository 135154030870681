package widgets.StudentDuoContent

import ApiResponse
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.serialization.json.Json
import online.interactiver.common.autogeneration.LanguageAutoState
import online.interactiver.common.course.CourseState
import utils.getToken

suspend fun generateStudentDuoCourse(courseState: CourseState): ApiResponse<Int> {
    return try {
        val response = jsonClient.post("/api/v2/student-duo/course") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(courseState)
        }

        if (response.status.value != 200) {
            return ApiResponse(null, response.status.value)
        }

        ApiResponse(response.bodyAsText().toIntOrNull(), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}

suspend fun getStudentSliderInStoryRequest(state: LanguageAutoState): ApiResponse<LanguageAutoState> {
    return try {
        val response = jsonClient.post("/api/v2/student-duo") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(state)
        }

        if (response.status.value != 200) {
            return ApiResponse(null, response.status.value)
        }

        ApiResponse(Json.decodeFromString(response.bodyAsText()), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}
