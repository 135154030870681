package entities.interactivePicture.guides.ui.canvas

import app.useAppSelector
import entities.interactivePicture.background.selectCanvasHeight
import entities.interactivePicture.background.selectCanvasWidth
import entities.interactivePicture.guides.LineGuide
import entities.interactivePicture.guides.ObjectSnappingEdges
import enums.EOrientation
import react.FC
import react.MutableRefObject
import react.Props
import react.ref
import reactkonva.Arrow

external interface GuideProps : Props {
    var horizontalRef: MutableRefObject<dynamic>
    var verticalRef: MutableRefObject<dynamic>
    var guidesLines:  MutableList<ObjectSnappingEdges>
}


val GuidesCanvas = FC<GuideProps> a@{ props ->
    val canvasWidth = useAppSelector(selectCanvasWidth) ?: 6000
    val canvasHeight = useAppSelector(selectCanvasHeight) ?: 6000

    Arrow {
        this.points = arrayOf(-canvasWidth, 0, canvasWidth, 0)
        this.ref = props.horizontalRef
        pointerLength = 0
        pointerWidth = 0
        stroke = "#1677ff"
        opacity = 0
        dash = arrayOf(4, 6)
        strokeWidth = 1
        this.x = x
        this.y = y
    }

    Arrow {
        this.points = arrayOf(0, -canvasHeight, 0, canvasHeight)
        this.ref = props.verticalRef
        pointerLength = 0
        pointerWidth = 0
        stroke = "#1677ff"
        strokeWidth = 1
        opacity = 0
        dash = arrayOf(4, 6)
        this.x = x
        this.y = y
    }
}

// На вход принимает:
// horizontalRef: ссылку на горизонтальную линию
// verticalRef: ссылку на вертикальную линию
// lineGuides: найденные линии для выравнивания
// Пробегает по найденным линиям
// Передвигает соответсвующую ей линию на канвасе
fun updateGuidesOnDrag(
    horizontalRef: MutableRefObject<dynamic>,
    verticalRef: MutableRefObject<dynamic>,
    lineGuides: List<LineGuide>
) {
    if (lineGuides.size == 0) {
        horizontalRef.current.opacity(0)
        verticalRef.current.opacity(0)
        return
    }

    lineGuides.forEach { guide ->
        var guideLine = verticalRef.current

        if (guide.orientation == EOrientation.HORIZONTAL) {
            guideLine = horizontalRef.current
            guideLine.y(guide.position)
        } else {
            guideLine.x(guide.position)
        }

        guideLine.opacity(1)
    }

    if (lineGuides.size != 1) {
        return
    }

    if (lineGuides.get(0).orientation == EOrientation.HORIZONTAL) {
        verticalRef.current.opacity(0)
    } else {
        horizontalRef.current.opacity(0)
    }
}
