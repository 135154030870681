package entities.interactivePicture.elements.editors.ScoreEditor.ui

import antd.BaseOptionType
import antd.Input
import antd.Select
import app.appState.selectAppState
import app.useAppDispatch
import app.useAppSelector
import emotion.react.css
import entities.interactivePicture.elements.editors.ScoreEditor.scoreTitles
import online.interactiver.common.interactivepicture.ScoreCondition
import org.w3c.dom.HTMLInputElement
import react.FC
import react.Props
import react.dom.events.FocusEvent
import react.dom.html.ReactHTML
import shared.components.Icon
import utils.parseNumber
import utils.types.jsObject

external interface ScoreConditionInputProps : Props {
    var elementUID: String
    var conditionIndex: Int
    var condition: ScoreCondition
}

val ScoreConditionInput = FC<ScoreConditionInputProps> { props ->

    val dispatch = useAppDispatch()
    val appState = useAppSelector(selectAppState)

    val setNewName = { name: String ->
        dispatch(
            appState.setScoreCondition(
                props.elementUID,
                props.conditionIndex,
                ScoreCondition(
                    props.condition.left,
                    name,
                    props.condition.right
                )
            )
        )
    }

    val onFocusOut = a@{ event: FocusEvent<HTMLInputElement> ->
        val target = event.target as? HTMLInputElement

        val value = target!!.value
        if (value.isEmpty()) {
            return@a
        }

        setNewName(value)
    }

    val onValueInput = { isLeftInput: Boolean ->
        b@{ event: dynamic ->

            val value = event.target.value
            val leftNumber = parseNumber(value) ?: props.condition.left
            val rightNumber = parseNumber(value) ?: props.condition.right
            dispatch(
                appState.setScoreCondition(
                    props.elementUID,
                    props.conditionIndex,
                    ScoreCondition(
                        if (isLeftInput) leftNumber else props.condition.left,
                        props.condition.scoreName,
                        if (!isLeftInput) rightNumber else props.condition.right
                    )
                )
            )
            return@b
        }
    }

    val onSelectValue = c@{ value: String, _: BaseOptionType ->
        setNewName(value)
        return@c
    }

    val options = useAppSelector(scoreTitles)


    ReactHTML.div {
        css(horizontalContainer)

        Input {
            css(shortInput)
            this.value = props.condition.left.toString()
            this.defaultValue = props.condition.left.toString()
            this.onBlur = onValueInput(true)
            this.onChange = onValueInput(true)
        }

        Icon {
            src = "ic_less_equal_24x24.svg"
        }

        Select {
            css(select)
            this.showArrow = false
            this.showSearch = true

            this.onBlur = onFocusOut
            this.onSelect = onSelectValue

            this.options = options.map {
                jsObject {
                    this.value = it
                    this.label = it
                }
            }.toTypedArray()

            this.defaultValue = props.condition.scoreName
            this.value = props.condition.scoreName
        }

        Icon {
            src = "ic_less_equal_24x24.svg"
        }

        Input {
            css(shortInput)
            this.value = props.condition.right.toString()
            this.defaultValue = props.condition.right.toString()
            this.onChange = onValueInput(false)
            this.onBlur = onValueInput(false)
        }
    }

}
