package pages.constructor.ui.components.advancedElementsSettings.advancedButtonSettings

import online.interactiver.common.interactivepicture.ScoreData
import online.interactiver.common.utils.clone
import react.useCallback

fun useAddScoreToOption(scores: MutableList<ScoreData>): () -> MutableList<ScoreData> {
    return useCallback(scores) {
        val newScores = scores.clone()
        val newScore = ScoreData("", 0)
        newScores.add(newScore)

        return@useCallback newScores
    }
}

fun useSetScoreValue(scores: MutableList<ScoreData>): (Int, Int) -> MutableList<ScoreData> {
    return useCallback(scores) { scoreIdx, value ->
        val newScores = scores.clone()
        newScores[scoreIdx].value = value

        return@useCallback newScores
    }
}

fun useSetScoreName(scores: MutableList<ScoreData>): (Int, String) -> MutableList<ScoreData> {
    return useCallback(scores) { scoreIdx, name ->
        val newScores = scores.clone()
        newScores[scoreIdx].name = name

        return@useCallback newScores
    }
}

fun useRemoveScore(scores: MutableList<ScoreData>): (Int) -> MutableList<ScoreData> {
    return useCallback(scores) { scoreIdx ->
        val newScores = scores.clone()
        newScores.removeAt(scoreIdx)

        return@useCallback newScores
    }
}