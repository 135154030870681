package pages.constructor.ui.components.header.interactiveParams

import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import online.interactiver.common.admin.interactive.InteractiveResponse
import online.interactiver.common.admin.interactive.InteractivesGroup


fun getGroupsConfig(json: String): List<InteractivesGroup> {
    val data = Json {
        ignoreUnknownKeys = true
        explicitNulls = false
    }.decodeFromString<List<InteractivesGroup>>(json)
    return data
}

fun getInteractiveResponse(json: String): InteractiveResponse {
    val data = Json {
        ignoreUnknownKeys = true
        explicitNulls = false
    }.decodeFromString<InteractiveResponse>(json)
    return data
}