package pages.constructor.ui.components.advancedElementsSettings.advancedButtonSettings

import antd.Button
import antd.Select
import antd.TypedInputNumber
import emotion.react.css
import online.interactiver.common.interactivepicture.ScoreData
import org.w3c.dom.HTMLElement
import pages.constructor.ui.components.advancedElementsSettings.advancedSelectorSettings.*
import react.FC
import react.Props
import react.dom.html.ReactHTML
import shared.components.Icon
import utils.types.jsObject

external interface RenderScoreProps: Props {
    var scores: MutableList<ScoreData>
    var addScore: () -> MutableList<ScoreData>
    var setScoreValue: (Int, Int) -> MutableList<ScoreData>
    var setScoreName: (Int, String) -> MutableList<ScoreData>
    var removeScore: (Int) -> MutableList<ScoreData>
    var onChangeScores: (MutableList<ScoreData>) -> Unit
    var allScoresNames: MutableSet<String>
}

val Scores = FC<RenderScoreProps>{props ->
    props.scores.mapIndexed {scoreIndex, score ->
        ReactHTML.div {
            css(inputsContainer)
            Select {
                css(nameSelector)
                this.showArrow = false
                this.showSearch = true

                this.placeholder = "Type your score"

                this.options = props.allScoresNames.map {
                    jsObject {
                        this.value = it
                        this.label = it
                    }
                }.toTypedArray()

                this.value = score.name.ifBlank { null }

                this.onSelect = { value, _ ->
                    props.onChangeScores(props.setScoreName(scoreIndex, value))
                }
                this.onBlur = onBlur@{
                    val value = it.target.value
                    if (value.isBlank()) {
                        return@onBlur
                    }

                    props.onChangeScores(props.setScoreName(scoreIndex, value))
                }
                this.onKeyDown = onKeyDown@{
                    if (it.key != "Enter") {
                        return@onKeyDown
                    }
                    it.preventDefault()
                    (it.target as? HTMLElement)?.blur()
                }
            }
            TypedInputNumber {
                css(valueInput)
                controls = false
                precision = 0
                this.value = score.value
                onChange = {
                    props.onChangeScores(props.setScoreValue(scoreIndex, it.toInt()))
                }
            }
            if (scoreIndex > 0) {
                Button {
                    css(deleteScoreButton)
                    Icon {
                        src = "ic_cross_24x24.svg"
                    }
                    onClick = {
                        props.onChangeScores(props.removeScore(scoreIndex))
                    }
                }
            } else {
                ReactHTML.div { css(fakeDeleteButtonContainer) }
            }
        }
    }
    Button {
        css(addScoreButton)
        +"+ Add score category"
        onClick = {
            props.onChangeScores(props.addScore())
        }
    }
}