package shared.components.switchWIthLabelAndTooltip

import csstype.*
import utils.types.CssStyle

val enableButtonContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
}

val textContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 2.px
}

val enableText: CssStyle = {
    fontWeight = integer(500)
    fontSize = 14.px
    lineHeight = 18.9.px
    color = Color("#5D6676")
    margin = 0.px
}
