package widgets.Notifications.ui

import csstype.*
import utils.types.CssStyle

val settingsContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 15.px
    padding = 10.px
}
