package widgets.LanguageAutoContent.ui.components.Content

import app.useAppDispatch
import entities.languageAutoCurrentRequest.SetCurrentRequestTextOrTopic
import entities.languageAutoCurrentRequest.selectCurrentRequestTextOrTopic
import react.FC
import react.Props
import react.redux.useSelector

const val contentPlaceholder = "Paste your content here..."

val ExercisesFromContent = FC<Props> {
    val textOrTopic = useSelector(selectCurrentRequestTextOrTopic)
    val dispatch = useAppDispatch()

    TextAreaWithLengthRestriction {
        label = "Your text - at least 20 words"
        value = textOrTopic
        placeholder = contentPlaceholder
        onChange = { dispatch(SetCurrentRequestTextOrTopic(it)) }
    }
}