package pages.constructor.ui.components.modalUpgradePlan

import csstype.*
import utils.types.CssStyle

val modalMaskStyle: CssStyle = {
    background = rgba(5, 10, 49, 0.45)
}

val modalHeaderStyle: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    gap = 10.px
    alignItems = AlignItems.center
}

val modalHeaderIconStyle: CssStyle = {
    color = rgb(140,142,140)
}

val modalHeaderH3Style: CssStyle = {
    margin = 0.px
}

val modalBodyStyle: CssStyle = {
    marginLeft = 30.px
    marginRight = 12.px
}

val modalTextStyle: CssStyle = {
    fontFamily = string("Arial")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 14.px
    lineHeight = 17.px
}