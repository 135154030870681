package pages.constructor.ui.components.header

import csstype.*
import utils.inject
import utils.types.CssStyle

val headerHeight = 50

val headerContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.center
    height = headerHeight.px
    width = 100.pct
    gap = 20.px
    backgroundColor = Color("#1c274c")
    paddingLeft = 30.px
    paddingRight = 30.px
    boxSizing = BoxSizing.borderBox // to prevent padding enlarge width
}

val dividedBlock: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.center
    height = Length.fitContent
}

val dividedBlockItem: CssStyle = {
    val mainStyle: CssStyle = {
        paddingLeft = 20.px
        paddingRight = 20.px
    }

    inject(mainStyle)

    firstChild {
        inject(mainStyle)
        paddingLeft = 0.px
    }

    lastChild {
        inject(mainStyle)
        paddingRight = 0.px
    }
}

val divider: CssStyle = {
    width = 1.px
    backgroundColor = rgba(255, 255, 255, 0.2)
    alignSelf = AlignSelf.center
    height = 24.px
}
