package utils.types

inline fun <T> impl(init: T.() -> Unit): T {
    val res: T = object {}.unsafeCast<T>()
    init(res)
    return res
}

inline fun jsObject(init: dynamic.() -> Unit) = impl<dynamic>(init)

external fun encodeURIComponent(text: String): String
