package shared.canvas.implementations


import Vector2d
import react.FC
import react.ref
import reactkonva.Circle
import shared.canvas.KonvaElementProps
import utils.types.impl

val TransformableCircle = FC<KonvaElementProps> { props ->

    val style = props.style

    val radius = props.bounds.width / 2

    Circle {
        this.x = radius
        this.y = radius
        this.ref = props.elementRef
        this.radius = radius
        this.width = props.bounds.width
        this.height = props.bounds.height
        this.strokeWidth = style.strokeWidth
        this.stroke = style.strokeColor
        this.lineCap = style.lineCap
        this.lineJoin = style.lineJoin
        this.dash = style.dash?.toTypedArray()
        this.fill = style.fillColor
        this.shadowColor = style.shadowColor
        this.shadowBlur = style.shadowBlur
        this.shadowOffset = style.shadowOffset?.let { impl<Vector2d> { x = it.x; y = it.y } }
        this.shadowOpacity = style.shadowOpacity

        this.onTransform = props.onTransform
        this.onTransformStart = props.onTransformStart
        this.onTransformEnd = props.onTransformEnd
    }
    TextComponent {
        elementRef = props.elementRef
        textRef = props.textRef
        data = props.data
        bounds = props.bounds
        textStyle = props.textStyle
        isUnderSelectionRectangle = props.isUnderSelectionRectangle
    }
}