package entities.interactivePicture.elements.gapPuzzles.drags

import online.interactiver.common.interactivepicture.GeometryStyle
import online.interactiver.common.interactivepicture.TextStyle

val puzzleTextStyle = GeometryStyle(
        fillColor = "#9DACF3",
        opacity = 1.0,
        cornerRadius = 5,
)

val puzzleAreaFromBgStyle = GeometryStyle(
        cornerRadius = 5,
        strokeWidth = 2,
        opacity = 0.5,
        fillColor = "#FAFBFC",
        strokeColor = "#D0D5DD",
)

val puzzleTextTextStyle = TextStyle(
        fontFamily = "Arial",
        fontStyle = "700",
        fontSize = 14,
        textColor = "#101828",
        padding = 5,
        align = "center"
)

val gapStyle = GeometryStyle(
        cornerRadius = 5,
        fillColor = "#FAFBFC",
        strokeWidth = 2,
        strokeColor = "#D0D5DD",
)

val puzzleImageStyle = GeometryStyle()
