package pages.constructor.ui.components.advancedSettings

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    width = 740.px
    height = 530.px
    overflowY = Auto.auto
}

val header: CssStyle = {
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween

    fontFamily = string("\"Arial\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = FontWeight.bold
    fontSize = 18.px
    lineHeight = 26.px
    color = Color("#282F3E")
    marginBottom = 28.px
}

val resetSettingsButton: CssStyle = {
    fontFamily = string("\"Arial\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = FontWeight.normal
    fontSize = 14.px
    lineHeight = 17.px
    color = Color("#597EF7")
}

val settingsContainer: CssStyle = {
    display = Display.flex
}

val column: CssStyle = {
    width = 50.pct
}

val settingsHeader: CssStyle = {
    fontFamily = string("\"Arial\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = FontWeight.normal
    fontSize = 14.px
    lineHeight = 130.pct
    textTransform = TextTransform.uppercase
    color = Color("#98A2B3")
    marginBottom = 18.px
}

val settingHeader: CssStyle = {
    fontFamily = string("\"Arial\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = FontWeight.bold
    fontSize = 14.px
    lineHeight = 130.pct
    color = Color("#282F3E")
    marginBottom = 14.px
}

val advancedSettingsButton: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    position = Position.absolute
    height = 35.px
    width = 210.px
    bottom = 50.px
    left = 45.px
    justifyContent = JustifyContent.center
    borderRadius = 5.px
    background = Color("#F2F4F7")
    transitionDuration = 0.s
    hover {
        color = important(Color("#FFFFFF"))
        filter = ("invert(52%) sepia(29%) saturate(5446%) " +
                "hue-rotate(207deg) brightness(96%) contrast(102%)").unsafeCast<FilterFunction>()
        backgroundColor = important(rgba(0, 0, 0, 0.0))
        transitionDuration = 0.s
    }
}

val settingText: CssStyle = {
    marginLeft = 10.px
}
