package widgets.Exercises.ui.components.ManageAccessModal.components

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 7.px
}

val grid: CssStyle = {
    display = Display.grid
    gridTemplateColumns = "4fr 2fr 4fr 1fr".unsafeCast<GridAutoColumns>()
    border = Border(1.px, LineStyle.solid, Color("#D0D5DD"))
    borderRadius = 6.px
}

val header: CssStyle = {
    borderBottom = Border(1.px, LineStyle.solid, Color("#D0D5DD"))
    fontWeight = integer(600)
    margin = 0.px
    padding = 5.px
}

val data: CssStyle = {
    margin = 0.px
    padding = 5.px
}

val deleteButton: CssStyle = {
    padding = 5.px
    border = None.none
    boxShadow = None.none
}
