package pages.constructor.ui.components.modalUpgradePlan

import Modal
import emotion.react.css
import kotlinx.js.jso
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.h3
import shared.components.Icon

external interface ModalUpgradePlanProps : Props {
    var show: Boolean
    var setShow: StateSetter<Boolean>
}

val ModalUpgradePlanHeader = VFC {
    div {
        css(modalHeaderStyle)
        Icon {
            css(modalHeaderIconStyle)
            src = "ic_issue_20x20.svg"
        }
        h3{
            css(modalHeaderH3Style)
            +"It's time for upgrade!"
        }
    }
}

val ModalUpgradePlan = FC<ModalUpgradePlanProps> { props ->
    Modal {
        centered = true
        open = props.show
        footer = null
        onCancel = { props.setShow(false) }
        width = "342px"
        bodyStyle = jso(modalBodyStyle)
        maskStyle = jso(modalMaskStyle)
        title = createElement(ModalUpgradePlanHeader)

        div {
            css(modalTextStyle)
            p { +"Sorry, but it seems like your limit has been exceeded" }
            p {
                +"Please contact "
                a {
                    href = "mailto:vikatsman@gmail.com"
                    +"vikatsman@gmail.com "
                }
                +"or "
                a {
                    href = "https://t.me/vkatsman"
                    +"https://t.me/vkatsman "
                }
                +"for upgrading"
            }
        }
    }
}