package online.interactiver.common.statistics

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
class UserDetailedStatisticsRequest(
    @SerialName("time_period")
    val timePeriod: String,
    @SerialName("student_name")
    val studentName: String
)
