package online.interactiver.common.enums

enum class ECourseLevel(val value: String, val timesToAppearInCourse: Int, val timesToAppearInSlider: Int) {
    FROM_SCRATCH("From scratch - 7 repetitions", 7, 3),
    CONSOLIDATE("Consolidate - 4 repetitions", 4, 2),
    REPEAT("Repeat - 2 repetitions", 2, 1);

    companion object {
        fun fromString(value: String): ECourseLevel? {
            return entries.find { it.value == value }
        }
    }
}