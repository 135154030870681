package online.interactiver.common.enums

enum class ECourseGenerationType(val value: String, val uiValue: String, val description: String, val icon: String) {
    WORDS_SENTENCES(
        "Words Sentences",
        "Sentences and Words",
        "If you have ready set of sentences, words, and its’ translations or definitions",
        "ic_bookmark_24x24.svg"
    ),
    TEXT_WORDS(
        "Text Words",
        "Text and/or Words",
        "If you have text",
        "ic_book_bookmark_24x24.svg"
    ),
    TOPIC_WORDS(
        "Topic Words",
        "Topic and/or Words",
        "If you have an idea what to learn",
        "ic_note_pencil_24x24.svg"
    );

    companion object {
        fun fromString(value: String): ECourseGenerationType? {
            return entries.find { it.value == value }
        }
    }
}
