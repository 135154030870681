package widgets.ExerciseSettings

import online.interactiver.common.user.UserSliderSettings
import pages.languageAuto.LanguageAutoStoreState
import widgets.UserProfile.UserProfileAction
import widgets.UserProfile.selectUserProfile

open class SliderSettingsAction : UserProfileAction()

data class SetTranslateRequestToTargetLanguageAction(val translateRequestToTargetLanguageAction: Boolean)
    : SliderSettingsAction()

data class SetNumberOfSlidesToGenerateAction(val numberOfSlidesToGenerate: Int) : SliderSettingsAction()

data class SetUserSliderSettingsAction(val userSliderSettings: UserSliderSettings) : SliderSettingsAction()

data class SetLogoUseTypeAction(val logoUseType: String) : SliderSettingsAction()

data class SetLinkInLogoAction(val linkInLogo: String?) : SliderSettingsAction()

data class SetLogoAction(val logo: String?, val fileName: String) : SliderSettingsAction()

data class SetGptModelAction(val gptModel: String, val gptModelName: String) : SliderSettingsAction()

data class SetGptModelNameAction(val gptModelName: String) : SliderSettingsAction()

val selectUserSliderSettings = { state: LanguageAutoStoreState ->
    selectUserProfile(state)?.sliderSettings
}

val selectTranslateRequestToTargetLanguage = { state: LanguageAutoStoreState ->
    selectUserSliderSettings(state)?.translateRequestToTargetLanguage
}

val selectNumberOfSlidesToGenerate = { state: LanguageAutoStoreState ->
    selectUserSliderSettings(state)?.numberOfSlidesToGenerate
}

val selectLogoUseType = { state: LanguageAutoStoreState ->
    selectUserSliderSettings(state)?.logoUseType
}

val selectLogo = { state: LanguageAutoStoreState ->
    selectUserSliderSettings(state)?.logo
}

val selectLogoFileName = { state: LanguageAutoStoreState ->
    selectUserSliderSettings(state)?.logoFileName
}

val selectLinkInLogo = { state: LanguageAutoStoreState ->
    selectUserSliderSettings(state)?.linkInLogo
}

val selectGptModelName = { state: LanguageAutoStoreState ->
    selectUserSliderSettings(state)?.gptModelName
}

val selectGptModel = { state: LanguageAutoStoreState ->
    selectUserSliderSettings(state)?.gptModel
}

val SliderSettingsReducer = f@{ state: UserSliderSettings?, action: SliderSettingsAction ->
    return@f when (action) {
        is SetTranslateRequestToTargetLanguageAction -> state?.copy(
            translateRequestToTargetLanguage = action.translateRequestToTargetLanguageAction
        )
        is SetNumberOfSlidesToGenerateAction -> state?.copy(
            numberOfSlidesToGenerate = action.numberOfSlidesToGenerate
        )
        is SetUserSliderSettingsAction -> action.userSliderSettings
        is SetLogoAction -> state?.copy(
            logo = action.logo, logoFileName = action.fileName
        )
        is SetLinkInLogoAction -> state?.copy(linkInLogo = action.linkInLogo)
        is SetLogoUseTypeAction -> state?.copy(logoUseType = action.logoUseType)
        is SetGptModelAction -> state?.copy(gptModel = action.gptModel, gptModelName = action.gptModelName)
        is SetGptModelNameAction -> state?.copy(gptModelName = action.gptModelName)
        else -> state
    }
}
