package pages.constructor.ui.components.advancedSettings.hintsSettings.hintShowAction

import csstype.AlignItems
import csstype.Display
import csstype.px
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    marginBottom = 8.px
}

val text: CssStyle = {
    marginLeft = 12.px
}
