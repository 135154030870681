package utils

import kotlinx.browser.document
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import online.interactiver.common.interactivepicture.InteractivePicture
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import utils.types.impl

fun downloadInteractivePictureAsJson(interactivePicture: InteractivePicture) {
    val json = Json {
        explicitNulls = false
    }.encodeToString(interactivePicture)
    val blob = Blob(arrayOf(json), impl { type = "text/plain" })
    val url = URL.Companion.createObjectURL(blob)
    val link = document.createElement("a") as HTMLAnchorElement
    link.href = url
    link.download = "${interactivePicture.identifier.name}.json"
    link.click()
}

fun downloadInteractiveHtml(name: String, content: String) {
    val blob = Blob(arrayOf(content), impl { type = "text/plain" })
    val url = URL.Companion.createObjectURL(blob)
    val link = document.createElement("a") as HTMLAnchorElement
    link.href = url
    link.download = "${name}.html"
    link.click()
}
