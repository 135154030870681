package online.interactiver.common.admin.interactive.phrase

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PhraseWithSoundSourceRequest(
    @SerialName("phrase")
    val phrase: String?,
    @SerialName("from_dictionary")
    val fromDictionary: Boolean,
    @SerialName("from_google_text_to_speech")
    val fromGoogleTextToSpeech: Boolean,
    @SerialName("material_language")
    val materialLanguage: String
)