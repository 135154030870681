package entities.interactivePicture.elements.figuresAndLines

import entities.interactivePicture.elements.CommonInteractiveElementAction
import entities.interactivePicture.elements.CommonListReducer
import entities.interactivePicture.elements.InteractiveElementAction
import entities.interactivePicture.elements.figuresAndLines.lines.LinesAction
import entities.interactivePicture.elements.figuresAndLines.lines.LinesReducer
import entities.interactivePicture.elements.figuresAndLines.marker.MarkerAction
import entities.interactivePicture.elements.figuresAndLines.marker.MarkerReducer
import entities.interactivePicture.elements.figuresAndLines.static.StaticsAction
import entities.interactivePicture.elements.figuresAndLines.static.StaticsReducer
import online.interactiver.common.interactivepicture.InteractiveElement

val FiguresAndLinesReducer = { state: MutableList<InteractiveElement>, action: InteractiveElementAction ->
    when (action) {
        is CommonInteractiveElementAction -> CommonListReducer(state, action)
        is MarkerAction -> MarkerReducer(state, action)
        is StaticsAction -> StaticsReducer(state, action)
        is LinesAction -> LinesReducer(state, action)
        else -> state
    }
}
