package widgets.CourseMaterials.ui.components.Material

import antd.Button
import antd.Input
import antd.TextArea
import csstype.Cursor
import csstype.px
import emotion.react.css
import enums.EMaterialType
import online.interactiver.common.course.MaterialToGenerateExercises
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLTextAreaElement
import react.*
import react.dom.events.ChangeEvent
import react.dom.events.KeyboardEvent
import react.dom.html.InputType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.textarea
import reactgriddnd.GridItem
import shared.components.Icon
import widgets.CourseMaterials.ui.components.EditAndPlaySound.EditAndPlaySound
import widgets.CourseMaterials.ui.components.UploadImage.UploadImage

external interface MaterialProps : Props {
    var material: MaterialToGenerateExercises
    var onChange: (MaterialToGenerateExercises) -> Unit
    var materialType: EMaterialType
    var onCopyClick: () -> Unit
    var onDeleteClick: () -> Unit
    var index: Int
    var withAudio: Boolean
    var rowHeight: Int
}

val Material = FC<MaterialProps> { props ->
    val (material, setMaterial) = useState(props.material)

    useEffect(props.material.hashCode()) {
        setMaterial(props.material)
    }

    fun handleOnBlur() {
        props.onChange(material)
    }

    val isSentences = props.materialType == EMaterialType.SENTENCES

    GridItem {
        key = material.id.toString()
        css {
            cursor = Cursor.grab
        }
        div {
            css(materialCss(props.rowHeight))
            div {
                css(number)
                +"${props.index + 1}."
            }
            TextArea {
                css(textArea(isSentences))
                value = material.value
                onChange = { evt ->
                    setMaterial(material.copy(value = evt.currentTarget.value.replace("\n", " ")))
                }
                onPressEnter = { evt: KeyboardEvent<HTMLTextAreaElement> ->
                    evt.stopPropagation()
                    evt.currentTarget.blur()
                }
                onMouseDown = {
                    it.stopPropagation()
                }
                placeholder = if (isSentences) "Sentences" else "Words"
                onBlur = { handleOnBlur() }
                dir = "auto"
            }
            if (props.withAudio) {
                EditAndPlaySound {
                    soundSrc = material.audioSrc
                    wordForSound = material.value
                    onChange = {
                        props.onChange(material.copy(audioSrc = it))
                    }
                }
            } else {
                TextArea {
                    css(textArea(isSentences))
                    value = material.definition
                    onChange = { evt ->
                        setMaterial(material.copy(definition = evt.currentTarget.value.replace("\n", " ")))
                    }
                    onPressEnter = { evt: KeyboardEvent<HTMLTextAreaElement> ->
                        evt.stopPropagation()
                        evt.currentTarget.blur()
                    }
                    onMouseDown = {
                        it.stopPropagation()
                    }
                    placeholder = "Translation or explanation"
                    onBlur = { handleOnBlur() }
                    dir = "auto"
                }
                if (!isSentences) {
                    UploadImage {
                        pictureSrc = material.imageSrc
                        onChange = {
                            props.onChange(material.copy(imageSrc = it))
                        }
                    }
                }
                Input {
                    css(numberedInput)
                    type = InputType.number
                    value = material.numberOfAppearances.toString()
                    onChange = {
                        it.currentTarget.value.toIntOrNull()?.let { number ->
                            props.onChange(material.copy(numberOfAppearances = number))
                        }
                    }
                    onMouseDown = {
                        it.stopPropagation()
                    }
                    min = 1
                    max = 10
                }
            }
            Button {
                css(settingButton)
                Icon {
                    src = "ic_copy_with_bg_24x24.svg"
                }
                onMouseDown = { it: ChangeEvent<HTMLButtonElement> ->
                    it.stopPropagation()
                }
                onClick = {
                    props.onCopyClick()
                }
            }
            Button {
                css(settingButton)
                Icon {
                    src = "ic_delete_with_bg_24x24.svg"
                }
                onClick = {
                    props.onDeleteClick()
                }
                onMouseDown = { it: ChangeEvent<HTMLButtonElement> ->
                    it.stopPropagation()
                }
            }
            Icon {
                css {
                    minWidth = 24.px
                    minHeight = 24.px
                }
                src = "ic_drag_vertical_15x15.svg"
            }
        }
    }
}