package entities.interactivePicture.elements.editors.elementCodeEditor

import app.useAppSelector
import csstype.FlexDirection
import entities.interactivePicture.elements.editors.textEditor.TextEditor
import entities.interactivePicture.selectFocusedElement
import react.*

external interface ElementCodeEditorProps: Props {
    var value: String?
    var onChange: ((String) -> Unit)?
}

fun useFocusedElementCode(): Pair<String, StateSetter<String>> {
    val focusedElement = useAppSelector(selectFocusedElement)
    val (code, setCode) = useState(focusedElement?.identifier?.code ?: "")
    useEffect(focusedElement?.identifier?.code) {
        setCode(focusedElement?.identifier?.code ?: "")
    }

    return Pair(code, setCode)
}

val ElementCodeEditor = FC<ElementCodeEditorProps> { props ->

    val code = props.value
    TextEditor {
        header = "Element code"
        value = code
        placeholder = "Input code"
        onChange = {
            props.onChange?.invoke(it.target.value)
        }
        height = 35
        width = 100
        flexDirection = FlexDirection.row
        fontWeight = 400
    }
}