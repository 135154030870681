package widgets.ExerciseSettings.components.Warning

import antd.Button
import emotion.react.css
import kotlinx.browser.localStorage
import org.w3c.dom.get
import org.w3c.dom.set
import pages.constructor.ui.components.elements.blueFilter
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.useState
import shared.components.Icon

val Warning = FC<Props> {
    val (showWarning, setShowWarning) = useState(localStorage["exercise_settings_warning"] == null)
    if (!showWarning) {
        return@FC
    }

    div {
        css(warningContainer)
        div {
            css(warning)
            Icon {
                css(blueFilter)
                src = "ic_issue_20x20.svg"
            }
            p {
                css(warningText)
                +"The new settings will only apply to new sliders (old ones that have already been generated will not change)."
            }
        }
        Button {
            css(closeWarningButton)
            onClick = {
                setShowWarning(false)
                localStorage["exercise_settings_warning"] = "1"
            }
            Icon {
                css(blueFilter)
                src = "ic_cross_24x24.svg"
            }
        }
    }
}