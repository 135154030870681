package pages.constructor.ui.components.header.styleSettings.colorPicker

import antd.Button
import antd.Popover
import antd.TextArea
import emotion.react.css
import entities.interactivePicture.elements.colorToRgba
import pages.constructor.ui.components.advancedSettings.controlButtonsColorSettings.testHex
import pages.constructor.ui.components.header.styleSettings.primary
import react.*
import react.dom.html.ReactHTML.div
import shared.components.Icon
import utils.types.impl

val ALL_COLORS = arrayOf(
    "#101828", "#344054", "#98A2B3", "#D0D5DD", "#F2F4F7", "#FFFFFF",
    "#FFCCC7", "#FFD8BF", "#FFF1B8", "#D9F7BE", "#D6E4FF", "#F9F0FF",
    "#FF7875", "#FF9C6E", "#FFD666", "#95DE64", "#ADC6FF", "#D3ADF7",
    "#F5222D", "#FA541C", "#FAAD14", "#52C41A", "#2F54EB", "#722ED1",
    "#A8071A", "#AD2102", "#D48806", "#389E0D", "#1D39C4", "#391085"
)

external interface ColorButtonProps : Props {
    var value: String?
    var color: String
    var onChange: ((String) -> Unit)?
    var noBorderForLines: Boolean?
}

val ColorButton = FC<ColorButtonProps> { props ->
    Button {
        css(
            if (colorToRgba(props.value ?: "", 1.0) == (colorToRgba(
                    props.color, 1.0
                ))
            ) buttonActive(props.color) else button(props.color)
        )
        key = props.color
        onClick = onClick@{
            if (colorToRgba(props.value ?: "", 1.0) == (colorToRgba(props.color, 1.0))) return@onClick
            props.onChange?.invoke(props.color)
        }
        if (props.noBorderForLines == false) {
            this.disabled = true
        }
    }
}

external interface ColorPickerProps : Props {
    var value: String?
    var mainColors: Array<String>?
    var onChange: ((String) -> Unit)?
    var noBorderForLines: Boolean?
}

val ColorButtons = FC<ColorPickerProps> { props ->
    props.mainColors?.map { color ->
        ColorButton {
            this.color = color
            this.value = props.value
            this.onChange = props.onChange
            this.noBorderForLines = props.noBorderForLines
        }
    }
}

val ColorPickerAllColors = FC<ColorPickerProps> { props ->
    val (customColor, setCustomColor) = useState("#F2F4F7")
    val (customInput, setCustomInput) = useState("")

    div {
        css(wrapContainer)
        div {
            css(container)
            div {
                css(primary)
                +"All colors"
            }
            div {
                css(colorsContainer)
                ColorButtons {
                    mainColors = props.mainColors
                    value = props.value
                    onChange = props.onChange
                }
            }

            div {
                css(colorInputContainer)

                TextArea {
                    css(colorInput)
                    onChange = cb@{ eventChangeTitleText ->
                        val newColor = eventChangeTitleText.target.value
                        setCustomInput(newColor)

                        if (!testHex(newColor)) {
                            return@cb
                        }
                        setCustomColor(newColor)
                    }
                    value = customInput
                    placeholder = "HEX Code"
                    status = if (testHex(customInput)) null else "error"
                    spellCheck = false
                }
                div {
                    css(colorInputButton)
                    ColorButton {
                        this.color = customColor
                        this.value = props.value
                        this.onChange = props.onChange
                        this.noBorderForLines = props.noBorderForLines
                    }
                }
            }
        }
    }
}

val ColorPicker = FC<ColorPickerProps> { props ->
    div {
        css(container)
        div {
            css(colorsContainer)
            if (props.noBorderForLines != false) {
                Popover {
                    trigger = "click"
                    align = impl {
                        points = arrayOf("tr", "tl")
                        offset = arrayOf(-20, -72)
                    }
                    arrowPointAtCenter = true
                    content = createElement(ColorPickerAllColors, impl {
                        mainColors = ALL_COLORS
                        value = props.value
                        onChange = props.onChange
                    })
                    this.open = open
                    if (props.noBorderForLines == false) {
                        this.open = false
                    }

                    Button {
                        css(expandButton)
                        Icon {
                            src = "ic_expand_left_24x24.svg"
                        }
                        ghost = true
                    }
                }
            }
            if (props.noBorderForLines == false) {
                Button {
                    css(expandButton)
                    disabled = true
                    Icon {
                        src = "ic_expand_left_24x24.svg"
                    }
                    ghost = true
                }
            }
            ColorButtons {
                mainColors = props.mainColors
                value = props.value
                onChange = props.onChange
                noBorderForLines = props.noBorderForLines
            }
        }
    }
}
