package widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.RawTaskLineEditor

import antd.TextArea
import emotion.react.css
import react.FC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.TaskLineUiEditorProps

val RawTaskLineEditor = FC<TaskLineUiEditorProps> { props ->
    div {
        css(slideInputContainer)
        p {
            css(header)
            +"Slide text"
        }
        TextArea {
            css(textAreaEdit)
            value = props.taskLine
            onChange = { props.onChange?.invoke(it.target.value) }
        }
    }
}
