package pages.constructor.ui.components.header.undoRedoCopyDelete

import antd.Button
import app.*
import app.appState.selectAppState
import emotion.css.ClassName
import emotion.react.css
import entities.interactivePicture.elements.CopyMultipleElements
import entities.interactivePicture.elements.RemoveMultipleElements
import entities.interactivePicture.selectFocusedElement
import entities.selectedElement.SelectElement
import entities.selectedElement.SelectMultipleElements
import entities.selectedElement.selectElementsIdsUnderSelectionRectangle
import enums.EButtonTypes
import io.ktor.util.date.*
import online.interactiver.common.utils.getTimeMillisInBase
import online.interactiver.common.utils.toBase
import pages.constructor.ui.components.elements.grayFilter
import pages.constructor.ui.components.elements.whiteFilter
import react.FC
import react.Props
import react.createElement
import react.dom.html.ReactHTML.div
import shared.components.Icon
import utils.types.buildProps

val UndoRedoCopyDelete = FC<Props> {
    val canUndo = useAppSelector(selectCanUndo)
    val canRedo = useAppSelector(selectCanRedo)

    val focusedElement = useAppSelector(selectFocusedElement)
    val elementsIdsUnderSelectionRectangle = useAppSelector(selectElementsIdsUnderSelectionRectangle)
    val appState = useAppSelector(selectAppState)

    val dispatch = useAppDispatch()

    div {
        css(container)
        Button {
            css(button)
            id = EButtonTypes.UNDO_BUTTON.value
            ghost = true
            disabled = !canUndo
            onClick = {
                dispatch(Undo())
            }
            icon = createElement(Icon, buildProps {
                className = if (canUndo) ClassName(whiteFilter) else ClassName(grayFilter)
                src = "ic_undo_24x24.svg"
            })
        }
        Button {
            css(button)
            id = EButtonTypes.REDO_BUTTON.value
            ghost = true
            disabled = !canRedo
            onClick = {
                dispatch(Redo())
            }
            icon = createElement(Icon, buildProps {
                className = if (canRedo) ClassName(whiteFilter) else ClassName(grayFilter)
                src = "ic_redo_24x24.svg"
            })
        }
        val copyAndDeleteDisabled = focusedElement == null && elementsIdsUnderSelectionRectangle.isEmpty()
        Button {
            css(button)
            id = EButtonTypes.COPY_BUTTON.value
            ghost = true
            disabled = copyAndDeleteDisabled
            onClick = {
                if (focusedElement != null) {
                    val newId = getTimeMillisInBase()
                    dispatch(appState.getCopyElement(focusedElement.identifier.id!!, newId))
                    dispatch(SelectElement(newId))
                }
                if (elementsIdsUnderSelectionRectangle.isNotEmpty()) {
                    val newIds = elementsIdsUnderSelectionRectangle.indices.map { (getTimeMillis() + it).toULong().toBase() }
                    dispatch(CopyMultipleElements(elementsIdsUnderSelectionRectangle, newIds.toTypedArray()))
                    dispatch(SelectMultipleElements(newIds.toTypedArray()))
                }
            }
            icon = createElement(Icon, buildProps {
                className = if (!copyAndDeleteDisabled) ClassName(whiteFilter) else ClassName(grayFilter)
                src = "ic_copy_24x24.svg"
            })
        }
        Button {
            css(button)
            id = EButtonTypes.DELETE_BUTTON.value
            ghost = true
            disabled = copyAndDeleteDisabled
            onClick = {
                if (focusedElement != null) {
                    dispatch(appState.getRemoveElement(focusedElement.identifier.id!!))
                }
                if (elementsIdsUnderSelectionRectangle.isNotEmpty()) {
                    dispatch(SelectMultipleElements(arrayOf()))
                    dispatch(RemoveMultipleElements(elementsIdsUnderSelectionRectangle))
                }
            }
            icon = createElement(Icon, buildProps {
                className = if (!copyAndDeleteDisabled) ClassName(whiteFilter) else ClassName(grayFilter)
                src = "ic_delete_24x24.svg"
            })
        }
    }
}
