package pages.payment.ui.PromoCode

import antd.Button
import antd.Input
import emotion.react.css
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.ELanguage
import pages.payment.getDiscount
import pages.payment.ui.Payment.PromoCodeMeta
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.useState
import utils.types.extend
import widgets.LanguageAutoContent.ui.generateButton

external interface PromoCodeProps : Props {
    var onChange: (PromoCodeMeta?) -> Unit
    var discount: Int?
    var nativeLanguage: String
}

val PromoCode = FC<PromoCodeProps> { props ->
    val nativeLanguage = props.nativeLanguage
    val (promoCode, setPromoCode) = useState("")
    val (isError, setIsError) = useState(false)

    div {
        p {
            css(label)
            +labelPromoCode(nativeLanguage)
        }
        div {
            css(horizontalContainer)
            Input {
                value = promoCode
                onInput = {
                    setPromoCode(it.currentTarget.value)
                }
                placeholder = labelEnterCode(nativeLanguage)
            }
            Button {
                css(generateButton)
                onClick = {
                    GlobalScope.launch {
                        val discount = getDiscount(promoCode)
                        if (discount == null) {
                            props.onChange(null)
                            setIsError(true)
                            return@launch
                        }

                        setIsError(false)
                        props.onChange(PromoCodeMeta(promoCode, discount))
                    }
                }
                +labelApply(nativeLanguage)
            }
        }
        if (isError) {
            p {
                css(label.extend(error))
                +labelInvalidPromoCode(nativeLanguage)
            }
        } else if (props.discount != null) {
            p {
                css(label)
                +"${labelDiscount(nativeLanguage)} ${props.discount}%"
            }
        }
    }
}

private fun labelPromoCode(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) { "Промокод" } else { "Promo code" }
private fun labelEnterCode(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) { "Введите код" } else { "Enter code" }
private fun labelInvalidPromoCode(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) { "Неправильный промокод" } else { "Invalid promo code" }
private fun labelApply(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) { "Применить" } else { "Apply" }
private fun labelDiscount(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) { "Скидка" } else { "Discount" }