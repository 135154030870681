package entities.interactivePicture.elements.other.ui.tools

import app.useAppDispatch
import app.useBackgroundCenteredPosition
import emotion.react.css
import entities.interactivePicture.elements.other.ui.tools.phrase.PhraseTool
import entities.interactivePicture.elements.shared.tools.buttonsGroup
import react.FC
import react.Props
import react.dom.html.ReactHTML.div

val OtherTools = FC<Props> {
    val bgCenteredPos = useBackgroundCenteredPosition()

    val dispatch = useAppDispatch()

    div {
        css(buttonsGroup)
        PhraseTool { }
    }
}
