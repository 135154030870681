package pages.constructor.ui.components.advancedSettings.hintsSettings.hintShowAction

import emotion.react.css
import enums.EHintShowAction
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.components.radio.Radio

external interface HintShowActionEditorProps : Props {
    var showAction: String
    var onChange: (String) -> Unit
}

val HintShowActionEditor = FC<HintShowActionEditorProps> { props ->
    div {
        EHintShowAction.values().forEach {
            div {
                css(container)
                Radio {
                    current = props.showAction
                    value = it.value
                    onChange = { value ->
                        props.onChange(value)
                    }
                }
                div {
                    css(text)
                    +it.label
                }
            }
        }
    }
}