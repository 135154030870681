package pages.duoSettings.duoProfile

import csstype.AlignItems
import csstype.Display
import csstype.FlexDirection
import csstype.px
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    gap = 20.px
    padding = 20.px
}