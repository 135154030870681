package pages.wordsProgress.ui

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    padding = 20.px
    gap = 7.px
    boxSizing = BoxSizing.borderBox
    overflowY = Auto.auto
    height = 100.pct
}

val headerContainer: CssStyle = {
    alignSelf = AlignSelf.center
}
