package entities.errorModal.ui

import Modal
import csstype.px
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import shared.components.Icon

external interface ErrorModalProps : Props {
    var title: String
    var text: String
    var isOpen: Boolean
    var onClose: () -> Unit
}

val ErrorModal = FC<ErrorModalProps> { props ->
    Modal {
        zIndex = 500
        open = props.isOpen
        footer = null
        onCancel = {
            props.onClose()
        }
        div {
            css(container)
            Icon {
                css {
                    width = 40.px
                    height = 40.px
                }
                src = "ic_issue_20x20.svg"
            }
            p {
                css(header)
                +props.title
            }
            p {
                css(description)
                +props.text
            }
        }
    }
}