package widgets.Exercises.ui.components.FolderUserActions.FolderRenamingModal

import Modal
import antd.Input
import app.useAppDispatch
import emotion.react.css
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import online.interactiver.common.autogeneration.SliderAutoGenerationFolder
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useState
import widgets.Exercises.renameFolder

external interface FolderRenamingModalProps : Props {
    var open: Boolean?
    var onClose: (() -> Unit)?
    var folder: SliderAutoGenerationFolder?
    var onFolderRenamed: ((Int) -> Unit)?
}

val FolderRenamingModal = FC<FolderRenamingModalProps> { props ->
    val dispatch = useAppDispatch()

    val (folderName, setFolderName) = useState(props.folder?.name ?: "")

    val placeholder = "My folder"

    if (props.folder == null) {
        return@FC
    }

    Modal {
        open = props.open
        onCancel = {
            props.onClose?.invoke()
        }
        onOk = {
            props.onClose?.invoke()
            dispatch(StartModalLoading("Renaming '${props.folder!!.name}' to '${folderName.ifBlank { placeholder }}'..."))
            MainScope().launch {
                val response = renameFolder(
                    props.folder!!.id,
                    folderName.ifBlank { placeholder }
                )
                dispatch(EndModalLoading())

                if (response.value >= 400) {
                    // we do not reset folder name if failed so user can try again without re-entering a name again
                    console.error("Failed to rename '${props.folder!!.name}' to '${folderName.ifBlank { placeholder }}'.")
                } else {
                    props.onFolderRenamed?.invoke(props.folder!!.id)
                }
            }
        }
        div {
            css(modalBody)
            div {
                css(modalHeader)
                +"Enter a folder name"
            }
            Input {
                value = folderName
                this.placeholder = placeholder
                onChange = {
                    setFolderName(it.target.value)
                }
            }
        }
    }
}
