package shared.components.editableSound

import csstype.*
import utils.types.CssStyle

val soundContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 6.px
}

val soundButton: CssStyle = {
    width = 29.px
    height = 29.px
    padding = 0.px
    display = Display.flex
    justifyContent = JustifyContent.center
    alignItems = AlignItems.center
    borderRadius = 50.pct
}
