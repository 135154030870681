package online.interactiver.common.autogeneration

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class LanguageAutoGeneration(
    @SerialName("request_id")
    val requestId: Int,
    val time: String,
    val name: String,
    @SerialName("content_type")
    val contentType: String?,
    @SerialName("language_level")
    val languageLevel: String,
    @SerialName("is_public")
    val isPublic: Boolean,
    @SerialName("is_user_owns")
    val isUserOwns: Boolean,
    @SerialName("owner_id")
    val ownerId: Int,
    @SerialName("parent_folder_id")
    val parentFolderId: Int?,
    @SerialName("role")
    val role: String? = null,
    val isChosen: Boolean = false,
    val numberInFolder: Int = -1
) {
    fun asFolder(): SliderAutoGenerationFolder = SliderAutoGenerationFolder(
        id = requestId,
        name = name,
        ownerId = ownerId,
        parentFolderId = parentFolderId,
        interactivesGroupId = null,
        role = role
    )

    fun isFolder(): Boolean = contentType == "Folder"

    fun isManuallyUploaded(): Boolean = contentType == "Manually Uploaded"
}
