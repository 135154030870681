package shared.canvas

import app.appState.selectAppState
import app.useAppDispatch
import app.useAppSelector
import entities.interactivePicture.advancedSettings.selectedHintColorSettings
import enums.EHintColor
import online.interactiver.common.interactivepicture.*
import react.FC
import react.Fragment
import react.Props
import react.useEffect
import reactkonva.Group
import reactkonva.Rect
import reactkonva.Text
import utils.getStringWidth
import utils.structures.Position

external interface HintPreviewProps : Props {
    var x: Int
    var y: Int
    var text: String
    var visible: Boolean
    var rect: Rect
    var textStyle: TextStyle?
    var geometryStyle: GeometryStyle?
    var element: InteractiveElement
    var isUnderSelectionRectangle: Boolean
}

const val PADDING = 7
const val FONT_SIZE = 16
const val FONT_FAMILY = "Arial"
const val TEXT_COLOR = "white"
const val ALIGN = "center"
const val LINE_HEIGHT = 1.0
const val FILL_COLOR = "#334054"
const val CORNER_RADIUS = 5
const val OPACITY = 0.99
const val SHADOW_COLOR = "#113275"
const val SHADOW_BLUR = 10
val SHADOW_OFFSET = Point(0,1)
const val SHADOW_OPACITY = 0.15


fun calculateHintShape(text: String, fontSize: Int, font: String, lineHeight : Double): Position {

    val lines = text.split('\n')

    val width: Int = lines.map { line -> getStringWidth(line, fontSize, font) }.sorted().last()
    val height: Int = ((lines.size + 1) * fontSize * lineHeight).toInt()
    val result = Position(10, 10, width + fontSize + PADDING * 2, height)

    return result
}

val HintPreview = FC<HintPreviewProps> { props ->
    val appState = useAppSelector(selectAppState)
    val hintColorSettings = useAppSelector(selectedHintColorSettings)
    val dispatch = useAppDispatch()
    val fontSize = props.textStyle?.fontSize ?: FONT_SIZE
    val fontFamily = props.textStyle?.fontFamily ?: FONT_FAMILY
    val lineHeight = props.textStyle?.lineHeight ?: LINE_HEIGHT
    val position = calculateHintShape(
        props.text,
        fontSize,
        fontFamily,
        lineHeight
    )

    useEffect(props.text, fontSize, props.textStyle?.fontStyle, lineHeight) {
        dispatch(
            appState.getSetHintRect(
                props.element.identifier.id!!, Rect(
                    width = position.width + PADDING * 2,
                    height = position.height + PADDING * 2,
                    leftTopPosition = Position(
                        relativeToMousePosition = Point(
                            x = position.x,
                            y = position.y,
                        )
                    ),
                    verticalAlign = props.element.hint.geometry?.rect?.verticalAlign
                )
            )
        )
    }
    val hintColor = when (hintColorSettings){
        EHintColor.BLACK.value -> EHintColor.BLACK.color
        EHintColor.WHITE.value -> EHintColor.WHITE.color
        else -> FILL_COLOR
    }

    val hintTextColor = when (hintColorSettings){
        EHintColor.BLACK.value -> EHintColor.BLACK.textColor
        EHintColor.WHITE.value -> EHintColor.WHITE.textColor
        else -> TEXT_COLOR
    }

    if (props.isUnderSelectionRectangle) {
        return@FC
    }

    Fragment {
        if (props.visible) {
            Group {
                x = props.rect.leftTopPosition!!.absolutePosition!!.x + position.x + props.rect.width!! + props.x
                y = props.rect.leftTopPosition!!.absolutePosition!!.y + props.y
                Rect {
                    fill = hintColor
                    cornerRadius = props.geometryStyle?.cornerRadius ?: CORNER_RADIUS
                    width = position.width + PADDING * 2
                    height = position.height + PADDING * 2
                    opacity = props.geometryStyle?.opacity ?: OPACITY
                    shadowColor = props.geometryStyle?.shadowColor ?: SHADOW_COLOR
                    shadowBlur = props.geometryStyle?.shadowBlur ?: SHADOW_BLUR
                    shadowOffsetX = props.geometryStyle?.shadowOffset?.x ?: SHADOW_OFFSET.x
                    shadowOffsetY = props.geometryStyle?.shadowOffset?.y ?: SHADOW_OFFSET.y
                    shadowOpacity = props.geometryStyle?.shadowOpacity ?: SHADOW_OPACITY
                }
                Text {
                    x = PADDING
                    y = PADDING
                    width = position.width
                    height = position.height
                    text = props.text
                    this.fontSize = fontSize
                    this.fontFamily = fontFamily
                    fill = hintTextColor
                    align = props.textStyle?.align ?: ALIGN
                    verticalAlign = "middle"
                    padding = props.textStyle?.padding ?: PADDING
                    fontStyle = props.textStyle?.fontStyle ?: "normal"
                    textDecoration = props.textStyle?.textDecoration ?: "none"
                    this.lineHeight = lineHeight
                }
            }
        }
    }
}
