package widgets.LanguageAutoContent.ui.components.ContentCard

import csstype.*
import utils.phones
import utils.types.CssStyle
import utils.types.FitContent
import utils.types.shadowArray

val card: CssStyle = {
    cursor = Cursor.pointer
    display = Display.flex
    flexDirection = FlexDirection.column
    padding = 16.px
    width = 174.px
    height = 180.px
    gap = 12.px
    borderRadius = 12.px
    boxSizing = BoxSizing.borderBox
    backgroundColor = Color("white")
    boxShadow = shadowArray(
        BoxShadow(0.px, 3.px, 14.px, (-1).px, Color("#32324717")),
        BoxShadow(0.px, 0.px, 0.5.px, 0.px, Color("#0C1A4B3D"))
    )
    phones {
        width = 134.px
    }
}

val textContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
}

val headerContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 6.px
}

val iconContainer: CssStyle = {
    width = 100.pct
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
}

val beta: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    padding = Padding(2.px, 8.px)
    borderRadius = 8.px
    backgroundColor = Color("#597EF7")
    fontSize = 10.px
    lineHeight = 13.px
    color = Color("white")
    fontWeight = integer(500)
    margin = 0.px
    height = FitContent.fitContent
}