package widgets.ExerciseSettings.components.InputWithCustomLink

import antd.Input
import emotion.react.css
import org.w3c.dom.HTMLInputElement
import react.FC
import react.Props
import react.dom.events.KeyboardEvent
import react.useEffect
import react.useState
import utils.types.extend
import widgets.ExerciseSettings.components.SettingLabel.label

external interface InputWithCustomLinkProps : Props {
    var value: String
    var onChange: (String) -> Unit
}

val InputWithCustomLink = FC<InputWithCustomLinkProps> { props ->
    val (value, setValue) = useState(props.value)

    useEffect(props.value) {
        setValue(props.value)
    }

    Input {
        css(input.extend(label))
        this.value = value
        onInput = {
            setValue(it.currentTarget.value)
        }
        onBlur = {
            props.onChange(value)
        }
        onPressEnter = { evt: KeyboardEvent<HTMLInputElement> ->
            evt.currentTarget.blur()
        }
    }
}