package shared.components.checkboxWithLabelAndComment

import antd.Tooltip
import emotion.react.css
import entities.interactivePicture.elements.other.ui.tools.phrase.tooltipButton
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import shared.components.Icon
import shared.components.checkbox.ButtonColor
import shared.components.checkbox.Checkbox

external interface CheckboxWithLabelAndCommentProps : Props {
    var disabled: Boolean?
    var checked: Boolean
    var onChange: (Boolean) -> Unit
    var id: String
    var label: String
    var tooltipTitle: String?
    var comment: String
    var iconFilter: ButtonColor?
    var height: Int?
    var width: Int?
}

val CheckboxWithLabelAndComment = FC<CheckboxWithLabelAndCommentProps> { props ->
    div {
        css(checkboxContainer)
        Checkbox {
            id = props.id
            disabled = props.disabled
            checked = props.checked
            onChange = props.onChange
            iconFilter = props.iconFilter
            height = props.height
            width = props.width
        }
        div {
            css(checkboxText)
            +props.label
        }
        div {
            css(checkboxText)
            +props.comment
        }
        if (props.tooltipTitle != null){
            Tooltip {
                title = props.tooltipTitle
                placement = "topLeft"
                ReactHTML.button {
                    css(tooltipButton)
                    Icon {
                        src = "ic_tooltip_18x18.svg"
                    }
                }
            }
        }
    }
}