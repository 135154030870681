package pages.constructor.ui.components.advancedElementsSettings.advancedStaticSettings

import csstype.*
import utils.types.CssStyle

val settingContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
    paddingLeft = 20.px
}

val settingHeader: CssStyle = {
    fontFamily = string("\"Inter\", system-ui")
    fontWeight = FontWeight.normal
    fontSize = 12.px
    lineHeight = 18.px
    letterSpacing = (-0.002).em
    color = Color("#2E2E2E")
    margin = 0.px
    width = 100.px
}

val input: CssStyle = {
    resize = important(None.none)
    width = important(200.px)
    borderRadius = 3.px
    fontFamily = string("\"Inter\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 12.px
    lineHeight = important(14.px)
    color = Color("#2E2E2E")
    padding = Padding(9.5.px, 6.px)
}
