package widgets.Exercises.ui.components.FolderUserActions.FolderMovingModal

import online.interactiver.common.autogeneration.SliderAutoGenerationFolder
import react.FC
import react.Props

external interface FolderMovingModalProps : Props {
    var open: Boolean?
    var onClose: (() -> Unit)?
    var folder: SliderAutoGenerationFolder?
    var onFolderMoved: ((Int?) -> Unit)?
}

val FolderMovingModal = FC<FolderMovingModalProps> { props ->
    MovingModal {
        open = props.open
        onClose = props.onClose
        generations = listOf(props.folder?.asExercise())
        onMoved = props.onFolderMoved
    }
}
