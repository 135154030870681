package widgets.LanguageAutoTask.ui.components.SlidesDragAndDrop

import csstype.*
import utils.types.CssStyle

val buttonsContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 8.px
}

val settingButton: CssStyle = {
    padding = 0.px
    backgroundColor = Color("white")
    boxShadow = None.none
    border = None.none
}

val settingContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
    width = 100.pct
}

val slideImage: (String) -> CssStyle = {
    {
        backgroundImage = url(it)
        backgroundSize = BackgroundSize.cover
        width = 100.pct
        height = 218.px
    }
}

val slideCss: CssStyle = {
    width = 213.px
    height = 250.px
}

val slideContainer: CssStyle = {
    width = 95.pct
    height = 242.px
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 8.px
    marginBottom = 8.px
}
