package entities.interactivePicture.viewer

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    height = 100.px
    width = 100.pct
    display = Display.flex
    flexDirection = FlexDirection.row

    border = Border(1.px, LineStyle.solid, Color("#D7D7D7"))
    borderRadius = 3.px
}


val elementContainer: CssStyle = {
    height = 100.px
    width = 100.pct
    boxSizing = BoxSizing.borderBox
    backgroundColor = Color("#FFFFFF")
    padding = Padding(15.px, 20.px)

    display = Display.flex
    flexWrap = FlexWrap.wrap
    columnGap = 20.px
    rowGap = 10.px
    placeItems = PlaceItems.stretch

    overflowY = Auto.auto
}

val puzzleContainer: CssStyle = {
    height = 100.px
    width = 100.pct
    boxSizing = BoxSizing.borderBox
    backgroundColor = Color("#FFFFFF")
    padding = Padding(15.px, 20.px)

    display = Display.grid
    gridTemplateColumns = repeat(6, 1.fr)
    gridTemplateRows = repeat(1, 1.fr)
    columnGap = 20.px
    rowGap = 10.px
    placeItems = PlaceItems.stretch

    overflowY = Auto.auto
}

val button: CssStyle = {
    borderRadius = 5.px
    backgroundColor = Color("#EBEDEE")

    fontFamily = string("\"Inter\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(600)
    lineHeight = 125.pct

    display = Display.block
    whiteSpace = WhiteSpace.normal
    textAlign = TextAlign.center

    height = 60.px
    minWidth = 100.px

    transition = None.none
}

val buttonSelected: CssStyle = {
    borderWidth = 3.px
    borderStyle = LineStyle.solid
    borderColor = important(Color("4E65D9"))
    borderRadius = 5.px
    backgroundColor = Color("#EBEDEE")

    fontFamily = string("\"Inter\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(600)
    lineHeight = 125.pct

    display = Display.block
    whiteSpace = WhiteSpace.normal
    textAlign = TextAlign.center

    height = 60.px
    minWidth = 100.px

    transition = None.none
}

val filterContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column

    padding = 10.px
    paddingRight = 0.px

    gap = 4.px

    width = 100.px - 20.px
    height = 100.pct - 20.px

    background = Color("#FFFFFF")
}

val filterButton: CssStyle = {
    height = 24.px
    width = Globals.inherit // better than copying container width

    borderRadius = 2.px

    background = Color("#F9F5F2")

    fontFamily = string("\"Inter\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 12.px
    lineHeight = 12.px

    color = Color("#333333")

    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween

    whiteSpace = WhiteSpace.normal
    textAlign = TextAlign.left

    padding = 0.px
    paddingLeft = 6.px
}
