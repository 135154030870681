package pages.constructor.ui.components.tools

import emotion.react.css
import entities.interactivePicture.background.ui.tools.BackgroundTools
import pages.constructor.ui.components.advancedSettings.AdvancedSettingsModal
import pages.constructor.ui.components.copyrightLabel.CopyrightLabel
import pages.constructor.ui.components.elements.Elements
import react.*
import react.dom.html.ReactHTML.div

val Tools = FC<Props> {

    div {
        css(toolsContainer)

        BackgroundTools { }
        div {
            css(panel)
            Elements { }
        }

        // Components below have position: absolute
        AdvancedSettingsModal {}
        CopyrightLabel {
            year = 2023
        }
    }
}
