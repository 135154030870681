package widgets.LanguageAutoContent.ui.components.Content.ListeningContent

import antd.Button
import app.useAppDispatch
import csstype.Color
import csstype.important
import emotion.react.css
import entities.languageAutoCurrentRequest.SetCurrentRequestSoundSrc
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useState
import utils.types.extend
import widgets.LanguageAutoContent.ui.components.Content.ListeningContent.components.FromAudio.FromAudio
import widgets.LanguageAutoContent.ui.components.Content.ListeningContent.components.FromReadyText.FromReadyText
import widgets.LanguageAutoContent.ui.components.Content.ListeningContent.components.FromTopic.FromTopic
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton

enum class EListeningType(val value: String, val uiValue: String) {
    FROM_TOPIC("From topic", "From the Topic"),
    FROM_AUDIO("From audio", "From the Audio"),
    FROM_READY_TEXT("From ready text", "From Ready Text")
}

val ListeningContent = FC<Props> {
    val dispatch = useAppDispatch()
    val (listeningType, setListeningType) = useState<EListeningType>()

    div {
        css(generateTextForListeningContainer)
        EListeningType.entries.forEach {
            Button {
                css(newTaskButton.extend {
                    if (listeningType != it) {
                        color = Color("#5d6676")
                        hover {
                            color = important(Color("#344054"))
                        }
                    }
                })
                onClick = {
                    setListeningType(it)
                    // if we change listeningType we might not be able to change audio, but we can change text.
                    // Then we don't want to save this sound to unrelated text when we generate new slider
                    if (it != listeningType) {
                        dispatch(SetCurrentRequestSoundSrc(null))
                    }
                }
                +it.uiValue
            }
        }
    }

    when (listeningType) {
        EListeningType.FROM_AUDIO -> FromAudio {}
        EListeningType.FROM_READY_TEXT -> FromReadyText {}
        EListeningType.FROM_TOPIC -> FromTopic {}
        else -> {}
    }
}
