package pages.duoSettings.ui

import csstype.*
import utils.types.CssStyle
import utils.types.shadowArray

val page: CssStyle = {
    width = 100.pct
    maxWidth = 605.px
    boxSizing = BoxSizing.borderBox
    backgroundColor = Color("white")
    minHeight = 90.vh
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
    borderRadius = 12.px
    boxShadow = shadowArray(
        BoxShadow(0.px, 3.px, 18.px, (-1).px, Color("#32324717")), BoxShadow(0.px, 0.px, 1.px, 0.px, Color("#0C1A4B3D"))
    )
}

val buttonsContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    gap = 20.px
    padding = 20.px
}
