package pages.constructor.ui.components.copyrightLabel

import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import shared.components.Icon

external interface CopyrightLabelProps: Props {
    var year: Int
}

val CopyrightLabel = FC<CopyrightLabelProps> { props ->
    div {
        css(label)
        Icon {
            css(icon)
            src = "ic_copyright_24x24.svg"
        }

        span {
            css(text)
            +"${props.year} Interactiver Beta"
        }
    }
}