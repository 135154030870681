package online.interactiver.common.interactivepicture

import kotlinx.serialization.Serializable
import online.interactiver.common.api.RequestParams
import online.interactiver.common.utils.Copyable

@Serializable
data class PopupSettings(
    var showPopup: Boolean = true,
    var popupStyle: String = "Random",
    var isDefaultMessageUsed: Boolean = true,
    var title: String = "Congrats!",
    var subtitle: String = "Your solution is correct",
) : Copyable<PopupSettings> {
    override fun clone() = copy()
}

@Serializable
data class CheckSettings(
    var popupSettings: PopupSettings = PopupSettings(),
    val controlButtonsSettings: ControlButtonsSettings = ControlButtonsSettings(),
    val scoredPointsSettings: ScoredPointsSettings = ScoredPointsSettings(),
    // maybe add checkPatternType in elements later? //for special types requires immediately verification
    //var verificationMechanics: VerificationMechanics? = null, // may be later
    var instantCheck: Boolean = false, // check after interacting with element
) : Copyable<CheckSettings> {
    override fun clone() = copy()
}

@Serializable
data class AnalyticsSettings(
    var enableMetrics: Boolean = true, // collecting and sending data about solving the interactive task
) : Copyable<AnalyticsSettings> {
    override fun clone() = copy()
}

@Serializable
data class ButtonsSettings(
    var radioButtonMode: Boolean = false,
    var addCheckbox: Boolean = false,
    var showUnselectedStates: Boolean = false,
) : Copyable<ButtonsSettings> {
    override fun clone() = copy()
}

@Serializable
data class HintsSettings(
    var showAction: String = "Hover", // "Hover", "Click" when hints should be displayed
    var hintColor: String = "Black", // "Black", "White" style of hints
) : Copyable<HintsSettings> {
    override fun clone() = copy()
}

@Serializable
data class PuzzlesSettings(
    var shufflePuzzles: Boolean = true, // reshuffle puzzles on each interactive refresh
    var mobileDragDropAction: String = "Drop to selected",
    var desktopDragDropAction: String = "Drag&Drop",
    var puzzlesOnPanel: Boolean = true,
    var bottomPadding: Int = 0,
    var widthPadding: Int = 5,
    var elementsAlign: String = "Left"
) : Copyable<PuzzlesSettings> {
    override fun clone() = copy()
}

@Serializable
data class ControlButtonsSettings(
    val checkButtonText: String = "CHECK",
    val restartButtonText: String = "RESTART",
    var enableCheckButton: Boolean = true,
    var enableRestartButton: Boolean = true,
    var color: String = "#181818"
)
@Serializable
data class ScoredPointsSettings(
    val scorePointsType: String = "Points",
    val pointsToSuccess: Int = 0,

)

@Serializable
data class TaskType(
    val language: String = "English",
    val platform: String = "Desktop",
): Copyable<TaskType> {
    override fun clone(): TaskType = copy()
    override fun toString(): String {
        return "$language ($platform)"
    }
}

@Serializable
data class SelectorsSettings(
    val autoClose: Boolean = true,
) : Copyable<SelectorsSettings> {
    override fun clone(): SelectorsSettings = copy()
}

@Serializable
data class AdvancedSettings(
    val taskType: TaskType = TaskType(),
    var checkSettings: CheckSettings = CheckSettings(),
    var requestSettings: RequestParams = RequestParams(),
    var analyticsSettings: AnalyticsSettings = AnalyticsSettings(),
    // elements settings
    var puzzleSettings: PuzzlesSettings = PuzzlesSettings(),
    var buttonSettings: ButtonsSettings = ButtonsSettings(),
    var selectorsSettings: SelectorsSettings = SelectorsSettings(),
    // val inputsSettings: InputsSettings? = null,
    var hintsSettings: HintsSettings = HintsSettings(),

    ) : Copyable<AdvancedSettings> {
    override fun clone() = AdvancedSettings(
        taskType = taskType.clone(),
        checkSettings = checkSettings.clone(),
        requestSettings = requestSettings.clone(),
        analyticsSettings = analyticsSettings.clone(),
        puzzleSettings = puzzleSettings.clone(),
        buttonSettings = buttonSettings.clone(),
        selectorsSettings = selectorsSettings.clone(),
        hintsSettings = hintsSettings.clone()
    )
}
