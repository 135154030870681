package widgets.LanguageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.MarkTrueFalseTaskLineEditor.MarkThreeTrueFalseTaskLineEditor

import react.FC
import widgets.LanguageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.MarkTrueFalseTaskLineEditor.MarkTrueFalseTaskLineEditor
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.TaskLineUiEditorWithSpecifiedPatternProps

val MarkThreeTrueFalseTaskLineEditor = FC<TaskLineUiEditorWithSpecifiedPatternProps> { props ->
    MarkTrueFalseTaskLineEditor {
        statementsCount = 3

        taskLine = props.taskLine
        onChange = props.onChange
        onPatternChange = props.onPatternChange
        slide = props.slide
        onEditSoundClick = props.onEditSoundClick

        maxWordLength = props.maxWordLength
        maxMatchLength = props.maxMatchLength

        maxPhraseLengthWithTranslation = props.maxPhraseLengthWithTranslation
        maxPhraseLengthWithoutTranslation = props.maxPhraseLengthWithoutTranslation
        maxTranslationLength = props.maxTranslationLength
    }
}