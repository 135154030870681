package widgets.CourseGenerationType.ui.TopicAndWordsType

import antd.Option
import antd.Select
import app.useAppDispatch
import online.interactiver.common.enums.EContentForm
import react.FC
import react.Props
import react.redux.useSelector
import widgets.CourseGenerationType.ui.GenerateMaterialsBasedOnTopicButton.GenerateMaterialsBasedOnTopicButton
import widgets.CourseMaterials.SetCourseContentForm
import widgets.CourseMaterials.SetCourseText
import widgets.CourseMaterials.selectCourseContentForm
import widgets.CourseMaterials.selectCourseText
import widgets.LanguageAutoContent.ui.components.Content.TextAreaWithLengthRestriction

val TopicAndWordsType = FC<Props> {
    val topic = useSelector(selectCourseText)
    val contentForm = useSelector(selectCourseContentForm)

    val dispatch = useAppDispatch()

    TextAreaWithLengthRestriction {
        label = "Your topic and any details"
        value = topic
        placeholder = "Your course data in any format"
        onChange = { dispatch(SetCourseText(it)) }
    }

    Select {
        value = contentForm.uiValue
        onChange = { _, option ->
            EContentForm.fromString(option.label.toString())?.let {
                dispatch(SetCourseContentForm(it))
            }
        }
        EContentForm.entries.forEach { option ->
            Option {
                label = option.value
                value = option.uiValue
            }
        }
    }

//    div {
//        css(contentInputContainer)
//        Header {
//            text = "Topic"
//            fontSize = 14.px
//            lineHeight = 18.9.px
//            fontWeight = integer(600)
//        }
//        Input {
//            value = topic
//            placeholder = "Type topic"
//            onChange = { dispatch(SetCourseText(it.target.value)) }
//        }
//    }

    GenerateMaterialsBasedOnTopicButton {}
}
