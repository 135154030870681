package pages.constructor.ui.components.header.styleSettings.colorPicker

import csstype.*
import utils.inject
import utils.types.CssStyle

val wrapContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column

    borderRadius = 5.px
    padding = 8.px
}

val container: CssStyle = {
    display = Display.inlineFlex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.flexStart
}

val colorsContainer: CssStyle = {
    display = Display.grid
    gridTemplateColumns = repeat(6, minmax(0.px, 1.fr))
    gap = 8.px
    alignContent = AlignContent.center
    justifyContent = JustifyContent.center
    marginTop = 5.px
    marginBottom = 8.px
}

val button: (String) -> CssStyle = { color ->
    {
        display = Display.block
        width = 24.px
        height = 24.px
        padding = 0.px
        backgroundColor = Color(color)
        border = Border(0.7.px, LineStyle.solid, Color("#D0D5DD"))
        borderRadius = 3.px
        zIndex = integer(2) // to prevent Popover's arrow overlapping
    }
}

val buttonActive: (String) -> CssStyle = { color ->
    {
        inject(button(color))
        border = Border(1.px, LineStyle.solid, Color("#FFFFFF"))
        outline = Outline(1.px, LineStyle.solid, Color("#597EF7"))
    }
}

val expandButton: CssStyle = {
    display = Display.flex
    justifyContent = JustifyContent.center
    alignItems = AlignItems.center
    padding = 0.px
    margin = 0.px
    width = 24.px
    height = 24.px
    borderRadius = 3.px
    border = Border(0.7.px, LineStyle.solid, Color("#D0D5DD"))
}

val colorInputContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row

    gap = 8.px
    marginBottom = 5.px
}

val colorInput: CssStyle = {
    height = important(24.px)
    width = 152.px
    resize = important(None.none)
    backgroundColor = Color("#FFFFFF")
    border = Border(1.px, LineStyle.solid, Color("#D0D5DD"))
    borderRadius = 5.px

    fontFamily = string("Arial")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 14.px
    lineHeight = 17.px

    color = Color("#282F3E")
}

val colorInputButton: CssStyle = {
    marginBottom = Auto.auto
    marginTop = Auto.auto
}