package entities.selectedElement

import app.StoreState
import redux.RAction

open class SelectedElementIdAction : RAction
data class SelectElement(val id: String?) : SelectedElementIdAction()
data class SelectMultipleElements(val ids: Array<String>) : SelectedElementIdAction()

val SelectedElementIdReducer = { state: String?, action: SelectedElementIdAction ->
    when (action) {
        is SelectElement -> action.id
        else -> state
    }
}

val MultiSelectedElementsIdsReducer = { state: Array<String>, action: SelectedElementIdAction ->
    when (action) {
        is SelectMultipleElements -> action.ids
        else -> state
    }
}

val selectSelectedElementId = { state: StoreState -> state.selectedElementId }
val selectElementsIdsUnderSelectionRectangle = { state: StoreState -> state.multiSelectedIds }
