package widgets.LanguageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.TextToLearnTaskLineEditor

import csstype.TextAlign
import csstype.px
import emotion.react.css
import online.interactiver.common.autogeneration.TaskLine
import online.interactiver.common.autogeneration.taskLineContent.TextToLearnContent
import online.interactiver.common.interactiveexercise.ExerciseUiPattern
import react.FC
import react.createElement
import react.dom.html.ReactHTML.div
import react.useMemo
import shared.components.inputLabelledLimited.InputLabelledLimited
import shared.components.inputLabelledLimited.InputType
import utils.types.jsObject
import widgets.LanguageAutoContent.ui.components.Content.maxContentText
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.TaskLineUiEditorWithSpecifiedPatternProps

val TextToLearnTaskLineEditor = FC<TaskLineUiEditorWithSpecifiedPatternProps> { props ->
    val taskLine = useMemo(props.taskLine, callback = {
        try {
            TaskLine(props.taskLine)
        } catch(e: TaskLine.Error) {
            null
        }
    })

    val content = useMemo(taskLine, callback = {
        taskLine?.content as? TextToLearnContent
    })

    val pattern = useMemo(taskLine) {
        taskLine?.pattern
    }

    if (taskLine == null || content == null || pattern == null) {
        return@FC
    }

    InputLabelledLimited {
        css(textarea)
        placeholder = "Text"
        label = "Text"
        value = content.text
        onChange = {
            val newContent = TextToLearnContent(it)

            taskLine.content = newContent
            props.onChange?.invoke(taskLine.toRawTaskLine())
        }
        limit = maxContentText
        inputType = InputType.TEXTAREA

        tooltip = createElement(FC {
            div {
                css {
                    textAlign = TextAlign.justify
                    marginBottom = 12.px
                }
                +"Improve the text by changing it."
            }
        })
        tooltipOverlayStyle = jsObject { maxWidth = "500px" }
        soundSrc = content.soundSrc
        onEditSoundClick = {
            props.onEditSoundClick?.invoke(content.text)
        }

        onPatternMetaTagsChange = {
            val newPattern = ExerciseUiPattern(pattern.type, it)
            taskLine.pattern = newPattern
            props.onPatternChange?.invoke(taskLine.toRawTaskLine())
        }
    }
}