package entities.solvedTasks

import entities.solvedTasks.ui.DaysWithTasks
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import jsonClient
import kotlinx.browser.window
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import utils.getToken

suspend fun getDaysWithTasksRequest(): DaysWithTasks? {
    return try {
        val response = jsonClient.get("/attempts_log/tasks_days/${window.localStorage["userLogId"]}") {
            bearerAuth(getToken())
        }
        if (response.status.value != 200) {
            return null
        }

        Json.decodeFromString(response.bodyAsText())
    } catch (e: ResponseException) {
        null
    }
}