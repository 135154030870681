package pages.constructor.ui.components.header.interactiveParams.selectors

import antd.Select
import app.useAppDispatch
import emotion.react.css
import entities.previewAndExport.SetInteractiveGroupOnServer
import online.interactiver.common.admin.interactive.InteractivesGroup
import pages.constructor.ui.components.header.interactiveParams.enums.EParamPopupState
import react.FC
import react.Props
import react.dom.html.ReactHTML
import utils.types.jsObject

external interface GroupSelectorProps : Props {
    var groups: List<InteractivesGroup>?
    var curGroup: Int
    var state: EParamPopupState
}

val GroupSelector = FC<GroupSelectorProps> { props ->
    val dispatch = useAppDispatch()
    val curGroup = props.groups?.find { it.id == props.curGroup }

    val onSelectGroupValue = c@{ _: String, option: dynamic ->
        val value = option.value
        dispatch(SetInteractiveGroupOnServer(value))
        return@c
    }

    ReactHTML.div {
        css(container)
        ReactHTML.div {
            css(header)
            +"Folders"
        }

        Select {
            css(select)
            showArrow = true
            size = "middle"

            value = curGroup?.name

            status = if (props.state == EParamPopupState.OPEN_INPUT_CHECK) "error" else null

            placeholder = "Choose folder"

            this.options = props.groups?.map {
                jsObject {
                    this.value = it.id ?: 0
                    this.label = it.name
                }
            }?.toTypedArray()

            onSelect = onSelectGroupValue
        }
    }
}