package shared.canvas


import app.StoreState
import app.appState.selectAppState
import app.useAppDispatch
import app.useAppSelector
import builders.enums.EElementType
import builders.enums.EInteractiveType
import entities.interactivePicture.elements.DragPhrase
import entities.interactivePicture.elements.TransformElementGapPuzzle
import entities.interactivePicture.guides.ObjectSnappingEdges
import entities.interactivePicture.selectFocusedElement
import entities.selectedElement.SelectElement
import entities.selectedElement.selectElementsIdsUnderSelectionRectangle
import online.interactiver.common.interactivepicture.InteractiveElement
import react.FC
import react.MutableRefObject
import react.Props
import react.key
import shared.canvas.implementations.DraggablePhrase
import shared.canvas.interfaces.isDraggable
import shared.canvas.utils.selectElements

external interface ElementCanvasProps : Props {
    var selector: (StoreState) -> MutableList<InteractiveElement>?
    var horizontalRef: MutableRefObject<dynamic>
    var verticalRef: MutableRefObject<dynamic>
    var parentTrRef: MutableRefObject<dynamic>
    var guidesLines:  MutableList<ObjectSnappingEdges>
}


val ElementCanvas = FC<ElementCanvasProps> { props ->
    val elements = useAppSelector(props.selector)
    val selected = useAppSelector(selectFocusedElement)
    val elementsUnderSelectionRectangle = useAppSelector(selectElementsIdsUnderSelectionRectangle)
    val appState = useAppSelector(selectAppState)
    val dispatch = useAppDispatch()

    sortSelectedToEnd(elements, selected)?.map { element ->
        val isFocused = selected?.identifier?.id == element.identifier.id!!
        val isUnderSelectionRectangle = element.identifier.id in elementsUnderSelectionRectangle

        val onSelect = f@{ isShiftPressed: Boolean ->
            selectElements(
                isShiftPressed,
                element,
                selected,
                elementsUnderSelectionRectangle,
                isFocused,
                isUnderSelectionRectangle,
                dispatch,
                appState
            )
        }

        // TODO: Избавиться от IF
        if (element.visibleElement.type.equals(EElementType.LINE_STATIC.text) ||
            element.visibleElement.type.equals(EElementType.ARROW_STATIC.text)
        ) {
            // TODO: Завернуть в Transformable
            LineTransformable {
                this.guidesLines = props.guidesLines
                horizontalRef = props.horizontalRef
                verticalRef = props.verticalRef
                parentTrRef = props.parentTrRef
                data = element
                key = element.identifier.id
                curve = element.visibleElement.geometry!!.curve!!
                style = element.visibleElement.style
                isSelected = isFocused
                this.isUnderSelectionRectangle = isUnderSelectionRectangle
                this.onSelect = onSelect
                onChange = { curve -> dispatch(appState.getTransformElementCurve(element.identifier.id!!, curve)) }
            }
        } else if (element.isDraggable()) {
            if (element.gapPuzzle?.customPuzzleInitialPosition == null) {
                Transformable {
                    this.guidesLines = props.guidesLines
                    horizontalRef = props.horizontalRef
                    verticalRef = props.verticalRef
                    parentTrRef = props.parentTrRef
                    key = element.identifier.id
                    data = element
                    drawable =
                        EElementType.values().find { type -> type.text == element.visibleElement.type }!!.drawable
                    isSelected = isFocused
                    this.isUnderSelectionRectangle = isUnderSelectionRectangle
                    this.onSelect = onSelect
                    onChange = { geometry ->
                        dispatch(TransformElementGapPuzzle(element.identifier.id!!, geometry))
                        dispatch(appState.getTransformElement(element.identifier.id!!, geometry))
                    }
                }
            } else {
                TransformableGapPuzzleAdvanced {
                    this.guidesLines = props.guidesLines
                    horizontalRef = props.horizontalRef
                    verticalRef = props.verticalRef
                    parentTrRef = props.parentTrRef
                    key = element.identifier.id
                    data = element
                    isSelected = isFocused
                    this.isUnderSelectionRectangle = isUnderSelectionRectangle
                    this.onSelect = onSelect
                }
            }
        } else if (element.type.equals(EInteractiveType.PHRASE_INTERACTIVE.text)) {
            DraggablePhrase {
                key = element.identifier.id
                this.element = element
                horizontalRef = props.horizontalRef
                verticalRef = props.verticalRef
                isSelected = isFocused
                this.isUnderSelectionRectangle = isUnderSelectionRectangle
                this.guidesLines = props.guidesLines
                this.onSelect = {
                    dispatch(SelectElement(element.identifier.id!!))
                }
                this.onDragEnd = {
                    dispatch(DragPhrase(element.identifier.id!!, it))
                }
            }
        } else {
            Transformable {
                this.guidesLines = props.guidesLines
                horizontalRef = props.horizontalRef
                verticalRef = props.verticalRef
                parentTrRef = props.parentTrRef
                key = element.identifier.id
                data = element
                drawable = EElementType.values().find { type -> type.text == element.visibleElement.type }!!.drawable
                isSelected = isFocused
                this.isUnderSelectionRectangle = isUnderSelectionRectangle
                this.onSelect = onSelect
                onChange = { geometry -> dispatch(appState.getTransformElement(element.identifier.id!!, geometry)) }
            }
        }
    }
}

fun sortSelectedToEnd(
    array: MutableList<InteractiveElement>?,
    selected: InteractiveElement?,
): MutableList<InteractiveElement>? {
    val result = ArrayList<InteractiveElement>()
    var flag = false

    if (array == null || selected == null) {
        return array
    }

    for (i in 0..array.size - 1) {
        val element = array[i]
        if (element.identifier.id!!.equals(selected.identifier.id)) {
            flag = true
            continue
        }
        result.add(element)
    }


    if (flag) {
        result.add(selected)
    }

    return result
}
