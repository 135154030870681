package widgets.LanguageAutoSlider

import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.serialization.json.Json
import online.interactiver.common.autogeneration.UpdateCustomLinkResponse
import online.interactiver.common.autogeneration.UpdateSliderSettingsRequest
import online.interactiver.common.interactivepicture.SliderGreetingScreenTitleWithScoreSettings
import utils.getToken

suspend fun updateSliderName(requestId: Int, sliderName: String): Boolean {
    return try {
        val response = jsonClient.post("/api/v2/auto-generation/language-slider/requests/$requestId/update-name") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(FormDataContent(Parameters.build {
                if (sliderName.isNotBlank()) {
                    append("name", sliderName)
                }
            }))
        }

        response.status.value == 200
    } catch (_: ResponseException) {
        false
    }
}

suspend fun updateCustomLink(requestId: Int, customLink: String, slider: String): UpdateCustomLinkResponse? {
    return try {
        val response = jsonClient.post("/api/v2/auto-generation/language-slider/requests/$requestId/update-custom-link") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(FormDataContent(Parameters.build {
                append("custom_link", customLink)
                append("slider", slider)
            }))
        }

        if (response.status.value != 200) {
            return null
        }

        return Json.decodeFromString(response.bodyAsText())
    } catch (e: ResponseException) {
        null
    }
}

suspend fun updateSliderSettingsRequest(
    requestId: Int, continueButtonLink: String, continueButtonText: String,
    shareButtonLink: String, shareButtonText: String, task: String,
    titlesWithScoreSettings: List<SliderGreetingScreenTitleWithScoreSettings>,
    enableGamifiedPopups: Boolean, enableThirdPassage: Boolean,
    askStudentName: String
): String? {
    return try {
        val response = jsonClient.post("/api/v2/auto-generation/language-slider/requests/$requestId/update-slider-settings") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(UpdateSliderSettingsRequest(
                task,
                continueButtonLink,
                continueButtonText,
                shareButtonLink,
                shareButtonText,
                titlesWithScoreSettings,
                enableGamifiedPopups,
                enableThirdPassage,
                askStudentName
            ))
        }

        if (response.status.value != 200) {
            return null
        }

        return response.bodyAsText()
    } catch (e: ResponseException) {
        null
    }
}
