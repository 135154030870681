package online.interactiver.common.enums

enum class EAccessType(val value: String) {
    PRIVATE("private"),
    PUBLIC("public"),
    PUBLIC_OF_USER("public of user"),
    SHARED("shared");

    fun hideMenu(isUserOwns: Boolean): Boolean {
        return when (this) {
            SHARED -> false
            PRIVATE -> false
            PUBLIC -> !isUserOwns
            PUBLIC_OF_USER -> !isUserOwns
        }
    }

    companion object {
        fun fromString(value: String): EAccessType? {
            return entries.find { it.value == value }
        }
    }
}