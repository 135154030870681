package widgets.Exercises.ui.components.Exercise

import csstype.*
import utils.types.CssStyle
import utils.types.extend

val row: CssStyle = {
    borderRadius = 10.px
    width = 100.pct
    height = 48.px
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
    cursor = Cursor.pointer
    hover {
        backgroundColor = Color("#F4F7FF")
    }
}

val rowSelected: CssStyle = row.extend {
    backgroundColor = Color("#b3cdf9")
    hover {
        backgroundColor = Color("#b3cdf9")
    }
}

val rowDisabled: CssStyle = row.extend {
    filter = opacity(0.4)
    cursor = Cursor.notAllowed
}

val state: (Boolean) -> CssStyle = { hasParentFolder ->
    {
        boxSizing = BoxSizing.borderBox
        display = Display.grid
        gridAutoFlow = GridAutoFlow.column
        gridAutoColumns = "${if (hasParentFolder) "0.9fr " else ""}3fr 2fr 1fr 1fr 2fr 1.2fr 0.3fr".unsafeCast<GridAutoColumns>()
        alignItems = AlignItems.center
        gap = 5.px
        height = 100.pct
        width = 100.pct
    }
}

val menu: CssStyle = {
    border = None.none
    padding = 0.px
    boxShadow = None.none
    width = 32.px
    marginLeft = Auto.auto
    marginRight = Auto.auto
}

val data: (FontWeight) -> CssStyle = { fontWeight ->
    {
        display = Display.flex
        alignItems = AlignItems.center
        gap = 20.px
        this.fontWeight = fontWeight
        fontSize = 14.px
        lineHeight = 18.9.px
        color = Color("#282F3E")
        margin = 0.px
    }
}

val statisticsButton: CssStyle = {
    border = None.none
    padding = 0.px
    boxShadow = None.none
    height = 24.px
}

val timeCss: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 10.px
}

val numberInFolderContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 5.px
}

val arrows: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 3.px
}

val arrowIcon: CssStyle = {
    width = 15.px
    height = 20.px
    hover {
        filter = "brightness(0%) saturate(100%) invert(21%) sepia(59%) saturate(4395%) hue-rotate(228deg) brightness(98%) contrast(89%)".unsafeCast<Filter>()
    }
}
