package pages.constructor.ui.components.header.styleSettings

import antd.Button
import antd.Popover
import app.appState.IAppState
import app.appState.selectAppState
import app.useAppDispatch
import app.useAppSelector
import emotion.react.css
import entities.interactivePicture.background.selectBackgroundBase64
import entities.interactivePicture.selectFocusedElement
import entities.selectedElement.selectElementsIdsUnderSelectionRectangle
import entities.interactivePicture.styles.*
import enums.EButtonTypes
import online.interactiver.common.interactivepicture.InteractiveElement
import pages.constructor.ui.components.elements.whiteFilter
import pages.constructor.ui.components.header.styleSettings.alignPicker.AlignPicker
import pages.constructor.ui.components.header.styleSettings.colorPicker.ColorPicker
import pages.constructor.ui.components.header.styleSettings.dashPicker.DashPicker
import pages.constructor.ui.components.header.styleSettings.fontSizeInput.FontSizeInput
import pages.constructor.ui.components.header.styleSettings.opacitySlider.OpacitySlider
import pages.constructor.ui.components.header.styleSettings.weightPicker.WeightPicker
import pages.constructor.ui.components.header.styleSettings.paddingSizeInput.PaddingSizeInput
import react.*
import react.dom.html.ReactHTML.div
import shared.canvas.interfaces.getNoBorderForLines
import shared.canvas.interfaces.getNoStrokeAllowed
import shared.components.Icon
import utils.types.impl
import entities.interactivePicture.selectElementsUnderSelectionRectangle
import pages.constructor.ui.components.header.styleSettings.borderRadiusPicker.BorderRadiusPicker
import pages.constructor.ui.components.header.styleSettings.lineHeightInput.LineHeightInput
import pages.constructor.ui.components.header.styleSettings.stylePicker.StylePicker
import shared.canvas.interfaces.getValueForEditor

val COLORS = arrayOf("#FEAC66", "#FDFDFD", "#E0E8FC", "#6E83ED", "#1C274C")  // TODO: change "#6E83ED" on "#9DACF3"

external interface StylePopoverProps : Props {
    var focusedElement: InteractiveElement?
    var appState: IAppState?
}

val PopoverBackgroundContent = FC<Props> {
    val dispatch = useAppDispatch()
    val background = useAppSelector(selectBackgroundStyle)
    val backgroundBase64 = useAppSelector(selectBackgroundBase64)

    div {
        css(container)
        div {
            css(primary)
            +"Fill"
        }
        if (backgroundBase64 == null) {
            ColorPicker {
                value = background?.fillColor
                mainColors = COLORS
                onChange = {
                    dispatch(SetBackgroundColor(it))
                }
            }
        }
        OpacitySlider {
            value = background?.opacity
            onChange = {
                dispatch(SetBackgroundOpacity(it, true))
            }
            onAfterChange = {
                dispatch(SetBackgroundOpacity(it, false))
            }
        }
        div { css(divider) }
        div {
            css(primary)
            +"Border"
        }
        ColorPicker {
            value = background?.strokeColor
            mainColors = COLORS
            onChange = {
                dispatch(SetBackgroundBorderColor(it))
            }
        }
        WeightPicker {
            value = background?.strokeWidth
            light = 1
            medium = 2
            heavy = 3
            onChange = {
                dispatch(SetBackgroundBorderWidth(it))
            }
        }
        DashPicker {
            value = background?.dash?.toTypedArray()
            lineLine = arrayOf(30.0, 10.0)
            lineDot = arrayOf(30.0, 10.0, 5.0, 10.0)
            dotDot = arrayOf(5.0, 5.0)
            onChange = {
                dispatch(SetBackgroundBorderDash(it))
            }
        }
        BorderRadiusPicker {
            value = background?.cornerRadius
            onChange = { radius, preventHistoryUpdate ->
                dispatch(SetBackgroundBorderRadius(radius, preventHistoryUpdate))
            }
        }
    }
}

val PopoverElementContent = FC<StylePopoverProps> { props ->
    val dispatch = useAppDispatch()
    val elementsIdsUnderSelectionRectangle = useAppSelector(selectElementsIdsUnderSelectionRectangle)

    val allElementOfSelectedGroup = useAppSelector(selectElementsUnderSelectionRectangle)

    val fillColorValue = getValueForEditor(props.focusedElement, allElementOfSelectedGroup) { it.visibleElement.style?.usual?.fillColor }

    val opacityValue = getValueForEditor(props.focusedElement, allElementOfSelectedGroup) { it.visibleElement.style?.usual?.opacity.toString() }?.toDoubleOrNull() ?: 0.0

    val borderColorValue = getValueForEditor(props.focusedElement, allElementOfSelectedGroup) { it.visibleElement.style?.usual?.strokeColor }

    val weightValue = getValueForEditor(props.focusedElement, allElementOfSelectedGroup) { it.visibleElement.style?.usual?.strokeWidth.toString() }?.toIntOrNull() ?: 4

    val dashValue = if (props.focusedElement != null) {
        props.focusedElement!!.visibleElement.style?.usual?.dash?.toTypedArray()
    } else {
        var value = allElementOfSelectedGroup.firstOrNull()?.visibleElement?.style?.usual?.dash?.toTypedArray()
        allElementOfSelectedGroup.forEach { element ->
            if (!element.visibleElement.style?.usual?.dash?.toTypedArray().contentEquals(value)){
                value = arrayOf(10.0, 10.0)
                return@forEach
            }
        }
        value
    }

    val textColorValue = getValueForEditor(props.focusedElement, allElementOfSelectedGroup) { it.visibleElement.text?.style?.textColor }

    val fontSizeValue = getValueForEditor(props.focusedElement, allElementOfSelectedGroup) { it.visibleElement.text?.style?.fontSize.toString()}?.toIntOrNull() ?: 0

    val verticalTextValue = getValueForEditor(props.focusedElement, allElementOfSelectedGroup) { it.visibleElement.text?.style?.verticalAlign }

    val horizontalTextValue = getValueForEditor(props.focusedElement, allElementOfSelectedGroup) { it.visibleElement.text?.style?.align }

    val paddingSizeValue = getValueForEditor(props.focusedElement, allElementOfSelectedGroup) { it.visibleElement.text?.style?.padding.toString() }?.toIntOrNull() ?: 0

    val fontStyleValue = getValueForEditor(props.focusedElement, allElementOfSelectedGroup){ it.visibleElement.text?.style?.fontStyle }
    val textDecorationStyleValue = getValueForEditor(props.focusedElement, allElementOfSelectedGroup){ it.visibleElement.text?.style?.textDecoration }
    val lineHeightValue = getValueForEditor(props.focusedElement, allElementOfSelectedGroup){ it.visibleElement.text?.style?.lineHeight.toString() }?.toDoubleOrNull() ?: 1.0
    div {
        css(container)
        div {
            css(primary)
            +"Fill"
        }

        ColorPicker {
            value = fillColorValue
            mainColors = COLORS
            onChange = {
                if (elementsIdsUnderSelectionRectangle.isNotEmpty()){
                   dispatch(props.appState?.getSetFillColorForElements(elementsIdsUnderSelectionRectangle, it) ?: impl {})
                } else {
                    dispatch(props.appState?.getSetFillColor(props.focusedElement!!.identifier.id!!, it) ?: impl {})
                }
            }
        }
        OpacitySlider {
            value = opacityValue
            onChange = {
                if (elementsIdsUnderSelectionRectangle.isNotEmpty()){
                    dispatch(props.appState?.getSetFillOpacityForElements(elementsIdsUnderSelectionRectangle, it, true) ?: impl {})
                } else {
                    dispatch(props.appState?.getSetFillOpacity(props.focusedElement!!.identifier.id!!, it, true) ?: impl {})
                }
            }
            onAfterChange = {
                if (elementsIdsUnderSelectionRectangle.isNotEmpty()){
                    dispatch(props.appState?.getSetFillOpacityForElements(elementsIdsUnderSelectionRectangle, it, false) ?: impl {})
                } else {
                    dispatch(props.appState?.getSetFillOpacity(props.focusedElement!!.identifier.id!!, it, false) ?: impl {})
                }
            }
        }
        div { css(divider) }
        div {
            css(primary)
            +"Border"
        }
        ColorPicker {
            value = borderColorValue
            mainColors = COLORS
            noBorderForLines = props.focusedElement?.getNoBorderForLines()
            onChange = {
                if (elementsIdsUnderSelectionRectangle.isNotEmpty()){
                    dispatch(props.appState?.getSetBorderColorForElements(elementsIdsUnderSelectionRectangle, it) ?: impl {})
                } else {
                    dispatch(props.appState?.getSetBorderColor(props.focusedElement!!.identifier.id!!, it) ?: impl {})
                }
            }
        }
        WeightPicker {
            value = weightValue
            light = 1
            medium = 2
            heavy = 3
            noStokeAllowed = props.focusedElement?.getNoStrokeAllowed()
            onChange = {
                if (elementsIdsUnderSelectionRectangle.isNotEmpty()){
                    dispatch(props.appState?.getSetBorderWidthForElements(elementsIdsUnderSelectionRectangle, it) ?: impl {})
                } else {
                    dispatch(props.appState?.getSetBorderWidth(props.focusedElement!!.identifier.id!!, it) ?: impl {})
                }
            }
        }
        DashPicker {
            value = dashValue
            lineLine = arrayOf(30.0, 10.0)
            lineDot = arrayOf(30.0, 10.0, 5.0, 10.0)
            dotDot = arrayOf(5.0, 5.0)
            onChange = {
                if (elementsIdsUnderSelectionRectangle.isNotEmpty()){
                    dispatch(props.appState?.getSetBorderDashForElements(elementsIdsUnderSelectionRectangle, it) ?: impl {})
                } else {
                    dispatch(props.appState?.getSetBorderDash(props.focusedElement!!.identifier.id!!, it) ?: impl {})
                }
            }
        }
        div { css(divider) }
        div {
            css(primary)
            +"Text"
        }
        ColorPicker {
            value = textColorValue
            mainColors = COLORS
            onChange = {
                if (elementsIdsUnderSelectionRectangle.isNotEmpty()){
                    dispatch(props.appState?.getSetTextColorForElements(elementsIdsUnderSelectionRectangle, it) ?: impl {})
                } else {
                    dispatch(props.appState?.getSetTextColor(props.focusedElement!!.identifier.id!!, it) ?: impl {})
                }

            }
        }
        div {
            css(containerRow)
            FontSizeInput {
                value = fontSizeValue
                min = 8
                max = 72
                onChange = {
                    if (elementsIdsUnderSelectionRectangle.isNotEmpty()) {
                        dispatch(props.appState?.getSetTextFontSizeForElements(elementsIdsUnderSelectionRectangle, it, true) ?: impl {})
                    } else {
                        dispatch(props.appState?.getSetTextFontSize(props.focusedElement!!.identifier.id!!, it, true) ?: impl {})
                    }
                }
                onAfterChange = {
                    if (elementsIdsUnderSelectionRectangle.isNotEmpty()) {
                        dispatch(props.appState?.getSetTextFontSizeForElements(elementsIdsUnderSelectionRectangle, it, false) ?: impl {})
                    } else {
                        dispatch(props.appState?.getSetTextFontSize(props.focusedElement!!.identifier.id!!, it, false) ?: impl {})
                    }
                }
            }
            StylePicker{
                styleValue = fontStyleValue
                decorationValue = textDecorationStyleValue
                onChangeFontStyle = {
                    if (elementsIdsUnderSelectionRectangle.isNotEmpty()){
                        dispatch(props.appState?.getSetTextStyleForElements(elementsIdsUnderSelectionRectangle, it) ?: impl {})
                    } else {
                        dispatch(props.appState?.getSetTextStyle(props.focusedElement!!.identifier.id!!, it) ?: impl {})
                    }
                }
                onChangeTextDecoration = {
                    if (elementsIdsUnderSelectionRectangle.isNotEmpty()){
                        dispatch(props.appState?.getSetTextDecorationForElements(elementsIdsUnderSelectionRectangle, it) ?: impl {})
                    } else {
                        dispatch(props.appState?.getSetTextDecoration(props.focusedElement!!.identifier.id!!, it) ?: impl {})
                    }
                }
            }
        }
        div {
            css(containerRow)
            PaddingSizeInput {
                value = paddingSizeValue
                min = 1
                max = 100000
                fun changePadding(size: Int, boolean: Boolean) {
                    if (elementsIdsUnderSelectionRectangle.isNotEmpty()) {
                        dispatch(
                            props.appState?.getSetTextPaddingForElements(elementsIdsUnderSelectionRectangle, size, boolean) ?: impl {})
                    } else {
                        dispatch(
                            props.appState?.getSetTextPadding(props.focusedElement!!.identifier.id!!, size, boolean) ?: impl {})
                    }
                }
                onChange = {
                    changePadding(it, true)
                }
                onAfterChange = {
                    changePadding(it, false)
                }
            }
            LineHeightInput{
                value = lineHeightValue
                min = 1.0
                max = 2.0
                onChange = {
                    if (elementsIdsUnderSelectionRectangle.isNotEmpty()) {
                        dispatch(props.appState?.getSetTextLineHeightForElements(elementsIdsUnderSelectionRectangle, it, true) ?: impl {})
                    } else {
                        dispatch(props.appState?.getSetTextLineHeight(props.focusedElement!!.identifier.id!!, it, true) ?: impl {})
                    }
                }
                onAfterChange = {
                    if (elementsIdsUnderSelectionRectangle.isNotEmpty()) {
                        dispatch(props.appState?.getSetTextLineHeightForElements(elementsIdsUnderSelectionRectangle, it, false) ?: impl {})
                    } else {
                        dispatch(props.appState?.getSetTextLineHeight(props.focusedElement!!.identifier.id!!, it, false) ?: impl {})
                    }
                }
            }
        }
        AlignPicker {
            verticalValue = verticalTextValue
            horizontalValue = horizontalTextValue
            onHorizontalAlignChange = {
                if (elementsIdsUnderSelectionRectangle.isNotEmpty()){
                    dispatch(props.appState?.getSetTextHorizontalAlignForElements(elementsIdsUnderSelectionRectangle, it) ?: impl {})
                } else {
                    dispatch(props.appState?.getSetTextHorizontalAlign(props.focusedElement!!.identifier.id!!, it) ?: impl {})                }
            }
            onVerticalAlignChange = {
                if (elementsIdsUnderSelectionRectangle.isNotEmpty()){
                    dispatch(props.appState?.getSetTextVerticalAlignForElements(elementsIdsUnderSelectionRectangle, it) ?: impl {})
                } else {
                    dispatch(props.appState?.getSetTextVerticalAlign(props.focusedElement!!.identifier.id!!, it) ?: impl {})                }
            }
        }
    }
}

val StyleSettings = FC<Props> {
    val appState = useAppSelector(selectAppState)
    val focusedElement = useAppSelector(selectFocusedElement)
    val elementsIdsUnderSelectionRectangle = useAppSelector(selectElementsIdsUnderSelectionRectangle)

    val (open, setOpen) = useState(false)

    useEffect(focusedElement) {
        if (focusedElement == null) {
            setOpen(false)
        }
    }

    Popover {
        trigger = "click"
        align = impl {
            points = arrayOf("tl", "bl")
            offset = arrayOf(-20, 6)
        }
        arrowPointAtCenter = false
        content = if (focusedElement != null || elementsIdsUnderSelectionRectangle.isNotEmpty()) createElement(PopoverElementContent, impl {
            this.focusedElement = focusedElement
            this.appState = appState
        }) else createElement(PopoverBackgroundContent)
        this.open = open
        onOpenChange = { newOpen: Boolean ->
            setOpen(newOpen)
        }

        Button {
            css(button)
            id = EButtonTypes.STYLE_BUTTON.value
            Icon {
                css(whiteFilter)
                src = "ic_brush_24x24.svg"
            }
            +"Style settings"
            ghost = true
            type = "text"
        }

    }
}
