package widgets.ExerciseSettings.components.LogoSettings

import antd.Option
import antd.Select
import app.useAppDispatch
import csstype.ClassName
import emotion.react.css
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.ELogoUseType
import react.FC
import react.Props
import react.redux.useSelector
import widgets.ExerciseSettings.SetLogoUseTypeAction
import widgets.ExerciseSettings.components.CustomLogoSettings.CustomLogoSettings
import widgets.ExerciseSettings.selectLogoUseType
import widgets.ExerciseSettings.updateLogoUseTypeRequest

val LogoSettings = FC<Props> {
    val logoUseType = useSelector(selectLogoUseType)
    val dispatch = useAppDispatch()

    Select {
        css(ClassName("select-logo-use-type")) {}
        value = logoUseType
        onChange = { value, _ ->

            ELogoUseType.fromUiValue(value)?.let {
                if (it.value == logoUseType) {
                    return@let
                }

                dispatch(SetLogoUseTypeAction(it.value))
                GlobalScope.launch {
                    updateLogoUseTypeRequest(it.value)
                }
            }
        }
        ELogoUseType.entries.forEach {
            Option {
                value = it.uiValue
                className = ClassName("select-logo-use-type-option")
            }
        }
    }

    if (logoUseType != ELogoUseType.CUSTOM_LOGO.value) {
        return@FC
    }

    CustomLogoSettings {}
}