package widgets.UserProfile.ui

import app.useAppDispatch
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import pages.languageAuto.SetHiddenIframeIsShown
import pages.languageAuto.UpdateCurrentLanguageAutoRequest
import pages.languageAuto.ui.ERoute
import react.VFC
import react.redux.useSelector
import react.router.useNavigate
import widgets.Exercises.getLanguageAutoRequest
import widgets.UserProfile.selectUserProfile

val UserProfileToEdit = VFC {
    val userProfile = useSelector(selectUserProfile)
    val dispatch = useAppDispatch()
    val navigate = useNavigate()

    UserProfile {
        userViewsHisProfile = true
        this.userProfile = userProfile
        onExerciseClick = {
            navigate("${ERoute.EXERCISE.path}/${it.requestId}")
        }
    }
}