package entities.dictionary.ui

import getPhraseByInteractiveGroupIdAndPhrase
import getSoundFromDictionaryOrGoogleCloudRequest
import getSoundFromGoogleCloudRequest
import getSoundsFromDictionaryOrGoogleCloudRequest
import getSyncingPhrasesFromDictionaryRequest
import kotlinx.browser.window
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import online.interactiver.common.admin.interactive.phrase.Phrase
import savePhraseRequest
import utils.extractUUIDFromUrl

suspend fun phraseAndInteractiveGroupIdToSyncedPhrase(phrase: String, interactiveGroupId: Int): Pair<Phrase?, Int> {
    val response = getPhraseByInteractiveGroupIdAndPhrase(phrase, interactiveGroupId)
    if (response.code != 200) {
        return Pair(null, response.code)
    }

    return Pair(Json.decodeFromString<Phrase>(response.content), response.code)
}

suspend fun getSyncingPhrasesFromDictionary(interactiveGroupId: Int): Set<Phrase> {
    val uuid = extractUUIDFromUrl(window.location.href)

    val response = getSyncingPhrasesFromDictionaryRequest(interactiveGroupId, uuid)
    if (response.code != 200) {
        return setOf()
    }

    return Json{
        ignoreUnknownKeys = true
        explicitNulls = false
    }.decodeFromString(response.content)
}

suspend fun getSoundFromDictionaryOrGoogleCloud(
    phrase: String,
    interactiveGroupId: Int,
    fromDictionary: Boolean,
    fromGoogleTextToSpeech: Boolean,
    materialLanguage: String
): Phrase? {
    val response = getSoundFromDictionaryOrGoogleCloudRequest(
        phrase,
        interactiveGroupId,
        fromDictionary,
        fromGoogleTextToSpeech,
        materialLanguage)
    if (response.code != 200) {
        return null
    }

    return Json.decodeFromString<Phrase>(response.content)
}

suspend fun getSoundsFromDictionaryOrGoogleCloud(
    phrases: Array<String>,
    interactiveGroupId: Int,
    materialLanguage: String
): Array<Phrase>? {
    val response = getSoundsFromDictionaryOrGoogleCloudRequest(phrases, interactiveGroupId, materialLanguage)
    if (response.code != 200) {
        return null
    }

    return Json.decodeFromString(response.content)
}


suspend fun getSoundFromGoogleCloud(phrase: String, interactiveGroupId: Int): Phrase? {
    val response = getSoundFromGoogleCloudRequest(phrase, interactiveGroupId)
    if (response.code != 200) {
        return null
    }

    return Json.decodeFromString<Phrase>(response.content)
}
suspend fun savePhraseByInteractiveGroupId(phrase: String, textDescription: String?, soundDescription: String?, interactiveGroupId: Int): Phrase? {
    val response = savePhraseRequest(phrase, textDescription, soundDescription, interactiveGroupId)
    if (response.code != 200) {
        return null
    }

    return Json.decodeFromString<Phrase>(response.content)
}
