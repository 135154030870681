package widgets.LanguageAutoSlider.ui.components.CustomLinkModal

import csstype.*
import utils.types.CssStyle
import utils.types.FitContent

val linkContainer: CssStyle = {
    display = Display.flex
    width = 100.pct
    border = Border(1.px, LineStyle.solid, Color("#D0D5DD"))
    borderRadius = 8.px
    boxSizing = BoxSizing.borderBox
    height = 40.px
}

val domain: CssStyle = {
    width = FitContent.fitContent
    borderRight = Border(1.px, LineStyle.solid, Color("#D0D5DD"))
    margin = 0.px
    padding = 8.px
    fontSize = 16.px
    lineHeight = 21.6.px
    fontWeight = FontWeight.normal
    color = Color("black")
}

val custom: CssStyle = {
    border = None.none
    margin = 0.px
    padding = 8.px
    fontSize = 16.px
    lineHeight = 21.6.px
    fontWeight = FontWeight.normal
    color = Color("black")
}

val alreadyExists: CssStyle = {
    margin = 0.px
    color = Color("red")

}
