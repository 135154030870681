package entities.interactivePicture.elements.gapPuzzles

import entities.interactivePicture.elements.CommonInteractiveElementAction
import entities.interactivePicture.elements.CommonListReducer
import entities.interactivePicture.elements.InteractiveElementAction
import entities.interactivePicture.elements.gapPuzzles.drags.DragsAction
import entities.interactivePicture.elements.gapPuzzles.drags.DragsReducer
import online.interactiver.common.interactivepicture.InteractiveElement

val GapPuzzlesReducer = { state: MutableList<InteractiveElement>, action: InteractiveElementAction ->
    when (action) {
        is DragsAction -> DragsReducer(state, action)
        is CommonInteractiveElementAction -> CommonListReducer(state, action)
        else -> state
    }
}
