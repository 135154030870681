package entities.modalLoader

import kotlinx.js.timers.clearTimeout
import kotlinx.js.timers.setTimeout
import online.interactiver.common.enums.ELanguage
import react.useEffect
import react.useState

data class EntertainingTextsMeta(val value: String, val duration: Int, val nextIndex: Int? = null)

val entertainingTextsEd = listOf(
    EntertainingTextsMeta("Starting AI magic ✨", 3),
    EntertainingTextsMeta("Analyzing your preferences \uD83D\uDD0D", 3),
    EntertainingTextsMeta("Generating content for exercises ✍\uFE0F", 7),
    EntertainingTextsMeta("Crafting gamified exercises \uD83E\uDD57", 7),
    EntertainingTextsMeta("Generating sound \uD83D\uDD0A", 7),
    EntertainingTextsMeta("Almost done \uD83D\uDD1C", 10),
    EntertainingTextsMeta("Verifying Exercise Accuracy ✅", 10),
    EntertainingTextsMeta("Fixing Exercise Accuracy \uD83D\uDCDD", 8),
    EntertainingTextsMeta("Almost there \uD83D\uDD52", 10),
    EntertainingTextsMeta("Testing Exercise Flow \uD83D\uDD03", 7),
    EntertainingTextsMeta("Fine-Tuning for Perfection \uD83D\uDEE0", 7),
    EntertainingTextsMeta("The last action ☺\uFE0F", 10),
    EntertainingTextsMeta("Identifying Tweaks Needed ⚠\uFE0F", 10),
    EntertainingTextsMeta("Making Adjustments \uD83D\uDD27",10),
    EntertainingTextsMeta("Fine-Tuning for Perfection \uD83D\uDEE0", 10),
    EntertainingTextsMeta("Preparing for Launch \uD83D\uDE80", 10, 12)
)

val entertainingTextsDuoEn = listOf(
    EntertainingTextsMeta("Starting the AI magic ✨", 2),
    EntertainingTextsMeta("Analyzing your request 🔍", 2),
    EntertainingTextsMeta("Gathering words and phrases with translations and audio 🔠", 4),
    EntertainingTextsMeta("Creating course of game-based tasks for word translation, phrase building, word input 📝", 6),
    EntertainingTextsMeta("Our approach is based on thematic and contextual language learning methods 🧩", 7),
    EntertainingTextsMeta("With regular repetition based on the forgetting curve 🔄", 6),
    EntertainingTextsMeta("Practice topics, words, and phrases that you need right now, every day ⏱️", 7),
    EntertainingTextsMeta("This is the most effective way to learn a language 🚀", 4),
    EntertainingTextsMeta("In just 2 weeks of practice, people learn language skills for conversations with neighbors, doctors, or repairmen 🗣️", 9),
    EntertainingTextsMeta("Inream creates tasks tailored specifically to your words, phrases, or topics 📚", 7),
    EntertainingTextsMeta("Inream tracks your progress and sends weekly reports 📊", 7),
    EntertainingTextsMeta("Verifying Exercise Accuracy ✅", 10),
    EntertainingTextsMeta("Fixing Exercise Accuracy \uD83D\uDCDD", 8),
    EntertainingTextsMeta("Almost there \uD83D\uDD52", 10),


)

val entertainingTextsDuoRu = listOf(
    EntertainingTextsMeta("Стартуем AI магию ✨", 2),
    EntertainingTextsMeta("Анализируем ваш запрос \uD83D\uDD0D", 2),
    EntertainingTextsMeta("Собираем слова и фразы с переводами и аудио \uD83D\uDD20", 4),
    EntertainingTextsMeta("Создаем курс из игровых заданий на перевод слов, сбор фраз, ввод слов \uD83D\uDCDD", 6),
    EntertainingTextsMeta("Наш подход основан на тематической и контекстной методиках изучения языка \uD83E\uDDE9", 7),
    EntertainingTextsMeta("C регулярным повторением на основе кривой забывания \uD83D\uDD04", 6),
    EntertainingTextsMeta("Ежедневно практикуйте темы, слова и фразы, которые нужны уже сейчас ⏱️", 7),
    EntertainingTextsMeta("Это самый эффективный способ учить язык \uD83D\uDE80", 4),
    EntertainingTextsMeta("Всего за 2 недели практики люди осваивают язык для разговоров с соседом, врачом, или мастером \uD83D\uDDE3️", 9),
    EntertainingTextsMeta("Inream соберет вам задания именно по вашим словам, фразам или темам \uD83D\uDCDA", 7),
    EntertainingTextsMeta("Inream отслеживает ваш прогресс и еженедельно отправляет отчеты \uD83D\uDCCA", 7),
    EntertainingTextsMeta("Проверяем качество заданий ✅", 10),
    EntertainingTextsMeta("Поправляем ошибки \uD83D\uDCDD", 8),
    EntertainingTextsMeta("Почти закончили \uD83D\uDD52", 10),
)

data class ModalLoaderWithEntertainingTexts(val startLoading: () -> Unit, val endLoading: () -> Unit)

fun useModalLoaderWithEntertainingTexts(isDuo: Boolean, nativeLanguage: String, startLoading: (String) -> Unit, endLoading: () -> Unit): ModalLoaderWithEntertainingTexts {
    val (indexOfEntertainingText, setIndexOfEntertainingText) = useState<Int?>(null)
    val entertainingTexts = if(isDuo) {
        if (nativeLanguage == ELanguage.RUSSIAN.text) {
            entertainingTextsDuoRu
        } else {
            entertainingTextsDuoEn
        }
    } else {
        entertainingTextsEd
    }

    useEffect(indexOfEntertainingText) {
        if (indexOfEntertainingText == null) {
            return@useEffect
        }

        val id = setTimeout({
            val newIndex = entertainingTexts[indexOfEntertainingText].nextIndex
                ?: ((indexOfEntertainingText + 1) % entertainingTexts.size)

            setIndexOfEntertainingText(newIndex)
            startLoading(entertainingTexts[newIndex].value)
        }, entertainingTexts[indexOfEntertainingText].duration * 1000)

        cleanup {
            clearTimeout(id)
        }
    }

    return ModalLoaderWithEntertainingTexts(
        startLoading = {
            setIndexOfEntertainingText(0)
            startLoading(entertainingTexts[0].value)
        },
        endLoading = {
            setIndexOfEntertainingText(null)
            endLoading()
        }
    )
}
