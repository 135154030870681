package entities.studentDuoPromocodePopup.ui

import features.PromoCodePopup.PromoCodePopup
import pages.studentDuo.selectStudentDuoCourses
import pages.studentDuo.selectStudentDuoNativeLanguage
import pages.studentDuo.selectStudentDuoUserProfile
import react.FC
import react.Props
import react.redux.useSelector

val StudentDuoPromoCodePopup = FC<Props> {props ->
    val nativeLanguage = useSelector(selectStudentDuoNativeLanguage)
    val courses = useSelector(selectStudentDuoCourses)
    val userProfile = useSelector(selectStudentDuoUserProfile)
    PromoCodePopup {
        open = courses.size > 3 && userProfile?.tariffPlan?.uppercase() == "BASIC"
        this.nativeLanguage = nativeLanguage
    }
}
