package shared.components.rangeSelect

import antd.Option
import antd.Select
import emotion.react.css
import react.FC
import react.Props
import react.useMemo

external interface RangeSelectProps : Props {
    var max: Int
    var min: Int
    var value: String
    var onChange: (String) -> Unit
    var step: Int
    var disabled: Boolean
}

val RangeSelect = FC<RangeSelectProps> { props ->
    val options = useMemo(props.min, props.max, props.step) {
        val numbers = mutableListOf(props.min)
        var i = props.min + props.step
        while (i <= props.max) {
            numbers.add(i)
            i += props.step
        }
        numbers
    }

    Select {
        css(select)
        disabled = props.disabled
        value = getNumberWithLeadingNull(props.value)
        onChange = { _, option ->
            props.onChange(option.value.toString())
        }
        options.forEach {
            Option {
                value = getNumberWithLeadingNull(it.toString())
            }
        }
    }
}

fun getNumberWithLeadingNull(value: String): String {
    return if (value.length == 1) {
        "0$value"
    } else {
        value
    }
}
