package online.interactiver.common.duo

enum class PredefinedTopicType {
    REAL_LIFE, GRAMMAR, FILM_STORY, COMBO, VOCABULARY
}

data class PredefinedTopic (
    val en: String,
    val ru: String,
    val prompt: String?,
    val type: PredefinedTopicType,
    val languageToLearn: String = "",
    val languageLevel: String = ""
)

val startPredefinedTopics = listOf(
    PredefinedTopic("Сhat with the neighbors", "Разговор с соседом", "Сhat with the neighbors\n" +
            "How have you been?\n" +
            "Lived here long\n" +
            "Grab a coffee\n" +
            "I’ve just moved in\n" +
            "Familiar with the area\n" +
            "Could I borrow\n" +
            "Hammer\n" +
            "Is there a place nearby\n" +
            "Recommend\n" +
            "Buying fresh produce\n" +
            "If you need anything\n" +
            "Feel free to knock\n" +
            "What do you usually do?\n" +
            "On weekends\n" +
            "Good places to go\n" +
            "For a walk\n" +
            "Meaning to ask\n" +
            "Parking situation\n" +
            "Favorite spot to eat\n" +
            "I hope I’m not bothering you\n" +
            "Community events\n" +
            "Gatherings\n" +
            "If you’re ever free\n" +
            "Get-together\n" +
            "House rules\n" +
            "Commute", PredefinedTopicType.REAL_LIFE),

    PredefinedTopic("Visit to the doctor", "Визит к врачу", "Visit to the doctor\n" +
            "Sharp pain\n" +
            "In my stomach\n" +
            "I’ve been coughing\n" +
            "Should I be worried?\n" +
            "Sign of something serious\n" +
            "What tests do you recommend?\n" +
            "Side effects\n" +
            "Medication\n" +
            "How often\n" +
            "Prescribe\n" +
            "Specialist\n" +
            "I’ve noticed this rash\n" +
            "Related to my symptoms\n" +
            "Exercise\n" +
            "Taking this treatment\n" +
            "Follow-up appointment\n" +
            "Regular routine\n" +
            "Start feeling better\n" +
            "Come back to see me\n" +
            "Blood test\n" +
            "X-ray\n" +
            "Rule out\n" +
            "Tolerate this medication\n" +
            "Unusual reactions\n" +
            "Antibiotics\n" +
            "Complete the course\n" +
            "Twice a day", PredefinedTopicType.REAL_LIFE),

    PredefinedTopic("Verbs in the present tense", "Глаголы в настоящем времени", null, PredefinedTopicType.GRAMMAR),

    PredefinedTopic("Movie The Lord of the Rings", "Фильм Властелин колец", "Movie The Lord of the Rings\n" +
            "Frodo receives a magic ring from his uncle Bilbo.\n" +
            "Gandalf reveals to Frodo that the ring belongs to the evil Sauron.\n" +
            "Frodo and his friends must destroy the ring.\n" +
            "They embark on a long and dangerous journey to Mount Orodruin.\n" +
            "Sam, Frodo’s best friend, follows him everywhere.\n" +
            "Along the way, they meet Aragorn, Legolas, and Gimli.\n" +
            "Sauron builds an army of orcs to conquer Middle-earth.\n" +
            "The Nazgûl, Sauron’s servants, chase after Frodo and the ring.\n" +
            "The ring affects Frodo, making him feel heavy and tired.\n" +
            "Gollum, a strange creature, tries to take the ring for himself.\n" +
            "Gollum helps Frodo and Sam reach Mordor, but he has his own plans.\n" +
            "Aragorn’s army fights the orcs to save Gondor.\n" +
            "Frodo and Sam sneak through Mordor to reach the volcano.\n" +
            "Gollum fights Frodo for the ring but falls into the lava.\n" +
            "The ring melts in the fire, and Sauron is destroyed.\n" +
            "Sauron’s army disappears after the ring is destroyed.\n" +
            "Aragorn becomes the king of Gondor after the victory.\n" +
            "Frodo returns home to the Shire but feels he has changed forever.\n" +
            "Frodo decides to leave Middle-earth and travel to the Undying Lands.\n" +
            "Frodo’s story ends, and Middle-earth enters an era of peace.", PredefinedTopicType.FILM_STORY)
)

val predefinedTopics = startPredefinedTopics + listOf(
    PredefinedTopic("Verbs in the past tense", "Глаголы в прошедшем времени от первого лица", null, PredefinedTopicType.GRAMMAR),
    PredefinedTopic("Basic Verbs in traveling", "Глаголы базового уровня в путешествии", "Book (a flight, hotel)\n" +
            "Check-in (at a hotel or airport)\n" +
            "Depart\n" +
            "Arrive\n" +
            "Pack (your bags)\n" +
            "Unpack\n" +
            "Explore (a new city or country)\n" +
            "Discover (new places)\n" +
            "Rent (a car or accommodation)\n" +
            "Fly\n" +
            "Drive (to a destination)\n" +
            "Sail (on a boat)\n" +
            "Hike (in nature)\n" +
            "Camp (in the wilderness)\n" +
            "Reserve (a table, ticket, or room)\n" +
            "Tour (a city or attraction)\n" +
            "Visit (landmarks, museums, etc.)\n" +
            "Stay (at a hotel or Airbnb)\n" +
            "Travel (abroad, around the world)\n" +
            "Catch (a flight, train, or bus)\n" +
            "Miss (a flight, train, or bus)\n" +
            "Plan (a trip or itinerary)\n" +
            "Cancel (a trip, flight, or hotel)", PredefinedTopicType.VOCABULARY),

    PredefinedTopic("Description of symptoms to the doctor", "Описание симптомов врачу", "" +
            "Fever\n" +
            "Chills\n" +
            "Fatigue\n" +
            "Weakness\n" +
            "Dizziness\n" +
            "Nausea\n" +
            "Vomiting\n" +
            "Loss of appetite\n" +
            "Shortness of breath\n" +
            "Sweating\n" +
            "Sharp pain\n" +
            "Dull pain\n" +
            "Throbbing\n" +
            "Burning sensation\n" +
            "Stabbing pain\n" +
            "Cramping\n" +
            "Aching\n" +
            "Radiating pain\n" +
            "Tingling\n" +
            "Tenderness\n" +
            "Headache\n" +
            "Stomach ache\n" +
            "Chest pain\n" +
            "Back pain\n" +
            "Joint pain\n" +
            "Muscle soreness\n" +
            "Skin rash\n" +
            "Sore throat\n" +
            "Congestion\n" +
            "Runny nose", PredefinedTopicType.VOCABULARY),


    PredefinedTopic("In the store", "Разговор в магазине", "" +
            "I’m looking for\n" +
            "Do you have this in stock?\n" +
            "Can you tell me where the (milk) is?\n" +
            "Is this available in a different size?\n" +
            "Could you check the price for me?\n" +
            "Are there any discounts on this item?\n" +
            "Do you have any special offers today?\n" +
            "Can I try this on?\n" +
            "Where is the fitting room?\n" +
            "Do you have a bigger size?\n" +
            "Do you have a smaller size?\n" +
            "How much does this cost?\n" +
            "I’ll take it\n" +
            "Could I get a bag, please?\n" +
            "Can I pay with a credit card?\n" +
            "Do you accept contactless payment?\n" +
            "Can I get a receipt, please?\n" +
            "What’s your return policy?\n" +
            "How long is the warranty?\n" +
            "loyalty program?\n" +
            "Could you wrap this as a gift?\n" +
            "Are these items on sale?\n" +
            "What time does the store close?\n" +
            "how can I help you?\n" +
            "This item costs \n" +
            "It’s just around the corner, on the left\n" +
            "You can return the item within 30 days with a receipt\n" +
            "The warranty lasts for one year from the purchase date\n" +
            "We offer gift wrapping at the counter\n" +
            "We close at 9 PM\n" +
            "slice this for me\n" +
            "wrap this up\n" +
            "in paper or a bag\n" +
            "cut this into smaller pieces\n" +
            "We’re open until 10 PM tonight", PredefinedTopicType.VOCABULARY),

    //PredefinedTopic("Past and future time", "Прошедшее и будущее время", null, PredefinedTopicType.GRAMMAR)
)

val topicsByEx = predefinedTopics.associateBy { it.en }
val topicsByRu = predefinedTopics.associateBy { it.ru }
val allTopics = topicsByEx + topicsByRu

fun getPredefinedTopicsByLanguageToLearnAndLevel (
    languageToLearn: String,
    languageLevel: String
) = predefinedTopics.filter {
    (it.languageToLearn == "" || it.languageToLearn == languageToLearn) &&
            (it.languageLevel == "" || it.languageLevel == languageLevel)
}
fun getPredefinedPromptByTopic (topic: String) = allTopics.get(topic)?.prompt ?: topic