package widgets.LanguageAutoSlider.ui.components.ShareModal

import Modal
import antd.Button
import csstype.Position
import csstype.integer
import csstype.px
import emotion.react.css
import kotlinx.browser.window
import kotlinx.js.timers.setTimeout
import pages.constructor.ui.components.elements.grayFilter
import widgets.UserProfile.selectTariffPlan
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.redux.useSelector
import react.useState
import shared.components.Icon
import shared.components.header.Header
import utils.types.extend
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoSlider.ui.components.CustomLinkModal.CustomLinkModal
import widgets.LanguageAutoSlider.ui.components.IntegrateModal.IntegrateModal
import widgets.LanguageAutoSlider.ui.components.LanguageAutoSliderHeader.pro
import widgets.LanguageAutoTask.ui.divider

external interface ShareModalProps : Props {
    var isOpen: Boolean
    var onClose: () -> Unit
    var requestId: Int
    var link: String
    var embedCode: String
    var slider: String
    var openShareModal: () -> Unit
    var isUserOwns: Boolean
}

val publicPlatformLink = "${window.location.origin}/duo/exercise/"
val folderPlatformLink = "${window.location.origin}/duo/folder/"

val ShareModal = FC<ShareModalProps> { props ->
    val tariffPlan = useSelector(selectTariffPlan)

    val (isWebLinkCopied, setIsWebLinkCopied) = useState(false)
    val (isPlatformLinkCopied, setIsPlatformLinkCopied) = useState(false)
    val (customLinkModalIsOpened, setCustomModalIsOpened) = useState(false)
    val (integrateModalIsOpened, setIntegrateModalIsOpened) = useState(false)

    val uuid = props.link.substringAfterLast("/").substringBefore(".")
    val platformPublicLink = "$publicPlatformLink$uuid?public=true"

    Modal {
        open = props.isOpen
        onCancel = {
            props.onClose()
        }
        footer = null
        width = 650

        div {
            css(modal)
            Header {
                text = "Share"
            }
            div {
                css(copyLinkContainer)
                div {
                    css(linkContainer)
                    Header {
                        text = "Public platform link"
                        fontSize = 14.0.px
                        lineHeight = 18.9.px
                        fontWeight = integer(600)
                    }
                    p {
                        css(link)
                        +platformPublicLink
                    }
                }
                Button {
                    css(generateButton)
                    onClick = {
                        window.navigator.clipboard.writeText(platformPublicLink)
                        if (!isPlatformLinkCopied) {
                            setIsPlatformLinkCopied(true)
                            setTimeout({ setIsPlatformLinkCopied(false) }, 1000)
                        }
                    }
                    + if (isPlatformLinkCopied) "Link is copied" else "Copy link"
                }
            }
            div {
                css(copyLinkContainer)
                div {
                    css(linkContainer)
                    Header {
                        text = "Web link"
                        fontSize = 14.0.px
                        lineHeight = 18.9.px
                        fontWeight = integer(600)
                    }
                    p {
                        css(link)
                        +props.link
                    }
                }
                Button {
                    css(generateButton)
                    onClick = {
                        window.navigator.clipboard.writeText(props.link)
                        if (!isWebLinkCopied) {
                            setIsWebLinkCopied(true)
                            setTimeout({ setIsWebLinkCopied(false) }, 1000)
                        }
                    }
                    + if (isWebLinkCopied) "Link is copied" else "Copy link"
                }
            }
            div {
                css(divider)
            }
            div {
                css(optionsContainer)
                div {
                    css(optionContainer)
                    Button {
                        css(optionButton)
                        onClick = {
                            setIntegrateModalIsOpened(true)
                            props.onClose()
                            if (isWebLinkCopied) {
                                setIsWebLinkCopied(false)
                            }
                        }
                        Icon {
                            css(grayFilter)
                            src = "ic_integrate_grey_22x22.svg"
                        }
                    }
                    p {
                        css(optionText)
                        +"Embed"
                    }
                }
                if (props.isUserOwns) {
                    div {
                        css(optionContainer.extend {
                            position = Position.relative
                        })
                        Button {
                            css(optionButton)
                            disabled = tariffPlan?.uppercase() == "BASIC"
                            onClick = {
                                setCustomModalIsOpened(true)
                                props.onClose()
                                if (isWebLinkCopied) {
                                    setIsWebLinkCopied(false)
                                }
                            }
                            Icon {
                                css(grayFilter.extend {
                                    width = 18.px
                                    height = 18.px
                                })
                                src = "ic_link_grey_22x22.svg"
                            }
                        }
                        p {
                            css(optionText)
                            +"Custom link"
                        }
                        p {
                            css(pro.extend {
                                position = Position.absolute
                                top = (-4).px
                                right = (-25).px
                            })
                            +"Pro"
                        }

                    }
                }
            }
        }
    }
    if (props.isUserOwns) {
        CustomLinkModal {
            isOpen = customLinkModalIsOpened
            onClose = { setCustomModalIsOpened(false) }
            openShareModal = props.openShareModal
            this.link = props.link
            this.requestId = props.requestId
            this.slider = props.slider
        }
    }

    IntegrateModal {
        isOpen = integrateModalIsOpened
        onClose = { setIntegrateModalIsOpened(false) }
        openShareModal = props.openShareModal
        this.embedCode = props.embedCode
        this.requestId = props.requestId
    }
}