package pages.constructor.ui.components.loadingDots

import csstype.Display
import csstype.TextAlign
import csstype.ch
import utils.types.CssStyle

val loadingDotsStyle : CssStyle = {
    display = Display.inlineBlock
    width = 3.ch
    textAlign = TextAlign.start
}