package shared.components.tooltipInfo

import antd.Tooltip
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML
import shared.components.Icon
import utils.types.impl

external interface TooltipInfoProps : Props {
    var title: dynamic
    var overlayStyle: dynamic
}

val TooltipInfo = FC<TooltipInfoProps> { props ->
    if (props.title != null) {
        Tooltip {
            title = props.title
            align = impl {
                offset = arrayOf(0, 5)
            }
            color = "#000"
            overlayStyle = props.overlayStyle
            arrowPointAtCenter = true
            ReactHTML.button {
                css(tooltipButton)
                Icon {
                    src = "ic_info_14x14.svg"
                }
            }
        }
    }
}
