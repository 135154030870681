package entities.errorModal.ui

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    gap = 12.px
    margin = 24.px
}

val header: CssStyle = {
    color = Color("#282F3E")
    fontWeight = integer(500)
    lineHeight = 26.px
    fontSize = 20.px
    fontFamily = string("\"Inter\", system-ui")
    margin = 0.px
    textAlign = TextAlign.center
}

val description: CssStyle = {
    color = Color("#5D6676")
    fontWeight = FontWeight.normal
    lineHeight = 21.6.px
    fontSize = 16.px
    fontFamily = string("\"Inter\", system-ui")
    margin = 0.px
    textAlign = TextAlign.center
}
