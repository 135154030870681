package online.interactiver.common.statistics

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SlideStatisticsResponse(
    @SerialName("number_of_successful_attempts")
    val numberOfSuccessfulAttempts: Int,
    @SerialName("number_of_views")
    val numberOfViews: Int
)
