package widgets.LanguageAutoContent.ui.components.Content.ListeningContent.components.FromAudio

import antd.Button
import app.getTextFromSound
import app.useAppDispatch
import csstype.Color
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.languageAutoCurrentRequest.*
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.ELanguage
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import shared.components.Icon
import shared.components.UploadOrRecordAudio.useUploadOrRecordAudio
import shared.components.header.Header
import utils.types.extend
import widgets.LanguageAutoContent.ui.components.Content.ListeningContent.components.ReadyText.ReadyText
import widgets.LanguageAutoContent.ui.generateButton

val FromAudio = FC<Props> {
    val dispatch = useAppDispatch()
    val textOrTopic = useSelector(selectCurrentRequestTextOrTopic)
    val languageToLearn = useSelector(selectCurrentRequestLanguageToLearn)

    val audioToGenerateText = useSelector(selectCurrentRequestSoundSrc)

    val maxFileSizeInMb = 5

    val (
        uploadOrRecordAudioComponent,
        _,
        uploadOrRecordAudioProps
    ) = useUploadOrRecordAudio(maxFileSizeInMb, audioToGenerateText, 60) { dispatch(SetCurrentRequestSoundSrc(it)) }

    Header {
        text = "Upload your audio file or record it"
    }

    div {
        uploadOrRecordAudioComponent {
            this.maxFileSizeInMb = uploadOrRecordAudioProps.maxFileSizeInMb
            this.onSoundChange = uploadOrRecordAudioProps.onSoundChange
            this.sound = uploadOrRecordAudioProps.sound
            this.resetAudioDuration = uploadOrRecordAudioProps.resetAudioDuration
            this.preparingToRecordDuration = uploadOrRecordAudioProps.preparingToRecordDuration
            this.isRecording = uploadOrRecordAudioProps.isRecording
            this.startPreparingToRecord = uploadOrRecordAudioProps.startPreparingToRecord
            this.continuePreparingToRecords = uploadOrRecordAudioProps.continuePreparingToRecords
            this.stopRecording = uploadOrRecordAudioProps.stopRecording
            this.maxAudioDuration = uploadOrRecordAudioProps.maxAudioDuration
            this.recordingDuration = uploadOrRecordAudioProps.recordingDuration
        }
    }

    Button {
        css(generateButton.extend {
            if (textOrTopic.isNotBlank()) {
                backgroundColor = Color("#adc6ff")
            }
        })
        Icon {
            src = "ic_magic_wand_24x24.svg"
        }
        onClick = f@{
            val languageCode = ELanguage.fromText(languageToLearn)?.code
            if (audioToGenerateText == null || languageCode == null) {
                return@f
            }
            dispatch(StartModalLoading("Generating text"))
            GlobalScope.launch {
                val response = getTextFromSound(audioToGenerateText, languageCode, 5)
                dispatch(EndModalLoading())
                if (response == null) {
                    dispatch(OpenErrorModal("Can't generate text"))
                    return@launch
                }

                dispatch(SetCurrentRequestTextOrTopic(response))
            }
        }
        +"Get text"
    }

    if (textOrTopic.isBlank()) {
        return@FC
    }

    ReadyText {}
}