package shared.canvas

import online.interactiver.common.interactivepicture.Rect
import pages.constructor.ui.constructorBgColor
import react.FC
import react.Props
import reactkonva.Circle
import reactkonva.Group
import reactkonva.Rect

enum class HintBadgeHorizontalAnchor {
    Left, Right
}

enum class HintBadgeVerticalAnchor {
    Top, Bottom
}

external interface HintBadgeProps : Props {
    var x: Number
    var y: Number
    var rect: Rect?
    var horizontalAnchor: HintBadgeHorizontalAnchor?
    var verticalAnchor: HintBadgeVerticalAnchor?
    var radius: Int?
    var offset: Int?
}

val HintBadge = FC<HintBadgeProps> { props ->
    val rectX = props.rect?.leftTopPosition?.absolutePosition?.x ?: 0
    val rectY = props.rect?.leftTopPosition?.absolutePosition?.y ?: 0
    val rectWidth = props.rect?.width ?: 0
    val rectHeight = props.rect?.height ?: 0

    val radius = props.radius ?: 10
    val offset = props.offset ?: 5

    val centerX = when (props.horizontalAnchor) {
        HintBadgeHorizontalAnchor.Left -> rectX - radius - offset
        HintBadgeHorizontalAnchor.Right -> rectX + rectWidth + radius + offset
        else -> 0
    }
    val centerY = when (props.verticalAnchor) {
        HintBadgeVerticalAnchor.Bottom -> rectY + rectHeight + radius + offset
        HintBadgeVerticalAnchor.Top -> rectY - radius - offset
        else -> 0
    }

    val iBottomRectWidth = radius / 3
    val iBottomRectX = centerX - iBottomRectWidth / 2
    val iBottomRectY = centerY - iBottomRectWidth / 2
    val iBottomRectHeight = (radius / 1.3).toInt()

    val iTopRectWidth = iBottomRectWidth
    val iTopRectHeight = (iTopRectWidth / 1.2).toInt()
    val iTopRectX = iBottomRectX
    val iTopRectY = iBottomRectY - iBottomRectWidth / 2 - iTopRectHeight

    Group {
        x = props.x
        y = props.y
        Circle {
            x = centerX
            y = centerY
            this.radius = radius
            fill = constructorBgColor
        }
        Rect {
            x = iBottomRectX
            y = iBottomRectY
            width = iBottomRectWidth
            height = iBottomRectHeight
            fill = "#000"
        }
        Rect {
            x = iTopRectX
            y = iTopRectY
            width = iTopRectWidth
            height = iTopRectHeight
            fill = "#000"
        }
    }
}
