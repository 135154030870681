package entities.interactivePicture.elements.editors.activeStateEditor

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    padding = 20.px
    paddingTop = 0.px
    paddingBottom = 10.px
    width = 210.px
    borderBottom = Border(1.px, LineStyle.solid, Color("#D7D7D7"))
}
val activeStateEditorContainer: CssStyle = {
    height = 24.px
    width = 210.px
    borderRadius = 3.px
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
    whiteSpace = WhiteSpace.normal
    textAlign = TextAlign.left
    padding = 0.px
    marginBottom = 20.px
}