package widgets.CourseMaterials.ui.components.EditAndPlaySound

import app.useAppDispatch
import entities.errorModal.store.OpenErrorModal
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.FC
import react.Props
import react.useState
import shared.components.editSoundModal.EditSoundModal
import shared.components.editableSound.EditableSound
import widgets.LanguageAutoTask.ui.components.SlideEditModal.saveNewSound

external interface EditAndPlaySoundProps : Props {
    var onChange: (String) -> Unit
    var wordForSound: String
    var soundSrc: String?
}

val EditAndPlaySound = FC<EditAndPlaySoundProps> { props ->
    val (isModalOpened, setIsModalOpened) = useState(false)
    val dispatch = useAppDispatch()

    EditSoundModal {
        isOpen = isModalOpened
        onCancelClick = { setIsModalOpened(false) }
        onSaveSoundClick = {
            dispatch(StartModalLoading("Saving new audio..."))
            GlobalScope.launch {
                val saved = saveNewSound(it, props.wordForSound)
                dispatch(EndModalLoading())
                if (!saved) {
                    dispatch(OpenErrorModal(text = "Error saving new sound for ${props.wordForSound}"))
                    return@launch
                }

                props.onChange(it)
            }
        }
    }

    EditableSound {
        onEditClick = { setIsModalOpened(true) }
        soundSrc = props.soundSrc
    }
}