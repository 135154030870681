package shared.components.inputLabelledLimitedWithRadio

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.stretch
    gap = 5.px
}

val labelContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.center
}

val labelStyle: CssStyle = {
    color = Color("#282F3E")

    fontFamily = string("Montserrat")
    fontWeight = integer(400)
    fontSize = 14.px
    fontStyle = FontStyle.normal
}
