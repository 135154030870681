package widgets.Exercises.ui.components.ActionsForMultipleExercises

import antd.Button
import antd.Popover
import emotion.react.css
import online.interactiver.common.autogeneration.LanguageAutoGeneration
import pages.constructor.ui.components.elements.grayFilter
import react.FC
import react.Props
import react.createElement
import react.dom.html.ReactHTML.div
import react.router.dom.useSearchParams
import react.useState
import shared.components.Icon
import utils.types.extend
import utils.types.impl
import widgets.Exercises.ui.components.ExercisesAndFoldersDeleteModal.ExercisesAndFoldersDeleteModal
import widgets.Exercises.ui.components.FolderUserActions.FolderMovingModal.MovingModal

external interface ActionsForMultipleExercisesProps : Props {
    var chosenExercises: List<LanguageAutoGeneration>
    var updateExercises: (() -> Unit)?
    var closePopover: (() -> Unit)?
}

val ActionsForMultipleExercises = FC<ActionsForMultipleExercisesProps> { props ->
    val (isOpen, setIsOpen) = useState(false)

    if (props.chosenExercises.isEmpty()) {
        div {}
        return@FC
    }

    Popover {
        trigger = "click"
        align = impl {
            points = arrayOf("tl", "bl")
            offset = arrayOf(-20, 6)
        }
        content = createElement(ActionsContent, impl {
            chosenExercises = props.chosenExercises
            updateExercises = props.updateExercises
            closePopover = { setIsOpen(false) }
        })
        arrowPointAtCenter = false
        open = isOpen
        onOpenChange = { newOpen: Boolean ->
            setIsOpen(newOpen)
        }
        Button {
            +"Actions"
        }
    }
}

val ActionsContent = FC<ActionsForMultipleExercisesProps> { props ->
    val (searchParams, setSearchParams) = useSearchParams()

    val (isDeleteModalOpened, setIsDeleteModalOpened) = useState(false)
    val (isMoveModalOpened, setIsMoveModalOpened) = useState(false)

    ExercisesAndFoldersDeleteModal {
        isOpen = isDeleteModalOpened
        onClose = { setIsDeleteModalOpened(false) }
        requests = props.chosenExercises
        updateExercises = props.updateExercises
    }

    MovingModal {
        open = isMoveModalOpened
        onClose = { setIsMoveModalOpened(false) }
        generations = props.chosenExercises
        onMoved = {
            if (it == null) {
                searchParams.delete("parent_folder_id")
            } else {
                searchParams.set("parent_folder_id", it.toString())
            }
            setSearchParams(searchParams)

            props.updateExercises?.invoke()
        }
    }

    div {
        css(actions)
        Button {
            css(actionButton)
            Icon {
                css(grayFilter.extend(ic))
                src = "ic_move_24x24.svg"
            }
            onClick = {
                setIsMoveModalOpened(true)
                props.closePopover?.invoke()
            }
            +"Move"
        }
        Button {
            css(actionButton)
            Icon {
                css(grayFilter.extend(ic))
                src = "ic_delete_16x18.svg"
            }
            onClick = {
                setIsDeleteModalOpened(true)
                props.closePopover?.invoke()
            }
            +"Delete"
        }
    }
}