package entities.interactivePicture.elements.other

import online.interactiver.common.interactivepicture.GeometryStyle
import online.interactiver.common.interactivepicture.Point
import online.interactiver.common.interactivepicture.TextStyle

val hintGeometryStyle = GeometryStyle(
    opacity = 0.99,
    cornerRadius = 5,
    fillColor = "#334054",
    shadowColor = "#113275",
    shadowBlur = 10,
    shadowOpacity = 0.15,
    shadowOffset = Point(0,1),
)

val hintTextStyle = TextStyle(
    fontSize = 18,
    fontFamily = "Arial",
    textColor = "white",
    align = "center",
    padding = 5,
    lineHeight = 1.2,
)
