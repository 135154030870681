package shared.components.radio

import antd.Button
import emotion.react.css
import react.FC
import react.PropsWithClassName
import react.dom.html.ReactHTML.div
import shared.components.tooltipInfo.TooltipInfo

external interface RadioProps : PropsWithClassName {
    var onChange: (String) -> Unit
    var value: String
    var id: String
    var current: String
    var label: String?
    var disabled: Boolean?

    var tooltip: String?
}

val Radio = FC<RadioProps> { props ->
    div {
        css(tooltipWrapper)
        div {
            css(radioWithLabel)
            onClick = {
                if (props.current != props.value && props.disabled != true) {
                    props.onChange(props.value)
                }
            }
            Button {
                disabled = props.disabled
                if (props.current == props.value) css(activeRadio) else css(radio)
                id = props.id
                if (props.current == props.value) {
                    div {
                        css(radioCircle)
                    }
                }
            }
            props.label?.let {
                div {
                    className = props.className
                    +it
                }
            }
        }
        TooltipInfo {
            title = props.tooltip
        }
    }
}
