package entities.interactivePicture.elements

import app.UndoableAction
import builders.enums.*
import entities.interactivePicture.elements.other.hintTextStyle
import enums.EActiveStateType
import enums.EKeyboardArrows
import online.interactiver.common.interactivepicture.*
import online.interactiver.common.utils.TRUE_STRING
import online.interactiver.common.utils.clone
import shared.canvas.interfaces.*
import utils.structures.Position

open class InteractiveElementAction(override val preventHistoryUpdate: Boolean,
                                    override val preventFutureUpdate: Boolean = false) : UndoableAction(preventHistoryUpdate, preventFutureUpdate)
open class CommonInteractiveElementAction(override val preventHistoryUpdate: Boolean,
                                          override val preventFutureUpdate: Boolean = false) : InteractiveElementAction(preventHistoryUpdate, preventFutureUpdate)
data class TransformElement(val id: String, val geometry: Geometry) : CommonInteractiveElementAction(false)
data class TransformElementCurve(val id: String, val curve: Curve) : CommonInteractiveElementAction(false)
data class TransformElementGapPuzzle(val id: String, val geometry: Geometry) : CommonInteractiveElementAction(false)
data class RemoveElement(val id: String) : CommonInteractiveElementAction(false)
data class CopyElement(val id: String, val newId: String) : CommonInteractiveElementAction(false)
data class CopyMultipleElements(val ids: Array<String>, val newIds: Array<String>): CommonInteractiveElementAction(false)
data class RemoveMultipleElements(val ids: Array<String>): CommonInteractiveElementAction(false)
data class SetVisibleElementText(val id: String, val text: String) : CommonInteractiveElementAction(false)
data class SetVisibleElementRenderView(val id: String, val base64: String) : CommonInteractiveElementAction(true, true)
data class SetElementsHorizontalAlignForElements(val ids: Array<String>, val position: Int, val flag: String):
    CommonInteractiveElementAction( true)
data class SetElementsVerticalAlignForElements(val ids: Array<String>, val position: Int, val flag: String):
    CommonInteractiveElementAction(true)
data class SetElementsSpaceBetweenHorizontal(val id: String, val offset: Int): CommonInteractiveElementAction(true)
data class SetElementsSpaceBetweenVertical(val id: String, val offset: Int): CommonInteractiveElementAction(true)
data class SetHintText(val id: String, val text: String) : CommonInteractiveElementAction(false)
data class SetHintRect(val id: String, val rect: Rect) : CommonInteractiveElementAction(true)
data class SetHintVerticalAlign(val id: String, val align: String?) : CommonInteractiveElementAction(false)
data class SetFillColor(val id: String, val color: String) : CommonInteractiveElementAction(false)
data class SetFillColorForElements(val ids: Array<String>, val color: String) : CommonInteractiveElementAction(false)
data class SetFillOpacity(val id: String, val opacity: Double, override val preventHistoryUpdate: Boolean) :
    CommonInteractiveElementAction(preventHistoryUpdate)
data class SetFillOpacityForElements(val ids: Array<String>, val opacity: Double, override val preventHistoryUpdate: Boolean) :
    CommonInteractiveElementAction(preventHistoryUpdate)

data class SetBorderColor(val id: String, val color: String) : CommonInteractiveElementAction(false)
data class SetBorderColorForElements(val ids: Array<String>, val color: String) : CommonInteractiveElementAction(false)
data class SetBorderWidth(val id: String, val width: Int) : CommonInteractiveElementAction(false)
data class SetBorderWidthForElements(val ids: Array<String>, val width: Int) : CommonInteractiveElementAction(false)
data class SetBorderDash(val id: String, val dash: Array<Double>?) : CommonInteractiveElementAction(false)
data class SetBorderDashForElements(val ids: Array<String>, val dash: Array<Double>?) : CommonInteractiveElementAction(false)
data class SetTextColor(val id: String, val color: String) : CommonInteractiveElementAction(false)
data class SetTextColorForElements(val ids: Array<String>, val color: String) : CommonInteractiveElementAction(false)
data class SetTextHorizontalAlign(val id: String, val align: String) : CommonInteractiveElementAction(false)
data class SetTextHorizontalAlignForElements(val ids: Array<String>, val align: String) : CommonInteractiveElementAction(false)
data class SetTextVerticalAlign(val id: String, val align: String) : CommonInteractiveElementAction(false)
data class SetTextVerticalAlignForElements(val ids: Array<String>, val align: String) : CommonInteractiveElementAction(false)
data class SetTextFontSize(val id: String, val size: Int, override val preventHistoryUpdate: Boolean) :
    CommonInteractiveElementAction(preventHistoryUpdate)
data class SetTextFontSizeForElements(val ids: Array<String>, val size: Int, override val preventHistoryUpdate: Boolean) :
    CommonInteractiveElementAction(preventHistoryUpdate)
data class SetTextPadding(val id: String, val size: Int, override val preventHistoryUpdate: Boolean) :
    CommonInteractiveElementAction(preventHistoryUpdate)
data class SetTextPaddingForElements(val ids: Array<String>, val size: Int, override val preventHistoryUpdate: Boolean) :
    CommonInteractiveElementAction(preventHistoryUpdate)
data class SetTextStyle(val id: String, val style: String) : CommonInteractiveElementAction(false)
data class SetTextStyleForElements(val ids: Array<String>, val style: String) : CommonInteractiveElementAction(false)
data class SetTextDecoration(val id: String, val style: String) : CommonInteractiveElementAction(false)
data class SetTextDecorationForElements(val ids: Array<String>, val style: String) : CommonInteractiveElementAction(false)
data class SetTextLineHeight(val id: String, val lineHeight: Double, override val preventHistoryUpdate: Boolean) : CommonInteractiveElementAction(preventHistoryUpdate)
data class SetTextLineHeightForElements(val ids: Array<String>, val lineHeight: Double, override val preventHistoryUpdate: Boolean) : CommonInteractiveElementAction(preventHistoryUpdate)
data class SetHintTextFontSize(val id: String, val size: Int, override val preventHistoryUpdate: Boolean) :
    CommonInteractiveElementAction(preventHistoryUpdate)
data class SetHintTextFontSizeForElements(val ids: Array<String>, val size: Int, override val preventHistoryUpdate: Boolean) :
    CommonInteractiveElementAction(preventHistoryUpdate)
data class SetHintFontStyle(val id: String, val style: String) :
    CommonInteractiveElementAction(false)
data class SetHintFontStyleForElements(val ids: Array<String>, val style: String ) :
    CommonInteractiveElementAction(false)
data class SetHintDecoration(val id: String, val style: String) :
    CommonInteractiveElementAction(false)
data class SetHintDecorationForElements(val ids: Array<String>, val style: String ) :
    CommonInteractiveElementAction(false)
data class SetHintLineHeight(val id: String, val lineHeight: Double, override val preventHistoryUpdate: Boolean) :
    CommonInteractiveElementAction(preventHistoryUpdate)
data class SetHintLineHeightForElements(val ids: Array<String>, val lineHeight: Double, override val preventHistoryUpdate: Boolean) :
    CommonInteractiveElementAction(preventHistoryUpdate)
data class SetHintHorizontalAlign(val id: String, val align: String) : CommonInteractiveElementAction(false)
data class SetHintHorizontalAlignForElements(val ids: Array<String>, val align: String) : CommonInteractiveElementAction(false)
data class SetHintTextPadding(val id: String, val size: Int, override val preventHistoryUpdate: Boolean) :
    CommonInteractiveElementAction(preventHistoryUpdate)
data class SetHintTextPaddingForElements(val ids: Array<String>, val size: Int, override val preventHistoryUpdate: Boolean) :
    CommonInteractiveElementAction(preventHistoryUpdate)

data class SetElementActiveState(val id: String, val activeState: String) : CommonInteractiveElementAction(false)
data class SetHintActiveState(val id: String, val activeState: String) : CommonInteractiveElementAction(false)
data class MoveSelectedElements(val ids: Array<String>, val direction: String) : CommonInteractiveElementAction(true)
data class SetCheckmarkPosition(val id: String, val checkmarkPosition: String) : CommonInteractiveElementAction(false)
data class SetCheckmarkPositionForElements(val ids: Array<String>, val checkmarkPosition: String) : CommonInteractiveElementAction(false)
data class SetHintVerticalAlignForElements(val ids: Array<String>, val align: String?) : CommonInteractiveElementAction(false)
data class SetElementActiveStateForElements(val ids: Array<String>, val activeState: String) : CommonInteractiveElementAction(false)
data class SetHintActiveStateForElements(val ids: Array<String>, val activeState: String) : CommonInteractiveElementAction(false)
data class SetHintTextForElements(val ids: Array<String>, val value: String) : CommonInteractiveElementAction(false)
data class SetPlaceholderForElements(val inputIds: Array<String>, val dragIds: Array<String>, val placeholder: String) : CommonInteractiveElementAction(false)
data class SetTextForElements(val ids: Array<String>, val text: String) : CommonInteractiveElementAction(false)
data class SetHintsAfterImport(val hintsText: String?) : CommonInteractiveElementAction(false)
data class SetSound(val id: String, val src: String, val fileName: String?) : CommonInteractiveElementAction(false)
data class DeleteSound(val id: String) : CommonInteractiveElementAction(false)
data class SetSoundPosition(val id: String, val position: String) : CommonInteractiveElementAction(false)
data class SetSoundPositionForElements(val ids: Array<String>, val position: String) : CommonInteractiveElementAction(false)
data class SetSoundActivation(val id: String, val activation: String) : CommonInteractiveElementAction(false)
data class SetSoundActivationForElements(val ids: Array<String>, val activation: String) : CommonInteractiveElementAction(false)
data class SetSoundShowing(val id: String, val showing: EActiveStateType?) : CommonInteractiveElementAction(false)
data class SetSoundShowingForElements(val ids: Array<String>, val showing: EActiveStateType?) : CommonInteractiveElementAction(false)
data class SetSoundSrcAndFilename(val id: String, val src: String, val altSrc: String?, val filename: String?) : CommonInteractiveElementAction(false)
data class SetSoundIconSize(val id: String, val iconSize: Int, override val preventHistoryUpdate: Boolean) : CommonInteractiveElementAction(preventHistoryUpdate)
data class SetSoundIconSizeForElements(val ids: Array<String>, val iconSize: Int, override val preventHistoryUpdate: Boolean) : CommonInteractiveElementAction(preventHistoryUpdate)
data class SetSyncingPhrase(val id: String, val isSynced: Boolean) : CommonInteractiveElementAction(false)
data class SetSyncingPhrases(val ids: Array<String>, val isSynced: Boolean) : CommonInteractiveElementAction(false)
data class SetPuzzleCustomInitialPosition(val id: String, val customPos: String?) : CommonInteractiveElementAction(false)
data class DragPhrase(val id: String, val newLeftTop: Point) : CommonInteractiveElementAction(false)

data class SetElementCode(val id: String, val code: String) : CommonInteractiveElementAction(false)
data class StretchEdgesOfElements(val ids: Array<String>, val stretchByPosition: Position, val label: String)
    : CommonInteractiveElementAction(false)

// Wrappers for the actions compatible with all arrays of interactive elements
val transformElementOfList = f@{ list: MutableList<InteractiveElement>, id: String,
                                 geometry: Geometry ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList
    newList[elementIndex] = newList[elementIndex].copy(
        visibleElement = newList[elementIndex].visibleElement.copy(
            geometry = geometry
        ),
        gapPuzzle = newList[elementIndex].gapPuzzle?.copy(
            gap = newList[elementIndex].gapPuzzle?.gap?.copy(
                geometry = newList[elementIndex].gapPuzzle?.gap?.geometry?.copy(
                    rect = newList[elementIndex].gapPuzzle?.gap?.geometry?.rect?.copy(
                        width = geometry.rect?.width,
                        height = geometry.rect?.height,
                    )
                )
            )
        )
    )
    newList
}

val transformElementGapPuzzleOfList = f@{ list: MutableList<InteractiveElement>, id: String,
                                          geometry: Geometry ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList
    newList[elementIndex] = newList[elementIndex].copy(
        gapPuzzle = newList[elementIndex].gapPuzzle?.copy(
            gap = newList[elementIndex].gapPuzzle?.gap?.copy(
                geometry = geometry
            )
        ),
        visibleElement = newList[elementIndex].visibleElement.copy(
            geometry = newList[elementIndex].visibleElement.geometry?.copy(
                rect = newList[elementIndex].visibleElement.geometry?.rect?.copy(
                    width = geometry.rect?.width,
                    height = geometry.rect?.height,
                )
            )
        )
    )
    newList
}

val setGapPuzzleCustomInitPositionOfList = f@{ list: MutableList<InteractiveElement>, id: String,
                                          customPos: String? ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList
    val toggledCustomFromDefault = customPos == "1" && newList[elementIndex].gapPuzzle?.customPuzzleInitialPosition == null
    newList[elementIndex] = newList[elementIndex].copy(
        gapPuzzle = newList[elementIndex].gapPuzzle?.copy(
            customPuzzleInitialPosition = customPos
        )
    )
    if (toggledCustomFromDefault) {
        newList[elementIndex].visibleElement.geometry?.rect?.leftTopPosition?.absolutePosition?.apply {
            this.x += COPIED_ELEMENT_OFFSET
            this.y += COPIED_ELEMENT_OFFSET
        }
    }
    newList
}

val transformElementCurveOfList = f@{ list: MutableList<InteractiveElement>, id: String,
                                      curve: Curve ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList
    newList[elementIndex] = newList[elementIndex].copy(
        visibleElement = newList[elementIndex].visibleElement.copy(
            geometry = newList[elementIndex].visibleElement.geometry?.copy(
                curve = curve
            )
        )
    )
    newList
}

val removeElementOfList = { list: MutableList<InteractiveElement>, id: String ->
    val newList = list.map { it.clone() }.toMutableList()
    newList.removeAll { it.identifier.id.equals(id) }
    newList
}

const val COPIED_ELEMENT_OFFSET = 20

val copyElementOfList = { list: MutableList<InteractiveElement>, id: String, newId: String ->
    val newList = list.map { it.clone() }.toMutableList()
    val copiedElement = newList.find { it.identifier.id.equals(id) }?.clone()
    copiedElement?.let {
        it.updateIdentifiersOfElement(newId)
        if (it.visibleElement.geometry?.circle != null) {
            it.visibleElement.geometry!!.circle!!.centerPosition!!.absolutePosition!!.x += COPIED_ELEMENT_OFFSET
            it.visibleElement.geometry!!.circle!!.centerPosition!!.absolutePosition!!.y += COPIED_ELEMENT_OFFSET
        }
        if (it.visibleElement.geometry?.rect != null) {
            it.visibleElement.geometry!!.rect!!.leftTopPosition!!.absolutePosition!!.x += COPIED_ELEMENT_OFFSET
            it.visibleElement.geometry!!.rect!!.leftTopPosition!!.absolutePosition!!.y += COPIED_ELEMENT_OFFSET
        }
        if (it.visibleElement.geometry?.curve != null) {
            it.visibleElement.geometry!!.curve!!.start!!.absolutePosition!!.x += COPIED_ELEMENT_OFFSET
            it.visibleElement.geometry!!.curve!!.middle!!.absolutePosition!!.x += COPIED_ELEMENT_OFFSET
            it.visibleElement.geometry!!.curve!!.end!!.absolutePosition!!.x += COPIED_ELEMENT_OFFSET
            it.visibleElement.geometry!!.curve!!.start!!.absolutePosition!!.y += COPIED_ELEMENT_OFFSET
            it.visibleElement.geometry!!.curve!!.middle!!.absolutePosition!!.y += COPIED_ELEMENT_OFFSET
            it.visibleElement.geometry!!.curve!!.end!!.absolutePosition!!.y += COPIED_ELEMENT_OFFSET
        }
        newList.add(it)
    }
    newList
}

val doMultipleForList = { list: MutableList<InteractiveElement>, ids: Array<String>,
                          transformation: (MutableList<InteractiveElement>, String) -> MutableList<InteractiveElement> ->
    var newList = list.map { it.clone() }.toMutableList()
    for (id in ids) {
        newList = transformation(newList, id)
    }
    newList
}
val doMultipleForListOnlyCopy = { list: MutableList<InteractiveElement>, ids: Array<String>, newIds: Array<String>,
                          transformation: (MutableList<InteractiveElement>, String, String) -> MutableList<InteractiveElement> ->
    var newList = list.map { it.clone() }.toMutableList()
    for (i in 0..ids.size){
        newList = transformation(newList, ids[i], newIds[i])
    }
    newList
}

val copyMultipleElements = { list: MutableList<InteractiveElement>, ids: Array<String>, newIds: Array<String> ->
    doMultipleForListOnlyCopy(list, ids, newIds, copyElementOfList)
}

val removeMultipleElements = { list: MutableList<InteractiveElement>, ids: Array<String> ->
    doMultipleForList(list, ids, removeElementOfList)
}

val setVisibleElementTextOfList = f@{ list: MutableList<InteractiveElement>, id: String, text: String ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList
    newList[elementIndex] = newList[elementIndex].copy(
        visibleElement = newList[elementIndex].visibleElement.copy(
            text = newList[elementIndex].visibleElement.text?.copy(
                simpleText = text
            )
        )
    )
    val typeStr = newList[elementIndex].visibleElement.type
    val elemType = EElementType.values().find { it.text == typeStr }!!
    val textToGenerateIdentifier = "${elemType.shortName} ${elemType.textToDefineIdentifier.textField(newList[elementIndex])}"
    newList[elementIndex].identifier.setIdentifierByText(
        textToGenerateIdentifier
    )
    newList[elementIndex].visibleElement.identifier.setIdentifierByText(
        textToGenerateIdentifier + " visibleElement"
    )

    newList
}

val setVisibleElementRenderView = f@{ list: MutableList<InteractiveElement>, id: String, base64: String ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList
    newList[elementIndex] = newList[elementIndex].copy(
        visibleElement = newList[elementIndex].visibleElement.copy(
            text = newList[elementIndex].visibleElement.text?.copy(
                renderView = Picture(base64 = base64)
            )
        )
    )

    newList
}

val setHintTextOfList = f@{ list: MutableList<InteractiveElement>, id: String, text: String ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList
    newList[elementIndex] = newList[elementIndex].copy(
        hint = newList[elementIndex].hint.copy(
            text = newList[elementIndex].hint.text?.copy(
                simpleText = text
            ) ?: TextFrame(
                simpleText = text,
                style = hintTextStyle
            )
        )
    )
    val typeStr = newList[elementIndex].visibleElement.type
    val elemType = EElementType.values().find { it.text == typeStr }!!
    val textToGenerateIdentifier = "${elemType.shortName} ${elemType.textToDefineIdentifier.textField(newList[elementIndex])}"
    newList[elementIndex].identifier.setIdentifierByText(
        textToGenerateIdentifier
    )
    newList[elementIndex].hint.identifier.setIdentifierByText(
        textToGenerateIdentifier + " hint"
    )

    newList
}

val setHintVerticalAlignOfList = f@{ list: MutableList<InteractiveElement>, id: String, align: String? ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList
    newList[elementIndex] = newList[elementIndex].copy(
        hint = newList[elementIndex].hint.copy(
            geometry = newList[elementIndex].hint.geometry?.copy(
                rect = newList[elementIndex].hint.geometry?.rect?.copy(
                    verticalAlign = align
                ) ?: Rect (
                    verticalAlign = align
                )
            ) ?: Geometry(
                rect = Rect(
                    verticalAlign = align
                )
            )
        )
    )

    newList
}

val addElementToList = { list: MutableList<InteractiveElement>, element: InteractiveElement ->
    val newList = list.map { it.clone() }.toMutableList()
    newList.add(element)
    newList
}

val transformGeometryStyleOfList = f@{ list: MutableList<InteractiveElement>, id: String,
                                       transformation: (GeometryStyle?) -> GeometryStyle? ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList

    val newUsualStyle = transformation(newList[elementIndex].getElementStyle(false, EStyleType.USUAL))

    val newFocusStyle = newUsualStyle?.toOnFocus()
    val newSelectStyle = newUsualStyle?.toOnSelect()
    val newHoverStyle = newUsualStyle?.toOnHover()

    if ((newList[elementIndex].visibleElement.type == "LineStatic" || newList[elementIndex].visibleElement.type == "ArrowStatic") && (newUsualStyle?.strokeWidth == 0)){
        return@f newList
    }

    newList[elementIndex] = newList[elementIndex].copy(
        visibleElement = newList[elementIndex].visibleElement.copy(
            style = newList[elementIndex].visibleElement.style?.copy(
                usual = newUsualStyle,
                onHover = newHoverStyle,
                onFocus = newFocusStyle,
                onSelect = newSelectStyle
            )
        )
    )

    newList
}

fun colorToRgba(color: String, opacity: Double): String {
    if (color.startsWith("#")) {
        var hexString = color.removePrefix("#")
        if (hexString.length == 3) {
            hexString = hexString.map { "$it$it" }.joinToString("")
        }
        val rgbValue = hexString.toInt(16)
        val red = (rgbValue shr 16 and 0xFF)
        val green = (rgbValue shr 8 and 0xFF)
        val blue = (rgbValue and 0xFF)
        return "rgba($red,$green,$blue,$opacity)"
    } else if (color.startsWith("rgba(")) {
        val rgbaString = color.removePrefix("rgba(").removeSuffix(")").split(",")
        val red = rgbaString[0].toInt()
        val green = rgbaString[1].toInt()
        val blue = rgbaString[2].toInt()
        return "rgba($red,$green,$blue,$opacity)"
    }
    return color
}
fun setFillColorOfList(list: MutableList<InteractiveElement>, id: String, color: String): MutableList<InteractiveElement> {
    return transformGeometryStyleOfList(list, id) { style ->
        val rgbaColor = colorToRgba(color, style?.opacity ?: 1.0)
        style?.copy(fillColor = rgbaColor)
    }
}
fun setFillColorOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, color: String): MutableList<InteractiveElement> {
    var newList = list
    for (id in ids){
        newList = setFillColorOfList(newList, id, color)
    }
    return newList
}


fun setFillOpacityOfList(list: MutableList<InteractiveElement>, id: String, opacity: Double) =
    transformGeometryStyleOfList(list, id) { style ->
        val rgbaFillColor = colorToRgba(style?.fillColor ?: "#ffffff", opacity)
        style?.copy(fillColor = rgbaFillColor, opacity = opacity)
    }
fun setFillOpacityOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, opacity: Double): MutableList<InteractiveElement>{
    var newList = list
    for (id in ids){
        newList = setFillOpacityOfList(newList, id, opacity)
    }
    return newList
}

fun setBorderColorOfList(list: MutableList<InteractiveElement>, id: String, color: String) =
    transformGeometryStyleOfList(list, id) { style ->
        style?.copy(strokeColor = color)
    }
fun setBorderColorOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, color: String): MutableList<InteractiveElement> {
    var newList = list
    for (id in ids){
        newList = setBorderColorOfList(newList, id, color)
    }
    return newList
}

fun setBorderWidthOfList(list: MutableList<InteractiveElement>, id: String, width: Int) =
    transformGeometryStyleOfList(list, id) { style ->
        style?.copy(strokeWidth = width)
    }
fun setBorderWidthOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, width: Int): MutableList<InteractiveElement> {
    var newList = list
    for (id in ids){
        newList = setBorderWidthOfList(newList, id, width)
    }
    return newList
}

fun setBorderDashOfList(list: MutableList<InteractiveElement>, id: String, dash: Array<Double>?) =
    transformGeometryStyleOfList(list, id) { style ->
        style?.copy(dash = dash?.toMutableList())
    }

fun setBorderDashOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, dash: Array<Double>?): MutableList<InteractiveElement> {
    var newList = list
    for (id in ids) {
        newList = setBorderDashOfList(newList, id, dash)
    }
    return newList
}

val transformTextStyleOfList = f@{ list: MutableList<InteractiveElement>, id: String,
                                   transformation: (TextStyle?) -> TextStyle? ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList

    val newTextStyle = transformation(newList[elementIndex].visibleElement.text?.style)

    if (newList[elementIndex].visibleElement.type == "MarkerStatic" && (newTextStyle?.align != "center" || newTextStyle.verticalAlign != "middle")){
        return@f newList
    }

    newList[elementIndex] = newList[elementIndex].copy(
        visibleElement = newList[elementIndex].visibleElement.copy(
            text = newList[elementIndex].visibleElement.text?.copy(
                style = newTextStyle
            )
        )
    )

    newList
}

fun setTextColorOfList(list: MutableList<InteractiveElement>, id: String, color: String) =
    transformTextStyleOfList(list, id) { style ->
        style?.copy(textColor = color)
    }
fun setTextColorOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, color: String): MutableList<InteractiveElement> {
    var newList = list
    for (id in ids){
        newList = setTextColorOfList(newList, id, color)
    }
    return newList
}

fun setTextHorizontalAlignOfList(list: MutableList<InteractiveElement>, id: String, align: String) =
    transformTextStyleOfList(list, id) { style ->
        style?.copy(align = align)
    }
fun setTextHorizontalAlignOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, align: String): MutableList<InteractiveElement> {
    var newList = list
    for (id in ids){
        newList = setTextHorizontalAlignOfList(newList, id, align)
    }
    return newList
}

fun setTextVerticalAlignOfList(list: MutableList<InteractiveElement>, id: String, align: String) =
    transformTextStyleOfList(list, id) { style ->
        style?.copy(verticalAlign = align)
    }
fun setTextVerticalAlignOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, align: String): MutableList<InteractiveElement> {
    var newList = list
    for (id in ids){
        newList = setTextVerticalAlignOfList(newList, id, align)
    }
    return newList
}

fun setTextFontSizeOfList(list: MutableList<InteractiveElement>, id: String, size: Int ): MutableList<InteractiveElement>{
    val newList = transformTextStyleOfList(list, id) { style ->
        style?.copy(fontSize = size)
    }
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList

    return newList

}
fun setTextFontSizeOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, size: Int): MutableList<InteractiveElement> {
    var newList = list
    for (id in ids){
        newList = setTextFontSizeOfList(newList, id, size)
    }
    return newList
}

fun setTextPaddingOfList(list: MutableList<InteractiveElement>, id: String, size: Int ): MutableList<InteractiveElement>{
    val newList = transformTextStyleOfList(list, id) { style ->
        style?.copy(padding = size)
    }
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList

    return newList

}
fun setTextPaddingOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, size: Int): MutableList<InteractiveElement> {
    var newList = list
    for (id in ids){
        newList = setTextPaddingOfList(newList, id, size)
    }
    return newList
}
fun setTextStyleOfList(list: MutableList<InteractiveElement>, id: String, fontStyle: String ): MutableList<InteractiveElement>{
    val newList = transformTextStyleOfList(list, id) { style ->
        style?.copy(fontStyle = fontStyle)
    }
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList

    return newList
}
fun setTextStyleOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, fontStyle: String): MutableList<InteractiveElement> {
    var newList = list
    for (id in ids){
        newList = setTextStyleOfList(newList, id, fontStyle)
    }
    return newList
}
fun setTextLineHeightOfList(list: MutableList<InteractiveElement>, id: String, lineHeight: Double ): MutableList<InteractiveElement>{
    val newList = transformTextStyleOfList(list, id) { style ->
        style?.copy(lineHeight = lineHeight)
    }
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList

    return newList
}
fun setTextLineHeightOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, lineHeight: Double): MutableList<InteractiveElement> {
    var newList = list
    for (id in ids){
        newList = setTextLineHeightOfList(newList, id, lineHeight)
    }
    return newList
}
fun setTextDecorationOfList(list: MutableList<InteractiveElement>, id: String, textDecoration: String ): MutableList<InteractiveElement>{
    val newList = transformTextStyleOfList(list, id) { style ->
        style?.copy(textDecoration = textDecoration)
    }
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList

    return newList
}
fun setTextDecorationOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, textDecoration: String): MutableList<InteractiveElement> {
    var newList = list
    for (id in ids){
        newList = setTextDecorationOfList(newList, id, textDecoration)
    }
    return newList
}
fun setHintTextFontSizeOfList(list: MutableList<InteractiveElement>, id: String, size: Int ): MutableList<InteractiveElement>{
    val newList = list
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList
    newList[elementIndex] = newList[elementIndex].copy(
        hint = newList[elementIndex].hint.copy(
            text = newList[elementIndex].hint.text?.copy(
                style = newList[elementIndex].hint.text?.style?.copy(
                    fontSize = size
                )
            )
        )
    )

    return newList

}
fun setHintTextFontSizeOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, size: Int): MutableList<InteractiveElement> {
    return setValueForElements(list, ids) { newList, id ->
        setHintTextFontSizeOfList(newList, id, size)
    }
}
fun setHintHorizontalAlignOfList(list: MutableList<InteractiveElement>, id: String, align: String ): MutableList<InteractiveElement>{
    val newList = list
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList
    newList[elementIndex] = newList[elementIndex].copy(
        hint = newList[elementIndex].hint.copy(
            text = newList[elementIndex].hint.text?.copy(
                style = newList[elementIndex].hint.text?.style?.copy(
                    align = align
                )
            )
        )
    )

    return newList

}
fun setHintHorizontalAlignOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, align: String): MutableList<InteractiveElement> {
    return setValueForElements(list, ids) { newList, id ->
        setHintHorizontalAlignOfList(newList, id, align)
    }
}

fun setHintLineHeightOfList(list: MutableList<InteractiveElement>, id: String, lineHeight: Double ): MutableList<InteractiveElement>{
    val newList = list
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList
    newList[elementIndex] = newList[elementIndex].copy(
        hint = newList[elementIndex].hint.copy(
            text = newList[elementIndex].hint.text?.copy(
                style = newList[elementIndex].hint.text?.style?.copy(
                    lineHeight = lineHeight
                )
            )
        )
    )

    return newList

}
fun setHintLineHeightOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, lineHeight: Double): MutableList<InteractiveElement> {
    return setValueForElements(list, ids) { newList, id ->
        setHintLineHeightOfList(newList, id, lineHeight)
    }
}
fun setHintFontStyleOfList(list: MutableList<InteractiveElement>, id: String, fontStyle: String ): MutableList<InteractiveElement>{
    val newList = list
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList
    newList[elementIndex] = newList[elementIndex].copy(
        hint = newList[elementIndex].hint.copy(
            text = newList[elementIndex].hint.text?.copy(
                style = newList[elementIndex].hint.text?.style?.copy(
                    fontStyle = fontStyle
                )
            )
        )
    )

    return newList

}
fun setHintFontStyleOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, style: String): MutableList<InteractiveElement> {
    return setValueForElements(list, ids) { newList, id ->
        setHintFontStyleOfList(newList, id, style)
    }
}

fun setHintDecorationOfList(list: MutableList<InteractiveElement>, id: String, textDecoration: String ): MutableList<InteractiveElement>{
    val newList = list
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList
    newList[elementIndex] = newList[elementIndex].copy(
        hint = newList[elementIndex].hint.copy(
            text = newList[elementIndex].hint.text?.copy(
                style = newList[elementIndex].hint.text?.style?.copy(
                    textDecoration = textDecoration
                )
            )
        )
    )

    return newList

}
fun setHintDecorationOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, textDecoration: String): MutableList<InteractiveElement> {
    return setValueForElements(list, ids) { newList, id ->
        setHintDecorationOfList(newList, id, textDecoration)
    }
}

fun setHintTextPaddingOfList(list: MutableList<InteractiveElement>, id: String, size: Int ): MutableList<InteractiveElement>{
    val newList = list
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList

    newList[elementIndex] = newList[elementIndex].copy(
        hint = newList[elementIndex].hint.copy(
            text = newList[elementIndex].hint.text?.copy(
                style = newList[elementIndex].hint.text?.style?.copy(
                    padding = size
                )
            )
        )
    )

    return newList

}
fun setHintTextPaddingOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, size: Int): MutableList<InteractiveElement> {
    return setValueForElements(list, ids) { newList, id ->
        setHintTextPaddingOfList(newList, id, size)
    }
}

fun setElementsHorizontalAlignElementsOfList(list: MutableList<InteractiveElement>, id: String, position: Int, alignType: String): MutableList<InteractiveElement> {
    val newList = list
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList
    var newPosition = position
    if (alignType == "Right"){
        newPosition = position.minus(newList[elementIndex].getElementBounds().width)
    } else if (alignType == "Center"){
        newPosition = position.minus((0.5*newList[elementIndex].getElementBounds().width).toInt())
    }

    when (newList[elementIndex].visibleElement.type){
        EElementType.MARKER_STATIC.text -> {
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCircleCenter {
                this!!.x = newPosition
            }
        }
        EElementType.LINE_STATIC.text -> {
            var startPosition = newPosition
            var middlePosition = newPosition
            var endPosition = newPosition
            if (newList[elementIndex].visibleElement.geometry?.curve?.start?.absolutePosition?.x == newList[elementIndex].getElementBounds().x) {
                middlePosition = newList[elementIndex].calculateForCurve { curve -> curve?.middle?.absolutePosition?.x?.minus(curve.start?.absolutePosition?.x!!.minus(newPosition))!! }
                endPosition = newList[elementIndex].calculateForCurve { curve -> curve?.end?.absolutePosition?.x?.minus(curve.start?.absolutePosition?.x!!.minus(newPosition))!! }
            } else if (newList[elementIndex].visibleElement.geometry?.curve?.middle?.absolutePosition?.x == newList[elementIndex].getElementBounds().x){
                startPosition = newList[elementIndex].calculateForCurve { curve -> curve?.start?.absolutePosition?.x?.minus(curve.middle?.absolutePosition?.x!!.minus(newPosition))!! }
                endPosition = newList[elementIndex].calculateForCurve { curve -> curve?.end?.absolutePosition?.x?.minus(curve.middle?.absolutePosition?.x!!.minus(newPosition))!! }
            } else {
                startPosition = newList[elementIndex].calculateForCurve { curve -> curve?.start?.absolutePosition?.x?.minus(curve.end?.absolutePosition?.x!!.minus(newPosition))!! }
                middlePosition = newList[elementIndex].calculateForCurve { curve -> curve?.middle?.absolutePosition?.x?.minus(curve.end?.absolutePosition?.x!!.minus(newPosition))!! }
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveStart {
                this!!.x = startPosition
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveMiddle {
                this!!.x = middlePosition
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveEnd {
                this!!.x = endPosition
            }
        }
        EElementType.ARROW_STATIC.text -> {
            var startPosition = newPosition
            var middlePosition = newPosition
            var endPosition = newPosition
            if (newList[elementIndex].visibleElement.geometry?.curve?.start?.absolutePosition?.x == newList[elementIndex].getElementBounds().x) {
                middlePosition = newList[elementIndex].calculateForCurve { curve -> curve?.middle?.absolutePosition?.x?.minus(curve.start?.absolutePosition?.x!!.minus(newPosition))!! }
                endPosition = newList[elementIndex].calculateForCurve { curve -> curve?.end?.absolutePosition?.x?.minus(curve.start?.absolutePosition?.x!!.minus(newPosition))!! }
            } else if (newList[elementIndex].visibleElement.geometry?.curve?.middle?.absolutePosition?.x == newList[elementIndex].getElementBounds().x){
                startPosition = newList[elementIndex].calculateForCurve { curve -> curve?.start?.absolutePosition?.x?.minus(curve.middle?.absolutePosition?.x!!.minus(newPosition))!! }
                endPosition = newList[elementIndex].calculateForCurve { curve -> curve?.end?.absolutePosition?.x?.minus(curve.middle?.absolutePosition?.x!!.minus(newPosition))!! }
            } else {
                startPosition = newList[elementIndex].calculateForCurve { curve -> curve?.start?.absolutePosition?.x?.minus(curve.end?.absolutePosition?.x!!.minus(newPosition))!! }
                middlePosition = newList[elementIndex].calculateForCurve { curve -> curve?.middle?.absolutePosition?.x?.minus(curve.end?.absolutePosition?.x!!.minus(newPosition))!! }
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveStart {
                this!!.x = startPosition
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveMiddle {
                this!!.x = middlePosition
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveEnd {
                this!!.x = endPosition
            }
        }
        else -> run {
            newList[elementIndex] = newList[elementIndex].transformVisibleElementRectPosition {
                this!!.x = newPosition
            }
            if (!newList[elementIndex].isDraggable()) {
                return@run
            }

            newList[elementIndex] = newList[elementIndex].transformGapPuzzlePosition {
                this?.x = newPosition
            }
        }

    }
    return newList
}
fun setElementsHorizontalAlignOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, position: Int, alignType: String): MutableList<InteractiveElement> {
    return setValueForElements(list, ids) { newList, id ->
        setElementsHorizontalAlignElementsOfList(newList, id, position, alignType)
    }
}

fun setElementsVerticalAlignElementsOfList(list: MutableList<InteractiveElement>, id: String, position: Int, alignType: String): MutableList<InteractiveElement> {
    val newList = list
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList
    var newPosition = position
    if (alignType == "Bottom"){
        newPosition = position.minus(newList[elementIndex].getElementBounds().height)
    } else if (alignType== "Middle"){
        newPosition = position.minus((0.5*newList[elementIndex].getElementBounds().height).toInt())
    }

    when (newList[elementIndex].visibleElement.type){
        EElementType.MARKER_STATIC.text -> {
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCircleCenter {
                this!!.y = newPosition
            }
        }
        EElementType.LINE_STATIC.text -> {
            var startPosition = newPosition
            var middlePosition = newPosition
            var endPosition = newPosition
            if (newList[elementIndex].visibleElement.geometry?.curve?.start?.absolutePosition?.y == newList[elementIndex].getElementBounds().y) {
                middlePosition = newList[elementIndex].calculateForCurve { curve -> curve?.middle?.absolutePosition?.y?.minus(curve.start?.absolutePosition?.y!!.minus(newPosition))!! }
                endPosition = newList[elementIndex].calculateForCurve { curve -> curve?.end?.absolutePosition?.y?.minus(curve.start?.absolutePosition?.y!!.minus(newPosition))!! }
            } else if (newList[elementIndex].visibleElement.geometry?.curve?.middle?.absolutePosition?.y == newList[elementIndex].getElementBounds().y){
                startPosition = newList[elementIndex].calculateForCurve { curve -> curve?.start?.absolutePosition?.y?.minus(curve.middle?.absolutePosition?.y!!.minus(newPosition))!! }
                endPosition = newList[elementIndex].calculateForCurve { curve -> curve?.end?.absolutePosition?.y?.minus(curve.middle?.absolutePosition?.y!!.minus(newPosition))!! }
            } else {
                startPosition = newList[elementIndex].calculateForCurve { curve -> curve?.start?.absolutePosition?.y?.minus(curve.end?.absolutePosition?.y!!.minus(newPosition))!! }
                middlePosition = newList[elementIndex].calculateForCurve { curve -> curve?.middle?.absolutePosition?.y?.minus(curve.end?.absolutePosition?.y!!.minus(newPosition))!! }
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveStart {
                this!!.y = startPosition
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveMiddle {
                this!!.y = middlePosition
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveEnd {
                this!!.y = endPosition
            }
        }
        EElementType.ARROW_STATIC.text -> {
            var startPosition = newPosition
            var middlePosition = newPosition
            var endPosition = newPosition
            if (newList[elementIndex].visibleElement.geometry?.curve?.start?.absolutePosition?.y == newList[elementIndex].getElementBounds().y) {
                middlePosition = newList[elementIndex].calculateForCurve { curve -> curve?.middle?.absolutePosition?.y?.minus(curve.start?.absolutePosition?.y!!.minus(newPosition))!! }
                endPosition = newList[elementIndex].calculateForCurve { curve -> curve?.end?.absolutePosition?.y?.minus(curve.start?.absolutePosition?.y!!.minus(newPosition))!! }
            } else if (newList[elementIndex].visibleElement.geometry?.curve?.middle?.absolutePosition?.y == newList[elementIndex].getElementBounds().y){
                startPosition = newList[elementIndex].calculateForCurve { curve -> curve?.start?.absolutePosition?.y?.minus(curve.middle?.absolutePosition?.y!!.minus(newPosition))!! }
                endPosition = newList[elementIndex].calculateForCurve { curve -> curve?.end?.absolutePosition?.y?.minus(curve.middle?.absolutePosition?.y!!.minus(newPosition))!! }
            } else {
                startPosition = newList[elementIndex].calculateForCurve { curve -> curve?.start?.absolutePosition?.y?.minus(curve.end?.absolutePosition?.y!!.minus(newPosition))!! }
                middlePosition = newList[elementIndex].calculateForCurve { curve -> curve?.middle?.absolutePosition?.y?.minus(curve.end?.absolutePosition?.y!!.minus(newPosition))!! }
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveStart {
                this!!.y = startPosition
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveMiddle {
                this!!.y = middlePosition
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveEnd {
                this!!.y = endPosition
            }
        }
        else -> run {
            newList[elementIndex] = newList[elementIndex].transformVisibleElementRectPosition {
                this!!.y = newPosition
            }
            if (!newList[elementIndex].isDraggable()) {
                return@run
            }

            newList[elementIndex] = newList[elementIndex].transformGapPuzzlePosition {
                this?.y = newPosition
            }
        }
    }
    return newList
}
fun setElementsVerticalAlignOfListForElements(list: MutableList<InteractiveElement>, ids: Array<String>, position: Int, alignType: String): MutableList<InteractiveElement> {
    return setValueForElements(list, ids) { newList, id ->
        setElementsVerticalAlignElementsOfList(newList, id, position, alignType)
    }
}

fun setElementsSpaceBetweenHorizontalOfList(list: MutableList<InteractiveElement>, id: String, offset: Int): MutableList<InteractiveElement>{
    val newList = list
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList
    when (newList[elementIndex].visibleElement.type){
        EElementType.MARKER_STATIC.text -> {
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCircleCenter {
                this!!.x = newList[elementIndex].getElementBounds().x - offset
            }
        }
        EElementType.LINE_STATIC.text -> {
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveStart {
                this!!.x = newList[elementIndex].visibleElement.geometry?.curve!!.start!!.absolutePosition!!.x- offset
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveMiddle {
                this!!.x = newList[elementIndex].visibleElement.geometry?.curve!!.middle!!.absolutePosition!!.x- offset
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveEnd {
                this!!.x = newList[elementIndex].visibleElement.geometry?.curve!!.end!!.absolutePosition!!.x - offset
            }
        }
        EElementType.ARROW_STATIC.text -> {
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveStart {
                this!!.x = newList[elementIndex].visibleElement.geometry?.curve!!.start!!.absolutePosition!!.x- offset
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveMiddle {
                this!!.x = newList[elementIndex].visibleElement.geometry?.curve!!.middle!!.absolutePosition!!.x- offset
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveEnd {
                this!!.x = newList[elementIndex].visibleElement.geometry?.curve!!.end!!.absolutePosition!!.x - offset
            }
        }
        else -> run {
            newList[elementIndex] = newList[elementIndex].transformVisibleElementRectPosition {
                this!!.x = newList[elementIndex].getElementBounds().x - offset
            }
            if (!newList[elementIndex].isDraggable()) {
                return@run
            }

            newList[elementIndex] = newList[elementIndex].transformGapPuzzlePosition {
                this?.x = newList[elementIndex].getElementBounds().x - offset
            }
        }
    }
    return newList
}
fun setElementsSpaceBetweenVerticalOfList(list: MutableList<InteractiveElement>, id: String, offset: Int): MutableList<InteractiveElement>{
    val newList = list
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList
    when (newList[elementIndex].visibleElement.type){
        EElementType.MARKER_STATIC.text -> {
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCircleCenter {
                this!!.y = newList[elementIndex].getElementBounds().y - offset
            }
        }
        EElementType.LINE_STATIC.text -> {
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveStart {
                this!!.y = newList[elementIndex].visibleElement.geometry?.curve!!.start!!.absolutePosition!!.y- offset
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveMiddle {
                this!!.y = newList[elementIndex].visibleElement.geometry?.curve!!.middle!!.absolutePosition!!.y- offset
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveEnd {
                this!!.y = newList[elementIndex].visibleElement.geometry?.curve!!.end!!.absolutePosition!!.y - offset
            }
        }
        EElementType.ARROW_STATIC.text -> {
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveStart {
                this!!.y = newList[elementIndex].visibleElement.geometry?.curve!!.start!!.absolutePosition!!.y- offset
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveMiddle {
                this!!.y = newList[elementIndex].visibleElement.geometry?.curve!!.middle!!.absolutePosition!!.y- offset
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveEnd {
                this!!.y = newList[elementIndex].visibleElement.geometry?.curve!!.end!!.absolutePosition!!.y - offset
            }
        }
        else -> run {
            newList[elementIndex] = newList[elementIndex].transformVisibleElementRectPosition {
                this!!.y = newList[elementIndex].getElementBounds().y - offset
            }
            if (!newList[elementIndex].isDraggable()) {
                return@run
            }

            newList[elementIndex] = newList[elementIndex].transformGapPuzzlePosition {
                this?.y = newList[elementIndex].getElementBounds().y - offset
            }
        }
    }
    return newList
}

val setElementAllTimeActive = f@{ newList: MutableList<InteractiveElement>, elementIndex: Int ->
    setElementShowCondition(
        newList, elementIndex, SolvingStageCondition(
            activeBeforeSolvingStart = "1",
            activeWhileSolving = "1",
            activeAfterFirstVerification = "1",
            activeAfterFinalVerification = "1",
        )
    )
}

val setHintAllTimeActive = f@{ newList: MutableList<InteractiveElement>, elementIndex: Int ->
    setHintShowCondition(
        newList, elementIndex, SolvingStageCondition(
            activeBeforeSolvingStart = "1",
            activeWhileSolving = "1",
            activeAfterFirstVerification = "1",
            activeAfterFinalVerification = "1",
        )
    )
}

val setElementBeforeCheckingActive = f@{ newList: MutableList<InteractiveElement>, elementIndex: Int ->
    setElementShowCondition(
        newList, elementIndex, SolvingStageCondition(
            activeBeforeSolvingStart = "1",
            activeWhileSolving = "1",
        )
    )
}

val setHintBeforeCheckingActive = f@{ newList: MutableList<InteractiveElement>, elementIndex: Int ->
    setHintShowCondition(
        newList, elementIndex, SolvingStageCondition(
            activeBeforeSolvingStart = "1",
            activeWhileSolving = "1",
        )
    )
}

val setElementAfterCheckingActive = f@{ newList: MutableList<InteractiveElement>, elementIndex: Int ->
    setElementShowCondition(
        newList, elementIndex, SolvingStageCondition(
            activeAfterFirstVerification = "1",
            activeAfterFinalVerification = "1",
        )
    )
}

val setHintAfterCheckingActive = f@{ newList: MutableList<InteractiveElement>, elementIndex: Int ->
    setHintShowCondition(
        newList, elementIndex, SolvingStageCondition(
            activeAfterFirstVerification = "1",
            activeAfterFinalVerification = "1",
        )
    )
}

val setElementShowCondition =
    { newList: MutableList<InteractiveElement>, elementIndex: Int, solvingStageCondition: SolvingStageCondition ->
        newList[elementIndex] = newList[elementIndex].copy(
            visibleElement = newList[elementIndex].visibleElement.copy(
                showCondition = newList[elementIndex].visibleElement.showCondition?.copy(
                    solvingStageCondition = solvingStageCondition
                )
            )
        )

        newList
    }

val setHintShowCondition =
    { newList: MutableList<InteractiveElement>, elementIndex: Int, solvingStageCondition: SolvingStageCondition ->
        newList[elementIndex] = newList[elementIndex].copy(
            hint = newList[elementIndex].hint.copy(
                showCondition = newList[elementIndex].hint.showCondition?.copy(
                    solvingStageCondition = solvingStageCondition
                )
            )
        )

        newList
    }

val setElementActiveState = f@{ list: MutableList<InteractiveElement>, id: String, activeState: String ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList
    when (activeState) {
        EActiveStateType.ALL_TIME.text -> setElementAllTimeActive(newList, elementIndex)
        EActiveStateType.BEFORE_CHECKING.text -> setElementBeforeCheckingActive(newList, elementIndex)
        EActiveStateType.AFTER_CHECKING.text -> setElementAfterCheckingActive(newList, elementIndex)
    }

    newList
}

val setHintActiveState = f@{ list: MutableList<InteractiveElement>, id: String, activeState: String ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList
    when (activeState) {
        EActiveStateType.ALL_TIME.text -> setHintAllTimeActive(newList, elementIndex)
        EActiveStateType.BEFORE_CHECKING.text -> setHintBeforeCheckingActive(newList, elementIndex)
        EActiveStateType.AFTER_CHECKING.text -> setHintAfterCheckingActive(newList, elementIndex)
    }

    newList
}

val setHintRectOfList = f@{ list: MutableList<InteractiveElement>, id: String, rect: Rect ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList
    newList[elementIndex] = newList[elementIndex].copy(
        hint = newList[elementIndex].hint.copy(
            geometry = Geometry(
                rect = rect
            )
        )
    )
    newList
}

val moveSelectedElements = { list: MutableList<InteractiveElement>, ids: Array<String>, direction: String ->
    val newList = list.map { it.clone() }.toMutableList()
    val speed = 1
    val moving = when (direction) {
        EKeyboardArrows.RIGHT.value -> Point(speed, 0)
        EKeyboardArrows.LEFT.value -> Point(-speed, 0)
        EKeyboardArrows.UP.value -> Point(0, -speed)
        EKeyboardArrows.DOWN.value -> Point(0, speed)
        else -> Point(0, 0)
    }
    fun moveGeometry (geometry: Geometry?): Geometry? {
        if (geometry?.rect != null) {
            geometry.rect?.leftTopPosition?.absolutePosition?.x = moving.x + (geometry.rect?.leftTopPosition?.absolutePosition?.x ?: 0)
            geometry.rect?.leftTopPosition?.absolutePosition?.y = moving.y + (geometry.rect?.leftTopPosition?.absolutePosition?.y ?: 0)
        } else if (geometry?.circle != null) {
            geometry.circle?.centerPosition?.absolutePosition?.x = moving.x + (geometry.circle?.centerPosition?.absolutePosition?.x ?: 0)
            geometry.circle?.centerPosition?.absolutePosition?.y = moving.y + (geometry.circle?.centerPosition?.absolutePosition?.y ?: 0)
        } else if (geometry?.curve != null) {
            geometry.curve?.start?.absolutePosition?.x = moving.x + (geometry.curve?.start?.absolutePosition?.x ?: 0)
            geometry.curve?.start?.absolutePosition?.y = moving.y + (geometry.curve?.start?.absolutePosition?.y ?: 0)
            geometry.curve?.middle?.absolutePosition?.x = moving.x + (geometry.curve?.middle?.absolutePosition?.x ?: 0)
            geometry.curve?.middle?.absolutePosition?.y = moving.y + (geometry.curve?.middle?.absolutePosition?.y ?: 0)
            geometry.curve?.end?.absolutePosition?.x = moving.x + (geometry.curve?.end?.absolutePosition?.x ?: 0)
            geometry.curve?.end?.absolutePosition?.y = moving.y + (geometry.curve?.end?.absolutePosition?.y ?: 0)
        }
        return geometry
    }
    for (id in ids) {
        val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
        if (elementIndex == -1) continue
        val geometry = moveGeometry(newList[elementIndex].visibleElement.geometry?.copy())
        newList[elementIndex] = newList[elementIndex].copy(
            visibleElement = newList[elementIndex].visibleElement.copy(
                geometry = geometry
            )
        )
        if (!newList[elementIndex].isDraggable()) continue
        newList[elementIndex] = newList[elementIndex].copy(
            gapPuzzle = newList[elementIndex].gapPuzzle?.copy(
                gap = newList[elementIndex].gapPuzzle?.gap?.copy(
                    geometry = geometry
                )
            )
        )
    }
    newList
}

val setCheckmarkPosition = f@{ list: MutableList<InteractiveElement>, id: String, checkmarkPosition: String ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList

    when (newList[elementIndex].type) {
        EInteractiveType.INPUT_INTERACTIVE.text -> {
            newList[elementIndex] = newList[elementIndex].copy(
                input = newList[elementIndex].input?.copy(
                    checkmarkPosition = checkmarkPosition,
                ),
            )
        }
        EInteractiveType.BUTTON_INTERACTIVE.text -> {
            newList[elementIndex] = newList[elementIndex].copy(
                button = newList[elementIndex].button?.copy(
                    checkmarkPosition = checkmarkPosition,
                ),
            )
        }
        EInteractiveType.GAP_PUZZLE_INTERACTIVE.text -> {
            newList[elementIndex] = newList[elementIndex].copy(
                gapPuzzle = newList[elementIndex].gapPuzzle?.copy(
                    checkmarkPosition = checkmarkPosition,
                ),
            )
        }
    }


    newList
}


fun setValueForElements(
    list: MutableList<InteractiveElement>,
    ids: Array<String>,
    setter: (MutableList<InteractiveElement>, String) -> MutableList<InteractiveElement>
): MutableList<InteractiveElement> {
    var newList = list
    for (id in ids) {
        newList = setter(newList, id)
    }

    return newList
}

val setCheckmarkPositionForElements = { list: MutableList<InteractiveElement>, ids: Array<String>, checkmarkPosition: String ->
    setValueForElements(list, ids) { newList, id ->
        setCheckmarkPosition(newList, id, checkmarkPosition)
    }
}

val setHintVerticalAlignForElements = { list: MutableList<InteractiveElement>, ids: Array<String>, align: String? ->
    setValueForElements(list, ids) { newList, id ->
        setHintVerticalAlignOfList(newList, id, align)
    }
}

val setElementActiveStateForElements = { list: MutableList<InteractiveElement>, ids: Array<String>, activeState: String ->
    setValueForElements(list, ids) { newList, id ->
        setElementActiveState(newList, id, activeState)
    }
}

val setHintActiveStateForElements = { list: MutableList<InteractiveElement>, ids: Array<String>, activeState: String ->
    setValueForElements(list, ids) { newList, id ->
        setHintActiveState(newList, id, activeState)
    }
}

val setHintTextForElements = { list: MutableList<InteractiveElement>, ids: Array<String>, value: String ->
    setValueForElements(list, ids) { newList, id ->
        setHintTextOfList(newList, id, value)
    }
}

val setPlaceholderForElements = { list: MutableList<InteractiveElement>, inputIds: Array<String>, dragIds: Array<String>, placeholder: String ->
    val firstList = setValueForElements(list, inputIds) { newList, id ->
        entities.interactivePicture.elements.controls.inputs.setPlaceholder(newList, id, placeholder)
    }
    setValueForElements(firstList, dragIds) { newList, id ->
        entities.interactivePicture.elements.gapPuzzles.drags.setPlaceholder(newList, id, placeholder)
    }
}

val setTextForElements = { list: MutableList<InteractiveElement>, ids: Array<String>, text: String ->
    setValueForElements(list, ids) {newList, id ->
        setVisibleElementTextOfList(newList, id, text)
    }
}

val setPlaceholder = f@{ list: MutableList<InteractiveElement>, id: String, placeholder: String ->
    val element = list.find { it.identifier.id.equals(id) } ?: return@f list

    when (element.type) {
        EInteractiveType.INPUT_INTERACTIVE.text -> entities.interactivePicture.elements.controls.inputs.setPlaceholder(list, id, placeholder)
        EInteractiveType.GAP_PUZZLE_INTERACTIVE.text -> entities.interactivePicture.elements.gapPuzzles.drags.setPlaceholder(list, id, placeholder)
        else -> list
    }
}
fun setHintsTextAfterImport(list: MutableList<InteractiveElement>, hintsText: String?) : MutableList<InteractiveElement> {
    if (hintsText == null) {
        return list
    }
    val getKey = { key: String ->
        key.substring(1, key.indexOf(":")).trim()
    }
    val getValue = { value: String ->
        value.trim()
    }
    var newList = list
    hintsText
        .split("}")
        .filter { it.contains("{") }
        .forEach {element ->
            val parts = element.split("\"\"\"")
            val idArr = parts.firstOrNull()?.split("\"") ?: return@forEach

            val id = idArr[1]
            val fields = listOf(idArr.last()) + parts.subList(1, parts.size - 1)
            var i = 0
            val map = mutableMapOf<String, String>()
            while (i + 1 < fields.size) {
                map[getKey(fields[i])] = getValue(fields[i + 1])
                i += 2
            }
            map.forEach {
                when (it.key) {
                    "visible" -> newList = setVisibleElementTextOfList(newList, id, it.value)
                    "hint" -> newList = setHintTextOfList(newList, id, it.value)
                    "placeholder" -> newList = setPlaceholder(newList, id, it.value)
                }
            }
        }

    return newList
}

fun doForSoundOfList(list: MutableList<InteractiveElement>, id: String, transformation: (Sound?) -> Sound?): MutableList<InteractiveElement> {
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList
    newList[elementIndex] = newList[elementIndex].copy(
        sound = transformation(newList[elementIndex].sound)
    )
    return newList
}

fun setSoundOfList(list: MutableList<InteractiveElement>, id: String, sound: Sound?) = doForSoundOfList(list, id) { sound }

fun deleteSoundOfList(list: MutableList<InteractiveElement>, id: String) = setSoundOfList(list, id, null)

fun setSoundPositionOfList(list: MutableList<InteractiveElement>, id: String, position: String?) = doForSoundOfList(list, id) {
    it?.copy(
        badgePosition = position
    )
}

fun setSoundActivationOfList(list: MutableList<InteractiveElement>, id: String, activation: String?) = doForSoundOfList(list, id) {
    it?.copy(
        activation = activation
    )
}

fun setSoundShowingOfList(list: MutableList<InteractiveElement>, id: String, showing: EActiveStateType?) = doForSoundOfList(list, id) {
    val res = it?.copy(
        showCondition = it.showCondition?.copy(
            solvingStageCondition = showing?.toSolvingStageCondition() ?: SolvingStageCondition()
        ) ?: ShowCondition(
            solvingStageCondition = showing?.toSolvingStageCondition() ?: SolvingStageCondition()
        )
    )
    res
}

fun setSoundIconSizeOfList(list: MutableList<InteractiveElement>, id: String, iconSize: Int) = doForSoundOfList(list, id) {
    it?.copy(
        iconSize = iconSize
    )
}

fun setSoundIconSizeForElements(
    list: MutableList<InteractiveElement>,
    ids: Array<String>, iconSize: Int
) = setValueForElements(list, ids) { newList, id ->
    setSoundIconSizeOfList(newList, id, iconSize)
}

fun setSoundActivationForElements(
    list: MutableList<InteractiveElement>,
    ids: Array<String>,
    activation: String
) = setValueForElements(list, ids) { newList, id ->
    setSoundActivationOfList(newList, id, activation)
}

fun setSoundShowingForElements(
    list: MutableList<InteractiveElement>,
    ids: Array<String>,
    showing: EActiveStateType?
) = setValueForElements(list, ids) { newList, id ->
    setSoundShowingOfList(newList, id, showing)
}

fun setSoundPositionForElements(
    list: MutableList<InteractiveElement>,
    ids: Array<String>,
    position: String
) = setValueForElements(list, ids) { newList, id ->
    setSoundPositionOfList(newList, id, position)
}

fun setSoundSrcAndFilenameOfList(list: MutableList<InteractiveElement>, id: String, src: String, altSrc: String?, filename: String?) =
    doForSoundOfList(list, id) {
        it?.copy(
            src = src,
            filename = filename
        ) ?: Sound(src, altSrc, filename, ESoundBadgePosition.DEFAULT.textValue, ESoundActivation.DEFAULT.textValue)
    }

val setSyncingPhraseOfList = f@{ list: MutableList<InteractiveElement>, id: String, isSynced: Boolean ->
    val newList = list.map { it.clone() }.toMutableList()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1 || newList[elementIndex].isSynced == null) return@f newList
    newList[elementIndex] = newList[elementIndex].copy(
        isSynced = isSynced
    )
    newList
}

val setSyncingPhrases = { list: MutableList<InteractiveElement>, ids: Array<String>, isSynced: Boolean ->
    setValueForElements(list, ids) { newList, id ->
        setSyncingPhraseOfList(newList, id, isSynced)
    }
}

fun doForPhraseOfList(list: MutableList<InteractiveElement>, id: String, transform: (Phrase?) -> Phrase?): MutableList<InteractiveElement> {
    val newList = list.clone()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList
    newList[elementIndex] = newList[elementIndex].copy(
        phrase = transform(newList[elementIndex].phrase)
    )
    return newList
}

fun dragPhraseOfList(list: MutableList<InteractiveElement>, id: String, newLeftTop: Point): MutableList<InteractiveElement> =
    doForPhraseOfList(list, id) { it?.copy(leftTop = newLeftTop) }

val setElementCode = f@{ list: MutableList<InteractiveElement>, id: String, code: String ->
    val newList = list.clone()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return@f newList
    newList[elementIndex] = newList[elementIndex].copy(
        identifier = newList[elementIndex].identifier.copy(
            code = code
        )
    )

    if (newList[elementIndex].identifier.codeEditedManually != TRUE_STRING) {
        newList[elementIndex] = newList[elementIndex].copy(
            identifier = newList[elementIndex].identifier.copy(
                codeEditedManually = TRUE_STRING
            )
        )
    }

    newList
}

val addCopiedElement = { list: MutableList<InteractiveElement>, id: String, element: InteractiveElement ->
    val newList = list.clone()
    element.updateIdentifiersOfElement(id)
    newList.add(element)
    newList
}

fun stretchElementToLeft(list: MutableList<InteractiveElement>, id: String, stretchByPosition: Position): MutableList<InteractiveElement> {
    val newList = list.clone()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList

    val elementPosition = newList[elementIndex].getElementBounds()

    when(newList[elementIndex].visibleElement.type) {
        EElementType.MARKER_STATIC.text -> {
            val newRadius = (elementPosition.x - stretchByPosition.x + elementPosition.width) / 2
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCircleCenter {
                this?.x = stretchByPosition.x
                this?.y = elementPosition.y + elementPosition.width / 2 - newRadius
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCircle {
                this?.radius = newRadius
            }
        }
        EElementType.LINE_STATIC.text, EElementType.ARROW_STATIC.text -> run {
            val startX = newList[elementIndex].visibleElement.geometry?.curve?.start?.absolutePosition?.x
            val endX = newList[elementIndex].visibleElement.geometry?.curve?.end?.absolutePosition?.x
            if (startX == null || endX == null || startX == endX) {
                return@run
            }

            if (startX > endX) {
                newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveEnd {
                    this?.x = stretchByPosition.x
                }
                newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveMiddle {
                    this?.x = (stretchByPosition.x + startX) / 2
                }
                return@run
            }

            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveStart {
                this?.x = stretchByPosition.x
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveMiddle {
                this?.x = (stretchByPosition.x + endX) / 2
            }
        }
        else -> run {
            val newWidth = elementPosition.width + elementPosition.x - stretchByPosition.x
            val newX = stretchByPosition.x
            newList[elementIndex] = newList[elementIndex].transformVisibleElementRect {
                this?.width = newWidth
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementRectPosition {
                this?.x = newX
            }
            if (!newList[elementIndex].isDraggable()) {
                return@run
            }

            newList[elementIndex] = newList[elementIndex].transformGapPuzzleRect {
                this?.width = newWidth
            }
            newList[elementIndex] = newList[elementIndex].transformGapPuzzlePosition {
                this?.x = newX
            }
        }
    }

    return newList
}

fun stretchElementToRight(list: MutableList<InteractiveElement>, id: String, stretchByPosition: Position): MutableList<InteractiveElement> {
    val newList = list.clone()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList

    val elementPosition = newList[elementIndex].getElementBounds()

    when(newList[elementIndex].visibleElement.type) {
        EElementType.MARKER_STATIC.text -> {
            val newRadius = (stretchByPosition.x + stretchByPosition.width - elementPosition.x) / 2
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCircleCenter {
                this?.y = elementPosition.y + elementPosition.width / 2 - newRadius
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCircle {
                this?.radius = newRadius
            }
        }
        EElementType.LINE_STATIC.text, EElementType.ARROW_STATIC.text -> run {
            val startX = newList[elementIndex].visibleElement.geometry?.curve?.start?.absolutePosition?.x
            val endX = newList[elementIndex].visibleElement.geometry?.curve?.end?.absolutePosition?.x
            if (startX == null || endX == null || startX == endX) {
                return@run
            }

            if (startX > endX) {
                newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveStart {
                    this?.x = stretchByPosition.x + stretchByPosition.width
                }
                newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveMiddle {
                    this?.x = (stretchByPosition.x + stretchByPosition.width + endX) / 2
                }
                return@run
            }

            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveEnd {
                this?.x = stretchByPosition.x + stretchByPosition.width
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveMiddle {
                this?.x = (stretchByPosition.x + stretchByPosition.width + startX) / 2
            }
        }
        else -> run {
            val newWidth = stretchByPosition.x + stretchByPosition.width - elementPosition.x
            newList[elementIndex] = newList[elementIndex].transformVisibleElementRect {
                this?.width = newWidth
            }
            if (!newList[elementIndex].isDraggable()) {
                return@run
            }

            newList[elementIndex] = newList[elementIndex].transformGapPuzzleRect {
                this?.width = newWidth
            }
        }
    }

    return newList
}

fun stretchElementToBottom(list: MutableList<InteractiveElement>, id: String, stretchByPosition: Position): MutableList<InteractiveElement> {
    val newList = list.clone()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList

    val elementPosition = newList[elementIndex].getElementBounds()

    when(newList[elementIndex].visibleElement.type) {
        EElementType.MARKER_STATIC.text -> {
            val newRadius = (stretchByPosition.y + stretchByPosition.height - elementPosition.y) / 2
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCircleCenter {
                this?.x = elementPosition.x + elementPosition.height / 2 - newRadius
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCircle {
                this?.radius = newRadius
            }
        }
        EElementType.LINE_STATIC.text, EElementType.ARROW_STATIC.text -> run {
            val startY = newList[elementIndex].visibleElement.geometry?.curve?.start?.absolutePosition?.y
            val endY = newList[elementIndex].visibleElement.geometry?.curve?.end?.absolutePosition?.y
            if (startY == null || endY == null || startY == endY) {
                return@run
            }

            if (startY > endY) {
                newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveStart {
                    this?.y = stretchByPosition.y + stretchByPosition.height
                }
                newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveMiddle {
                    this?.y = (stretchByPosition.y + stretchByPosition.height + endY) / 2
                }
                return@run
            }

            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveEnd {
                this?.y = stretchByPosition.y + stretchByPosition.height
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveMiddle {
                this?.y = (stretchByPosition.y + stretchByPosition.height + startY) / 2
            }
        }
        else -> run {
            val newHeight = stretchByPosition.y + stretchByPosition.height - elementPosition.y
            newList[elementIndex] = newList[elementIndex].transformVisibleElementRect {
                this?.height = newHeight
            }
            if (!newList[elementIndex].isDraggable()) {
                return@run
            }

            newList[elementIndex] = newList[elementIndex].transformGapPuzzleRect {
                this?.height = newHeight
            }
        }
    }

    return newList
}

fun stretchElementToTop(list: MutableList<InteractiveElement>, id: String, stretchByPosition: Position): MutableList<InteractiveElement> {
    val newList = list.clone()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList

    val elementPosition = newList[elementIndex].getElementBounds()

    when(newList[elementIndex].visibleElement.type) {
        EElementType.MARKER_STATIC.text -> {
            val newRadius = (elementPosition.y - stretchByPosition.y + elementPosition.height) / 2
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCircleCenter {
                this?.y = stretchByPosition.y
                this?.x = elementPosition.x + elementPosition.height / 2 - newRadius
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCircle {
                this?.radius = newRadius
            }
        }
        EElementType.LINE_STATIC.text, EElementType.ARROW_STATIC.text -> run {
            val startY = newList[elementIndex].visibleElement.geometry?.curve?.start?.absolutePosition?.y
            val endY = newList[elementIndex].visibleElement.geometry?.curve?.end?.absolutePosition?.y
            if (startY == null || endY == null || startY == endY) {
                return@run
            }

            if (startY > endY) {
                newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveEnd {
                    this?.y = stretchByPosition.y
                }
                newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveMiddle {
                    this?.y = (stretchByPosition.y + startY) / 2
                }
                return@run
            }

            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveStart {
                this?.y = stretchByPosition.y
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementCurveMiddle {
                this?.y = (stretchByPosition.y + endY) / 2
            }
        }
        else -> run {
            val newHeight = elementPosition.height + elementPosition.y - stretchByPosition.y
            val newY = stretchByPosition.y
            newList[elementIndex] = newList[elementIndex].transformVisibleElementRect {
                this?.height = newHeight
            }
            newList[elementIndex] = newList[elementIndex].transformVisibleElementRectPosition {
                this?.y = newY
            }
            if (!newList[elementIndex].isDraggable()) {
                return@run
            }

            newList[elementIndex] = newList[elementIndex].transformGapPuzzleRect {
                this?.height = newHeight
            }
            newList[elementIndex] = newList[elementIndex].transformGapPuzzlePosition {
                this?.y = newY
            }
        }
    }

    return newList
}

fun stretchEdgesOfElements(
    list: MutableList<InteractiveElement>,
    ids: Array<String>,
    stretchByPosition: Position,
    label: String
) : MutableList<InteractiveElement> {
    return when (label) {
        EAlign.Vertical.BOTTOM.label -> {
            setValueForElements(list, ids) { newList, id ->
                stretchElementToBottom(newList, id, stretchByPosition)
            }
        }
        EAlign.Vertical.TOP.label -> {
            setValueForElements(list, ids) { newList, id ->
                stretchElementToTop(newList, id, stretchByPosition)
            }
        }
        EAlign.Horizontal.LEFT.label -> {
            setValueForElements(list, ids) { newList, id ->
                stretchElementToLeft(newList, id, stretchByPosition)
            }
        }
        EAlign.Horizontal.RIGHT.label -> {
            setValueForElements(list, ids) { newList, id ->
                stretchElementToRight(newList, id, stretchByPosition)
            }
        }
        else -> list
    }
}

// CommonListReducer - reducer valuable for every list
val CommonListReducer = { state: MutableList<InteractiveElement>, action: CommonInteractiveElementAction ->
    when (action) {
        is SetHintText -> setHintTextOfList(state, action.id, action.text)
        is SetHintRect -> setHintRectOfList(state, action.id, action.rect)
        is SetHintVerticalAlign -> setHintVerticalAlignOfList(state, action.id, action.align)
        is SetVisibleElementText -> setVisibleElementTextOfList(state, action.id, action.text)
        is SetVisibleElementRenderView -> setVisibleElementRenderView(state, action.id, action.base64)
        is SetElementsHorizontalAlignForElements -> setElementsHorizontalAlignOfListForElements(state, action.ids, action.position, action.flag)
        is SetElementsVerticalAlignForElements -> setElementsVerticalAlignOfListForElements(state, action.ids, action.position, action.flag)
        is SetElementsSpaceBetweenHorizontal -> setElementsSpaceBetweenHorizontalOfList(state, action.id, action.offset)
        is SetElementsSpaceBetweenVertical -> setElementsSpaceBetweenVerticalOfList(state, action.id, action.offset)
        is TransformElement -> transformElementOfList(state, action.id, action.geometry)
        is TransformElementCurve -> transformElementCurveOfList(state, action.id, action.curve)
        is TransformElementGapPuzzle -> transformElementGapPuzzleOfList(state, action.id, action.geometry)
        is RemoveElement -> removeElementOfList(state, action.id)
        is CopyElement -> copyElementOfList(state, action.id, action.newId)
        is CopyMultipleElements -> copyMultipleElements(state, action.ids, action.newIds)
        is RemoveMultipleElements -> removeMultipleElements(state, action.ids)
        is SetFillColor -> setFillColorOfList(state, action.id, action.color)
        is SetFillColorForElements -> setFillColorOfListForElements(state, action.ids, action.color)
        is SetFillOpacity -> setFillOpacityOfList(state, action.id, action.opacity)
        is SetFillOpacityForElements -> setFillOpacityOfListForElements(state, action.ids, action.opacity)
        is SetBorderColor -> setBorderColorOfList(state, action.id, action.color)
        is SetBorderColorForElements -> setBorderColorOfListForElements(state, action.ids, action.color)
        is SetBorderWidth -> setBorderWidthOfList(state, action.id, action.width)
        is SetBorderWidthForElements -> setBorderWidthOfListForElements(state, action.ids, action.width)
        is SetBorderDash -> setBorderDashOfList(state, action.id, action.dash)
        is SetBorderDashForElements -> setBorderDashOfListForElements(state, action.ids, action.dash)
        is SetTextColor -> setTextColorOfList(state, action.id, action.color)
        is SetTextColorForElements -> setTextColorOfListForElements(state, action.ids, action.color)
        is SetTextHorizontalAlign -> setTextHorizontalAlignOfList(state, action.id, action.align)
        is SetTextHorizontalAlignForElements -> setTextHorizontalAlignOfListForElements(state, action.ids, action.align)
        is SetTextVerticalAlign -> setTextVerticalAlignOfList(state, action.id, action.align)
        is SetTextVerticalAlignForElements -> setTextVerticalAlignOfListForElements(state, action.ids, action.align)
        is SetTextFontSize -> setTextFontSizeOfList(state, action.id, action.size)
        is SetTextFontSizeForElements -> setTextFontSizeOfListForElements(state, action.ids, action.size)
        is SetTextPadding -> setTextPaddingOfList(state, action.id, action.size)
        is SetTextPaddingForElements -> setTextPaddingOfListForElements(state, action.ids, action.size)
        is SetTextStyle-> setTextStyleOfList(state, action.id, action.style)
        is SetTextStyleForElements -> setTextStyleOfListForElements(state, action.ids, action.style)
        is SetTextLineHeight-> setTextLineHeightOfList(state, action.id, action.lineHeight)
        is SetTextLineHeightForElements -> setTextLineHeightOfListForElements(state, action.ids, action.lineHeight)
        is SetTextDecoration-> setTextDecorationOfList(state, action.id, action.style)
        is SetTextDecorationForElements -> setTextDecorationOfListForElements(state, action.ids, action.style)
        is SetHintTextFontSize -> setHintTextFontSizeOfList(state, action.id, action.size)
        is SetHintTextFontSizeForElements -> setHintTextFontSizeOfListForElements(state, action.ids, action.size)
        is SetHintFontStyle -> setHintFontStyleOfList(state, action.id, action.style)
        is SetHintFontStyleForElements -> setHintFontStyleOfListForElements(state, action.ids, action.style)
        is SetHintDecoration -> setHintDecorationOfList(state, action.id, action.style)
        is SetHintDecorationForElements -> setHintDecorationOfListForElements(state, action.ids, action.style)
        is SetHintTextPadding -> setHintTextPaddingOfList(state, action.id, action.size)
        is SetHintTextPaddingForElements -> setHintTextPaddingOfListForElements(state, action.ids, action.size)
        is SetHintLineHeight -> setHintLineHeightOfList(state, action.id, action.lineHeight)
        is SetHintLineHeightForElements -> setHintLineHeightOfListForElements(state, action.ids, action.lineHeight)
        is SetHintHorizontalAlign -> setHintHorizontalAlignOfList(state, action.id, action.align)
        is SetHintHorizontalAlignForElements -> setHintHorizontalAlignOfListForElements(state, action.ids, action.align)
        is SetElementActiveState -> setElementActiveState(state, action.id, action.activeState)
        is SetHintActiveState -> setHintActiveState(state, action.id, action.activeState)
        is MoveSelectedElements -> moveSelectedElements(state, action.ids, action.direction)
        is SetCheckmarkPosition -> setCheckmarkPosition(state, action.id, action.checkmarkPosition)
        is SetCheckmarkPositionForElements -> setCheckmarkPositionForElements(state, action.ids, action.checkmarkPosition)
        is SetHintVerticalAlignForElements -> setHintVerticalAlignForElements(state, action.ids, action.align)
        is SetElementActiveStateForElements -> setElementActiveStateForElements(state, action.ids, action.activeState)
        is SetHintActiveStateForElements -> setHintActiveStateForElements(state, action.ids, action.activeState)
        is SetHintTextForElements -> setHintTextForElements(state, action.ids, action.value)
        is SetPlaceholderForElements -> setPlaceholderForElements(state, action.inputIds, action.dragIds, action.placeholder)
        is SetTextForElements -> setTextForElements(state, action.ids, action.text)
        is SetHintsAfterImport -> setHintsTextAfterImport(state, action.hintsText)
        is SetSound -> setSoundOfList(state, action.id, Sound(action.src, null, action.fileName,
            ESoundBadgePosition.DEFAULT.textValue, ESoundActivation.DEFAULT.textValue))
        is DeleteSound -> deleteSoundOfList(state, action.id)
        is SetSoundPosition -> setSoundPositionOfList(state, action.id, action.position)
        is SetSoundPositionForElements -> setSoundPositionForElements(state, action.ids, action.position)
        is SetSoundActivation -> setSoundActivationOfList(state, action.id, action.activation)
        is SetSoundActivationForElements -> setSoundActivationForElements(state, action.ids, action.activation)
        is SetSoundShowing -> setSoundShowingOfList(state, action.id, action.showing)
        is SetSoundShowingForElements -> setSoundShowingForElements(state, action.ids, action.showing)
        is SetSoundSrcAndFilename -> setSoundSrcAndFilenameOfList(state, action.id, action.src, action.altSrc, action.filename)
        is SetSoundIconSize -> setSoundIconSizeOfList(state, action.id, action.iconSize)
        is SetSoundIconSizeForElements -> setSoundIconSizeForElements(state, action.ids, action.iconSize)
        is SetSyncingPhrase -> setSyncingPhraseOfList(state, action.id, action.isSynced)
        is SetSyncingPhrases -> setSyncingPhrases(state, action.ids, action.isSynced)
        is SetPuzzleCustomInitialPosition -> setGapPuzzleCustomInitPositionOfList(state, action.id, action.customPos)
        is SetElementCode -> setElementCode(state, action.id, action.code)
        is DragPhrase -> dragPhraseOfList(state, action.id, action.newLeftTop)
        is StretchEdgesOfElements -> stretchEdgesOfElements(state, action.ids, action.stretchByPosition, action.label)
        else -> state
    }
}

fun GeometryStyle.toOnHover(): GeometryStyle {
    return copy(
        strokeColor = "#4096ff",
        mouseCursor = "pointer",
        strokeWidth = if (this.strokeWidth?.let { it > 0 } == true) this.strokeWidth else 2,
        opacity = if (this.opacity?.let { it < 0.1 } == true) 0.2 else this.opacity,
    )
}

fun GeometryStyle.toOnFocus(): GeometryStyle {
    return copy(
        strokeColor = "#4096ff",
        strokeWidth = if (this.strokeWidth?.let { it > 0 } == true) this.strokeWidth else 2,
        opacity = if (this.opacity?.let { it < 0.1 } == true) 0.2 else this.opacity,
    )
}

fun GeometryStyle.toOnSelect(): GeometryStyle {
    return copy(
        strokeColor = "#4096ff",
        strokeWidth = if (this.strokeWidth?.let { it > 0 } == true) this.strokeWidth else 2,
        opacity = if (this.opacity?.let { it < 0.1 } == true) 0.2 else this.opacity,
        fillColor = if (this.fillColor?.let {it === "#FFFFFF"} == true) "#597EF7" else this.fillColor,
    )
}

fun InteractiveElement.setIdentifiersByText() {
    this.identifier.setIdentifierByText(this.visibleElement.text?.simpleText ?: this.hint.text?.simpleText ?: "")
    this.visibleElement.identifier.setIdentifierByText((this.visibleElement.text?.simpleText ?: "") + " visibleElement")
    this.hint.identifier.setIdentifierByText((this.hint.text?.simpleText ?: "") + " hint")
}
