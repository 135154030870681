package pages.onboarding.languageLevelChoice

import csstype.*
import utils.types.CssStyle
import utils.types.shadowArray

val page: CssStyle = {
    width = 100.pct
    maxWidth = 414.px
    minHeight = 90.vh
    boxSizing = BoxSizing.borderBox
    backgroundColor = Color("white")
    borderRadius = 12.px
    boxShadow = shadowArray(
        BoxShadow(0.px, 3.px, 18.px, (-1).px, Color("#32324717")), BoxShadow(0.px, 0.px, 1.px, 0.px, Color("#0C1A4B3D"))
    )
}

val headerContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    gap = 10.px
    textAlign = TextAlign.center
}

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    gap = 20.px
    padding = 20.px
}

val label: CssStyle = {
    margin = 0.px
    color = Color("#282F3E")
    fontWeight = integer(600)
    lineHeight = 18.9.px
    fontSize = 15.px
    fontFamily = string("\"Inter\", system-ui")
}

val verticalContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
    width = 100.pct
    maxWidth = 256.px
}
