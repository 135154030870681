package builders.enums

import csstype.TextAlign

sealed class EAlign(open val value: String, open val label: String) {
    sealed class Horizontal(override val value: String, override val label: String) : EAlign(value, label) {
        abstract fun toTextAlign(): TextAlign

        object LEFT : Horizontal("left", "Left") {
            override fun toTextAlign(): TextAlign = TextAlign.left
        }

        object CENTER : Horizontal("center", "Center") {
            override fun toTextAlign(): TextAlign = TextAlign.center
        }

        object RIGHT : Horizontal("right", "Right") {
            override fun toTextAlign(): TextAlign = TextAlign.right
        }
    }

    sealed class Vertical(override val value: String, override val label: String) : EAlign(value, label) {
        object TOP : Vertical("top", "Top")
        object MIDDLE : Vertical("middle", "Middle")
        object BOTTOM : Vertical("bottom", "Bottom")
    }
}
