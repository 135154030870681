package online.interactiver.common.admin.interactive.phrase

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Phrase(
    var id: Int? = null,
    @SerialName("dictionary_id")
    var dictionaryId: Int? = null,
    @SerialName("phrase")
    var phrase: String,
    @SerialName("text_description")
    var textDescription: String? = null,
    @SerialName("sound_description")
    var soundDescription: String? = null,
    @SerialName("alt_sound_description")
    var altSoundDescription: String? = null,
    @SerialName("comments")
    var comments: String? = null,
)
