package widgets.Exercises.ui.components.FolderUserActions

import emotion.react.css
import kotlinx.browser.window
import kotlinx.js.timers.setTimeout
import online.interactiver.common.accessgroupuser.Role
import online.interactiver.common.autogeneration.SliderAutoGenerationFolder
import online.interactiver.common.enums.EAccessType
import pages.constructor.ui.components.elements.grayFilter
import pages.languageAuto.ui.ERoute
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.router.dom.useSearchParams
import react.useState
import shared.components.Icon
import widgets.Exercises.ui.components.FolderUserActions.FolderDeletingModal.FolderDeletingModal
import widgets.Exercises.ui.components.FolderUserActions.FolderMovingModal.FolderMovingModal
import widgets.Exercises.ui.components.FolderUserActions.FolderRenamingModal.FolderRenamingModal
import widgets.Exercises.ui.components.ManageAccessModal.ManageAccessModal
import widgets.Exercises.ui.components.UserActions.*
import widgets.LanguageAutoSlider.ui.components.ShareModal.folderPlatformLink
import kotlin.time.Duration.Companion.seconds

external interface FolderUserActionsProps : Props {
    var folder: SliderAutoGenerationFolder?
    var onUpdate: (() -> Unit)? // being called whenever is something updated with api request
    var closePopover: (() -> Unit)?
    var accessType: EAccessType?
}

val FolderUserActions = FC<FolderUserActionsProps> { props ->
    val (renamingModalIsOpened, setRenamingModalIsOpened) = useState(false)
    val (deletingModalIsOpened, setDeletingModalIsOpened) = useState(false)
    val (movingModalIsOpened, setMovingModalIsOpened) = useState(false)
    val (isManageAccessModalOpened, setIsManageAccessModalOpened) = useState(false)

    val (folderShareLinkIsCopied, setFolderShareLinkIsCopied) = useState(false)
    val (folderStudentsLinkIsCopied, setFolderStudentsLinkIsCopied) = useState(false)

    val (searchParams, setSearchParams) = useSearchParams()

    if (props.folder == null) {
        return@FC
    }

    FolderRenamingModal {
        folder = props.folder
        open = renamingModalIsOpened
        onClose = {
            setRenamingModalIsOpened(false)
        }
        onFolderRenamed = {
            props.onUpdate?.invoke()
        }
    }

    FolderDeletingModal {
        folder = props.folder
        open = deletingModalIsOpened
        onClose = {
            setDeletingModalIsOpened(false)
        }
        onFolderDeleted = {
            props.onUpdate?.invoke()
        }
    }

    FolderMovingModal {
        folder = props.folder
        open = movingModalIsOpened
        onClose = {
            setMovingModalIsOpened(false)
        }
        onFolderMoved = {
            if (it == null) {
                searchParams.delete("parent_folder_id")
            } else {
                searchParams.set("parent_folder_id", it.toString())
            }
            setSearchParams(searchParams)

            props.onUpdate?.invoke()
        }
    }

    val folderIdForManagingAccess = if (props.folder != null && props.folder?.parentFolderId == null) {
        props.folder
    } else {
        null
    }
    if (folderIdForManagingAccess != null) {
        ManageAccessModal {
            isOpen = isManageAccessModalOpened
            closeModal = { setIsManageAccessModalOpened(false) }
            folder = folderIdForManagingAccess
        }
    }

    data class FolderActionMeta(
        val label: String,
        val icon: String,
        val onClick: (Int) -> Unit,
        val include: Boolean = true
    )

    div {
        css(container)
        listOf(
            FolderActionMeta(
                label = if (folderStudentsLinkIsCopied) "Copied" else "Link for students",
                icon = "ic_link_45deg_24x24.svg",
                onClick = onClick@{
                    if (folderStudentsLinkIsCopied) {
                        return@onClick
                    }
                    window.navigator.clipboard.writeText(
                        "${folderPlatformLink}${props.folder?.id}"
                    )
                    setFolderStudentsLinkIsCopied(true)
                    setTimeout(1.seconds) {
                        setFolderStudentsLinkIsCopied(false)
                    }
                }
            ),
            FolderActionMeta(
                label = "Rename",
                icon = "ic_pencil_simple_24x24.svg",
                onClick = {
                    props.closePopover?.invoke()
                    setRenamingModalIsOpened(true)
                },
            ),
            FolderActionMeta(
                label = "Delete",
                icon = "ic_delete_16x18.svg",
                onClick = {
                    props.closePopover?.invoke()
                    setDeletingModalIsOpened(true)
                },
                include = props.accessType != EAccessType.SHARED || props.folder?.role == Role.ADMIN.get
            ),
            FolderActionMeta(
                label = "Move",
                icon = "ic_move_24x24.svg",
                onClick = {
                    props.closePopover?.invoke()
                    setMovingModalIsOpened(true)
                },
                include = props.accessType != EAccessType.SHARED
            ),
            FolderActionMeta(
                label = "Manage access",
                icon = "ic_people_24x24.svg",
                onClick = {
                    props.closePopover?.invoke()
                    setIsManageAccessModalOpened(true)
                },
                include = props.folder?.parentFolderId == null
                        && (props.accessType != EAccessType.SHARED || props.folder?.role == Role.ADMIN.get)
            ),
            FolderActionMeta(
                label = if (folderShareLinkIsCopied) "Copied!" else "Link to folder",
                icon = "ic_link_45deg_24x24.svg",
                onClick = onClick@{
                    if (folderShareLinkIsCopied) {
                        return@onClick
                    }
                    window.navigator.clipboard.writeText(
                        "${window.location.origin}${ERoute.SHARED_EXERCISES.path}?parent_folder_id=$it"
                    )
                    setFolderShareLinkIsCopied(true)
                    setTimeout(1.seconds) {
                        setFolderShareLinkIsCopied(false)
                    }
                }
            )
        ).forEach { actionMeta ->
            if (!actionMeta.include) {
                return@forEach
            }

            div {
                css(action)
                onClick = {
                    props.folder?.let {
                        actionMeta.onClick(it.id)
                    }
                }
                div {
                    css(iconContainer)
                    Icon {
                        css(grayFilter)
                        src = actionMeta.icon
                    }
                }
                +actionMeta.label
            }
        }
    }
}
