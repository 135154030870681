package builders.enums

import app.StoreState
import entities.interactivePicture.elements.gapPuzzles.drags.selectFocusedOptionStatic
import entities.interactivePicture.elements.other.selectOthers
import entities.interactivePicture.selectByGroup
import entities.interactivePicture.selectControls
import entities.interactivePicture.selectGapPuzzles
import entities.interactivePicture.selectStatic
import entities.interactivePicture.viewer.elementContainer
import entities.interactivePicture.viewer.puzzleContainer
import online.interactiver.common.interactivepicture.InteractiveElement
import utils.types.CssStyle

enum class EElementGroup(
    val selector: (StoreState) -> MutableList<InteractiveElement>?,
    val containerCSS: CssStyle
) {
    STATIC(selectStatic, elementContainer),
    CONTROLS(selectControls, puzzleContainer),
    PUZZLE(selectGapPuzzles, puzzleContainer),
    OTHER(selectOthers, elementContainer),
    FOCUSED_OPTION_STATIC(selectFocusedOptionStatic, elementContainer)
}