package pages.onboarding.nativeLanguageChoice

import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import online.interactiver.common.user.UpdateUserRequest
import utils.getToken

suspend fun updateNativeLanguageRequest(nativeLanguage: String): String? {
    return try {
       val response = jsonClient.put("/api/v1/user-account/update-languages") {
           bearerAuth(getToken())
           contentType(ContentType.Application.Json)
           setBody(UpdateUserRequest(nativeLanguage = nativeLanguage))
       }

        if (response.status.value != 200) {
            return null
        }

        response.bodyAsText()
    } catch (e: ResponseException) {
        null
    }
}

suspend fun updateUserLanguagesRequest(nativeLanguage: String, languageToLearn: String): String? {
    return try {
        val response = jsonClient.put("/api/v1/user-account/update-languages") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(UpdateUserRequest(nativeLanguage = nativeLanguage, languageToLearn = languageToLearn))
        }

        if (response.status.value != 200) {
            return null
        }

        response.bodyAsText()
    } catch (e: ResponseException) {
        null
    }
}