package widgets.UserProfile.ui.components.UserNameWIthPhoto

import antd.Button
import antd.Input
import app.useAppDispatch
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import kotlinx.browser.document
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLInputElement
import react.FC
import react.Props
import react.dom.events.KeyboardEvent
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.useEffect
import react.useState
import utils.types.extend
import widgets.AdminPanel.ui.imageProfile
import widgets.AdminPanel.ui.imageReplacement
import widgets.AdminPanel.ui.userName
import widgets.UserProfile.SetUserName
import widgets.UserProfile.ui.components.InputWithUserData.changeButton
import widgets.UserProfile.updateNameRequest

external interface UserNameWithPhotoProps : Props {
    var editable: Boolean
    var userName: String
    var photoSrc: String?
}

val UserNameWithPhoto = FC<UserNameWithPhotoProps> { props ->
    val dispatch = useAppDispatch()
    val (newName, setNewName) = useState(props.userName)
    val (inputFocused, setInputFocused) = useState(false)
    val (buttonClicked, setButtonClicked) = useState(false)

    useEffect(props.userName) {
        setNewName(props.userName)
    }

    val id = "user_change_name_button"

    fun updateName() {
        if (newName == props.userName) {
            return
        }

        dispatch(SetUserName(newName))
        GlobalScope.launch {
            val response = updateNameRequest(newName)
            if (response.data == null) {
                dispatch(OpenErrorModal("Error updating about"))
                return@launch
            }
        }
    }

    div {
        css(container)
        div {
            css(userPhotoWithName)
            if (props.photoSrc == null) {
                div {
                    css(imageProfile.extend(imageReplacement))
                    +props.userName.split(" ").filter { it.isNotBlank() }.take(2)
                        .joinToString("") { it.first().toString() }
                }
            } else {
                img {
                    css(imageProfile)
                    src = props.photoSrc
                }
            }
            Input {
                css(userName)
                readOnly = !inputFocused
                bordered = inputFocused
                this.id = id
                this.value = newName
                onInput = {
                    setNewName(it.currentTarget.value)
                }
                onPressEnter = { evt: KeyboardEvent<HTMLInputElement> ->
                    evt.currentTarget.blur()
                }
                onBlur = f@{
                    if (newName.isBlank()) {
                        it.currentTarget.focus()
                        return@f
                    }
                    if (buttonClicked) {
                        return@f
                    }
                    setInputFocused(false)
                    updateName()
                }
            }
        }
        if (props.editable) {
            Button {
                css(changeButton)
                onMouseDown = { setButtonClicked(true) }
                onMouseUp = { setButtonClicked(false) }
                onClick = f@{
                    setInputFocused { !it }
                    if (!inputFocused) {
                        (document.getElementById(id) as HTMLInputElement?)?.focus()
                    } else if (newName.isNotBlank()) {
                        updateName()
                    }
                    Unit
                }
                +"${if (inputFocused) "Save" else "Change"} name"
            }
        }
    }
}