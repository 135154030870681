package widgets.ExerciseSettings.components.InputGptModelName

import csstype.*
import utils.types.CssStyle

val modelNameContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
}

val input: CssStyle = {
    fontFamily = string("\"Montserrat\", system-ui")
    fontWeight = integer(500)
    color = Color("#5D6676")
}
