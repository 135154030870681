package pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.previewShareTabs.viewStatisticsTabContent

import antd.*
import app.useAppDispatch
import app.useAppSelector
import emotion.react.css
import entities.alert.EAlertTarget
import entities.alert.EAlertType
import entities.alert.ShowAutoCloseAlert
import entities.interactivePicture.elements.editors.ScoreEditor.scoreTitles
import entities.interactivePicture.selectInteractivePicture
import enums.EButtonTypes
import kotlinx.browser.window
import pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.PREVIEW_ALERT_DURATION_MS
import react.*
import react.dom.html.ReactHTML.div
import react.redux.useDispatch
import shared.components.Icon
import shared.components.checkbox.Checkbox
import utils.types.extend
import utils.types.jsObject

enum class ETimePeriods(val label: String, val value: String) {
    LAST_30_MINUTES("Last 30 minutes","last30minutes"),
    LAST_2_HOURS("Last 2 hours","last2hours"),
    PAST_2_DAYS("Past 2 days","last2days"),
    ALL_TIME("All time","0"),
}

val ViewStatisticsTabContent = VFC {
    val (title, setTitle) = useState("")
    val (subtitle, setSubtitle) = useState("")
    val (timePeriod, setTimePeriod) = useState(ETimePeriods.LAST_2_HOURS.value)
    val scoreDetails = useAppSelector(scoreTitles).map { currentName ->
        val (show, setShow) = useState(true)
        val (displayedName, setDisplayedName) = useState(currentName)
        ScoreDetails(show, setShow, currentName, displayedName, setDisplayedName)
    }
    val interactiveId = useAppSelector(selectInteractivePicture).identifier.id
    ViewStatisticsContext.Provider {
        value = ViewStatisticsContextData(
            title, setTitle,
            subtitle, setSubtitle,
            timePeriod, setTimePeriod,
            scoreDetails,
            interactiveId
        )

        div {
            css(tabContainer)
            FilterEditors {}
            ResultScreenButton {}
            ScoreAliases {}
        }
    }
}

val FilterEditors = VFC {
    val context = useContext(ViewStatisticsContext)
    div {
        css(filtersContainer)
        FilterEditor {
            name = "Title"
            Input {
                defaultValue = context.title
                onChange = {
                    context.setTitle?.invoke(it.target.value)
                }
                placeholder = "Input title"
            }
        }

        FilterEditor {
            name = "Subtitle"
            Input {
                defaultValue = context.subtitle
                onChange = {
                    context.setSubtitle?.invoke(it.target.value)
                }
                placeholder = "Write description"
            }
        }

        FilterEditor {
            name = "Time period"

            Select {
                css(selectStyle)
                showArrow = true
                defaultValue = ETimePeriods.LAST_2_HOURS.value
                this.options = ETimePeriods.entries.map {
                    jsObject {
                        this.value = it.value
                        this.label = it.label
                    }
                }.toTypedArray()
                onChange = { value, _ ->
                    context.setTimePeriod?.invoke(value)
                }
            }
        }
    }
}

external interface EditorProps: PropsWithChildren {
    var name: String
}

val FilterEditor = FC<EditorProps> {props ->
    div {
        css(filter)
        div {
            css(filterName)
            +props.name
        }
        div {
            css(filterEditor)
            +props.children
        }
    }
}

val ResultScreenButton = VFC {
    val context = useContext(ViewStatisticsContext)
    val dispatch = useAppDispatch()

    val copyLink = useCallback(dispatch) { link: String? ->
        window.navigator.clipboard.writeText(link.toString())
        dispatch(
            ShowAutoCloseAlert(
                "The link has been copied successfully!",
                EAlertType.SUCCESS, EAlertTarget.PREVIEW, PREVIEW_ALERT_DURATION_MS
            )
        )
    }

    Button {
        css(resultButton)
        id = EButtonTypes.RESULTS_SCREEN_LINK_VIEW_STATS_TAB_BUTTON.value
        +"Results screen link"
        Icon {
            css(whiteFilter)
            src = "ic_link_45deg_24x24.svg"
        }
        onClick = {
            copyLink(context.generateResultsScreenLink())
        }
    }
}

val ScoreAliases = VFC {
    val context = useContext(ViewStatisticsContext)
    if (context.scoreDetails.isNullOrEmpty()) {
        return@VFC
    }

    div {
        css(scoreAliasesContainer)

        div {
            css(scoreAliasesSectionHeader)
            +"Score details"
        }

        div {
            css(scoreAlias.extend(scoreAliasesHeader))
            div {
                css {
                    width = scoreAliasCheckboxWidth
                }
            }
            div {
                css {
                    width = scoreAliasInputWidth
                }
                +"Current name"
            }
            div {
                css {
                    width = scoreAliasInputWidth
                }
                +"Displayed name"
            }
        }

        div {
            css(scoreAliases)
            context.scoreDetails.forEach {
                ScoreAlias {
                    checked = it.show
                    setChecked = it.setShow
                    currentName = it.currentName
                    displayedName = it.displayedName
                    setDisplayedName = it.setDisplayedName
                }
            }
        }
    }
}

external interface ScoreAliasProps: Props {
    var checked: Boolean
    var setChecked: StateSetter<Boolean>
    var currentName: String
    var displayedName: String
    var setDisplayedName: StateSetter<String>
}

val ScoreAlias = FC<ScoreAliasProps> { props->
    div {
        css(scoreAlias)

        Checkbox {
            css {
                width = scoreAliasCheckboxWidth
            }
            this.checked = props.checked
            id = EButtonTypes.SCORE_DETAILS_VIEW_STATS_TAB_CHECKBOX_PREFIX.value+"_"+props.currentName.split(" ").joinToString("_")
            onChange = {
                props.setChecked(it)
            }
        }

        Input {
            css {
                width = scoreAliasInputWidth
            }
            disabled = true
            defaultValue = props.currentName
        }

        Input {
            css {
                width = scoreAliasInputWidth
            }
            defaultValue = props.displayedName
            onChange = {
                props.setDisplayedName(it.target.value)
            }
        }
    }
}