import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import io.ktor.utils.io.core.*
import kotlinx.browser.window
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import online.interactiver.common.EBackendError
import online.interactiver.common.admin.interactive.googlecloud.SpeechToTextRequest
import online.interactiver.common.admin.interactive.phrase.Phrase
import online.interactiver.common.admin.interactive.phrase.PhraseRequest
import online.interactiver.common.admin.interactive.phrase.PhraseWithSoundSourceRequest
import online.interactiver.common.admin.interactive.phrase.PhrasesRequest
import online.interactiver.common.api.*
import online.interactiver.common.interactivepicture.InteractivePicture
import online.interactiver.common.utils.getRandUID
import utils.extractUUIDFromUrl
import utils.getToken

data class AdvancedResponse(
    val content: String,
    val code: Int
) {
    fun isError() = code >= 400

    fun ToBackendError(): EBackendError? {
        if (!isError()) {
            return null
        }

        return EBackendError.entries.find { it.code.value == code && it.content == content } ?: EBackendError.UNKNOWN
    }
}

data class ApiResponse<T>(val data: T?, val code: Int)

val jsonClient = HttpClient {
    install(ContentNegotiation) {
        json()
    }
}


suspend fun makeGenerateInteractivePictureRequest(interactivePicture: InteractivePicture): String {
    return jsonClient.post(interactivePictureUrlRequestPath) {
        contentType(ContentType.Application.Json)
        setBody(interactivePicture)
    }.body()
}

suspend fun makeGenerateAndDownloadInteractivePictureRequest(interactivePicture: InteractivePicture): String {
    val response = jsonClient.post(interactivePictureUrlRequestDownloadPath) {
        contentType(ContentType.Application.Json)
        setBody(interactivePicture)
    }

    return response.bodyAsText()
}

suspend fun makeGenerateAndDownloadMultitaskTestRequest(interactivePicture: InteractivePicture): String {
    val response = jsonClient.post(multitaskTestRequestDownloadPath) {
        contentType(ContentType.Application.Json)
        setBody(interactivePicture)
    }

    return response.bodyAsText()
}

suspend fun generateMultitaskWithExistingInteractives(uuids: Array<String>): String {
    val response = jsonClient.post("/api/v1/interactives/multitask/html") {
        contentType(ContentType.Application.Json)
        bearerAuth(getToken())
        setBody(uuids)
    }

    return response.bodyAsText()
}


suspend fun saveInteractiveJSONRequest(
    groupID: Int,
    name: String,
    interactivePicture: InteractivePicture
): AdvancedResponse {
    val jsonArray: ByteArray = Json{
        ignoreUnknownKeys = true
        explicitNulls = false
    }.encodeToString(interactivePicture).encodeToByteArray()
    val uuid = extractUUIDFromUrl(window.location.href)

    if (uuid == null || uuid == "") {
        return AdvancedResponse("", 400)
    }

    val data = formData {
        append("name", name)
        append("uuid", uuid)
        append("interactives_group_id", groupID.toString())
        append("string_json", "${getRandUID(20)}.json") {
            writeFully(jsonArray, 0, jsonArray.size)
        }
    }

    return try {
        val response = jsonClient.submitFormWithBinaryData("/api/v1/interactives/json/update", data) {
            bearerAuth(getToken())
        }

        AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }
}

suspend fun createInteractiveJSONRequest(
    groupID: Int,
    name: String,
    interactivePicture: InteractivePicture
): AdvancedResponse {
    val jsonArray: ByteArray = Json{
        ignoreUnknownKeys = true
        explicitNulls = false
    }.encodeToString(interactivePicture).encodeToByteArray()
    val data = formData {
        append("name", name)
        append("interactives_group_id", groupID.toString())
        append("file", "${getRandUID(20)}.json") {
            writeFully(jsonArray, 0, jsonArray.size)
        }
    }

    return try {
        val response = jsonClient.submitFormWithBinaryData("/api/v1/interactives/json", data) {
            bearerAuth(getToken())
        }

        AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }
}

suspend fun generateHtmlInteractiveByUuid(uuid: String): AdvancedResponse {
    return try {
        val response = jsonClient.get("/api/v1/interactives/$uuid/html/generate") {
            bearerAuth(getToken())
        }
        AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }
}

suspend fun getInteractiveByUuid(uuid: String): AdvancedResponse {
    return try {
        val response = jsonClient.get("/api/v2/interactives/$uuid") {
            bearerAuth(getToken())
        }
        AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }
}

suspend fun generatePublicInteractiveHtml(uuid: String, publicLinkName: String): AdvancedResponse {
    return try {
        val response = jsonClient.get("/api/v1/interactives/$uuid/html/public/generate/$publicLinkName") {
            bearerAuth(getToken())
        }
        AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }
}

suspend fun getTemplates(): String {
    return jsonClient.get("${urlRequestTemplates}").body()
}

suspend fun getToFolderEndpoint(urlParams: String?): String {
    val urlLink = urlRequestToFolderEndpoint
    if (urlParams.isNullOrBlank()) {
        return jsonClient.get(urlLink).body()
    }
    return jsonClient.get("$urlLink?$urlParams").body()
}

suspend fun getToMainEndpoint(): String {
    return jsonClient.get("${urlRequestToMainPageEndpoint}").body()
}

suspend fun getAuthEndpoint(): String {
    return jsonClient.get("${urlRequestAuthEndpoint}").body()
}

suspend fun validateToken(): AdvancedResponse {
    try {
        val response = jsonClient.get("/validate") {
            bearerAuth(getToken())
        }
        return AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        return AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }
}

suspend fun getMyGroups(): AdvancedResponse {
    try {
        val response = jsonClient.get("/api/v1/projects") {
            bearerAuth(getToken())
        }
        return AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        return AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }
}

suspend fun getTemplateByCode(code: String): String {
    return jsonClient.get("${urlRequestTemplates}/${code}").body()
}

suspend fun getInteractiveJSONByUID(uuid: String): AdvancedResponse {
    val path = "/api/v1/interactives/${uuid}/json"
    try {
        val response = jsonClient.get(path) {
            bearerAuth(getToken())
        }
        return AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        return AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }
}

suspend fun getInteractiveByUID(uuid: String): AdvancedResponse {
    val path = "/api/v2/interactives/${uuid}"
    try {
        val response = jsonClient.get(path) {
            bearerAuth(getToken())
        }
        return AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        return AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }
}

suspend fun getPhraseByInteractiveGroupIdAndPhrase(phrase: String, interactiveGroupId: Int): AdvancedResponse {
    val path ="/api/v2/dictionaries/access/${interactiveGroupId}"
    return try {
        val response = jsonClient.put(path) {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(PhraseRequest(phrase))
        }
        AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }
}

suspend fun getSoundFromDictionaryOrGoogleCloudRequest(
    phrase: String,
    interactiveGroupId: Int,
    fromDictionary: Boolean,
    fromGoogleTextToSpeech: Boolean,
    materialLanguage: String
): AdvancedResponse {
    val path ="/api/v2/dictionaries/access/${interactiveGroupId}/text-to-sound"
    return try {
        val response = jsonClient.put(path) {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(PhraseWithSoundSourceRequest(phrase, fromDictionary, fromGoogleTextToSpeech, materialLanguage))
        }
        AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }
}

suspend fun getSoundsFromDictionaryOrGoogleCloudRequest(
    phrases: Array<String>, interactiveGroupId: Int, materialLanguage: String
) : AdvancedResponse {
    val path = "/api/v2/dictionaries/access/$interactiveGroupId/text-to-sound/pieces"
    return try {
        val response = jsonClient.post(path) {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(PhrasesRequest(phrases, materialLanguage))
        }
        AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }
}

suspend fun getSoundFromGoogleCloudRequest(
    phrase: String,
    interactiveGroupId: Int
): AdvancedResponse {
    val path ="/api/v2/dictionaries/access/${interactiveGroupId}/text-to-sound/google-cloud"
    return try {
        val response = jsonClient.put(path) {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(PhraseRequest(phrase))
        }
        AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }
}

suspend fun savePhraseRequest(phrase: String, textDescription: String?, soundDescription: String?, interactiveGroupId: Int): AdvancedResponse {
    val data = Phrase(
        phrase = phrase,
        textDescription = textDescription,
        soundDescription = soundDescription
    )
    return try {
        val response = jsonClient.post("api/v2/dictionaries/access/${interactiveGroupId}") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(data)
        }
        AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }
}

suspend fun getSyncingPhrasesFromDictionaryRequest(
    interactiveGroupId: Int, uuid: String?
): AdvancedResponse {
    val path = "api/v2/dictionaries/access/$interactiveGroupId/sync/interactive/$uuid"
    return try {
        val response = jsonClient.get(path) {
            bearerAuth(getToken())
        }
        AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }
}

suspend fun getTextFromSoundRequest(base64: String, language: String, seconds: Int): AdvancedResponse {
    val path = "/api/v2/google-cloud/speech-to-text"
    return try {
        val response = jsonClient.post(path) {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(SpeechToTextRequest(base64, language, seconds))
        }
        AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }
}

suspend fun createInteractivePicture(interactivePicture: InteractivePicture): String {
    return jsonClient.post("/v2/interactivePicture") {
        contentType(ContentType.Application.Json)
        setBody(interactivePicture)
    }.bodyAsText()
}

suspend fun getInteractivePublicLink(uuid: String): AdvancedResponse {
    val path = "/api/v1/interactives/$uuid/public/link"

    return try {
        val response = jsonClient.get(path) {
            bearerAuth(getToken())
        }
        AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }
}

suspend fun updateInteractiveHTMLByPublicLink(
    uuid: String
): AdvancedResponse {
    val path = "/api/v1/interactives/$uuid/html/public/generate"

    return try {
        val response = jsonClient.get(path) {
            bearerAuth(getToken())
        }
        AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }
}

suspend fun getAttemptScores(
    filterValue: String
): AdvancedResponse {
    val path = "/attempts_log?filter=$filterValue"

    return try {
        val response = jsonClient.get(path) {
            bearerAuth(getToken())
        }
        AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }
}

suspend fun getAttachedDictionary(interactiveGroupId: Int): AdvancedResponse {
    val path = "/api/v2/dictionaries/group/$interactiveGroupId"

    return try {
        val response = jsonClient.get(path) {
            bearerAuth(getToken())
        }
        AdvancedResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        AdvancedResponse(e.response.bodyAsText(), e.response.status.value)
    }
}