package entities.backgroundPosScale

import app.StoreState
import app.UndoableAction
import app.undoableSelector
import entities.interactivePicture.background.selectCanvasHeight
import entities.interactivePicture.background.selectCanvasWidth
import online.interactiver.common.math.LinearTransformation
import online.interactiver.common.utils.Copyable
import utils.structures.Position

data class StoreBackgroundPosScale(
    val canvasContainerWidth: Int = 0,
    val canvasContainerHeight: Int = 0,
    val backgroundMargin: Int = 0
) : Copyable<StoreBackgroundPosScale> {
    override fun clone(): StoreBackgroundPosScale {
        return StoreBackgroundPosScale(
            canvasContainerWidth = canvasContainerWidth,
            canvasContainerHeight = canvasContainerHeight
        )
    }
}

data class SetCanvasContainerSize(val width: Int, val height: Int) : UndoableAction(true)

val BackgroundPosScaleReducer = { state: StoreBackgroundPosScale, action: UndoableAction ->
    when (action) {
        is SetCanvasContainerSize -> {
            state.copy(canvasContainerWidth = action.width, canvasContainerHeight = action.height)
        }

        else -> state
    }
}

val selectCanvasContainerWidth = { state: StoreState ->
    val bgPosScale = selectBgPosScale(state)
    bgPosScale.canvasContainerWidth
}

val selectCanvasContainerHeight = { state: StoreState ->
    val bgPosScale = selectBgPosScale(state)
    bgPosScale.canvasContainerHeight
}

val selectBackgroundMargin = { state: StoreState ->
    selectBgPosScale(state).backgroundMargin
}

val selectUndoableBgPosScale = { state: StoreState -> state.backgroundPosScale }
val selectBgPosScale = undoableSelector(selectUndoableBgPosScale) { s -> s }
val selectBackgroundPosition = { state: StoreState ->
    val canvasWidth = selectCanvasWidth(state)
    val canvasHeight = selectCanvasHeight(state)
    val margin = selectBackgroundMargin(state)

    Position(margin, margin, canvasWidth ?: 0, canvasHeight ?: 0)
}

val selectBgBasedLinearTransformation = { state: StoreState ->
    val margin = selectBackgroundMargin(state)
    LinearTransformation(
        shiftX = -margin.toDouble(),
        shiftY = -margin.toDouble()
    )
}
