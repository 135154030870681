package widgets.ExerciseSettings.components.SwitchWithLabel

import antd.Switch
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import widgets.ExerciseSettings.components.SettingLabel.SettingLabel

external interface SwitchWithLabelProps : Props {
    var checked: Boolean?
    var onChange: (Boolean) -> Unit
    var label: String
    var hint: String
}

val SwitchWithLabel = FC<SwitchWithLabelProps> { props ->
    div {
        css(container)
        SettingLabel {
            label = props.label
            hint = props.hint
        }
        Switch {
            checked = props.checked
            onChange = { checked, _ ->
                props.onChange(checked)
            }
        }
    }
}
