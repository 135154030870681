package widgets.AdminPanel.ui.components.CreateFolderButton

import csstype.FontWeight
import csstype.Padding
import csstype.px
import csstype.string
import utils.types.CssStyle

val modalBody: CssStyle = {
    padding = Padding(0.px, 0.px, 10.px, 0.px)
}

val modalHeader: CssStyle = {
    fontFamily = string("Montserrat")
    fontWeight = FontWeight.bolder
    fontSize = 20.px
    marginBottom = 12.px
}
