package online.interactiver.common.math

data class LinearTransformation(
    val scaleX: Double = 1.0,
    val scaleY: Double = scaleX,
    val shiftX: Double = 0.0,
    val shiftY: Double = 0.0
) {
    fun applyToX(x: Double) = x * scaleX + shiftX
    fun applyToY(y: Double) = y * scaleY + shiftY
    fun applyToWidth(width: Double) = width * scaleX
    fun applyToHeight(height: Double) = height * scaleY

    fun applyToX(x: Int) = (x * scaleX + shiftX).toInt()
    fun applyToY(y: Int) = (y * scaleY + shiftY).toInt()
    fun applyToWidth(width: Int) = (width * scaleX).toInt()
    fun applyToHeight(height: Int) = (height * scaleY).toInt()

    fun applyToX(x: Int?) = if (x != null) applyToX(x!!) else null
    fun applyToY(y: Int?) = if (y != null) applyToY(y!!) else null
    fun applyToWidth(width: Int?) = if (width != null) applyToWidth(width!!) else null
    fun applyToHeight(height: Int?) = if (height != null) applyToHeight(height!!) else null
}