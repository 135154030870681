package pages.constructor.ui.components.advancedSettings.requestSettings

import emotion.react.css
import enums.EButtonTypes
import online.interactiver.common.api.RequestParams
import pages.constructor.ui.components.advancedSettings.settingsHeader
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.components.checkboxWithLabel.CheckboxWithLabel

external interface GeneratingSettingsEditorProps: Props {
    var settings: RequestParams
    var onChange: (RequestParams) -> Unit
}

val GeneratingSettingsEditor = FC<GeneratingSettingsEditorProps>{ props ->
    div {
        css(container)
        div {
            css(settingsHeader)
            +"PUBLISHING"
        }
        CheckboxWithLabel {
            id = EButtonTypes.MINIFY_HTML_SETTINGS_BUTTON.value
            checked = !props.settings.generateHtmlInReadableFormat
            label = "Minification HTML"
            onChange = {
                props.onChange(props.settings.copy(generateHtmlInReadableFormat = !it))
            }
        }
        CheckboxWithLabel {
            id = EButtonTypes.INCLUDE_LIBS_SETTINGS_BUTTON.value
            checked = props.settings.includeLibrariesCodeToWorkOffline
            label = "Turn on libraries"
            onChange = {
                props.onChange(props.settings.copy(includeLibrariesCodeToWorkOffline = it))
            }
        }
        CheckboxWithLabel {
            id = EButtonTypes.INCLUDE_SHARED_SETTINGS_BUTTON.value
            checked = props.settings.generateHtmlSharedPart
            label = "With shared code"
            onChange = {
                props.onChange(props.settings.copy(generateHtmlSharedPart = it))
            }
        }
        CheckboxWithLabel {
            id = EButtonTypes.INCLUDE_INDIVIDUAL_SETTINGS_BUTTON.value
            checked = props.settings.generateHtmlIndividualPart
            label = "With Individual code"
            onChange = {
                props.onChange(props.settings.copy(generateHtmlIndividualPart = it))
            }
        }
        CheckboxWithLabel {
            id = EButtonTypes.RELOAD_WINDOW_ON_RESTART.value
            checked = props.settings.reloadWindowOnRestart
            label = "Reload window on restart"
            onChange = {
                props.onChange(props.settings.copy(reloadWindowOnRestart = it))
            }
        }
        CheckboxWithLabel {
            id = EButtonTypes.SHRINK_TEXT_TO_FIT_IN_ELEMENT_BUTTON.value
            checked = props.settings.shrinkTextToFitInElement
            label = "Shrink text to fit in element"
            onChange = {
                props.onChange(props.settings.copy(shrinkTextToFitInElement = it))
            }
        }
    }
}