package pages.constructor.ui.components.header.logoButton

import antd.Tooltip
import app.useAppSelector
import emotion.react.css
import entities.interactivePicture.selectInteractiveGroup
import getToFolderEndpoint
import getToMainEndpoint
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import org.w3c.dom.url.URLSearchParams
import react.*
import react.dom.html.ReactHTML.a
import shared.components.Icon
import utils.extractUUIDFromUrl

val LogoButton = VFC {
    val (href, setHref) = useState("/")
    val uuid = extractUUIDFromUrl(window.location.href)
    val interactivesGroupId = useAppSelector(selectInteractiveGroup)

    useEffect(uuid, interactivesGroupId) {
        MainScope().launch {
            if (interactivesGroupId == 0) {
                val toMainEndpoint = async {getToMainEndpoint()}.await()
                setHref(toMainEndpoint)
                return@launch
            }

            val searchParams = URLSearchParams()
            searchParams.append("interactiveGroupId", interactivesGroupId.toString())
            if (!uuid.isNullOrBlank()) {
                searchParams.set("uuid", uuid)
            }
            val toFolderEndpoint = async {getToFolderEndpoint(searchParams.toString())}.await()
            setHref(toFolderEndpoint)
        }
    }

    Tooltip {
        title = "To admin panel"
        placement = "bottomLeft"
        a {
            css(button)
            this.href = href
            Icon {
                src = "ic_arrow_left_24x24.svg"
            }
            Icon {
                src = "ic_interactiver_26x24.svg"
            }
        }
    }
}