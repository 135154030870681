package entities.interactivePicture.elements.controls.inputs.ui.editor

import app.useAppDispatch
import app.useAppSelector
import builders.enums.EInteractiveType
import csstype.FlexDirection
import emotion.react.css
import entities.interactivePicture.elements.controls.inputs.SetIgnoreCase
import entities.interactivePicture.elements.controls.inputs.SetIgnoreCaseForInputs
import entities.interactivePicture.elements.controls.inputs.SetIgnoreSpaces
import entities.interactivePicture.elements.controls.inputs.SetIgnoreSpacesForInputs
import entities.interactivePicture.elements.editors.textEditor.*
import entities.interactivePicture.selectElementsUnderSelectionRectangle
import entities.interactivePicture.selectFocusedElement
import enums.EButtonTypes
import react.FC
import react.Props
import shared.canvas.interfaces.shouldDrawEditor
import react.dom.html.ReactHTML.div
import shared.canvas.interfaces.getValueForEditor
import shared.components.checkbox.Checkbox

val CaseAndWhiteSpaceEditor = FC<Props> f@{
    class CheckBoxData(
        val id: String,
        val label: String,
        val checked: String?,
        val onChange: ((Boolean) -> Unit)?,
    )

    val selected = useAppSelector(selectFocusedElement)
    val elementsUnderSelectionRectangle = useAppSelector(selectElementsUnderSelectionRectangle)
    val dispatch = useAppDispatch()

    if (!shouldDrawEditor(
            selected,
            elementsUnderSelectionRectangle
        ) { element -> element.type == EInteractiveType.INPUT_INTERACTIVE.text }
    ) {
        return@f
    }
    val checkedIgnoreSpaces = getValueForEditor(selected, elementsUnderSelectionRectangle) { element ->
        element.input?.ignoreWhiteSpaces ?: "selected"
    }
    val checkedIgnoreCaseSensitivity = getValueForEditor(selected, elementsUnderSelectionRectangle) { element ->
        element.input?.ignoreCaseSensitivity ?: "selected"
    }

    div {
        arrayOf(
            CheckBoxData(EButtonTypes.IGNORE_WHITE_SPACES_BUTTON.value, "Ignore spaces", checkedIgnoreSpaces
            ) { checked: Boolean ->
                val value = if (checked) "selected" else "unselected"
                if (selected != null) {
                    dispatch(SetIgnoreSpaces(selected.identifier.id!!, value))
                } else {
                    dispatch(SetIgnoreSpacesForInputs(elementsUnderSelectionRectangle.map { it.identifier.id!! }
                        .toTypedArray(), value))
                }
            },
            CheckBoxData(EButtonTypes.IGNORE_CASE_SENSITIVITY.value, "Ignore case", checkedIgnoreCaseSensitivity
            ) { checked: Boolean ->
                val value = if (checked) "selected" else "unselected"
                if (selected != null) {
                    dispatch(SetIgnoreCase(selected.identifier.id!!, value))
                } else {
                    dispatch(SetIgnoreCaseForInputs(elementsUnderSelectionRectangle.map { it.identifier.id!! }
                        .toTypedArray(), value))
                }
            }

        ).map { input ->
            div {
                css(container(FlexDirection.row))
                div {
                    css(editorTextContainer)
                    +input.label
                    Checkbox {
                        id = input.id
                        checked = input.checked.equals("selected")
                        onChange = input.onChange
                    }
                }
            }
        }
    }
}