package pages.constructor.ui.components.header.interactiveParams

import antd.*
import app.useAppDispatch
import app.useAppSelector
import app.useSaveInteractive
import emotion.react.css
import entities.interactivePicture.SetInteractiveName
import entities.interactivePicture.selectInteractiveGroup
import entities.interactivePicture.selectInteractiveName
import entities.previewAndExport.SetInteractivesGroups
import enums.EButtonTypes
import getMyGroups
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import online.interactiver.common.admin.interactive.InteractivesGroup
import pages.constructor.ui.components.elements.whiteFilter
import pages.constructor.ui.components.header.interactiveParams.enums.EParamPopupState
import pages.constructor.ui.components.header.interactiveParams.selectors.GroupSelector
import react.*
import react.dom.html.ReactHTML.div
import shared.components.Icon
import utils.types.buildProps
import utils.types.impl


external interface InteractiveParamsProps : Props {
    var popupState: EParamPopupState
    var setState: StateSetter<EParamPopupState>
}

val InteractiveParamsEditor = FC<InteractiveParamsProps> { props ->
    val interactiveName = useAppSelector(selectInteractiveName)
    val interactiveGroupID = useAppSelector(selectInteractiveGroup)
    val dispatch = useAppDispatch()

    val (groupsData, setGroupsData) = useState(null as List<InteractivesGroup>?)

    if (groupsData == null) {
        MainScope().launch {
            val response = getMyGroups();

            if (response.code != 200) {
                return@launch
            }

            val config = getGroupsConfig(response.content);
            dispatch(SetInteractivesGroups(config))
            setGroupsData(config);
        }
    }

    div {
        css(container)
        Input {
            css(nameInput)
            value = interactiveName
            onChange = {
                dispatch(SetInteractiveName(it.target.value))
            }
        }

        Popover {
            trigger = "click"
            align = impl {
                points = arrayOf("tl", "bl")
                offset = arrayOf(-20, 6)
            }

            content = createElement(PopoverProjectParams, buildProps {
                groups = groupsData
                curGroup = interactiveGroupID ?: 0
                state = props.popupState
                setState = props.setState
            })

            this.open = props.popupState != EParamPopupState.CLOSE

            onOpenChange = a@{ flag: Boolean ->
                props.setState(if (flag) EParamPopupState.OPEN else EParamPopupState.CLOSE)
            }
            Tooltip {
                title = "Save and Organize"
                placement = "bottom"
                Button {
                    css(button)
                    id = EButtonTypes.PARAMS_BUTTON.value
                    Icon {
                        css(whiteFilter)
                        src = "ic_params_24x24.svg"
                    }
                    ghost = true
                    type = "text"
                }
            }

        }
    }
}

external interface ProjectParamsProps : Props {
    var groups: List<InteractivesGroup>?
    var curGroup: Int
    var state: EParamPopupState
    var setState: StateSetter<EParamPopupState>
    var setParamPopupState: StateSetter<EParamPopupState>
}

val PopoverProjectParams = FC<ProjectParamsProps> { props ->
    val interactiveGroup = useAppSelector(selectInteractiveGroup)
    val saveInteractive = useSaveInteractive()

    div {
        css(popupContainer)
        div {
            css(popupInputContainer)
            div {
                css(header)
                +"Tags"
            }
            Select {
                css(select)
                showArrow = true
                size = "middle"
                placeholder = "Input tags"
            }
        }

        GroupSelector {
            groups = props.groups
            curGroup = props.curGroup
            state = props.state
        }

        div {
            css(popupInputContainer)
            div {
                css(header)
                +"Description"
            }
            Select {
                css(select)
                size = "middle"
                placeholder = "Enter description"
            }
        }

        Button {
            css(buttonSave)
            id = EButtonTypes.SAVE_PARAMS_BUTTON.value
            ghost = true
            type = "text"
            onClick = a@{
                if (interactiveGroup == null || interactiveGroup == 0) {
                    props.setParamPopupState(EParamPopupState.OPEN_INPUT_CHECK)
                    return@a
                }
                saveInteractive { }
                props.setState(EParamPopupState.CLOSE)
            }
            +"Save"
        }
    }
}
