package pages.onboarding.notifications

import antd.Button
import csstype.BoxSizing
import csstype.Display
import csstype.FlexDirection
import csstype.px
import emotion.react.css
import pages.studentDuo.selectRequiredStudentDuoNativeLanguage
import react.Fragment
import react.VFC
import react.createElement
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import react.router.useNavigate
import utils.localization.Localization
import utils.types.impl
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton
import widgets.Notifications.ui.ManageNotifications

val OnboardingNotifications = VFC {
    val navigate = useNavigate()
    val nativeLanguage = useSelector(selectRequiredStudentDuoNativeLanguage)

    ManageNotifications {
        buttons = createElement(
            div,
            impl {
                 css {
                     display = Display.flex
                     flexDirection = FlexDirection.column
                     gap = 20.px
                     padding = 20.px
                     boxSizing = BoxSizing.borderBox
                 }
            },
            createElement(
                Button,
                impl {
                    css(generateButton)
                    onClick = {
                        navigate("/duo/content/socials")
                    }
                    children = Localization.getContinueButtonText(nativeLanguage)
                }
            ),
            createElement(
                Button,
                impl {
                    css(newTaskButton)
                    onClick = {
                        navigate(-1)
                    }
                    children = Localization.getBackButtonText(nativeLanguage)
                }
            )
        )
    }
}
