package pages.constructor.ui.components.advancedElementsSettings.advancedButtonSettings

import csstype.*
import utils.types.CssStyle


val scoreType: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    justifyContent = JustifyContent.center
    alignItems = AlignItems.start
    gap = 8.px
    width = 100.pct
}