package widgets.Exercises.ui.components.FolderUserActions.FolderDeletingModal

import Modal
import app.useAppDispatch
import emotion.react.css
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import online.interactiver.common.autogeneration.SliderAutoGenerationFolder
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import widgets.Exercises.deleteFolder

external interface FolderDeletingModalProps : Props {
    var open: Boolean?
    var onClose: (() -> Unit)?
    var folder: SliderAutoGenerationFolder?
    var onFolderDeleted: (() -> Unit)?
}

val FolderDeletingModal = FC<FolderDeletingModalProps> { props ->
    val dispatch = useAppDispatch()

    if (props.folder == null) {
        return@FC
    }

    Modal {
        open = props.open
        onCancel = {
            props.onClose?.invoke()
        }
        onOk = {
            props.onClose?.invoke()
            dispatch(StartModalLoading("Deleting '${props.folder!!.name}'..."))
            MainScope().launch {
                val response = deleteFolder(
                    props.folder!!.id,
                )
                dispatch(EndModalLoading())

                if (response.value >= 400) {
                    // we do not reset folder name if failed so user can try again without re-entering a name again
                    console.error("Failed to delete '${props.folder!!.name}'.")
                } else {
                    props.onFolderDeleted?.invoke()
                }
            }
        }
        okText = "Delete without files"
        cancelText = "Cancel"

        div {
            css(modalBody)
            div {
                css(modalHeader)
                +"Are you sure?"
            }
            div {
                css(modalAttentionBody)
                +"A folder "
                span {
                    css(modalAttentionBodyEmph)
                    +props.folder!!.name
                }
                +" will be deleted forever and all the files in it will be moved here."
            }
        }
    }
}
