package shared.components.editSoundModal

import Modal
import antd.Button
import emotion.react.css
import kotlinx.js.timers.Timeout
import org.w3c.dom.events.MouseEvent
import react.*
import react.dom.html.ReactHTML.div
import shared.components.UploadOrRecordAudio.useUploadOrRecordAudio
import shared.components.header.Header
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoTask.ui.components.SlideEditModal.cancelButton

external interface EditSoundModalProps : Props {
    var isOpen: Boolean
    var onCancelClick: (MouseEvent) -> Unit
    var onSaveSoundClick: (String) -> Unit
}

data class Duration(val intervalId: Timeout, val duration: Int)

val EditSoundModal = FC<EditSoundModalProps> { props ->
    val maxFileSizeInMb = 1

    val (newSound, setNewSound) = useState<String?>(null)
    val (
        uploadOrRecordAudioComponent,
        resetAudioComponentState,
        uploadOrRecordAudioProps
    ) = useUploadOrRecordAudio(maxFileSizeInMb, newSound, 30) { setNewSound(it) }

    useEffect(props.isOpen) {
        if (!props.isOpen) {
            setNewSound(null)
        }
    }

    val resetState = { it: MouseEvent ->
        resetAudioComponentState()
        props.onCancelClick(it)
    }

    Modal {
        zIndex = 101
        open = props.isOpen
        onCancel = {
            resetState(it)
        }
        footer = null

        div {
            css(container)
            Header {
                text = "Edit audio"
            }

            uploadOrRecordAudioComponent {
                this.maxFileSizeInMb = uploadOrRecordAudioProps.maxFileSizeInMb
                this.onSoundChange = uploadOrRecordAudioProps.onSoundChange
                this.sound = uploadOrRecordAudioProps.sound
                this.resetAudioDuration = uploadOrRecordAudioProps.resetAudioDuration
                this.preparingToRecordDuration = uploadOrRecordAudioProps.preparingToRecordDuration
                this.isRecording = uploadOrRecordAudioProps.isRecording
                this.startPreparingToRecord = uploadOrRecordAudioProps.startPreparingToRecord
                this.continuePreparingToRecords = uploadOrRecordAudioProps.continuePreparingToRecords
                this.stopRecording = uploadOrRecordAudioProps.stopRecording
                this.maxAudioDuration = uploadOrRecordAudioProps.maxAudioDuration
                this.recordingDuration = uploadOrRecordAudioProps.recordingDuration
            }

            div {
                css(footerContainer)
                Button {
                    css(generateButton)
                    disabled = newSound == null
                    +"Save"
                    onClick = { it: MouseEvent ->
                        newSound?.let {
                            props.onSaveSoundClick(it)
                        }
                        resetState(it)
                    }
                }
                Button {
                    css(cancelButton)
                    onClick = { evt: MouseEvent ->
                        resetState(evt)
                    }
                    +"Cancel"
                }
            }
        }
    }
}