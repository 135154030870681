package shared.components.cropImage

import csstype.*
import utils.types.CssStyle

val uploadContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    width = 100.pct
    alignItems = AlignItems.center
}

val uploadButton: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    width = 100.pct
    backgroundColor = Color("#f2f4f7")
    borderRadius = 5.px
    border = None.none
    padding = 15.px
    boxSizing = BoxSizing.borderBox
}

val cropContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
}

val modal: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    gap = 25.px
    minHeight = 350.px
}

val footerContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    gap = 10.px
}

val savedImageCss: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 15.px
}

val savedImageName: CssStyle = {
    margin = 0.px
    maxWidth = 385.px
    textAlign = TextAlign.center
    fontSize = 14.px
}

val imageWithMaxWidth: CssStyle = {
    minWidth = 700.px
    height = Auto.auto
}

val imageWithMaxHeight: CssStyle = {
    minHeight = 500.px
    width = Auto.auto
}
