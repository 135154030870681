package pages.constructor.ui.components.header.styleSettings.paddingSizeInput

import csstype.*
import pages.constructor.ui.components.header.styleSettings.secondary
import utils.inject
import utils.types.CssStyle

val inputNumber: CssStyle = {
    inject(secondary)
    width = 42.px
    padding = Padding(0.px, 2.px)
}

val plusMinusButton: CssStyle = {
    display = Display.flex
    justifyContent = JustifyContent.center
    alignItems = AlignItems.center
    padding = 0.px
    margin = 0.px
    width = 24.px
    height = 24.px
    borderRadius = 3.px
    border = None.none
}

val inputContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 6.px
    marginTop = 5.px
}
