package entities.lastCreatedType

import app.StoreState
import builders.enums.EElementType
import redux.RAction

open class LastCreatedTypeAction : RAction

data class LastCreatedTypeState(val type: EElementType? = null, val times: Int = 0)

data class SetLastCreatedType(val type: EElementType?, val times: Int = 1) : LastCreatedTypeAction()

val setLastCreatedType = { state: LastCreatedTypeState, type: EElementType?, times: Int ->
    if (type == null) {
        LastCreatedTypeState()
    } else if (state.type == type) {
        state.copy(times = state.times + times)
    } else {
        LastCreatedTypeState(type = type, times = times)
    }
}

val LastCreatedTypeReducer = { state: LastCreatedTypeState, action: LastCreatedTypeAction ->
    when (action) {
        is SetLastCreatedType -> setLastCreatedType(state, action.type, action.times)
        else -> state
    }
}

val selectLastCreatedType = { state: StoreState ->
    state.lastCreatedType
}
