package widgets.LanguageAutoContent.ui.components.ContentCard

import csstype.*
import emotion.react.css
import pages.constructor.ui.components.elements.blueFilter
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.useState
import shared.components.Icon
import shared.components.description.Description
import shared.components.header.Header
import utils.types.extend

external interface ContentCardProps : Props {
    var header: String
    var description: String?
    var icon: String
    var onClick: () -> Unit
    var contentType: String
    var currentContentType: String?
    var isBeta: Boolean?
}

val ContentCard = FC<ContentCardProps> {props ->
    val (isHovered, setIsHovered) = useState(false)
    div {
        css(card.extend {
            if (props.contentType == props.currentContentType) {
                border = Border(1.px, LineStyle.solid, Color("#597EF7"))
            }
            if (props.description.isNullOrBlank()) {
                height = Auto.auto
            }
        })
        onClick = {
            props.onClick()
        }
        onMouseEnter = { setIsHovered(true) }
        onMouseLeave = { setIsHovered(false) }
        div {
            css(iconContainer)
            Icon {
                css(blueFilter)
                src = props.icon
            }
            if (props.isBeta == true) {
                p {
                    css(beta)
                    +"BETA"
                }
            }
        }
        div {
            css(textContainer)
            div {
                css(headerContainer)
                Header {
                    if (isHovered || props.contentType == props.currentContentType) {
                        color = "#597EF7"
                    }
                    fontSize = 16.0.px
                    lineHeight = 21.6.px
                    text = props.header
                    fontWeight = integer(600)
                }
            }
            props.description?.let {
                Description {
                    text = it
                    fontSize = 11.0
                    lineHeight = 14.85
                }
            }
        }
    }
}
