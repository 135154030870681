package widgets.LanguageAutoTask.ui.components.SlideEditModal

import kotlinx.serialization.json.Json
import online.interactiver.common.interactivepicture.InteractivePicture
import online.interactiver.common.interactivepicture.InteractiveSlider

fun getInteractivePictureFromSliderByIndex(slider: String, slideIndex: Int): InteractivePicture? {
    val sliderStr = slider.substringAfter("const interactiveSliderStr___", "")
        .substringBefore("'.replaceAll", "")
        .substringAfter("= '", "")
        .replace("\\\"", "\"").replace("\\\\", "\\").trim()

    if (sliderStr.isBlank()) {
        return null
    }

    val interactiveSlider = try {
        Json.decodeFromString<InteractiveSlider>(sliderStr)
    } catch (e: Exception) {
        console.log("Can't parse because of ${e.message}")
        return null
    }

    return interactiveSlider.interactivePictures.getOrNull(slideIndex)
}
