package features.UndoAlert.ui

import antd.Alert
import antd.Button
import app.useAppDispatch
import emotion.react.css
import entities.alert.EAlertType
import kotlinx.js.timers.clearTimeout
import pages.studentDuo.SetUndoAlert
import pages.studentDuo.selectUndoAlert
import react.FC
import react.Props
import react.createElement
import react.redux.useSelector

val UndoAlert = FC<Props> { props ->
    val undoAlertState = useSelector(selectUndoAlert) ?: return@FC

    Alert {
        css(undoAlert)
        message = undoAlertState.textAlert
        showIcon = false
        closable = false
        type = EAlertType.INFO.text
        action = createElement(UndoButton)
    }
}

val UndoButton = FC<Props> {
    val dispatch = useAppDispatch()
    val undoAlertState = useSelector(selectUndoAlert) ?: return@FC

    Button {
        css(button)
        onClick = {
            clearTimeout(undoAlertState.timeout)
            dispatch(SetUndoAlert(null))
        }
        +"UNDO"
    }
}
