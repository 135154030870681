package entities.interactivePicture.elements.other.ui.tools.phrase

import csstype.*
import utils.types.CssStyle
import utils.types.FitContent
import utils.types.extend

val modalBody: CssStyle = {
    padding = Padding(6.px, 2.px)
}

val header: CssStyle = {
    fontFamily = string("\"Arial\", system-ui")
    fontSize = 18.px
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    lineHeight = 130.pct
    color = Color("#2F3036")
    marginBottom = 4.px
}

val subheader: CssStyle = {
    fontFamily = string("\"Arial\", system-ui")
    fontSize = 14.px
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    lineHeight = 135.pct
    color = Color("#5D6676")
    marginBottom = 17.px
}

val headerContainer: CssStyle = {
    display = Display.flex
    width = 90.pct
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.center
}

val textarea: CssStyle = {
    resize = important(None.none)
    minHeight = important(128.px)
    paddingRight = 10.pct
    paddingBottom = 10.pct
}

val textareaContainer: CssStyle = {
    marginBottom = 32.px
    width = 100.pct
    position = Position.relative
}

val textareaButtons: CssStyle = {
    position = Position.absolute
    left = 80.pct
    top = 8.pct
    height = 84.pct
    display = Display.flex
    flexDirection = FlexDirection.column
    justifyContent = JustifyContent.spaceBetween
}

val arrow: CssStyle = {
    width = 16.px
    height = 16.px
}

fun getTextareaButton(isRecording: Boolean = false, width: Int = 32): CssStyle {
    val textareaButton: CssStyle = {
        border = None.none
        this.width = width.px
        height = 32.px
        display = Display.flex
        alignItems = AlignItems.center
        justifyContent = JustifyContent.center
        padding = 0.px
        borderRadius = 50.pct
        boxShadow = None.none
    }
    if (isRecording) {
        return textareaButton.extend { backgroundColor = Color("#2F54EB") }
    }
    return textareaButton
}

val row: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
    width = 94.px
}

val space: CssStyle = {
    width = 62.px
}

val horizontalContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.start
    justifyContent = JustifyContent.start
}

val verticalContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column

    width = 100.pct

    gap = 38.px
}

val paramsGroupHeader: CssStyle = header.extend {
    fontSize = 14.px
    marginBottom = 12.px
}

val radioWithLabel: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    gap = 12.px
    justifyContent = JustifyContent.start
    alignItems = AlignItems.center
}

val radioLabel: CssStyle = subheader.extend {
    marginBottom = 0.px
}

val radioGridContainer: CssStyle = {
    display = Display.grid
    rowGap = 10.px
    columnGap = 25.px

    gridTemplateColumns = repeat(2, Auto.auto)

    alignItems = AlignItems.center

    width = FitContent.fitContent
}

val checkboxesContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
}

val checkboxLabel: CssStyle = radioLabel

val tooltipButton: CssStyle = {
    padding = 0.px
    margin = 0.px

    width = 18.px
    height = 18.px

    display = Display.block

    border = None.none
    backgroundColor = Color("#fff")
}

val stylesWrapper: CssStyle = {
    width = 100.pct
}

val stylesContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
    alignItems = AlignItems.start
    maxWidth = 184.px
}

val styleParamContainerHorizontal: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.center
    width = 100.pct
}

val inputNumber: CssStyle = {
    width = 60.px
}

val styleParamContainerVertical: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.start
    width = 100.pct
    gap = 4.px
}

val select: CssStyle = {
    width = 100.pct
}

val languageSelect: CssStyle = {
    left = 70.pct
    top = 100.pct
    position = Position.absolute
    width = 30.pct
    borderRadius = 7.px
}