package widgets.LanguageAutoContent.ui.components.Content

import antd.TextArea
import app.useAppDispatch
import csstype.integer
import csstype.px
import emotion.react.css
import entities.languageAutoCurrentRequest.SetCurrentRequestTextOrTopic
import entities.languageAutoCurrentRequest.selectCurrentRequestTextOrTopic
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import shared.components.header.Header

const val preMadeContentPlaceholder = "Paste pre-made content here..."

val PreMadeContent = FC<Props> {
    val textOrTopic = useSelector(selectCurrentRequestTextOrTopic)
    val dispatch = useAppDispatch()

    div {
        css(contentInputContainer)
        Header {
            text = "Pre-made content"
            fontSize = 14.0.px
            lineHeight = 18.9.px
            fontWeight = integer(600)
        }
        TextArea {
            css(textarea)
            value = textOrTopic
            placeholder = preMadeContentPlaceholder
            onChange = { dispatch(SetCurrentRequestTextOrTopic(it.target.value)) }
        }
    }
}