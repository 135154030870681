package widgets.LanguageAutoSlider.ui.components.LanguageAutoSliderHeader

import antd.Button
import emotion.react.css
import pages.constructor.ui.components.elements.grayFilter
import widgets.UserProfile.selectTariffPlan
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import react.useState
import shared.components.Icon
import shared.components.header.Header
import widgets.LanguageAutoSlider.ui.components.AdditionalSettingsModal.AdditionalSettingsModal

external interface LanguageAutoSliderHeaderProps : Props {
    var isLanguageAutoTaskOpened: Boolean
    var requestId: Int
    var task: String
}

val LanguageAutoSliderHeader = FC<LanguageAutoSliderHeaderProps> { props ->

    val (advancedSettingsIsOpened, setAdvancedSettingsIsOpened) = useState(false)

    val tariffPlan = useSelector(selectTariffPlan)

    AdditionalSettingsModal {
        isOpen = advancedSettingsIsOpened
        onClose = { setAdvancedSettingsIsOpened(false) }
        this.requestId = props.requestId
        this.task = props.task
    }

    div {
        css(container)
        div {
            css(headerContainer)
            Header {
                text = "3. Customize your exercises"
            }

            div {
                css(featuresContainer)
                if (!props.isLanguageAutoTaskOpened) {
                    Button {
                        css(advancedSettingsButton)
                        disabled = tariffPlan == "BASIC"
                        onClick = {
                            setAdvancedSettingsIsOpened(true)
                        }
                        Icon {
                            css(grayFilter)
                            src = "ic_settings_21x21.svg"
                        }
                        +"Advanced settings"
                    }
                }
            }
        }
    }
}
