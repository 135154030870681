package pages.constructor.ui.components.alerts

import antd.Alert
import antd.AlertProps
import app.useAppSelector
import csstype.ClassName
import emotion.react.css
import entities.alert.Alert
import entities.alert.EAlertTarget
import entities.alert.selectAlerts
import react.FC
import react.Props
import react.PropsWithClassName

external interface AlertsProps : PropsWithClassName {
    var target: EAlertTarget?
}

val Alerts = FC<AlertsProps> { props ->
    val alerts = useAppSelector(selectAlerts)
    alerts.filter{ props.target == null || it.target == props.target }.map {
        Alert {
            className = props.className
            type = it.type.text
            message = it.message
            showIcon = true
            closable = false
        }
    }
}
