package pages.wordsProgress.ui.WordProgress

import antd.Button
import antd.notification
import app.useAppDispatch
import csstype.Color
import csstype.px
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.duo.wordsprogress.WordProgressCommon
import online.interactiver.common.enums.ELanguage
import pages.studentDuo.selectRequiredStudentDuoNativeLanguage
import pages.wordsProgress.changeMarkedToPractice
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import react.useState
import shared.components.Icon
import utils.types.extend
import utils.types.impl

external interface WordProgressProps : Props {
    var wordProgress: WordProgressCommon
    var onChange: () -> Unit
}

val WordProgressComponent = FC<WordProgressProps> { props ->
    val (opened, setOpened) = useState(false)
    val nativeLanguage = useSelector(selectRequiredStudentDuoNativeLanguage)
    val dispatch = useAppDispatch()

    div {
        css(wordProgress)
        Button {
            css(wordButton.extend {
                if (props.wordProgress.markedToPractice) {
                    borderColor = Color("#597EF7")
                }
            })
            onClick = {
                setOpened { !it }
                if (!props.wordProgress.markedToPractice) {
                    props.onChange()
                    GlobalScope.launch {
                        val saved = changeMarkedToPractice(props.wordProgress)
                        if (!saved) {
                            props.onChange()
                            dispatch(OpenErrorModal("Error adding word to vocabulary"))
                            return@launch
                        }

                        notification.success(impl {
                            message = ReactNode(getWordAddedToVocabularyMessage(nativeLanguage))
                            placement = "top"
                        })
                    }
                }
            }
            +if (opened) props.wordProgress.definition else props.wordProgress.word
        }
        Button {
            css(markButton)
            Icon {
                css {
                    width = 32.px
                    height = 32.px
                }
                src = if (props.wordProgress.markedToPractice) "ic_minus_16x16.svg" else "ic_plus_16x16.svg"
            }
            onClick = {
                val wasMarkedToPractice = props.wordProgress.markedToPractice
                props.onChange()
                GlobalScope.launch {
                    val saved = changeMarkedToPractice(props.wordProgress)
                    if (!saved) {
                        props.onChange()
                        dispatch(OpenErrorModal("Error adding word to vocabulary"))
                        return@launch
                    }

                    if (wasMarkedToPractice) {
                        return@launch
                    }

                    notification.success(impl {
                        message = ReactNode(getWordAddedToVocabularyMessage(nativeLanguage))
                        placement = "top"
                    })
                }
            }
        }
    }
}

fun getWordAddedToVocabularyMessage(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Добавлено в ваш словарь"
    } else {
        "Added to your vocabulary"
    }
}
