package widgets.LanguageAutoSlider.ui.components.IntegrateModal

import Modal
import antd.Button
import csstype.integer
import csstype.px
import emotion.react.css
import kotlinx.browser.window
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.section
import react.useState
import shared.components.Icon
import shared.components.header.Header
import widgets.LanguageAutoContent.ui.generateButton

external interface IntegrateModalProps : Props {
    var isOpen: Boolean
    var onClose: () -> Unit
    var openShareModal: () -> Unit
    var embedCode: String
    var requestId: Int
}

val IntegrateModal = FC<IntegrateModalProps> { props ->
    val (isCopied, setIsCopied) = useState(false)

    Modal {
        open = props.isOpen
        footer = null
        onCancel = {
            props.onClose()
            if (isCopied) {
                setIsCopied(false)
            }
        }
        width = 650

        section {
            css(modal)
            div {
                css(headerContainer)
                Button {
                    css(backButton)
                    onClick = {
                        props.openShareModal()
                        props.onClose()
                        if (isCopied) {
                            setIsCopied(false)
                        }
                    }
                    Icon {
                        css(turn)
                        src = "ic_arrow_down_24x24.svg"
                    }
                }
                Header {
                    text = "Embed"
                }
            }
            div {
                css(contentContainer)
                div {
                    css(embedCodeContainer)
                    Header {
                        text = "HTML embed code"
                        fontSize = 14.0.px
                        lineHeight = 18.9.px
                        fontWeight = integer(600)
                    }
                    p {
                        css(embedCode)
                        +props.embedCode
                    }
                }
                Button {
                    css(generateButton)
                    onClick = {
                        window.navigator.clipboard.writeText(props.embedCode)
                        if (!isCopied) {
                            setIsCopied(true)
                        }
                    }
                    + if (isCopied) "Code is copied" else "Copy code"
                }
            }
        }
    }
}