package enums

import online.interactiver.common.interactivepicture.SolvingStageCondition
import shared.canvas.interfaces.*

enum class EActiveStateType(val text: String) {
    ALL_TIME("All time"),
    BEFORE_CHECKING("Before checking"),
    AFTER_CHECKING("After checking");

    fun toSolvingStageCondition(): SolvingStageCondition = when(this) {
        ALL_TIME -> SolvingStageCondition.allTime
        AFTER_CHECKING -> SolvingStageCondition.afterChecking
        BEFORE_CHECKING -> SolvingStageCondition.beforeChecking
    }

    companion object {
        fun fromSolvingStageCondition(solvingStageCondition: SolvingStageCondition?): EActiveStateType? {
            return if (solvingStageCondition?.isAllTimeState() == true) {
                EActiveStateType.ALL_TIME
            } else if (solvingStageCondition?.isAfterCheckingState() == true) {
                EActiveStateType.AFTER_CHECKING
            } else if (solvingStageCondition?.isBeforeCheckingState() == true) {
                EActiveStateType.BEFORE_CHECKING
            } else null
        }

        fun fromString(string: String): EActiveStateType? = EActiveStateType.values().find { it.text == string }

        val DEFAULT = EActiveStateType.ALL_TIME
    }
}