package widgets.LanguageAutoTask.ui.components.SlideEditModal.patternMetaTagsEditor.soundChecboxMetaTagEditor

import emotion.react.css
import online.interactiver.common.interactiveexercise.EExerciseUiPatternMetaTag
import react.FC
import react.dom.html.ReactHTML.div
import shared.components.checkbox.ButtonColor
import shared.components.checkboxWithLabel.CheckboxWithLabel
import widgets.languageAutoTask.ui.components.SlideEditModal.patternMetaTagsEditor.soundShowingMetaTagEditor.header
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.PatternMetaTagsEditorProps

val SoundCheckboxMetaTagEditor = FC<PatternMetaTagsEditorProps> { props ->
    val value = props.value ?: listOf()

    val checked = value.contains(EExerciseUiPatternMetaTag.NO_SOUND)

    div {
        div {
            css(header)
            +"Sound pattern"
        }
        CheckboxWithLabel {
            this.checked = checked
            onChange = {
                val newValue = value.toMutableList()
                if (it) {
                    newValue.add(EExerciseUiPatternMetaTag.NO_SOUND)
                } else {
                    newValue.remove(EExerciseUiPatternMetaTag.NO_SOUND)
                }

                props.onChange?.invoke(newValue)
            }
            label = "No sound"
            iconFilter = ButtonColor.BLUE
        }
    }
}
