package pages.constructor.ui.components.header.undoRedoCopyDelete

import csstype.*
import utils.types.CssStyle

val button: CssStyle = {
    borderWidth = 0.px
}

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.center
    alignItems = AlignItems.center
    padding = 0.px
    gap = 20.px
    flex = null
    order = integer(1)
    flexGrow = number(0.0)
}
