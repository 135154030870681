package entities.interactivePicture.elements.controls.button.ui.editor

import app.useAppDispatch
import app.useAppSelector
import builders.enums.EInteractiveType
import csstype.FlexDirection
import emotion.react.css
import entities.interactivePicture.elements.editors.textEditor.container
import entities.interactivePicture.elements.editors.textEditor.editorTextContainer
import react.dom.html.ReactHTML.div
import entities.interactivePicture.elements.gapPuzzles.drags.SetCorrectState
import entities.interactivePicture.elements.gapPuzzles.drags.SetCorrectStateForElements
import entities.interactivePicture.selectElementsUnderSelectionRectangle
import entities.interactivePicture.selectFocusedElement
import enums.EButtonTypes
import react.FC
import react.Props
import shared.canvas.interfaces.getValueForEditor
import shared.canvas.interfaces.shouldDrawEditor
import shared.components.checkbox.Checkbox


val CheckboxStateEditor = FC<Props> a@{
    val selected = useAppSelector(selectFocusedElement)
    val elementsUnderSelectionRectangle = useAppSelector(selectElementsUnderSelectionRectangle)
    val dispatch = useAppDispatch()

    if (!shouldDrawEditor(selected, elementsUnderSelectionRectangle) { element -> element.type == EInteractiveType.BUTTON_INTERACTIVE.text }) {
        return@a
    }

    val checked = getValueForEditor(selected, elementsUnderSelectionRectangle) { element -> element.button!!.correctState ?: "" }

    div {
        css(container(FlexDirection.row))
        div {
            css(editorTextContainer)

            +"Correct state"

            Checkbox {
                id = EButtonTypes.CORRECT_STATE_BUTTON.value
                this.checked = checked.equals("selected")
                onChange = { checked: Boolean ->
                    val value = if (checked) "selected" else "unselected"
                    if (selected != null) {
                        dispatch(SetCorrectState(selected.identifier.id!!, value))
                    } else {
                        dispatch(SetCorrectStateForElements(elementsUnderSelectionRectangle.map { it.identifier.id!! }.toTypedArray(), value))
                    }
                }
            }
        }
    }
}
