package shared.canvas

import builders.enums.ESoundBadgeAlign
import builders.enums.ESoundBadgePosition
import online.interactiver.common.interactivepicture.Sound
import react.*
import reactkonva.Group
import reactkonva.Image
import shared.canvas.implementations.onPlayPauseAudio
import shared.canvas.implementations.useAudio
import shared.canvas.implementations.useSoundImages
import utils.structures.Position

external interface SoundProps : PropsWithRef<dynamic> {
    var sound: Sound
    var parentBounds: Position?
    var parentRef: MutableRefObject<dynamic>
    var iconSize: Int?
}

val Sound = forwardRef { props: SoundProps, ref ->
    val imageRef = useRef<dynamic>()
    val (playImage, pauseImage) = useSoundImages()
    val (isPlaying, setIsPlaying) = useState(false)
    val audio = useAudio(props.sound) { setIsPlaying(it) }

    val position = ESoundBadgePosition.fromString(props.sound.badgePosition)

    if (props.parentBounds == null) {
        throw RuntimeException("parentBounds were not provided for Sound!")
    }

    val parentHeight = props.parentBounds!!.height
    val parentWidth = props.parentBounds!!.width
    val width = props.iconSize ?: 0
    val x = soundX(position, parentWidth, width)
    val y = soundY(parentHeight, width)

    Group {
        this.ref = ref
        attrs {
            this.x = x
            this.y = y
            this.onTransform = { event ->
                val parent = props.parentRef.current
                val parentHeight = parent.height() as? Int ?: 0
                val parentWidth = parent.width() as? Int ?: 0
                val x = soundX(position, parentWidth, width)
                val y = soundY(parentHeight, width)

                val group = event.target
                group.scaleX(1.0)
                group.scaleY(1.0)
                group.x(x)
                group.y(y)
            }
        }
        Image {
            this.ref = imageRef
            attrs {
                this.width = width
                this.height = width
                this.image = if (isPlaying) pauseImage else playImage
                this.onClick = {
                    it.cancelBubble = true
                    onPlayPauseAudio(audio, isPlaying) { setIsPlaying(it) }
                }
            }
        }
    }
}

fun soundWidthOverlappingRect(position: ESoundBadgePosition, width: Int): Int {
    return ((1 - position.offset) * width).toInt()
}

fun soundX(position: ESoundBadgePosition, parentWidth: Int, width: Int): Int {
    return when(position.align) {
        ESoundBadgeAlign.LEFT -> -position.offset * width
        ESoundBadgeAlign.RIGHT -> parentWidth - (1 - position.offset) * width
    }.toInt()
}

fun soundY(parentHeight: Int, width: Int): Int {
    return (parentHeight - width) / 2
}
