package widgets.LanguageAutoContent.ui.components.Content

import antd.Input
import app.useAppDispatch
import csstype.integer
import csstype.px
import emotion.react.css
import entities.languageAutoCurrentRequest.SetCurrentRequestTextOrTopic
import entities.languageAutoCurrentRequest.selectCurrentRequestTextOrTopic
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import shared.components.header.Header

const val grammarContentPlaceholder = "Past Simple vs Present Perfect in food"

val GrammarContent = FC<Props> {
    val textOrTopic = useSelector(selectCurrentRequestTextOrTopic)
    val dispatch = useAppDispatch()

    div {
        css(contentInputContainer)
        Header {
            text = "Topic (be as specific as possible!)"
            fontSize = 14.px
            lineHeight = 18.9.px
            fontWeight = integer(600)
        }
        Input {
            value = textOrTopic
            placeholder = grammarContentPlaceholder
            onChange = { dispatch(SetCurrentRequestTextOrTopic(it.target.value)) }
        }
    }
}
