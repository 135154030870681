package widgets.LanguageAutoContent.ui.components.Content

import antd.Input
import app.useAppDispatch
import csstype.integer
import csstype.px
import emotion.react.css
import entities.languageAutoCurrentRequest.SetCurrentRequestTextOrTopic
import entities.languageAutoCurrentRequest.SetCurrentRequestVocabulary
import entities.languageAutoCurrentRequest.selectCurrentRequestTextOrTopic
import entities.languageAutoCurrentRequest.selectCurrentRequestVocabulary
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import shared.components.header.Header

const val textPlaceholder = "Pets"
const val storytellingTopicPlaceholder = "Medicine"
const val storytellingTextPlaceholder = "Example: visit to dentist with extreme pain, past or present time"

val StorytellingContent = FC<Props> {
    val textOrTopic = useSelector(selectCurrentRequestTextOrTopic)
    val vocabulary = useSelector(selectCurrentRequestVocabulary)
    val dispatch = useAppDispatch()

    div {
        css(contentInputContainer)
        Header {
            text = "Topic"
            fontSize = 14.0.px
            lineHeight = 18.9.px
            fontWeight = integer(600)
        }
        Input {
            value = textOrTopic
            placeholder = storytellingTopicPlaceholder
            onChange = { dispatch(SetCurrentRequestTextOrTopic(it.target.value)) }
        }
    }

    TextAreaWithLengthRestriction {
        label = "Details you want to learn"
        value = vocabulary
        placeholder = storytellingTextPlaceholder
        onChange = { dispatch(SetCurrentRequestVocabulary(it)) }
    }
}