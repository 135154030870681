package builders

import builders.enums.*
import entities.interactivePicture.elements.controls.inputs.inputTextStyle
import entities.interactivePicture.elements.gapPuzzles.drags.gapStyle
import entities.interactivePicture.elements.other.hintGeometryStyle
import entities.interactivePicture.elements.other.hintTextStyle
import entities.interactivePicture.elements.toOnFocus
import entities.interactivePicture.elements.toOnHover
import online.interactiver.common.interactivepicture.*


open class ElementBuilder {

    var id: String = ""
    var picture: Picture? = null
    var sound: Sound? = null
    var phrase: Phrase? = null
    open var interactiveType: String = EInteractiveType.FIGURE_INTERACTIVE.text
    var elementType: String = EElementType.TEXT_STATIC.text

    var geometry: Geometry = Geometry()
    var textFrame: TextFrame = TextFrame()
    var defaultStyle: GeometryStyle = GeometryStyle()
    var hoverStyle: GeometryStyle = GeometryStyle()
    var focusStyle: GeometryStyle = GeometryStyle()
    var selectStyle: GeometryStyle? = null

    var button: Button? = null

    var hintTextFrame: TextFrame = TextFrame(style = hintTextStyle)
    var hintGeometry: Geometry = Geometry()
    var hintDefaultStyle: GeometryStyle = hintGeometryStyle
    var hintFocusStyle: GeometryStyle = hintGeometryStyle
    var hintHoverStyle: GeometryStyle = hintGeometryStyle

    var scores: MutableList<ScoreData> = ArrayList();

    var geometryType: EGeometryType = EGeometryType.RECT

    var ratio: Double = 0.0

    var selector: Selector? = null

    var gapPuzzle: GapPuzzle? = null

    var input: Input? = null

    var isSynced: Boolean? = null

    init {
        refreshRect()
        refreshStyle()
    }

    private fun getStartPosition(): Position {
        return Position(absolutePosition = Point(100, 100))
    }

    private fun getIdentifier(suffix: String): Identifier {
        val identifier = Identifier(id = id + suffix)
        val elemType = EElementType.values().find { it.text == this.elementType }!!
        identifier.setIdentifierByText(elemType.shortName + suffix)
        return identifier
    }

    fun addScore(score: ScoreData): ElementBuilder {
        scores.add(score);
        return this;
    }

    fun setType(type: EElementType): ElementBuilder {
        this.elementType = type.text
        return this
    }

    fun setType(type: EInteractiveType): ElementBuilder {
        this.interactiveType = type.text
        return this
    }

    fun setIsSynced(isSynced: Boolean): ElementBuilder {
        this.isSynced = isSynced
        return this
    }

    fun refreshRect(): ElementBuilder {
        this.setRect(
                rect = Rect(
                        leftTopPosition = this.getStartPosition(),
                        width = 100,
                        height = 100,
                )
        )
        return this
    }

    fun setRect(rect: Rect): ElementBuilder {
        this.geometry = Geometry(rect = rect)
        this.geometryType = EGeometryType.RECT
        return this
    }

    fun setCurve(start: Point, startType: String?, end: Point, endType: String?): ElementBuilder {
        this.geometry = Geometry(
                curve = Curve(
                        start = Position(absolutePosition = start),
                        end = Position(absolutePosition = end),
                        middle = Position(absolutePosition = Point((start.x + end.x) / 2, (start.y + end.y) / 2)),
                        startType = startType,
                        endType = endType
                )
        )
        this.geometryType = EGeometryType.CURVE
        return this
    }

    fun setID(id: String): ElementBuilder {
        this.id = id
        return this
    }

    fun refreshCircle(): ElementBuilder {
        this.setCircle(Circle(centerPosition = this.getStartPosition(), radius = 50))
        return this
    }

    fun setCircle(circle: Circle): ElementBuilder {
        this.geometry = Geometry(circle = circle)
        this.geometryType = EGeometryType.CIRCLE
        return this
    }

    fun setText(text: String): ElementBuilder {
        this.textFrame.simpleText = text
        return this
    }

    fun refreshStyle(): ElementBuilder {
        this.setStyles(
                GeometryStyle(
                        fillColor = "#F7F7F7",
                        opacity = 1.0,
                )
        )
        return this
    }

    fun setStyles(style: GeometryStyle): ElementBuilder {
        EStyleType.values().forEach { this.setStyle(style, it) }
        return this
    }

    fun setStyle(style: GeometryStyle, styleType: EStyleType): ElementBuilder {
        when (styleType) {
            EStyleType.USUAL -> this.defaultStyle = style
            EStyleType.HOVER -> this.hoverStyle = style
            EStyleType.FOCUS -> this.focusStyle = style
            EStyleType.SELECT -> this.selectStyle = style
            else -> return this
        }
        return this
    }

    fun setDefaultStyle(style: GeometryStyle): ElementBuilder = setStyle(style, EStyleType.USUAL)

    fun setFocusStyle(style: GeometryStyle): ElementBuilder = setStyle(style, EStyleType.FOCUS)

    fun setHoverStyle(style: GeometryStyle): ElementBuilder = setStyle(style, EStyleType.HOVER)

    fun setSelectStyle(style: GeometryStyle): ElementBuilder = setStyle(style, EStyleType.SELECT)

    fun setHoverFocusStyling(style: GeometryStyle): ElementBuilder {
        setDefaultStyle(style)
        setHoverStyle(style.toOnHover())
        setFocusStyle(style.toOnFocus())
        return this
    }

    fun setTextStyle(style: TextStyle): ElementBuilder {
        this.textFrame.style = style
        return this
    }

    fun setHintText(text: String): ElementBuilder {
        this.hintTextFrame.simpleText = text
        return this
    }

    fun setHintStyle(style: GeometryStyle, styleType: EStyleType): ElementBuilder {
        when (styleType) {
            EStyleType.USUAL -> this.hintDefaultStyle = style
            EStyleType.HOVER -> this.hintHoverStyle = style
            EStyleType.FOCUS -> this.hintFocusStyle = style
            else -> return this
        }
        return this
    }

    fun setHintDefaultStyle(style: GeometryStyle): ElementBuilder = setHintStyle(style, EStyleType.USUAL)

    fun setHintTextStyle(style: TextStyle): ElementBuilder {
        this.hintTextFrame.style = style
        return this
    }

    fun setColor(color: String, styleType: EStyleType = EStyleType.USUAL): ElementBuilder {
        when (styleType) {
            EStyleType.USUAL -> this.defaultStyle.fillColor = color
            EStyleType.HOVER -> this.hoverStyle.fillColor = color
            EStyleType.FOCUS -> this.focusStyle.fillColor = color
            else -> return this
        }
        return this
    }

    fun setPosition(position: Position): ElementBuilder {
        when (this.geometryType) {
            EGeometryType.RECT -> this.geometry.rect!!.leftTopPosition = position
            EGeometryType.CIRCLE -> this.geometry.circle!!.centerPosition = position
            else -> return this
        }

        return this
    }

    fun setRectSize(width: Int, height: Int): ElementBuilder {
        if (this.geometryType == EGeometryType.RECT) {
            this.geometry.rect!!.width = width
            this.geometry.rect!!.height = height
        }
        return this
    }

    fun setRect(position: utils.structures.Position?): ElementBuilder {
        val pos = position ?: utils.structures.Position(100, 100, 100, 100)
        setRectSize(pos.width, pos.height)
        setPosition(Position(absolutePosition = Point(pos.x, pos.y)))
        return this
    }

    fun setGap(position: utils.structures.Position?): ElementBuilder {
        setRect(position)
        gapPuzzle = GapPuzzle(
                gap = SimpleElement(
                        type = "GapDragTo",
                        geometry = geometry,
                        style = ElementStyle(
                                usual = gapStyle
                        )
                )
        )
        return this
    }

    fun setInput(): ElementBuilder {
        input = Input(
                correctValue = textFrame.simpleText,
                placeholderText = "Type text",
                style = inputTextStyle,
        )
        return this
    }

    fun setRatio(ratio: Double): ElementBuilder {
        this.ratio = ratio
        return this
    }

    fun setButton(state: String): ElementBuilder {
        this.button = Button(correctState = state)
        return this
    }

    fun setPicture(base64: String, fileName: String): ElementBuilder {
        this.picture = Picture(base64 = base64, source = Source(fileName = fileName))

        if (this.geometryType != EGeometryType.RECT) {
            this.refreshRect()
        }


        return this
    }

    fun setSound(src: String, filename: String?, position: String?, activation: String?): ElementBuilder {
        this.sound = Sound(src, null, filename)
        return this
    }

    fun setPhrase(phrase: Phrase?): ElementBuilder {
        this.phrase = phrase
        return this
    }

    fun setSelectorDefaultText(text: String): ElementBuilder {
        if (this.selector != null) {
            this.selector!!.defaultText = text
        } else {
            this.selector = Selector(defaultText = text)
        }
        return this
    }

    private fun updateGeometryWithRatio() {
        if (geometryType != EGeometryType.RECT) {
            return
        }

        if (ratio < 0.001) {
            return
        }

        this.geometry.rect!!.height = (this.geometry.rect!!.width!! * this.ratio).toInt()
    }

    fun build(): InteractiveElement {
        this.updateGeometryWithRatio()

        val element = InteractiveElement(
                type = this.interactiveType,
                identifier = this.getIdentifier(""),
                visibleElement = SimpleElement(
                        type = this.elementType, identifier = this.getIdentifier("_VisibleElement"),
                        geometry = this.geometry,
                        picture = this.picture,
                        text = this.textFrame,
                        style = ElementStyle(
                                usual = this.defaultStyle,
                                onHover = this.hoverStyle,
                                onFocus = this.focusStyle,
                                onSelect = this.selectStyle,
                        ),
                        showCondition = ShowCondition(
                                solvingStageCondition = SolvingStageCondition(
                                        activeBeforeSolvingStart = "1",
                                        activeWhileSolving = "1",
                                        activeAfterFirstVerification = "1",
                                        activeAfterFinalVerification = "1",
                                ),
                                scoreConditions = null
                        )
                ),
                hint = SimpleElement(
                        type = this.elementType, identifier = this.getIdentifier("_Hint"),
                        geometry = this.hintGeometry, text = this.hintTextFrame,
                        style = ElementStyle(
                                usual = this.hintDefaultStyle,
                                onHover = this.hintHoverStyle,
                                onFocus = this.hintHoverStyle
                        ),
                        showCondition = ShowCondition(
                                solvingStageCondition = SolvingStageCondition(
                                        activeBeforeSolvingStart = "1",
                                        activeWhileSolving = "1",
                                        activeAfterFirstVerification = "1",
                                        activeAfterFinalVerification = "1",
                                ),
                                scoreConditions = null
                        )
                ),
                scores = scores,
                selector = this.selector,
                gapPuzzle = gapPuzzle,
                button = this.button,
                input = this.input,
                sound = this.sound,
                phrase = this.phrase,
                isSynced = this.isSynced
        )

        val elemType = EElementType.values().find { it.text == this.elementType }!!
        val textToGenerateIdentifier = "${elemType.shortName} ${elemType.textToDefineIdentifier.textField(element)}"
        element.identifier.setIdentifierByText(textToGenerateIdentifier)

        return element
    }
}

fun getFigureBuilder(): ElementBuilder {
    return ElementBuilder().setType(EInteractiveType.FIGURE_INTERACTIVE).setHintText("")
}

fun getLineBuilder(): ElementBuilder {
    return ElementBuilder().setType(EInteractiveType.CONNECTION_INTERACTIVE)
}

fun getDragBuilder(): ElementBuilder {
    return ElementBuilder().setType(EInteractiveType.GAP_PUZZLE_INTERACTIVE)
}

fun getFrameBuilder(): ElementBuilder {
    return ElementBuilder().setType(EInteractiveType.FRAME_INTERACTIVE).setHintText("")
}

fun getPhraseBuilder(): ElementBuilder {
    return ElementBuilder().setType(EInteractiveType.PHRASE_INTERACTIVE).setType(EElementType.PHRASE_OTHER)
}
