package online.interactiver.common.enums

enum class ELogoUseType(val value: String, val uiValue: String) {
    DEFAULT_LOGO("default logo", "Inream logo (default)"),
    NO_LOGO("no logo", "No logo"),
    CUSTOM_LOGO("custom logo", "Custom logo");

    companion object {
        fun fromUiValue(uiValue: String): ELogoUseType? {
            return entries.find { uiValue == it.uiValue }
        }
        fun fromString(value: String): ELogoUseType? {
            return entries.find { value == it.value }
        }
    }
}