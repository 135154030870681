package shared.canvas.utils

import app.StoreState
import entities.backgroundPosScale.selectBackgroundMargin
import entities.interactivePicture.background.selectCanvasHeight
import entities.interactivePicture.background.selectCanvasWidth
import entities.interactivePicture.elements.gapPuzzles.drags.selectFocusedOptionFiguresAndLines
import entities.interactivePicture.elements.gapPuzzles.drags.selectFocusedOptionFrames
import entities.interactivePicture.elements.other.selectOthers
import entities.interactivePicture.guides.GUIDELINE_OFFSET
import entities.interactivePicture.guides.LineGuide
import entities.interactivePicture.guides.ObjectSnappingEdges
import entities.interactivePicture.selectControls
import entities.interactivePicture.selectFiguresAndLines
import entities.interactivePicture.selectFrames
import entities.interactivePicture.selectGapPuzzles
import enums.EOrientation
import enums.ESnapType
import shared.canvas.interfaces.getElementBounds
import utils.structures.Position
import kotlin.math.abs

// Возвращает набор всех линий выравниваний всех объектов
// Пробегается по всем объектам и собирает их линии
val getObjectsSnappingEdges = { state: StoreState ->
    val canvasWidth = selectCanvasWidth(state) ?: 600
    val canvasHeight = selectCanvasHeight(state) ?: 400
    val backgroundMargin = selectBackgroundMargin(state)
    val edges = mutableListOf<ObjectSnappingEdges>()
    arrayOf(
        selectFrames,
        selectFiguresAndLines,
        selectControls,
        selectGapPuzzles,
        selectOthers,
        selectFocusedOptionFrames,
        selectFocusedOptionFiguresAndLines
    ).forEach { selector ->
        selector(state)?.forEach {
            val bounds = it.getElementBounds()
            if (bounds.x < canvasWidth && bounds.y < canvasHeight) {
                edges.add(getObjectSnappingEdge(it.identifier.id!!, bounds))
            }
            if (it.gapPuzzle?.customPuzzleInitialPosition != null) {
                edges.add(getObjectSnappingEdge(it.identifier.id!! + "_gap", it.gapPuzzle!!.gap!!.getElementBounds()))
            }
        }
    }

    edges.add(getCanvasSnappingEdges(state, backgroundMargin))
    edges.add(getCanvasSnappingEdges(state, EDGE_PADDING))

    edges
}


val EDGE_PADDING = 32

val getCanvasSnappingEdges = { state: StoreState, padding: Int ->
    val backgroundMargin = selectBackgroundMargin(state)
    val canvasWidth = (selectCanvasWidth(state) ?: 600) + 2 * backgroundMargin
    val canvasHeight = (selectCanvasHeight(state) ?: 400) + 2 * backgroundMargin
    getObjectSnappingEdge(
        "canvas",
        Position(padding, padding, canvasWidth - 2 * padding, canvasHeight - 2 * padding)
    )
}

// Возвращает линию объекта соответсвующую якорю
fun getObjectAnchorEdge(bounds: Position, anchor: ESnapType, orientation: EOrientation): LineGuide {
    val snapping = getObjectSnappingEdge("", bounds)

    val edges = if (orientation == EOrientation.HORIZONTAL) snapping.horizontal else snapping.vertical

    edges.forEach { guide ->
        if (guide.snap == anchor) {
            return guide
        }
    }

    return edges.get(0)
}


// Возвращает набор всех линий выравниваний одного объекта
// Всего возрващается 6 линий, на каждую ориентацию по 3
// Линии в одной ориентации: start, center, end
fun getObjectSnappingEdge(uid: String, bounds: Position): ObjectSnappingEdges {
    var horizontalEdges = ArrayList<LineGuide>()
    horizontalEdges.add(
        LineGuide(
            ownerUID = uid,
            position = bounds.y,
            snap = ESnapType.START,
            diff = 0,
            offset = 0,
            orientation = EOrientation.HORIZONTAL
        )
    )
    horizontalEdges.add(
        LineGuide(
            ownerUID = uid,
            position = bounds.y + bounds.height / 2,
            snap = ESnapType.CENTER,
            diff = 0,
            offset = bounds.height / 2,
            orientation = EOrientation.HORIZONTAL
        )
    )
    horizontalEdges.add(
        LineGuide(
            ownerUID = uid,
            position = bounds.y + bounds.height,
            snap = ESnapType.END,
            diff = 0,
            offset = bounds.height,
            orientation = EOrientation.HORIZONTAL
        )
    )

    var verticalEdges = ArrayList<LineGuide>()
    verticalEdges.add(
        LineGuide(
            ownerUID = uid,
            position = bounds.x,
            snap = ESnapType.START,
            diff = 0,
            offset = 0,
            orientation = EOrientation.VERTICAL
        )
    )
    verticalEdges.add(
        LineGuide(
            ownerUID = uid,
            position = bounds.x + bounds.width / 2,
            snap = ESnapType.CENTER,
            diff = 0,
            offset = bounds.width / 2,
            orientation = EOrientation.VERTICAL
        )
    )
    verticalEdges.add(
        LineGuide(
            ownerUID = uid,
            position = bounds.x + bounds.width,
            snap = ESnapType.END,
            diff = 0,
            offset = bounds.width,
            orientation = EOrientation.VERTICAL
        )
    )


    return ObjectSnappingEdges(horizontalEdges, verticalEdges)
}


// На вход принимает:
// lineGuideStops: множество линий выравниваний
// uid: айди родителя
// bounds: размера родителя
// Возвращает от 0 до 2х линий выравнивания
fun getGuideLines(
    lineGuideStops: List<ObjectSnappingEdges>,
    uid: String,
    bounds: Position,
    anchorVert: ESnapType,
    anchorHoriz: ESnapType
): List<LineGuide> {
    val guides = mutableListOf<LineGuide>()
    val guidesVertical = mutableListOf<LineGuide>()
    val guidesHorizontal = mutableListOf<LineGuide>()

    val itemBounds = getObjectSnappingEdge(uid, bounds)

    // Пробегается по всем "объектам" на канвасе
    val extractedHorizontal = extractPreferredGuides(itemBounds.horizontal, anchorHoriz)
    lineGuideStops.forEach { snappingEdges ->
        // Пробегается по всем горизонтальным линиям объекта на канвасе
        snappingEdges.horizontal.forEach { guide ->
            // Пробегается по всем горизонтальным линиям объекта инициатора
            extractedHorizontal.forEach { itemGuide ->
                val diff = guide.position - itemGuide.position

                // Если происходит ресайз пропускаем центральную линию инициатора
                val skip =
                    (anchorHoriz != ESnapType.ALL && itemGuide.snap == ESnapType.CENTER) || anchorHoriz == ESnapType.SKIP

                // Если расстояние до объекта меньше контрольного
                // И если линия не принадлежит объекту инициатору
                // Добавляет линию как потенциальную линию для выравнивания
                if (abs(diff) < GUIDELINE_OFFSET && !guide.ownerUID.equals(itemGuide.ownerUID) && !skip) {
                    val tmp =
                        LineGuide(guide.ownerUID, guide.position, itemGuide.offset, diff, guide.snap, guide.orientation)
                    guidesHorizontal.add(tmp)
                }
            }
        }
        // Пробегается по всем вертикальным линиям объекта на канвасе
        val extractedVertical = extractPreferredGuides(itemBounds.vertical, anchorVert)
        snappingEdges.vertical.forEach { guide ->
            // Пробегается по всем вертикальным линиям объекта инициатора
            extractedVertical.forEach { itemGuide ->
                val diff = guide.position - itemGuide.position

                // Если происходит ресайз пропускаем центральную линию инициатора
                val skip =
                    (anchorVert != ESnapType.ALL && itemGuide.snap == ESnapType.CENTER) || anchorVert == ESnapType.SKIP

                // Если расстояние до объекта меньше контрольного
                // И если линия не принадлежит объекту инициатору
                // Добавляет линию как потенциальную линию для выравнивания
                if (abs(diff) < GUIDELINE_OFFSET && !guide.ownerUID.equals(itemGuide.ownerUID) && !skip) {
                    val tmp =
                        LineGuide(guide.ownerUID, guide.position, itemGuide.offset, diff, guide.snap, guide.orientation)
                    guidesVertical.add(tmp)
                }
            }
        }
    }

    // Сортируем линии по увеличению расстояния
    val comparator = Comparator { a: LineGuide, b: LineGuide -> abs(a.diff) - abs(b.diff) }
    guidesVertical.sortWith(comparator)
    guidesHorizontal.sortWith(comparator)


    // Берем линию с самым маленьким расстоянием
    // Если таких несколько - берем центральную
    val verticalGuide = getClosestLine(guidesVertical)
    if (verticalGuide != null) {
        guides.add(verticalGuide)
    }

    // Берем линию с самым маленьким расстоянием
    // Если таких несколько - берем центральную
    val horizontalGuide = getClosestLine(guidesHorizontal)
    if (horizontalGuide != null) {
        guides.add(horizontalGuide)
    }

    return guides
}

// На вход получает список линий
// Возвращает список линий, diff которых равен первому значению в списке
fun extractEqualGuides(guides: List<LineGuide>): List<LineGuide> {
    if (guides.size == 0) {
        return guides
    }

    var result = mutableListOf<LineGuide>()
    val diff = abs(guides.get(0).diff)

    guides.forEach { guide ->
        if (abs(guide.diff) == diff) {
            result.add(guide)
        }
    }

    return result
}


// На вход получает список линий
// Возвращает список линий, тип которых совпадает с типом якоря
fun extractPreferredGuides(guides: List<LineGuide>, anchor: ESnapType): List<LineGuide> {

    if (anchor == ESnapType.ALL) {
        return guides
    }

    if (guides.size == 0) {
        return guides
    }

    var result = mutableListOf<LineGuide>()

    guides.forEach { guide ->
        if (guide.snap == anchor) {
            result.add(guide)
        }
    }

    return result
}


// На вход получает список линий, и линию якоря
// На выход отдает:
// null, если список пуст
// Первую линию, если она одна
// Центральную линию, если она есть
fun getClosestLine(guides: List<LineGuide>): LineGuide? {
    val equalGuides = extractEqualGuides(guides)

    if (equalGuides.size == 0) {
        return null
    }

    if (equalGuides.size == 1) {
        return equalGuides.get(0)
    }

    for (i in 0..equalGuides.size - 1) {
        val guide = equalGuides[i]
        if (guide.snap == ESnapType.CENTER) {
            return guide
        }
    }

    return guides.get(0)
}


// Конвертирует название якоря трансформации
// И выбранную ориентацию
// В линию для игнора выравнивания
fun anchorToPreferredLine(anchor: String, orientation: EOrientation): ESnapType {
    when (anchor) {
        "top-left" -> return ESnapType.START
        "middle-left" -> return if (orientation == EOrientation.VERTICAL) ESnapType.START else ESnapType.SKIP
        "top-center" -> return if (orientation == EOrientation.HORIZONTAL) ESnapType.START else return ESnapType.SKIP
        "bottom-right" -> return ESnapType.END
        "middle-right" -> return if (orientation == EOrientation.VERTICAL) ESnapType.END else ESnapType.SKIP
        "bottom-center" -> return if (orientation == EOrientation.HORIZONTAL) ESnapType.END else ESnapType.SKIP
        "top-right" -> return if (orientation == EOrientation.HORIZONTAL) ESnapType.START else ESnapType.END
        "bottom-left" -> return if (orientation == EOrientation.HORIZONTAL) ESnapType.END else ESnapType.START
        else -> return ESnapType.CENTER
    }
}