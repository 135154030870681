package pages.constructor.ui.components.advancedSettings.hintsSettings

import emotion.react.css
import online.interactiver.common.interactivepicture.HintsSettings
import pages.constructor.ui.components.advancedSettings.hintsSettings.hintShowAction.HintShowActionEditor
import pages.constructor.ui.components.advancedSettings.hintsSettings.hintStyle.HintStyleEditor
import pages.constructor.ui.components.advancedSettings.settingHeader
import react.FC
import react.Props
import react.dom.html.ReactHTML.div

external interface HintsSettingsEditorProps : Props {
    var settings: HintsSettings
    var onChange: (HintsSettings) -> Unit
}

val HintsSettingsEditor = FC<HintsSettingsEditorProps> { props ->
    div {
        css(settingHeader)
        +"Hints"
    }
    HintShowActionEditor {
        showAction = props.settings.showAction
        onChange = {
            props.onChange(props.settings.copy(showAction = it))
        }
    }
    HintStyleEditor {
        color = props.settings.hintColor
        onChange = {
            props.onChange(props.settings.copy(hintColor = it))
        }
    }
}
