package entities.interactivePicture.elements.other.ui.tools.phrase

import Modal
import antd.*
import online.interactiver.common.enums.ELanguage
import app.*
import builders.enums.EPhraseType
import emotion.react.css
import entities.dictionary.ui.getSoundFromDictionaryOrGoogleCloud
import entities.dictionary.ui.getSoundsFromDictionaryOrGoogleCloud
import entities.interactivePicture.background.selectCanvasWidth
import entities.interactivePicture.elements.figuresAndLines.static.AddSound
import entities.interactivePicture.elements.figuresAndLines.static.AddStaticPhrase
import entities.interactivePicture.elements.figuresAndLines.static.SetSounds
import entities.interactivePicture.elements.other.AddSentenceBuilderPhrase
import entities.interactivePicture.elements.shared.tools.button
import entities.interactivePicture.elements.shared.tools.buttonText
import entities.interactivePicture.selectInteractiveGroup
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import getAttachedDictionary
import io.ktor.client.fetch.*
import io.ktor.util.date.*
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.js.jso
import kotlinx.js.timers.Timeout
import kotlinx.js.timers.clearInterval
import kotlinx.js.timers.setInterval
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import online.interactiver.common.admin.interactive.Dictionary
import online.interactiver.common.interactivepicture.PhraseStyle
import online.interactiver.common.utils.getTimeMillisInBase
import online.interactiver.common.utils.splitToPhraseWords
import online.interactiver.common.utils.toBase
import org.w3c.files.FileReader
import pages.constructor.ui.components.editors.designEditor.activeFilter
import pages.constructor.ui.components.elements.whiteFilter
import react.*
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import reactaudiovoicerecorder.useReactMediaRecorder
import shared.canvas.interfaces.getLanguageByText
import shared.canvas.utils.EDGE_PADDING
import shared.components.Icon
import shared.components.checkbox.Checkbox
import shared.components.radio.Radio
import utils.result
import utils.types.extend
import utils.types.jsObject

enum class EPhraseHintAdditional(val label: String) {
    EXPLANATION("Hint with explanation"),
    PHRASE_SOUND("Phrase sound"),
    PIECES_SOUND("Pieces sound")
}

enum class EPhraseElementsAlign(val value: String, val label: String) {
    LEFT("Left", "Left"), RIGHT("Right", "Right");

    companion object {
        fun fromString(value: String): EPhraseElementsAlign =
            EPhraseElementsAlign.values().find { it.value == value } ?: throw IllegalArgumentException()
    }
}

val PhraseTool = FC<Props> {
    val (modalIsOpen, setModalIsOpen) = useState(false)
    val (phrase, setPhrase) = useState("")
    val (taskType, setTaskType) = useState(EPhraseType.WORD_EXPLORER)
    val (explanation, setExplanation) = useState(true)
    val (piecesSound, setPiecesSound) = useState(false)
    val (phraseSound, setPhraseSound) = useState(false)
    val (style, setStyle) = useState(PhraseStyle())
    val (language, setLanguage) = useState(ELanguage.ENGLISH)

    val canvasWidth = useAppSelector(selectCanvasWidth) ?: Int.MAX_VALUE
    val x = EDGE_PADDING
    val (phraseWidth, setPhraseWidth) = useState(canvasWidth - 2 * x)

    val recorder = useReactMediaRecorder(jso { this.audio = true })
    val (countdown, setCountdown) = useState(0)
    val (countdownId, setCountdownId) = useState<Timeout>()

    val (isSelectLanguageOpen, setIsSelectLanguageOpen) = useState(false)

    val dispatch = useAppDispatch()
    val interactiveGroupId = useAppSelector(selectInteractiveGroup)

    useEffect(interactiveGroupId) {
        if (interactiveGroupId == null || interactiveGroupId == 0) return@useEffect

        dispatch(StartModalLoading("Loading dictionary, please wait"))
        GlobalScope.launch {
            val response = getAttachedDictionary(interactiveGroupId)
            if (response.code == 200) {
                val dictionary = Json.decodeFromString<Dictionary>(response.content)
                ELanguage.values().firstOrNull { it.code == dictionary.materialLanguage }?.let { setLanguage(it) }
            }
            dispatch(EndModalLoading())
        }
    }

    useEffect(recorder.mediaBlobUrl) {
        val mediaBlobUrl = recorder.mediaBlobUrl ?: return@useEffect

        fetch(mediaBlobUrl).then {
            it.blob()
        }.then { blob ->
            val reader = FileReader()
            reader.onloadend = {
                val base64String = it.target.result
                if (base64String != null) {
                    dispatch(StartModalLoading("Loading text, please wait"))
                    GlobalScope.launch {
                        val text = getTextFromSound(base64String, language.code, countdown)
                        setCountdown(0)
                        if (text != null) {
                            setPhrase(text)
                        }
                        dispatch(EndModalLoading())
                    }
                }
            }
            reader.readAsDataURL(blob)
        }.catch {
            console.log("Error: $it")
        }
    }

    val phrasePosition = usePhraseCenteredPosition(phrase, taskType, style, phraseWidth)

    fun EPhraseHintAdditional.getState() = when(this) {
        EPhraseHintAdditional.EXPLANATION -> explanation
        EPhraseHintAdditional.PHRASE_SOUND -> phraseSound
        EPhraseHintAdditional.PIECES_SOUND -> piecesSound
    }

    fun EPhraseHintAdditional.setState(value: Boolean) = when(this) {
        EPhraseHintAdditional.EXPLANATION -> setExplanation(value)
        EPhraseHintAdditional.PHRASE_SOUND -> setPhraseSound(value)
        EPhraseHintAdditional.PIECES_SOUND -> setPiecesSound(value)
    }

    fun resetStates() {
        setPhrase(""); setTaskType(EPhraseType.WORD_EXPLORER); setExplanation(true)
        setPiecesSound(false); setPhraseSound(false)
        setStyle(PhraseStyle())
        setIsSelectLanguageOpen(false)
        countdownId?.let { clearInterval(it) }
        setPhraseWidth(canvasWidth - 2 * x)
    }

    Tooltip {
        title = "Study phrases for sentence-building tasks or explanations"
        placement = "topLeft"
        Button {
            css(button)
            div {
                css(buttonText)
                +"Phrase"
            }
            Icon {
                src = "ic_phrase_24x24.svg"
            }
            onClick = {
                setModalIsOpen(true)
            }
        }
    }
    Modal {
        zIndex = 100
        width = 600
        open = modalIsOpen
        onCancel = {
            resetStates()
            setModalIsOpen(false)
        }
        onOk = f@{
            var ids: Array<String>? = null
            when(taskType) {
                EPhraseType.WORD_EXPLORER -> {
                    ids = phrase.splitToPhraseWords().indices.map { getTimeMillisInBase() + it }.toTypedArray()
                    dispatch(AddStaticPhrase(ids, phrase, phrasePosition, style, piecesSound))
                }
                else -> dispatch(AddSentenceBuilderPhrase(phrase, phrasePosition, style))
            }
            setModalIsOpen(false)
            if (interactiveGroupId == null) {
                resetStates()
                return@f
            }
            if (phraseSound) {
                dispatch(StartModalLoading("Load sound, please wait"))
                GlobalScope.launch {
                    val sound = getSoundFromDictionaryOrGoogleCloud(
                        phrase,
                        interactiveGroupId,
                        fromDictionary = true,
                        fromGoogleTextToSpeech = true,
                        language.code
                    )

                    if (sound == null) {
                        resetStates()
                        dispatch(EndModalLoading())
                        return@launch
                    }

                    val id = getTimeMillisInBase()

                    dispatch(AddSound(id, phrasePosition.copy(y = phrasePosition.y - 60, width = 44, height = 44), sound.soundDescription, sound.soundDescription))
                }
            }

            if (piecesSound && ids != null) {
                dispatch(StartModalLoading("Load sound, please wait"))
                GlobalScope.launch {
                    val sounds = getSoundsFromDictionaryOrGoogleCloud(
                        phrase.splitToPhraseWords().map { it.word }.toTypedArray(),
                        interactiveGroupId,
                        language.code
                    )
                    if (sounds == null) {
                        resetStates()
                        dispatch(EndModalLoading())
                        return@launch
                    }

                    resetStates()
                    dispatch(SetSounds(ids, sounds))
                    dispatch(EndModalLoading())
                }
                return@f
            }

            dispatch(EndModalLoading())
            resetStates()
        }
        okText = "Generate phrase"
        okButtonProps = jsObject {
            disabled = phrase.isBlank()
        }
        centered = true
        div {
            css(modalBody)
            div {
                css(headerContainer)
                div {
                    div {
                        css(header)
                        +"Phrase"
                    }
                    div {
                        css(subheader)
                        +"Input a phrase and break it into pieces"
                    }
                }
            }
            div {
                css(textareaContainer)
                TextArea {
                    css(textarea)
                    value = phrase
                    onChange = {
                        setPhrase(it.target.value)
                    }
                    placeholder = "Input your text"
                }
                div {
                    css(textareaButtons)
                    div {
                        css(row)
                        div {
                            css(space)
                        }
                        if (phrase.isNotBlank()) {
                            Button {
                                css(getTextareaButton())
                                Icon {
                                    src = "ic_delete_sound_20x20.svg"
                                }
                                onClick = {
                                    setPhrase("")
                                }
                            }
                        }
                    }
                    div {
                        css(row)
                        Button {
                            css(getTextareaButton(width = 48))
                            Icon {
                                if (isSelectLanguageOpen) {
                                    css(activeFilter)
                                }
                                src = "ic_globe_32x32.svg"
                            }
                            Icon {
                                if (isSelectLanguageOpen) {
                                    css(activeFilter.extend(arrow))
                                } else {
                                    css(arrow)
                                }

                                src = if (isSelectLanguageOpen) "ic_arrow_up_24x24.svg" else "ic_arrow_down_24x24.svg"
                            }
                            onClick = {
                                setIsSelectLanguageOpen { !it }
                            }
                        }
                        Button {
                            css(getTextareaButton(recorder.status == "recording"))
                            Icon {
                                if (recorder.status == "recording") {
                                    css(whiteFilter)
                                }
                                src = "ic_micro_32x32.svg"
                            }
                            onClick = {
                                if (recorder.status == "recording") {
                                    recorder.stopRecording()
                                    countdownId?.let { clearInterval(countdownId); setCountdownId(null) }
                                } else {
                                    recorder.startRecording()
                                    val id = setInterval( { setCountdown { it + 1 } } , 1000)
                                    setCountdownId(id)
                                }
                            }
                        }
                    }
                }
                if (isSelectLanguageOpen) {
                    Select {
                        css(languageSelect)
                        value = language.toString()
                        showSearch = true
                        onSelect = { value, _ ->
                            value.getLanguageByText()?.let { setLanguage(it) }
                            setIsSelectLanguageOpen(false)
                        }
                        ELanguage.values().forEach {
                            Option {
                                value = it.text
                            }
                        }
                    }
                }
            }
            div {
                css(horizontalContainer)
                div {
                    css(verticalContainer)
                    div {
                        div {
                            css(paramsGroupHeader)
                            +"Task type"
                        }
                        div {
                            css(radioGridContainer)
                            EPhraseType.values().map { phraseType ->
                                Radio {
                                    css(radioLabel)
                                    value = phraseType.toString()
                                    current = taskType.toString()
                                    label = phraseType.label
                                    onChange = {
                                        setTaskType(EPhraseType.valueOf(it))
                                    }
                                }
                                Tooltip {
                                    title = phraseType.tooltip
                                    placement = "topLeft"
                                    ReactHTML.button {
                                        css(tooltipButton)
                                        Icon {
                                            src = "ic_tooltip_18x18.svg"
                                        }
                                    }
                                }
                            }
                        }
                    }
                    div {
                        div {
                            css(paramsGroupHeader)
                            +"Elements addition"
                        }
                        div {
                            css(checkboxesContainer)
                            EPhraseHintAdditional.values().map { hintAdditional ->
                                Checkbox {
                                    css(checkboxLabel)
                                    checked = hintAdditional.getState()
                                    onChange = hintAdditional::setState
                                    label = hintAdditional.label
                                }
                            }
                        }
                    }
                }
                div {
                    css(verticalContainer)
                    div {
                        div {
                            css(paramsGroupHeader)
                            +"Styles"
                        }
                        div {
                            css(stylesWrapper)
                            div {
                                css(stylesContainer)
                                class StyleParam(val label: String, val value: Int, val setState: (Int) -> Unit)
                                arrayOf(
                                    StyleParam("Line spacing", style.lineSpacing) { setStyle(style.copy(lineSpacing = it)) },
                                    StyleParam("Element padding", style.elementPadding) { setStyle(style.copy(elementPadding = it)) },
                                    StyleParam("Element spacing", style.elementSpacing) { setStyle(style.copy(elementSpacing = it)) },
                                    StyleParam("Element height", style.elementHeight) { setStyle(style.copy(elementHeight = it)) },
                                    StyleParam("Line element gap", style.lineElementGap) { setStyle(style.copy(lineElementGap = it)) },
                                    StyleParam("Line height", style.lineHeight) { setStyle(style.copy(lineHeight = it)) },
                                    StyleParam("Line spacing for unplaced puzzles", style.lineSpacingForUnplacedPuzzles) { setStyle(style.copy(lineSpacingForUnplacedPuzzles = it))},
                                    StyleParam("Font size", style.fontSize) { setStyle(style.copy(fontSize = it))},
                                    StyleParam("Phrase width", phraseWidth) { setPhraseWidth(it)}
                                ).map { styleParam ->
                                    div {
                                        css(styleParamContainerHorizontal)
                                        div {
                                            css(radioLabel)
                                            +styleParam.label
                                        }
                                        TypedInputNumber {
                                            css(inputNumber)
                                            min = 0
                                            controls = false
                                            precision = 0
                                            value = styleParam.value
                                            onChange = {
                                                styleParam.setState(it.toInt())
                                            }
                                        }
                                    }
                                }
                                div {
                                    css(styleParamContainerVertical)
                                    div {
                                        css(radioLabel)
                                        +"Elements align"
                                    }
                                    Select {
                                        css(select)
                                        value = style.elementsAlign
                                        showArrow = true
                                        onChange = { value, _ ->
                                            setStyle(style.copy(elementsAlign = value))
                                        }
                                        options = EPhraseElementsAlign.values().map {
                                            jsObject {
                                                this.label = it.label
                                                this.value = it.value
                                            }
                                        }.toTypedArray()
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
