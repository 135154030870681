package widgets.LanguageAutoTask.ui.components.AddExistingSlidesButton

import Modal
import antd.Button
import app.useAppDispatch
import csstype.px
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.EAccessType
import react.FC
import react.Props
import react.useState
import shared.components.Icon
import widgets.Exercises.ui.EFilterParams
import widgets.Exercises.ui.Exercises
import widgets.LanguageAutoTask.getTaskRequest
import widgets.LanguageAutoTask.ui.genMoreSlidesButton

external interface AddExistingSlidesButtonProps : Props {
    var onSave: (String) -> Unit
}

val AddExistingSlidesButton = FC<AddExistingSlidesButtonProps> { props ->
    val (modalIsOpened, setModalIsOpened) = useState(false)
    val dispatch = useAppDispatch()

    val (parentFolderId, setParentFolderId) = useState<Int?>(null)

    Button {
        css(genMoreSlidesButton)
        ghost = true
        Icon {
            src = "ic_plus_blue_32x32.svg"
        }
        +"Add existing slides"
        onClick = onClick@{
            setModalIsOpened(true)
        }
    }

    Modal {
        open = modalIsOpened
        onCancel = {
            setModalIsOpened(false)
        }
        width = 800.px
        footer = null
        closable = false

        Exercises {
            accessType = EAccessType.PRIVATE
            this.parentFolderId = parentFolderId
            onFolderClick = {
                goToFolder?.invoke(it.id)
            }
            goToFolder = {
                setParentFolderId(it)
            }
            onExerciseClick = {
                setModalIsOpened(false)
                dispatch(StartModalLoading("Getting exercise..."))
                GlobalScope.launch {
                    val response = getTaskRequest(it.requestId)
                    if (response.data == null) {
                        dispatch(EndModalLoading())
                        dispatch(OpenErrorModal("Error getting exercise"))
                        return@launch
                    }
                    props.onSave(response.data)
                }
            }
            hideMenu = true
            hideStatistics = true
            hideAddFolder = true
            hideAddExercise = true
        }
    }
}