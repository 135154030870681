package widgets.LanguageAutoTask.ui.components.SlidesDragAndDrop

import antd.Button
import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.key
import reactgriddnd.GridContextProvider
import reactgriddnd.GridDropZone
import reactgriddnd.GridItem
import reactgriddnd.swap
import shared.components.Icon
import shared.components.header.Header
import utils.types.impl
import widgets.LanguageAutoTask.ui.Slide

external interface SlidesDragAndDropProps : Props {
    var slides: Array<Slide>
    var onChange: (Array<Slide>) -> Unit
    var onEditSlideClick: (Int) -> Unit
}

val SlidesDragAndDrop = FC<SlidesDragAndDropProps> { props ->
    if (props.slides.isEmpty()) {
        return@FC
    }
    div {
        css {
            height = 520.px
            overflowY = Auto.auto
            overflowX = Overflow.hidden
        }
        GridContextProvider {
            onChange = { sourceId, sourceIndex, targetIndex, targetId ->
                val newSlides = swap(props.slides, sourceIndex, targetIndex)
                props.onChange(newSlides)
            }
            val gridRowHeight = 260
            val boxesPerRowInGrid = 4

            GridDropZone {
                id = "slides"
                boxesPerRow = boxesPerRowInGrid
                rowHeight = gridRowHeight

                val numberOfRows = kotlin.math.max(2, ((props.slides.size - 1) / boxesPerRowInGrid + 1))
                style = impl {
                    this?.height = (numberOfRows * gridRowHeight).px
                }

                props.slides.forEachIndexed { index, slide ->
                    GridItem {
                        key = slide.id.toString()
                        css {
                            cursor = Cursor.grab
                        }
                        div {
                            css(slideCss)
                            div {
                                css(slideContainer)
                                div {
                                    css(slideImage(slide.image))
                                }
                                div {
                                    css(settingContainer)
                                    Header {
                                        text = "Slide ${index + 1}"
                                        fontSize = 14.0.px
                                        lineHeight = 18.9.px
                                        fontWeight = integer(600)
                                    }
                                    div {
                                        css(buttonsContainer)
                                        Button {
                                            css(settingButton)
                                            Icon {
                                                src = "ic_edit_24x24.svg"
                                            }
                                            onClick = { props.onEditSlideClick(index) }
                                        }
                                        Button {
                                            css(settingButton)
                                            Icon {
                                                src = "ic_copy_with_bg_24x24.svg"
                                            }
                                            onClick = {
                                                val slides = props.slides.map { it }.toMutableList()
                                                slides.add(index + 1, slide.copy(id = slides.maxOf { it.id } + 1))
                                                props.onChange(slides.toTypedArray())
                                            }
                                        }
                                        Button {
                                            css(settingButton)
                                            Icon {
                                                src = "ic_delete_with_bg_24x24.svg"
                                            }
                                            onClick = {
                                                val slides = props.slides.map { it }.toMutableList()
                                                slides.removeAt(index)
                                                props.onChange(slides.toTypedArray())
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
