package app.middlewares

import app.StoreState
import kotlinext.js.js
import redux.applyMiddleware
import redux.RAction
import redux.WrapperAction

interface RThunk : RAction {
    operator fun invoke(
        dispatch: (RAction) -> WrapperAction,
        getState: () -> StoreState
    ): WrapperAction
}

// Credit https://github.com/AltmanEA/KotlinExamples
fun rThunk() =
    applyMiddleware<StoreState, RAction, WrapperAction, RAction, WrapperAction>(
        { store ->
            { next ->
                { action ->
                    if (action is RThunk)
                        // if action is inherited from RThunk then call invoke with these parameters
                        action(store::dispatch, store::getState)
                    else
                        // else pass action to next middleware (e.g. logger)
                        next(action)
                }
            }
        }
    )

val nullAction = js {}.unsafeCast<WrapperAction>()