package builders.enums

import pages.constructor.ui.components.advancedElementsSettings.advancedButtonSettings.AdvancedButtonSettings
import pages.constructor.ui.components.advancedElementsSettings.advancedPuzzleSettings.AdvancedPuzzleSettings
import pages.constructor.ui.components.advancedElementsSettings.advancedInputSettings.AdvancedInputSettings
import pages.constructor.ui.components.advancedElementsSettings.advancedSelectorSettings.AdvancedSelectorSettings
import pages.constructor.ui.components.advancedElementsSettings.advancedStaticSettings.AdvancedStaticSettings
import react.FC
import react.Props
import shared.canvas.KonvaElementProps
import shared.canvas.implementations.*

enum class EElementType(
    val text: String,
    val shortName: String,
    val usesTextEditor: Boolean,
    val usesHintEditor: Boolean,
    val shownAllTime: Boolean,
    val usesSoundEditor: Boolean,
    val usesSoundBadge: Boolean,
    val drawable: FC<KonvaElementProps>,
    val textToDefineIdentifier: EElementMainTextTransliterated = EElementMainTextTransliterated.VISIBLE_ELEMENT_TEXT,
    val advancedSettingsFC: FC<Props>? = null,
) {
    // Static
    AREA_FRAME("AreaFrame", "Area", false, true, false, false, false,
        TransformableRect, EElementMainTextTransliterated.HINT_TEXT, AdvancedStaticSettings),
    RECT_FRAME("RectFrame", "RectFrame",false, true, false, false, false,
        TransformableRect, EElementMainTextTransliterated.HINT_TEXT, AdvancedStaticSettings),
    TEXT_STATIC("TextStatic", "Rect", true, true, false, true, true,
        TransformableRect, EElementMainTextTransliterated.VISIBLE_ELEMENT_TEXT, AdvancedStaticSettings),
    MARKER_STATIC("MarkerStatic", "Marker", true, true, false, false, false,
        TransformableCircle, EElementMainTextTransliterated.VISIBLE_ELEMENT_TEXT, AdvancedStaticSettings),
    ARROW_STATIC("ArrowStatic", "Arrow", false, false, false, false, false,
        TransformableArrow, EElementMainTextTransliterated.VISIBLE_ELEMENT_TEXT, AdvancedStaticSettings),
    LINE_STATIC("LineStatic", "Line", false, false, false, false, false,
        TransformableArrow, EElementMainTextTransliterated.VISIBLE_ELEMENT_TEXT, AdvancedStaticSettings),
    IMAGE_STATIC("ImageStatic", "Image", false, true, false, false, true,
        TransformableImage, EElementMainTextTransliterated.HINT_TEXT, AdvancedStaticSettings),
    SOUND_STATIC("SoundStatic", "Sound", false, false, false, true, false,
        TransformableSound, EElementMainTextTransliterated.SOUND_FILENAME, AdvancedStaticSettings),

    // Drag
    BACKGROUND_DRAG("BackgroundDrag", "BgDrag", false, true, true, false, false,
        BackgroundCutComponent, EElementMainTextTransliterated.HINT_TEXT, AdvancedPuzzleSettings),
    TEXT_DRAG("TextDrag", "TextDrag", true, true, true, true, true,
        TransformableRect, EElementMainTextTransliterated.VISIBLE_ELEMENT_TEXT, AdvancedPuzzleSettings),
    IMAGE_DRAG("ImageDrag", "ImageDrag", false, true, true, false, false,
        TransformableImage, EElementMainTextTransliterated.HINT_TEXT, AdvancedPuzzleSettings),

    // Control
    INPUT_CONTROL("InputControl", "Input", true, true, true, true, true,
        TransformableRect, EElementMainTextTransliterated.INPUT_PLACEHOLDER, AdvancedInputSettings),
    BUTTON_CONTROL("ButtonControl", "Button", true, true, true, true, true,
        TransformableRect, EElementMainTextTransliterated.VISIBLE_ELEMENT_TEXT, AdvancedButtonSettings),
    SELECTOR_CONTROL("SelectorControl", "Selector", true, true, true, false, false,
        TransformableSelector, EElementMainTextTransliterated.VISIBLE_ELEMENT_TEXT, AdvancedSelectorSettings),

    //Other
    PHRASE_OTHER("PhraseOther", "Phrase", false, false, true, false, false,
        TransformableRect, EElementMainTextTransliterated.PHRASE_TEXT, AdvancedStaticSettings)
}
