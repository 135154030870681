package pages.duoSettings.ui

import antd.Button
import csstype.Auto
import csstype.Margin
import csstype.px
import emotion.react.css
import entities.solvedTasks.ui.SolvedTasks
import online.interactiver.common.enums.ELanguage
import pages.studentDuo.selectRequiredStudentDuoNativeLanguage
import react.FC
import react.VFC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.section
import react.redux.useSelector
import react.router.useNavigate
import shared.components.ProtectedRoute.ProtectedRoute
import utils.localization.Localization
import utils.types.extend
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton
import widgets.StudentDuoLayout.StudentDuoLayout

val DuoSettings = VFC {
    val navigate = useNavigate()
    val nativeLanguage = useSelector(selectRequiredStudentDuoNativeLanguage)

    ProtectedRoute {
        toStudentDuo = true

        StudentDuoLayout {
            buttons = FC {
                Button {
                    css(newTaskButton.extend {
                        margin = Margin(0.px, Auto.auto)
                    })
                    onClick = {
                        navigate(-1)
                    }
                    +Localization.getBackButtonText(nativeLanguage)
                }
            }
            div {
                css(buttonsContainer)
                Button {
                    css(newTaskButton)
                    onClick = {
                        navigate("/duo/profile-settings")
                    }
                    +getProfile(nativeLanguage)
                }
                Button {
                    css(newTaskButton)
                    onClick = {
                        navigate("/duo/exercises-settings")
                    }
                    +getExercisesSettings(nativeLanguage)
                }
                Button {
                    css(newTaskButton)
                    onClick = {
                        navigate("/duo/manage-notifications")
                    }
                    +getManageNotifications(nativeLanguage)
                }
            }
        }
    }
}

fun getProfile(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Профиль"
    } else {
        "Profile"
    }
}

fun getExercisesSettings(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Настройки заданий"
    } else {
        "Exercises Settings"
    }
}

fun getManageNotifications(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Настройки уведомлений"
    } else {
        "Manage notifications"
    }
}