package utils.structures

abstract class Segment {
    abstract fun intersectsWith(other: Segment): Boolean
}

class HorizontalSegment(y: Int, x1: Int, x2: Int) : Segment() {
    val y: Int
    val xLeft: Int
    val xRight: Int

    init {
        this.y = y
        this.xLeft = kotlin.math.min(x1, x2)
        this.xRight = kotlin.math.max(x1, x2)
    }

    override fun intersectsWith(other: Segment): Boolean {
        return when(other) {
            is HorizontalSegment ->
                y == other.y && (other.xLeft in xLeft .. xRight || other.xRight in xLeft .. xRight)
            is VerticalSegment ->
                other.x in xLeft .. xRight && y in other.yTop .. other.yBottom
            else -> false
        }
    }
}

class VerticalSegment(x: Int, y1: Int, y2: Int): Segment() {
    val x: Int
    val yBottom: Int
    val yTop: Int

    init {
        this.x = x
        this.yTop = kotlin.math.min(y1, y2)
        this.yBottom = kotlin.math.max(y1, y2)
    }

    override fun intersectsWith(other: Segment): Boolean {
        return when(other) {
            is HorizontalSegment -> other.intersectsWith(this)
            is VerticalSegment ->
                x == other.x && (other.yTop in yTop .. yBottom || other.yBottom in yTop .. yBottom)
            else -> false
        }
    }
}
