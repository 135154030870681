package pages.constructor.ui.components.advancedElementsSettings.advancedSelectorSettings

import Modal
import antd.Button
import antd.Select
import antd.TypedInputNumber
import app.useAppDispatch
import app.useAppSelector
import builders.enums.EElementType
import emotion.react.css
import entities.advancedElementSettings.CloseAdvancedElementSettings
import entities.advancedElementSettings.selectAdvancedElementSettingsModalState
import entities.interactivePicture.elements.SetElementCode
import online.interactiver.common.utils.clone
import entities.interactivePicture.elements.editors.ScoreEditor.scoreTitles
import entities.interactivePicture.elements.editors.elementCodeEditor.ElementCodeEditor
import entities.interactivePicture.elements.editors.elementCodeEditor.useFocusedElementCode
import entities.interactivePicture.elements.gapPuzzles.drags.SetOptions
import entities.interactivePicture.selectFocusedElement
import org.w3c.dom.HTMLElement
import react.*
import react.dom.html.ReactHTML.div
import shared.components.Icon
import utils.types.jsObject

val AdvancedSelectorSettings = FC<Props> {
    val modalState = useAppSelector(selectAdvancedElementSettingsModalState)
    val dispatch = useAppDispatch()

    val focusedElement = useAppSelector(selectFocusedElement)

    val focusedElementType = EElementType.values().find{ focusedElement?.visibleElement?.type == it.text }

    if (focusedElement == null || focusedElementType != EElementType.SELECTOR_CONTROL) {
        throw RuntimeException("Rendered advanced selector settings with no selector focused!")
    }

    val (options, setOptions) = useState(focusedElement.selector?.options?.clone())
    val (elementCode, setElementCode) = useFocusedElementCode()

    useEffect(focusedElement) {
        setOptions(focusedElement.selector?.options?.clone())
    }

    val addScoreToOption = useAddScoreToOption(options)
    val setScoreValue = useSetScoreValue(options)
    val setScoreName = useSetScoreName(options)
    val removeScore = useRemoveScore(options)

    val existingScoresNames = useAppSelector(scoreTitles)
    val (allScoresNames, setAllScoresNames) = useState(existingScoresNames)

    useEffect(options) {
        val newAllScores = existingScoresNames.toMutableSet()
        options?.forEach { newAllScores.addAll(it.scores?.map { it.name } ?: mutableListOf()) }
        newAllScores.addAll(focusedElement.scores?.map { it.name } ?: mutableListOf())
        setAllScoresNames(newAllScores.filter { !it.isBlank() }.toMutableSet())
    }

    val (okDisabled, setOkDisabled) = useState(false)

    useEffect(options) {
        val allScores = hashSetOf<String>()
        options?.forEach { allScores.addAll(it.scores?.map { it.name } ?: mutableListOf()) }
        setOkDisabled(allScores.any { it.isBlank() })
    }

    Modal {
        width = 800
        open = modalState.isOpen
        onCancel = {
            setOptions(focusedElement.selector?.options?.clone())
            setElementCode(focusedElement.identifier.code ?: "")
            dispatch(CloseAdvancedElementSettings())
        }
        onOk = {
            dispatch(SetOptions(focusedElement.identifier.id!!, options))
            dispatch(SetElementCode(focusedElement.identifier.id!!, elementCode))
            dispatch(CloseAdvancedElementSettings())
        }
        okText = "Save changes"
        okButtonProps = jsObject {
            disabled = okDisabled
        }
        div {
            css(header)
            +"Advanced element settings"
        }
        div {
            css(container)
            div {
                css(elementTypeHeader)
                +"Selector"
            }
            ElementCodeEditor {
                value = elementCode
                onChange = {
                    setElementCode(it)
                }
            }
            options?.mapIndexed { optionIndex, option ->
                div {
                    css(optionContainer)
                    div {
                        css(optionSubHeader)
                        +"Option \"${option.text?.simpleText}\""
                    }
                    option.scores?.mapIndexed { scoreIndex, score ->
                        div {
                            css(inputsContainer)
                            Select {
                                css(nameSelector)
                                this.showArrow = false
                                this.showSearch = true

                                this.placeholder = "Type your score"

                                this.options = allScoresNames.map {
                                    jsObject {
                                        this.value = it
                                        this.label = it
                                    }
                                }.toTypedArray()

                                this.value = if (score.name.isBlank()) null else score.name

                                this.onSelect = { value, _ ->
                                    setOptions(setScoreName(optionIndex, scoreIndex, value))
                                }
                                this.onBlur = onBlur@{
                                    val value = it.target.value
                                    if (value.isBlank()) {
                                        return@onBlur
                                    }
                                    setOptions(setScoreName(optionIndex, scoreIndex, value))
                                }
                                this.onKeyDown = onKeyDown@{
                                    if (it.key != "Enter") {
                                        return@onKeyDown
                                    }
                                    it.preventDefault()
                                    (it.target as? HTMLElement)?.blur()
                                }
                            }
                            TypedInputNumber {
                                css(valueInput)
                                controls = false
                                precision = 0
                                this.value = score.value
                                onChange = {
                                    setOptions(setScoreValue(optionIndex, scoreIndex, it.toInt()))
                                }
                            }
                            if (scoreIndex > 0) {
                                Button {
                                    css(deleteScoreButton)
                                    Icon {
                                        src = "ic_cross_24x24.svg"
                                    }
                                    onClick = {
                                        setOptions(removeScore(optionIndex, scoreIndex))
                                    }
                                }
                            } else {
                                div { css(fakeDeleteButtonContainer) }
                            }
                        }
                    }
                    Button {
                        css(addScoreButton)
                        +"+ Add score category"
                        onClick = {
                            setOptions(addScoreToOption(optionIndex))
                        }
                    }
                }
            }
        }
    }
}
