package pages.constructor.ui.components.modalVersionDiff

import csstype.*
import utils.types.CssStyle

val modalMaskStyle: CssStyle = {
    background = rgba(5, 10, 49, 0.45)
}

val modalBodyStyle: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    justifyContent = JustifyContent.center
    alignItems = AlignItems.center
    gap = 10.px
}

val modalTextStyle: CssStyle = {
    fontFamily = string("Arial")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 14.px
    lineHeight = 17.px

    textAlign = TextAlign.center

    color = rgb(73, 124, 255)
}

val modalFooterStyle: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
}