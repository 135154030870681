package widgets.CourseMaterials.ui.components.SentencesMaterials

import app.useAppDispatch
import enums.EMaterialType
import online.interactiver.common.course.getCourseMaterials
import react.FC
import react.Props
import react.redux.useSelector
import widgets.CourseMaterials.SetCourseSentencesToGenerateExercises
import widgets.CourseMaterials.selectSentencesToGenerateExercises
import widgets.CourseMaterials.ui.components.Materials.Materials

external interface SentencesMaterialsProps : Props {
    var withAudio: Boolean
}

val SentencesMaterials = FC<SentencesMaterialsProps> { props ->
    val rawSentencesToGenerateExercises = useSelector(selectSentencesToGenerateExercises)

    val dispatch = useAppDispatch()

    val sentencesToGenerateExercises = rawSentencesToGenerateExercises.getCourseMaterials()

    Materials {
        materials = sentencesToGenerateExercises
        onChange = {
            dispatch(SetCourseSentencesToGenerateExercises(it))
        }
        header = if (props.withAudio) "Audios sentences review" else "Sentences review"
        materialType = EMaterialType.SENTENCES
        withAudio = props.withAudio
    }
}
