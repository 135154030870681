package pages.scoreViewer.components.scoreProgressBar

import csstype.*
import utils.types.CssStyle

val labelStyle: CssStyle = {
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
}

val nameStyle: CssStyle = {
    color = Color("#7B848D")
    fontFamily = string("Montserrat")
    fontSize =  24.px
    fontStyle = FontStyle.normal
    fontWeight = FontWeight.normal
    lineHeight = 120.pct
}

val scoreStyle: CssStyle = {
    color = Color("#000")
    fontFamily = string("Montserrat")
    fontSize =  24.px
    fontStyle = FontStyle.normal
    fontWeight = integer(500)
    lineHeight = 120.pct
}