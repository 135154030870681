package online.interactiver.common.autogeneration

import online.interactiver.common.autogeneration.taskLineContent.RawTaskLineContent
import online.interactiver.common.autogeneration.taskLineContent.TaskLineContent
import online.interactiver.common.interactiveexercise.EExerciseType
import online.interactiver.common.interactiveexercise.EExerciseUiPattern
import online.interactiver.common.interactiveexercise.EExerciseUiPatternMetaTag
import online.interactiver.common.interactiveexercise.ExerciseUiPattern
import online.interactiver.common.interactivepicture.InteractivePicture

class TaskLine(rawTaskLine: String, isOpenAiFormat: Boolean = false, slide: InteractivePicture? = null) {
    var type: EExerciseType
    var pattern: ExerciseUiPattern?
    var content: TaskLineContent

    class Error(message: String) : Exception(message)

    init {
        val taskLineWithoutNum = if (rawTaskLine.firstOrNull()?.isDigit() == true) {
            rawTaskLine.substringAfter(".").trimStart()
        } else {
            rawTaskLine
        }
        val rawType = taskLineWithoutNum
            .substringBefore(".").trim()
            .removePrefix("\'")
            .removeSuffix("\'")
        type = EExerciseType.fromShortName(rawType) ?: throw Error("$rawType is unsupported exercise type in '$rawTaskLine'!")
        val contentWithMaybePattern = taskLineWithoutNum.substringAfter(".").trimStart()
        val hasPattern = contentWithMaybePattern.contains("\'.")
        val rawPattern = if (hasPattern) {
            contentWithMaybePattern
                .substringBefore(".").trim()
                .removePrefix("\'")
                .removeSuffix("\'")
        } else null
        val rawContent = if (hasPattern) {
            contentWithMaybePattern.substringAfter(".").trimStart()
        } else {
            contentWithMaybePattern
        }
        pattern = if (rawPattern != null) {
            try {
                ExerciseUiPattern.fromRawExerciseUiPattern(rawPattern)
            } catch(e: Exception) {
                throw Error("$rawPattern is unsupported pattern type in '$rawTaskLine'!")
            }
        } else null

        if (pattern?.type?.isSuitableFor(type) == false) {
            throw Error("$rawPattern is not suitable for $rawType exercise in '$rawTaskLine'!")
        }
        if (pattern?.type?.canBeBuiltFrom(rawContent, isOpenAiFormat, null) == false) {
            throw Error("Cannot build $rawPattern from content \"$rawContent\" in '$rawTaskLine'")
        }

        content = pattern?.type?.taskLineContentFabric?.fromRawTaskLine(rawContent, isOpenAiFormat) ?: RawTaskLineContent(rawContent)

        slide?.let {
            content = pattern?.type?.injectSoundFromSlideToContent(content, it) ?: RawTaskLineContent(rawContent)
        }
    }

    fun toRawTaskLine() = if (pattern != null) "\'${type.shortName}\'. \'${pattern!!.toRawExerciseUiPattern()}\'. ${content.toRawTaskContent()}"
        else "\'${type.shortName}\'. ${content.toRawTaskContent()}"
}
