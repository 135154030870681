package entities.dictionary.ui.phrase

import csstype.*
import utils.types.CssStyle

val phraseHeader: CssStyle = {
    fontFamily = string("\"Arial\", system-ui")
    fontSize = 14.px
    fontWeight = FontWeight.bold
    lineHeight = 18.2.px
    color = Color("#282F3E")
    marginLeft = 10.px
}

val phraseStyle: CssStyle = {
    fontFamily = string("\"Arial\", system-ui")
    fontSize = 12.px
    fontWeight = FontWeight.normal
    lineHeight = 15.6.px
    color = Color("#5D6676")
    marginBottom = 3.px
}

val phraseLabel: CssStyle = {
    fontFamily = string("\"Arial\", system-ui")
    border = Border(1.px, LineStyle.solid, Color("#D0D5DD"))
    borderRadius = 5.px
    padding = Padding(5.px, 10.px, 5.px, 10.px)
    width = 141.px
    height = 38.px
    whiteSpace = WhiteSpace.pre
    overflow = Auto.auto
}

val phraseContainer: CssStyle = {
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
    marginTop = 15.px
}

val radioContainer: CssStyle = {
    display = Display.flex
}

val container: CssStyle = {
    marginTop = 20.px
}

val audioContainer: CssStyle = {
    height = 48.px
    width = 150.px
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
}

val audioStyle: CssStyle = {
    width = 100.pct
    height = 35.px
}
