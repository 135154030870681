package entities.interactivePicture.elements.editors.canvasSizeEditor

import antd.Button
import app.useAppDispatch
import app.useAppSelector
import emotion.react.css
import entities.backgroundPosScale.selectCanvasContainerHeight
import entities.backgroundPosScale.selectCanvasContainerWidth
import entities.interactivePicture.background.*
import enums.EButtonTypes
import react.FC
import react.Props
import react.dom.html.ReactHTML
import utils.structures.Point
import kotlin.math.min

external interface CanvasSizeEditorProps : Props {
    var canvasSizes: List<Pair<Point, EButtonTypes>>
}

val CanvasSizeEditor = FC<CanvasSizeEditorProps> {props ->
    val backgroundBase64 = useAppSelector(selectBackgroundBase64)
    val canvasWidth = useAppSelector(selectCanvasWidth)
    val canvasHeight = useAppSelector(selectCanvasHeight)
    val canvasContainerWidth = useAppSelector(selectCanvasContainerWidth)
    val canvasContainerHeight = useAppSelector(selectCanvasContainerHeight)
    val canvasMaxWidth = useAppSelector(selectCanvasMaxWidth)
    val dispatch = useAppDispatch()
    val disabled = backgroundBase64 != null

    fun isCurrentRatio(ratio: Point): Boolean {
        return canvasWidth != null && canvasHeight != null && canvasWidth / ratio.x == canvasHeight / ratio.y
    }

    ReactHTML.div {
        css(setupContainer)
        ReactHTML.div {
            css(container)
            props.canvasSizes.forEach {
                val point = it.first
                val buttonType = it.second
                Button {
                    if (isCurrentRatio(point)) css(activeSetup) else css(setup)
                    id = buttonType.value
                    this.disabled = disabled
                    onClick = {
                        dispatch(EditBackgroundWithRatio(min(canvasMaxWidth ?: canvasContainerWidth, canvasContainerWidth), canvasContainerHeight, point))
                    }
                    +"${point.x}:${point.y}"
                }
            }
        }
    }
}