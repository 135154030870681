package builders.enums

enum class EInteractiveType(
    val text: String, val group: EElementGroup, val filterButtonText: String,
    val noStrokeAllowed: Boolean = true, val noBorderForLines: Boolean = true, val shouldDrawScore: Boolean = false
) {
    FRAME_INTERACTIVE("Frame", EElementGroup.STATIC, "Frames"),
    FIGURE_INTERACTIVE("Figure", EElementGroup.STATIC, "Figures"),
    CONNECTION_INTERACTIVE("Connection", EElementGroup.STATIC, "Lines", false, false),
    BUTTON_INTERACTIVE("Button", EElementGroup.CONTROLS, "Buttons", shouldDrawScore = true),
    INPUT_INTERACTIVE("Input", EElementGroup.CONTROLS, "Inputs", shouldDrawScore = true),
    SELECTOR_INTERACTIVE("Selector", EElementGroup.CONTROLS, "Selector", shouldDrawScore = true),
    GAP_PUZZLE_INTERACTIVE("Puzzle", EElementGroup.PUZZLE, "Puzzles", shouldDrawScore = true),
    PHRASE_INTERACTIVE("Phrase", EElementGroup.OTHER, "Phrases", shouldDrawScore = true),

    OPTION_FRAME_INTERACTIVE("OptionFrame", EElementGroup.FOCUSED_OPTION_STATIC, "Frames"),
    OPTION_FIGURE_INTERACTIVE("OptionFigure", EElementGroup.FOCUSED_OPTION_STATIC, "Figures"),
    OPTION_CONNECTION_INTERACTIVE("OptionConnection", EElementGroup.FOCUSED_OPTION_STATIC, "Lines", false, false),
}