package widgets.AdminPanel.ui.components.CreateFolderButton

import Modal
import antd.Input
import app.useAppDispatch
import emotion.react.css
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useState
import widgets.AdminPanel.ui.components.CreateNewButton.CreateNewButton
import widgets.Exercises.createNewFolder

external interface CreateFolderButtonProps : Props {
    var parentFolderId: Int?
    var onFolderCreated: ((Int) -> Unit)?
}

val CreateFolderButton = FC<CreateFolderButtonProps> { props ->
    val dispatch = useAppDispatch()

    val (modalIsOpened, setModalIsOpened) = useState(false)
    val (folderName, setFolderName) = useState("")

    val placeholder = "My folder"

    CreateNewButton {
        onClick = {
            setModalIsOpened(true)
        }
        label = "Folder"
        bgColor = "#5D6676"
        bgColorHovered = "#4C5360"
    }

    Modal {
        open = modalIsOpened
        onCancel = {
            setModalIsOpened(false)
        }
        onOk = {
            setModalIsOpened(false)
            dispatch(StartModalLoading("Creating '${folderName.ifBlank { placeholder }}' folder..."))
            MainScope().launch {
                val response = createNewFolder(
                    props.parentFolderId,
                    folderName.ifBlank { placeholder }
                )
                dispatch(EndModalLoading())

                if (response.data == null) {
                    // we do not reset folder name if failed so user can try again without re-entering a name again
                    console.error("Failed to create '${folderName.ifBlank { placeholder }}' folder with ${response.code} status!")
                } else {
                    setFolderName("")
                    props.onFolderCreated?.invoke(response.data)
                }
            }
        }
        div {
            css(modalBody)
            div {
                css(modalHeader)
                +"Enter a folder name"
            }
            Input {
                value = folderName
                this.placeholder = placeholder
                onChange = {
                    setFolderName(it.target.value)
                }
            }
        }
    }
}
