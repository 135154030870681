package builders.enums

import online.interactiver.common.enums.ELanguage
import online.interactiver.common.interactivepicture.TaskType

// Task types available in constructor
enum class ETaskType(val language: ELanguage, val platform: EPlatform) {
    ENGLISH_DESKTOP(ELanguage.ENGLISH, EPlatform.DESKTOP),
    ENGLISH_MOBILE(ELanguage.ENGLISH, EPlatform.MOBILE),
    HEBREW_MOBILE(ELanguage.HEBREW, EPlatform.MOBILE);

    fun toTaskType(): TaskType {
        return TaskType(language.text, platform.text)
    }
}
