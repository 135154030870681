package pages.onboarding.learningLanguageChoice

import antd.Button
import app.useAppDispatch
import emotion.react.css
import entities.solvedTasks.ui.SolvedTasks
import kotlinx.browser.localStorage
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.w3c.dom.get
import pages.onboarding.nativeLanguageChoice.updateUserLanguagesRequest
import pages.studentDuo.SetStudentDuoLanguageToLearn
import pages.studentDuo.rescheduleNotificationsRequest
import pages.studentDuo.selectStudentDuoNativeLanguage
import pages.studentDuo.selectStudentDuoState
import react.VFC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.section
import react.redux.useSelector
import react.router.useNavigate
import react.useEffectOnce
import utils.localization.Localization
import widgets.LanguageAutoContent.ui.components.SelectLanguage.SelectLanguage
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton
import widgets.StudentDuoContent.ui.EStudentDuoSearchParams

val LearningLanguageChoice = VFC {
    val nativeLanguage = useSelector(selectStudentDuoNativeLanguage)
    val navigate = useNavigate()
    val dispatch = useAppDispatch()
    val languageToLearn = useSelector(selectStudentDuoState).languageToLearn

    useEffectOnce {
        if (nativeLanguage == null) {
            navigate("/duo/content/native-language")
            return@useEffectOnce
        }

        localStorage["duo_${EStudentDuoSearchParams.LANGUAGE_TO_LEARN.value}"]?.let {
            dispatch(SetStudentDuoLanguageToLearn(it))
        }
    }

    if (nativeLanguage == null) {
        return@VFC
    }

    section {
        css(page)
        SolvedTasks {}
        div {
            css(container)
            SelectLanguage {
                label = Localization.getLanguageToLearnTitle(nativeLanguage)
                value = languageToLearn
                onChange = {
                    dispatch(SetStudentDuoLanguageToLearn(it))
                    localStorage.setItem("duo_${EStudentDuoSearchParams.LANGUAGE_TO_LEARN.value}", it)
                }
                excludedLanguageValue = nativeLanguage
            }
            Button {
                css(generateButton)
                onClick = {
                    GlobalScope.launch {
                        rescheduleNotificationsRequest()
                        updateUserLanguagesRequest(nativeLanguage, languageToLearn)
                    }
                    navigate("/duo/content/language-level")
                }
                +Localization.getContinueButtonText(nativeLanguage)
            }
            Button {
                css(newTaskButton)
                onClick = {
                    navigate(-1)
                }
                +Localization.getBackButtonText(nativeLanguage)
            }
        }
    }
}
