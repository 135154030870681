package widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.BuildSentenceTaskLineEditor

import csstype.TextAlign
import csstype.integer
import csstype.px
import emotion.react.css
import online.interactiver.common.autogeneration.TaskLine
import online.interactiver.common.autogeneration.taskLineContent.BuildSentenceContent
import online.interactiver.common.autogeneration.taskLineContent.patternedPhraseFromUserUI
import online.interactiver.common.interactiveexercise.ExerciseUiPattern
import react.*
import react.dom.html.ReactHTML.div
import shared.components.inputLabelledLimited.InputLabelledLimited
import shared.components.inputLabelledLimited.InputType
import utils.types.jsObject
import widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.TaskLineUiEditorWithSpecifiedPatternProps

val BuildSentenceTaskLineEditor = FC<TaskLineUiEditorWithSpecifiedPatternProps> { props ->
    val taskLine = useMemo(props.taskLine, props.slide, callback = {
        try {
            TaskLine(props.taskLine, slide = props.slide)
        } catch(e: TaskLine.Error) {
            null
        }
    })

    val content = useMemo(taskLine, callback = {
        taskLine?.content as? BuildSentenceContent
    })

    val pattern = useMemo(taskLine) {
        taskLine?.pattern
    }

    if (taskLine == null || content == null || pattern == null) {
        return@FC
    }

    InputLabelledLimited {
        css(textarea)
        placeholder = "Sentence"
        label = "Sentence"
        value = content.getPatternedPhraseToUserUI()
        onChange = {
            val newContent = BuildSentenceContent(patternedPhraseFromUserUI(it), content.hint)

            taskLine.content = newContent
            props.onChange?.invoke(taskLine.toRawTaskLine())
        }
        limit = if (content.hasHint()) props.maxPhraseLengthWithTranslation else props.maxPhraseLengthWithoutTranslation
        inputType = InputType.TEXTAREA

        tooltip = createElement(FC {
            div {
                css {
                    textAlign = TextAlign.justify
                    marginBottom = 12.px
                }
                +"Improve the sentence by changing words in [brackets] or adding [one/two/three] extra words. Options in [brackets] will be mixed up, and the right choice is marked with asterisk*."
            }
            div {
                css {
                    fontWeight = integer(700)
                }
                +"Example:"
            }
            div {
                css {
                    textAlign = TextAlign.justify
                }
                +"I like to [eat*/walk/enjoy] ice cream."
            }
        })
        tooltipOverlayStyle = jsObject { maxWidth = "500px" }
        soundSrc = content.soundSrc
        onEditSoundClick = {
            props.onEditSoundClick?.invoke(content.getCorrectPhrase())
        }

        patternMetaTags = pattern.metaTags
        onPatternMetaTagsChange = {
            val newPattern = ExerciseUiPattern(pattern.type, it)
            taskLine.pattern = newPattern
            props.onPatternChange?.invoke(taskLine.toRawTaskLine())
        }
    }

    InputLabelledLimited {
        css(textarea)
        placeholder = "Hint or Translation"
        label = "Hint or Translation"
        value = content.hint
        onChange = {
            val newContent = BuildSentenceContent(content.patternedPhrase, it)

            taskLine.content = newContent
            props.onChange?.invoke(taskLine.toRawTaskLine())
        }
        limit = props.maxTranslationLength
        inputType = InputType.TEXTAREA
    }
}
