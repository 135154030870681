package widgets.ExerciseSettings.components.SettingsContainer

import csstype.*
import utils.types.CssStyle

val settingsContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
    borderRadius = 12.px
    border = Border(1.px, LineStyle.solid, Color("#F2F4F7"))
    padding = 20.px
}