package pages.constructor.ui.components.header.styleSettings.opacitySlider

import csstype.*
import pages.constructor.ui.components.header.styleSettings.secondary
import utils.inject
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    justifyContent = JustifyContent.center
    width = 194.px
}

val headerContainer: CssStyle = {
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.flexStart
    alignSelf = AlignSelf.stretch
}

val header: CssStyle = {
    inject(secondary)
    marginTop = 2.px
}

val inputNumber: CssStyle = {
    inject(secondary)
    width = 60.px
    padding = Padding(0.px, 2.px)
}
