package pages.constructor.ui.components.saver

import csstype.*
import utils.types.CssStyle

val containerStyle: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    gap = 12.px
}

val textStyle: CssStyle = {
    display = Display.inlineBlock
    width = 9.ch
    fontFamily = string("\"Arial\"")
    fontWeight = integer(400)
    fontSize = 14.px
    lineHeight = 18.2.px
    color = Color("#FFFFFF")
}