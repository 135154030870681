package widgets.ExerciseSettings

import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.serialization.json.Json
import online.interactiver.common.user.SaveUserSliderSettingsRequest
import online.interactiver.common.user.UserSliderSettings
import utils.getToken

suspend fun saveUserSliderSettingsRequest(
    translateRequestToTargetLanguage: Boolean? = null,
    numberOfSlidesToGenerate: Int? = null
) {
    jsonClient.put("api/v1/user-account/slider-settings") {
        bearerAuth(getToken())
        contentType(ContentType.Application.Json)
        setBody(SaveUserSliderSettingsRequest(
            translateRequestToTargetLanguage, numberOfSlidesToGenerate
        ))
    }
}

suspend fun updateLogoRequest(logo: String?, filename: String): UserSliderSettings? {
    return try {
        val response = jsonClient.put("api/v1/user-account/slider-settings/update-logo") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(FormDataContent(Parameters.build {
                logo?.let { append("logo", it) }
                append("filename", filename)
            }))
        }

        if (response.status.value != 200) {
            return null
        }

        Json.decodeFromString(response.bodyAsText())
    } catch (_: ResponseException) {
        null
    }
}

suspend fun updateLinkInLogoRequest(linkInLogo: String?): UserSliderSettings? {
    return try {
        val response = jsonClient.put("api/v1/user-account/slider-settings/update-link-in-logo") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(FormDataContent(Parameters.build {
                linkInLogo?.let { append("link_in_logo", it) }
            }))
        }

        if (response.status.value != 200) {
            return null
        }

        Json.decodeFromString(response.bodyAsText())
    } catch (_: ResponseException) {
        null
    }
}

suspend fun updateLogoUseTypeRequest(logoUseType: String): UserSliderSettings? {
    return try {
        val response = jsonClient.put("api/v1/user-account/slider-settings/update-logo-use-type") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(FormDataContent(Parameters.build {
                append("logo_use_type", logoUseType)
            }))
        }

        if (response.status.value != 200) {
            return null
        }

        Json.decodeFromString(response.bodyAsText())
    } catch (_: ResponseException) {
        null
    }
}
