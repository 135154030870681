package pages.scoreViewer

import emotion.react.css
import getAttemptScores
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import org.w3c.dom.url.URLSearchParams
import pages.scoreViewer.components.scoreProgressBar.ScoreProgressBar
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.h2
import shared.components.Icon
import online.interactiver.common.api.AttemptLogScores
import org.w3c.dom.url.URL
import react.*

enum class SortType(val sorter: (Array<AttemptLogScores>) -> Array<AttemptLogScores>, val iconSrc: String) {
    NO_SORT({ it }, "ic_unsorted_41x36.svg"),
    ASC({ it.sortedArrayDescending().reversedArray() }, "ic_sorted_asc_41x36.svg"),
    DESC({ it.sortedArrayDescending() }, "ic_sorted_desc_41x36.svg"),
}

val ScoreViewer = VFC {
    val (title, _) = useState(getValueFromUrlParams("title"))
    val (subtitle, _) = useState(getValueFromUrlParams("subtitle"))
    val (scores, setScores) = useState<Array<AttemptLogScores>>(emptyArray())
    val aliases = getAliasesFromUrl()
    val (sortedScores, setSortedScores) = useState<Array<AttemptLogScores>>(emptyArray())
    val (sortType, setSortType) = useState(SortType.NO_SORT)

    useEffect(scores, sortType) {
        setSortedScores(sortType.sorter(scores))
    }

    useEffectOnce {
        MainScope().launch {
            val response = async { getAttemptScores(getValueFromUrlParams("filter")) }.await()
            when(val error = response.ToBackendError()) {
                null -> {
                    val deserialized = Json.decodeFromString<Array<AttemptLogScores>>(response.content)
                    val filtered = deserialized.filter { aliases.containsKey(it.scoreCode) }
                    val aliased = filtered.map { AttemptLogScores(
                        score = it.score,
                        targetScore = it.targetScore,
                        scoreCode = aliases[it.scoreCode]!!
                    )}.toTypedArray()
                    setScores(aliased)
                }
                else -> {
                    console.log(error.content)
                }
            }
        }
    }

    div {
        css(container)
        div {
            css(medalStyle)
            Icon {
                src = "icon_medal_ribbons_star_165x157.svg"
            }
        }

        h1 {
            css(titleStyle)
            +title
        }

        div {
            css(progressBarsHeaderStyle)
            h2 {
                css(subtitleStyle)
                +subtitle
            }

            if (sortedScores.isNotEmpty()) {
                SortButton {
                    this.sortType = sortType
                    this.setSortType = setSortType
                }
            }
        }

        if (sortedScores.isNotEmpty()) {
            sortedScores.forEach {
                ScoreProgressBar {
                    current = it.score
                    target = it.targetScore
                    label = it.scoreCode
                }
            }
        } else {
            h1 {
                css(noDataTitleStyle)
                +"No data to show"
            }
        }
    }
}

external interface SortButtonProps: Props {
    var sortType: SortType
    var setSortType: StateSetter<SortType>
}

val SortButton = FC<SortButtonProps> {props ->
    div {
        css(sortButtonStyle)
        onClick = {
            when(props.sortType) {
                SortType.NO_SORT -> props.setSortType(SortType.ASC)
                SortType.ASC -> props.setSortType(SortType.DESC)
                SortType.DESC -> props.setSortType(SortType.NO_SORT)
            }
        }
        Icon {
            src = props.sortType.iconSrc
        }
    }
}

private fun getValueFromUrlParams(key: String): String {
    return URLSearchParams(window.location.search).get(key) ?: ""
}

private fun getAliasesFromUrl(): Map<String, String> {
    val pairs = URL(window.location.href).searchParams.get("alias")?.split(",") ?: emptyList()
    val map = pairs.map {
        val pair = it.split("=")
        pair[0] to pair[1]
    }.toMap()
    return map
}