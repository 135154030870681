package pages.constructor.ui.components.editors.designEditor

import antd.Button
import app.useAppDispatch
import app.useAppSelector
import builders.enums.ETaskType
import emotion.react.css
import entities.backgroundPosScale.selectCanvasContainerHeight
import entities.backgroundPosScale.selectCanvasContainerWidth
import entities.interactivePicture.SetDeviceType
import entities.interactivePicture.advancedSettings.SetAdvancedSettings
import entities.interactivePicture.advancedSettings.selectAdvancedSettings
import entities.interactivePicture.background.EditBackgroundWithRatio
import entities.interactivePicture.background.selectBackgroundBase64
import entities.interactivePicture.background.selectCanvasHeight
import entities.interactivePicture.background.selectCanvasWidth
import entities.interactivePicture.elements.editors.canvasSizeEditor.CanvasSizeEditor
import entities.interactivePicture.selectDeviceType
import enums.EButtonTypes
import enums.EDeviceType
import pages.constructor.ui.components.editors.alignElementsPicker.AlignElementsPicker
import pages.constructor.ui.components.editors.spacingBetweenPicker.SpacingBetweenElementsPicker
import pages.constructor.ui.components.editors.stretchEdgesOfElementsEditor.StretchEdgesOfElementsEditor
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.components.Icon
import utils.structures.Point
import kotlin.math.min

val DesignEditor = FC<Props> {
    val deviceType = useAppSelector(selectDeviceType)
    val backgroundBase64 = useAppSelector(selectBackgroundBase64)
    val canvasContainerWidth = useAppSelector(selectCanvasContainerWidth)
    val canvasContainerHeight = useAppSelector(selectCanvasContainerHeight)
    val canvasWidth = useAppSelector(selectCanvasWidth)
    val canvasHeight = useAppSelector(selectCanvasHeight)
    val dispatch = useAppDispatch()
    val advancedSettings = useAppSelector(selectAdvancedSettings)

    div {
        css(headerContainer)
        div {
            css(header)
            +"Page setup"
        }
    }
    div {
        css(deviceContainer)
        Button {
            if (deviceType == EDeviceType.DESKTOP.value) css(activeDeviceButton) else css(deviceButton)
            id = EButtonTypes.DESKTOP_DEVICE_BUTTON.value
            onClick = f@{
                if (deviceType == EDeviceType.DESKTOP.value) {
                    return@f
                }
                dispatch(SetDeviceType(EDeviceType.DESKTOP.value, EDeviceType.DESKTOP.maxWidth))
                dispatch(SetAdvancedSettings(advancedSettings.copy(
                    taskType = ETaskType.ENGLISH_DESKTOP.toTaskType()
                )))
                val ratio = if (backgroundBase64 == null) {
                    Point(16, 9)
                } else {
                    Point(canvasWidth ?: 16, canvasHeight ?: 9)
                }

                dispatch(
                        EditBackgroundWithRatio(
                            min(canvasContainerWidth, EDeviceType.DESKTOP.maxWidth),
                            canvasContainerHeight,
                            ratio
                        )
                    )
            }
            Icon {
                if (deviceType == EDeviceType.DESKTOP.value) css(activeFilter)
                src = "ic_desktop_device_24x24.svg"
            }
            div {
                css(deviceText)
                +EDeviceType.DESKTOP.value
            }
        }
        Button {
            if (deviceType == EDeviceType.MOBILE.value) css(activeDeviceButton) else css(deviceButton)
            id = EButtonTypes.MOBILE_DEVICE_BUTTON.value
            onClick = f@{
                if (deviceType == EDeviceType.MOBILE.value) {
                    return@f
                }
                dispatch(SetDeviceType(EDeviceType.MOBILE.value, EDeviceType.MOBILE.maxWidth))
                dispatch(SetAdvancedSettings(advancedSettings.copy(
                    taskType = ETaskType.ENGLISH_MOBILE.toTaskType()
                )))
                val ratio = if (backgroundBase64 == null) {
                    Point(3, 4)
                } else {
                    Point(canvasWidth ?: 3, canvasHeight ?: 4)
                }
                dispatch(
                    EditBackgroundWithRatio(
                        min(canvasContainerWidth, EDeviceType.MOBILE.maxWidth),
                        canvasContainerHeight,
                        ratio
                    )
                )
            }
            Icon {
                if (deviceType == EDeviceType.MOBILE.value) css(activeFilter)
                src = "ic_mobile_device_24x24.svg"
            }
            div {
                css(deviceText)
                +EDeviceType.MOBILE.value
            }
        }
    }
    when (deviceType) {
        EDeviceType.DESKTOP.value -> CanvasSizeEditor {
            canvasSizes = listOf(
                Pair(Point(16, 9), EButtonTypes.RESOLUTION_A_BUTTON),
                Pair(Point(4, 3), EButtonTypes.RESOLUTION_B_BUTTON),
                Pair(Point(1, 1), EButtonTypes.RESOLUTION_C_BUTTON),
                )
        }
        EDeviceType.MOBILE.value -> CanvasSizeEditor {
            canvasSizes = listOf(
                Pair(Point(9, 16), EButtonTypes.RESOLUTION_D_BUTTON),
                Pair(Point(3, 4), EButtonTypes.RESOLUTION_E_BUTTON),
                Pair(Point(1, 1), EButtonTypes.RESOLUTION_F_BUTTON),
            )
        }
    }
    div {
        css(themesContainer)
    }
    AlignElementsPicker{}
    StretchEdgesOfElementsEditor {}
    SpacingBetweenElementsPicker{}
}