package pages.constructor.ui.components.header.templates.gallery

import antd.Input
import emotion.react.css
import online.interactiver.common.api.TemplateMeta
import online.interactiver.common.api.TemplatesModalWindowData
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.useState
import shared.components.Icon

external interface TemplateGalleryProps : Props {
    var setCurTemplate: (template: TemplateMeta) -> Unit
    var data: TemplatesModalWindowData?
}

val TemplateGallery = FC<TemplateGalleryProps> { props ->

    val (searchText, setSearchText) = useState("")

    ReactHTML.div {
        css(container)
        ReactHTML.div {
            css(topLine)
            ReactHTML.div {
                css(searchHolder)
                Icon {
                    css(searchIcon)
                    src = "ic_search_20x20.svg"
                }
                Input {
                    css(search)
                    bordered = false
                    placeholder = "Search..."
                    defaultValue = searchText
                    onChange = { event -> setSearchText(event.target.value) }
                }
            }
        }
        ReactHTML.div {
            css(groupContainer)
            ModalGroup {
                groupName = "Use Cases"
                this.templates = getFilteredArray(props.data!!.useCaseTemplates, searchText)
                this.setCurTemplate = { template: TemplateMeta -> props.setCurTemplate(template) }
                this.tags = props.data!!.tagColors
            }
            ModalGroup {
                groupName = "Desktop Mechanics"
                this.templates = getFilteredArray(props.data!!.mechanicsDesktopTemplates, searchText)
                this.setCurTemplate = { template: TemplateMeta -> props.setCurTemplate(template) }
                this.tags = props.data!!.tagColors
            }
            ModalGroup {
                groupName = "Mobile Mechanics"
                this.templates = getFilteredArray(props.data!!.mechanicsMobileTemplates, searchText)
                this.setCurTemplate = { template: TemplateMeta -> props.setCurTemplate(template) }
                this.tags = props.data!!.tagColors
            }
            ModalGroup {
                groupName = "Language Learning"
                this.templates = getFilteredArray(props.data!!.languageLearningTemplates, searchText)
                this.setCurTemplate = { template: TemplateMeta -> props.setCurTemplate(template) }
                this.tags = props.data!!.tagColors
            }
            ModalGroup {
                groupName = "Background"
                this.templates = getFilteredArray(props.data!!.backgroundTemplates, searchText)
                this.setCurTemplate = { template: TemplateMeta -> props.setCurTemplate(template) }
                this.tags = props.data!!.tagColors
            }
        }
    }
}

fun getFilteredArray(array: MutableList<TemplateMeta>, filter: String): MutableList<TemplateMeta> {
    val result = ArrayList<TemplateMeta>()

    array.forEach {
        if (it.name.lowercase().contains(filter.lowercase())) {
            result.add(it)
            return@forEach
        }

        if (it.tags.filter { it.lowercase().contains(filter.lowercase()) }.size > 0) {
            result.add(it)
            return@forEach
        }
    }

    return result
}