package shared.canvas.interfaces

import builders.enums.EElementType
import builders.enums.EInteractiveType
import online.interactiver.common.enums.ELanguage
import builders.enums.EStyleType
import entities.lastCreatedType.LastCreatedTypeState
import enums.EDeviceType
import online.interactiver.common.interactivepicture.*
import online.interactiver.common.utils.Copyable
import shared.canvas.implementations.PHRASE_BOUNDS_PADDING
import shared.canvas.implementations.PHRASE_PUZZLES_GROUP_PADDING
import utils.measureHeight
import utils.measurePuzzlesBlockHeight
import utils.structures.Position
import kotlin.math.max
import kotlin.math.min
import kotlin.math.sqrt

const val ELEMENT_OFFSET = 20

fun InteractiveElement.resizeSavingRatio(bounds: utils.structures.Position): utils.structures.Position {

    // Вычисляем сколько процентов нехватает чтобы заполнить вертикаль или горизонталь
    val percentsToVertical = (this.getElementBounds().height.toDouble() / bounds.height) - 1
    val percentsToHorizontal = (this.getElementBounds().width.toDouble() / bounds.width) - 1

    if (percentsToHorizontal < percentsToVertical) {
        // Если до вертикали скейлить меньше
        // То вертикали берем за высоту блока
        // А горизонталь домножаем на скейл
        val ratio = bounds.height / this.getElementBounds().height.toDouble()
        val newWidth = (ratio * this.getElementBounds().width).toInt()
        val newX = (bounds.width - newWidth) / 2
        return utils.structures.Position(newX, 0, newWidth, bounds.height)
    } else {
        // Если до горизонатли скейлить меньше
        // То горизонатль берем за длину блока
        // А вертикаль домножаем на скейл
        val ratio = bounds.width / this.getElementBounds().width.toDouble()
        val newHeight = (ratio * this.getElementBounds().height).toInt()
        val newY = (bounds.height - newHeight) / 2
        return utils.structures.Position(0, newY, bounds.width, newHeight)
    }

}

// TODO: Во всех ?: возвращать не пустой конструктор, а стандартный элемент
fun InteractiveElement.getHintText(): String {
    return this.hint.text?.simpleText ?: ""
}

fun InteractiveElement.getHintTextStyle(): TextStyle {
    return this.hint.text?.style ?: TextStyle()
}

fun InteractiveElement.getHintGeometryStyle(): GeometryStyle {
    return this.hint.style?.usual ?: GeometryStyle()
}

fun InteractiveElement.shouldSyncPhrase(): Boolean {
    return this.isMarker() || this.isControl() ||
            this.visibleElement.type == EElementType.TEXT_STATIC.text ||
            this.visibleElement.type == EElementType.TEXT_DRAG.text
}

fun InteractiveElement.isControl(): Boolean {
    return this.visibleElement.type == EElementType.BUTTON_CONTROL.text ||
            this.visibleElement.type == EElementType.INPUT_CONTROL.text ||
            this.isSelector()
}

fun InteractiveElement.isFiguresAndLines(): Boolean {
    return arrayOf(
        EElementType.TEXT_STATIC.text,
        EElementType.MARKER_STATIC.text,
        EElementType.LINE_STATIC.text,
        EElementType.ARROW_STATIC.text,
        EElementType.SOUND_STATIC.text,
        EElementType.IMAGE_STATIC.text,
    ).any { it == this.visibleElement.type }
}

fun InteractiveElement.isFrame(): Boolean {
    return arrayOf(EElementType.RECT_FRAME.text, EElementType.AREA_FRAME.text).any { it == this.visibleElement.type }
}

fun InteractiveElement.isOther(): Boolean {
    return EElementType.PHRASE_OTHER.text == this.visibleElement.type
}

fun InteractiveElement.isSelector(): Boolean {
    return this.visibleElement.type == EElementType.SELECTOR_CONTROL.text
}
fun InteractivePicture.getCanvasMaxWidth(): Int {
    this.background.maxWidth?.let { return it }
    if (this.deviceType == EDeviceType.DESKTOP.value) {
        return EDeviceType.DESKTOP.maxWidth
    }

    return EDeviceType.MOBILE.maxWidth
}


fun SimpleElement.getElementBounds(): utils.structures.Position {
    val x: Int
    val y: Int
    val width: Int?
    val height: Int?

    when (this.type) {
        EElementType.MARKER_STATIC.text -> {
            val radius = this.geometry!!.circle!!.radius!!
            x = this.geometry!!.circle!!.centerPosition!!.absolutePosition!!.x
            y = this.geometry!!.circle!!.centerPosition!!.absolutePosition!!.y
            width = 2 * radius
            height = 2 * radius
        }

        EElementType.LINE_STATIC.text -> {
            val curve = this.geometry!!.curve!!
            val start = curve.start!!.absolutePosition!!
            val middle = curve.middle!!.absolutePosition!!
            val end = curve.end!!.absolutePosition!!
            x = min(min(start.x, end.x), middle.x)
            y = min(min(start.y, end.y), middle.y)
            width = max(max(start.x, end.x), middle.x) - x
            height = max(max(start.y, end.y), middle.y) - y
        }

        EElementType.ARROW_STATIC.text -> {
            val curve = this.geometry!!.curve!!
            val start = curve.start!!.absolutePosition!!
            val middle = curve.middle!!.absolutePosition!!
            val end = curve.end!!.absolutePosition!!
            x = min(min(start.x, end.x), middle.x)
            y = min(min(start.y, end.y), middle.y)
            width = max(max(start.x, end.x), middle.x) - x
            height = max(max(start.y, end.y), middle.y) - y
        }

        else -> {
            x = this.geometry!!.rect!!.leftTopPosition!!.absolutePosition!!.x
            y = this.geometry!!.rect!!.leftTopPosition!!.absolutePosition!!.y
            width = this.geometry!!.rect!!.width
            height = this.geometry!!.rect!!.height
        }
    }

    return utils.structures.Position(x, y, width ?: 100, height ?: 100)
}

fun InteractiveElement.getElementBounds(): utils.structures.Position {
    return when(this.type) {
        EInteractiveType.PHRASE_INTERACTIVE.text -> {
            val phrase = this.phrase!!
            utils.structures.Position(
                x = phrase.leftTop.x - PHRASE_BOUNDS_PADDING, y = phrase.leftTop.y - PHRASE_BOUNDS_PADDING,
                width = phrase.width + 2 * PHRASE_BOUNDS_PADDING,
                height = phrase.measureHeight() + phrase.measurePuzzlesBlockHeight() + PHRASE_PUZZLES_GROUP_PADDING + 2 * PHRASE_BOUNDS_PADDING
            )
        }
        else -> this.visibleElement.getElementBounds()
    }
}

fun InteractiveElement.getElementStyle(isSelected: Boolean, type: EStyleType): GeometryStyle {

    val style = this.visibleElement.style!!

    val usual = style.usual ?: GeometryStyle()

    if (isSelected) {
        return when (type) {
            EStyleType.HOVER -> style.onHover ?: usual
            else -> style.onFocus ?: usual
        }
    }

    return when (type) {
        EStyleType.DRAG -> style.onDrag ?: usual
        EStyleType.HOVER -> style.onHover ?: usual
        EStyleType.FOCUS -> style.onFocus ?: usual
        else -> usual
    }
}

fun InteractiveElement.getGeometryClone(bounds: utils.structures.Position): Geometry {
    val geometry = this.visibleElement.geometry!!.clone()

    val position = Position()
    position.absolutePosition = Point(bounds.x, bounds.y)

    when (this.visibleElement.type) {
        EElementType.MARKER_STATIC.text -> {
            val circle = Circle()
            circle.radius = bounds.width / 2
            circle.centerPosition = position
            geometry.circle = circle
        }

        else -> {
            val rect = Rect()
            rect.height = bounds.height
            rect.width = bounds.width
            rect.leftTopPosition = position
            geometry.rect = rect
        }
    }

    return geometry
}

fun InteractiveElement.isDraggable(): Boolean {
    return this.visibleElement.type == EElementType.TEXT_DRAG.text ||
            this.visibleElement.type == EElementType.BACKGROUND_DRAG.text ||
            this.visibleElement.type == EElementType.IMAGE_DRAG.text
}

fun InteractiveElement.shouldDrawCheckmark(): Boolean {
    return this.type === EInteractiveType.INPUT_INTERACTIVE.text ||
            this.type === EInteractiveType.BUTTON_INTERACTIVE.text ||
            this.type === EInteractiveType.GAP_PUZZLE_INTERACTIVE.text
}

fun InteractiveElement.getCheckmark(): String {
    return when (this.type) {
        EInteractiveType.INPUT_INTERACTIVE.text -> this.input!!.checkmarkPosition
        EInteractiveType.BUTTON_INTERACTIVE.text -> this.button!!.checkmarkPosition
        else -> this.gapPuzzle!!.checkmarkPosition
    }
}

fun InteractiveElement.updateIdentifiersOfElement(id: String) {
    this.identifier.updateCopiedElements(this.type, id)
    this.visibleElement.identifier.setIdentifierByText(this.type + " visibleElement")
    this.hint.identifier.setIdentifierByText(this.type + " hint")
    this.gapPuzzle?.gap?.identifier?.setIdentifierByText(this.type + " gap")
    this.selector?.let { selector ->
        selector.options?.map { option ->
            option.identifier?.updateWithRand("SelectorOption")
            option.frames?.map { frame ->
                frame.identifier.updateWithRand(frame.type)
            }
            option.figuresAndLines?.map { figure ->
                figure.identifier.updateWithRand(figure.type)
            }
        }
    }
}
inline fun <reified T: Copyable<T>, reified U> T.transform(crossinline transformation: U.() -> Unit, selector: (T) -> U): T {
    val res = clone()
    transformation(selector(res))
    return res
}
inline fun <reified T, reified U, reified V> T.calculate(calculations: (U) -> V, selector: (T) -> U): V {
    return calculations(selector(this))
}
fun InteractiveElement.transformVisibleElementCurveStart(transformation: Point?.() -> Unit) = transform(transformation) {
    it.visibleElement.geometry?.curve?.start?.absolutePosition
}
fun InteractiveElement.transformVisibleElementCurveMiddle(transformation: Point?.() -> Unit) = transform(transformation) {
    it.visibleElement.geometry?.curve?.middle?.absolutePosition
}
fun InteractiveElement.transformVisibleElementCurveEnd(transformation: Point?.() -> Unit) = transform(transformation) {
    it.visibleElement.geometry?.curve?.end?.absolutePosition
}
fun InteractiveElement.calculateForCurve(calculations: (Curve?) -> Int): Int = calculate(calculations) {
    it.visibleElement.geometry?.curve
}
fun InteractiveElement.transformVisibleElementCircleCenter(transformation: Point?.() -> Unit) = transform(transformation) {
    it.visibleElement.geometry?.circle?.centerPosition?.absolutePosition
}
fun InteractiveElement.transformVisibleElementRectPosition(transformation: Point?.() -> Unit) = transform(transformation) {
    it.visibleElement.geometry?.rect?.leftTopPosition?.absolutePosition
}

fun InteractiveElement.transformVisibleElementRect(transformation: Rect?.() -> Unit) = transform(transformation) {
    it.visibleElement.geometry?.rect
}

fun InteractiveElement.transformVisibleElementCircle(transformation: Circle?.() -> Unit) = transform(transformation) {
    it.visibleElement.geometry?.circle
}

fun InteractiveElement.transformGapPuzzleRect(transformation: Rect?.() -> Unit) = transform(transformation) {
    it.gapPuzzle?.gap?.geometry?.rect
}

fun InteractiveElement.transformGapPuzzlePosition(transformation: Point?.() -> Unit) = transform(transformation) {
    it.gapPuzzle?.gap?.geometry?.rect?.leftTopPosition?.absolutePosition
}

fun <T> getValueForEditor(focused: InteractiveElement?, list: ArrayList<InteractiveElement>, getter: (InteractiveElement) -> T?): T? {
    if (focused != null) {
        return getter(focused)
    }

    val value = getter(list.first())
    list.forEach {
        if (getter(it) != value) {
            return null
        }
    }

    return value
}

val shouldDrawEditor = {focused: InteractiveElement?, list: ArrayList<InteractiveElement>, checker: (InteractiveElement) -> Boolean -> Boolean
    val shouldDrawIfOneFocused = focused != null && checker(focused)
    val shouldDrawIfGroupFocused = list.isNotEmpty() && list.all { checker(it) }

    shouldDrawIfOneFocused || shouldDrawIfGroupFocused
}

val SolvingStageCondition.Companion.allTime: SolvingStageCondition
    get() = SolvingStageCondition(
        activeAfterFinalVerification = "1",
        activeAfterFirstVerification = "1",
        activeBeforeSolvingStart = "1",
        activeWhileSolving = "1"
    )

val SolvingStageCondition.Companion.afterChecking: SolvingStageCondition
    get() = SolvingStageCondition(
        activeAfterFinalVerification = "1",
        activeAfterFirstVerification = "1",
        activeBeforeSolvingStart = "0",
        activeWhileSolving = "0"
    )

val SolvingStageCondition.Companion.beforeChecking: SolvingStageCondition
    get() = SolvingStageCondition(
        activeAfterFinalVerification = "0",
        activeAfterFirstVerification = "0",
        activeBeforeSolvingStart = "1",
        activeWhileSolving = "1"
    )

fun SolvingStageCondition.isAllTimeState(): Boolean {
    return activeBeforeSolvingStart == "1"
            && activeWhileSolving == "1"
            && activeAfterFirstVerification == "1"
            && activeAfterFinalVerification == "1"
}

fun SolvingStageCondition.isAfterCheckingState(): Boolean {
    return activeAfterFirstVerification == "1"
            && activeAfterFinalVerification == "1"
}

fun SolvingStageCondition.isBeforeCheckingState(): Boolean {
    return activeBeforeSolvingStart == "1" &&
            activeWhileSolving == "1"
}


fun InteractiveElement.setPicture(picture: Picture) {
    this.visibleElement.picture = picture
}

fun InteractiveElement.getRelativePosition(): Point {
    if (isMarker()) {
        return Point(getElementBounds().width / 2, getElementBounds().height / 2)
    }

    return Point(0, 0)
}


fun InteractiveElement.isMarker(): Boolean {
    return this.visibleElement.type.equals(EElementType.MARKER_STATIC.text)
}

fun InteractiveElement.isFixedRatio(): Boolean {
    if (this.visibleElement.type.equals(EElementType.MARKER_STATIC.text)) {
        return true
    }

    if (this.visibleElement.type.equals(EElementType.IMAGE_DRAG.text)) {
        return true
    }

    if (this.visibleElement.type.equals(EElementType.IMAGE_STATIC.text)) {
        return true
    }

    if (this.visibleElement.type.equals(EElementType.SOUND_STATIC.text)) {
        return true
    }

    return false
}


fun Point.norm(): Double {
    return sqrt((x.toDouble() * x.toDouble() + y.toDouble() * y.toDouble()))
}

fun Position.getPositionWithOffset(lastCreatedType: LastCreatedTypeState, type: EElementType): Position {
    if (lastCreatedType.type != type) {
        return this
    }

    return this.copy(x = this.x + lastCreatedType.times * ELEMENT_OFFSET, y = this.y + lastCreatedType.times * ELEMENT_OFFSET)
}

fun Point.getPointWithOffset(lastCreatedType: LastCreatedTypeState, type: EElementType): Point {
    if (lastCreatedType.type != type) {
        return this
    }

    return Point(x = this.x + lastCreatedType.times * ELEMENT_OFFSET, y = this.y + lastCreatedType.times * ELEMENT_OFFSET)
}

fun InteractiveElement.getRatio(): Double {
    if (this.visibleElement.type.equals(EElementType.MARKER_STATIC.text)) {
        return 1.0
    }

    val bounds = this.getElementBounds()

    if (bounds.height == 0) {
        return 1.0
    }

    return bounds.width.toDouble() / bounds.height.toDouble()
}

fun InteractiveElement.getNoStrokeAllowed(): Boolean {
    val type = EInteractiveType.values().find { it.text == this.type }
    return type!!.noStrokeAllowed
}

fun InteractiveElement.getNoBorderForLines(): Boolean {
    val type = EInteractiveType.values().find { it.text == this.type }
    return type!!.noBorderForLines
}

fun InteractiveElement.getPlaceholder(): String? {
    return when (this.type) {
        EInteractiveType.INPUT_INTERACTIVE.text -> this.input?.placeholderText
        EInteractiveType.GAP_PUZZLE_INTERACTIVE.text -> this.gapPuzzle?.gap?.text?.simpleText
        else -> null
    }
}

fun String.getLanguageByCode(): ELanguage? {
    return ELanguage.values().firstOrNull { this == it.code }
}

fun String.getLanguageByText(): ELanguage? {
    return ELanguage.values().firstOrNull { this == it.text }
}