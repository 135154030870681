package pages.constructor.ui.components.header.previewShare.previewModal

import Modal
import antd.Button
import app.useAppSelector
import emotion.react.css
import entities.alert.EAlertTarget
import entities.interactivePicture.selectInteractiveName
import org.w3c.dom.HTMLDivElement
import pages.constructor.ui.components.alerts.Alerts
import pages.constructor.ui.components.header.previewShare.previewModal.previewIframe.PreviewIframe
import pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar.PreviewRightBar
import react.*
import react.dom.html.ReactHTML.div
import shared.components.Icon

external interface PreviewModalProps : Props {
    var isOpen: Boolean?
    var onClose: (() -> Unit)?
}

data class PreviewModalContextData(
    val isOpen: Boolean = false,
    val setIsOpen: (Boolean) -> Unit = { },
    val errorNoGroupChosen: Boolean = false,
    val setErrorNoGroupChosen: (Boolean) -> Unit = { }
)

val PreviewModalContext = createContext(PreviewModalContextData())

val PreviewModal = FC<PreviewModalProps> { props ->
    val (isOpen, setIsOpen) = useState(props.isOpen == true)
    val (errorNoGroupChosen, setErrorNoGroupChosen) = useState(false)
    val interactiveName = useAppSelector(selectInteractiveName)

    useEffect(props.isOpen) {
        setIsOpen(props.isOpen == true)
    }

    val divRef = useRef<HTMLDivElement>()
    val alertsContainerRef = useRef<HTMLDivElement>()

    useLayoutEffect {
        alertsContainerRef.current?.style?.width = "${(divRef.current?.offsetWidth ?: 0) + 110}px"
    }

    PreviewModalContext.Provider {
        value = PreviewModalContextData(isOpen, { props.onClose?.invoke() }, errorNoGroupChosen, { setErrorNoGroupChosen(it) })
        Modal {
            centered = true
            open = isOpen
            footer = null
            width = "calc(100vw - 198px)"
            bodyStyle = modal
            onCancel = {
                props.onClose?.invoke()
            }
            div {
                css(globContainer)
                ref = divRef
                div {
                    css(alertsContainer)
                    ref = alertsContainerRef
                    Alerts {
                        css(alert)
                        target = EAlertTarget.PREVIEW
                    }
                }
                Button {
                    css(backToEditorButton)
                    Icon { src = "ic_arrow_left_dark_24x24.svg" }
                    +"Back to editor"
                    onClick = { props.onClose?.invoke() }
                }
                div {
                    css(interactiveHeader)
                    +(interactiveName ?: "No name")
                }
                div {
                    css(contentContainer)
                    PreviewIframe { }
                    PreviewRightBar { }
                }
            }
        }
    }
}
