package shared.components.header

import csstype.*
import emotion.react.css
import react.FC
import react.PropsWithClassName
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import shared.components.tooltipInfo.TooltipInfo

external interface HeaderProps : PropsWithClassName {
    var text: String
    var fontSize: FontSize?
    var lineHeight: LineHeight?
    var fontWeight: FontWeight?
    var color: String?

    var tooltip: dynamic
    var tooltipOverlayStyle: dynamic
}

val Header = FC<HeaderProps> {
    div {
        css(tooltipWrapper)
        p {
            css {
                fontSize = it.fontSize ?: 20.px
                lineHeight = it.lineHeight ?: 26.px
                margin = 3.px
                padding = 0.px
                color = Color(it.color ?: "#282F3E")
                fontWeight = it.fontWeight ?: FontWeight.bold
            }
            +it.text
        }
        TooltipInfo {
            title = it.tooltip
            overlayStyle = it.tooltipOverlayStyle
        }
    }
}