package entities.interactivePicture.viewer.filter


import builders.enums.EElementGroup
import builders.enums.EInteractiveType
import emotion.react.css
import entities.interactivePicture.viewer.filterContainer
import react.FC
import react.Props
import react.dom.html.ReactHTML.div

external interface ViewerFilterProps : Props {
    var group: EElementGroup
    var filter: Map<EInteractiveType, Boolean>
}

val ViewerFilter = FC<ViewerFilterProps> { props ->
    val filterTypes = EInteractiveType.values().filter { it.group == props.group }

    div {
        css(filterContainer)

        filterTypes.map {
            FilterButton {
                checked = if (!props.filter.containsKey(it)) true else props.filter.get(it)!!
                type = it
            }
        }
    }
}