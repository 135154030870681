package shared.canvas.implementations


import Vector2d
import app.useAppImage
import online.interactiver.common.interactivepicture.InteractiveElement
import org.w3c.dom.HTMLImageElement
import react.FC
import react.MutableRefObject
import react.ref
import react.useRef
import reactkonva.Image
import reactkonva.Rect
import shared.canvas.KonvaElementProps
import utils.types.impl

fun getImage(element: InteractiveElement): HTMLImageElement? {
    var image: HTMLImageElement? = null
    if (element.visibleElement.picture != null) {
        image = useAppImage(element.visibleElement.picture!!.base64!!)[0] as HTMLImageElement?
    }
    return image
}

val TransformableImage = FC<KonvaElementProps> { props ->

    val image = getImage(props.data)
    val imageRef = useRef<dynamic>()

    val style = props.style

    Rect {
        this.ref = props.elementRef
        this.width = props.bounds.width
        this.height = props.bounds.height
        this.onTransform =
            { event ->
                props.onTransform(event)
                val node = (props.elementRef as MutableRefObject<dynamic>).current
                val image = imageRef.current

                image.width(node.width())
                image.height(node.height())
                image.scaleX(1)
                image.scaleY(1)
            }
        this.onTransformStart = props.onTransformStart
        this.onTransformEnd = props.onTransformEnd
        this.fill = style.fillColor
        this.stroke = style.strokeColor
    }
    if (image != null) {
        Image {
            this.ref = imageRef
            this.width = props.bounds.width
            this.height = props.bounds.height
            this.image = image
            this.opacity = style.opacity
            this.strokeWidth = style.strokeWidth
            this.lineCap = style.lineCap
            this.lineJoin = style.lineJoin
            this.dash = style.dash?.toTypedArray()
            this.shadowColor = style.shadowColor
            this.shadowBlur = style.shadowBlur
            this.shadowOffset = style.shadowOffset?.let { impl<Vector2d> { x = it.x; y = it.y } }
            this.shadowOpacity = style.shadowOpacity
        }


    }
}