package pages.constructor.ui.components.advancedSettings.controlButtonsColorSettings

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    width = 170.px
}

val radioLabelSquare: CssStyle = {
    width = 115.px
    height = 19.px
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.center
    marginBottom = 8.px
}

val radioLabel: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
}

val text: CssStyle = {
    marginLeft = 12.px
    lineHeight = 18.9.px
    fontSize = 14.px
}

val colorSquare: (String) -> CssStyle = { color ->
    {
        display = Display.block
        width = 18.px
        height = 18.px
        padding = 0.px
        backgroundColor = Color(color)
        border = Border(0.7.px, LineStyle.solid, Color("#D0D5DD"))
        borderRadius = 3.px
    }
}