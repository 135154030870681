package online.interactiver.common.api

import kotlinx.serialization.Serializable

var urlDirectory: String = "https://interactives-storage.fra1.cdn.digitaloceanspaces.com/"

@Serializable
data class TemplateMeta(
    var name: String,
    var code: String,
    var directory: String = "", // for example "landing_samples/SQL_join/"
    var fileName: String, // jsonURL = urlDirectory + directory + fileName + ".json"; urlURL = urlDirectory + directory + fileName + ".html" // to take .json from storage without generation
    var imageName: String,
    var htmlName: String,
    var description: String,
    var tags: MutableList<String> // "Buttons", "Regular Verbs"
) {
    private fun getDirectoryName(): String {
        if (directory.endsWith("/")) {
            return directory
        }
        return directory + "/"
    }
    fun getJSONURL(): String {
        return urlDirectory + this.getDirectoryName() + this.fileName
    }

    fun getImageURL(): String {
        return urlDirectory + this.getDirectoryName() + this.imageName
    }

    fun getHtmlURL(): String {
        return urlDirectory + this.getDirectoryName() + this.htmlName
    }
}


@Serializable
data class TagColor(
    var tagName: String, // can contain spaces
    var color: String,
    var textColor: String
)

@Serializable
data class TemplatesModalWindowData(
    var useCaseTemplates: MutableList<TemplateMeta>, // идем в порядке загрузки (чем раньше встретили, тем левее нарисуем)
    var mechanicsMobileTemplates: MutableList<TemplateMeta>, // идем в порядке загрузки (чем раньше встретили, тем левее нарисуем)
    var mechanicsDesktopTemplates: MutableList<TemplateMeta>,
    var backgroundTemplates: MutableList<TemplateMeta>,
    var languageLearningTemplates: MutableList<TemplateMeta>,

    var tagColors: MutableList<TagColor>
) {
    fun getTemplateByCode(code: String): TemplateMeta? {
        val templates = useCaseTemplates + mechanicsMobileTemplates + mechanicsDesktopTemplates + backgroundTemplates + languageLearningTemplates
        return templates.find { template -> template.code == code }
    }
}


