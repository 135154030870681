package entities.interactivePicture.elements.frames

import builders.enums.EFrameType
import builders.enums.EInteractiveType
import builders.getFrameBuilder
import entities.interactivePicture.elements.CommonInteractiveElementAction
import entities.interactivePicture.elements.CommonListReducer
import entities.interactivePicture.elements.InteractiveElementAction
import entities.interactivePicture.elements.addElementToList
import entities.interactivePicture.elements.other.hintGeometryStyle
import entities.interactivePicture.elements.other.hintTextStyle
import online.interactiver.common.interactivepicture.InteractiveElement

data class AddFrame(val id: String, val position: utils.structures.Position?, val type: EFrameType) : InteractiveElementAction(false)

fun addFrameToList(
    list: MutableList<InteractiveElement>, id: String, position: utils.structures.Position?, type: EFrameType,
    toOption: Boolean
): MutableList<InteractiveElement> {
    val builder = getFrameBuilder().setType(type.toEElementType()).setRect(position).setHoverFocusStyling(type.style)
        .setHintDefaultStyle(hintGeometryStyle).setHintTextStyle(hintTextStyle).setID(id)

    if (toOption)
        builder.setType(EInteractiveType.OPTION_FRAME_INTERACTIVE)

    return addElementToList(list, builder.build())
}

val FramesReducer = { state: MutableList<InteractiveElement>, action: InteractiveElementAction ->
    when (action) {
        is CommonInteractiveElementAction -> CommonListReducer(state, action)
        is AddFrame -> addFrameToList(state, action.id, action.position, action.type, false)
        else -> state
    }
}
