package shared.canvas.utils

import app.appState.CommonState
import app.appState.IAppState
import builders.enums.ESoundBadgeAlign
import builders.enums.ESoundBadgePosition
import entities.interactivePicture.guides.LineGuide
import entities.interactivePicture.guides.getOppositeAnchor
import entities.selectedElement.SelectElement
import entities.selectedElement.SelectMultipleElements
import enums.EOrientation
import enums.ESnapType
import online.interactiver.common.interactivepicture.InteractiveElement
import online.interactiver.common.interactivepicture.Point
import online.interactiver.common.interactivepicture.Rect
import react.MutableRefObject
import react.StateSetter
import redux.RAction
import shared.canvas.implementations.TEXT_PADDING
import shared.canvas.implementations.getTextStyle
import shared.canvas.interfaces.getRatio
import shared.canvas.interfaces.isFixedRatio
import shared.canvas.interfaces.isMarker
import shared.canvas.soundWidthOverlappingRect
import utils.structures.Position
import kotlin.math.abs
import kotlin.math.max

// Стандартный метод изменения размеров объекта при трансформации
fun onTransformElement(
    target: dynamic, group: dynamic, text: dynamic, data: InteractiveElement, anchorVerticalLine: LineGuide,
    anchorHorizontalLine: LineGuide, leaderOrientation: EOrientation
): Position {
    var width = (target.width() * target.scaleX()) as? Int ?: 0
    var height = (target.height() * target.scaleY()) as? Int ?: 0

    if (data.isFixedRatio()) {
        val bounds = updateBoundsByRatio(width.toDouble(), height.toDouble(), data.getRatio(), leaderOrientation)
        width = bounds.width
        height = bounds.height
    }

    target.width(width)
    target.height(height)
    target.scaleX(1)
    target.scaleY(1)


    var offsetX = 0
    var offsetY = 0

    if (data.isMarker()) {
        offsetX = target.width() / 2
        offsetY = target.height() / 2
    }

    if (anchorVerticalLine.snap == ESnapType.SKIP) {
        group.x(target.x() - offsetX + group.x())
    } else if (anchorVerticalLine.snap == ESnapType.START) {
        group.x(anchorVerticalLine.position)
    } else {
        group.x(-width + anchorVerticalLine.position)
    }

    if (anchorHorizontalLine.snap == ESnapType.SKIP) {
        group.y(target.y() - offsetY + group.y())
    } else if (anchorHorizontalLine.snap == ESnapType.START) {
        group.y(anchorHorizontalLine.position)
    } else {
        group.y(-height + anchorHorizontalLine.position)
    }

    target.x(offsetX)
    target.y(offsetY)

    val textStyle = getTextStyle(data)

    val padding = textStyle.padding ?: TEXT_PADDING
    val soundIconSize = data.sound?.iconSize ?: 0
    val textX = if (data.sound == null) padding else {
        val soundPosition = ESoundBadgePosition.fromString(data.sound?.badgePosition)
        when(soundPosition.align) {
            ESoundBadgeAlign.LEFT -> padding + soundWidthOverlappingRect(soundPosition, soundIconSize)
            ESoundBadgeAlign.RIGHT -> padding
        }
    }
    val textWidth = if (data.sound == null) width - 2 * padding else {
        val soundPosition = ESoundBadgePosition.fromString(data.sound?.badgePosition)
        width - 2 * padding - soundWidthOverlappingRect(soundPosition, soundIconSize)
    }

    text?.x(textX)
    text?.y(padding)
    text?.width(textWidth)
    text?.height(height - 2 * padding)

    return Position(group.x(), group.y(), width, height)
}

fun selectElements(
    isShiftPressed: Boolean,
    element: InteractiveElement,
    selected: InteractiveElement?,
    elementsIdsUnderSelectionRectangle: Array<String>,
    isFocused: Boolean,
    isUnderSelectionRectangle: Boolean,
    dispatch: (RAction) -> RAction,
    appState: IAppState) {
    if (!isShiftPressed || appState !is CommonState || selected == null && elementsIdsUnderSelectionRectangle.isEmpty()) {
        dispatch(SelectElement(element.identifier.id))
        dispatch(SelectMultipleElements(arrayOf()))
        return
    }

    if (selected != null) {
        if (!isFocused) {
            dispatch(SelectMultipleElements(arrayOf(selected.identifier.id!!, element.identifier.id!!)))
        }

        dispatch(SelectElement(null))
        return
    }

    if (!isUnderSelectionRectangle) {
        dispatch(SelectMultipleElements(elementsIdsUnderSelectionRectangle + element.identifier.id!!))
        return
    }

    if (elementsIdsUnderSelectionRectangle.size == 2) {
        val id = if (element.identifier.id!! == elementsIdsUnderSelectionRectangle[0]) {
            elementsIdsUnderSelectionRectangle[1]
        } else {
            elementsIdsUnderSelectionRectangle[0]
        }
        dispatch(SelectElement(id))
        dispatch(SelectMultipleElements(arrayOf()))
        return
    }

    dispatch(SelectMultipleElements(elementsIdsUnderSelectionRectangle.filter { it != element.identifier.id!! }.toTypedArray()))
}


fun updateBoundsByRatio(width: Double, height: Double, ratio: Double, orientation: EOrientation): Position {

    var newWidth = width
    var newHeight = height

    when (orientation) {
        EOrientation.NONE -> {
            if (width > height) {
                newWidth = height * ratio
            }

            if (height > width) {
                newHeight = width / ratio
            }
        }

        EOrientation.VERTICAL -> {
            newHeight = width / ratio
        }

        EOrientation.HORIZONTAL -> {
            newWidth = height * ratio
        }
    }

    return Position(0, 0, newWidth.toInt(), newHeight.toInt())
}

// Вычисляет текущие линии привязки в зависимости от смены якоря в движении
fun calculateAnchorLine(
    transform: dynamic,
    guideLine: LineGuide,
    bounds: Position,
    effect: StateSetter<LineGuide>,
    startAnchor: String
): LineGuide {
    val selectedAnchor = transform.current._movingAnchorName
    val anchorLine = anchorToPreferredLine(selectedAnchor, guideLine.orientation)

    var line = getObjectAnchorEdge(
        bounds,
        getOppositeAnchor(anchorLine),
        guideLine.orientation
    )

    if (!startAnchor.equals("")) {
        line = guideLine.clone(line.snap)
    }

    effect(line)
    return line
}

// Во время трансформации меняет баунды подсказки
fun onTransformSetHint(target: dynamic, effect: StateSetter<Rect>) {
    effect(
        Rect(
            leftTopPosition = online.interactiver.common.interactivepicture.Position(
                absolutePosition = Point(0, 0)
            ), width = target.width(), height = target.height()
        )
    )
}

// Скрывает линии подсказок
fun hideGuideLines(
    horizontalRef: MutableRefObject<dynamic>,
    verticalRef: MutableRefObject<dynamic>
) {
    horizontalRef.current.opacity(0)
    verticalRef.current.opacity(0)
}

// Магнитит объект к линиям при трансформации
fun setTargetShapeByGuide(
    guides: List<LineGuide>,
    target: dynamic,
    anchorVerticalLine: LineGuide,
    anchorHorizontalLine: LineGuide
): EOrientation {
    var result = EOrientation.NONE
    guides.forEach { guide ->
        val anchorGuide = if (guide.orientation == EOrientation.VERTICAL) anchorVerticalLine else anchorHorizontalLine

        if (anchorGuide.snap == ESnapType.SKIP) {
            return@forEach
        }

        if (guide.orientation == EOrientation.HORIZONTAL) {
            target.height(max(abs(anchorGuide.position - guide.position), 10))
            result = EOrientation.HORIZONTAL
        } else {
            target.width(max(abs(anchorGuide.position - guide.position), 10))
            result = EOrientation.VERTICAL
        }

    }
    return result
}


// Магнитит модифицируемый объект к линиям при драге
fun setTargetPositionByGuide(
    guides: List<LineGuide>, target: dynamic,
) {

    guides.forEach { guide ->
        if (guide.orientation == EOrientation.HORIZONTAL) {
            target.y(guide.position - guide.offset)
        } else {
            target.x(guide.position - guide.offset)
        }
    }
}

fun setTargetsPositionUnderSelectionByGuide(
    guides: List<LineGuide>,
    targets: List<dynamic>,
    parentTr: dynamic
) {
    val x = parentTr.x()
    val y = parentTr.y()
    targets.forEach { target ->
        guides.forEach { guide ->
            if (guide.orientation == EOrientation.HORIZONTAL) {
                target.y(target.y() - y + guide.position - guide.offset)
            } else {
                target.x(target.x() - x + guide.position - guide.offset)
            }
        }
    }
}