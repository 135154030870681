package pages.constructor.ui.components.header.previewShare.previewModal.previewRightBar

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    position = Position.relative
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 32.px
    height = 100.pct
    overflow = Auto.auto
    width = 30.pct.minus(16.px)
}