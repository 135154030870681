package entities.interactivePicture.elements.editors.checkmarkEditor

import csstype.*
import utils.types.CssStyle

val text: CssStyle = {
    marginTop = 0.px
    marginLeft = 20.px
    marginBottom = 8.px
    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = FontWeight.normal
    fontSize = 12.px
    lineHeight = 130.pct
    color = Color("#101828")
}

val container: CssStyle = {
    marginLeft = 20.px
    marginBottom = 10.px
}
val containerBottom: CssStyle = {
    marginLeft = 20.px
    marginBottom = 20.px
}

val selector: CssStyle = {
    width = 210.px
    height = 35.px
}