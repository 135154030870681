package widgets.Exercises

import ApiResponse
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.serialization.json.Json
import online.interactiver.common.autogeneration.*
import online.interactiver.common.enums.EAccessType
import utils.getToken

suspend fun getLanguageAutoRequests(
    filters: GenerationsWithFiltersRequest, userId: Int? = null
): GenerationsWithFiltersResponse? {
    val accessUrl = if (filters.accessType == EAccessType.PUBLIC.value) {
        "/public"
    } else if(filters.accessType == EAccessType.PUBLIC_OF_USER.value && userId != null) {
        "/user/$userId"
    } else if (filters.accessType == EAccessType.SHARED.value) {
        "/shared"
    } else {
        ""
    }
    return try {
        val response = jsonClient.post("api/v2/auto-generation/language-slider/requests$accessUrl") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(filters)
        }

        if (response.status.value != 200) {
            return null
        }

        Json.decodeFromString(response.bodyAsText())
    } catch (e: ResponseException) {
        return null
    }
}

suspend fun deleteFoldersAndExercisesRequest(foldersAndExercises: List<FoldersAndExercisesRequest>): Boolean {
    return try {
        val response = jsonClient.delete("api/v2/auto-generation/folder/batch") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(foldersAndExercises)
        }

        response.status.value == 200
    } catch (e: ResponseException) {
        false
    }
}

suspend fun deleteRequest(requestId: Int): Boolean {
    return try {
        val response = jsonClient.delete("api/v2/auto-generation/language-slider/requests/$requestId") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        response.status.value == 200
    } catch (e: ResponseException) {
        false
    }
}

suspend fun getLanguageAutoRequest(id: Int, isPublic: Boolean): LanguageAutoState? {
    val accessUrl = if (isPublic) "/public" else ""
    return try {
        val response = jsonClient.get("api/v2/auto-generation/language-slider/requests/$id$accessUrl") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return null
        }

        Json.decodeFromString(response.bodyAsText())
    } catch (e: ResponseException) {
        null
    }
}

suspend fun createLanguageAutoRequestBasedOn(id: Int, accessType: EAccessType? = null): ApiResponse<LanguageAutoState> {
    return try {
        val accessTypeUrl = if (accessType == EAccessType.SHARED) "?access_type=${accessType.value}" else ""
        val response = jsonClient.get("api/v2/auto-generation/language-slider/requests/$id/new$accessTypeUrl") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return ApiResponse(null, response.status.value)
        }

        ApiResponse(Json.decodeFromString(response.bodyAsText()), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}

suspend fun updateSliderAccessType(id: Int, isPublic: Boolean): Boolean {
    return try {
        val response = jsonClient.post("api/v2/auto-generation/language-slider/requests/$id/update-access-type") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(UpdateAccessTypeRequest(id, isPublic))
        }

        return response.status.value == 200
    } catch (e: ResponseException) {
        false
    }
}

suspend fun getFolderWithAncestors(id: Int): ApiResponse<List<SliderAutoGenerationFolder>> {
    return try {
        val response = jsonClient.get("api/v2/auto-generation/folder/$id/with-ancestors") {
            bearerAuth(getToken())
        }

        ApiResponse(Json.decodeFromString(response.bodyAsText()), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}

suspend fun createNewFolder(parentFolderId: Int?, folderName: String): ApiResponse<Int> {
    return try {
        val data = formData {
            append("name", folderName)
            parentFolderId?.let { append("parent_folder_id", it) }
        }

        val response = jsonClient.submitFormWithBinaryData("api/v2/auto-generation/folder/create", data) {
            bearerAuth(getToken())
        }

        ApiResponse(Json.decodeFromString(response.bodyAsText()), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}

suspend fun renameFolder(folderId: Int, newName: String): HttpStatusCode {
    return try {
        val data = formData {
            append("new_name", newName)
        }

        val response = jsonClient.submitFormWithBinaryData("api/v2/auto-generation/folder/$folderId/rename", data) {
            bearerAuth(getToken())
        }

        response.status
    } catch (e: ResponseException) {
        e.response.status
    }
}

suspend fun deleteFolder(folderId: Int): HttpStatusCode {
    return try {
        val response = jsonClient.delete("api/v2/auto-generation/folder/$folderId/delete-soft") {
            bearerAuth(getToken())
        }

        response.status
    } catch (e: ResponseException) {
        e.response.status
    }
}

suspend fun moveFolder(sourceId: Int, destinationId: Int?): HttpStatusCode {
    return try {
        val data = formData {
           destinationId?.let { append("destination_id", it) }
        }

        val response = jsonClient.submitFormWithBinaryData("api/v2/auto-generation/folder/$sourceId/move", data) {
            bearerAuth(getToken())
        }

        response.status
    } catch (e: ResponseException) {
        e.response.status
    }
}

suspend fun moveExercise(sourceId: Int, destinationId: Int?): HttpStatusCode {
    return try {
        val data = formData {
            destinationId?.let { append("destination_id", it) }
        }

        val response = jsonClient.submitFormWithBinaryData("api/v2/auto-generation/language-slider/requests/$sourceId/move", data) {
            bearerAuth(getToken())
        }

        response.status
    } catch (e: ResponseException) {
        e.response.status
    }
}

suspend fun moveFoldersAndExercises(foldersAndExercises: List<FoldersAndExercisesRequest>, destinationId: Int?): HttpStatusCode {
    return try {
        val response = jsonClient.post(
            "api/v2/auto-generation/folder/batch/$destinationId"
        ) {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(foldersAndExercises)
        }

        response.status
    } catch (e: ResponseException) {
        e.response.status
    }
}

suspend fun moveExerciseInsideFolder(requestId: Int, moveUp: Boolean): Boolean {
    return try {
        val response = jsonClient.put("/api/v2/auto-generation/language-slider/requests/${requestId}/move-inside-folder") {
            bearerAuth(getToken())
            setBody(FormDataContent(Parameters.build {
                append("move_up", moveUp.toString())
            }))
        }

        response.status.value == 200
    } catch (e: ResponseException) {
        false
    }
}
