package widgets.languageAutoTask.ui.components.AddCustomSlideButton

import Modal
import antd.Button
import csstype.*
import emotion.react.css
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import shared.components.Icon
import utils.types.FitContent
import widgets.LanguageAutoTask.ui.genMoreSlidesButton

external interface AddCustomSlideButtonProps : Props {
    var onClick: ((String) -> Unit)?
}

enum class SlideType(
    val title: String,
    val imageSrc: String,
    val taskToAppend: String,
) {
    FOUR_CHOICE_QUESTION(
        title = "4-choice question",
        imageSrc = "four_choice_question.png",
        taskToAppend = "'Word Matching'. 'short_buttons'. Question; Answer1 - T; Answer2 - F; Answer3 - F; Answer4 - F"
    ),
    WORD_MATCHING_FOUR_WORDS(
        title = "Word matching: 4 words",
        imageSrc = "word_matching_four_words.png",
        taskToAppend = "'Word Matching'. 'short_selectors'. word1 - match1; word2 - match2; word3 - match3; word4 - match4"
    ),
    SENTENCE_BUILDING(
        title = "Sentence building",
        imageSrc = "sentence_building.png",
        taskToAppend = "'Build Sentence'. 'whole'. This is {true answer*/ wrong answer} and it’s {also true*/ also wrong};Select true answers"
    ),
    THREE_CHOICE_QUESTION(
        title = "3-choice question",
        imageSrc = "three_choice_question.png",
        taskToAppend = "'Word Matching'. 'long_buttons'. Question; Answer1 - T; Answer2 - F; Answer3 - F"
    ),
    WORD_MATCHING_THREE_WORDS(
        title = "Word matching: 3 words",
        imageSrc = "word_matching_three_words.png",
        taskToAppend = "'Word Matching'. 'long_selectors'. word1 - match1; word2 - match2; word3 - match3"
    ),
}

val AddCustomSlideButton = FC<AddCustomSlideButtonProps> { props ->
    val (modalIsOpened, setModalIsOpened) = useState(false)

    Button {
        css(genMoreSlidesButton)
        ghost = true
        Icon {
            src = "ic_plus_blue_32x32.svg"
        }
        +"Add custom slide"
        onClick = onClick@{
            setModalIsOpened(true)
        }
    }

    Modal {
        open = modalIsOpened
        onCancel = {
            setModalIsOpened(false)
        }
        width = 1300.px
        footer = null
        div {
            css(globContainer)
            div {
                css(modalTitle)
                +"Choose exercise type"
            }
            div {
                css(grid)
                SlideType.entries.map { slide ->
                    div {
                        css(card)
                        img {
                            css(image)
                            src = kotlinext.js.require("./images/${slide.imageSrc}").default
                        }
                        div {
                            css(cardLabel)
                            +slide.title
                        }
                        onClick = {
                            setModalIsOpened(false)
                            props.onClick?.invoke(slide.taskToAppend)
                        }
                    }
                }
            }
        }
    }
}
