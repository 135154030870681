package builders.enums

enum class ESoundBadgePosition(val textUi: String, val textValue: String,
                               val offset: Double, val padding: Double,
                               val align: ESoundBadgeAlign) {
    // textUi and textValue separated make it possible to change UI names of positions
    // Keep textValue unchanged to make generated JSONs work forever!
    LEFT("Left", "Left", -0.1, 0.1, ESoundBadgeAlign.LEFT),
    RIGHT("Right", "Right", -0.1, 0.1, ESoundBadgeAlign.RIGHT),
    LEFT_LINE("Left line", "LeftLine", 0.6, 0.05, ESoundBadgeAlign.LEFT),
    RIGHT_LINE("Right line", "RightLine", 0.6, 0.05, ESoundBadgeAlign.RIGHT);

    companion object {
        val DEFAULT = RIGHT_LINE
        fun fromString(string: String?): ESoundBadgePosition {
            return ESoundBadgePosition.values().find { it.textValue == string } ?: DEFAULT
        }
    }
}
