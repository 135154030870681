package widgets.CourseSettings.ui

import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.section
import shared.components.header.Header
import widgets.CourseSettings.ui.components.LanguagesSettings.LanguagesSettings
import widgets.CourseSettings.ui.components.SliderSettings.SliderSettings

val CourseSettings = FC<Props> {
    section {
        css(courseSettings)
        Header {
            text = "1. Choose the language for your course"
        }
        LanguagesSettings {}
        SliderSettings {}
    }
}
