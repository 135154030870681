package pages.constructor.ui.components.header.templates.preview

import antd.Button
import app.useAppDispatch
import app.useAppSelector
import emotion.react.css
import entities.backgroundPosScale.selectCanvasContainerHeight
import entities.backgroundPosScale.selectCanvasContainerWidth
import entities.interactivePicture.SetInteractivePicture
import entities.interactivePicture.background.EditBackgroundWithRatio
import entities.interactivePicture.background.selectCanvasMaxWidth
import enums.EButtonTypes
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import online.interactiver.common.api.TagColor
import online.interactiver.common.api.TemplateMeta
import pages.constructor.ui.components.header.templates.tag.TemplateTag
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.components.Icon
import utils.codeToInteractivePicture
import utils.structures.Point
import kotlin.math.min

external interface TemplatePreviewProps : Props {
    var templateMeta: TemplateMeta
    var setCurTemplate: (template: TemplateMeta?) -> Unit
    var setIsOpen: (flag: Boolean) -> Unit
    var tags: MutableList<TagColor>
}


val TemplatePreview = FC<TemplatePreviewProps> { props ->

    val dispatch = useAppDispatch()
    val canvasContainerWidth = useAppSelector(selectCanvasContainerWidth)
    val canvasContainerHeight = useAppSelector(selectCanvasContainerHeight)
    val canvasMaxWidth = useAppSelector(selectCanvasMaxWidth)

    div {
        css(container)
        Button {
            css(backButton)
            id = EButtonTypes.BACK_TO_TEMPLATES_BUTTON.value
            Icon {
                src = "ic_back_arrow_24x24.svg"
            }
            onClick = {
                props.setCurTemplate(null)
            }
            +"Back to templates"
            ghost = true
            type = "text"
        }
        div {
            css(table)
            div {
                css(leftColumn)
                div {
                    css(header)
                    +props.templateMeta.name
                }
                TemplateWindow {
                    templateMeta = props.templateMeta
                }
            }
            div {
                css(rightColumn)
                div {
                    css(verticalContainer)
                    div {
                        css(header)
                        +"Overview"
                    }
                    div {
                        css(descText)
                        +props.templateMeta.description
                    }
                }
                div {
                    css(verticalContainer)
                    div {
                        css(header)
                        +"Use case"
                    }
                    div {
                        css(descText)
                        +"Use case name"
                    }
                }
                div {
                    css(tagContainer)
                    props.templateMeta.tags.forEach {
                        val color = props.tags.filter { tag -> tag.tagName == it }
                        TemplateTag {
                            tagName = it
                            tagColor = if (color.isNotEmpty()) color.first().color else "#D9D9D9"
                            tagTextColor = if (color.isNotEmpty()) color.first().textColor else "#737373"
                        }
                    }
                }
                Button {
                    css(buttonUse)
                    onClick = {
                        MainScope().launch {
                            val newInteractivePicture = codeToInteractivePicture(props.templateMeta.code)
                            dispatch(EditBackgroundWithRatio(
                                    min(canvasMaxWidth ?: canvasContainerWidth, canvasContainerWidth),
                                    canvasContainerHeight,
                                    Point(newInteractivePicture.background.width ?: 16, newInteractivePicture.background.height ?: 9)
                                )
                            )
                            dispatch(SetInteractivePicture(newInteractivePicture, true))
                            props.setCurTemplate(null)
                            props.setIsOpen(false)
                        }
                    }
                    +"Use this template"
                }
            }
        }
    }
}