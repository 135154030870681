package pages.constructor.ui.components.header.styleSettings.dashPicker

import antd.Button
import emotion.react.css
import pages.constructor.ui.components.header.styleSettings.secondary
import pages.constructor.ui.components.header.styleSettings.weightPicker.button
import pages.constructor.ui.components.header.styleSettings.weightPicker.buttonActive
import pages.constructor.ui.components.header.styleSettings.weightPicker.container
import pages.constructor.ui.components.header.styleSettings.weightPicker.iconsContainer
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.key
import shared.components.Icon

external interface DashPickerProps : Props {
    var value: Array<Double>?
    var lineLine: Array<Double>?
    var lineDot: Array<Double>?
    var dotDot: Array<Double>?
    var onChange: ((Array<Double>?) -> Unit)?
}

val DashPicker = FC<DashPickerProps> { props ->
    div {
        css(container)
        div {
            css(secondary)
            +"Dash"
        }
        div {
            css(iconsContainer)
            class Dash(val dash: Array<Double>?, val iconSrc: String)
            arrayOf(
                Dash(null, "ic_border_light_24x24.svg"),
                Dash(props.lineLine ?: arrayOf(30.0, 10.0), "ic_dash_line_line_24x24.svg"),
                Dash(props.lineDot ?: arrayOf(30.0, 10.0, 5.0, 10.0), "ic_dash_line_dot_24x24.svg"),
                Dash(props.dotDot ?: arrayOf(5.0, 5.0), "ic_dash_dot_dot_24x24.svg"),
            ).map {
                Button {
                    css(if (props.value.contentEquals(it.dash)) buttonActive else button)
                    key = it.iconSrc
                    Icon {
                        src = it.iconSrc
                    }
                    onClick = onClick@{
                        if (props.value.contentEquals(it.dash)) {
                            return@onClick
                        }
                        props.onChange?.invoke(it.dash)
                    }
                }
            }
        }
    }
}