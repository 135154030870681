package pages.constructor.ui.components.advancedElementsSettings.advancedPuzzleSettings

import csstype.AlignItems
import csstype.Display
import csstype.FlexDirection
import csstype.px
import utils.types.CssStyle

val checkboxContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    gap = 10.px
    alignItems = AlignItems.center
}
