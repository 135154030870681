package entities.interactivePicture.elements.other

import app.StoreState
import builders.enums.EPhraseType
import builders.getPhraseBuilder
import entities.interactivePicture.elements.CommonInteractiveElementAction
import entities.interactivePicture.elements.CommonListReducer
import entities.interactivePicture.elements.InteractiveElementAction
import entities.interactivePicture.elements.addElementToList
import entities.interactivePicture.selectInteractivePicture
import online.interactiver.common.interactivepicture.*
import online.interactiver.common.utils.clone
import online.interactiver.common.utils.getTimeMillisInBase
import online.interactiver.common.utils.splitToPhraseWords
import utils.structures.Position

data class AddSentenceBuilderPhrase(val phrase: String, val position: Position,
                                    val style: PhraseStyle, val id: String = getTimeMillisInBase()) :
    InteractiveElementAction(false)

data class SetPhraseText(val id: String, val text: String) : InteractiveElementAction(false)

fun addPhraseToList(list: MutableList<InteractiveElement>, phrase: Phrase, id: String): MutableList<InteractiveElement> {
    val builder = getPhraseBuilder().setPhrase(phrase).setID(id).addScore(ScoreData(DEFAULT_SCORE, null))

    return addElementToList(list, builder.build())
}

fun setPhraseTextOfList(list: MutableList<InteractiveElement>, id: String, text: String): MutableList<InteractiveElement> {
    val newList = list.clone()
    val elementIndex = newList.indexOfFirst { it.identifier.id.equals(id) }
    if (elementIndex == -1) return newList

    newList[elementIndex] = newList[elementIndex].copy(
        phrase = newList[elementIndex].phrase?.copy(
            words = text.splitToPhraseWords()
        )
    )

    return newList
}

const val PHRASE_CUSTOM_SEPARATOR = '|'

val OthersReducer = { state: MutableList<InteractiveElement>, action: InteractiveElementAction ->
    when (action) {
        is CommonInteractiveElementAction -> CommonListReducer(state, action)
        is AddSentenceBuilderPhrase -> {
            val words = action.phrase.splitToPhraseWords()
            addPhraseToList(
                state,
                Phrase(
                    EPhraseType.SENTENCE_BUILDER.value, action.style,
                    Point(action.position.x, action.position.y), action.position.width,
                    words
                ),
                action.id
            )
        }
        is SetPhraseText -> setPhraseTextOfList(state, action.id, action.text)

        else -> state
    }
}

val selectOthers = { state: StoreState -> selectInteractivePicture(state).others ?: mutableListOf() }
