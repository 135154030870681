package pages.constructor.ui.components.viewers

import app.appState.selectAppState
import app.useAppSelector
import builders.enums.EElementGroup
import emotion.react.css
import entities.interactivePicture.viewer.ElementViewerPanel
import enums.EButtonTypes
import react.*
import react.dom.html.ReactHTML.div

enum class ViewersTabs {
    Static, Control, Puzzle, Other
}

external interface ViewersProps : Props {
    var stageRef: MutableRefObject<dynamic>
}

val Viewers = FC<ViewersProps> { props ->
    val (activeTab, setActiveTab) = useState(ViewersTabs.Static)

    val appState = useAppSelector(selectAppState)

    useEffect(appState::class) {
        setActiveTab(appState.getDefaultViewerTab())
    }

    div {
        css(tabsContainer)
        antd.Button {
            css(if (activeTab == ViewersTabs.Static) tabActive else tabInactive)
            id = EButtonTypes.STATIC_FILTER_TAB.value
            disabled = appState.getStaticViewerIsDisabled()
            +"Static"
            onClick = { setActiveTab(ViewersTabs.Static) }
        }
        antd.Button {
            css(if (activeTab == ViewersTabs.Control) tabActive else tabInactive)
            id = EButtonTypes.CONTROL_FILTER_TAB.value
            disabled = appState.getControlViewerIsDisabled()
            +"Controls"
            onClick = { setActiveTab(ViewersTabs.Control) }
        }
        antd.Button {
            css(if (activeTab == ViewersTabs.Puzzle) tabActive else tabInactive)
            id = EButtonTypes.PUZZLE_FILTER_TAB.value
            disabled = appState.getPuzzleViewerIsDisabled()
            +"Puzzle"
            onClick = { setActiveTab(ViewersTabs.Puzzle) }
        }
        antd.Button {
            css(if (activeTab == ViewersTabs.Other) tabActive else tabInactive)
            id = EButtonTypes.OTHER_FILTER_TAB.value
            disabled = appState.getOtherViewerIsDisabled()
            +"Other"
            onClick = { setActiveTab(ViewersTabs.Other) }
        }
    }
    when (activeTab) {
        ViewersTabs.Static -> ElementViewerPanel {
            group = appState.getStaticGroup()
            this.stageRef = props.stageRef
        }

        ViewersTabs.Control -> ElementViewerPanel {
            group = EElementGroup.CONTROLS
            this.stageRef = props.stageRef
        }

        ViewersTabs.Puzzle -> ElementViewerPanel {
            group = EElementGroup.PUZZLE
            this.stageRef = props.stageRef
        }

        ViewersTabs.Other -> ElementViewerPanel {
            group = EElementGroup.OTHER
            this.stageRef = props.stageRef
        }
    }
}
