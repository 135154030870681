package widgets.CourseSettings.ui.components.SliderSettings

import antd.Input
import app.useAppDispatch
import csstype.px
import emotion.react.css
import features.EnableContinueButton.ui.EnableButtonWithTextAndLink
import kotlinx.browser.localStorage
import online.interactiver.common.course.maxMinNumberOfSlidersInCourse
import online.interactiver.common.course.maxNumberOfSlidesInSlider
import online.interactiver.common.course.minMinNumberOfSlidersInCourse
import online.interactiver.common.course.minNumberOfSlidesInSlider
import online.interactiver.common.enums.ECourseLevel
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import react.useState
import shared.components.header.Header
import shared.components.inputNumberWithPlusAndMinus.EInputNumberWithPlusAndMinusVariant
import shared.components.inputNumberWithPlusAndMinus.InputNumberWithPlusAndMinus
import shared.components.selectWithLabel.SelectWithLabel
import shared.components.switchWIthLabelAndTooltip.SwitchWithLabelAndTooltip
import widgets.CourseSettings.*
import widgets.CourseSettings.ui.components.LanguagesSettings.select

enum class ESliderSettingsLocalStorageKeys(val value: String) {
    NUMBER_OF_SLIDES_IN_SLIDER("course_number_of_slides_in_slider"),
    COURSE_LEVEL("course_level"),
    CONTINUE_BUTTON_LINK("course_continue_button_link"),
    CONTINUE_BUTTON_TEXT("continue_button_text"),
    ENABLE_GAMIFIED_POPUPS("course_enable_gamified_popups"),
    START_FROM_SENTENCES("course_start_from_sentences"),
    NUMBER_OF_SLIDERS_IN_COURSE("course_number_of_sliders_in_course")
}

val SliderSettings = FC<Props> {
    val numberOfSlidesInSlider = useSelector(selectCourseNumberOfSlidesInSlider)
    val courseLevel = useSelector(selectCourseLevel)
    val continueButtonLink = useSelector(selectCourseContinueButtonLink)
    val continueButtonText = useSelector(selectCourseContinueButtonText)
    val enableGamifiedPopups = useSelector(selectCourseEnableGamifiedPopup)
    val startFromSentences = useSelector(selectCourseStartFromSentences)
    val numberOfSliders = useSelector(selectCourseNumberOfSliders)

    val dispatch = useAppDispatch()

    val (enableContinueButton, setEnableContinueButton) = useState(false)

    useSliderSettingsFromLocalStorageEffect {
        setEnableContinueButton(it)
    }

    div {
        css(horizontalContainer)
        InputNumberWithPlusAndMinus {
            value = numberOfSliders
            min = minMinNumberOfSlidersInCourse
            max = maxMinNumberOfSlidersInCourse
            label = "Number of sliders"
            onChange = {
                dispatch(SetCourseNumberOfSliders(it))
            }
            onAfterChange = {
                dispatch(SetCourseNumberOfSliders(it))
                localStorage.setItem(ESliderSettingsLocalStorageKeys.NUMBER_OF_SLIDERS_IN_COURSE.value, it.toString())
            }
            variant = EInputNumberWithPlusAndMinusVariant.PRIMARY
        }
        InputNumberWithPlusAndMinus {
            value = numberOfSlidesInSlider
            min = minNumberOfSlidesInSlider
            max = maxNumberOfSlidesInSlider
            label = "Number of slides in slider"
            onChange = {
                dispatch(SetCourseNumberOfSlidesInSlider(it))
            }
            onAfterChange = {
                dispatch(SetCourseNumberOfSlidesInSlider(it))
                localStorage.setItem(ESliderSettingsLocalStorageKeys.NUMBER_OF_SLIDES_IN_SLIDER.value, it.toString())
            }
            variant = EInputNumberWithPlusAndMinusVariant.PRIMARY
        }
        div {
            css(select)
            SelectWithLabel {
                value = courseLevel
                onChange = {
                    dispatch(SetCourseLevel(it))
                    localStorage.setItem(ESliderSettingsLocalStorageKeys.COURSE_LEVEL.value, it)
                }
                options = ECourseLevel.entries.map { it.value }
                label = "Number of repetitions"
            }
        }
    }
    div {
        css(verticalContainer)
        SwitchWithLabelAndTooltip {
            label = "Start sliders from sentences"
            tooltipHint = "TODO"
            checked = startFromSentences
            onChange = {
                localStorage.setItem(ESliderSettingsLocalStorageKeys.START_FROM_SENTENCES.value, it.toString())
                dispatch(SetCourseStartFromSentences(it))
            }
        }
        SwitchWithLabelAndTooltip {
            label = "Enable gamified popups"
            tooltipHint = "“Gamified popups“ are popups after each 5th slide with some entertaining data."
            checked = enableGamifiedPopups
            onChange = {
                dispatch(SetCourseEnableGamifiedPopups(it))
            }
        }
        EnableButtonWithTextAndLink {
            label = "Enable “continue”  button"
            tooltipHint =
                "“Continue” button contains a link that leads to additional exercises or any other relevant content you provide."
            enableButton = enableContinueButton
            onEnableButtonChange = { setEnableContinueButton(it) }
            buttonText = continueButtonText
            onButtonTextChange = {
                localStorage.setItem(ESliderSettingsLocalStorageKeys.CONTINUE_BUTTON_TEXT.value, it)
                dispatch(SetCourseContinueButtonText(it))
            }
            buttonTextPlaceholder = "“Continue” button name"
            buttonLink = continueButtonLink
            onButtonLinkChange = {
                localStorage.setItem(ESliderSettingsLocalStorageKeys.CONTINUE_BUTTON_LINK.value, it)
                dispatch(SetCourseContinueButtonLink(it))
            }
            buttonLinkPlaceholder = "“Continue” button link"
        }
    }
}