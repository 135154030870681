package widgets.Exercises.ui.components.StatisticsModal.components.CustomTooltip

import emotion.react.css
import react.FC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import recharts.CustomTooltipProps
import widgets.Exercises.ui.components.StatisticsModal.ECharts

val CustomTooltip = FC<CustomTooltipProps> { props ->
    val allViews = props.payload?.firstOrNull { it.dataKey == ECharts.VIEWS.type }
    val successfulAttempts = props.payload?.firstOrNull { it.dataKey == ECharts.SUCCESSFUL_ATTEMPTS.type }
    if (props.active != true || props.label == null || allViews == null || successfulAttempts == null) {
        return@FC
    }

    val numberOfAllViews = allViews.value?.toInt()?.plus(successfulAttempts.value?.toInt() ?: 0)

    div {
        css(container)
        p {
            css(header)
            +"Slide ${props.label}"
        }
        p {
            css(bar(allViews.color))
            +"${ECharts.VIEWS.legendLabel}: $numberOfAllViews"
        }
        p {
            css(bar(successfulAttempts.color))
            +"${ECharts.SUCCESSFUL_ATTEMPTS.legendLabel}: ${successfulAttempts.value}"
        }
    }
}
