package entities.solvedTasks.ui

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    width = 100.pct
    backgroundColor = Color("#597EF7")
    display = Display.flex
    alignItems = AlignItems.center
    gap = 5.px
    justifyContent = JustifyContent.spaceBetween
    padding = Padding(20.px, 13.px)
    borderTopLeftRadius = "inherit".unsafeCast<BorderTopLeftRadius>()
    borderTopRightRadius = "inherit".unsafeCast<BorderTopRightRadius>()
    boxSizing = BoxSizing.borderBox
}

val text: CssStyle = {
    margin = 0.px
    color = Color("white")
    fontWeight = integer(500)
}
