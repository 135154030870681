package online.interactiver.common.api

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class AttemptLogScores(
    @SerialName("score")
    val score: Int = 0,
    @SerialName("targetScore")
    val targetScore: Int = 0,
    @SerialName("scoreCode")
    val scoreCode: String = ""
) : Comparable<AttemptLogScores> {
    override fun compareTo(other: AttemptLogScores): Int {
        return when {
            this == other -> 0
            this.score.toDouble()/this.targetScore.toDouble() > other.score.toDouble()/other.targetScore.toDouble() -> 1
            else -> -1
        }
    }

}
