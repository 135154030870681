package pages.constructor.ui.components.loadingDots
import emotion.react.css
import kotlinx.js.timers.clearInterval
import kotlinx.js.timers.setInterval
import react.VFC
import react.dom.html.ReactHTML.span
import react.useEffect
import react.useState
import kotlin.time.Duration
import kotlin.time.Duration.Companion.milliseconds
val LoadingDots = VFC{
    span {
        val (dots, setDots) = useState(3)
        useEffect {
            val period: Duration = 333.milliseconds
            val interval = setInterval(period) {
                setDots((dots + 1) % 4)
            }
            cleanup {
                clearInterval(interval)
            }
        }
        css(loadingDotsStyle)
        +".".repeat(dots)
    }
}