package entities.modalLoader

import app.StoreState
import react.FC
import react.Props
import react.ReactNode
import react.createElement
import redux.RAction

data class ModalLoaderState(
    val loading: Boolean,
    val text: String?,
    val showSuccessfulLoading: Boolean = false,
    val subText: ReactNode? = createElement(FC { +"This process might take about a minute. Please wait..." })
)
open class ModalLoaderAction : RAction
data class StartModalLoading(val text: String) : ModalLoaderAction()

data class ChangeModalLoadingText(val text: String) : ModalLoaderAction()

data class ShowSuccessfulLoading(override val text: String, val subText: String) : ShowSuccessfulLoadingImpl(text, createElement(FC { +subText }))

open class ShowSuccessfulLoadingImpl(open val text: String, val sub: ReactNode?) : ModalLoaderAction()

class EndModalLoading: ModalLoaderAction()

val ModalLoaderReducer = { state: ModalLoaderState, action: ModalLoaderAction ->
    when (action) {
        is ShowSuccessfulLoadingImpl -> ModalLoaderState(
            loading = true,
            action.text,
            showSuccessfulLoading = true,
            action.sub
        )
        is StartModalLoading -> ModalLoaderState(true, action.text)
        is ChangeModalLoadingText -> ModalLoaderState(state.loading, action.text)
        is EndModalLoading -> ModalLoaderState(false, null)
        else -> state
    }
}

val selectModalLoader = { store: StoreState ->
    store.modalLoader
}