package widgets.languageAutoTask.ui.components.SlideEditModal.taskUiLineBuilders.components.ThreeLongPairsTaskLineEditor.components.WordMatchLongPairEditor

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.stretch
    flexDirection = FlexDirection.column
    gap = 5.px
    width = 100.pct
}

val input: CssStyle = {
    width = 100.pct
}
