package utils

import org.w3c.dom.HTMLInputElement
import org.w3c.dom.Image
import org.w3c.dom.asList
import org.w3c.dom.events.EventTarget
import org.w3c.files.FileReader
import react.dom.events.ChangeEvent

val EventTarget?.result: String?
    get() = asDynamic().result

val fileToBase64 = { onLoad: (String?, String?) -> Unit ->
    { evt: ChangeEvent<HTMLInputElement> ->
        evt.target.files?.asList()?.forEach { file ->
            val fileReader = FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = {
                onLoad(it.target.result, file.name)
            }
        }
        evt.target.value = ""
    }
}

val fileToString = { onLoad: (String?, String?) -> Unit ->
    { evt: ChangeEvent<HTMLInputElement> ->
        evt.target.files?.asList()?.forEach { file ->
            val fileReader = FileReader()
            fileReader.readAsText(file)
            fileReader.onload = {
                onLoad(it.target.result, file.name)
            }
        }
        evt.target.value = ""
    }
}

val fileToBase64WithMaxFileSizeInMb = { onOversize: () -> Unit, maxFileSizeInMb: Double, onLoad: (String?, String?) -> Unit ->
    { evt: ChangeEvent<HTMLInputElement> ->
        evt.target.files?.asList()?.forEach { file ->
            if (file.size.toInt() > maxFileSizeInMb * 1024 * 1024) {
                onOversize()
                return@forEach
            }
            val fileReader = FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = {
                onLoad(it.target.result, file.name)
            }
        }
        evt.target.value = ""
    }
}

val fileToPicture = { onLoad: (String?, String?, Double?) -> Unit ->
    { evt: ChangeEvent<HTMLInputElement> ->
        evt.target.files?.asList()?.forEach { file ->
            val fileReader = FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = {
                val image = Image()
                val base64 = it.target.result
                image.onload = {
                    val ratio = image.height.toDouble() / image.width.toDouble()
                    onLoad(base64, file.name, ratio)
                }

                image.src = base64!!
                true
            }
        }
        evt.target.value = ""
    }
}
