package online.interactiver.common.admin.interactive

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class InteractivesGroup(
    val id: Int? = null,
    var name: String,
    @SerialName("owner_id")
    val ownerId: Int,
    @SerialName("creation_time")
    val creationTime: Long?,
    @SerialName("is_public")
    var isPublic: Boolean = false,
    @SerialName("dictionary_id")
    var dictionaryId: Int?
)