package pages.languageAuto

import AdvancedResponse
import ApiResponse
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.serialization.json.Json
import online.interactiver.common.admin.useraccount.LimitSummaryResponse
import online.interactiver.common.api.translation.TranslationRequest
import online.interactiver.common.api.translation.Translator
import online.interactiver.common.enums.ELanguage
import online.interactiver.common.user.UserProfile
import online.interactiver.common.user.UserSliderSettings
import utils.getToken

suspend fun getNumberOfFreeGenerations(): LimitSummaryResponse? {
    return try {
        val response = jsonClient.get("/api/v1/user-account/limit-summary") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return null
        }

        return Json.decodeFromString(response.bodyAsText())
    } catch (_: ResponseException) {
        null
    }
}

suspend fun getTariffPlanRequest(): String? {
    return try {
        val response = jsonClient.get("/api/v1/user-account/tariff-plan") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return null
        }

        response.bodyAsText()
    } catch (e: ResponseException) {
        null
    }
}

suspend fun getUserProfileRequest(userId: Int? = null): ApiResponse<UserProfile> {
    return try {
        val response = jsonClient.get("/api/v1/user-account${if (userId != null) "/$userId" else ""}") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return ApiResponse(null, response.status.value)
        }

        return ApiResponse(Json.decodeFromString(response.bodyAsText()), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}

suspend fun getUserSliderSettingsRequest(): UserSliderSettings? {
    val response = jsonClient.get("/api/v1/user-account/slider-settings") {
        bearerAuth(getToken())
        contentType(ContentType.Application.Json)
    }

    if (response.status.value != 200) {
        return null
    }

    return Json.decodeFromString(response.bodyAsText())
}

suspend fun getNumberOfGenerations(): Int? {
    return try {
        val response = jsonClient.get("api/v2/auto-generation/language-slider/number-of-generations") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return null
        }

        response.bodyAsText().toIntOrNull()
    } catch (e: ResponseException) {
        null
    }
}

