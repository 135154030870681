package entities.interactivePicture.elements.gapPuzzles.drags

import app.StoreState
import app.appState.selectFocusedOptionId
import builders.ElementBuilder
import builders.enums.EElementType
import builders.enums.EFrameType
import builders.enums.EInteractiveType
import builders.enums.ELineType
import online.interactiver.common.utils.clone
import entities.interactivePicture.elements.*
import entities.interactivePicture.elements.controls.selectors.selectorStyle
import entities.interactivePicture.elements.controls.selectors.selectorTextStyle
import entities.interactivePicture.elements.editors.ScoreEditor.*
import entities.interactivePicture.elements.figuresAndLines.lines.addLineToList
import entities.interactivePicture.elements.figuresAndLines.marker.addMarkerToList
import entities.interactivePicture.elements.figuresAndLines.static.addRectWithImageToList
import entities.interactivePicture.elements.figuresAndLines.static.addRectWithTextToList
import entities.interactivePicture.elements.figuresAndLines.static.addSoundToList
import entities.interactivePicture.elements.frames.addFrameToList
import entities.interactivePicture.selectControls
import io.ktor.util.date.*
import online.interactiver.common.interactivepicture.*
import utils.structures.Position

open class SelectorsAction(override val preventHistoryUpdate: Boolean, override val preventFutureUpdate: Boolean = false)
    : InteractiveElementAction(preventHistoryUpdate, preventFutureUpdate)
open class OptionsAction(open val optionId: String, override val preventHistoryUpdate: Boolean,
                         override val preventFutureUpdate: Boolean = false) :
    SelectorsAction(preventHistoryUpdate, preventFutureUpdate)
open class OptionAction(override val optionId: String, override val preventHistoryUpdate: Boolean,
                        override val preventFutureUpdate: Boolean = false) :
    OptionsAction(optionId, preventHistoryUpdate, preventFutureUpdate)
open class CommonOptionInteractiveElementAction(override val optionId: String, override val preventHistoryUpdate: Boolean,
                                                override val preventFutureUpdate: Boolean = false) :
    OptionAction(optionId, preventHistoryUpdate, preventFutureUpdate)
open class OptionScoreAction(override val optionId: String) : OptionAction(optionId, false)
open class OptionFramesAction(override val optionId: String) : OptionAction(optionId, false)
open class OptionFiguresAndLinesAction(override val optionId: String) : OptionAction(optionId, false)

data class AddSelector(val id: String, val position: Position?) : SelectorsAction(false)
data class SetOptions(val id: String, val options: MutableList<SelectorOption>?) : SelectorsAction(true)
data class AddOption(val selectorId: String, val text: String) : SelectorsAction(false)
data class RemoveOption(override val optionId: String) : OptionsAction(optionId, false)
data class ChangeOptionText(override val optionId: String, val text: String) : OptionAction(optionId, false)
data class SetOptionIsCorrect(override val optionId: String, val isCorrect: Boolean) : OptionAction(optionId, false)
data class AddRectWithTextToOption(override val optionId: String, val id: String, val position: Position?) :
        OptionFiguresAndLinesAction(optionId)

data class AddRectWithImageToOption(
        override val optionId: String, val id: String, val position: Position?, val base64: String,
        val fileName: String, val ratio: Double
) :
        OptionFiguresAndLinesAction(optionId)

data class AddMarkerToOption(override val optionId: String, val id: String, val position: Position) :
        OptionFiguresAndLinesAction(optionId)

data class AddLineToOption(override val optionId: String, val id: String, val start: Point, val end: Point, val type: ELineType) :
        OptionFiguresAndLinesAction(optionId)

data class AddFrameToOption(override val optionId: String, val id: String, val position: Position?, val type: EFrameType) :
        OptionFramesAction(optionId)

data class AddSoundToOption(override val optionId: String, val id: String, val position: Position?, val src: String?, val filename: String?) :
        OptionFiguresAndLinesAction(optionId)

data class SetOptionScoreData(
        override val optionId: String,
        val elementID: String,
        val index: Int,
        val score: ScoreData
) : OptionScoreAction(optionId)

data class SetOptionScoreCondition(
        override val optionId: String,
        val elementID: String,
        val index: Int,
        val condition: ScoreCondition
) : OptionScoreAction(optionId)

data class AddOptionScoreData(override val optionId: String, val elementID: String) : OptionScoreAction(optionId)
data class AddOptionScoreCondition(override val optionId: String, val elementID: String) : OptionScoreAction(optionId)
data class DeleteOptionScoreData(override val optionId: String, val elementID: String, val index: Int) :
        OptionScoreAction(optionId)

data class DeleteOptionScoreCondition(override val optionId: String, val elementID: String, val index: Int) :
        OptionScoreAction(optionId)

data class TransformElementOfOption(override val optionId: String, val elementId: String, val geometry: Geometry) :
        CommonOptionInteractiveElementAction(optionId, false)

data class TransformElementCurveOfOption(override val optionId: String, val elementId: String, val curve: Curve) :
        CommonOptionInteractiveElementAction(optionId, false)

data class SetVisibleElementTextOfOption(override val optionId: String, val elementId: String, val text: String) :
        CommonOptionInteractiveElementAction(optionId, false)
data class SetVisibleElementRenderViewOfOption(override val optionId: String, val elementId: String, val base64: String):
        CommonOptionInteractiveElementAction(optionId, true, true)
data class SetElementsHorizontalAlignForElementsOfOption(override val optionId: String, val position: Int, val flag: String):
    CommonOptionInteractiveElementAction(optionId, true)
data class SetElementsVerticalAlignForElementsOfOption(override val optionId: String, val position: Int, val flag: String):
    CommonOptionInteractiveElementAction(optionId, true)
data class SetElementsSpaceBetweenHorizontalOfOption(override val optionId: String, val offset: Int): CommonOptionInteractiveElementAction(optionId, true)
data class SetElementsSpaceBetweenVerticalOfOption(override val optionId: String, val offset: Int): CommonOptionInteractiveElementAction(optionId, true)

data class SetElementHintTextOfOption(override val optionId: String, val elementId: String, val text: String) :
        CommonOptionInteractiveElementAction(optionId, false)
data class SetElementHintVerticalAlignOfOption(override val optionId: String, val elementId: String, val align: String?):
        CommonOptionInteractiveElementAction(optionId, false)

data class SetHintRectOfOption(override val optionId: String, val elementId: String, val rect: Rect) :
        CommonOptionInteractiveElementAction(optionId, true)

data class MoveSelectedElementsOfOption(override val optionId: String, val ids: Array<String>, val direction: String) :
    CommonOptionInteractiveElementAction(optionId, true)

data class RemoveElementOfOption(override val optionId: String, val elementId: String) :
        CommonOptionInteractiveElementAction(optionId, false)

data class CopyElementOfOption(override val optionId: String, val elementId: String, val elementNewId: String) :
    CommonOptionInteractiveElementAction(optionId, false)

data class SetFillColorOfOption(override val optionId: String, val elementId: String, val color: String) :
        CommonOptionInteractiveElementAction(optionId, false)

data class SetFillOpacityOfOption(
        override val optionId: String, val elementId: String, val opacity: Double,
        override val preventHistoryUpdate: Boolean
) :
        CommonOptionInteractiveElementAction(optionId, preventHistoryUpdate)

data class SetBorderColorOfOption(override val optionId: String, val elementId: String, val color: String) :
        CommonOptionInteractiveElementAction(optionId, false)

data class SetBorderWidthOfOption(override val optionId: String, val elementId: String, val width: Int) :
        CommonOptionInteractiveElementAction(optionId, false)

data class SetBorderDashOfOption(override val optionId: String, val elementId: String, val dash: Array<Double>?) :
        CommonOptionInteractiveElementAction(optionId, false)

data class SetTextColorOfOption(override val optionId: String, val elementId: String, val color: String) :
        CommonOptionInteractiveElementAction(optionId, false)

data class SetTextHorizontalAlignOfOption(override val optionId: String, val elementId: String, val align: String) :
        CommonOptionInteractiveElementAction(optionId, false)

data class SetTextVerticalAlignOfOption(override val optionId: String, val elementId: String, val align: String) :
        CommonOptionInteractiveElementAction(optionId, false)

data class SetTextFontSizeOfOption(
        override val optionId: String, val elementId: String, val size: Int,
        override val preventHistoryUpdate: Boolean
) :
        CommonOptionInteractiveElementAction(optionId, preventHistoryUpdate)

data class SetTextPaddingOfOption(override val optionId: String, val elementId: String, val size: Int, override val preventHistoryUpdate: Boolean) :
    CommonOptionInteractiveElementAction(optionId, preventHistoryUpdate)
data class SetTextStyleOfOption(override val optionId: String, val elementId: String, val style: String ) :
    CommonOptionInteractiveElementAction(optionId, false)
data class SetTextDecorationOfOption(override val optionId: String, val elementId: String, val style: String ) :
    CommonOptionInteractiveElementAction(optionId, false)
data class SetTextLineHeightOfOption(override val optionId: String, val elementId: String, val lineHeight: Double, override val preventHistoryUpdate: Boolean ) :
    CommonOptionInteractiveElementAction(optionId, preventHistoryUpdate)
data class SetHintTextFontSizeOfOption(override val optionId: String, val elementId: String, val size: Int, override val preventHistoryUpdate: Boolean) :
    CommonOptionInteractiveElementAction(optionId, preventHistoryUpdate)
data class SetHintFontStyleOfOption(override val optionId: String, val elementId: String, val style: String) :
    CommonOptionInteractiveElementAction(optionId, false)
data class SetHintDecorationOfOption(override val optionId: String, val elementId: String, val style: String) :
    CommonOptionInteractiveElementAction(optionId, false)
data class SetHintLineHeightOfOption(override val optionId: String, val elementId: String, val lineHeight: Double, override val preventHistoryUpdate: Boolean) :
    CommonOptionInteractiveElementAction(optionId, preventHistoryUpdate)
data class SetHintHorizontalAlignOfOption(override val optionId: String, val elementId: String, val align: String) :
    CommonOptionInteractiveElementAction(optionId, false)
data class SetHintTextPaddingOfOption(override val optionId: String, val elementId: String, val size: Int, override val preventHistoryUpdate: Boolean) :
    CommonOptionInteractiveElementAction(optionId, preventHistoryUpdate)

data class SetElementActiveStateOfOption(
        override val optionId: String,
        val elementId: String,
        val activeState: String
) :
        CommonOptionInteractiveElementAction(optionId, false)

data class SetHintActiveStateOfOption(override val optionId: String, val elementId: String, val activeState: String) :
        CommonOptionInteractiveElementAction(optionId, false)
data class SetSyncingPhraseOfOption(override val optionId: String, val elementId: String, val isSynced: Boolean) :
        CommonOptionInteractiveElementAction(optionId, false)

data class SetElementCodeOfOption(override val optionId: String, val elementId: String, val code: String) :
        CommonOptionInteractiveElementAction(optionId, false)

fun buildOption(text: String): SelectorOption {
    return SelectorOption(
            identifier = Identifier(id = "SelectorOption_${getTimeMillis()}${(0 until Int.MAX_VALUE).random()}"),
            text = TextFrame(simpleText = text),
            isCorrect = "0",
            frames = mutableListOf(),
            figuresAndLines = mutableListOf(),
            style = ElementStyle(
                    usual = selectorStyle,
                    onFocus = selectorStyle.toOnFocus(),
                    onHover = selectorStyle.toOnHover()
            )
    )
}

val OptionScoreReducer = { state: MutableList<InteractiveElement>?, action: OptionScoreAction ->
    when (action) {
        is SetOptionScoreData -> state?.let {
            setCommonScoreDataToList(
                    state,
                    action.elementID,
                    action.index,
                    action.score
            )
        }

        is SetOptionScoreCondition -> state?.let {
            setCommonScoreConditionToList(
                    state,
                    action.elementID,
                    action.index,
                    action.condition
            )
        }

        is AddOptionScoreData -> state?.let {
            addCommonScoreDataToList(
                    state,
                    action.elementID,
            )
        }

        is AddOptionScoreCondition -> state?.let {
            addCommonScoreDataToList(
                    state,
                    action.elementID,
            )
        }

        is DeleteOptionScoreData -> state?.let {
            deleteCommonScoreDataFromList(
                    state,
                    action.elementID,
                    action.index
            )
        }

        is DeleteOptionScoreCondition -> state?.let {
            deleteCommonScoreConditionFromList(
                    state,
                    action.elementID,
                    action.index
            )
        }

        else -> state
    }
}

val OptionFramesReducer = { state: MutableList<InteractiveElement>?, action: OptionFramesAction ->
    when (action) {
        is AddFrameToOption -> state?.let { addFrameToList(it, action.id, action.position, action.type, true) }
        else -> state
    }
}

val OptionFiguresAndLinesReducer = { state: MutableList<InteractiveElement>?, action: OptionFiguresAndLinesAction ->
    when (action) {
        is AddRectWithTextToOption -> state?.let { addRectWithTextToList(it, action.id, action.position, true) }
        is AddRectWithImageToOption -> state?.let {
            addRectWithImageToList(it, action.id, action.position, action.base64, action.fileName, action.ratio, true)
        }

        is AddMarkerToOption -> state?.let { addMarkerToList(it, action.id, action.position, true) }
        is AddLineToOption -> state?.let { addLineToList(it, action.id, action.start, action.end, action.type, true) }
        is AddSoundToOption -> state?.let { addSoundToList(it, action.id, action.position, action.src, action.filename, true) }
        else -> state
    }
}

val CommonOptionInteractiveElementReducer = { state: MutableList<InteractiveElement>?,
                                              action: CommonOptionInteractiveElementAction ->
    when (action) {
        is TransformElementOfOption -> state?.let { transformElementOfList(it, action.elementId, action.geometry) }
        is SetVisibleElementRenderViewOfOption -> state?.let { setVisibleElementRenderView(it, action.elementId, action.base64) }
        is TransformElementCurveOfOption -> state?.let {
            transformElementCurveOfList(
                    it,
                    action.elementId,
                    action.curve
            )
        }

        is SetVisibleElementTextOfOption -> state?.let {
            setVisibleElementTextOfList(
                    it,
                    action.elementId,
                    action.text
            )
        }

        is RemoveElementOfOption -> state?.let { removeElementOfList(it, action.elementId) }
        is CopyElementOfOption -> state?.let { copyElementOfList(it, action.elementId,action.elementNewId) }
        is SetElementHintTextOfOption -> state?.let { setHintTextOfList(it, action.elementId, action.text) }
        is SetElementHintVerticalAlignOfOption -> state?.let { setHintVerticalAlignOfList(it, action.elementId, action.align) }
        is SetHintRectOfOption -> state?.let { setHintRectOfList(it, action.elementId, action.rect) }
        is SetFillColorOfOption -> state?.let { setFillColorOfList(it, action.elementId, action.color) }
        is SetFillOpacityOfOption -> state?.let { setFillOpacityOfList(it, action.elementId, action.opacity) }
        is SetBorderColorOfOption -> state?.let { setBorderColorOfList(it, action.elementId, action.color) }
        is SetBorderWidthOfOption -> state?.let { setBorderWidthOfList(it, action.elementId, action.width) }
        is SetBorderDashOfOption -> state?.let { setBorderDashOfList(it, action.elementId, action.dash) }
        is SetTextColorOfOption -> state?.let { setTextColorOfList(it, action.elementId, action.color) }
        is SetTextHorizontalAlignOfOption -> state?.let { setTextHorizontalAlignOfList(it, action.elementId, action.align) }
        is SetTextVerticalAlignOfOption -> state?.let { setTextVerticalAlignOfList(it, action.elementId, action.align) }
        is SetTextFontSizeOfOption -> state?.let { setTextFontSizeOfList(it, action.elementId, action.size) }
        is SetTextPaddingOfOption -> state?.let { setTextPaddingOfList(it, action.elementId, action.size) }
        is SetTextStyleOfOption -> state?.let { setTextStyleOfList(it, action.elementId, action.style) }
        is SetTextDecorationOfOption -> state?.let { setTextDecorationOfList(it, action.elementId, action.style) }
        is SetTextLineHeightOfOption -> state?.let { setTextLineHeightOfList(it, action.elementId, action.lineHeight) }
        is SetHintTextFontSizeOfOption -> state?.let { setHintTextFontSizeOfList(it, action.elementId, action.size) }
        is SetHintFontStyleOfOption -> state?.let { setHintFontStyleOfList(it, action.elementId, action.style) }
        is SetHintDecorationOfOption -> state?.let { setHintDecorationOfList(it, action.elementId, action.style) }
        is SetHintLineHeightOfOption -> state?.let { setHintLineHeightOfList(it, action.elementId, action.lineHeight) }
        is SetHintHorizontalAlignOfOption -> state?.let { setHintHorizontalAlignOfList(it, action.elementId, action.align) }
        is SetHintTextPaddingOfOption -> state?.let { setHintTextPaddingOfList(it, action.elementId, action.size) }
        is SetElementActiveStateOfOption -> state?.let { setElementActiveState(it, action.elementId, action.activeState) }
        is SetHintActiveStateOfOption -> state?.let { setHintActiveState(state, action.elementId, action.activeState) }
        is MoveSelectedElementsOfOption -> state?.let { moveSelectedElements(state, action.ids, action.direction)}
        is SetElementsHorizontalAlignForElementsOfOption -> state?.let {setElementsHorizontalAlignElementsOfList(it, action.optionId, action.position, action.flag)}
        is SetElementsVerticalAlignForElementsOfOption -> state?.let {setElementsVerticalAlignElementsOfList(it, action.optionId, action.position, action.flag)}
        is SetElementsSpaceBetweenHorizontalOfOption -> state?.let{setElementsSpaceBetweenHorizontalOfList(it, action.optionId, action.offset)}
        is SetElementsSpaceBetweenVerticalOfOption -> state?.let{setElementsSpaceBetweenVerticalOfList(it, action.optionId, action.offset)}
        is SetSyncingPhraseOfOption -> state?.let { setSyncingPhraseOfList(state, action.elementId, action.isSynced) }
        is SetElementCodeOfOption -> state?.let { setElementCode(state, action.elementId, action.code) }
        else -> state
    }
}

val OptionReducer = { state: SelectorOption, action: OptionAction ->
    when (action) {
        is ChangeOptionText -> {
            val newState = state.clone()
            newState.text!!.simpleText = action.text
            newState.identifier!!.setIdentifierByText(action.text)

            newState
        }

        is SetOptionIsCorrect -> {
            val newState = state.clone()
            newState.isCorrect = if (action.isCorrect) "1" else "0"

            newState
        }

        is OptionFramesAction -> {
            val newState = state.clone()
            if (newState.figuresAndLines == null)
                newState.figuresAndLines = mutableListOf()

            newState.frames = OptionFramesReducer(newState.frames, action)

            newState
        }

        is OptionFiguresAndLinesAction -> {
            val newState = state.clone()
            if (newState.figuresAndLines == null)
                newState.figuresAndLines = mutableListOf()

            newState.figuresAndLines = OptionFiguresAndLinesReducer(newState.figuresAndLines, action)

            newState
        }

        is OptionScoreAction -> {
            val newState = state.clone()
            newState.figuresAndLines = OptionScoreReducer(newState.figuresAndLines, action)
            newState.frames = OptionScoreReducer(newState.frames, action)
            newState
        }

        is CommonOptionInteractiveElementAction -> {
            val newState = state.clone()
            newState.figuresAndLines = CommonOptionInteractiveElementReducer(newState.figuresAndLines, action)
            newState.frames = CommonOptionInteractiveElementReducer(newState.frames, action)
            newState
        }

        else -> state
    }
}

val OptionsReducer = { state: MutableList<SelectorOption>, action: OptionsAction ->
    when (action) {
        is RemoveOption -> {
            val newState = state.clone()
            newState.removeAll { it.identifier?.id.equals(action.optionId) }
            newState
        }

        is OptionAction -> {
            val newState = state.clone()
            val optionIndex = newState.indexOfFirst {
                it.identifier?.id?.equals(action.optionId) == true
            }

            if (optionIndex < 0) throw AssertionError()

            newState[optionIndex] = OptionReducer(newState[optionIndex], action)

            newState
        }

        else -> state
    }
}

val SelectorReducer = { state: MutableList<InteractiveElement>, action: SelectorsAction ->
    when (action) {

        is AddSelector -> {
            val builder =
                    ElementBuilder().setType(EInteractiveType.SELECTOR_INTERACTIVE)
                            .setType(EElementType.SELECTOR_CONTROL)
                            .setHoverFocusStyling(selectorStyle)
                            .setText("Select")
                            .setRect(action.position)
                            .setRectSize(196, 32)
                            .setSelectorDefaultText("Selector")
                            .setTextStyle(selectorTextStyle)
                            .addScore(ScoreData(DEFAULT_SCORE, null))
                            .setID(action.id)
                            .setIsSynced(false)

            addElementToList(state, builder.build())
        }

        is SetOptions -> {
            val newState = state.clone()
            val selectorIndex = newState.indexOfFirst { it.identifier.id.equals(action.id) }

            if (selectorIndex >= 0) {
                val newSelector = state[selectorIndex].clone()
                newSelector.selector?.options = action.options
                newState[selectorIndex] = newSelector
            }

            newState
        }

        is AddOption -> {
            val newState = state.clone()
            val option = buildOption(action.text)
            option.identifier?.setIdentifierByText(action.text)
            val selectorIndex = newState.indexOfFirst { it.identifier.id.equals(action.selectorId) }

            if (selectorIndex >= 0) {
                val newSelector = state[selectorIndex].clone()

                if (newSelector.selector == null) {
                    newSelector.selector = Selector()
                }

                if (newSelector.selector!!.options == null) {
                    newSelector.selector!!.options = mutableListOf(option)
                } else {
                    newSelector.selector!!.options!!.add(option)
                }

                newState[selectorIndex] = newSelector
            }

            newState

        }

        is OptionsAction -> {
            val newState = state.clone()

            val selectorIndex = newState.indexOfFirst { element ->
                element.selector?.options?.any { it.identifier?.id?.equals(action.optionId) == true } == true
            }

            if (selectorIndex >= 0) {
                newState[selectorIndex].selector!!.options =
                        OptionsReducer(newState[selectorIndex].selector!!.options!!, action)
            }

            newState
        }

        else -> state
    }
}

val selectSelectors = { state: StoreState ->
    selectControls(state)?.filter { it.type == EInteractiveType.SELECTOR_INTERACTIVE.text }?.toMutableList()
}

val selectFocusedSelector = { state: StoreState ->
    selectSelectors(state)?.find { it.identifier.id!! == state.selectedElementId }
            ?: selectSelectors(state)?.find { it.selector?.options?.any { it.identifier?.id!! == selectFocusedOptionId(state) } == true }
}

val selectFocusedOption = { state: StoreState ->
    selectFocusedSelector(state)?.selector?.options?.find { it.identifier!!.id!! == selectFocusedOptionId(state) }
}

val selectFocusedOptionFrames = { state: StoreState ->
    selectFocusedOption(state)?.frames
}

val selectFocusedOptionFiguresAndLines = { state: StoreState ->
    selectFocusedOption(state)?.figuresAndLines
}

val selectFocusedOptionStatic = { state: StoreState ->
    val elements = mutableListOf<InteractiveElement>()
    arrayOf(selectFocusedOptionFrames, selectFocusedOptionFiguresAndLines).forEach { selector ->
        selector(state)?.forEach { elements.add(it) }
    }
    elements
}
