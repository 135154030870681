package shared.components.UploadOrRecordAudio

import antd.Button
import app.useAppDispatch
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.interactivePicture.elements.editors.soundEditor.inputHidden
import enums.EButtonTypes
import io.ktor.client.fetch.*
import kotlinx.js.jso
import kotlinx.js.timers.Timeout
import kotlinx.js.timers.clearInterval
import kotlinx.js.timers.setInterval
import org.w3c.dom.HTMLInputElement
import org.w3c.files.FileReader
import react.*
import react.dom.html.InputType
import react.dom.html.ReactHTML.audio
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import reactaudiovoicerecorder.useReactMediaRecorder
import shared.components.Icon
import shared.components.description.Description
import shared.components.editSoundModal.*
import shared.components.editableSound.useAudioListener
import utils.fileToBase64WithMaxFileSizeInMb
import utils.result
import utils.types.impl

external interface UploadOrRecordAudioProps : Props {
    var maxFileSizeInMb: Int
    var sound: String?
    var onSoundChange: (String?) -> Unit
    var isRecording: Boolean
    var preparingToRecordDuration: Int?
    var startPreparingToRecord: (Timeout) -> Unit
    var continuePreparingToRecords: () -> Unit
    var resetAudioDuration: () -> Unit
    var stopRecording: () -> Unit
    var maxAudioDuration: Int
    var recordingDuration: Int?
}

data class UploadOrRecordAudioHook(
    val component: FC<UploadOrRecordAudioProps>,
    val resetState: () -> Unit,
    val props: UploadOrRecordAudioProps,
)

val useUploadOrRecordAudio = {
    maxFileSizeInMb: Int,
    sound: String?,
    maxAudioDuration: Int,
    onSoundChange: (String?) -> Unit ->
    val recorder = useReactMediaRecorder(jso { this.audio = true })

    val (preparingToRecordSeconds, setPreparingToRecordSeconds) = useState<Duration?>(null)
    val (audioDuration, setAudioDuration) = useState<Duration?>(null)

    val dispatch = useAppDispatch()

    val isRecording = recorder.status == "recording"

    useEffect(recorder.mediaBlobUrl) {
        val mediaBlobUrl = recorder.mediaBlobUrl ?: return@useEffect

        fetch(mediaBlobUrl).then {
            it.blob()
        }.then { blob ->
            if (blob.size.toInt() > maxFileSizeInMb * 1024 * 1024) {
                dispatch(OpenErrorModal("Sound file must be less ${maxFileSizeInMb}Mb"))
                return@then
            }
            val reader = FileReader()
            reader.onloadend = f@{
                onSoundChange(it.target.result)
            }
            reader.readAsDataURL(blob)
        }.catch {
            console.log("Error: $it")
        }
    }
    useEffect(audioDuration) {
        if (audioDuration == null) {
            return@useEffect
        }

        if (audioDuration.duration > maxAudioDuration) {
            clearInterval(audioDuration.intervalId)
            setAudioDuration(null)
            recorder.stopRecording()
            dispatch(OpenErrorModal("Sound file must be less ${maxFileSizeInMb}Mb", "Recording audio for too long"))
        }
    }

    useEffect(isRecording) {
        if (!isRecording) {
            return@useEffect
        }

        val id = setInterval({ setAudioDuration { it?.copy(duration = it.duration + 1) } }, 1000)
        setAudioDuration(Duration(id, 0))
        setPreparingToRecordSeconds(null)
    }

    useEffect(preparingToRecordSeconds) {
        if (preparingToRecordSeconds == null) {
            return@useEffect
        }

        if (preparingToRecordSeconds.duration == 0) {
            recorder.startRecording()
            clearInterval(preparingToRecordSeconds.intervalId)
        }
    }

    UploadOrRecordAudioHook(
        component = UploadOrRecordAudio,
        resetState = {
            recorder.stopRecording()
            preparingToRecordSeconds?.intervalId?.let { clearInterval(it) }
            setPreparingToRecordSeconds(null)
            audioDuration?.intervalId?.let { clearInterval(it) }
            setAudioDuration(null)
        },
        props = impl {
            this.isRecording = isRecording
            this.stopRecording = {
                recorder.stopRecording()
                audioDuration?.intervalId?.let { clearInterval(it) }
            }
            this.onSoundChange = onSoundChange
            this.startPreparingToRecord = { setPreparingToRecordSeconds(Duration(it, 3)) }
            this.continuePreparingToRecords = { setPreparingToRecordSeconds { it?.copy(duration = it.duration - 1) }}
            this.resetAudioDuration = { setAudioDuration(null) }
            this.sound = sound
            this.preparingToRecordDuration = preparingToRecordSeconds?.duration
            this.maxFileSizeInMb = maxFileSizeInMb
            this.maxAudioDuration = maxAudioDuration
            this.recordingDuration = audioDuration?.duration
        }
    )
}

val UploadOrRecordAudio = FC<UploadOrRecordAudioProps> { props ->
    val (play, pause, audioRef, isAudioPaused) = useAudioListener()
    val dispatch = useAppDispatch()

    val inputRef = useRef(null) as MutableRefObject<HTMLInputElement>

    div {
        css(buttonsContainer)
        div {
            css(rowContainer)
            val inputAudioOnChange =
                fileToBase64WithMaxFileSizeInMb(
                    { dispatch(OpenErrorModal("Sound file must be less ${props.maxFileSizeInMb}Mb")) },
                    props.maxFileSizeInMb.toDouble()
                ) { src, _ ->
                    props.onSoundChange(src)
                }
            Button {
                css(button)
                Icon {
                    css(iconCss)
                    src = "ic_upload_sound_24x24.svg"
                }
                onClick = {
                    inputRef.current?.click()
                }
                +"Upload"
            }
            input {
                css(inputHidden)
                ref = inputRef
                id = EButtonTypes.EDIT_SOUND_MODAL_UPLOAD_BUTTON.value
                type = InputType.file
                accept = "audio/*"
                size = 1
                onChange = inputAudioOnChange
            }
            val duration = props.preparingToRecordDuration

            if (duration != null) {
                p {
                    css(button)
                    val countdownNumber = maxOf(duration, 1)
                    span {
                        css(countdown)
                        key = countdownNumber.toString()
                        +"$countdownNumber"
                    }
                }
            } else {
                Button {
                    css(button)
                    Icon {
                        css(iconCss)
                        src = if (props.isRecording) "ic_stop_circle_24x24.svg" else "ic_micro_32x32.svg"
                    }
                    onClick = {
                        if (props.isRecording) {
                            props.resetAudioDuration()
                            props.stopRecording()
                        } else {
                            val id = setInterval(
                                { props.continuePreparingToRecords() },
                                1000
                            )
                            props.startPreparingToRecord(id)
                        }
                    }
                    +if (props.isRecording) "Finish ${props.recordingDuration?.let { props.maxAudioDuration - it }}" else "Record"
                }
            }
        }
        Description {
            text = "Max size is ${props.maxAudioDuration} seconds or ${props.maxFileSizeInMb}Mb"
        }
        props.sound?.let {
            audio {
                ref = audioRef
                src = props.sound
            }
            Button {
                css(button)
                Icon {
                    css(iconCss)
                    src = if (isAudioPaused) "ic_speaker_20x21.svg" else "ic_pause_44x44.svg"
                }
                onClick = {
                    if (isAudioPaused) {
                        play()
                    } else {
                        pause()
                    }
                }
                +"Listen"
            }
        }
    }
}