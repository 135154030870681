package features.ShareButton.ui

import csstype.*
import utils.types.CssStyle

val shareButton: CssStyle = {
    color = Color("white")
    backgroundColor = Color("#597EF7")
    borderRadius = 8.px
    border = None.none
    fontWeight = FontWeight.bold
    lineHeight = 20.8.px
    fontSize = 16.px
    fontFamily = string("\"Inter\", system-ui")
    width = 250.px
    height = 51.px
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    gap = 6.px
    hover {
        backgroundColor = Color("#3E62D9")
        color = important(Color("white"))
    }
}