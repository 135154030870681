package widgets.Exercises.ui.components.StatisticsModal

enum class ECharts(val color: String, val legendLabel: String, val type: String) {
    VIEWS(
        color = "#8884d8",
        legendLabel = "Number of slide views",
        type = "users"
    ),
    SUCCESSFUL_ATTEMPTS(
        color = "#82ca9d",
        legendLabel = "Number of correct answers",
        type = "successfulUsers"
    )
}
