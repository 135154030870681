package online.interactiver.common.duo.wordsprogress

import kotlinx.serialization.Serializable

@Serializable
data class WordProgressCommon(
    val word: String,
    val definition: String,
    val markedToPractice: Boolean,
    val languageToLearn: String,
    val interfaceLanguage: String,
    val phraseDefinition: String
)
