package widgets.Exercises.ui.components.ExercisesAndFoldersDeleteModal

import Modal
import antd.Button
import app.useAppDispatch
import csstype.px
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.js.jso
import online.interactiver.common.autogeneration.FoldersAndExercisesRequest
import online.interactiver.common.autogeneration.LanguageAutoGeneration
import org.w3c.dom.HTMLButtonElement
import react.FC
import react.Props
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import shared.components.header.Header
import utils.types.extend
import widgets.Exercises.deleteFoldersAndExercisesRequest
import widgets.Exercises.ui.components.UserActions.deleteText
import widgets.Exercises.ui.components.UserActions.modalButtonsContainer
import widgets.Exercises.ui.components.UserActions.modalContainer
import widgets.Exercises.ui.components.UserActions.modalTextContainer
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoTask.ui.components.SlideEditModal.cancelButton

external interface ExerciseDeleteModalProps : Props {
    var isOpen: Boolean
    var onClose: () -> Unit
    var requests: List<LanguageAutoGeneration>
    var updateExercises: (() -> Unit)?
}

val ExercisesAndFoldersDeleteModal = FC<ExerciseDeleteModalProps> { props ->
    val dispatch = useAppDispatch()

    Modal {
        open = props.isOpen
        onCancel = {
            it.stopPropagation()
            props.onClose()
        }
        footer = null
        width = 445
        bodyStyle = jso(modalContainer)
        div {
            css(modalTextContainer)
            Header {
                text = "Are you sure you want to delete \n${props.requests.take(3).joinToString(", ") {
                    "'${it.name}'"
                }} ${if (props.requests.size > 3) ", ..." else "" }\nfiles?"
            }
            p {
                css(deleteText)
                +"This action cannot be undone."
            }
        }
        div {
            css(modalButtonsContainer)
            Button {
                css(generateButton.extend {
                    width = 186.px
                })
                onClick = { evt: MouseEvent<HTMLButtonElement, *> ->
                    evt.stopPropagation()
                    props.onClose()
                    dispatch(StartModalLoading("Deleting..."))
                    GlobalScope.launch {
                        val deleted = deleteFoldersAndExercisesRequest(props.requests.map { FoldersAndExercisesRequest(
                            isFolder = it.isFolder(),
                            id = it.requestId
                        ) })
                        dispatch(EndModalLoading())
                        if (!deleted) {
                            OpenErrorModal(text = "Couldn't delete request")
                            return@launch
                        }

                        props.updateExercises?.invoke()
                    }
                }
                +"Delete"
            }
            Button {
                css(cancelButton)
                onClick = { evt: MouseEvent<HTMLButtonElement, *> ->
                    evt.stopPropagation()
                    props.onClose()
                }
                +"Cancel"
            }
        }
    }
}
