package entities.interactivePicture.advancedSettings

import app.StoreState
import online.interactiver.common.enums.ELanguage
import online.interactiver.common.interactivepicture.TaskType

open class TaskTypeSettingsAction : AdvancedSettingsAction()

data class SetTaskType(val taskType: TaskType) : TaskTypeSettingsAction()

val TaskTypeSettingsReducer = { state: TaskType, action: TaskTypeSettingsAction ->
    when (action) {
        is SetTaskType -> action.taskType
        else -> state
    }
}

val selectTaskTypeSettings = { state: StoreState -> selectAdvancedSettings(state).taskType }
val selectTaskLanguage = { state: StoreState -> ELanguage.values().find{ it.text == selectTaskTypeSettings(state).language }!! }
