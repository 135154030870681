package widgets.Notifications.ui

import app.useAppDispatch
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.solvedTasks.ui.SolvedTasks
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.ELanguage
import online.interactiver.common.notifications.UpdateNotificationsRequest
import pages.duoSettings.ui.page
import pages.studentDuo.selectRequiredStudentDuoNativeLanguage
import react.FC
import react.Props
import react.ReactNode
import widgets.Notifications.ui.SelectNotifactionsTime.SelectNotificationsTime
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.section
import react.redux.useSelector
import react.useEffectOnce
import shared.components.ProtectedRoute.ProtectedRoute
import shared.components.checkboxWithLabel.CheckboxWithLabel
import shared.components.header.Header
import shared.components.loader.Loader
import widgets.Notifications.*

external interface ManageNotificationsProps : Props {
    var buttons: ReactNode
}

val ManageNotifications = FC<ManageNotificationsProps> {
    val notifications = useSelector(selectNotifications)
    val nativeLanguage = useSelector(selectRequiredStudentDuoNativeLanguage)
    val dispatch = useAppDispatch()

    useEffectOnce {
        GlobalScope.launch {
            val notificationsResponse = getNotificationsSettingsRequest()
            if (notificationsResponse == null) {
                dispatch(OpenErrorModal("Error getting notifications"))
                return@launch
            }

            dispatch(SetNotifications(notificationsResponse))
        }
    }

    ProtectedRoute {
        toStudentDuo = true
        section {
            css(page)
            SolvedTasks {}
            Header {
                text = getHeader(nativeLanguage)
            }
            if (notifications == null) {
                Loader {}
            } else {
                val morningNotificationHourTime = notifications.morningNotificationsTime.split(":").firstOrNull() ?: "7"
                val morningNotificationMinuteTime =
                    notifications.morningNotificationsTime.split(":").getOrElse(1) { "0" }
                val eveningNotificationHourTime =
                    notifications.eveningNotificationsTime.split(":").firstOrNull() ?: "19"
                val eveningNotificationMinuteTime =
                    notifications.eveningNotificationsTime.split(":").getOrElse(1) { "0" }

                div {
                    css(settingsContainer)
                    CheckboxWithLabel {
                        label = getCourseGenerationNotifications(nativeLanguage)
                        checked = notifications.enableCourseGenerationNotification
                        onChange = {
                            dispatch(SetEnableCourseGenerationNotification(it))
                            GlobalScope.launch {
                                val updated = updateNotificationsRequest(
                                    UpdateNotificationsRequest(
                                        enableCourseGenerationNotification = it
                                    )
                                )
                                if (updated) {
                                    return@launch
                                }

                                dispatch(OpenErrorModal("Error updating setting"))
                                dispatch(SetEnableCourseGenerationNotification(!it))
                            }
                        }
                    }
                    SelectNotificationsTime {
                        notificationsLabel = getMorningNotificationsTime(nativeLanguage)
                        maxHour = 11
                        minHour = 0
                        hourStep = 1
                        maxMinute = 50
                        minMinute = 0
                        minuteStep = 10
                        enableNotifications = notifications.enableMorningNotifications
                        notificationsHourTime = morningNotificationHourTime
                        notificationsMinuteTime = morningNotificationMinuteTime
                        onEnableNotificationsChange = {
                            dispatch(SetEnableMorningNotification(it))
                            GlobalScope.launch {
                                val updated = updateNotificationsRequest(
                                    UpdateNotificationsRequest(
                                        enableMorningNotifications = it
                                    )
                                )
                                if (updated) {
                                    return@launch
                                }

                                dispatch(OpenErrorModal("Error updating setting"))
                                dispatch(SetEnableMorningNotification(!it))
                            }
                        }
                        onHourTimeChange = {
                            val oldMorningNotificationsTime = notifications.morningNotificationsTime
                            val newMorningNotificationsTime = "$it:$morningNotificationMinuteTime"
                            dispatch(SetMorningNotificationTime(newMorningNotificationsTime))
                            GlobalScope.launch {
                                val updated = updateNotificationsRequest(
                                    UpdateNotificationsRequest(
                                        morningNotificationsTime = newMorningNotificationsTime
                                    )
                                )
                                if (updated) {
                                    return@launch
                                }

                                dispatch(OpenErrorModal("Error updating setting"))
                                dispatch(SetMorningNotificationTime(oldMorningNotificationsTime))
                            }
                        }
                        onMinuteTimeChange = {
                            val oldMorningNotificationsTime = notifications.morningNotificationsTime
                            val newMorningNotificationsTime = "$morningNotificationHourTime:$it"
                            dispatch(SetMorningNotificationTime(newMorningNotificationsTime))
                            GlobalScope.launch {
                                val updated = updateNotificationsRequest(
                                    UpdateNotificationsRequest(
                                        morningNotificationsTime = newMorningNotificationsTime
                                    )
                                )
                                if (updated) {
                                    return@launch
                                }

                                dispatch(OpenErrorModal("Error updating setting"))
                                dispatch(SetMorningNotificationTime(oldMorningNotificationsTime))
                            }
                        }
                    }
                    SelectNotificationsTime {
                        notificationsLabel = getEveningNotificationsTime(nativeLanguage)
                        maxHour = 23
                        minHour = 12
                        hourStep = 1
                        maxMinute = 50
                        minMinute = 0
                        minuteStep = 10
                        enableNotifications = notifications.enableEveningNotifications
                        notificationsHourTime = eveningNotificationHourTime
                        notificationsMinuteTime = eveningNotificationMinuteTime
                        onEnableNotificationsChange = {
                            dispatch(SetEnableEveningNotification(it))
                            GlobalScope.launch {
                                val updated = updateNotificationsRequest(
                                    UpdateNotificationsRequest(
                                        enableEveningNotifications = it
                                    )
                                )
                                if (updated) {
                                    return@launch
                                }

                                dispatch(OpenErrorModal("Error updating setting"))
                                dispatch(SetEnableEveningNotification(!it))
                            }
                        }
                        onHourTimeChange = {
                            val oldEveningNotificationsTime = notifications.eveningNotificationsTime
                            val newEveningNotificationsTime = "$it:$eveningNotificationMinuteTime"
                            dispatch(SetEveningNotificationTime(newEveningNotificationsTime))
                            GlobalScope.launch {
                                val updated = updateNotificationsRequest(
                                    UpdateNotificationsRequest(
                                        eveningNotificationsTime = newEveningNotificationsTime
                                    )
                                )
                                if (updated) {
                                    return@launch
                                }

                                dispatch(OpenErrorModal("Error updating setting"))
                                dispatch(SetEveningNotificationTime(oldEveningNotificationsTime))
                            }
                        }
                        onMinuteTimeChange = {
                            val oldEveningNotificationsTime = notifications.eveningNotificationsTime
                            val newEveningNotificationsTime = "$eveningNotificationHourTime:$it"
                            dispatch(SetEveningNotificationTime(newEveningNotificationsTime))
                            GlobalScope.launch {
                                val updated = updateNotificationsRequest(
                                    UpdateNotificationsRequest(
                                        eveningNotificationsTime = newEveningNotificationsTime
                                    )
                                )
                                if (updated) {
                                    return@launch
                                }

                                dispatch(OpenErrorModal("Error updating setting"))
                                dispatch(SetEveningNotificationTime(oldEveningNotificationsTime))
                            }
                        }
                    }
                    CheckboxWithLabel {
                        label = getProgressReports(nativeLanguage)
                        checked = notifications.enableProgressReports
                        onChange = {
                            dispatch(SetEnableProgressReports(it))
                            GlobalScope.launch {
                                val updated = updateNotificationsRequest(
                                    UpdateNotificationsRequest(
                                        enableProgressReports = it
                                    )
                                )
                                if (updated) {
                                    return@launch
                                }

                                dispatch(OpenErrorModal("Error updating setting"))
                                dispatch(SetEnableProgressReports(!it))
                            }
                        }
                    }
                    CheckboxWithLabel {
                        label = getSuggestions(nativeLanguage)
                        checked = notifications.enableSuggestions
                        onChange = {
                            dispatch(SetEnableSuggestions(it))
                            GlobalScope.launch {
                                val updated = updateNotificationsRequest(
                                    UpdateNotificationsRequest(
                                        enableSuggestions = it
                                    )
                                )
                                if (updated) {
                                    return@launch
                                }

                                dispatch(OpenErrorModal("Error updating setting"))
                                dispatch(SetEnableSuggestions(!it))
                            }
                        }
                    }
                }
            }

            +it.buttons
        }
    }
}

fun getCourseGenerationNotifications(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Ссылка на созданный для Вас курс"
    } else {
        "Link to the course for you"
    }
}

fun getMorningNotificationsTime(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Утренние напоминания:"
    } else {
        "Morning reminders:"
    }
}


fun getEveningNotificationsTime(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Вечерние напоминания:"
    } else {
        "Evening reminders:"
    }
}

fun getProgressReports(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Отчёты о прогрессе с новыми словами и фразами"
    } else {
        "Progress reports with new words and phrases"
    }
}

fun getSuggestions(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Предложения и другая информация"
    } else {
        "Offers and other information"
    }
}

fun getHeader(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Настройте уведомления"
    } else {
        "Set up notifications"
    }
}
