package online.interactiver.common.duo

import kotlinx.serialization.Serializable

@Serializable
data class DuoCourse(
    val id: Int,
    val name: String,
    val states: MutableList<DuoExercise>,
    var languageToLearn: String? = null
)
