package entities.interactivePicture.elements.controls.inputs.ui.editor

import app.useAppDispatch
import app.useAppSelector
import builders.enums.EInteractiveType
import csstype.FlexDirection
import entities.interactivePicture.elements.controls.inputs.SetMaxLength
import entities.interactivePicture.elements.controls.inputs.SetMaxLengthForInputs
import entities.interactivePicture.elements.editors.textEditor.TextEditor
import entities.interactivePicture.selectElementsUnderSelectionRectangle
import entities.interactivePicture.selectFocusedElement
import react.FC
import react.Props
import shared.canvas.interfaces.getValueForEditor
import shared.canvas.interfaces.shouldDrawEditor
external interface MaxLengthProps : Props {
    var value: Int
    var onChange: ((String) -> Unit)?
}


val MaxLengthEditor = FC<MaxLengthProps> f@{props ->
    val selected = useAppSelector(selectFocusedElement)
    val elementsUnderSelectionRectangle = useAppSelector(selectElementsUnderSelectionRectangle)

    if (!shouldDrawEditor(selected, elementsUnderSelectionRectangle) { element -> element.type == EInteractiveType.INPUT_INTERACTIVE.text}) {
        return@f
    }

    val value = props.value
    TextEditor {
        header = "Maximum length"
        this.value = value.toString()
        placeholder = "Input maximum length"
        onChange = {
            props.onChange?.invoke(it.target.value)
        }
        height = 35
        width = 100
        flexDirection = FlexDirection.row
        fontWeight = 400
    }
}