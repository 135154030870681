package widgets.LanguageAutoSlider.ui.components.AdditionalSettingsModal

import Modal
import antd.*
import app.useAppDispatch
import csstype.pct
import emotion.react.css
import entities.languageAutoCurrentRequest.*
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import features.EnableContinueButton.ui.EnableButtonWithTextAndLink
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.EAskStudentName
import online.interactiver.common.interactivepicture.SliderGreetingScreenTitleWithScoreSettings
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.redux.useSelector
import react.useEffect
import react.useState
import shared.components.Icon
import shared.components.header.Header
import shared.components.switchWIthLabelAndTooltip.SwitchWithLabelAndTooltip
import utils.types.extend
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.shareButton
import widgets.LanguageAutoSlider.ui.components.TitleWithScoreSetting.TitleWithScoreSetting
import widgets.LanguageAutoSlider.updateSliderSettingsRequest

external interface AdditionalSettingsModalProps : Props {
    var isOpen: Boolean
    var onClose: () -> Unit
    var requestId: Int
    var task: String
}

val AdditionalSettingsModal = FC<AdditionalSettingsModalProps> { props ->
    val link = useSelector(selectCurrentRequestContinueButtonLink)
    val continueText = useSelector(selectCurrentRequestContinueButtonText)
    val titlesWithScoreSettings = useSelector(selectCurrentRequestTitlesWithScore)
    val askStudentNameSetting = useSelector(selectAskStudentName)
    val enableGamifiedPopups = useSelector(selectCurrentRequestHasEnabledGamifiedPopups)
    val enableThirdPassage = useSelector(selectCurrentRequestHasEnabledThirdPassage)
    val shareLink = useSelector(selectCurrentRequestShareButtonLink)
    val shareText = useSelector(selectCurrentRequestShareButtonText)

    val (newLink, setNewLink) = useState(link)
    val (newContinueText, setNewContinueText) = useState(continueText)
    val (newTitlesWithScoreSettings, setNewTitlesWithScoreSettings) = useState(titlesWithScoreSettings)
    val (newAskStudentNameSetting, setNewAskStudentNameSetting) = useState(askStudentNameSetting)
    val (newShareLink, setNewShareLink) = useState(shareLink)
    val (newShareText, setNewShareText) = useState(shareText)

    val (enableContinueButton, setEnableContinueButton) = useState(false)
    val (enableShareButton, setEnableShareButton) = useState(false)

    val (newEnableGamifiedPopups, setNewEnableGamifiedPopups) = useState(enableGamifiedPopups)
    val (newEnableThirdPassage, setNewEnableThirdPassage) = useState(enableThirdPassage)

    val dispatch = useAppDispatch()

    useEffect(link) {
        setNewLink(link)
        if (link.isNotBlank()) {
            setEnableContinueButton(true)
        }
    }

    useEffect(continueText) {
        setNewContinueText(continueText)
        if (continueText.isNotBlank()) {
            setEnableContinueButton(true)
        }
    }

    useEffect(shareLink) {
        setNewShareLink(shareLink)
        if (shareLink.isNotBlank()) {
            setEnableShareButton(true)
        }
    }

    useEffect(shareText) {
        setNewShareText(shareText)
        if (shareText.isNotBlank()) {
            setEnableShareButton(true)
        }
    }

    useEffect(titlesWithScoreSettings) {
        setNewTitlesWithScoreSettings(titlesWithScoreSettings)
    }

    useEffect(askStudentNameSetting) {
        setNewAskStudentNameSetting(askStudentNameSetting)
    }

    useEffect(enableGamifiedPopups) {
        setNewEnableGamifiedPopups(enableGamifiedPopups)
    }

    useEffect(enableThirdPassage) {
        setNewEnableThirdPassage(enableThirdPassage)
    }

    fun resetState() {
        setNewLink(link)
        setNewContinueText(continueText)
        if (link.isNotBlank() || continueText.isNotBlank()) {
            setEnableContinueButton(true)
        } else {
            setEnableContinueButton(false)
        }
        setNewTitlesWithScoreSettings(titlesWithScoreSettings)
        setNewAskStudentNameSetting(askStudentNameSetting)
        setNewEnableGamifiedPopups(enableGamifiedPopups)
    }

    Modal {
        open = props.isOpen
        onCancel = {
            props.onClose()
            resetState()
        }
        footer = null
        div {
            css(modal)
            Header {
                text = "Advanced settings"
            }
            div {
                css(settingContainer)
                p {
                    css(header)
                    +"STUDENT NAME"
                }
                Select {
                    value = newAskStudentNameSetting
                    onSelect = { it, _ ->
                        setNewAskStudentNameSetting(it)
                    }
                    EAskStudentName.entries.forEach {
                        Option {
                            value = it.value
                        }
                    }
                }
            }
            div {
                css(settingContainer)
                p {
                    css(header)
                    +"SCORING"
                }
                newTitlesWithScoreSettings.forEachIndexed { index, it ->
                    TitleWithScoreSetting {
                        setting = it
                        onChange = { setting ->
                            setNewTitlesWithScoreSettings {
                                val newSettings = it.toMutableList()
                                newSettings[index] = setting
                                newSettings
                            }
                        }
                    }
                }
                div {
                    css(buttonsContainer)
                    Button {
                        css(addButton)
                        Icon {
                            src = "ic_plus_16x16.svg"
                        }
                        onClick = {
                            setNewTitlesWithScoreSettings {
                                it + SliderGreetingScreenTitleWithScoreSettings(
                                    minGeneralScore = if (it.isEmpty()) 0 else it.last().maxGeneralScore
                                )
                            }
                        }
                        +"Add scoring"
                    }
                    Button {
                        css(deleteButton)
                        disabled = newTitlesWithScoreSettings.isEmpty()
                        Icon {
                            src = "ic_delete_16x18.svg"
                        }
                        onClick = { setNewTitlesWithScoreSettings {
                            if (it.size == 1) {
                                listOf()
                            } else {
                                it.subList(0, it.size - 1)
                            }
                        }}
                    }
                }
            }
            div {
                css(settingContainer)
                p {
                    css(header)
                    +"BUTTON CUSTOMIZATION"
                }
                EnableButtonWithTextAndLink {
                    label = "Enable “continue”  button"
                    tooltipHint = "“Continue” button contains a link that leads to additional exercises or any other relevant content you provide."
                    enableButton = enableContinueButton
                    onEnableButtonChange = { setEnableContinueButton(it) }
                    buttonText = newContinueText
                    onButtonTextChange = {
                        setNewContinueText(it)
                    }
                    buttonTextPlaceholder = "“Continue” button name"
                    buttonLink = newLink
                    onButtonLinkChange = {
                        setNewLink(it)
                    }
                    buttonLinkPlaceholder = "“Continue” button link"
                }
                EnableButtonWithTextAndLink {
                    label = "Enable “share”  button"
                    tooltipHint = "“Share” button contains a link that leads to additional exercises or any other relevant content you provide."
                    enableButton = enableShareButton
                    onEnableButtonChange = { setEnableShareButton(it) }
                    buttonText = newShareText
                    onButtonTextChange = {
                        setNewShareText(it)
                    }
                    buttonTextPlaceholder = "“Share” button name"
                    buttonLink = newShareLink
                    onButtonLinkChange = {
                        setNewShareLink(it)
                    }
                    buttonLinkPlaceholder = "“Share” button link"
                }
            }
            div {
                css(settingContainer)
                p {
                    css(header)
                    +"PASSAGE SETTINGS"
                }
                SwitchWithLabelAndTooltip {
                    label = "Enable gamified popups"
                    tooltipHint = "“Gamified popups“ are popups after each 5th slide with some entertaining data."
                    checked = newEnableGamifiedPopups
                    onChange = {
                        setNewEnableGamifiedPopups(it)
                    }
                }
                SwitchWithLabelAndTooltip {
                    label = "Third passage"
                    tooltipHint = "“Third passage“ is a last attempt to solve wrong slides correct when all slides are passed for the first time."
                    checked = newEnableThirdPassage
                    onChange = {
                        setNewEnableThirdPassage(it)
                    }
                }
            }
            Button {
                css(shareButton.extend {
                    width = 100.pct
                })
                onClick = f@{
                    if (newContinueText == continueText
                        && newLink == link
                        && newTitlesWithScoreSettings.equals(titlesWithScoreSettings)
                        && newEnableGamifiedPopups == enableGamifiedPopups
                        && newEnableThirdPassage == enableThirdPassage
                        && newAskStudentNameSetting == askStudentNameSetting
                        && newShareText == shareText
                        && newShareLink == shareLink) {
                        return@f
                    }

                    props.onClose()
                    dispatch(StartModalLoading("Preparing the content"))
                    GlobalScope.launch {
                        val slider = updateSliderSettingsRequest(
                            props.requestId, newLink, newContinueText, newShareLink, newShareText, props.task,
                            newTitlesWithScoreSettings, newEnableGamifiedPopups, newEnableThirdPassage, newAskStudentNameSetting,
                        )
                        dispatch(EndModalLoading())
                        if (slider == null) {
                            return@launch
                        }

                        dispatch(UpdateCurrentRequestSettings(
                            newLink,
                            newContinueText,
                            newShareLink,
                            newShareText,
                            newTitlesWithScoreSettings,
                            newAskStudentNameSetting,
                            newEnableGamifiedPopups,
                            newEnableThirdPassage
                        ))
                        dispatch(UpdateSliderInCurrentRequest(slider))
                    }
                }
                +"Apply all settings"
            }
        }
    }
}