package pages.constructor.ui.components.header.interactiveParams

import csstype.*
import utils.inject
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    gap = 10.px
    width = 20.pct.plus(34.px)
}

val nameInput: CssStyle = {
    padding = Padding(4.px, 10.px)
    width = 100.pct
    textAlign = TextAlign.center

    backgroundColor = rgba(0, 0, 0, 0.0)
    border = Border(1.px, LineStyle.solid, Color("#344054"))
    borderRadius = 4.px

    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 14.px
    lineHeight = 17.px
    color = Color("#FFFFFF")
}

val button: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    gap = 10.px

    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 14.px
    lineHeight = 130.pct
    color = Color("#FFFFFF")

    border = None.none
    padding = 0.px

    transitionDuration = 0.s // because filter's transition is weird

    hover {
        color = important(Color("#FFFFFF"))
        filter = ("invert(52%) sepia(29%) saturate(5446%) " +
                "hue-rotate(207deg) brightness(96%) contrast(102%)").unsafeCast<FilterFunction>()
        backgroundColor = important(rgba(0, 0, 0, 0.0))
        transitionDuration = 0.s
    }
}

val popupContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column

    gap = 8.px
    padding = 8.px

    width = 236.px
    height = 245.px
}

val popupInputContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column

    gap = 6.px

    width = 100.pct
    height = 63.px
}

val header: CssStyle = {
    fontFamily = string("\"Arial\"")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 14.px
    lineHeight = 17.px
    color = Color("#101828")
}

val select: CssStyle = {
    height = 32.px
    width = 100.pct
    borderRadius = 3.px

    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween

    whiteSpace = WhiteSpace.normal
    textAlign = TextAlign.left

    padding = 0.px
}

val buttonSave: CssStyle = {
    inject(button)

    padding = Padding(6.px, 12.px)
    backgroundColor = Color("#597EF7")

    height = 30.px
    width = 100.px

    display = Display.flex

    marginTop = 5.px
    marginLeft = Auto.auto

    hover {
        color = important(Color("#FFFFFF"))
        backgroundColor = important(Color("#597EF7"))
    }
}
