package widgets.LanguageAutoTask.ui

import antd.Button
import antd.TextArea
import app.useAppDispatch
import csstype.Auto
import csstype.Margin
import csstype.pct
import csstype.px
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.languageAutoCurrentRequest.*
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.w3c.dom.MessageEvent
import org.w3c.dom.events.Event
import pages.languageAuto.SetHiddenIframeIsShown
import pages.languageAuto.selectHiddenIframeIsShown
import pages.languageAuto.ui.useLanguageAutoContext
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.redux.useSelector
import shared.components.Icon
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoSlider.ui.components.SliderName.SliderName
import widgets.LanguageAutoTask.generateMoreSlides
import widgets.LanguageAutoTask.ui.components.HiddenIframe.HiddenIframe
import widgets.LanguageAutoTask.ui.components.SlidesDragAndDrop.SlidesDragAndDrop
import widgets.LanguageAutoTask.generateSliderRequest
import widgets.LanguageAutoTask.ui.components.AddExistingSlidesButton.AddExistingSlidesButton
import widgets.LanguageAutoTask.ui.components.HiddenIframe.getIframeWithCookieScript
import widgets.LanguageAutoTask.ui.components.HiddenIframe.screeningImagesScript
import widgets.LanguageAutoTask.ui.components.SlideEditModal.SlideEditModal
import widgets.LanguageAutoTask.ui.components.SlideEditModal.cancelButton
import widgets.languageAutoTask.ui.components.AddCustomSlideButton.AddCustomSlideButton
import widgets.languageAutoTask.ui.components.GenerateMoreSlidesButton.GenerateMoreSlidesButton

data class Slide(
    val image: String,
    val id: Int,
    val taskLine: String
)

external interface LanguageAutoTaskProps : Props {
    var isOpened: Boolean
    var onSave: (String) -> Unit
    var onCancelClick: () -> Unit
}

val LanguageAutoTask = FC<LanguageAutoTaskProps> { props ->
    val requestId = useSelector(selectCurrentRequestId)
    val task = useSelector(selectCurrentRequestTask)
    val slider = useSelector(selectCurrentRequestSlider)

    val hiddenIFrameIsShown = useSelector(selectHiddenIframeIsShown)

    val (newTask, setNewTask) = useState("")
    val (newSlider, setNewSlider) = useState("")
    val (slides, setSlides) = useState(arrayOf<Slide>())
    val (currentSlides, setCurrentSlides) = useState(arrayOf<Slide>())
    val (advancedEditOpened, setAdvancedEditOpened) = useState(false)

    val newTaskLinesCount = useMemo(newTask) {
        if (newTask != null) newTask.count { it == '\n' } + 1 else 0
    }

    val (entertainingLoader) = useLanguageAutoContext()

    val (lastSuccessfullyGeneratedTask, setLastSuccessfullyGeneratedTask) = useState(task)

    val dispatch = useAppDispatch()

    useEffect(task) {
        task?.let { setNewTask(it) }
    }

    useEffect(slider) {
        slider?.let { setNewSlider(it) }
    }

    val (indexOfSlideToEdit, setIndexOfSlideToEdit) = useState<Int?>(null)

    if (requestId == null || task == null || slider == null || !props.isOpened) {
        return@FC
    }

    suspend fun update(taskToGenerate: String, shouldSave: Boolean = true, onUpdated: () -> Unit = {}): Boolean {
        dispatch(StartModalLoading("Preparing the content"))
        val sliderResponse = generateSliderRequest(taskToGenerate, requestId, false, shouldSave)
        if (sliderResponse == null) {
            dispatch(EndModalLoading())
            return false
        }

        onUpdated()
        dispatch(SetHiddenIframeIsShown(true))
        setNewSlider(sliderResponse.slider)
        setNewTask(taskToGenerate)
        setLastSuccessfullyGeneratedTask(taskToGenerate)

        return true
    }

    SlideEditModal {
        slideIndex = indexOfSlideToEdit
        closeModal = { setIndexOfSlideToEdit(null) }
        taskLine = if (indexOfSlideToEdit != null) currentSlides.getOrNull(indexOfSlideToEdit)?.taskLine ?: "" else ""
        show = indexOfSlideToEdit != null
        this.slider = newSlider
        generateTask = {
            currentSlides.mapIndexed { index, slide ->
                val taskLine = if (index == indexOfSlideToEdit) {
                    it
                } else {
                    slide.taskLine
                }

                "${index + 1}. ${taskLine}"
            }.joinToString("\n")
        }
        saveSlide = { taskFromEditSlide, sliderFromEditSlide ->
            val newTasks = generateTask(taskFromEditSlide)
            setNewTask(newTasks)
            setNewSlider(sliderFromEditSlide)
            setLastSuccessfullyGeneratedTask(newTasks)
            dispatch(SetHiddenIframeIsShown(true))
            dispatch(StartModalLoading("Preparing the content..."))
            closeModal()
        }
    }

    div {
        css(headerContainer)
        div {
            css {
                margin = Margin(0.px, Auto.auto)
            }
            SliderName {
                this.requestId = requestId
            }
        }
        div {
            css(headerContent)
            p {
                css(numberOfSlides)
                +"${currentSlides.size} slide${if (currentSlides.size != 1) "s" else ""}"
            }
            Button {
                css(advancedEditButton(advancedEditOpened))
                onClick = f@{
                    if (lastSuccessfullyGeneratedTask == newTask) {
                        setAdvancedEditOpened { !it }
                        return@f
                    }

                    GlobalScope.launch {

                        if (advancedEditOpened) {
                            // shouldSave = false: this only makes new images of slides appear, so user can confirm them, or not
                            if (!update(newTask, shouldSave = false)) {
                                return@launch
                            }
                        }

                        setAdvancedEditOpened { !it }

                    }
                }
                +if (advancedEditOpened) "Save & Update" else "Advanced edit"
            }
        }
    }
    HiddenIframe {
        this.slider = getIframeWithCookieScript(newSlider, "")
        this.script = screeningImagesScript
        this.show = hiddenIFrameIsShown
        this.onHandleMessage = f@{ event: Event ->
            try {
                val imgs: dynamic = (event as MessageEvent).data ?: return@f
                if (!imgs[0] || !imgs[0].image) {
                    return@f
                }

                val slides = mutableListOf<Slide>()
                for (i in 0 until imgs.length as Int) {
                    slides.add(
                        Slide(
                            image = imgs[i].image,
                            id = i,
                            taskLine = imgs[i].taskLine
                        )
                    )
                }

                setSlides(slides.toTypedArray())
                setCurrentSlides(slides.toTypedArray())
                val newTask = slides.mapIndexed { index, slide -> "${index + 1}. ${slide.taskLine}" }.joinToString("\n")

                entertainingLoader.endLoading()
                setNewTask(newTask)
                setLastSuccessfullyGeneratedTask(newTask)
                dispatch(SetHiddenIframeIsShown(false))

            } catch (e: Exception) {
                console.log("Error: ${e.message}")
            }
        }
    }
    if (advancedEditOpened) {
        TextArea {
            css(taskTextArea)
            value = newTask
            placeholder = "Type task"
            onChange = { setNewTask(it.target.value) }
        }
    } else {
        div {
            css {
                width = 100.pct
            }
            SlidesDragAndDrop {
                this.slides = currentSlides
                onChange = {
                    setCurrentSlides(it)
                    val taskAfterChange = it.mapIndexed { index, slide -> "${index + 1}. ${slide.taskLine}" }
                        .joinToString("\n")
                    setNewTask(taskAfterChange)
                }
                onEditSlideClick = f@{
                    if (newTask.trim() == lastSuccessfullyGeneratedTask?.trim()) {
                        setIndexOfSlideToEdit(it)
                        return@f
                    }

                    GlobalScope.launch {
                        update(newTask) { setIndexOfSlideToEdit(it) }
                    }
                }
            }
            div {
                css(addSlideButtonsGroup)
                GenerateMoreSlidesButton {
                    onClick = {
                        entertainingLoader.startLoading()
                        GlobalScope.launch {
                            val (sliderResponse, error) = generateMoreSlides(
                                task, requestId
                            )

                            if (sliderResponse != null) {
                                dispatch(SetHiddenIframeIsShown(true))
                                setNewTask(sliderResponse.task)
                                setNewSlider(sliderResponse.slider)
                                setLastSuccessfullyGeneratedTask(newTask)
                            } else if (error != null) {
                                dispatch(OpenErrorModal(error.ifBlank { "Try again" }))
                                entertainingLoader.endLoading()
                                lastSuccessfullyGeneratedTask?.let {
                                    setNewTask(it)
                                }
                            }
                        }
                    }
                }
                AddExistingSlidesButton {
                    onSave = {
                        val updatedNewTask = "$newTask\n$it"
                        entertainingLoader.startLoading()
                        GlobalScope.launch {
                            update(updatedNewTask, shouldSave = false)
                        }
                    }
                }
                AddCustomSlideButton {
                    onClick = { taskToAppend ->
                        val updatedNewTask = "$newTask\n$newTaskLinesCount. $taskToAppend"

                        entertainingLoader.startLoading()
                        GlobalScope.launch {
                            update(updatedNewTask, shouldSave = false)
                            // This prevents ub caused by async state setting
                            val updatedNewTaskLinesCount =
                                updatedNewTask.split("\n").count { it.isNotBlank() && it.first().isDigit() }
                            setIndexOfSlideToEdit(updatedNewTaskLinesCount - 1)
                        }
                    }
                }
            }
        }
    }
    div {
        css(buttonsContainer)
        div {
            css(mainButtons)
            Button {
                css(generateButton)
                disabled = !advancedEditOpened && task == newTask
                onClick = f@{
                    GlobalScope.launch {

                        if (advancedEditOpened) {
                            if (task == newTask) {
                                setAdvancedEditOpened(false)
                                return@launch
                            }

                            if (update(newTask)) {
                                setAdvancedEditOpened(false)
                            } else {
                                return@launch
                            }
                        }

                        props.onSave(newTask)
                        setSlides(currentSlides)

                    }
                }
                Icon {
                    src = "ic_magic_wand_24x24.svg"
                }
                +"Save & ${if (advancedEditOpened) "Update" else "Apply"}"
            }
            Button {
                css(cancelButton)
                onClick = f@{
                    props.onCancelClick()
                    setNewTask(task)
                    setCurrentSlides(slides)
                    if (slider == newSlider) {
                        return@f
                    }

                    setNewSlider(slider)
                    dispatch(SetHiddenIframeIsShown(true))
                }
                +"Cancel"
            }
        }
        if (task.trim() != newTask.trim()) {
            Button {
                css(resetButton)
                +"Reset all changes"
                onClick = f@{
                    setNewTask(task)
                    setCurrentSlides(slides)
                    if (slider == newSlider) {
                        return@f
                    }

                    setNewSlider(slider)
                    dispatch(SetHiddenIframeIsShown(true))
                    dispatch(StartModalLoading("Preparing the content..."))
                }
            }
        }
    }
}