package online.interactiver.common.interactivepicture

import kotlinx.serialization.Serializable
import online.interactiver.common.utils.Copyable

@Serializable
data class ScoreCondition(
        var left: Int?,
        val scoreName: String,
        val right: Int?
) : Copyable<ScoreCondition> {
    override fun clone() = copy()
}
