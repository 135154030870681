package widgets.Exercises.ui.components.FolderUserActions.FolderDeletingModal

import csstype.*
import utils.types.CssStyle
import utils.types.extend

val modalBody: CssStyle = {
    padding = Padding(0.px, 0.px, 10.px, 0.px)
}

val modalHeader: CssStyle = {
    fontFamily = string("Montserrat")
    fontWeight = FontWeight.bolder
    fontSize = 20.px
    marginBottom = 12.px
    color = Color("#282F3E")
}

val modalAttentionBody: CssStyle = {
    fontSize = 16.px
}

val modalAttentionBodyEmph: CssStyle = modalAttentionBody.extend {
    fontWeight = integer(700)
}
