package pages.constructor.ui.components.modalLoader

import Modal
import antd.Spin
import app.useAppDispatch
import emotion.react.css
import entities.modalLoader.EndModalLoading
import kotlinx.js.jso
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import pages.constructor.ui.components.loadingDots.LoadingDots
import react.ReactNode
import react.dom.html.ReactHTML.p
import shared.components.Icon

external interface ModalLoaderProps : Props {
    var loading: Boolean
    var text: String?
    var showSuccessfulLoading: Boolean?
    var subText: ReactNode?
    var onClose: (() -> Unit)?
}

val ModalLoader = FC<ModalLoaderProps> {
    Modal {
        zIndex = 500
        centered = true
        open = it.loading
        footer = null
        closable = false
        width = "328px"
        bodyStyle = jso(loaderBodyStyle)
        maskStyle = jso(loaderMaskStyle)

        Spin {
            size = "large"
        }

        if (it.text != null) {
            div {
                css(loaderTextStyle)
                +it.text!!
                LoadingDots {}
            }
        }
    }
}

val LanguageAutoModalLoader = FC<ModalLoaderProps> { props ->
    Modal {
        zIndex = 500
        centered = true
        open = props.loading
        footer = null
        closable = props.showSuccessfulLoading == true
        width = "328px"
        bodyStyle = jso(loaderBodyStyle)
        maskStyle = jso(loaderMaskStyle)
        onCancel = {
            if (props.showSuccessfulLoading == true) {
                props.onClose?.invoke()
            }
        }

        div {
            css(container)
            if (props.showSuccessfulLoading == true) {
                Icon {
                    src = "ic_correct_36x36.svg"
                }
            } else {
                Spin {
                    size = "large"
                }
            }

            if (props.text != null) {
                div {
                    css(textContainer)
                    p {
                        css(header)
                        +props.text!!
                    }
                    p {
                        css(description)
                        props.subText?.let {
                            +it
                        }
                    }
                }
            }
        }
    }
}