package pages.constructor.ui.components.advancedElementsSettings.advancedPuzzleSettings

import Modal
import app.useAppDispatch
import app.useAppSelector
import emotion.react.css
import entities.advancedElementSettings.CloseAdvancedElementSettings
import entities.advancedElementSettings.selectAdvancedElementSettingsModalState
import entities.interactivePicture.elements.SetElementCode
import entities.interactivePicture.elements.SetPuzzleCustomInitialPosition
import entities.interactivePicture.elements.editors.elementCodeEditor.ElementCodeEditor
import entities.interactivePicture.elements.editors.elementCodeEditor.useFocusedElementCode
import entities.interactivePicture.selectFocusedElement
import entities.interactivePicture.selectFocusedElementCustomPuzzleInitPosition
import entities.interactivePicture.selectFocusedElementId
import pages.constructor.ui.components.advancedElementsSettings.advancedSelectorSettings.container
import pages.constructor.ui.components.advancedElementsSettings.advancedSelectorSettings.elementTypeHeader
import pages.constructor.ui.components.advancedElementsSettings.advancedSelectorSettings.header
import react.*
import react.dom.html.ReactHTML.div
import shared.canvas.interfaces.isDraggable
import shared.components.checkboxWithLabel.CheckboxWithLabel

val AdvancedPuzzleSettings = FC<Props> {
    val modalState = useAppSelector(selectAdvancedElementSettingsModalState)
    val focusedElementId = useAppSelector(selectFocusedElementId)
    val focusedElement = useAppSelector(selectFocusedElement)
    val focusedElementCustomPuzzleInitPosition = useAppSelector(selectFocusedElementCustomPuzzleInitPosition)
    val (customPuzzleInitPosition, setCustomPuzzleInitPosition) = useState<String?>(focusedElementCustomPuzzleInitPosition)
    val dispatch = useAppDispatch()

    if (focusedElement == null || !focusedElement.isDraggable()) {
        throw RuntimeException("Rendered advanced puzzle settings with no input focused!")
    }

    val (elementCode, setElementCode) = useFocusedElementCode()

    useEffect(focusedElementCustomPuzzleInitPosition) {
        setCustomPuzzleInitPosition(focusedElementCustomPuzzleInitPosition)
    }

    Modal {
        width = 800
        open = modalState.isOpen
        onCancel = {
            setCustomPuzzleInitPosition(focusedElementCustomPuzzleInitPosition)
            setElementCode(focusedElement.identifier.code ?: "")
            dispatch(CloseAdvancedElementSettings())
        }
        onOk = {
            dispatch(SetPuzzleCustomInitialPosition(focusedElementId!!, customPuzzleInitPosition))
            dispatch(SetElementCode(focusedElement.identifier.id!!, elementCode))
            dispatch(CloseAdvancedElementSettings())
        }
        okText = "Save changes"
        div {
            css(header)
            +"Advanced element settings"
        }
        div {
            css(container)
            div {
                css(elementTypeHeader)
                +"Puzzle"
            }
            ElementCodeEditor {
                value = elementCode
                onChange = {
                    setElementCode(it)
                }
            }
            div {
                css(checkboxContainer)
                CheckboxWithLabel {
                    checked = customPuzzleInitPosition != null
                    onChange = {
                        setCustomPuzzleInitPosition(if (it) "1" else null)
                    }
                    label = "Use custom initial position of puzzle"
                }
            }
        }
    }
}
